import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import { AssignmentIndOutlined, Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import * as api from "../../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";
import ChooseVendorDropDown from "../../../GlobalComponents/DropDowns/ChooseVendorDropDown";
import ChooseSubVendor from "../../../GlobalComponents/DropDowns/ChooseSubVendor";

function AssignVendorToAll(props) {
  const classes = useStyles();
  const { assignToAll, setassignToAll, categoryId, getComplaintSubCategory } =
    props;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [days, setdays] = useState("");
  const [hours, sethours] = useState("");
  const [confirmAutoAssign, setconfirmAutoAssign] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [subVendor, setsubVendor] = useState([]);

  const handleAssigntoAll = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let temp =
        24 * parseInt(days === "" ? 0 : days) +
        parseInt(hours === "" ? 0 : hours);

      if (temp == "0" || !vendor || vendor?.length === 0) {
        setconfirmAutoAssign(false);
        setloader(false);
        return seterrorMsg(
          "Deadline day/hours and vendor is required for Enable Auto Assign"
        );
      } else {
        const sendData = {
          subcategoryName: "",
          deadline: temp.toString(),
          categoryId: categoryId,
          vendor: vendor?._id,
          autoAssign: true,
          subcomplaintCategoryId: "",
          autoEscalation: false,
          all: true,
          subVendors: subVendor?.map((vendor) => vendor?._id),
        };

        const res = await api.editSubComplaintCategory(sendData);
        if (res?.data?.success) {
          seterrorMsg("");
          setdays("");
          sethours("");
          setVendor([]);
          setsubVendor([]);
          setassignToAll(false);
          setloader(false);
          getComplaintSubCategory();
          toast.success("Vendor Assigned to all");
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  const confirmAssign = (
    <div>
      {confirmAutoAssign && (
        <Modal open={confirmAutoAssign}>
          <Paper
            style={{ backgroundColor: "#e6e7f0" }}
            className={classes.confirmModal}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
              }}
            >
              CONFIRM ASSIGN VENDOR TO ALL
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <AssignmentIndOutlined
                style={{ fontSize: 70, color: "#1e2082" }}
              />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              A confirmation message will appear when this process is
              complete.You can confirm by clicking below.
            </Typography>
            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={() => {
                  setconfirmAutoAssign(false);
                }}
                className={classes.buttonDelete}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  handleAssigntoAll();
                  setconfirmAutoAssign(false);
                }}
                style={{ backgroundColor: "#1e2082", color: "#fff" }}
                className={classes.buttonDelete}
              >
                CONFIRM
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
    </div>
  );

  return (
    <>
      <Modal open={assignToAll}>
        <Paper
          className={classes.ModalView}
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            minWidth: "350px",
            maxWidth: "29vw",
            height: "auto",
          }}
        >
          <Grid
            container
            style={{ textAlign: "center", justifyContent: "space-between" }}
          >
            <Typography
              style={{
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Assign Vendor to all
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  setassignToAll(false);
                  setloader(false);
                  seterrorMsg("");
                  setdays("");
                  sethours("");
                  setVendor([]);
                  setsubVendor([]);
                  setassignToAll(false);
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
          />
          <Grid container style={{ marginLeft: -10 }}>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginBottom: 2,
                  marginLeft: 13,
                }}
              >
                Deadline in Days
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginBottom: 2,
                  marginLeft: 13,
                }}
              >
                Deadline in Hours
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                type="number"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => setdays(event.target.value)}
                placeholder="Enter Days"
                value={days}
                min={1}
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                type="number"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => sethours(event.target.value)}
                placeholder="Enter Hours"
                value={hours}
                min={1}
              />
            </Grid>
          </Grid>
          <Typography
            style={{
              fontWeight: "bold",
              color: "#283f59",
              fontSize: "11px",
              textAlign: "right",
              marginBottom: 8,
              marginRight: 8,
            }}
          >
            Total Deadline Hours : &nbsp;
            {24 * parseInt(days === "" ? 0 : days) +
              parseInt(hours === "" ? 0 : hours)}{" "}
            Hours
          </Typography>

          {/* <Grid container style={{ marginTop: 8 }}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: 6,
                marginRight: 7,
                marginLeft: 8,
              }}
            >
              Select vendor :
            </Typography>
            <Select
              value={vendorId}
              onChange={(event) => setvendorId(event.target.value)}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 6,
                width: "180px",
                height: 37,
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                marginBottom: 10,
                border: "1px solid #6c757d",
                textAlign: "center",
              }}
            >
              <MenuItem selected value="Choose Vendor">
                Choose Vendor
              </MenuItem>
              {vendors?.map((vendor) => {
                return (
                  <MenuItem selected value={vendor._id}>
                    {vendor?.name} ({vendor?.category?.categoryName})
                  </MenuItem>
                );
              })}
            </Select>
          </Grid> */}
          <Grid container style={{ marginTop: 8 }}>
            <ChooseVendorDropDown
              vendor={vendor}
              setVendor={setVendor}
              setsearchKey={setsearchKey}
              searchKey={searchKey}
            />
          </Grid>
          <Grid container style={{ marginTop: 8 }}>
            <ChooseSubVendor
              subVendor={subVendor}
              setsubVendor={setsubVendor}
              vendor={vendor}
              isMultiple={true}
            />
          </Grid>

          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "180px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!loader) {
                      setconfirmAutoAssign(true);
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <AssignmentIndOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Assign Vendor to all" : `Assigning...`}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
      {confirmAssign}
    </>
  );
}

export default AssignVendorToAll;
