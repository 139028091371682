import { Grid, Typography } from "@material-ui/core";
import React from "react";
import TmlTable from "./TmlTable";
import AbstractTable from "./AbstractTable";
import useStyles from "./style";

const JmrBillInvoice = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} style={{ padding: 20 }}>
      <Grid container justifyContent={"center"}>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 18,
            fontFamily: "Arial, sans-serif",
            color: "#010101",
            marginTop: "15px",
            marginBottom: 10,
            textAlign: "center",
          }}
        >
          TATA STEEL Utilities & Infatructures Services Ltd.
          <br />
          INDEX
        </Typography>
      </Grid>
      <div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              1.&nbsp; &nbsp; M.B NO. &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              JUSCO/NDJ/SEC7
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              2.&nbsp; &nbsp; Name of work &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              Rate contract for civil and allied jobs for all sectors(H1) in
              Town
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              3.&nbsp; &nbsp; P.O NO. &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>3430053925</Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              4.&nbsp; &nbsp; J.O Value Rs &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              ₹ 2,62,00,000.00
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              5.&nbsp; &nbsp; Bill Value Rs &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>₹ 4,63,918.41</Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              6.&nbsp; &nbsp; Period of work done &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              1.09.22 to 30.09.22
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              7.&nbsp; &nbsp; Job measured by &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              Mr. Supriya Mallick
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              8.&nbsp; &nbsp; Job Supervised by (TMLRep.) &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              Mr.Ranjan Naskar
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              9.&nbsp; &nbsp; Job measured upto &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>30.09.2022</Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              10.&nbsp; &nbsp;Supplier Code &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>J64630</Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              11.&nbsp; &nbsp;Name of Owner &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}>
              TATA MOTORS Lmt
            </Typography>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col-md-6">
            <Typography className={classes.textLeft}>
              12.&nbsp; &nbsp;Sign. Contractor &nbsp;:
            </Typography>
          </div>
          <div className="col-md-6">
            <Typography className={classes.textRight}></Typography>
          </div>
        </div>
      </div>
      <Grid container style={{ paddingBottom: "40px", paddingTop: "40px" }}>
        <TmlTable />
      </Grid>
      <Grid container className={classes.secondPageContent}>
        <Grid container item justifyContent="center">
          <Typography className={classes.header}>ABSTRACT SHEET</Typography>
        </Grid>
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          style={{
            border: "1px solid black",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Grid container justifyContent="center">
            <Typography className={classes.title}>
              TATA STEEL Utilities & Infatructures Services Ltd.
            </Typography>
          </Grid>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.textLeft}>
              JUSCO/NDJ/SEC7/3010/RA125/22-23
            </Typography>

            <Typography className={classes.textRight}>
              DATE : 10-12-2022
            </Typography>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.textLeft}>
              Period of work done : 01.09.22 to 30.09.22
            </Typography>

            <Typography className={classes.textRight}>
              P.O NO: 3430053925
            </Typography>
          </div>
        </Grid>
        <Grid container justifyContent="center">
          <Typography className={classes.title}>
            (Rupees Four Lakh Fifty five thousand three hundred forty six)
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "20px" }}>
        <AbstractTable />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.textLeft}>
            Gand Total Bill Value
          </Typography>

          <Typography className={classes.textRight}>4,55,346.64</Typography>
        </div>
      </Grid>
    </div>
  );
});

export default JmrBillInvoice;
