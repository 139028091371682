import React, { useContext, useEffect, useState } from "react";
import useStyles from "../ComplaintCategory/styles";
import SearchIcon from "@mui/icons-material/Search";
import ApprovalTable from "./ApprovalTable";
import { Typography, Grid } from "@material-ui/core";
import CloseReasonModal from "./CloseReasonModal";
import ApiCall from "../../../api/ApiCall";
import { ComplaintUpdateProcessRoutes } from "../../../api/Routes";
import { toast } from "react-toastify";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { AuthContext } from "../../../contextAPI/context";
import { Pagination } from "@mui/material";
import Loading from "../../../contextAPI/Loading";
import { LoadingOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { handleUploadInDirectAws } from "../../../utils/awsUpload";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { getPlantWiseRoleSegregation } from "../../../GlobalFunctions/GlobalFunctions";

const Approval = () => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions,plant } = authState;
  const [actionTypeModal, setActionTypeModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [page, setpage] = useState(1);
  const [limit] = useState(40);
  const [totalCount, settotalCount] = useState(0);
  const [allRequest, setallRequest] = useState([]);
  const [complaintData, setcomplaintData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [search, setSearch] = useState("");
  const [deadline, setdeadline] = useState("");
  const [feedback, setfeedback] = useState("");
  const [reason, setreason] = useState("");
  const [stars, setstars] = useState(0);
  const [OTP, setOTP] = useState("");
  const [imageTest, setimageTest] = useState({
    data: "",
    file: "",
  });
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Approval"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setpage(value);
  };

  const rejectConfirmation = () => {
    setIsConfirm(false);
    setcomplaintData("");
    seterrorMessage("");
    setdeadline("");
    setfeedback("");
    setreason("");
    setstars(0);
    setOTP("");
    setimageTest({
      data: "",
      file: "",
    });
  };

  const handleApproveRejectComplaint = async () => {
    if (
      (complaintData?.data?.requestType === "close complaint" ||
        complaintData?.data?.requestType === "deadline extension") &&
      complaintData?.status === "rejected" &&
      reason?.length < 8
    ) {
      seterrorMessage("Reason message should be at least 8 characters long.");
    } else if (
      complaintData?.data?.requestType === "deadline extension" &&
      complaintData?.status === "approved" &&
      (deadline === "" || deadline <= 0)
    ) {
      seterrorMessage("Add a valid deadline.");
    } else if (
      complaintData?.data?.requestType === "close complaint" &&
      complaintData?.status === "approved" &&
      OTP.trim() === ""
    ) {
      seterrorMessage("Otp is required.");
    } else if (
      complaintData?.data?.requestType === "close complaint" &&
      complaintData?.status === "approved" &&
      (feedback === "" || feedback?.length < 8)
    ) {
      seterrorMessage("Please type a feedback of at least 8 characters.");
    } else if (
      complaintData?.data?.requestType === "close complaint" &&
      complaintData?.status === "approved" &&
      stars === 0
    ) {
      seterrorMessage("Please enter a rating.");
    } else {
      let temp1 = [];
      let isApi = true;
      setloader2(true);
      seterrorMessage("");
      setTimeout(() => {
        setloader(false);
      }, 40000);
      if (imageTest?.data) {
        try {
          temp1.push(await handleUploadInDirectAws(imageTest));
        } catch (error) {
          isApi = false;
          seterrorMessage(
            "Something went wrong. Image uploading failed , try again after sometimes."
          );
          setloader(false);
        }
      }
      if (isApi) {
        const sendData = {
          requestId: complaintData?.data?._id,
          status: complaintData?.status,
          actionBy: user?._id,
          requestType: complaintData?.data?.requestType,
          extendedDeadline: deadline,
          closeFeedback: feedback,
          rejectReason: reason,
          stars: stars,
          photo: temp1?.[0]?.Location,
          otp: OTP,
          complaintId: complaintData?.data?.complaintId?._id,
          updatedBy: getPlantWiseRoleSegregation(user, plant),
          updatedFor:"Vendor",
        };

        const res = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.approveDeadline,
          sendData
        );
        if (res?.success) {
          rejectConfirmation();
          handleGetComplaintRequest();
          toast.success("Complaint Approved Successfully");
          setloader2(false);
        } else {
          setloader2(false);
          seterrorMessage(res.error);
        }
      }
    }
  };
  const handleGetComplaintRequest = async () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);

    const sendData = {
      filters: {
        societyId: fetchSocietyId(),
        searchKey: search,
        status: "pending",
      },
      page: page,
      limit: limit,
    };

    const res = await ApiCall(
      "post",
      ComplaintUpdateProcessRoutes.getComplaintDeadline,
      sendData
    );
    if (res?.success) {
      setallRequest(res?.vendor?.data);
      settotalCount(res?.vendor?.totalPages);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    handleGetComplaintRequest();
  }, [search,page]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader || loader2 ? "blur(1px)" : "",
        pointerEvents: loader || loader2 ? "none" : "",
        overflow: loader || loader2 ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint&nbsp;
                    <span style={{ color: "#000" }}>{` > Approval `}</span>{" "}
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Discussion Page"
                        onClick={() => {
                          handleGetComplaintRequest();
                          setpage(1);
                        }}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setpage(1);
                      }}
                      value={search}
                    />
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                {allRequest?.length > 0 && !loader && (
                  <>
                    <ApprovalTable
                      setActionTypeModal={setActionTypeModal}
                      handleApproveComplaint={handleApproveRejectComplaint}
                      loader={loader2}
                      allRequest={allRequest}
                      page={page}
                      setcomplaintData={setcomplaintData}
                      complaintData={complaintData}
                      errorMessage={errorMessage}
                      seterrorMessage={seterrorMessage}
                      isConfirm={isConfirm}
                      setIsConfirm={setIsConfirm}
                      rejectConfirmation={rejectConfirmation}
                      deadline={deadline}
                      setdeadline={setdeadline}
                      feedback={feedback}
                      setfeedback={setfeedback}
                      reason={reason}
                      setreason={setreason}
                      stars={stars}
                      setstars={setstars}
                      imageTest={imageTest}
                      setimageTest={setimageTest}
                      loader2={loader2}
                      OTP={OTP}
                      setOTP={setOTP}
                      isReadWrite={isReadWrite}
                      limit={limit}
                    />

                    <Pagination
                      className={classes.pagination}
                      count={totalCount}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </>
                )}
                {allRequest?.length === 0 && !loader && (
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 19,
                      color: "#2f3aa3",
                    }}
                  >
                    No Request
                  </Typography>
                )}
              </Grid>
            </div>
          </div>
        </Grid>

        <CloseReasonModal
          actionTypeModal={actionTypeModal}
          setActionTypeModal={setActionTypeModal}
          setcomplaintData={setcomplaintData}
          complaintData={complaintData}
        />
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
};

export default Approval;
