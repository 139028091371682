import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  DialogActions,
  Modal,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";
import useStyles from "../styles";
import AddComplaint from "../AddComplaint/AddComplaint";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contextAPI/context";
import * as api from "../../../api";
import {
  Apartment,
  BarChart,
  Error,
} from "@material-ui/icons";
import MixedComplaintsGraph from "../MixedComplaintsGraph";
import { ExportJsonCsv } from "react-export-json-csv";
import { saveAs } from "file-saver";
import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Close } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { PreviewRounded, RestartAlt } from "@mui/icons-material";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { navigateInitialQuickView } from "../../../Navigation/Navigations";

function MixedComplaintsGraphDV() {
  const classes = useStyles();
  const [addComplaintModal, setaddComplaintModal] = useState(false);
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { categories, vendors, properties } = authState;
  const [openVendor, setOpenVendor] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [fyWiseGraphDialogData, setFyWiseGraphDialogData] = useState({
    yearOne: "",
    yearTwo: "",
    complaintStatus: "assigned",
  });
  const [openSectorDialog, setOpenSectorDialog] = useState(false);
  const [sectorWiseGraphData, setSectorWiseGraphData] = useState({
    sectors: [],
    complaintStatus: "assigned",
  });
  const [openSLA, setOpenSLA] = useState(false);
  const [anchorElOne, setAnchorElOne] = useState(null);
  const [anchorElTwo, setAnchorElTwo] = useState(null);
  const [anchorElThree, setAnchorElThree] = useState(null);
  const [anchorElFour, setAnchorElFour] = useState(null);
  const [anchorElFive, setAnchorElFive] = useState(null);
  const [anchorElSix, setAnchorElSix] = useState(null);
  const [selectedSectorsSlaForGraph, setselectedSectorsSlaForGraph] = useState(
    []
  );
  const [selectedSectorsForGraph, setselectedSectorsForGraph] = useState([]);

  const openOne = Boolean(anchorElOne);
  const [sectorGraphData, setSectorGraphData] = useState({ sectors: [] });
  const [mixedComplaintsGraphData, setMixedComplaintsGraphData] =
    useState(null);

  const [durationData, setDurationData] = useState({ months: [] });
  const [slaGraphData, setSlaGraphData] = useState({
    sectors: [],
    complaintStatus: "assigned",
  });
  const [vendorWiseGraphData, setVendorWiseGraphData] = useState({
    vendors: [],
    complaintStatus: "assigned",
  });
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const openTwo = Boolean(anchorElTwo);
  const openThree = Boolean(anchorElThree);
  const openFour = Boolean(anchorElFour);
  const openFive = Boolean(anchorElFive);
  const openSix = Boolean(anchorElSix);
  // const [getPng, { ref }] = useCurrentPng();
  const [headers, setHeaders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [errorMessageSectorWiseGraph, seterrorMessageSectorWiseGraph] =
    useState("");
  const [VendorWiseFromDate, setVendorWiseFromDate] = useState("");
  const [VendorWiseToDate, setVendorWiseToDate] = useState("");
  const [SectorWiseFromDate, setSectorWiseFromDate] = useState("");
  const [SectorWiseToDate, setSectorWiseToDate] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [graphType, setgraphType] = useState("");
  const [ToDate, setToDate] = useState("");
  const [SlaWiseFromDate, setSlaWiseFromDate] = useState("");
  const [SlaWiseToDate, setSlaWiseToDate] = useState("");
  const [CategoryWisefromDate, setCategoryWisefromDate] = useState("");
  const [CategoryWiseToDate, setCategoryWiseToDate] = useState("");
  const [VendorWisePerfFromDate, setVendorWisePerfFromDate] = useState("");
  const [VendorWisePerfToDate, setVendorWisePerfToDate] = useState("");
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const [allProperties, setallProperties] = useState([]);
  /* fixed for all */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const monthName = (mon) => {
    if (mon == "01") {
      mon = 1;
    } else if (mon == "02") {
      mon = 2;
    } else if (mon == "03") {
      mon = 3;
    } else if (mon == "04") {
      mon = 4;
    } else if (mon == "05") {
      mon = 5;
    } else if (mon == "06") {
      mon = 6;
    } else if (mon == "07") {
      mon = 7;
    } else if (mon == "08") {
      mon = 8;
    } else if (mon == "09") {
      mon = 9;
    }
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][mon - 1];
  };

  const handleOpenVendor = () => {
    setOpenVendor(true);
  };

  const handleOpenYear = () => {
    setOpenYear(true);
  };

  /* for Sector Wise graph */
  const sectors = allProperties?.map((property) => property.sector);

  const handleChangeSector = (event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "sectors")
      setSectorWiseGraphData((prev) => ({
        ...prev,
        sectors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setSectorWiseGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleCloseTwo = () => {
    setAnchorElTwo(null);
  };
  const handleCloseThree = () => {
    setAnchorElThree(null);
  };
  const handleCloseFive = () => {
    setAnchorElFive(null);
  };
  const handleCloseSix = () => {
    setAnchorElSix(null);
  };

  const handleChangeSectorSla = (event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "sectors")
      setSlaGraphData((prev) => ({
        ...prev,
        sectors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setSlaGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleOpenSector = () => {
    setOpenSectorDialog(true);
  };

  const handleOpenSLA = () => {
    setOpenSLA(true);
  };

  const handleCloseSLA = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    seterrorMessageSectorWiseGraph("");
    if (durationData.months.length !== 0) {
      if (selectedSectorsSlaForGraph.length === 0) {
        setIsLoading(false);
        seterrorMessageSectorWiseGraph("please select atleast one sector");
      } else {
        const res = await api.getSlaGraphData({
          societyId: fetchSocietyId(),
          ...slaGraphData,
          sectors: selectedSectorsSlaForGraph,
          months: durationData.months,
        });
        setOpenSLA(false);
        setMixedComplaintsGraphData(res.data.slaData);
        setgraphType("");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a time period first");
    }
  };

  const handleCloseSector = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    if (durationData.months.length !== 0) {
      if (selectedSectorsForGraph.length === 0) {
        setIsLoading(false);
        seterrorMessageSectorWiseGraph("please select atleast one sector");
      } else {
        const res = await api.getSectorWiseGraphData({
          societyId: fetchSocietyId(),
          ...sectorWiseGraphData,
          months: durationData.months,
          sectors: selectedSectorsForGraph,
        });
        setOpenSectorDialog(false);
        seterrorMessageSectorWiseGraph("");
        setgraphType("");
        setSectorGraphData(res.data.sectorComplaintData);

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a duration first");
    }
  };

  /* For download and report download */
  const handleClickOne = (event) => {
    setAnchorElOne(event.currentTarget);
  };
  const handleClickTwo = (event) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleClickThree = (event) => {
    setAnchorElThree(event.currentTarget);
  };
  const handleClickFour = (event) => {
    setAnchorElFour(event.currentTarget);
  };
  const handleClickFive = (event) => {
    setAnchorElFive(event.currentTarget);
  };
  const handleClickSix = (event) => {
    setAnchorElSix(event.currentTarget);
  };

  var date = new Date();

  useEffect(async () => {
    if (authState) {
      loadInitialData();
    }
  }, [authState]);

  const currentYear = new Date().getFullYear().toString().split("0")[1];
  const currentMonth = (new Date().getMonth() + 1).toString();

  const loadInitialData = async () => {
    setIsLoading(true);
    let res = await api.getMsiData({
      societyId: fetchSocietyId(),
      complaintStatus: "assigned",
      monthAndYear:
        durationData.months.length !== 0
          ? durationData.months
          : [`${currentMonth}-${currentYear}`],
    });
    setMixedComplaintsGraphData(res.data.data.slaData);
    setIsLoading(false);
  };
  const handleResetData = async () => {
    setIsLoading(true);
    setfromDate("");
    setSectorWiseFromDate("");
    setToDate("");
    setSectorWiseToDate("");
    let res = await api.getMsiData({
      societyId: fetchSocietyId(),
      complaintStatus: "assigned",
      monthAndYear: [`${currentMonth}-${currentYear}`],
    });
    setMixedComplaintsGraphData(res.data.data.slaData);
    setIsLoading(false);
  };

  const handleDurationChange = (e) => {
    console.log("e.target.value", e.target.value);
    const data =
      e.target.value.substring(2).split("-")[1] +
      "-" +
      e.target.value.substring(2).split("-")[0];
    if (e.target.name === "from") {
      if (graphType === "") {
        setfromDate(e.target.value);
        setCategoryWisefromDate(e.target.value);
        setSlaWiseFromDate(e.target.value);
        setVendorWiseFromDate(e.target.value);
        setSectorWiseFromDate(e.target.value);
        setVendorWisePerfFromDate(e.target.value);
      }
      if (graphType === "categoryWise") {
        setCategoryWisefromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "SlaWise") {
        setSlaWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "vendorWise") {
        setVendorWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "sectorWise") {
        setSectorWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "vendorPerfWise") {
        setVendorWisePerfFromDate(e.target.value);
        setfromDate("");
      }
      if (durationData.to) {
        let start = data.split("-");
        let end = durationData.to.split("-");
        let startYear = parseInt(start[1]);
        let endYear = parseInt(end[1]);
        let months = [];

        for (let i = startYear; i <= endYear; i++) {
          let endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
          let startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
          for (
            let j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            let month = j + 1;
            let displayMonth = month < 10 ? "0" + month : month;
            months.push([displayMonth, i].join("-"));
          }
        }
        setDurationData((prev) => ({ ...prev, months, from: data }));
      } else setDurationData((prev) => ({ ...prev, [e.target.name]: data }));
    } else {
      if (graphType === "") {
        setToDate(e.target.value);
        setCategoryWiseToDate(e.target.value);
        setSlaWiseToDate(e.target.value);
        setVendorWiseToDate(e.target.value);
        setSectorWiseToDate(e.target.value);
        setVendorWisePerfToDate(e.target.value);
      }
      if (graphType === "categoryWise") {
        setCategoryWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "SlaWise") {
        setSlaWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "vendorWise") {
        setVendorWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "sectorWise") {
        setSectorWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "vendorPerfWise") {
        setVendorWisePerfToDate(e.target.value);
        setToDate("");
      }
      if (durationData.from) {
        let start = durationData.from.split("-");
        let end = data.split("-");
        let startYear = parseInt(start[1]);
        let endYear = parseInt(end[1]);
        let months = [];

        for (let i = startYear; i <= endYear; i++) {
          let endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
          let startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
          for (
            let j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            let month = j + 1;
            let displayMonth = month < 10 ? "0" + month : month;
            months.push([displayMonth, i].join("-"));
          }
        }
        setDurationData((prev) => ({ ...prev, months, to: data }));
      } else setDurationData((prev) => ({ ...prev, [e.target.name]: data }));
    }
  };
  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseOne = () => {
    setAnchorElOne(null);
  };

  const handleCloseFour = () => {
    setAnchorElFour(null);
  };

  const saveCanvas = (event, id) => {
    console.log(id);
    const canvasSave = document.getElementById(id);
    console.log(canvasSave);
    canvasSave.toBlob(function (blob) {
      saveAs(blob, `${id}.png`);
    });
    setAnchorElOne(null);
    setAnchorElTwo(null);
  };

  const handleThreeCsv = () => {
    const temp = [];
    mixedComplaintsGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setAnchorElThree(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
  };

  const handleGetProperties = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
        pageLimit: 1000,
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        setallProperties(res?.data?.properties?.properties);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetProperties();
  }, []);

  const yearOne = fyWiseGraphDialogData.yearOne.toString();
  const yearTwo = fyWiseGraphDialogData.yearTwo.toString();

  const handleDownload = async () => {
    // const png = await getPng();
    // console.log(png);
    // if (png) saveAs(png, "myChart.png");
    // setAnchorElTwo(null);
  };
  return (
    <>
      <div
        className={classes.root}
        style={{
          filter: isLoading ? "blur(1px)" : "",
          overflow: isLoading ? "hidden" : "auto",
          height: "100vh",
          pointerEvents: isLoading ? "none" : "",
        }}
      >
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Grid item lg={12} sm={12} xs={12}>
            <div class="card" style={{ borderRadius: 6, minHeight: "89vh" }}>
              <div class="card-body">
                <Grid
                  container
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container item xs={6}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                      }}
                    >
                      SLA / Beyond SLA complaints
                    </Typography>
                  </Grid>
                  {/* <Grid container item xs={6} style={{justifyContent: "flex-end"}}>
                        <Typography
                        container
                        style={{
                            color: "#6c757d",
                            textAlign: "left",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bold",
                        }}
                        >
                        From :&nbsp;
                        <input
                            type="month"
                            style={{
                              borderRadius: 6,
                              padding: 10,
                              border: "2px solid #6c757d",
                              marginBottom: 8,
                              outline: 0,
                              height: 40,
                            }}
                            onChange={handleDurationChange}
                            name="from"
                            value={fromDate}
                            min={"2021-08"}
                            max={
                            new Date().getFullYear().toString() +
                            "-" +
                            currentMonth.toString()
                            }
                        />
                        &nbsp; To &nbsp;
                        <input
                            type="month"
                            style={{
                              borderRadius: 6,
                              padding: 10,
                              border: "2px solid #6c757d",
                              marginBottom: 8,
                              outline: 0,
                              height: 40,
                            }}
                            onChange={handleDurationChange}
                            name="to"
                            value={ToDate}
                            min={fromDate}
                            max={
                            new Date().getFullYear().toString() +
                            "-" +
                            currentMonth.toString()
                            }
                        />
                        </Typography>
                    <button
                        style={{
                          backgroundColor:fromDate==="" || ToDate===""?"#c8c8c8": "#2c60ab",
                          border: "none",
                          color: "#fff",
                          marginLeft: 10,
                          padding: 6,
                          borderRadius: 5,
                          cursor:fromDate==="" || ToDate===""?"none": "pointer",
                          fontSize: 14,
                          paddingLeft: 10,
                          paddingRight: 10,
                          height: "40px",
                        }}
                        disabled={fromDate==="" || ToDate===""}
                        onClick={loadInitialData}
                    >
                        Fetch Data
                    </button>
                    <button
                        style={{
                          backgroundColor:"#2c60ab",
                          border: "none",
                          color: "#fff",
                          marginLeft: 10,
                          padding: 6,
                          borderRadius: 5,
                          cursor: "pointer",
                          fontSize: 14,
                          paddingLeft: 10,
                          paddingRight: 10,
                          height: "40px",
                        }}
    
                        onClick={handleResetData}
                    >
                        <RestartAlt />
                        Reset
                    </button>
                    </Grid> */}
                  <Grid
                    container
                    item
                    xs={6}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      class="card"
                      style={{
                        height: 35,
                        boxShadow: "0 3px 6px #7D858D",
                        width: "127px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateInitialQuickView());
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-10px",
                            textAlign: "center",
                          }}
                        >
                          <PreviewRounded
                            style={{ color: "#ffffff", fontSize: 15 }}
                          />
                          &nbsp;QuickView
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {/* SLA / Beyond SLA complaints */}
                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-start", marginTop: 20 }}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card
                      style={{
                        padding: 5,
                        height: "75vh",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        {/* <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            SLA / Beyond SLA complaints -&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {slaGraphData?.complaintStatus}
                            </span>
                          </Typography>
                        </Grid> */}
                        {SlaWiseFromDate === "" || SlaWiseToDate === "" ? (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Fetched from Current Month&nbsp;(
                            {monthName(currentMonth)})
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Duration&nbsp;from{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SlaWiseFromDate.split("-")[1])}
                              &nbsp;{SlaWiseFromDate.split("-")[0]}
                            </span>{" "}
                            to{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SlaWiseToDate.split("-")[1])}
                              &nbsp;{SlaWiseToDate.split("-")[0]}
                            </span>
                          </Typography>
                        )}
                        <Grid>
                          {/* <button
                            onClick={() => {
                              setgraphType("SlaWise");
                              handleOpenSLA();
                            }}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Choose Sector
                            <Apartment
                              style={{ marginLeft: 6, fontSize: 16 }}
                            />
                          </button> */}
                          <button
                            onClick={handleThreeCsv}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Download Report
                            <BarChart style={{ marginLeft: 6, fontSize: 16 }} />
                          </button>
                          {/* <IconButton
                      aria-controls={
                        openThree ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openThree ? "true" : undefined}
                      onClick={handleClickThree}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElThree}
                      open={openThree}
                      onClose={handleCloseThree}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleCloseThree}>
                        Download Image
                      </MenuItem>
                      <MenuItem onClick={handleThreeCsv}>
                        Download Report
                      </MenuItem>
                    </Menu> */}
                        </Grid>
                      </Grid>
                      <CardContent style={{ marginTop: 15 }}>
                        {mixedComplaintsGraphData?.length === 0 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 22,
                                fontFamily: "Montserrat, sans-serif",
                                color: "#6c757d",
                              }}
                            >
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <MixedComplaintsGraph
                            data={mixedComplaintsGraphData}
                          />
                        )}
                      </CardContent>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <Dialog
                            disableEscapeKeyDown
                            open={openSLA}
                            onClose={(event) => {
                              seterrorMessageSectorWiseGraph("");
                              handleCloseSLA(event, "backdropClick");
                            }}
                          >
                            <DialogTitle>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  display: "flex",
                                }}
                              >
                                Please select a time Period first
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    container
                                    style={{
                                      color: "#6c757d",
                                      textAlign: "left",
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                    }}
                                  >
                                    From :&nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="from"
                                      value={
                                        SlaWiseFromDate
                                          ? SlaWiseFromDate
                                          : "2021-08"
                                      }
                                      min={"2021-08"}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                    &nbsp; To &nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="to"
                                      min={
                                        SlaWiseFromDate
                                          ? SlaWiseFromDate
                                          : "2021-08"
                                      }
                                      value={SlaWiseToDate}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </DialogTitle>
                            <DialogContent>
                              <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        Select Complaint Status :
                                      </Typography>
                                      <Select
                                        label="Choose Sector"
                                        onChange={handleChangeSectorSla}
                                        value={slaGraphData.complaintStatus}
                                        MenuProps={MenuProps}
                                        name="complaintStatus"
                                        style={{
                                          color: "#212529",
                                          backgroundColor: "#fff",
                                          fontWeight: "bold",
                                          borderRadius: 6,
                                          width: "140px",
                                          height: 30,
                                          fontSize: "13px",
                                          padding: 8,
                                          paddingLeft: 6,
                                          marginLeft: 8,
                                          border: "1px solid #6c757d",
                                        }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="assigned">
                                          Pending
                                        </MenuItem>
                                        <MenuItem value="resolved">
                                          Resolved
                                        </MenuItem>
                                        <MenuItem value="closed">
                                          Closed
                                        </MenuItem>
                                        <MenuItem value="cancel">
                                          Cancelled
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid>
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          border: "1px solid #000",
                                          borderTopLeftRadius: 10,
                                          borderTopRightRadius: 10,
                                          backgroundColor: "#2f45c5",
                                          marginTop: 15,
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Select Sector
                                          <Checkbox
                                            color="primary"
                                            style={{ color: "#fff" }}
                                            checked={
                                              sectors?.length ===
                                              selectedSectorsSlaForGraph?.length
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                sectors?.map((row) => {
                                                  if (
                                                    !selectedSectorsSlaForGraph.includes(
                                                      row
                                                    )
                                                  ) {
                                                    selectedSectorsSlaForGraph.push(
                                                      row
                                                    );
                                                  }
                                                  setselectedSectorsSlaForGraph(
                                                    [
                                                      ...selectedSectorsSlaForGraph,
                                                    ]
                                                  );
                                                });
                                              } else {
                                                setselectedSectorsSlaForGraph(
                                                  []
                                                );
                                              }
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                            marginTop: 17,
                                          }}
                                        >
                                          Sector Name
                                        </Typography>
                                      </Grid>
                                      <TableContainer
                                        component={Paper}
                                        style={{
                                          borderRadius: 10,
                                          marginRight: 10,
                                          height: 450,
                                        }}
                                      >
                                        <Table
                                          style={{ height: "max-content" }}
                                        >
                                          <TableBody style={{ padding: 2 }}>
                                            {sectors?.map((row) => {
                                              return (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      color="primary"
                                                      checked={selectedSectorsSlaForGraph.includes(
                                                        row
                                                      )}
                                                      name="sectors"
                                                      key={row}
                                                      onChange={() => {
                                                        if (
                                                          selectedSectorsSlaForGraph.includes(
                                                            row
                                                          )
                                                        ) {
                                                          selectedSectorsSlaForGraph.splice(
                                                            selectedSectorsSlaForGraph.indexOf(
                                                              row
                                                            ),
                                                            1
                                                          );
                                                          setselectedSectorsSlaForGraph(
                                                            [
                                                              ...selectedSectorsSlaForGraph,
                                                            ]
                                                          );
                                                        } else {
                                                          selectedSectorsSlaForGraph.push(
                                                            row
                                                          );
                                                          setselectedSectorsSlaForGraph(
                                                            [
                                                              ...selectedSectorsSlaForGraph,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                      color: "black",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.textname
                                                      }
                                                    >
                                                      {row}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </DialogContent>
                            {errorMessageSectorWiseGraph && (
                              <Typography
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: 14,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Error style={{ color: "red", fontSize: 20 }} />{" "}
                                {errorMessageSectorWiseGraph}
                              </Typography>
                            )}
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setOpenSLA(false);
                                  seterrorMessageSectorWiseGraph("");
                                  setgraphType("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleCloseSLA}>Ok</Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <AddComplaint
            addComplaintModal={addComplaintModal}
            setaddComplaintModal={setaddComplaintModal}
          />
          {
            <Modal open={downloadCsv}>
              <Paper
                style={{ backgroundColor: "#fff", width: 500 }}
                className={classes.deleteModal}
              >
                {csvData?.length > 0 && (
                  <>
                    <Grid container style={{ justifyContent: "center" }}>
                      <img
                        style={{
                          cursor: "pointer",
                          height: 100,
                          width: 100,
                          marginLeft: 5,
                          marginRight: 10,
                          marginTop: -50,
                          borderTopRightRadius: 50,
                          borderTopLeftRadius: 50,
                        }}
                        src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif"
                        alt="Sociohood"
                      />
                    </Grid>

                    <Typography
                      style={{
                        fontSize: 23,
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Success !
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        color: "#6c757d",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: 6,
                      }}
                    >
                      Data is successfully fetched and ready for download
                    </Typography>
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    >
                      <button
                        className={classes.cancelButton}
                        onClick={() => {
                          setDownloadCsv(false);
                        }}
                      >
                        Cancel
                        <Close style={{ marginLeft: 6, fontSize: 16 }} />
                      </button>
                      <ExportJsonCsv
                        fileTitle={
                          authState.user?.name +
                          `(${authState.user?.email})` +
                          "-" +
                          "Fy Complaints Data"
                        }
                        headers={headers}
                        items={csvData}
                        onClick={() => {
                          setDownloadCsv(false);
                          csvData([]);
                        }}
                        className={classes.successButton}
                        style={{ marginLeft: 24 }}
                      >
                        Download Report
                        <CloudDownloadOutlined style={{ marginLeft: 6 }} />
                      </ExportJsonCsv>
                    </Grid>
                  </>
                )}
                {csvData?.length === 0 && (
                  <>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        color: "#6c757d",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: 6,
                      }}
                    >
                      No data for Download
                    </Typography>
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    >
                      <button
                        style={{ width: "50%" }}
                        className={classes.cancelButton}
                        onClick={() => {
                          setDownloadCsv(false);
                        }}
                      >
                        Close
                        <Close style={{ marginLeft: 6, fontSize: 16 }} />
                      </button>
                    </Grid>
                  </>
                )}
              </Paper>
            </Modal>
          }
        </main>
      </div>
      {/* <Modal open={isLoading}>
        <Paper
          style={{ backgroundColor: "#fff", width: 20 }}
          className={classes.deleteModal}
        > */}
      {isLoading && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined style={{ fontSize: 60, color: "#03045E" }} />
          <Typography
            style={{
              color: "#03045E",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -14,
              fontWeight: "bold",
            }}
          >
            Please wait....
          </Typography>
        </Grid>
      )}

      {/* </Paper> */}
      {/* </Modal> */}
    </>
  );
}

export default MixedComplaintsGraphDV;
