import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fff",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  moreDetails: {
    backgroundColor: "#EFAF0B",
    padding: 20,
    marginTop: 40,
    borderRadius: 6,
  },
  addButton: {
    borderRadius: 5,
    padding: 7,
    fontSize: 13,
  },
  text: {
    color: "#212529",
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
      color: "#212529",
      fontWeight: 600,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minHeight: "100vh",
  },
  appHover: {
    "&:hover": {
      backgroundColor: "#a3c2e6",
      cursor: "pointer",
    },
  },
  tableContainer: {
    borderRadius: 10,
    overflow: "none",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "60vw",
    borderRadius: 6,
  },
}));
