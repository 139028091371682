import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import { useNavigate, useParams } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Pagination, Typography } from "@mui/material";
import {
  navigateToComplaintCategory,
  navigateToComplaintSubCategory,
} from "../../../../Navigation/Navigations";
import CategoryIcon from "@mui/icons-material/Category";
import { AuthContext } from "../../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import { ArrowLeft } from "@material-ui/icons";
import { toast } from "react-toastify";
import SubSubCategoryTable from "./SubSubCategoryTable";
import { LoadingOutlined } from "@ant-design/icons";
import AddSubSubCategory from "./AddSubSubCategory";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import * as api from "../../../../api/index";
import { resolveErrorMessage } from "../../../../services/service";

function ComplaintSubSubCategory() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const { id, categoryName, subcategoryName, subcategoryId } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState("30");
  const [count, setCount] = useState(0);
  const [loader, setloader] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [allSubSubCategory, setallSubSubCategory] = useState([]);
  const [addSubSubCategory, setaddSubSubCategory] = useState(false);
  const [subSubCategoryEdit, setsubSubCategoryEdit] = useState(false);
  const [subSubCategoryData, setsubSubCategoryData] = useState([]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const getComplaintSubSubCategory = async (search) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        filters: {
          society: fetchSocietyId(),
          searchKey: search,
          subCategoryId: subcategoryId,
          isDeleted: false,
        },
        page: page,
        limit: pageLimit,
      };
      const res = await api.getSubSubComplaintCategory(sendData);
      if (res?.data?.success) {
        setCount(res.data.subSubCategory?.totalCounts);
        setallSubSubCategory(res.data?.subSubCategory?.data);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };
  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        getComplaintSubSubCategory(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      getComplaintSubSubCategory("");
    }
  }, [search, 500, page]);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Category"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(0.2px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint{" "}
                    <span
                      onClick={() => {
                        navigate(navigateToComplaintCategory());
                      }}
                      style={{ cursor: "pointer" }}
                    >{` > Category `}</span>{" "}
                    <span
                      onClick={() => {
                        navigate(
                          navigateToComplaintSubCategory({
                            categoryId: id,
                            categoryName: categoryName,
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >{` >  Subcategory `}</span>{" "}
                    <span
                      style={{ color: "#000" }}
                    >{` > Sub-Subcategory `}</span>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "200px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setaddSubSubCategory(true);
                          }}
                        >
                          <CategoryIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Sub-Subcategory
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(
                        navigateToComplaintSubCategory({
                          categoryId: id,
                          categoryName: categoryName,
                        })
                      );
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <ArrowLeft />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 30, justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 12,
                  }}
                >
                  Sub Category Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {subcategoryName}
                  </span>
                </Typography>
                <div
                  class="box"
                  style={{
                    minWidth: "250px",
                    marginLeft: 10,
                    border: "1px solid #E1E1E1",
                    borderRadius: 10,
                  }}
                >
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                    type="text"
                    placeholder="search..."
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          getComplaintSubSubCategory();
                        }
                      }
                    }}
                  />
                  <div className="searchIcon">
                    <SearchIcon
                      onClick={() => {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          //   getComplaintSubCategory();
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
              {allSubSubCategory?.length > 0 && (
                <Grid container style={{ marginTop: "12px" }}>
                  <SubSubCategoryTable
                    getComplaintSubSubCategory={getComplaintSubSubCategory}
                    allSubSubCategory={allSubSubCategory}
                    setsubSubCategoryData={setsubSubCategoryData}
                    setsubSubCategoryEdit={setsubSubCategoryEdit}
                    page={page}
                  />
                </Grid>
              )}

              {allSubSubCategory?.length === 0 && !loader && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: "12px" }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Complaint Sub-Subcategory
                  </Typography>
                </Grid>
              )}
              {loader && (
                <Typography
                  style={{
                    marginTop: "15px",
                    textAlign: "center",
                    fontSize: 15,
                    color: "#2f45c5",
                  }}
                >
                  page is loading.... <LoadingOutlined />
                </Typography>
              )}
              {allSubSubCategory?.length > 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    // count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {(addSubSubCategory || subSubCategoryEdit) && (
        <AddSubSubCategory
          setaddSubSubCategory={setaddSubSubCategory}
          addSubSubCategory={addSubSubCategory}
          getComplaintSubSubCategory={getComplaintSubSubCategory}
          subSubCategoryEdit={subSubCategoryEdit}
          setsubSubCategoryEdit={setsubSubCategoryEdit}
          subSubCategoryData={subSubCategoryData}
        />
      )}
    </div>
  );
}

export default ComplaintSubSubCategory;
