import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import AllSettings from "../../Components/GateManagement/AllSettings/AllSettings";

const SettingRoutes = {
  path: "/gatemanagement",
  element: <NavigationDrawer />,
  children: [
    {
      path: "settings",
      element: <AllSettings />,
    },
  ],
};

export default SettingRoutes;