import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    /* backgroundColor: "#f6f8fc", */
    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
  progressbar1: {
    backgroundColor: "#e9ecef",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#2f45c5",
    },
  },
  progressbar2: {
    backgroundColor: "#e9ecef",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#023e8a",
    },
  },
  progressbar3: {
    backgroundColor: "#e9ecef",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#004b23",
    },
  },
  progressbar4: {
    backgroundColor: "#e9ecef",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#ff9500",
    },
  },
  progressbar5: {
    backgroundColor: "#e9ecef",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#941b0c",
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 10,
    outline: 0,
    width: 580,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  successButton:{
    backgroundColor: "#fff",
    border: "2px solid #179917",
    color: "#179917",
    marginRight: 2,
    padding: 6,
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontWeight:400,
    "&:hover": {
      backgroundColor: "#179917",
      color: "#fff",
    },
  },
  cancelButton:{
    backgroundColor: "#fff",
    border: "2px solid #c94836",
    color: "#c94836",
    marginRight: 2,
    padding: 6,
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontWeight:400,
    "&:hover": {
      backgroundColor: "#c94836",
      color: "#fff",
    },
  },
  buttonSize: {
    maxWidth: 160,
    maxHeight: 50,
    borderRadius: 5,
    fontSize: 11,
  },
  tableContainer: {
    borderRadius: 10,
    margin: "15px 0px",
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "0.67rem",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  printtableHeaderCell: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  smallModal: {
    position: "absolute",
    top: "50%",
    left: "76%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
  successTableModal:{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 10,
    outline: 0,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));


