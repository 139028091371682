import React, { useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import * as api from "../../../api/index";
import useStyles from "./styles";
import { Typography, Grid, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../contextAPI/context";
import { DomainAddOutlined } from "@mui/icons-material";
import AddType from "./AddType";
import SocietyTypeTable from "./SocietyTypeTable";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../../services/service";
import {
  handlePropertyName,
  handleFlatName,
} from "../../../contextAPI/NamingConvention";

const SocietyType = () => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [societyType, setsocietyType] = useState("Property");
  const [addTypeModal, setaddTypeModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [searchedType, setsearchedType] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Type"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    handleSearchType();
  }, [societyType, debouncedSearch]);

  const handleSearchType = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const res = await api.serachType({
        key: search,
        type: societyType.toLowerCase(),
        societyId: fetchSocietyId(),
      });
      if (res?.data?.success) {
        setsearchedType(res?.data?.Type);

        setloader(false);
      }
    } catch (error) {
      console.log(error);
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    const handleDebounce = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handleDebounce);
    };
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Society Type (
                    {societyType === "Property"
                      ? handlePropertyName()
                      : handleFlatName()}
                    )
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                      }}
                    >
                      Switch Type :
                      <Select
                        value={societyType}
                        onChange={(event) => {
                          setPage(0);
                          setSearch("");
                          setsocietyType(event.target.value);
                        }}
                        style={{
                          color: "#212529",
                          backgroundColor: "#fff",
                          fontWeight: "bold",
                          borderRadius: 6,
                          width: "140px",
                          height: 30,
                          fontSize: "13px",
                          padding: 8,
                          paddingLeft: 6,
                          marginLeft: 8,
                          border: "1px solid #6c757d",
                          textAlign: "center",
                        }}
                      >
                        <MenuItem selected value="Property">
                          {handlePropertyName()}
                        </MenuItem>
                        <MenuItem selected value="Flat">
                          {handleFlatName()}
                        </MenuItem>
                      </Select>
                    </Typography>
                    {isReadWrite && (
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "190px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginRight: 15,
                          marginLeft: 10,
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              setaddTypeModal(true);
                            }}
                          >
                            <DomainAddOutlined
                              style={{
                                marginRight: 10,
                                fontSize: "15px",
                              }}
                            />
                            Add{" "}
                            {societyType === "Property"
                              ? handlePropertyName()
                              : handleFlatName()}{" "}
                            Type
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 10 }}
              >
                <div class="box">
                  <input
                    type="text"
                    placeholder="search by name"
                    onChange={(e) => {
                      setPage(0);
                      setSearch(e.target.value);
                    }}
                    value={search}
                  />
                  <div style={{ marginRight: 10 }} className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </Grid>

              <Grid container>
                <SocietyTypeTable
                  typeData={searchedType}
                  societyType={societyType}
                  loader={loader}
                  page={page}
                  setPage={setPage}
                />
              </Grid>

              <AddType
                addTypeModal={addTypeModal}
                setaddTypeModal={setaddTypeModal}
                societyType={societyType}
                handleSearchType={handleSearchType}
              />
            </div>
          </div>
        </Grid>
      </main>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading{" "}
            {societyType === "Property"
              ? handlePropertyName()
              : handleFlatName()}{" "}
            Types...
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default SocietyType;
