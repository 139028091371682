import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 10,
    margin: "5px 5px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "17px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));
