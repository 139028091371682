import React, { useState } from "react";
import { Grid, Typography, Modal, Button, Paper } from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tag } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import AddItems from "./AddItems";
import { QuestionMark } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import * as api from "../../../../../api/index";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../../services/service";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import { ErrorOutline } from "@material-ui/icons";

function ItemsTable(props) {
  const classes = useStyles();
  const {
    loader,
    setloader,
    allItems,
    handleItems,
    errorMsg,
    seterrorMsg,
    page,
  } = props;
  const [deleteItem, setDeleteItems] = useState(false);
  const [isEdited, setisEdited] = useState(false);
  const [editItemData, setEditItemData] = useState([]);

  const confirmModalDelete = (
    <div>
      <Modal open={deleteItem}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.confirmModal}
        >
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 19,
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: 7,
              textTransform: "uppercase",
            }}
          >
            ARE YOU SURE YOU WANT TO DELETE THIS ITEM ?
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
          </Typography>

          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            A confirmation message will appear when this process is complete.You
            can confirm by clicking below.
          </Typography>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          {loader && (
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              Please wait ... &nbsp;
              <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
            </Typography>
          )}
          <Grid container style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                setDeleteItems(false);
              }}
              style={{
                backgroundColor: "#C60C30",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                handleDeleteItems();
              }}
              style={{
                backgroundColor: "#1e2082",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );

  const handleDeleteItems = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        itemId: editItemData?._id,
        societyId: fetchSocietyId(),
      };
      const res = await api.deleteJmrItems(sendData);
      if (res?.data?.success) {
        setDeleteItems(false);
        toast.success("Items Number Deleted ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleItems();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Item Code
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Item Description
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                UOM
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                BaseRate
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Rate
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                %Escl
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Final Rate
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allItems?.map((itm, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 50 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.itemCode}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.itemDescription}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.uom}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.basePrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.basePrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {itm?.escalation}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(itm?.basePrice * itm?.escalation) / 100 + itm?.basePrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Tag
                      color="blue"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setisEdited(true);
                        setEditItemData(itm);
                      }}
                      icon={<FormOutlined style={{ fontSize: "20px" }} />}
                    >
                      EDIT
                    </Tag>
                    <Tag
                      style={{ cursor: "pointer" }}
                      color="red"
                      onClick={() => {
                        setDeleteItems(true);
                        setEditItemData(itm);
                      }}
                      icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                    >
                      DELETE
                    </Tag>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!loader && allItems?.length === 0 && (
        <Typography
          style={{
            color: "#6c757d",
            textAlign: "center",
            fontSize: 22,
            marginTop: 10,
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          No Item
        </Typography>
      )}
      {confirmModalDelete}
      <AddItems
        isEdited={isEdited}
        setisEdited={setisEdited}
        editItemData={editItemData}
        handleItems={handleItems}
        type="Edited"
      />
    </>
  );
}

export default ItemsTable;
