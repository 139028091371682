import React, { useState, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  Tooltip,
} from "@material-ui/core";
import useStyles from "./styles";
import {
  AddCircle,
  AddCircleOutline,
  ApartmentOutlined,
  Close,
  ErrorOutline,
} from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import * as api from "../../../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../../services/service";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contextAPI/context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  handlePropertyName,
  handleFloorName,
  handleFlatName,
} from "../../../../contextAPI/NamingConvention";

function AddProperty(props) {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const classes = useStyles();
  const {
    addPropertyModal,
    setaddPropertyModal,
    handleGetProperties,
    allPropertyTypes,
  } = props;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [propertyType, setpropertyType] = useState("Choose Property Type");
  const [propertyName, setpropertyName] = useState("");
  const [propertySector, setpropertySector] = useState("");
  const [propertySize, setpropertySize] = useState("");
  const [parkingNumber, setparkingNumber] = useState("");
  const [propertyCategory, setpropertyCategory] = useState("Select Category");
  const [propertyWaterTankCap, setpropertyWaterTankCap] = useState("");
  const [openFloorModal, setopenFloorModal] = useState(false);
  const [floorName, setfloorName] = useState("");
  const [floorNumber, setfloorNumber] = useState(1);
  const [errorMsg2, seterrorMsg2] = useState("");

  const defaultpropertyCategory = [
    "Tower",
    "Bungalow",
    // "Independent Houses",
    "Commercial",
  ];

  const [propertyFloors, setpropertyFloors] = useState([
    {
      floorName: "Ground Floor",
      floorNumber: 0,
    },
  ]);

  const handleAddFloor = () => {
    if (floorName === "" || floorNumber === 0) {
      seterrorMsg2("");
      seterrorMsg2("* fields are required");
    } else {
      seterrorMsg2("");
      let add = true;
      propertyFloors.map((flr) => {
        if (
          flr.floorName.toLowerCase() === floorName.toLowerCase() ||
          flr.floorNumber === floorNumber
        ) {
          add = false;
          seterrorMsg2("floor already exists");
        }
      });
      if (add) {
        propertyFloors.push({
          floorName: floorName,
          floorNumber: floorNumber,
        });
        setpropertyFloors([...propertyFloors]);
      }
    }
  };

  const handleRemoveFloor = (index) => {
    seterrorMsg2("");
    propertyFloors.splice(index);
    setpropertyFloors([...propertyFloors]);
  };

  const handleAddProperty = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      if (
        propertyCategory === "Select Category" ||
        propertyType === "Choose Property Type" ||
        propertyName === "" ||
        propertySector === "" ||
        propertySize === "" ||
        parkingNumber === "" ||
        propertyWaterTankCap === ""
      ) {
        setloader(false);
        seterrorMsg("All * fields are required");
      } else {
        let temp = [];
        propertyFloors?.map((flr) => {
          temp.push(flr.floorName);
        });
        const sendData = {
          adminId: user?._id,
          societyId: fetchSocietyId(),
          name: propertyName,
          category: propertyCategory.toLowerCase(),
          floors: temp,
          size: propertySize,
          waterTankCapacity: propertyWaterTankCap,
          sector: propertySector,
          type: propertyType,
        };

        const res = await api.createProperty(sendData);
        if (res?.data?.success) {
          handleGetProperties();
          toast.success(`${handleFlatName()} Added Successfully`);
          handleReset();
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  const handleReset = () => {
    seterrorMsg("");
    setaddPropertyModal(false);
    setloader(false);
    setpropertyType("Choose Property Type");
    setpropertyName("");
    setpropertySector("");
    setparkingNumber("");
    setpropertySize("");
    setpropertyCategory("Select Category");
    setpropertyWaterTankCap("");
  };

  const handleCloseDialog = () => {
    seterrorMsg2("");
    setopenFloorModal(false);
  };
  return (
    <>
      <Modal open={addPropertyModal}>
        <Paper
          className={classes.ModalView}
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            // width: "32vw",
            // maxHeight: "82vh",
            minWidth: "400px",
            maxWidth: "32vw",
          }}
        >
          <Grid
            container
            style={{ textAlign: "center", justifyContent: "space-between" }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Add {handlePropertyName()}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  handleReset();
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
          />
          {/* ADD PROPERTY FLOOR */}
          <Dialog
            open={openFloorModal}
            keepMounted
            onClose={handleCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Typography
                style={{
                  fontSize: 15,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                  textAlign: "center",
                  textDecoration: "underline",
                  marginBottom: 6,
                }}
              >
                Add {handleFloorName()}
              </Typography>
              <Grid container>
                <Typography
                  style={{
                    color: "#000",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "12px",
                    marginTop: 6,
                    width: "87px",
                  }}
                >
                  {handleFloorName()} Name :
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
                <input
                  type="text"
                  style={{
                    borderRadius: 5,
                    padding: 6,
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                    fontSize: "12px",
                    marginLeft: 5,
                    width: "184px",
                  }}
                  onChange={(event) => setfloorName(event.target.value)}
                  placeholder={`Enter ${handleFloorName()} Name`}
                  value={floorName}
                />
              </Grid>
              <Grid container>
                <Typography
                  style={{
                    color: "#000",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "12px",
                    marginTop: 6,
                    width: "88px",
                  }}
                >
                  {handleFloorName()} No. :
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
                <input
                  type="text"
                  style={{
                    borderRadius: 5,
                    padding: 6,
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                    fontSize: "12px",
                    marginLeft: 5,
                    width: "184px",
                  }}
                  onChange={(event) => setfloorNumber(event.target.value)}
                  placeholder={`Enter ${handleFloorName()} Number`}
                  value={floorNumber}
                  min={1}
                />
              </Grid>
              {errorMsg2 && (
                <Typography
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "13px",
                    marginBottom: 6,
                  }}
                >
                  <ErrorOutline style={{ marginRight: 3 }} />
                  {errorMsg2}
                </Typography>
              )}
              <Grid
                container
                style={{ justifyContent: "center", marginLeft: 20 }}
              >
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #6c757d",
                    width: "130px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        handleAddFloor();
                      }}
                    >
                      <AddCircleOutline
                        style={{
                          marginRight: 10,
                          fontSize: "20px",
                        }}
                      />
                      Add {handleFloorName()}
                    </p>
                  </div>
                </div>
              </Grid>
            </DialogTitle>

            <DialogContent dividers>
              <Typography
                style={{
                  fontSize: 15,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 6,
                  marginTop: -10,
                }}
              >
                <span style={{ textDecoration: "underline" }}>
                  Added {handleFloorName()}
                </span>{" "}
                ({propertyFloors?.length} {handleFloorName()} Added)
              </Typography>

              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    marginBottom: 5,
                  }}
                >
                  {handleFloorName()} Name
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    marginBottom: 5,
                    marginLeft: 30,
                  }}
                >
                  {handleFloorName()} Number
                </Typography>
              </Grid>
              <Grid>
                <List>
                  {propertyFloors?.map((flr, index) => {
                    return (
                      <ListItem>
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography style={{ fontSize: 15, marginLeft: -17 }}>
                            {index !== 0 && (
                              <Delete
                                title="remove floor"
                                style={{
                                  fontSize: "20px",
                                  color: "#2f45c5",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleRemoveFloor(index);
                                }}
                              />
                            )}
                            {flr?.floorName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 15,
                              textTransform: "capitalize",
                            }}
                          >
                            {flr?.floorNumber}
                          </Typography>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          <Grid container style={{ marginTop: 8, justifyContent: "center" }}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: 6,
                marginRight: 7,
                marginLeft: 8,
              }}
            >
              Select Category :
            </Typography>
            <Select
              value={propertyCategory}
              onChange={(event) => setpropertyCategory(event.target.value)}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 6,
                width: "180px",
                height: 37,
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                marginBottom: 10,
                border: "1px solid #6c757d",
                textAlign: "center",
              }}
            >
              <MenuItem
                selected
                value="Select Category"
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                Select Category
              </MenuItem>
              {defaultpropertyCategory?.map((struc) => {
                return (
                  <MenuItem
                    selected
                    value={struc}
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    &nbsp;{struc}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {propertyCategory === "Select Category" && (
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 5,
                textAlign: "center",
              }}
            >
              Please Select a Category first{" "}
            </Typography>
          )}
          <Grid
            style={{
              opacity: propertyCategory === "Select Category" ? 0.4 : "",
              pointerEvents:
                propertyCategory === "Select Category" ? "none" : "",
            }}
          >
            <Grid container style={{ marginLeft: -10 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handlePropertyName()} Type
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handlePropertyName()} Sector/Block
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <Select
                  value={propertyType}
                  onChange={(event) => setpropertyType(event.target.value)}
                  style={{
                    color: "#212529",
                    backgroundColor: "#fff",
                    fontWeight: "bold",
                    borderRadius: 10,
                    width: "98%",
                    height: 55,
                    fontSize: "13px",
                    padding: 8,
                    paddingLeft: 6,
                    marginBottom: 10,
                    border: "1px solid #6c757d",
                    textAlign: "center",
                  }}
                >
                  <MenuItem
                    selected
                    value="Choose Property Type"
                    style={{
                      color: "#6c757d",
                      fontFamily: "Montserrat,sans-serif",
                    }}
                  >
                    Choose {handlePropertyName()} Type
                  </MenuItem>
                  {allPropertyTypes?.map((type) => {
                    return (
                      <MenuItem
                        selected
                        value={type.name}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        &nbsp;{type.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setpropertySector(event.target.value)}
                  placeholder="Enter Number"
                  value={propertySector}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginLeft: -10 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handlePropertyName()} Name
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handlePropertyName()} Size
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setpropertyName(event.target.value)}
                  placeholder="Enter Name"
                  value={propertyName}
                />
              </Grid>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setpropertySize(event.target.value)}
                  placeholder="Enter Size in sqaure feet"
                  value={propertySize}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginLeft: -10 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  Parking Size
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  Watertank Capacity
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) =>
                    setpropertyWaterTankCap(event.target.value)
                  }
                  placeholder="Enter parking in sqaure feet"
                  value={propertyWaterTankCap}
                />
              </Grid>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setparkingNumber(event.target.value)}
                  placeholder="Enter capacity in ltr"
                  value={parkingNumber}
                />
              </Grid>
            </Grid>
          </Grid>

          <Typography
            style={{
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              fontSize: "14px",
              marginBottom: 5,
              textAlign: "center",
            }}
          >
            {handlePropertyName()} {handleFloorName()}
            <Tooltip title="Add or View Floors" placement="right" arrow>
              <AddCircle
                title="Permission Info"
                style={{
                  color: "#2f45c5",
                  cursor: "pointer",
                  marginLeft: 10,
                  fontSize: 19,
                }}
                onClick={() => {
                  setopenFloorModal(true);
                }}
              />
            </Tooltip>
          </Typography>

          <Typography
            style={{
              fontSize: 15,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 6,
              marginTop: -5,
            }}
          >
            <span style={{ textDecoration: "underline" }}>
              Added {handleFloorName()}
            </span>{" "}
            ({propertyFloors?.length} {handleFloorName()} Added)
          </Typography>

          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
              }}
            >
              Sr. No.
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
              }}
            >
              {handleFloorName()} Name
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
              }}
            >
              {handleFloorName()} Number
            </Typography>
          </Grid>
          <Divider
            style={{
              marginTop: 0,

              backgroundColor: "#000",
            }}
          />
          <Grid style={{ overflow: "scroll", height: "140px" }}>
            <List>
              {propertyFloors?.map((flr, index) => {
                return (
                  <ListItem>
                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Typography
                        style={{
                          fontSize: 15,
                          textTransform: "capitalize",
                        }}
                      >
                        {index + 1}.
                      </Typography>
                      <Typography style={{ fontSize: 15, marginLeft: -17 }}>
                        {index !== 0 && (
                          <Delete
                            title="remove floor"
                            style={{
                              fontSize: "20px",
                              color: "#2f45c5",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleRemoveFloor(index);
                            }}
                          />
                        )}
                        {flr?.floorName}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 15,
                          textTransform: "capitalize",
                        }}
                      >
                        {flr?.floorNumber}
                      </Typography>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "160px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!loader) {
                      handleAddProperty();
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ApartmentOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? `Add ${handlePropertyName()}` : `Adding...`}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default AddProperty;
