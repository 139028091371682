import React, { useState, useEffect, useContext } from "react";
import { CssBaseline, Grid, Paper, Typography } from "@material-ui/core";
import useStyles from "../style";
import * as api from "../../../api/index";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { AddCircle } from "@material-ui/icons";
import AddEmergencyNumber from "./AddEmergencyNumber";
import EmergencyNumberTable from "./EmergencyNumberTable";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import Loading from "../../../contextAPI/Loading";

function EmergencyNumber() {
  const classes = useStyles();
  const [allEmergencyNumber, setAllEmergencyNumber] = useState([]);
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [searchKey, setsearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState("50");
  const [count, setcount] = useState(0);
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [addContactModal, setAddContactModal] = useState(false);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };
  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Emergency Number")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleGetEmegencyNumber = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        society: fetchSocietyId(),
        searchKey: searchKey,
        pageLimit,
        pageNumber: page,
      };
      const res = await api.getEmegencyNumber(sendData);
      if (res?.data?.success) {
        setAllEmergencyNumber(res?.data?.directory?.emergencyContacts);
        setcount(res?.data?.directory?.totalPage);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetEmegencyNumber();
  }, [searchKey, page]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    Emergency Number
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Emergency Number Page"
                        onClick={handleGetEmegencyNumber}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search by title"
                      onChange={(e) => {
                        setsearchKey(e.target.value);
                        setPage(1);
                      }}
                      value={searchKey}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #7D858D",
                        width: "150px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 8,
                        marginTop: 7,
                      }}
                      onClick={() => {
                        setAddContactModal(true);
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <AddCircle
                            style={{ marginTop: -3, fontSize: "15px" }}
                          />
                          &nbsp;&nbsp; Add Contact
                        </p>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <EmergencyNumberTable
                  allEmergencyNumber={allEmergencyNumber}
                  loader={loader}
                  setloader={setloader}
                  page={page}
                  handleGetEmegencyNumber={handleGetEmegencyNumber}
                  isReadWrite={isReadWrite}
                />
              </Grid>
              {allEmergencyNumber?.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddEmergencyNumber
          addContactModal={addContactModal}
          setAddContactModal={setAddContactModal}
          handleGetEmegencyNumber={handleGetEmegencyNumber}
        />
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default EmergencyNumber;
