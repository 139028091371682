import { CssBaseline, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./style";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import ConfirmModal from "../../../GlobalComponents/Modals/ConfirmModal";

function ConfigurationSetting() {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const [loading, setloading] = useState(false);
  const [isPlant, setisPlant] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [complainerCount, setcomplainerCount] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [isDisabled, setisDisabled] = useState(true);
  const [socityDetails, setsocityDetails] = useState([]);

  const handleTogglePlant = () => {
    setisPlant(socityDetails?.isPlant ? false : true);
    setIsOpen(true);
  };

  const getSocietyDetails = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);

    try {
      const res = await api.fetchSocietyDetailsById({
        params: { societyId: localStorage.getItem("societyId") },
      });
      if (res?.data?.success) {
        setsocityDetails(res.data.society);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    getSocietyDetails();
  }, []);

  const handleEditPlant = async () => {
    seterrorMsg("");
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);

    try {
      const sendMeetingData = {
        societyId: fetchSocietyId(),
        isPlant: isPlant,
      };
      const res = await api.editConfiguration(sendMeetingData);
      if (res?.data?.success) {
        handleReset();
        getSocietyDetails();
        toast.success(
          `${
            isPlant
              ? "Society Changed to plant successfully"
              : "Plant Changed to society successfully"
          }`,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          }
        );
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleEditNotifyCount = async () => {
    seterrorMsg("");
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);

    try {
      const sendMeetingData = {
        societyId: fetchSocietyId(),
        customerNotifyLimit: complainerCount,
      };
      const res = await api.editConfiguration(sendMeetingData);
      if (res?.data?.success) {
        setisDisabled(true);
        getSocietyDetails();
        toast.success(`Notify Count Changed successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleReset = () => {
    setIsOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={classes.content}
        style={{
          filter: loader ? "blur(1px)" : "",
          overflow: loader ? "hidden" : "auto",
        }}
      >
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            sm={12}
            style={{
              height: "100vh",
              pointerEvents: loader ? "none" : "",
            }}
          >
            <Paper
              style={{
                padding: 12,
                boxShadow: "0px 0px 0px",
                minHeight: "100vh",
              }}
            >
              <Grid container className="mt-2">
                <span className="page-header">
                  Configuration
                  {!loader ? (
                    <RefreshIcon
                      title="refresh Poll Page"
                      onClick={() => {
                        getSocietyDetails();
                      }}
                      style={{
                        marginLeft: 5,
                        marginTop: -4,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="laoding..."
                      style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                    />
                  )}
                </span>
              </Grid>

              <Grid
                container
                xs={12}
                lg={12}
                sm={12}
                className="mt-2 configuration-setting"
              >
                <div className="col-sm-12 d-flex flex-column gap-4 p-4">
                  <div className="col-sm-12 d-flex">
                    <div className="fs-4 mt-4">Plant :</div>
                    <div>
                      <span
                        className="d-flex align-items-center justify-content-center position-relative ms-3"
                        style={{
                          width: "140px",
                          height: "80px",
                          backgroundColor: "rgba(247, 247, 247,0.7)",
                          borderRadius: "20px",
                        }}
                      >
                        <div className="form-check form-switch d-flex justify-content-center">
                          <input
                            className={`form-check-input btn ${
                              socityDetails?.isPlant
                                ? "bg-primary"
                                : "bg-danger"
                            }`}
                            type="checkbox"
                            role="switch"
                            id="SwitchCheckDefault"
                            //   disabled={!isModuleReadWriteAccessForSetting}
                            checked={socityDetails?.isPlant}
                            onClick={() => handleTogglePlant()}
                          />
                        </div>
                      </span>
                    </div>
                    <div className="d-flex justify-content-center w-50 ms-2 mt-2">
                      <div
                        className="alert alert-info text-primary fs-7"
                        role="alert"
                        style={{ backgroundColor: "#EDFCFE" }}
                      >
                        <strong>Note:</strong>
                        {socityDetails?.isPlant
                          ? "Toggling this switch will change the plant to society"
                          : "Toggling this switch will change the society to plant"}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex mt-5">
                    <div className="fs-4 mt-3">
                      Maximum Notify Complainer Limit :
                    </div>

                    {isDisabled ? (
                      <span
                        className="d-flex align-items-center justify-content-center position-relative ms-3"
                        style={{
                          width: "140px",
                          height: "80px",
                          backgroundColor: "rgba(247, 247, 247,0.7)",
                          borderRadius: "20px",
                        }}
                      >
                        {socityDetails?.customerNotifyLimit}
                        <span
                          className="position-top-end-overflow bg-white"
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            borderRadius: "50%",
                          }}
                        >
                          <span
                            className="btn btn-sm btn-icon btn-rounded btn-outline-secondary"
                            style={{
                              borderRadius: "50%",
                              width: "36px",
                              height: "36px",
                            }}
                            onClick={() => {
                              setcomplainerCount(
                                socityDetails?.customerNotifyLimit
                              );
                              setisDisabled(false);
                            }}
                          >
                            <span className="feather-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </span>
                          </span>
                        </span>
                      </span>
                    ) : (
                      <>
                        <div className="configuration-frame d-flex align-items-center justify-content-center position-relative ms-2">
                          <input
                            className="form-control"
                            style={{
                              width: "300px",
                              height: "56px",
                              borderRadius: "14px",
                              border: "none",
                            }}
                            type="number"
                            //   disabled={!isModuleReadWriteAccessForSetting}
                            value={complainerCount}
                            onChange={(e) => {
                              setcomplainerCount(e.target.value);
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-center position-relative gap-3 ms-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            style={{ height: "35px" }}
                            onClick={() => {
                              setcomplainerCount("");
                              setisDisabled(true);
                            }}
                          >
                            Close
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            style={{ height: "35px" }}
                            onClick={() => handleEditNotifyCount()}
                          >
                            {loading ? (
                              <LoadingOutlined style={{ fontSize: "18px" }} />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </main>
      <ConfirmModal
        open={isOpen}
        setOpen={setIsOpen}
        text={`${
          isPlant
            ? "Are you sure you want to change this plant to society"
            : "Are you sure you want to change this society to plant"
        }`}
        loader={loading}
        handleReset={handleReset}
        handleConfirm={handleEditPlant}
      />
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading...
          </Typography>
        </Grid>
      )}
    </div>
  );
}

export default ConfigurationSetting;
