import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = () => {
  const barChartData = {
    labels: [
      "Recieved",
      "Closed",
      "Pending",
      "Pending With SLA",
      "Pending Beyond SLA",
    ],
    datasets: [
      {
        data: [8137119, 9431691, 10266674, 1216410, 1371390],
        borderColor: "#3333ff",
        label:["Recieved"],
      
        backgroundColor: ["#0bab64","#990000","#ec9f05","#1fd1f9","#7f53ac"],
        fill: true,
      },
    ],
  };

  const barChart = (
    <Bar
      type="bar"
      width={130}
      height={50}
      options={{
        plugins: {
          title: {
            display: true,
            text: "Barchart for Monthly Reports",
          },
          legend: {
            display: true,
            position: "bottom",
          },
        },
      }}
      data={barChartData}
    />
  );
  return barChart;
};

export default BarChart;
