import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import { Autorenew, CheckCircleOutline } from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ArrowRightAlt } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { handlePropertyName } from "../../../../contextAPI/NamingConvention";
import { navigateToProperty } from "../../../../Navigation/Navigations";
import {
  fetchSocietyDetails,
  fetchSocietyId,
} from "../../../../contextAPI/fetchSociety";
import ConfirmModal from "../../../../contextAPI/ConfirmModal";
import {
  GETUSER,
  MEMBERTYPE,
  PERMISSIONMODULES,
  PLANT,
  SOCIETIES,
  SWITCHEDSOCIETYID,
  USERMODULESWITHPERSMISSIONS,
} from "../../../../contextAPI/actions/auth.actions";
import * as api from "../../../../api";
import { getAllPermissionModules } from "../../../../GlobalFunctions/GlobalFunctions";
import PageLaoder from "../../../../GlobalComponents/Loaders/PageLaoder";

function SocietyTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { societies, authDispatch } = props;
  const [isSocietySwitch, setisSocietySwitch] = useState(false);
  const [switchSociety, setswitchSociety] = useState([]);
  const [loader, setloader] = useState(false);
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

  const handleSwitchSociety = () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    localStorage.setItem("societyId", switchSociety?._id);
    authDispatch({
      type: SWITCHEDSOCIETYID,
      payload: switchSociety?._id,
    });
    verifyUser();
  };
  const verifyUser = async () => {
    try {
      const res = await api.getUser({ params: { token: refreshToken } });
      if (res?.data.user) {
        const allPermissionModules = await getAllPermissionModules({
          societyId: fetchSocietyId(),
          userId: res?.data.user?._id,
        });
        const isPlant = await fetchSocietyDetails("isPlant");
        authDispatch({
          type: GETUSER,
          payload: res.data,
        });
        authDispatch({
          type: USERMODULESWITHPERSMISSIONS,
          payload: allPermissionModules?.permissions,
        });
        let modulesNames = allPermissionModules?.permissions?.map((per) => {
          return per.moduleName;
        });
        let societies = res.data.user.societies.map((soc) => {
          return soc;
        });
        authDispatch({
          type: MEMBERTYPE,
          payload: allPermissionModules?.role,
        });
        authDispatch({
          type: PERMISSIONMODULES,
          payload: modulesNames ? modulesNames : [],
        });
        authDispatch({
          type: SOCIETIES,
          payload: societies,
        });
        authDispatch({
          type: PLANT,
          payload: isPlant,
        });
        setloader(false);
        setisSocietySwitch(false);
        toast.success(`Successfully Switched into ${switchSociety?.name}`);
      }
    } catch (error) {
      setloader(false);
      setisSocietySwitch(false);
    }
  };

  const handleClose = () => {
    setisSocietySwitch(false);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Society Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                City
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Unique ID
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {handlePropertyName()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {societies?.map((society, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {society?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.city}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.societyUniqueId}
                    <CopyToClipboard
                      title="Copy Unique ID"
                      text={society?.societyUniqueId}
                      onCopy={() => {
                        toast.success("Copy to Clipboard", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: false,
                          closeOnClick: true,
                        });
                      }}
                    >
                      <ContentCopy
                        style={{
                          fontSize: 17,
                          marginLeft: 6,
                          color: "#000",
                          cursor: "pointer",
                        }}
                      />
                    </CopyToClipboard>
                  </TableCell>
                  {fetchSocietyId() === society?._id ? (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <CheckCircleOutline style={{ color: "rgb(36 202 38)" }} />{" "}
                      Activated
                    </TableCell>
                  ) : (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Typography
                        onClick={() => {
                          setloader(false);
                          setswitchSociety(society);
                          setisSocietySwitch(true);
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#2f3aa3",
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        <Autorenew />
                        Switch{" "}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (fetchSocietyId() === society?._id) {
                        navigate(
                          navigateToProperty({ societyName: society?.name })
                        );
                      }
                    }}
                  >
                    {society?.properties?.length} {handlePropertyName()}
                    {fetchSocietyId() === society?._id && (
                      <ArrowRightAlt style={{ fontSize: 25 }} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={`ARE YOU SURE YOU WANT TO SWITCH INTO ${switchSociety?.name}`}
        description="A confirmation message will appear when this process is
        complete.You can confirm by clicking below."
        loader={loader}
        handleConfirmation={handleSwitchSociety}
        rejectConfirmation={handleClose}
        isOpen={isSocietySwitch}
        errorMsg={""}
      />
      {loader && <PageLaoder />}
    </>
  );
}

export default SocietyTable;
