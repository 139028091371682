import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import SocietyRequest from "../../Components/Settings/SocietyManagement/Society/SocietyRequest/SocietyRequest";

const SocietyRequestsRoutes = {
  path: "/settings",
  element: <NavigationDrawer />,
  children: [
    {
      path: "society/requests",
      element: <SocietyRequest />,
    },
  
  ],
};

export default SocietyRequestsRoutes;
