import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import useStyles from "../../../styles";
import { Typography, Grid } from "@material-ui/core";
import { Circle } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import moment from "moment";
import { BASE_URL } from "../../../../../utils/base";
import { handleFlatName } from "../../../../../contextAPI/NamingConvention";
import { AuthContext } from "../../../../../contextAPI/context";

function HistoryTable(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;
  const { historyData, page } = props;

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Sr. No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Email
            </TableCell>

            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Phone
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Unique Id
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {/* Flat Name/Number */}
              {handleFlatName()} Name/Number
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Action By
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyData?.map((user, index) => {
            return (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  <Grid container style={{ justifyContent: "center" }}>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      alt={user?.userName}
                      src={
                        user?.userImage
                          ? `${BASE_URL}/user/${user?.userImage}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      className={classes.avatar}
                    />
                  </Grid>
                  <Typography className={classes.textname}>
                    {user?.userName}
                    <span
                      style={{
                        width: `${plant ? "67px" : "50px"}`,
                        padding: 5,
                        textTransform: "capitalize",
                        marginLeft: 5,
                      }}
                      class="badge bg-primary"
                    >
                      {plant
                        ? "Employee"
                        : user?.userRole
                        ? user?.userRole
                        : "-"}
                    </span>
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {user?.userEmail}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {user?.userPhone}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {user?.userUniqueId}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {user?.flat?.name} / {user?.flat?.number}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {user?.actionBy ? (
                    <>
                      {user?.actionBy?.name
                        ? user?.actionBy?.name
                        : "No Action Taken"}
                      <span
                        style={{
                          width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class="badge bg-primary"
                      >
                        {user?.actionBy?.role ? user?.actionBy?.role : "-"}
                      </span>
                      <br></br>{" "}
                      <Typography style={{ marginTop: 3, fontSize: 13 }}>
                        {" "}
                        {moment(user?.actionTime).format("DD/MM/YYYY - h:mm A")}
                      </Typography>
                    </>
                  ) : (
                    moment(user?.actionTime).format("DD/MM/YYYY - h:mm A")
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {user?.status === "approved" && (
                    <span
                      style={{
                        color: "rgb(36 202 38)",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      USER APPROVED
                    </span>
                  )}
                  {user?.status === "rejected" && (
                    <span
                      style={{
                        color: "rgb(239 13 39)",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      USER REJECTED
                    </span>
                  )}
                  {user?.status === "pending" && (
                    <span
                      style={{
                        color: "rgb(202 133 36)",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      USER REQUESTED
                    </span>
                  )}
                  {user?.status === "send for approval" && (
                    <span
                      style={{
                        color: "#2f45c5",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      SEND FOR APPROVAL
                    </span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default HistoryTable;
