import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fff",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    /* backgroundColor: "#fff", */
    minHeight: "100vh",
  },
  content2: {
    flexGrow: 1,
    padding: theme.spacing(2),
    /* backgroundColor: "#fff", */
    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
  addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    backgroundColor: "#4b8edb",
    color: "#fff",
    outline: 0,
    border: "1px solid #4b8edb",
  },
  imgWrap: {
    position: "relative",
    width: "80px",
    height: "80px",
    overflow: "hidden",
    borderRadius: "50%",
    border: "2px solid #4b8edb",
    cursor: "pointer",
    padding: 0,
    backgroundColor: "#4b8edb",
  },
}));
