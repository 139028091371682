import React from "react";
import { Avatar, Grid, Paper } from "@material-ui/core";
import useStyle from "../style";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tag } from "antd";

function RejectTable(props) {
  const classes = useStyle();
  const { allAdminRejectedRequests, page } = props;

  return (
    <>
      {allAdminRejectedRequests?.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow container>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Sr No.
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Rejected Date
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Contact
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Society
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAdminRejectedRequests?.map((admin, index) => {
                return (
                  <TableRow
                  // key={row.name}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page-1) * 50 + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {moment(admin?.actionTime).format("DD/MM/YYYY - h:mm A")}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar alt={"Avtar"} className={classes.avatar} />
                      </Grid>
                      {admin?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.email}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.societies[0]?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Tag
                          style={{
                            cursor: "pointer",
                            padding: 3,
                            fontSize: "11px",
                          }}
                          color="red"
                        >
                          Rejected
                        </Tag>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default RejectTable;
