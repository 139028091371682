import {
  CssBaseline,
  Typography,
  Grid,
  Button,
  Paper,
  Avatar,
  List,
  ListItem,
  Chip,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useContext, useEffect, useState } from "react";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import useStyles from "./style";
import * as api from "../../../api";
import { BASE_URL } from "../../../utils/base";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import AddDiscussion from "../AddDiscussions.jsx/AddDiscussion";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import DiscussionTotalCards from "./DiscussionTotalCards";
import DiscussionCards from "./DiscussionCards";
import { resolveErrorMessage } from "../../../services/service";
import { AuthContext } from "../../../contextAPI/context";
import { Pagination } from "@mui/material";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";

export default function Alldiscussions(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [alldiscussions, setalldiscussions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [loading, setloading] = useState(false);
  var date = moment(new Date()).format("YYYY-MM-DD");
  const [search, setsearch] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [filterDiscussionType, setfilterDiscussionType] = useState("all");
  const [discussionCount, setdiscussionCount] = useState(0);
  const [openViewersInfo, setopenViewersInfo] = useState(false);
  const [viewersDetails, setviewersDetails] = useState([]);
  const [loader2, setloader2] = useState(false);
  const [pageLimit] = useState(9);

  const handleCloseDialog = () => {
    setopenViewersInfo(false);
  };

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Discussion"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleGetAllDiscussions = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);
    try {
      const res = await api.getallDiscussions({
        params: {
          societyId: fetchSocietyId(),
          pageLimit: pageLimit,
          pageNumber: localStorage.getItem("discussionPageNumber")
            ? localStorage.getItem("discussionPageNumber")
            : 1,
          viewerType: filterDiscussionType,
          startDate: startDate,
          endDate: endDate === "" ? date : endDate,
          title: search,
          isDeleted: filterDiscussionType === "deleted" ? true : false,
        },
      });
      if (res?.data?.success) {
        setalldiscussions(res?.data?.discussion?.discussion);
        setdiscussionCount(res?.data?.discussion);
        setCount(res?.data?.discussion?.count);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      resolveErrorMessage(error);
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    localStorage.setItem("discussionPageNumber", value);
  };

  const removeStartDate = () => {
    setstartDate("");
  };

  const removeEndDate = () => {
    setendDate("");
  };

  const handleReset = () => {
    setfilterDiscussionType("all");
    setstartDate("");
    setendDate("");
    setsearch("");
    setPage(1);
    localStorage.removeItem("discussionPageNumber");
  };

  useEffect(() => {
    handleGetAllDiscussions();
  }, [page, filterDiscussionType, startDate, search]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main
          className={classes.content}
          style={{
            filter: loading ? "blur(1px)" : "",
            overflow: loading ? "hidden" : "auto",
          }}
        >
          <div className={classes.toolbar} />
          <Grid container>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              style={{
                height: "100vh",
                pointerEvents: loading ? "none" : "",
              }}
            >
              <Paper
                style={{
                  padding: 12,
                  boxShadow: "0px 0px 0px",
                  overflow: "visible",
                  minHeight: "100vh",
                }}
              >
                <Grid container className="justify-content-between">
                  <Grid
                    container
                    item
                    xs={12}
                    lg={5}
                    sm={12}
                    className="d-flex align-items-center"
                  >
                    <span className="page-header">
                      Discussion Lists
                      {!loading ? (
                        <RefreshIcon
                          title="Refresh Notice Page"
                          onClick={() => {
                            handleGetAllDiscussions();
                            setPage(1);
                          }}
                          className="pointer ms-1 text-bg-dark"
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                          }}
                        />
                      )}
                    </span>
                    <div
                      class="d-flex align-items-center rounded-10p border px-2 overflow-hidden gap-1 ms-2 h-100"
                      style={{
                        minWidth: "250px",
                      }}
                    >
                      <div>
                        <SearchIcon />
                      </div>
                      <input
                        className="h-100 border-0 w-100"
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="search by title"
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setPage(1);
                        }}
                        value={search}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={7}
                    sm={12}
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 12,
                        marginRight: 8,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Start Date
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 5,
                        width: 160,
                        border: "1px solid #416082",
                        outline: 0,
                      }}
                      onChange={(event) => {
                        setstartDate(event.target.value);
                        setPage(1);
                      }}
                      placeholder="Choose Start Date"
                      value={startDate}
                      max={date}
                    />

                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 12,
                        marginRight: 8,
                        marginLeft: 10,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      End Date
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 5,
                        width: 160,
                        border: "1px solid #416082",
                        outline: 0,
                      }}
                      min={startDate}
                      max={date}
                      disabled={!startDate}
                      onChange={(event) => {
                        setendDate(event.target.value);
                        setPage(1);
                      }}
                      placeholder="Choose End Date"
                      value={endDate === "" ? date : endDate}
                    />
                    <div
                      className="search-button mt-1"
                      onClick={() => {
                        setPage(1);
                        handleGetAllDiscussions();
                      }}
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="search-icon"
                        >
                          <path
                            d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className=" d-flex justify-content-end mt-3">
                  {isReadWrite && (
                    <div
                      className="btn btn-outline-custom"
                      style={{ width: "200px", borderRadius: "10px" }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      +&nbsp;Add Discussion
                    </div>
                  )}
                </div>
                <Grid
                  container
                  style={{ justifyContent: "flex-end", marginTop: 5 }}
                >
                  {startDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 15,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={startDate}
                        onDelete={removeStartDate}
                      />
                    </Typography>
                  )}
                  {endDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 15,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={endDate}
                        onDelete={removeEndDate}
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid container spacing={2} className="mt-3 d-flex gap-3 px-2">
                  <DiscussionTotalCards
                    count={discussionCount}
                    filterDiscussionType={filterDiscussionType}
                    setfilterDiscussionType={setfilterDiscussionType}
                    setPage={setPage}
                  />
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  xs={12}
                  lg={12}
                  className="mt-5 row g-3"
                >
                  {alldiscussions?.map((dis, index) => {
                    return (
                      <DiscussionCards
                        index={index}
                        discussion={dis}
                        loading={loading}
                        setloader2={setloader2}
                        setviewersDetails={setviewersDetails}
                        setopenViewersInfo={setopenViewersInfo}
                        pageLimit={pageLimit}
                        page={page}
                      />
                    );
                  })}
                </Grid>
                {alldiscussions.length > 0 && (
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: 10 }}
                  >
                    <Pagination
                      className={classes.pagination}
                      count={count}
                      color="primary"
                      page={
                        parseInt(localStorage.getItem("discussionPageNumber"))
                          ? parseInt(
                              localStorage.getItem("discussionPageNumber")
                            )
                          : page
                      }
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}
                {!loading && alldiscussions?.length === 0 && (
                  <Typography
                    style={{
                      color: "#6c757d",
                      textAlign: "center",
                      fontSize: 38,
                      marginTop: 6,
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    No Discussions
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            maxWidth="lg"
            open={openViewersInfo}
            keepMounted
            onClose={handleCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 17,
                  fontWeight: "bolder",
                }}
              >
                DISCUSSION VIEWERS INFO
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              {viewersDetails?.length !== 0 && !loader2 && (
                <>
                  <Grid
                    container
                    style={{ justifyContent: "space-between", marginBottom: 7 }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#000000",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Sr. No.
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#000000",
                        fontSize: 15,
                        fontWeight: "bold",
                        marginLeft: 20,
                      }}
                    >
                      Viewer Name
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#000000",
                        fontSize: 15,
                        fontWeight: "bold",
                        marginLeft: 40,
                      }}
                    >
                      Viewer Role
                    </Typography>
                  </Grid>
                  <Grid>
                    <List>
                      {viewersDetails?.map((viewers, index) => {
                        return (
                          <ListItem>
                            <Typography
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                color: "#000",
                                fontSize: 15,
                                marginBottom: 5,
                                marginLeft: -10,
                              }}
                            >
                              {index + 1}.&nbsp;&nbsp;
                            </Typography>

                            <Grid container>
                              {viewers?.image ? (
                                <Avatar
                                  sx={{ width: 29, height: 29 }}
                                  alt={viewers?.name}
                                  src={`${BASE_URL}/user/${viewers.image}`}
                                />
                              ) : (
                                <Avatar
                                  sx={{ width: 25, height: 25 }}
                                  alt={viewers?.name}
                                >
                                  {viewers?.name?.slice(0, 1)}
                                </Avatar>
                              )}
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  color: "#000",
                                  fontSize: 15,
                                  marginTop: 5,
                                  marginLeft: 10,
                                  textTransform: "capitalize",
                                }}
                              >
                                {viewers?.name}
                              </Typography>
                            </Grid>
                            <Typography
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                color: "#000",
                                fontSize: 15,
                                marginBottom: 5,
                                marginLeft: -10,
                                width: "80px",
                              }}
                            >
                              {viewers?.role ? viewers?.role : "-"}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </>
              )}
              {loader2 && (
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    textAlign: "center",
                  }}
                >
                  Loading Viewers...
                  <LoadingOutlined
                    style={{ fontSize: 20, color: "#2f45c5", marginLeft: 12 }}
                  />
                </Typography>
              )}
              {viewersDetails?.length === 0 && !loader2 && (
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    textAlign: "center",
                  }}
                >
                  NO VIEWERS YET
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          <AddDiscussion
            open={open}
            setOpen={setOpen}
            handleGetAllDiscussions={handleGetAllDiscussions}
            setPage={setPage}
          />
        </main>
        {loading && <BanterLoader />}
      </div>
    </>
  );
}
