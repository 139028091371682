import React, { useState } from "react";
import useStyles from "../style";
import {
  Grid,
  Avatar,
  Typography,
  Paper,
  Button,
  Modal,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { BASE_URL } from "../../../utils/base";
import { Tag } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import AddLocalContact from "./AddLocalContact";
import { QuestionMark } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import * as api from "../../../api/index";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";

function LocalDirectoryDetailsTable(props) {
  const classes = useStyles();
  const [editlocalDirectory, setEditLocalDirectory] = useState(false);
  const [deleteLocalContact, setDeleteLocalConatct] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const {
    allLocalContact,
    loader,
    page,
    setloader,
    handleLocalDirectory,
    isReadWrite,
  } = props;

  const confirmModalDelete = (
    <div>
      <Modal open={deleteLocalContact}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.confirmModal}
        >
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 19,
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: 7,
              textTransform: "uppercase",
            }}
          >
            ARE YOU SURE YOU WANT TO DELETE THIS CONTACT ?
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
          </Typography>

          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            A confirmation message will appear when this process is complete.You
            can confirm by clicking below.
          </Typography>
          {loader && (
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              Please wait ... &nbsp;
              <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
            </Typography>
          )}
          <Grid container style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                setDeleteLocalConatct(false);
              }}
              style={{
                backgroundColor: "#C60C30",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                handleDeleteContact();
              }}
              style={{
                backgroundColor: "#1e2082",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
  const handleDeleteContact = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        localDirectoryId: contactData?._id,
      };
      const res = await api.deleteLocalDirectoryContact(sendData);
      if (res?.data?.success) {
        toast.success("Local Contact Deleted ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setDeleteLocalConatct(false);
        handleLocalDirectory();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <>
      {/* <Typography
        style={{
          fontWeight: "bold",
          fontSize: 22,
          fontFamily: "Montserrat, sans-serif",
          color: "#6c757d",
          marginLeft: 15,
        }}
      >
        New Category
      </Typography> */}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact Number
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created By
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Category
              </TableCell>
              {isReadWrite && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allLocalContact?.map((contact, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 50 + (index + 1)}.{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {contact?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {contact?.phone}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Grid container style={{ justifyContent: "center" }}>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        alt={contact?.createdBy?.name}
                        src={
                          contact?.createdBy?.image
                            ? `${BASE_URL}/user/${contact?.createdBy?.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        className={classes.avatar}
                      />
                    </Grid>
                    {contact?.createdBy?.name}
                    <br />
                    {moment(contact?.createdAt).format("DD/MM/YYYY - h:mm A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {contact?.category?.name}
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Tag
                        color="blue"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditLocalDirectory(true);
                          setContactData(contact);
                        }}
                        icon={<FormOutlined style={{ fontSize: "20px" }} />}
                      >
                        EDIT
                      </Tag>
                      <Tag
                        style={{ cursor: "pointer" }}
                        color="red"
                        onClick={() => {
                          setDeleteLocalConatct(true);
                          setContactData(contact);
                        }}
                        icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                      >
                        DELETE
                      </Tag>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {confirmModalDelete}
      <AddLocalContact
        editlocalDirectory={editlocalDirectory}
        setEditLocalDirectory={setEditLocalDirectory}
        contactData={contactData}
        handleLocalDirectory={handleLocalDirectory}
        type="Edit"
      />
    </>
  );
}

export default LocalDirectoryDetailsTable;
