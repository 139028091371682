import React, { useContext, useEffect, useState } from "react";
import { CssBaseline, Grid, Tooltip, Typography } from "@material-ui/core";
import useStyles from "./style";
import moment from "moment";
import * as api from "../../api";
import { resolveErrorMessage } from "../../services/service";
import Loading from "../../contextAPI/Loading";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { BASE_URL } from "../../utils/base";
import "./timeline.css";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { AuthContext } from "../../contextAPI/context";
import { toast } from "react-toastify";
import FlatDetailsModal from "./FlatDetailsModal";
import RemoveResidentModal from "./RemoveResidentModal";
import { InfoRounded } from "@mui/icons-material";
import { handleFlatName } from "../../contextAPI/NamingConvention";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { navigateToFlats } from "../../Navigation/Navigations";

function AllTimeline() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions } = authState;
  const { propertyId, societyName, flatId, propertyName, flatName } =
    useParams();
  const [allTimeline, setAllTimeline] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setloader] = useState(false);
  const [count, setCount] = React.useState(0);
  const [removeResident, setremoveResident] = useState(false);
  const [flatInfo, setflatInfo] = useState([]);
  const [removedUser, setremovedUser] = useState([]);
  const [password, setpassword] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [loader3, setloader3] = useState(false);
  const [isOpenFlatDetailsModal, setisOpenFlatDetailsModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Manage"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    handleGetTimelineData();
  }, [page]);

  const handleGetTimelineData = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pageNumber: page,
        pageLimit: 1000,
        flatId,
      };
      const res = await api.getTimeline(sendData);
      if (res?.data?.success) {
        if (page === 1) {
          setAllTimeline(res?.data?.timeline?.timeline);
          setflatInfo(res?.data?.timeline?.flat);
          setCount(res?.data?.timeline?.count);
        } else {
          setAllTimeline((prev) => [...prev, ...res.data.timeline.timeline]);
        }

        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleRemoveResidentFromFlat = async () => {
    setloader3(true);
    setTimeout(() => {
      setloader3(false);
    }, 40000);
    try {
      let sendData = {
        flatId: flatInfo?._id,
        userId: removedUser?._id,
        societyId: fetchSocietyId(),
        adminId: user?._id,
        role: removedUser?.role,
      };
      const res = await api.removeResidentFromFlat(sendData);
      if (res?.data?.success) {
        toast.success("Removed Resident From Flat Successfully");
        handleGetTimelineData();
        setloader3(false);
        setremoveResident(false);
      }
    } catch (error) {
      setloader3(false);
      resolveErrorMessage(error);
    }
  };

  const handleCheckUser = async () => {
    setloader3(true);
    setTimeout(() => {
      setloader3(false);
    }, 40000);
    seterrorMsg("");
    try {
      if (password === "") {
        seterrorMsg("Please enter your admin password for remove resident");
        setloader3(false);
      } else {
        const sendData = {
          userId: user?._id,
          societyId: fetchSocietyId(),
          password,
        };
        const res = await api.userCheckForGivePermission(sendData);
        if (res.data.success) {
          seterrorMsg("");
          setpassword("");
          setloader3(false);
          handleRemoveResidentFromFlat();
        }
      }
    } catch (error) {
      setloader3(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const infiniteScroll = async () => {
    try {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", infiniteScroll);
  //   return () => window.removeEventListener("scroll", infiniteScroll);
  // }, [allTimeline]);

  return (
    <div className={classes.root} style={{ overflow: "hidden" }}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6, maxHeight: "88vh" }}>
            <div class="card-body">
              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginTop: "-5px",
                    marginBottom: 10,
                  }}
                >
                  {` Timeline > `}
                  <span style={{ cursor: "pointer", color: "#000" }}>
                    {flatName}
                  </span>

                  {!loader ? (
                    <RefreshIcon
                      title="refresh timeline page"
                      onClick={handleGetTimelineData}
                      style={{
                        color: "#000",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginTop: -4,
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="laoding..."
                      style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                    />
                  )}
                </Typography>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "90px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                  onClick={() => {
                    navigate(
                      navigateToFlats({ societyName, propertyId, propertyName })
                    );
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      Back
                    </p>
                  </div>
                </div>
              </Grid>

              <div class="main-timeline" onScroll={infiniteScroll}>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid container item xs={6}>
                    {" "}
                    {/* <Grid container style={{ justifyContent: "flex-start" }}>
                      <Typography
                        style={{
                          color: "#000",
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "0.9rem",
                          marginLeft: 4,
                          marginBottom: 4,
                        }}
                      >
                        Current Owner{" "}
                        {flatInfo?.owner
                          ? `(${
                              flatInfo?.owner?.isResiding
                                ? "Residing"
                                : "Non-Residing"
                            })`
                          : ""}
                        :&nbsp;&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        {flatInfo?.owner ? (
                          <>
                            <Tooltip
                              title={
                                <>
                                  <p>
                                    Name : {flatInfo?.owner?.name} (
                                    {flatInfo?.owner?.role})
                                  </p>
                                  <p>Phone : {flatInfo?.owner?.phone}</p>
                                </>
                              }
                            >
                              <img
                                style={{
                                  height: "26px",
                                  width: "26px",
                                  border: "1px solid #e6e7f0",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                src={
                                  flatInfo?.owner?.image
                                    ? `${BASE_URL}/user/${flatInfo?.owner?.image}`
                                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                }
                                alt="Sociohood"
                              />
                            </Tooltip>
                            &nbsp;
                            <span style={{ color: "#2f45c5" }}>
                              {flatInfo?.owner?.name}
                            </span>{" "}
                          </>
                        ) : (
                          "at present no owner exist"
                        )}
                      </Typography>
                    </Grid>
                    <Grid container style={{ justifyContent: "flex-start" }}>
                      <Typography
                        style={{
                          color: "#283f59",
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "0.9rem",
                          marginLeft: 4,
                          marginBottom: 4,
                        }}
                      >
                        Current Tenant{" "}
                        {flatInfo?.tenant
                          ? `(${
                              flatInfo?.tenant?.isResiding
                                ? "Residing"
                                : "Non-Residing"
                            })`
                          : ""}{" "}
                        :&nbsp;&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        {flatInfo?.tenant ? (
                          <>
                            <Tooltip
                              title={
                                <>
                                  <p>
                                    Name : {flatInfo?.tenant?.name} (
                                    {flatInfo?.tenant?.role})
                                  </p>
                                  <p>Phone : {flatInfo?.tenant?.phone}</p>
                                </>
                              }
                            >
                              <img
                                style={{
                                  height: "26px",
                                  width: "26px",
                                  border: "1px solid #e6e7f0",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                src={
                                  flatInfo?.tenant?.image
                                    ? `${BASE_URL}/user/${flatInfo?.tenant?.image}`
                                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                }
                                alt="Sociohood"
                              />
                            </Tooltip>
                            &nbsp;
                            <span style={{ color: "#2f45c5" }}>
                              {flatInfo?.tenant?.name}
                            </span>{" "}
                          </>
                        ) : (
                          "at present no tenant exist"
                        )}
                      </Typography>
                    </Grid> */}
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontFamily: "Montserrat, sans-serif",
                        textDecoration: "underline",
                        textAlign: "end",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onClick={() => {
                        setisOpenFlatDetailsModal(true);
                      }}
                    >
                      click to see {handleFlatName()} details
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#6c757d",
                        fontFamily: "Montserrat, sans-serif",
                        textAlign: "end",
                        cursor: "pointer",
                        fontSize: "12px",
                        marginTop: "-10px",
                      }}
                    >
                      <InfoRounded style={{ fontSize: "18px" }} />
                      &nbsp; R = RESIDING || NR = NON-RESIDING
                    </p>
                  </Grid>
                </Grid>

                <div class="container-timeline" onScroll={infiniteScroll}>
                  <div class="wrapper-timeline">
                    <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>
                      Action Activity of {flatName}
                    </h1>
                    <ul class="sessions">
                      {allTimeline?.map((tl) => {
                        return (
                          <li>
                            <div class="time">
                              {moment(tl?.time).format("DD/MM/YYYY - h:mm A")}
                            </div>
                            <p style={{ fontFamily: "Poppins, sans-serif" }}>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  fontFamily: "Montserrat, sans-serif",
                                }}
                              >
                                {tl?.actionTitle} :
                              </span>
                              &nbsp;
                              {tl?.actionForDetails?.name && (
                                <>
                                  <Tooltip
                                    title={
                                      <>
                                        <p>
                                          Name : {tl?.actionForDetails?.name} (
                                          {tl?.actionForDetails?.role})
                                        </p>
                                        <p>
                                          Phone : {tl?.actionForDetails?.phone}
                                        </p>
                                      </>
                                    }
                                  >
                                    <img
                                      src={
                                        tl?.actionForDetails?.image
                                          ? `${BASE_URL}/user/${tl?.actionForDetails?.image}`
                                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                      }
                                      alt="Sociohood"
                                    />
                                  </Tooltip>
                                  <span style={{ color: "#2f45c5" }}>
                                    &nbsp;{tl?.actionForDetails?.name}(
                                    {tl?.actionForDetails?.role})
                                  </span>
                                </>
                              )}
                              &nbsp;{tl?.actionDescription}&nbsp;
                              {tl?.actionByDetails?.name && (
                                <>
                                  <Tooltip
                                    title={
                                      <>
                                        <p>
                                          Name : {tl?.actionByDetails?.name} (
                                          {tl?.actionByDetails?.role})
                                        </p>
                                        <p>
                                          Phone : {tl?.actionByDetails?.phone}
                                        </p>
                                      </>
                                    }
                                  >
                                    <img
                                      src={
                                        tl?.actionByDetails?.image
                                          ? `${BASE_URL}/user/${tl?.actionByDetails?.image}`
                                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                      }
                                      alt="Sociohood"
                                    />
                                  </Tooltip>
                                  <span style={{ color: "#2f45c5" }}>
                                    &nbsp;{tl?.actionByDetails?.name}(
                                    {tl?.actionByDetails?.role})
                                  </span>
                                </>
                              )}
                            </p>
                          </li>
                        );
                      })}
                      {allTimeline?.length === 0 && !loader && (
                        <Grid container style={{ justifyContent: "center" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 22,
                              marginTop: 30,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            No Activity
                          </Typography>
                        </Grid>
                      )}
                    </ul>
                  </div>
                  <div class="other-part">
                    <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>
                      Residing Activity of {flatName}
                    </h1>

                    <ul class="sessions">
                      {allTimeline?.[0]?.owner?.name && (
                        <li>
                          <div class="time">Resident Details (Owner)</div>

                          <p>
                            {" "}
                            {allTimeline?.[0].owner?.name} (
                            {allTimeline?.[0].owner?.phone})
                          </p>
                        </li>
                      )}
                      {allTimeline?.[0]?.tenants?.length > 0 && (
                        <li>
                          <div class="time">Resident Details (Tenants)</div>
                          {allTimeline?.[0]?.tenants?.map((tl, index) => {
                            return (
                              <p key={index}>
                                {index + 1}. {tl?.name} ({tl?.phone})
                              </p>
                            );
                          })}
                        </li>
                      )}
                      {allTimeline?.length === 0 && !loader && (
                        <Grid container style={{ justifyContent: "center" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 22,
                              marginTop: 30,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            No Residing Resident
                          </Typography>
                        </Grid>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {loader && (
                <Typography
                  style={{
                    color: "#2f45c5",
                    textAlign: "center",
                    fontSize: 18,
                    marginLeft: -23,
                    marginTop: 6,
                    fontWeight: "bold",
                  }}
                >
                  <LoadingOutlined
                    style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
                  />
                </Typography>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
      <FlatDetailsModal
        isOpenFlatDetailsModal={isOpenFlatDetailsModal}
        setisOpenFlatDetailsModal={setisOpenFlatDetailsModal}
        flatInfo={flatInfo}
        setremovedUser={setremovedUser}
        setremoveResident={setremoveResident}
        flatName={flatName}
        propertyName={propertyName}
        isReadWrite={isReadWrite}
      />
      <RemoveResidentModal
        removeResident={removeResident}
        handleCheckUser={handleCheckUser}
        password={password}
        setpassword={setpassword}
        setremoveResident={setremoveResident}
        seterrorMsg={seterrorMsg}
        loader3={loader3}
        errorMsg={errorMsg}
      />
    </div>
  );
}

export default AllTimeline;
