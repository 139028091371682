import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Modal,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  Close,
  VisibilityOffOutlined,
  VisibilityOutlined,
  VpnKey,
} from "@mui/icons-material";
import { EmailOutlined, ErrorOutline } from "@material-ui/icons";
import "../entrypoint.css";
import OtpInput from "otp-input-react";
import ApiCall from "../../api/ApiCall";
import { EntryPoints } from "../../api/Routes";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";

const Reset = (props) => {
  const { isResetPassword, setisResetPassword } = props;
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [email, setemail] = useState("");
  const [loader, setloader] = useState("");
  const [isEmailValid, setisEmailValid] = useState(true);
  const [isSendOtp, setisSendOtp] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isVisible, setisVisible] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [OTP, setOTP] = useState("");
  const [isResend, setisResend] = useState(false);

  const startApi = (setloader) => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };
  const handlePasswordVisibility = () => {
    setisVisible(!isVisible);
  };
  const validateEmail = (e) => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setisEmailValid(true);
      setemail(e.target.value);
    } else {
      setemail(e.target.value);
      setisEmailValid(false);
    }
  };

  const handleSendOtptoVerifyEmail = async (e) => {
    if (e !== "resend") {
      e.preventDefault();
    }
    startApi(e === "resend" ? setisResend : setloader);
    const res = await ApiCall("post", EntryPoints.sendOtpForResetPassword, {
      emailOrPhone: email.trim().toLowerCase(),
      userType: "admin",
    });
    if (res?.success) {
      seterrorMessage("");
      setOTP("");
      setisSendOtp(true);
      toast.success("Send OTP Successfully");
      setloader(false);
      setisResend(false);
    } else {
      setisResend(false);
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    startApi(setloader);
    const res = await ApiCall("post", EntryPoints.confirmOtpForResetPassword, {
      otp: OTP,
    });
    if (res?.success) {
      seterrorMessage("");
      toast.success("OTP Verified Successfully");
      setisSendOtp(false);
      setisConfirmPassword(true);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      seterrorMessage("password and confirm password must be same.");
      setloader(false);
    } else {
      startApi(setloader);
      const res = await ApiCall("post", EntryPoints.confirmResetPassword, {
        password: password,
        emailOrPhone: email.trim().toLowerCase(),
        userType: "admin",
      });
      if (res?.success) {
        seterrorMessage("");
        setemail("");
        setpassword("");
        setOTP("");
        toast.success("Password is Successfully Changed");
        setisConfirmPassword(false);
        setisResetPassword(false);
        setloader(false);
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  const handleReset = () => {
    seterrorMessage("");
    setisResend(false);
    setemail("");
    setisSendOtp(false);
    setisConfirmPassword(false);
    setisResetPassword(false);
  };
  return (
    <Modal open={isResetPassword}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Grid container item xs={8}>
            <Typography
              style={{
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "start",
                marginLeft: 10,
                fontSize: "1rem",
                opacity: 1,
              }}
            >
              Reset Password
            </Typography>
          </Grid>
          <Grid
            conatiner
            item
            xs={4}
            style={{
              textAlign: "end",
            }}
          >
            {!loader && (
              <IconButton>
                <Close
                  style={{
                    color: "#000",
                    fontSize: 20,
                    marginTop: -10,
                  }}
                  onClick={handleReset}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        {/* ************** 1ST STEP PARAGRAPH ********************* */}
        {!isSendOtp && !isConfirmPassword && (
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#7c817c",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            No worries, we'll send you reset instructions.
          </Typography>
        )}
        {/* ************** 2ND STEP PARAGRAPH ********************* */}
        {isSendOtp && !isConfirmPassword && !isResend && (
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#656965",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            We send a code to{" "}
            <span style={{ color: "#000", fontWeight: "bold" }}>{email}</span>
            &nbsp;&nbsp;
            <span
              style={{
                color: "#303f9f",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "12px",
              }}
              onClick={() => {
                setisSendOtp(false);
              }}
            >
              change
            </span>
          </Typography>
        )}
        {errorMessage && !isResend && (
          <Typography
            style={{
              marginTop: 8,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
              marginBottom: -5,
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMessage}
          </Typography>
        )}
        {isResend && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Resend OTP to{" "}
            <span style={{ color: "#000", fontWeight: "bold" }}>{email}</span>{" "}
            &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        <form
          onSubmit={(e) => {
            if (!isSendOtp && !isConfirmPassword) {
              // 1ST STEP
              handleSendOtptoVerifyEmail(e);
            } else if (isSendOtp && !isConfirmPassword) {
              // SECOND STEP
              handleVerifyOTP(e);
            } else {
              // THIRD STEP
              handleResetPassword(e);
            }
          }}
        >
          <div class="form-detalis">
            {/* ************** 1ST STEP INPUT BOX ********************* */}
            {!isSendOtp && !isConfirmPassword && (
              <div class="input-main">
                <EmailOutlined style={{ color: "#303f9f" }} />

                <div class="be-main"></div>
                <div class="input-box">
                  <input
                    type="email"
                    class="input"
                    required
                    placeholder="Enter your email here"
                    onChange={(e) => {
                      validateEmail(e);
                    }}
                    value={email}
                    style={{ backgroundColor: "#e6e7f0" }}
                  />
                </div>

                {isEmailValid ? (
                  <svg
                    class="icon-continue"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                ) : (
                  <>
                    <svg
                      style={{ color: "red" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        fill="red"
                      ></path>{" "}
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        fill="red"
                      ></path>{" "}
                    </svg>
                  </>
                )}
              </div>
            )}

            {/* ************** 2ND STEP INPUT BOX ********************* */}
            {isSendOtp && !isConfirmPassword && (
              <div class="input-main">
                <div class="input-box">
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    renderSeparator={<span>-</span>}
                    disabled={false}
                    shouldAutoFocus={true}
                    style={{ marginLeft: 10 }}
                  />
                </div>
              </div>
            )}
            {/* ************** 3RD STEP INPUT BOX ********************* */}
            {isConfirmPassword && !isSendOtp && (
              <>
                <div class="input-main" style={{ marginTop: 15 }}>
                  {isVisible ? (
                    <VisibilityOutlined
                      onClick={handlePasswordVisibility}
                      style={{ color: "#303f9f", cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOffOutlined
                      onClick={handlePasswordVisibility}
                      style={{ color: "#303f9f", cursor: "pointer" }}
                    />
                  )}
                  <div class="be-main"></div>
                  <div class="input-box">
                    <input
                      type={isVisible ? "text" : "password"}
                      required
                      class="input"
                      pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                      title="Password must be minimum eight characters, at least one letter, one number and one special character."
                      placeholder="Enter Password"
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      value={password}
                      style={{ backgroundColor: "#e6e7f0" }}
                    />
                  </div>
                </div>
                <div class="input-main" style={{ marginTop: 15 }}>
                  <VpnKey style={{ color: "#303f9f", cursor: "pointer" }} />
                  <div class="be-main"></div>
                  <div class="input-box">
                    <input
                      type={"password"}
                      required
                      class="input"
                      pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                      title="Password must be minimum eight characters, at least one letter, one number and one special character."
                      placeholder="Enter Confirm Password"
                      onChange={(e) => {
                        setconfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                      style={{ backgroundColor: "#e6e7f0" }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <button
            type="submit"
            class="continue"
            disabled={loader}
            style={{ opacity: loader ? 0.8 : "", marginLeft: 15 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* ************** 1ST STEP BUTTON TEXT ********************* */}
              {!isSendOtp && !isConfirmPassword && (
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Sending...." : "SEND OTP"}
                </p>
              )}

              {/* ************** 2ND STEP BUTTON TEXT ********************* */}
              {isSendOtp && !isConfirmPassword && (
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Confirming...." : "CONFIRM OTP"}
                </p>
              )}

              {/* ************** 3RD STEP BUTTON TEXT ********************* */}
              {isConfirmPassword && !isSendOtp && (
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Reset...." : "RESET PASSWORD"}
                </p>
              )}
            </div>
          </button>
        </form>

        {/* ************** 2ND STEP BOTTOM TEXT ********************* */}
        {isSendOtp && !isConfirmPassword && (
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
            }}
            class="other-paragraph"
          >
            Didn't recieve the email?{" "}
            <span
              style={{
                color: "#303f9f",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isResend) {
                  handleSendOtptoVerifyEmail("resend");
                }
              }}
            >
              Click to resend
            </span>
            &nbsp;&nbsp;
          </Typography>
        )}
      </Paper>
    </Modal>
  );
};

export default Reset;
