import React, { useState, useEffect, useContext } from "react";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import useStyles from "./style";
import SearchIcon from "@mui/icons-material/Search";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import * as api from "../../api/index";
import AddMeeting from "./AddMeeting";
import AllMeeting from "./AllMeeting";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import RefreshIcon from "@mui/icons-material/Refresh";
import MeetingCard from "./MeetingCard";
import { AuthContext } from "../../contextAPI/context";
import { resolveErrorMessage } from "../../services/service";
import Chip from "@mui/material/Chip";
import { Pagination } from "@mui/material";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";

function Meeting() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions } = authState;
  const [allmeeting, setallmeeting] = useState([]);
  const [AddMeetingModal, setAddMeetingModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchKey, setSearchKey] = useState("");
  var date = moment(new Date()).format("YYYY-MM-DD");
  var newDate = moment(new Date()).add(-2, "months").format("YYYY-MM-DD");
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [meetingStatusType, setmeetingStatusType] = useState("all");
  const [meetingCount, setmeetingCount] = useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit] = useState(9);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Meeting"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    handleMeeting();
  }, [page, searchKey, meetingStatusType, startDate]);

  const handleMeeting = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        startDate: startDate ? startDate : newDate,
        endDate: endDate ? endDate : date,
        status: meetingStatusType === "all" ? "" : meetingStatusType,
        searchKey: searchKey,
        pageLimit: pageLimit,
        pageNumber: localStorage.getItem("meetingPageNumber")
          ? localStorage.getItem("meetingPageNumber")
          : 1,
        userId: user?._id,
      };
      const res = await api.getMeeting({ params: sendData });
      if (res?.data?.success) {
        setCount(res.data?.meeting?.count);
        setmeetingCount(res?.data?.meeting);
        setallmeeting(res.data.meeting?.meeting);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleReset = () => {
    setPage(1);
    setmeetingStatusType("all");
    setSearchKey("");
    setStartDate("");
    setEndDate("");
    localStorage.removeItem("meetingPageNumber");
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    localStorage.setItem("meetingPageNumber", value);
  };

  const removeStartDate = () => {
    setStartDate("");
    handleMeeting();
  };
  const removeEndDate = () => {
    setEndDate("");
    handleMeeting();
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={classes.content}
        style={{
          filter: loader ? "blur(1px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
        }}
      >
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            sm={12}
          >
            <Paper
              style={{
                padding: 12,
                boxShadow: "0px 0px 0px",
              }}
            >
              <Grid container className="justify-content-between">
                <Grid
                  container
                  item
                  xs={12}
                  lg={5}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <span className="page-header">
                    Meeting Lists{" "}
                    {!loader ? (
                      <RefreshIcon
                        title="Refresh Notice Page"
                        onClick={() => {
                          handleReset();
                          handleMeeting();
                          setPage(1);
                        }}
                        className="pointer ms-1 text-bg-dark"
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                        }}
                      />
                    )}
                  </span>
                  <div
                    class="d-flex align-items-center rounded-10p border px-2 overflow-hidden gap-1 ms-2 h-100"
                    style={{
                      minWidth: "250px",
                    }}
                  >
                    <div>
                      <SearchIcon />
                    </div>
                    <input
                      className="h-100 border-0 w-100"
                      style={{ outline: "none" }}
                      type="text"
                      placeholder="search by title"
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(1);
                      }}
                      value={searchKey}
                    />
                  </div>
                </Grid>
                <Grid
                   container
                   item
                   xs={12}
                   lg={7}
                   sm={12}
                   style={{
                     justifyContent: "flex-end",
                   }}
                >
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginTop: 12,
                      marginRight: 8,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Start Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 160,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                    placeholder="Choose Start Date"
                    max={date}
                    value={startDate === "" ? newDate : startDate}
                  />

                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginTop: 12,
                      marginRight: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    End Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 160,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    min={startDate}
                    max={date}
                    disabled={!startDate}
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                    placeholder="Choose End Date"
                    value={endDate === "" ? date : endDate}
                  />
                  <div
                    className="search-button mt-1"
                    onClick={() => {
                      setPage(1);
                      handleMeeting();
                    }}
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="search-icon"
                      >
                        <path
                          d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Grid>
                <div className="d-flex justify-content-end mt-3 w-100">
                  {isReadWrite && (
                    <div
                      className="btn btn-outline-custom"
                      style={{ width: "200px", borderRadius: "10px" }}
                      onClick={() => {
                        setAddMeetingModal(true);
                      }}
                    >
                      +&nbsp;Schedule Meeting
                    </div>
                  )}
                </div>
              </Grid>
              <Grid container spacing={2} className="mt-3 d-flex gap-3 px-2">
                <MeetingCard
                  count={meetingCount}
                  meetingStatusType={meetingStatusType}
                  setmeetingStatusType={setmeetingStatusType}
                  setPage={setPage}
                />
              </Grid>

              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 10 }}
              >
                {startDate && (
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginLeft: 6,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Chip
                      style={{
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                      }}
                      label={startDate}
                      onDelete={removeStartDate}
                    />
                  </Typography>
                )}
                {endDate && (
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginLeft: 6,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Chip
                      style={{
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                      }}
                      label={endDate}
                      onDelete={removeEndDate}
                    />
                  </Typography>
                )}
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                lg={12}
                className="mt-4 row g-3"
              >
                {allmeeting?.length > 0 && (
                  <>
                    {allmeeting?.map((meeting, index) => {
                      return (
                        <AllMeeting
                          meeting={meeting?.meet}
                          page={page}
                          pageLimit={pageLimit}
                          index={index}
                        />
                      );
                    })}
                  </>
                )}
              </Grid>
              {allmeeting.length > 0 && (
                <Grid container className="mt-2 justify-content-center">
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={
                      parseInt(localStorage.getItem("meetingPageNumber"))
                        ? parseInt(localStorage.getItem("meetingPageNumber"))
                        : page
                    }
                    onChange={handlePageChange}
                  />
                </Grid>
              )}

              {allmeeting?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      marginTop: 30,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    No Meeting
                  </Typography>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddMeeting
          AddMeetingModal={AddMeetingModal}
          setAddMeetingModal={setAddMeetingModal}
          handleMeeting={handleMeeting}
          setPage={setPage}
        />
      </main>
      {loader && <BanterLoader />}
    </div>
  );
}

export default Meeting;
