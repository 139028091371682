import { CheckCircle } from "@material-ui/icons";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";

const DiscussionTotalCards = (props) => {
  const { filterDiscussionType, setfilterDiscussionType, count, setPage } =
    props;

  const icons = {
    all: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 55 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.5003 26.5835V26.6077M18.3337 26.5835V26.6077M36.667 26.5835V26.6077M9.16699 50.7502V19.3335C9.16699 17.4107 9.89132 15.5666 11.1806 14.207C12.4699 12.8473 14.2186 12.0835 16.042 12.0835H38.9587C40.782 12.0835 42.5307 12.8473 43.82 14.207C45.1093 15.5666 45.8337 17.4107 45.8337 19.3335V33.8335C45.8337 35.7563 45.1093 37.6004 43.82 38.96C42.5307 40.3197 40.782 41.0835 38.9587 41.0835H18.3337L9.16699 50.7502Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.all,
      title: "All Discussion",
      type: "all",
    },
    published: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 49H49M11.6667 49V16.3333L30.3333 7V49M44.3333 49V25.6667L30.3333 16.3333M21 21V21.0233M21 28V28.0233M21 35V35.0233M21 42V42.0233"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.society,
      title: "Society",
      type: "society",
    },
    unpublished: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8333 26H6.5L26 6.5L45.5 26H41.1667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.8333 26V41.1667C10.8333 42.3159 11.2899 43.4181 12.1025 44.2308C12.9152 45.0435 14.0174 45.5 15.1667 45.5H36.8333C37.9826 45.5 39.0848 45.0435 39.8975 44.2308C40.7101 43.4181 41.1667 42.3159 41.1667 41.1667V26"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.6667 26H30.3333V34.6667H21.6667V26Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.property,
      title: `${handlePropertyName()}`,
      type: "property",
    },
    deleted: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14H40M10 14L12 38C12 39.0609 12.4214 40.0783 13.1716 40.8284C13.9217 41.5786 14.9391 42 16 42H32C33.0609 42 34.0783 41.5786 34.8284 40.8284C35.5786 40.0783 36 39.0609 36 38L38 14M18 14V8C18 7.46957 18.2107 6.96086 18.5858 6.58579C18.9609 6.21071 19.4696 6 20 6H28C28.5304 6 29.0391 6.21071 29.4142 6.58579C29.7893 6.96086 30 7.46957 30 8V14M20 24L28 32M28 24L20 32"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.deleted,
      title: "Delete",
      type: "deleted",
    },
  };

  const DiscussionCard = ({ icon, title, count, active, type }) => {
    return (
      <div
        className={`card rounded-10p pointer ${active ? "border-success" : ""}`}
        onClick={() => {
          setfilterDiscussionType(type);
          setPage(1);
        }}
        style={{
          boxShadow: `${
            active
              ? "rgba(36, 202, 38, 0.4) 0px 1px 2px 0px, rgba(36, 202, 38, 0.15) 0px 2px 6px 2px"
              : "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }`,
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center gap-2">
            {typeof icon === "string" ? (
              <img
                src={icon}
                alt="Icon"
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              icon
            )}
            <div>
              <p
                className={`text-nowrap mb-0 text-poppins h6 ${
                  active ? "text-dark" : "text-secondary"
                }`}
              >
                {title}
              </p>
              <p
                className={`mb-0 text-poppins ${
                  active ? "text-dark" : "text-secondary"
                }`}
              >
                {count}
              </p>
            </div>
            {filterDiscussionType === type && (
              <CheckCircle
                style={{
                  color: "rgb(36 202 38)",
                  fontSize: "30px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center gap-2 pointer">
      {Object?.keys(icons)?.map((key) => (
        <div key={key}>
          <DiscussionCard
            icon={icons[key]?.icon}
            title={icons[key]?.title}
            count={icons[key]?.count}
            active={filterDiscussionType === icons[key]?.type}
            type={icons[key]?.type}
          />
        </div>
      ))}
    </div>
  );
};

export default DiscussionTotalCards;
