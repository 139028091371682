import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

export default function SectorComplaintGraphQV({ data }) {
  return (
    <ResponsiveContainer width="100%" height={330}>
      <BarChart
        data={data}
        margin={{ top: 5, bottom: 5 }}
        layout="vertical"
      >
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          type="category"
          dataKey="sectorName"
          interval={0}
          axisLine={false}
          style={{
            fontSize: 8,
            color: "#0000",
            fontWeight: "bold",
          }}
        />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="numberOfComplaints"
          fill="#3862DD"
          name="Sector wise Total Complaints"
        >
          <LabelList
            dataKey="numberOfComplaints"
            position="center"
            fill="#fff"
            style={{
              fontSize: 9,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
