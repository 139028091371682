import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "45vw",
    borderRadius: 6,
  },
  hoverText: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#007aff",
    },
  },
  cardSection: {
    display: "flex",
    flexDirection: "column",
    minHeight: "51vh",
    maxHeight: "51vh",
    overflow: "hidden",
    borderRadius: ".4em",
    textDecoration: "none",
    margin: "0.70em",
    padding: "1.8em 1.2em",
    boxShadow: "0 3px 6px #7D858D",
    "&:hover": {
      boxShadow: "1px 6px 10px #7D858D",
      transform: "scale(1.02)",
      transition: "all 0.5s ease-in-out",
    },
  },
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: "#2A265F",
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  meetingCard: {
    backgroundolor: " #fff",
    borderRadius: "10px",
    boxShadow:
      "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
    display: "flex",
    maxWidth: " 100%",
    margin: "0.70em",
    overflow: "hidden",
    width: "100%",
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  pagination: {
    "& button": {
      borderRadius: "50%",
      padding: theme.spacing(1),
      "&.Mui-selected": {
        color: "#fff",
      },
    },
  },
}));
