import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Staff from "../../Components/GateManagement/Staff/Staff";
import EntryExitLogs from "../../Components/GateManagement/Logs/EntryExitLogs";

const StaffRoutes = {
  path: "/gatemanagement",
  element: <NavigationDrawer />,
  children: [
    {
      path: "staff",
      element: <Staff />,
    },
    {
      path: "staff/logs/:id",
      element: <EntryExitLogs />,
    },
  ],
};
export default StaffRoutes;
