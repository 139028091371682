import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import { Grid, Typography } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { handlePropertyName, handleFlatName } from "../../../contextAPI/NamingConvention";

function SocietyTypeTable(props) {
  const classes = useStyles();
  const { societyType, typeData, loader, page, setPage } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {societyType === "Property" ? handlePropertyName() : handleFlatName()} Type Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {typeData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((type, index) => {
                return (
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {rowsPerPage * page - 1 + index + 2}.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {type?.name}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {typeData?.length === 0 && !loader && (
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              marginTop: 7,
              fontWeight: "bolder",
            }}
          >
            No {societyType === "Property" ? handlePropertyName() : handleFlatName()} Type
          </Typography>
        </Grid>
      )}
      {typeData?.length >= 20 && (
        <Grid container style={{ justifyContent: "center" }}>
          <TablePagination
            rowsPerPageOptions={[25, 45, 60]}
            component="div"
            count={typeData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </>
  );
}

export default SocietyTypeTable;
