import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  DialogActions,
  Modal,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";
import useStyles from "./styles";
import AddComplaint from "./AddComplaint/AddComplaint";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contextAPI/context";
import * as api from "../../api";
import { Apartment, BarChart, Error } from "@material-ui/icons";
import CategoryIcon from "@mui/icons-material/Category";
import SectorComplaintGraph from "./SectorComplaintGraph";
import CategoryComplaintGraph from "../Complaints/CategoryComplaintGraph";
import MixedComplaintsGraph from "../Complaints/MixedComplaintsGraph";
import MonthlyPendingComplaintGraph from "./MonthlyPendingComplaintGraph";
import VendorPerformanceGraph from "./VendorPerformanceGraph";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ExportJsonCsv } from "react-export-json-csv";
import { saveAs } from "file-saver";
import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Close } from "@material-ui/icons";
// import { useCurrentPng } from "recharts-to-png";
import { ToastContainer, toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  CalendarToday,
  Engineering,
  PreviewRounded,
  RestartAlt,
} from "@mui/icons-material";
import { Row } from "antd";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import { navigateInitialQuickView } from "../../Navigation/Navigations";
import { getComplaintCategories } from "../../GlobalFunctions/GlobalFunctions";

function MisDashboard() {
  const classes = useStyles();
  const [addComplaintModal, setaddComplaintModal] = useState(false);
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { properties } = authState;
  const [openVendor, setOpenVendor] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [fyWiseGraphDialogData, setFyWiseGraphDialogData] = useState({
    yearOne: "",
    yearTwo: "",
    complaintStatus: "assigned",
  });
  const [openSectorDialog, setOpenSectorDialog] = useState(false);
  const [sectorWiseGraphData, setSectorWiseGraphData] = useState({
    sectors: [],
    complaintStatus: "assigned",
  });
  const [openSLA, setOpenSLA] = useState(false);
  const [slaName, setSLAName] = useState([]);
  const [openVendorComplaints, setopenVendorComplaints] = useState(false);
  const [anchorElOne, setAnchorElOne] = useState(null);
  const [anchorElTwo, setAnchorElTwo] = useState(null);
  const [anchorElThree, setAnchorElThree] = useState(null);
  const [anchorElFour, setAnchorElFour] = useState(null);
  const [anchorElFive, setAnchorElFive] = useState(null);
  const [anchorElSix, setAnchorElSix] = useState(null);
  const [selectedCatgoriesForGraph, setselectedCatgoriesForGraph] = useState(
    []
  );
  const [selectedSectorsSlaForGraph, setselectedSectorsSlaForGraph] = useState(
    []
  );

  const [selectedSectorsForGraph, setselectedSectorsForGraph] = useState([]);
  const [selectedVendorsForGraph, setselectedVendorsForGraph] = useState([]);
  const [
    selectedVendorsPerformanceForGraph,
    setselectedVendorsPerformanceForGraph,
  ] = useState([]);

  const openOne = Boolean(anchorElOne);
  const [fyGraphData, setFyGraphData] = useState(null);
  const [sectorGraphData, setSectorGraphData] = useState({ sectors: [] });
  const [mixedComplaintsGraphData, setMixedComplaintsGraphData] =
    useState(null);
  const [categoryComplaintGraphData, setCategoryComplaintGraphData] =
    useState(null);
  const [durationData, setDurationData] = useState({ months: [] });
  const [slaGraphData, setSlaGraphData] = useState({
    sectors: [],
    complaintStatus: "assigned",
  });
  const [vendorWiseGraphData, setVendorWiseGraphData] = useState({
    vendors: [],
    complaintStatus: "assigned",
  });
  const [
    monthlyPendingComplaintGraphData,
    setMonthlyPendingComplaintGraphData,
  ] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categoryWiseGraphData, setCategoryWiseGraphData] = useState({
    categories: [],
    complaintStatus: "assigned",
  });
  const [vendorPerformanceDialogData, setVendorPerformanceDialogData] =
    useState({ vendors: [], type: "rating" });
  const [vendorPerformanceGraphData, setVendorPerformanceGraphData] =
    useState(null);
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const openTwo = Boolean(anchorElTwo);
  const openThree = Boolean(anchorElThree);
  const openFour = Boolean(anchorElFour);
  const openFive = Boolean(anchorElFive);
  const openSix = Boolean(anchorElSix);
  // const [getPng, { ref }] = useCurrentPng();
  const [headers, setHeaders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessageCategoryWiseGraph, seterrorMessageCategoryWiseGraph] =
    useState("");
  const [errorMessageSectorWiseGraph, seterrorMessageSectorWiseGraph] =
    useState("");
  const [errorMessageVendorWiseGraph, seterrorMessageVendorWiseGraph] =
    useState("");
  const [errorMessageVendorWisePerGraph, seterrorMessageVendorWisePerGraph] =
    useState("");
  const [errorFy, seterrorFy] = useState("");

  const [loading, setloading] = useState(false);
  const [slaAllCheckedSectors, setslaAllCheckedSectors] = useState(false);
  const [sectorwiseAllChecked, setsectorwiseAllChecked] = useState(false);
  // FOR INDIVIDUAL DATE
  const [fromDate, setfromDate] = useState("");
  const [CategoryWisefromDate, setCategoryWisefromDate] = useState("");
  const [CategoryWiseToDate, setCategoryWiseToDate] = useState("");
  const [graphType, setgraphType] = useState("");
  const [ToDate, setToDate] = useState("");
  const [SlaWiseFromDate, setSlaWiseFromDate] = useState("");
  const [SlaWiseToDate, setSlaWiseToDate] = useState("");
  const [VendorWiseFromDate, setVendorWiseFromDate] = useState("");
  const [VendorWiseToDate, setVendorWiseToDate] = useState("");
  const [SectorWiseFromDate, setSectorWiseFromDate] = useState("");
  const [SectorWiseToDate, setSectorWiseToDate] = useState("");
  const [VendorWisePerfFromDate, setVendorWisePerfFromDate] = useState("");
  const [VendorWisePerfToDate, setVendorWisePerfToDate] = useState("");
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const [vendors, setVendors] = useState([]);
  const [categories, setcategories] = useState([]);
  const [allProperties, setallProperties] = useState([]);
  /* fixed for all */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const monthName = (mon) => {
    if (mon == "01") {
      mon = 1;
    } else if (mon == "02") {
      mon = 2;
    } else if (mon == "03") {
      mon = 3;
    } else if (mon == "04") {
      mon = 4;
    } else if (mon == "05") {
      mon = 5;
    } else if (mon == "06") {
      mon = 6;
    } else if (mon == "07") {
      mon = 7;
    } else if (mon == "08") {
      mon = 8;
    } else if (mon == "09") {
      mon = 9;
    }
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][mon - 1];
  };

  const handleChangePerformanceVendor = (event) => {
    const {
      target: { value, name },
    } = event;

    if (name === "vendors")
      setVendorPerformanceDialogData((prev) => ({
        ...prev,
        vendors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "type")
      setVendorPerformanceDialogData((prev) => ({ ...prev, type: value }));
  };
  const handleOpenVendor = () => {
    setOpenVendor(true);
  };

  /* for FY graph */
  const fyWise = ["2021", "2022", "2023"];
  const handleFyGraphChange = (event) => {
    const { target } = event;
    if (target.name === "yearOne") {
      setFyWiseGraphDialogData((prev) => ({ ...prev, yearOne: target.value }));
      setFyWiseGraphDialogData((prev) => ({ ...prev, yearTwo: "" }));
    } else if (target.name === "yearTwo")
      setFyWiseGraphDialogData((prev) => ({ ...prev, yearTwo: target.value }));
    else
      setFyWiseGraphDialogData((prev) => ({
        ...prev,
        complaintStatus: target.value,
      }));
  };
  const handleOpenYear = () => {
    setOpenYear(true);
  };

  const handleChangeSector = (event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "sectors")
      setSectorWiseGraphData((prev) => ({
        ...prev,
        sectors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setSectorWiseGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleCloseTwo = () => {
    setAnchorElTwo(null);
  };
  const handleCloseThree = () => {
    setAnchorElThree(null);
  };
  const handleCloseFive = () => {
    setAnchorElFive(null);
  };
  const handleCloseSix = () => {
    setAnchorElSix(null);
  };

  const handleChangeSectorSla = (event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "sectors")
      setSlaGraphData((prev) => ({
        ...prev,
        sectors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setSlaGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleChangeVendorWiseGraphData = (event) => {
    const {
      target: { value, name },
    } = event;

    if (name === "vendors")
      setVendorWiseGraphData((prev) => ({
        ...prev,
        vendors: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setVendorWiseGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleOpenSector = () => {
    setOpenSectorDialog(true);
  };

  const handleOpenSLA = () => {
    setOpenSLA(true);
  };
  const handleCloseCategoryDialog = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    seterrorMessageCategoryWiseGraph("");
    if (durationData.months?.length !== 0) {
      if (selectedCatgoriesForGraph?.length === 0) {
        setIsLoading(false);
        seterrorMessageCategoryWiseGraph("please select atleast one category");
      } else {
        const res = await api.getCategoryWiseGraphData({
          societyId: fetchSocietyId(),
          ...categoryWiseGraphData,
          months: durationData.months,
          categories: selectedCatgoriesForGraph,
        });
        setOpenCategoryDialog(false);
        seterrorMessageCategoryWiseGraph("");
        setgraphType("");
        setCategoryComplaintGraphData(res.data.categoryWiseComplaintData);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a time Period first");
    }
  };

  const handleCloseSLA = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    seterrorMessageSectorWiseGraph("");
    if (durationData.months?.length !== 0) {
      if (selectedSectorsSlaForGraph?.length === 0) {
        setIsLoading(false);
        seterrorMessageSectorWiseGraph("please select atleast one sector");
      } else {
        const res = await api.getSlaGraphData({
          societyId: fetchSocietyId(),
          ...slaGraphData,
          sectors: selectedSectorsSlaForGraph,
          months: durationData.months,
        });
        setOpenSLA(false);
        setMixedComplaintsGraphData(res.data.slaData);
        setgraphType("");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a time period first");
    }
  };
  const handleCloseVendorComplaints = async () => {
    if (durationData.months?.length !== 0) {
      if (selectedVendorsForGraph?.length === 0) {
        seterrorMessageVendorWiseGraph("please select atleast one vendor");
      } else {
        const res = await api.getVendorWiseGraphData({
          societyId: fetchSocietyId(),
          ...vendorWiseGraphData,
          months: durationData.months,
          vendors: selectedVendorsForGraph,
        });
        setopenVendorComplaints(false);
        seterrorMessageVendorWiseGraph("");
        setgraphType("");
        setMonthlyPendingComplaintGraphData(res.data.vendorWiseData);
      }
    } else toast.error("Please select a duration first");
  };

  const handleCloseSector = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    if (durationData.months.length !== 0) {
      if (selectedSectorsForGraph.length === 0) {
        setIsLoading(false);
        seterrorMessageSectorWiseGraph("please select atleast one sector");
      } else {
        const res = await api.getSectorWiseGraphData({
          societyId: fetchSocietyId(),
          ...sectorWiseGraphData,
          months: durationData.months,
          sectors: selectedSectorsForGraph,
        });
        setOpenSectorDialog(false);
        seterrorMessageSectorWiseGraph("");
        setgraphType("");
        setSectorGraphData(res.data.sectorComplaintData);

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a duration first");
    }
  };

  const handleCloseVendor = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 50000);
    if (durationData.months.length !== 0) {
      if (selectedVendorsPerformanceForGraph.length === 0) {
        setloading(false);
        seterrorMessageVendorWisePerGraph("please select atleast one vendor");
      }
      if (vendorPerformanceDialogData.type === "") {
        setloading(false);
        seterrorMessageVendorWisePerGraph("please select type");
      } else {
        const res = await api.getVendorPerformanceGraphData({
          societyId: fetchSocietyId(),
          type: vendorPerformanceDialogData.type,
          vendorIds: selectedVendorsPerformanceForGraph,
        });
        setloading(false);
        seterrorMessageVendorWisePerGraph("");
        setgraphType("");
        setVendorPerformanceGraphData(res.data.vendorPerformanceData);
        setOpenVendor(false);
      }
    } else {
      setloading(false);
      toast.error("Please select a duration first");
    }
  };

  const handleCloseYear = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
    if (fyWiseGraphDialogData.yearOne === "") {
      setIsLoading(false);
      seterrorFy("Please Select year one");
    }
    if (fyWiseGraphDialogData.yearTwo === "") {
      setIsLoading(false);
      seterrorFy("Please Select year two");
    } else {
      const res = await api.getMsiFyGraphData({
        societyId: fetchSocietyId(),
        ...fyWiseGraphDialogData,
      });
      seterrorFy("");
      setFyGraphData(res.data.fyWiseData);
      setOpenYear(false);
      setgraphType("");
      setIsLoading(false);
    }
  };

  const handleOpenVendorComplaints = () => {
    setopenVendorComplaints(true);
  };

  /* For download and report download */
  const handleClickOne = (event) => {
    setAnchorElOne(event.currentTarget);
  };
  const handleClickTwo = (event) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleClickThree = (event) => {
    setAnchorElThree(event.currentTarget);
  };
  const handleClickFour = (event) => {
    setAnchorElFour(event.currentTarget);
  };
  const handleClickFive = (event) => {
    setAnchorElFive(event.currentTarget);
  };
  const handleClickSix = (event) => {
    setAnchorElSix(event.currentTarget);
  };

  var date = new Date();

  useEffect(() => {
      loadInitialData();
  }, []);

  useEffect(() => {
    handleGetVendors();
    handleGetCategories();
    handleGetProperties();
  }, []);

  const handleGetVendors = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
      };
      const res = await api.getAllVendor(sendData);
      if(res?.data?.success){
        setVendors(res?.data?.vendor?.vendor);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handleGetCategories = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      pageNumber: page,
      searchKey: search,
    };
    const res = await getComplaintCategories(sendData);
    setcategories(res?.complaintCategory);
    setloading(false);
  };

  const handleGetProperties = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
        pageLimit: 1000,
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        setallProperties(res?.data?.properties?.properties);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      resolveErrorMessage(error);
    }
  };

  const venndorsGrapheData = async () => {
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        complaintStatus: "assigned",
        months:
          durationData.months.length !== 0
            ? durationData.months
            : [`${currentMonth}-${currentYear}`],
        vendors: vendorsId,
      }
      const res = await api.getVendorWiseGraphData(sendData)
      if(res?.data?.success){
        setMonthlyPendingComplaintGraphData(res.data.vendorWiseData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    venndorsGrapheData();
  },[vendors])

  /* for Sector Wise graph */
  const sectors = allProperties?.map((property) => property?.sector);
  const currentYear = new Date().getFullYear().toString().split("0")[1];
  const vendorsId = vendors?.map((vendor) => vendor?._id);
  const currentMonth = (new Date().getMonth() + 1).toString();

  const loadInitialData = async () => {
    try {
      setIsLoading(true);
      let res = await api.getMsiData({
        societyId: fetchSocietyId(),
        complaintStatus: "assigned",
        monthAndYear:
          durationData.months.length !== 0
            ? durationData.months
            : [`${currentMonth}-${currentYear}`],
      });
      setFyGraphData(res.data.data.fyWiseData);
      setSectorGraphData(res.data.data.sectorComplaintData);
      setMixedComplaintsGraphData(res.data.data.slaData);
      setCategoryComplaintGraphData(res.data.data.categoryWiseComplaintData);
      res = await api.getVendorPerformanceGraphData({
        societyId: fetchSocietyId(),
        vendorIds: vendorsId,
        type: "rating",
      });
      setVendorPerformanceGraphData(res.data.vendorPerformanceData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      resolveErrorMessage(error);
    }
  };
  const handleResetData = async () => {
    setIsLoading(true);
    setfromDate("");
    setCategoryWisefromDate("");
    setSlaWiseFromDate("");
    setVendorWiseFromDate("");
    setSectorWiseFromDate("");
    setVendorWisePerfFromDate("");
    setToDate("");
    setCategoryWiseToDate("");
    setSlaWiseToDate("");
    setVendorWiseToDate("");
    setSectorWiseToDate("");
    setVendorWisePerfToDate("");
    let res = await api.getMsiData({
      societyId: fetchSocietyId(),
      complaintStatus: "assigned",
      monthAndYear: [`${currentMonth}-${currentYear}`],
    });
    setFyGraphData(res.data.data.fyWiseData);
    setSectorGraphData(res.data.data.sectorComplaintData);
    setMixedComplaintsGraphData(res.data.data.slaData);
    setCategoryComplaintGraphData(res.data.data.categoryWiseComplaintData);
    res = await api.getVendorWiseGraphData({
      societyId: fetchSocietyId(),
      complaintStatus: "assigned",
      months: [`${currentMonth}-${currentYear}`],
      vendors: vendorsId,
    });
    setMonthlyPendingComplaintGraphData(res.data.vendorWiseData);
    res = await api.getVendorPerformanceGraphData({
      societyId: fetchSocietyId(),
      vendorIds: vendorsId,
      type: "rating",
    });
    setVendorPerformanceGraphData(res.data.vendorPerformanceData);
    setIsLoading(false);
  };

  const handleDurationChange = (e) => {
    console.log("e.target.value", e.target.value);
    const data =
      e.target.value.substring(2).split("-")[1] +
      "-" +
      e.target.value.substring(2).split("-")[0];
    if (e.target.name === "from") {
      if (graphType === "") {
        setfromDate(e.target.value);
        setCategoryWisefromDate(e.target.value);
        setSlaWiseFromDate(e.target.value);
        setVendorWiseFromDate(e.target.value);
        setSectorWiseFromDate(e.target.value);
        setVendorWisePerfFromDate(e.target.value);
      }
      if (graphType === "categoryWise") {
        setCategoryWisefromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "SlaWise") {
        setSlaWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "vendorWise") {
        setVendorWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "sectorWise") {
        setSectorWiseFromDate(e.target.value);
        setfromDate("");
      }
      if (graphType === "vendorPerfWise") {
        setVendorWisePerfFromDate(e.target.value);
        setfromDate("");
      }
      if (durationData.to) {
        let start = data.split("-");
        let end = durationData.to.split("-");
        let startYear = parseInt(start[1]);
        let endYear = parseInt(end[1]);
        let months = [];

        for (let i = startYear; i <= endYear; i++) {
          let endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
          let startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
          for (
            let j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            let month = j + 1;
            let displayMonth = month < 10 ? "0" + month : month;
            months.push([displayMonth, i].join("-"));
          }
        }
        setDurationData((prev) => ({ ...prev, months, from: data }));
      } else setDurationData((prev) => ({ ...prev, [e.target.name]: data }));
    } else {
      if (graphType === "") {
        setToDate(e.target.value);
        setCategoryWiseToDate(e.target.value);
        setSlaWiseToDate(e.target.value);
        setVendorWiseToDate(e.target.value);
        setSectorWiseToDate(e.target.value);
        setVendorWisePerfToDate(e.target.value);
      }
      if (graphType === "categoryWise") {
        setCategoryWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "SlaWise") {
        setSlaWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "vendorWise") {
        setVendorWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "sectorWise") {
        setSectorWiseToDate(e.target.value);
        setToDate("");
      }
      if (graphType === "vendorPerfWise") {
        setVendorWisePerfToDate(e.target.value);
        setToDate("");
      }
      if (durationData.from) {
        let start = durationData.from.split("-");
        let end = data.split("-");
        let startYear = parseInt(start[1]);
        let endYear = parseInt(end[1]);
        let months = [];

        for (let i = startYear; i <= endYear; i++) {
          let endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
          let startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
          for (
            let j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            let month = j + 1;
            let displayMonth = month < 10 ? "0" + month : month;
            months.push([displayMonth, i].join("-"));
          }
        }
        setDurationData((prev) => ({ ...prev, months, to: data }));
      } else setDurationData((prev) => ({ ...prev, [e.target.name]: data }));
    }
  };
  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleChangeCategoryWiseGraphData = (e) => {
    const {
      target: { value, name },
    } = e;
    if (name === "categories")
      setCategoryWiseGraphData((prev) => ({
        ...prev,
        categories: typeof value === "string" ? value.split(",") : value,
      }));
    else if (name === "complaintStatus")
      setCategoryWiseGraphData((prev) => ({ ...prev, complaintStatus: value }));
  };

  const handleCloseOne = () => {
    setAnchorElOne(null);
  };

  const handleCloseFour = () => {
    setAnchorElFour(null);
  };

  const saveCanvas = (event, id) => {
    console.log(id);
    const canvasSave = document.getElementById(id);
    console.log(canvasSave);
    canvasSave.toBlob(function (blob) {
      saveAs(blob, `${id}.png`);
    });
    setAnchorElOne(null);
    setAnchorElTwo(null);
  };

  const handleOneCsv = () => {
    const temp = [];
    temp.push({
      status: fyWiseGraphDialogData.complaintStatus
        ? fyWiseGraphDialogData.complaintStatus
        : "Pending",
      yearOne: fyGraphData.data[0],
      yearTwo: fyGraphData.data[1],
    });
    console.log(temp[0]);
    setAnchorElOne(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "status",
        name: "Status",
      },
      {
        key: "yearOne",
        name: yearOne ? yearOne : new Date().getFullYear().toString(),
      },
      {
        key: "yearTwo",
        name: yearTwo ? yearTwo : (new Date().getFullYear() - 1).toString(),
      },
    ]);
  };

  const handleTwoCsv = () => {
    const temp = [];
    // const yearOne = fyWiseGraphDialogData.yearOne;
    // const yearTwo = fyWiseGraphDialogData.yearTwo;
    categoryComplaintGraphData.forEach((data) =>
      temp.push({
        category: data.categoryName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setAnchorElTwo(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "category",
        name: "Category",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
  };

  const handleThreeCsv = () => {
    const temp = [];
    mixedComplaintsGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setAnchorElThree(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
  };

  const handleFourCsv = () => {
    const temp = [];
    console.log(
      "monthlyPendingComplaintGraphData",
      monthlyPendingComplaintGraphData
    );
    monthlyPendingComplaintGraphData?.forEach((data) =>
      temp.push({
        vendor: data.vendorName,
        total: data.totalComplaints,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setAnchorElFour(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "vendor",
        name: "Vendor",
      },
      {
        key: "total",
        name: "Total Complaints",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
  };

  const handleFiveCsv = () => {
    const temp = [];
    sectorGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setAnchorElFive(null);
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
  };

  const handleSixCsv = () => {
    const temp = [];
    if (
      vendorPerformanceGraphData &&
      vendorPerformanceDialogData.type === "rating"
    ) {
      for (let i = 0; i < vendorPerformanceGraphData.data.length; i++) {
        temp.push({
          vendor: vendorPerformanceGraphData.labels[i],
          percentage: vendorPerformanceGraphData.data[i],
        });
      }
      setAnchorElSix(null);
      setCsvData([...temp]);
      setDownloadCsv(true);
      setHeaders([
        {
          key: "vendor",
          name: "Vendor",
        },
        {
          key: "percentage",
          name: "Customer Satisfaction Percentage",
        },
      ]);
    } else if (
      vendorPerformanceGraphData &&
      vendorPerformanceDialogData.type === "resolved_in_time"
    ) {
      for (let i = 0; i < vendorPerformanceGraphData.data.length; i++) {
        temp.push({
          vendor: vendorPerformanceGraphData.labels[i],
          resolved: vendorPerformanceGraphData.data[i],
        });
      }
      setAnchorElSix(null);
      setCsvData([...temp]);
      setDownloadCsv(true);
      setHeaders([
        {
          key: "vendor",
          name: "Vendor",
        },
        {
          key: "resolved",
          name: "Number of Complaints Resolved Within SLA",
        },
      ]);
    }
  };

  const yearOne = fyWiseGraphDialogData.yearOne.toString();
  const yearTwo = fyWiseGraphDialogData.yearTwo.toString();

  const handleDownload = async () => {
    // const png = await getPng();
    // console.log(png);
    // if (png) saveAs(png, "myChart.png");
    // setAnchorElTwo(null);
  };
  return (
    <>
      <div
        className={classes.root}
        style={{
          filter: isLoading ? "blur(1px)" : "",
          overflow: isLoading ? "hidden" : "auto",
          height: "100vh",
          pointerEvents: isLoading ? "none" : "",
        }}
      >
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Grid item lg={12} sm={12} xs={12}>
            <div class="card" style={{ borderRadius: 6, minHeight: "89vh" }}>
              <div class="card-body">
                <Grid
                  container
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container item xs={6}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                      }}
                    >
                      MIS Dashboard
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      class="card"
                      style={{
                        height: 35,
                        boxShadow: "0 3px 6px #7D858D",
                        width: "127px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateInitialQuickView({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-10px",
                            textAlign: "center",
                          }}
                        >
                          <PreviewRounded
                            style={{ color: "#ffffff", fontSize: 15 }}
                          />
                          &nbsp;QuickView
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Typography
                  style={{
                    fontWeight: "bold",
                    marginTop: 20,
                    marginBottom: 15,
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Search all at once selecting monthwise (by default fetched
                  data from{" "}
                  <span
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    {monthName(currentMonth)}
                  </span>
                  )
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography
                      container
                      style={{
                        color: "#6c757d",
                        textAlign: "left",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      From :&nbsp;
                      <input
                        type="month"
                        style={{
                          borderRadius: 6,
                          padding: 10,
                          border: "2px solid #6c757d",
                          marginBottom: 8,
                          outline: 0,
                          height: 40,
                        }}
                        onChange={handleDurationChange}
                        name="from"
                        value={fromDate}
                        min={"2021-08"}
                        max={
                          new Date().getFullYear().toString() +
                          "-" +
                          currentMonth.toString()
                        }
                      />
                      &nbsp; To &nbsp;
                      <input
                        type="month"
                        style={{
                          borderRadius: 6,
                          padding: 10,
                          border: "2px solid #6c757d",
                          marginBottom: 8,
                          outline: 0,
                          height: 40,
                        }}
                        onChange={handleDurationChange}
                        name="to"
                        value={ToDate}
                        min={fromDate}
                        max={
                          new Date().getFullYear().toString() +
                          "-" +
                          currentMonth.toString()
                        }
                      />
                    </Typography>
                  </Grid>
                  <button
                    style={{
                      backgroundColor:
                        fromDate === "" || ToDate === ""
                          ? "#c8c8c8"
                          : "#2c60ab",
                      border: "none",
                      color: "#fff",
                      marginLeft: 10,
                      padding: 6,
                      borderRadius: 5,
                      cursor:
                        fromDate === "" || ToDate === "" ? "none" : "pointer",
                      fontSize: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "40px",
                    }}
                    disabled={fromDate === "" || ToDate === ""}
                    onClick={loadInitialData}
                  >
                    Fetch Data
                  </button>
                  <button
                    style={{
                      backgroundColor: "#2c60ab",
                      border: "none",
                      color: "#fff",
                      marginLeft: 10,
                      padding: 6,
                      borderRadius: 5,
                      cursor: "pointer",
                      fontSize: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "40px",
                    }}
                    onClick={handleResetData}
                  >
                    <RestartAlt />
                    Reset
                  </button>
                </Grid>
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 20 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginLeft: 10,
                      marginTop: 5,
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#495057",
                    }}
                  >
                    All Complaint Statistics
                  </Typography>
                </Grid>

                {/* CATEGORY WISE COMPLAINT */}

                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-start", marginTop: 20 }}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card
                      style={{
                        padding: 5,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Category wise complaints -&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {categoryWiseGraphData?.complaintStatus}
                            </span>
                          </Typography>
                        </Grid>
                        {CategoryWisefromDate === "" ||
                        CategoryWiseToDate === "" ? (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Fetched from Current Month&nbsp;(
                            {monthName(currentMonth)})
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Duration&nbsp;from{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(CategoryWisefromDate.split("-")[1])}
                              &nbsp;{CategoryWiseToDate.split("-")[0]}
                            </span>{" "}
                            to{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(CategoryWiseToDate.split("-")[1])}
                              &nbsp;{CategoryWiseToDate.split("-")[0]}
                            </span>
                          </Typography>
                        )}
                        <Grid>
                          <button
                            onClick={() => {
                              setgraphType("categoryWise");
                              handleOpenCategoryDialog();
                            }}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Choose Category
                            <CategoryIcon
                              style={{ marginLeft: 6, fontSize: 16 }}
                            />
                          </button>
                          <button
                            onClick={handleTwoCsv}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Download Report
                            <BarChart style={{ marginLeft: 6, fontSize: 16 }} />
                          </button>
                          {/* <IconButton
                    aria-controls={openTwo ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openTwo ? "true" : undefined}
                    onClick={handleClickTwo}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElTwo}
                    open={openTwo}
                    onClose={handleCloseTwo}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleDownload}>
                      Download Image
                    </MenuItem>
                    <MenuItem onClick={handleTwoCsv}>
                      Download Report
                    </MenuItem>
                  </Menu> */}
                        </Grid>
                      </Grid>
                      <CardContent style={{ marginTop: 10 }}>
                        {categoryComplaintGraphData?.length === 0 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 22,
                                fontFamily: "Montserrat, sans-serif",
                                color: "#6c757d",
                              }}
                            >
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <CategoryComplaintGraph
                            data={categoryComplaintGraphData}
                            // ref={ref}
                          />
                        )}
                      </CardContent>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <Dialog
                            disableEscapeKeyDown
                            open={openCategoryDialog}
                            onClose={(event) =>
                              handleCloseCategoryDialog(event, "backdropClick")
                            }
                          >
                            <DialogTitle>
                              {/* {" "}
                              {durationData.months.length ? (
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat, sans-serif",
                                    color: "#6c757d",
                                    fontSize: 13,
                                    fontWeight: "bolder",
                                    marginBottom: 10,
                                    display: "flex",
                                  }}
                                >
                                  Selected Month from&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    {durationData.from}{" "}
                                  </span>
                                  &nbsp;to&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    {durationData.to}{" "}
                                  </span>
                                </Typography>
                              ) : ( */}
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  display: "flex",
                                }}
                              >
                                Please select a time Period first
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    container
                                    style={{
                                      color: "#6c757d",
                                      textAlign: "left",
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                    }}
                                  >
                                    From :&nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="from"
                                      value={CategoryWisefromDate}
                                      min={"2021-08"}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                    &nbsp; To &nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="to"
                                      min={
                                        CategoryWisefromDate
                                          ? CategoryWisefromDate
                                          : "2021-08"
                                      }
                                      value={CategoryWiseToDate}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* )} */}
                            </DialogTitle>
                            <DialogContent>
                              <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        Select Complaint Status :
                                      </Typography>
                                      <Select
                                        label="Choose Catagory"
                                        onChange={
                                          handleChangeCategoryWiseGraphData
                                        }
                                        value={
                                          categoryWiseGraphData.complaintStatus
                                        }
                                        name="complaintStatus"
                                        MenuProps={MenuProps}
                                        style={{
                                          color: "#212529",
                                          backgroundColor: "#fff",
                                          fontWeight: "bold",
                                          borderRadius: 6,
                                          width: "40%",
                                          height: 30,
                                          fontSize: "13px",
                                          padding: 8,
                                          paddingLeft: 6,
                                          marginLeft: 8,
                                          border: "1px solid #6c757d",
                                        }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="assigned">
                                          Pending
                                        </MenuItem>
                                        <MenuItem value="resolved">
                                          Resolved
                                        </MenuItem>
                                        <MenuItem value="closed">
                                          Closed
                                        </MenuItem>
                                        <MenuItem value="cancel">
                                          Cancelled
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid>
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          border: "1px solid #000",
                                          borderTopLeftRadius: 10,
                                          borderTopRightRadius: 10,
                                          backgroundColor: "#2f45c5",
                                          marginTop: 15,
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Select Category
                                          <Checkbox
                                            color="primary"
                                            style={{ color: "#fff" }}
                                            checked={
                                              categories?.length ===
                                              selectedCatgoriesForGraph?.length
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                categories?.map((row) => {
                                                  if (
                                                    !selectedCatgoriesForGraph.includes(
                                                      row?._id
                                                    )
                                                  ) {
                                                    selectedCatgoriesForGraph.push(
                                                      row?._id
                                                    );
                                                  }

                                                  setselectedCatgoriesForGraph([
                                                    ...selectedCatgoriesForGraph,
                                                  ]);
                                                });
                                              } else {
                                                setselectedCatgoriesForGraph(
                                                  []
                                                );
                                              }
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                            marginTop: 17,
                                          }}
                                        >
                                          Category Name
                                        </Typography>
                                      </Grid>
                                      <TableContainer
                                        component={Paper}
                                        style={{
                                          borderRadius: 10,
                                          marginRight: 10,
                                          height: 450,
                                        }}
                                      >
                                        <Table
                                          style={{ height: "max-content" }}
                                        >
                                          <TableBody style={{ padding: 2 }}>
                                            {categories?.map((row) => {
                                              return (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      color="primary"
                                                      checked={selectedCatgoriesForGraph.includes(
                                                        row._id
                                                      )}
                                                      name="categories"
                                                      key={row._id}
                                                      onChange={() => {
                                                        if (
                                                          selectedCatgoriesForGraph.includes(
                                                            row._id
                                                          )
                                                        ) {
                                                          selectedCatgoriesForGraph.splice(
                                                            selectedCatgoriesForGraph.indexOf(
                                                              row._id
                                                            ),
                                                            1
                                                          );
                                                          setselectedCatgoriesForGraph(
                                                            [
                                                              ...selectedCatgoriesForGraph,
                                                            ]
                                                          );
                                                        } else {
                                                          selectedCatgoriesForGraph.push(
                                                            row._id
                                                          );
                                                          setselectedCatgoriesForGraph(
                                                            [
                                                              ...selectedCatgoriesForGraph,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                      color: "black",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.textname
                                                      }
                                                    >
                                                      {row?.categoryName}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </DialogContent>
                            {errorMessageCategoryWiseGraph && (
                              <Typography
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: 14,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Error style={{ color: "red", fontSize: 20 }} />{" "}
                                {errorMessageCategoryWiseGraph}
                              </Typography>
                            )}
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  seterrorMessageCategoryWiseGraph("");
                                  setOpenCategoryDialog(false);
                                  setgraphType("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleCloseCategoryDialog}>
                                Ok
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>

                {/* SLA / Beyond SLA complaints */}
                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-start", marginTop: 20 }}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card
                      style={{
                        padding: 5,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            SLA / Beyond SLA complaints -&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {slaGraphData?.complaintStatus}
                            </span>
                          </Typography>
                        </Grid>

                        {SlaWiseFromDate === "" || SlaWiseToDate === "" ? (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Fetched from Current Month&nbsp;(
                            {monthName(currentMonth)})
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Duration&nbsp;from{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SlaWiseFromDate.split("-")[1])}
                              &nbsp;{SlaWiseFromDate.split("-")[0]}
                            </span>{" "}
                            to{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SlaWiseToDate.split("-")[1])}
                              &nbsp;{SlaWiseToDate.split("-")[0]}
                            </span>
                          </Typography>
                        )}
                        <Grid>
                          {/* <button
                            onClick={() => {
                              setgraphType("SlaWise");
                              handleOpenSLA();
                            }}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Choose Sector
                            <Apartment
                              style={{ marginLeft: 6, fontSize: 16 }}
                            />
                          </button> */}
                          <button
                            onClick={handleThreeCsv}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Download Report
                            <BarChart style={{ marginLeft: 6, fontSize: 16 }} />
                          </button>
                          {/* <IconButton
                      aria-controls={
                        openThree ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openThree ? "true" : undefined}
                      onClick={handleClickThree}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElThree}
                      open={openThree}
                      onClose={handleCloseThree}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleCloseThree}>
                        Download Image
                      </MenuItem>
                      <MenuItem onClick={handleThreeCsv}>
                        Download Report
                      </MenuItem>
                    </Menu> */}
                        </Grid>
                      </Grid>
                      <CardContent style={{ marginTop: 15 }}>
                        {mixedComplaintsGraphData?.length === 0 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 22,
                                fontFamily: "Montserrat, sans-serif",
                                color: "#6c757d",
                              }}
                            >
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <MixedComplaintsGraph
                            data={mixedComplaintsGraphData}
                          />
                        )}
                      </CardContent>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <Dialog
                            disableEscapeKeyDown
                            open={openSLA}
                            onClose={(event) => {
                              seterrorMessageSectorWiseGraph("");
                              handleCloseSLA(event, "backdropClick");
                            }}
                          >
                            <DialogTitle>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  display: "flex",
                                }}
                              >
                                Please select a time Period first
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    container
                                    style={{
                                      color: "#6c757d",
                                      textAlign: "left",
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                    }}
                                  >
                                    From :&nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="from"
                                      value={
                                        SlaWiseFromDate
                                          ? SlaWiseFromDate
                                          : "2021-08"
                                      }
                                      min={"2021-08"}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                    &nbsp; To &nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="to"
                                      min={
                                        SlaWiseFromDate
                                          ? SlaWiseFromDate
                                          : "2021-08"
                                      }
                                      value={SlaWiseToDate}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </DialogTitle>
                            <DialogContent>
                              <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        Select Complaint Status :
                                      </Typography>
                                      <Select
                                        label="Choose Sector"
                                        onChange={handleChangeSectorSla}
                                        value={slaGraphData.complaintStatus}
                                        MenuProps={MenuProps}
                                        name="complaintStatus"
                                        style={{
                                          color: "#212529",
                                          backgroundColor: "#fff",
                                          fontWeight: "bold",
                                          borderRadius: 6,
                                          width: "40%",
                                          height: 30,
                                          fontSize: "13px",
                                          padding: 8,
                                          paddingLeft: 6,
                                          marginLeft: 8,
                                          border: "1px solid #6c757d",
                                        }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="assigned">
                                          Pending
                                        </MenuItem>
                                        <MenuItem value="resolved">
                                          Resolved
                                        </MenuItem>
                                        <MenuItem value="closed">
                                          Closed
                                        </MenuItem>
                                        <MenuItem value="cancel">
                                          Cancelled
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid>
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          border: "1px solid #000",
                                          borderTopLeftRadius: 10,
                                          borderTopRightRadius: 10,
                                          backgroundColor: "#2f45c5",
                                          marginTop: 15,
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Select Sector
                                          <Checkbox
                                            color="primary"
                                            style={{ color: "#fff" }}
                                            checked={
                                              sectors?.length ===
                                              selectedSectorsSlaForGraph?.length
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                sectors?.map((row) => {
                                                  if (
                                                    !selectedSectorsSlaForGraph.includes(
                                                      row
                                                    )
                                                  ) {
                                                    selectedSectorsSlaForGraph.push(
                                                      row
                                                    );
                                                  }
                                                  setselectedSectorsSlaForGraph(
                                                    [
                                                      ...selectedSectorsSlaForGraph,
                                                    ]
                                                  );
                                                });
                                              } else {
                                                setselectedSectorsSlaForGraph(
                                                  []
                                                );
                                              }
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                            marginTop: 17,
                                          }}
                                        >
                                          Sector Name
                                        </Typography>
                                      </Grid>
                                      <TableContainer
                                        component={Paper}
                                        style={{
                                          borderRadius: 10,
                                          marginRight: 10,
                                          height: 450,
                                        }}
                                      >
                                        <Table
                                          style={{ height: "max-content" }}
                                        >
                                          <TableBody style={{ padding: 2 }}>
                                            {sectors?.map((row) => {
                                              return (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      color="primary"
                                                      checked={selectedSectorsSlaForGraph.includes(
                                                        row
                                                      )}
                                                      name="sectors"
                                                      key={row}
                                                      onChange={() => {
                                                        if (
                                                          selectedSectorsSlaForGraph.includes(
                                                            row
                                                          )
                                                        ) {
                                                          selectedSectorsSlaForGraph.splice(
                                                            selectedSectorsSlaForGraph.indexOf(
                                                              row
                                                            ),
                                                            1
                                                          );
                                                          setselectedSectorsSlaForGraph(
                                                            [
                                                              ...selectedSectorsSlaForGraph,
                                                            ]
                                                          );
                                                        } else {
                                                          selectedSectorsSlaForGraph.push(
                                                            row
                                                          );
                                                          setselectedSectorsSlaForGraph(
                                                            [
                                                              ...selectedSectorsSlaForGraph,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                      color: "black",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.textname
                                                      }
                                                    >
                                                      {row}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </DialogContent>
                            {errorMessageSectorWiseGraph && (
                              <Typography
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: 14,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Error style={{ color: "red", fontSize: 20 }} />{" "}
                                {errorMessageSectorWiseGraph}
                              </Typography>
                            )}
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setOpenSLA(false);
                                  seterrorMessageSectorWiseGraph("");
                                  setgraphType("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleCloseSLA}>Ok</Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>

                {/* Vendor wise complaints */}

                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-start", marginTop: 20 }}
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        padding: 5,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Vendor wise complaints - &nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {vendorWiseGraphData?.complaintStatus}
                            </span>
                          </Typography>
                        </Grid>

                        {VendorWiseFromDate === "" ||
                        VendorWiseToDate === "" ? (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Fetched from Current Month&nbsp;(
                            {monthName(currentMonth)})
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Duration&nbsp;from{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(VendorWiseFromDate.split("-")[1])}
                              &nbsp;{VendorWiseFromDate.split("-")[0]}
                            </span>{" "}
                            to{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(VendorWiseToDate.split("-")[1])}
                              &nbsp;{VendorWiseToDate.split("-")[0]}
                            </span>
                          </Typography>
                        )}
                        <Grid>
                          <button
                            onClick={() => {
                              setgraphType("vendorWise");
                              handleOpenVendorComplaints();
                            }}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Choose Vendor
                            <Engineering
                              style={{ marginLeft: 6, fontSize: 16 }}
                            />
                          </button>
                          <button
                            onClick={handleFourCsv}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Download Report
                            <BarChart style={{ marginLeft: 6, fontSize: 16 }} />
                          </button>
                          {/* <IconButton
                      aria-label="settings"
                      aria-controls={
                        openFour ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openFour ? "true" : undefined}
                      onClick={handleClickFour}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFour}
                      open={openFour}
                      onClose={handleCloseFour}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleCloseFour}>
                        Download Image
                      </MenuItem>
                      <MenuItem onClick={handleFourCsv}>
                        Download Report
                      </MenuItem>
                    </Menu> */}
                        </Grid>
                      </Grid>
                      <CardContent style={{ marginTop: 15 }}>
                        {monthlyPendingComplaintGraphData?.length === 0 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 22,
                                fontFamily: "Montserrat, sans-serif",
                                color: "#6c757d",
                              }}
                            >
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <MonthlyPendingComplaintGraph
                            data={monthlyPendingComplaintGraphData}
                          />
                        )}
                      </CardContent>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <Dialog
                            disableEscapeKeyDown
                            open={openVendorComplaints}
                            onClose={(event) => {
                              seterrorMessageVendorWiseGraph("");
                              handleCloseVendorComplaints(
                                event,
                                "backdropClick"
                              );
                            }}
                          >
                            <DialogTitle>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  display: "flex",
                                }}
                              >
                                Please select a time Period first
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    container
                                    style={{
                                      color: "#6c757d",
                                      textAlign: "left",
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                    }}
                                  >
                                    From :&nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="from"
                                      value={VendorWiseFromDate}
                                      min={"2021-08"}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                    &nbsp; To &nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="to"
                                      min={
                                        VendorWiseFromDate
                                          ? VendorWiseFromDate
                                          : "2021-08"
                                      }
                                      value={VendorWiseToDate}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </DialogTitle>
                            <DialogContent>
                              <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        Select Complaint Status :
                                      </Typography>
                                      <Select
                                        label="Choose Sector"
                                        onChange={
                                          handleChangeVendorWiseGraphData
                                        }
                                        value={
                                          vendorWiseGraphData.complaintStatus
                                        }
                                        MenuProps={MenuProps}
                                        name="complaintStatus"
                                        style={{
                                          color: "#212529",
                                          backgroundColor: "#fff",
                                          fontWeight: "bold",
                                          borderRadius: 6,
                                          width: "40%",
                                          height: 30,
                                          fontSize: "13px",
                                          padding: 8,
                                          paddingLeft: 6,
                                          marginLeft: 8,
                                          border: "1px solid #6c757d",
                                        }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="assigned">
                                          Pending
                                        </MenuItem>
                                        <MenuItem value="resolved">
                                          Resolved
                                        </MenuItem>
                                        <MenuItem value="closed">
                                          Closed
                                        </MenuItem>
                                        <MenuItem value="cancel">
                                          Cancelled
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid>
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          border: "1px solid #000",
                                          borderTopLeftRadius: 10,
                                          borderTopRightRadius: 10,
                                          backgroundColor: "#2f45c5",
                                          marginTop: 15,
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Select Vendor
                                          <Checkbox
                                            color="primary"
                                            style={{ color: "#fff" }}
                                            checked={
                                              vendors?.length ===
                                              selectedVendorsForGraph?.length
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                vendors?.map((row) => {
                                                  if (
                                                    !selectedVendorsForGraph?.includes(
                                                      row?._id
                                                    )
                                                  ) {
                                                    selectedVendorsForGraph.push(
                                                      row?._id
                                                    );
                                                  }
                                                  setselectedVendorsForGraph([
                                                    ...selectedVendorsForGraph,
                                                  ]);
                                                });
                                              } else {
                                                setselectedVendorsForGraph([]);
                                              }
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                            marginTop: 17,
                                          }}
                                        >
                                          Vendor Name
                                        </Typography>
                                      </Grid>
                                      <TableContainer
                                        component={Paper}
                                        style={{
                                          borderRadius: 10,
                                          marginRight: 10,
                                          height: 450,
                                        }}
                                      >
                                        <Table
                                          style={{ height: "max-content" }}
                                        >
                                          <TableBody style={{ padding: 2 }}>
                                            {vendors?.map((row) => {
                                              return (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      color="primary"
                                                      checked={selectedVendorsForGraph.includes(
                                                        row._id
                                                      )}
                                                      name="sectors"
                                                      key={row._id}
                                                      onChange={() => {
                                                        if (
                                                          selectedVendorsForGraph.includes(
                                                            row._id
                                                          )
                                                        ) {
                                                          selectedVendorsForGraph.splice(
                                                            selectedVendorsForGraph.indexOf(
                                                              row._id
                                                            ),
                                                            1
                                                          );
                                                          setselectedVendorsForGraph(
                                                            [
                                                              ...selectedVendorsForGraph,
                                                            ]
                                                          );
                                                        } else {
                                                          selectedVendorsForGraph.push(
                                                            row._id
                                                          );
                                                          setselectedVendorsForGraph(
                                                            [
                                                              ...selectedVendorsForGraph,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row._id"
                                                    sx={{
                                                      color: "black",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.textname
                                                      }
                                                    >
                                                      {row.name}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </DialogContent>
                            {errorMessageVendorWiseGraph && (
                              <Typography
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: 14,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Error style={{ color: "red", fontSize: 20 }} />{" "}
                                {errorMessageVendorWiseGraph}
                              </Typography>
                            )}
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setopenVendorComplaints(false);
                                  seterrorMessageVendorWiseGraph("");
                                  setgraphType("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleCloseVendorComplaints}>
                                Ok
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>

                {/* SECTOR WISE COMPLAINTS*/}
                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-start", marginTop: 20 }}
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        padding: 5,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Sector wise complaints -&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {sectorWiseGraphData?.complaintStatus}
                            </span>
                          </Typography>
                        </Grid>
                        {SectorWiseFromDate === "" ||
                        SectorWiseToDate === "" ? (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Fetched from Current Month&nbsp;(
                            {monthName(currentMonth)})
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 5,
                              fontSize: 13,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#495057",
                            }}
                          >
                            Duration&nbsp;from{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SectorWiseFromDate.split("-")[1])}
                              &nbsp;{SectorWiseFromDate.split("-")[0]}
                            </span>{" "}
                            to{" "}
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {monthName(SectorWiseToDate.split("-")[1])}
                              &nbsp;{SectorWiseToDate.split("-")[0]}
                            </span>
                          </Typography>
                        )}
                        <Grid>
                          {/* <button
                            onClick={() => {
                              setgraphType("sectorWise");
                              handleOpenSector();
                            }}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Choose Sector
                            <Apartment
                              style={{ marginLeft: 6, fontSize: 16 }}
                            />
                          </button> */}
                          <button
                            onClick={handleFiveCsv}
                            style={{
                              backgroundColor: "#2c60ab",
                              border: "none",
                              color: "#fff",
                              marginRight: 2,
                              padding: 6,
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 14,
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 5,
                            }}
                          >
                            Download Report
                            <BarChart style={{ marginLeft: 6, fontSize: 16 }} />
                          </button>
                          {/*         <IconButton
                      aria-controls={
                        openFive ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openFive ? "true" : undefined}
                      onClick={handleClickFive}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFive}
                      open={openFive}
                      onClose={handleCloseFive}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleCloseFive}>
                        Download Image
                      </MenuItem>
                      <MenuItem onClick={handleFiveCsv}>
                        Download Report
                      </MenuItem>
                    </Menu>*/}
                        </Grid>
                      </Grid>
                      <CardContent style={{ marginTop: 15 }}>
                        {sectorGraphData?.length === 0 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 22,
                                fontFamily: "Montserrat, sans-serif",
                                color: "#6c757d",
                              }}
                            >
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <SectorComplaintGraph data={sectorGraphData} />
                        )}
                      </CardContent>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <Dialog
                            disableEscapeKeyDown
                            open={openSectorDialog}
                            onClose={(event) => {
                              seterrorMessageSectorWiseGraph("");
                              handleCloseSector(event, "backdropClick");
                            }}
                          >
                            <DialogTitle>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  display: "flex",
                                }}
                              >
                                Please select a time Period first
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    container
                                    style={{
                                      color: "#6c757d",
                                      textAlign: "left",
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                    }}
                                  >
                                    From :&nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="from"
                                      value={SectorWiseFromDate}
                                      min={"2021-08"}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                    &nbsp; To &nbsp;
                                    <input
                                      type="month"
                                      style={{
                                        borderRadius: 6,
                                        padding: 10,
                                        border: "2px solid #6c757d",
                                        marginBottom: 8,
                                        outline: 0,
                                        height: 35,
                                        width: 130,
                                      }}
                                      onChange={handleDurationChange}
                                      name="to"
                                      min={
                                        SectorWiseFromDate
                                          ? SectorWiseFromDate
                                          : "2021-08"
                                      }
                                      value={SectorWiseToDate}
                                      max={
                                        new Date().getFullYear().toString() +
                                        "-" +
                                        currentMonth.toString()
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </DialogTitle>
                            <DialogContent>
                              <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        Select Complaint Status :
                                      </Typography>
                                      <Select
                                        label="Choose Sector"
                                        onChange={handleChangeSector}
                                        value={
                                          sectorWiseGraphData.complaintStatus
                                        }
                                        MenuProps={MenuProps}
                                        name="complaintStatus"
                                        style={{
                                          color: "#212529",
                                          backgroundColor: "#fff",
                                          fontWeight: "bold",
                                          borderRadius: 6,
                                          width: "40%",
                                          height: 30,
                                          fontSize: "13px",
                                          padding: 8,
                                          paddingLeft: 6,
                                          marginLeft: 8,
                                          border: "1px solid #6c757d",
                                        }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="assigned">
                                          Pending
                                        </MenuItem>
                                        <MenuItem value="resolved">
                                          Resolved
                                        </MenuItem>
                                        <MenuItem value="closed">
                                          Closed
                                        </MenuItem>
                                        <MenuItem value="cancel">
                                          Cancelled
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid>
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                          border: "1px solid #000",
                                          borderTopLeftRadius: 10,
                                          borderTopRightRadius: 10,
                                          backgroundColor: "#2f45c5",
                                          marginTop: 15,
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Select Sector
                                          <Checkbox
                                            color="primary"
                                            style={{ color: "#fff" }}
                                            checked={
                                              sectors?.length ===
                                              selectedSectorsForGraph?.length
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                sectors?.map((row) => {
                                                  if (
                                                    !selectedSectorsForGraph.includes(
                                                      row
                                                    )
                                                  ) {
                                                    selectedSectorsForGraph.push(
                                                      row
                                                    );
                                                  }

                                                  setselectedSectorsForGraph([
                                                    ...selectedSectorsForGraph,
                                                  ]);
                                                });
                                              } else {
                                                setselectedSectorsForGraph([]);
                                              }
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          style={{
                                            margin: 10,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        >
                                          Sector Name
                                        </Typography>
                                      </Grid>
                                      <TableContainer
                                        component={Paper}
                                        style={{
                                          borderRadius: 10,
                                          marginRight: 10,
                                          height: 450,
                                        }}
                                      >
                                        <Table
                                          style={{ height: "max-content" }}
                                        >
                                          <TableBody style={{ padding: 2 }}>
                                            {sectors?.map((row) => {
                                              return (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      color="primary"
                                                      checked={selectedSectorsForGraph.includes(
                                                        row
                                                      )}
                                                      name="sectors"
                                                      key={row}
                                                      onChange={() => {
                                                        if (
                                                          selectedSectorsForGraph.includes(
                                                            row
                                                          )
                                                        ) {
                                                          selectedSectorsForGraph.splice(
                                                            selectedSectorsForGraph.indexOf(
                                                              row
                                                            ),
                                                            1
                                                          );
                                                          setselectedSectorsForGraph(
                                                            [
                                                              ...selectedSectorsForGraph,
                                                            ]
                                                          );
                                                        } else {
                                                          selectedSectorsForGraph.push(
                                                            row
                                                          );
                                                          setselectedSectorsForGraph(
                                                            [
                                                              ...selectedSectorsForGraph,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                      color: "black",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.textname
                                                      }
                                                    >
                                                      {row}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </DialogContent>
                            {errorMessageSectorWiseGraph && (
                              <Typography
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: 14,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Error style={{ color: "red", fontSize: 20 }} />{" "}
                                {errorMessageSectorWiseGraph}
                              </Typography>
                            )}
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setOpenSectorDialog(false);
                                  seterrorMessageSectorWiseGraph("");
                                  setgraphType("");
                                }}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleCloseSector}>Ok</Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <AddComplaint
            addComplaintModal={addComplaintModal}
            setaddComplaintModal={setaddComplaintModal}
          />
          {
            <Modal open={downloadCsv}>
              <Paper
                style={{ backgroundColor: "#fff", width: 500 }}
                className={classes.deleteModal}
              >
                {csvData?.length > 0 && (
                  <>
                    <Grid container style={{ justifyContent: "center" }}>
                      <img
                        style={{
                          cursor: "pointer",
                          height: 100,
                          width: 100,
                          marginLeft: 5,
                          marginRight: 10,
                          marginTop: -50,
                          borderTopRightRadius: 50,
                          borderTopLeftRadius: 50,
                        }}
                        src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif"
                        alt="Sociohood"
                      />
                    </Grid>

                    <Typography
                      style={{
                        fontSize: 23,
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Success !
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        color: "#6c757d",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: 6,
                      }}
                    >
                      Data is successfully fetched and ready for download
                    </Typography>
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    >
                      <button
                        className={classes.cancelButton}
                        onClick={() => {
                          setDownloadCsv(false);
                        }}
                      >
                        Cancel
                        <Close style={{ marginLeft: 6, fontSize: 16 }} />
                      </button>
                      <ExportJsonCsv
                        fileTitle={
                          authState.user?.name +
                          `(${authState.user?.email})` +
                          "-" +
                          "Fy Complaints Data"
                        }
                        headers={headers}
                        items={csvData}
                        onClick={() => {
                          setDownloadCsv(false);
                          csvData([]);
                        }}
                        className={classes.successButton}
                        style={{ marginLeft: 24 }}
                      >
                        Download Report
                        <CloudDownloadOutlined style={{ marginLeft: 6 }} />
                      </ExportJsonCsv>
                    </Grid>
                  </>
                )}
                {csvData?.length === 0 && (
                  <>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        color: "#6c757d",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: 6,
                      }}
                    >
                      No data for Download
                    </Typography>
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    >
                      <button
                        style={{ width: "50%" }}
                        className={classes.cancelButton}
                        onClick={() => {
                          setDownloadCsv(false);
                        }}
                      >
                        Close
                        <Close style={{ marginLeft: 6, fontSize: 16 }} />
                      </button>
                    </Grid>
                  </>
                )}
              </Paper>
            </Modal>
          }
        </main>
      </div>
      {/* <Modal open={isLoading}>
        <Paper
          style={{ backgroundColor: "#fff", width: 20 }}
          className={classes.deleteModal}
        > */}
      {isLoading && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined style={{ fontSize: 60, color: "#03045E" }} />
          <Typography
            style={{
              color: "#03045E",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -14,
              fontWeight: "bold",
            }}
          >
            Please wait....
          </Typography>
        </Grid>
      )}

      {/* </Paper> */}
      {/* </Modal> */}
    </>
  );
}

export default MisDashboard;
