import { CssBaseline, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useNavigate } from "react-router";
import "./errorpage.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "##ffb000",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: "100vh",
  },
}));

function ErrorPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <div class="center">
        <div class="error">
          <div class="number">4</div>
          <div class="illustration">
            <div class="circle"></div>
            <div class="clip">
              <div class="paper">
                <div class="face">
                  <div class="eyes">
                    <div class="eye eye-left"></div>
                    <div class="eye eye-right"></div>
                  </div>
                  <div class="rosyCheeks rosyCheeks-left"></div>
                  <div class="rosyCheeks rosyCheeks-right"></div>
                  <div class="mouth"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="number">4</div>
        </div>
        <div class="description-error-text">
          <p>
            Oops! We're sorry for the inconvenience. You might be here because:
          </p>
          <ul>
            <li style={{fontWeight:"bold"}}>- &nbsp;It seems the page you're looking for couldn't be found.</li>
            <li style={{fontWeight:"bold"}}>- &nbsp;Access to the requested resource has been denied.</li>
          </ul>
        </div>
        <a onClick={()=>{
          localStorage.setItem("subModuleName", "");
          navigate("/dashboard")
        }} class="button">Back Home</a>
      </div>
    </>
  );
}

export default ErrorPage;
