import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, List, ListItem } from "@material-ui/core";
import useStyles from "../styles";
import * as api from "../../../api/index";
import { BASE_URL } from "../../../utils/base";
import { useParams } from "react-router-dom";
import VisitorLogsTable from "./VisitorLogsTable";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Apartment,
  KeyboardBackspaceRounded,
  LocationCity,
  Phone,
  Schedule,
  TimerOff,
  VpnKey,
} from "@material-ui/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AddModeratorOutlined,
  Circle,
  RestartAlt,
  ContentCopy,
} from "@mui/icons-material";
import { Pagination } from "@mui/material";
import StaffLogsTable from "./StaffLogsTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Tag } from "antd";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import ResidentLogTable from "./ResidentLogTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import Chip from "@mui/material/Chip";
import {
  handlePropertyName,
  handleFlatName,
} from "../../../contextAPI/NamingConvention";
import { navigateInitialVisitor } from "../../../Navigation/Navigations";
import { Key } from "@mui/icons-material";
import VisitorIdShowModal from "./VisitorIdShowModal";

function EntryExitLogs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pleasewait, setpleasewait] = useState(false);
  const { id } = useParams();
  const [entryExitLogsData, setentryExitLogsData] = useState([]);
  const [visitorDetails, setvisitorDetails] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [count, setCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [staffFlatInfo, setstaffFlatInfo] = useState(false);
  var date = moment(new Date()).format("YYYY-MM-DD");
  const location = useLocation();
  const pathName = location.pathname;
  const type = pathName?.split("/")?.[2];
  const [idModal, setIdModal] = useState(false);
  const [seeVisitorId, setseeVisitorId] = useState([]);

  const handleOpenDialog = () => {
    setstaffFlatInfo(true);
  };
  const handleCloseDialogBox = () => {
    setstaffFlatInfo(false);
  };

  const handleEntryExitLogs = async () => {
    try {
      setpleasewait(true);
      setTimeout(() => {
        setpleasewait(false);
      }, 40000);
      const sendData = {
        visitorId: id,
        pageNumber: page,
        startDate: startDate,
        endDate: endDate === "" ? date : endDate,
      };
      const res = await api.getEntryExitlogs(sendData);
      if (res?.data?.success) {
        setCount(res.data?.log?.count);
        setentryExitLogsData(res?.data?.log?.log);
        setvisitorDetails(res?.data?.log?.visitor);

        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };
  useEffect(() => {
    handleEntryExitLogs();
  }, [startDate, page]);

  const handleResetFilter = () => {
    setstartDate("");
    setendDate("");
  };

  function formatTime(timeString) {
    if (timeString) {
      const [hourString, minute] = timeString?.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }
  }

  const removeStartDate = () => {
    setstartDate("");
    handleEntryExitLogs();
  };
  const removeEndDate = () => {
    setendDate("");
    handleEntryExitLogs();
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div class="card" style={{ borderRadius: 10 }}>
          <div class="card-body">
            <Grid container>
              <Grid container item xs={4}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  {`Gate Management > `}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(navigateInitialVisitor({ type }));
                    }}
                  >
                    {type === "staff"
                      ? "Staff"
                      : type === "resident"
                      ? "Resident"
                      : "Visitor"}
                  </span>

                  <span style={{ color: "#000" }}>{` > Logs`}</span>
                  {!pleasewait ? (
                    <RefreshIcon
                      title={`refresh ${type} log Page`}
                      onClick={handleEntryExitLogs}
                      style={{
                        marginLeft: 5,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="laoding..."
                      style={{ marginLeft: 6, color: "#000", marginTop: -3 }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={8}
                style={{
                  justifyContent: "flex-end",
                  marginBottom: 12,
                  marginTop: -8,
                }}
              >
                <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 12,
                    marginTop: 12,
                    marginRight: 8,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Start Date
                </Typography>
                <input
                  type="date"
                  style={{
                    borderRadius: 10,
                    padding: 5,
                    width: 160,
                    height: 40,
                    border: "1px solid #416082",
                    outline: 0,
                    marginTop: 3,
                  }}
                  onChange={(event) => {
                    setstartDate(event.target.value);
                    setPage(1);
                  }}
                  placeholder="Choose Start Date"
                  value={startDate}
                  max={date}
                />

                <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 12,
                    marginTop: 12,
                    marginRight: 8,
                    marginLeft: 10,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  End Date
                </Typography>
                <input
                  type="date"
                  style={{
                    borderRadius: 10,
                    padding: 5,
                    width: 160,
                    height: 40,
                    border: "1px solid #416082",
                    outline: 0,
                    marginTop: 3,
                  }}
                  min={startDate}
                  max={date}
                  disabled={!startDate}
                  onChange={(event) => {
                    setendDate(event.target.value);
                  }}
                  placeholder="Choose End Date"
                  value={endDate === "" ? date : endDate}
                />

                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        handleEntryExitLogs();
                        setPage(1);
                      }}
                    >
                      <SearchIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      FIND
                    </p>
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        handleResetFilter();
                      }}
                    >
                      <RestartAlt
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      RESET
                    </p>
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginTop: 7,
                    marginLeft: 8,
                  }}
                  onClick={() => {
                    navigate(navigateInitialVisitor({ type }));
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      BACK
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ justifyContent: "flex-end", marginTop: 5 }}
            >
              {startDate && (
                <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 14,
                    marginLeft: 6,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <Chip
                    style={{
                      backgroundColor: "rgba(54, 162, 235, 0.2)",
                    }}
                    label={startDate}
                    onDelete={removeStartDate}
                  />
                </Typography>
              )}
              {endDate && (
                <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 14,
                    marginLeft: 6,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <Chip
                    style={{
                      backgroundColor: "rgba(54, 162, 235, 0.2)",
                    }}
                    label={endDate}
                    onDelete={removeEndDate}
                  />
                </Typography>
              )}
            </Grid>
            <div class="container mt-2 d-flex justify-content-center">
              <div class="card p-3" style={{ boxShadow: "0 3px 6px #4a6078" }}>
                <div class="d-flex align-items-center">
                  <div class="image">
                    {visitorDetails?.image && (
                      <img
                        src={
                          visitorDetails?.image?.includes("https:")
                            ? visitorDetails?.image
                            : !visitorDetails?.image?.includes("https:")
                            ? `${BASE_URL}/user/${visitorDetails.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        class="rounded"
                        width="145"
                        height="130"
                      />
                    )}
                    {(visitorDetails?.image == "" ||
                      visitorDetails?.image == null) && (
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        class="rounded"
                        width="145"
                        height="130"
                      />
                    )}
                  </div>

                  <div class="ml-3 w-100" style={{ marginLeft: 6 }}>
                    <h4
                      class="mb-0 mt-0"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontWeight: "bold",
                      }}
                    >
                      {visitorDetails?.name}
                    </h4>
                    <span
                      style={{
                        // width: "50px",
                        padding: 5,
                        textTransform: "capitalize",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                      class="badge bg-primary"
                    >
                      {visitorDetails?.role}
                    </span>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      <Phone style={{ fontSize: "15px", color: "#000" }} />{" "}
                      {visitorDetails?.phone}
                    </Typography>
                    {type === "staff" && (
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        <Apartment
                          style={{ fontSize: "15px", color: "#000" }}
                        />{" "}
                        {visitorDetails?.flatsStatus?.length}&nbsp;
                        {handleFlatName()} details
                        <InfoIcon
                          title="More Info"
                          style={{
                            color: "#2f45c5",
                            cursor: "pointer",
                            marginLeft: 5,
                            fontSize: 19,
                          }}
                          onClick={handleOpenDialog}
                        />
                      </Typography>
                    )}
                    {type === "staff" && (
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        <VpnKey style={{ fontSize: "15px", color: "#000" }} />{" "}
                        {visitorDetails?.portNumber}
                        <CopyToClipboard
                          title="Copy Staff Code"
                          text={visitorDetails?.portNumber}
                          onCopy={() => {
                            toast.success("Copy to Clipboard", {
                              position: "top-right",
                              autoClose: 500,
                              hideProgressBar: false,
                              closeOnClick: true,
                            });
                          }}
                        >
                          <ContentCopy
                            style={{
                              fontSize: 17,
                              marginLeft: 6,
                              color: "#000",
                              cursor: "pointer",
                            }}
                          />
                        </CopyToClipboard>
                      </Typography>
                    )}

                    {type === "resident" && (
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        <LocationCity
                          style={{ fontSize: "15px", color: "#000" }}
                        />{" "}
                        {visitorDetails?.property?.name}-
                        {visitorDetails?.floor?.name}-
                        {visitorDetails?.flat?.number}
                      </Typography>
                    )}
                    {type !== "resident" && (
                      <>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          <Circle style={{ fontSize: "15px", color: "#000" }} />{" "}
                          {visitorDetails?.visitorType
                            ? visitorDetails?.visitorType
                            : visitorDetails?.staffType}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          <AddModeratorOutlined
                            style={{ fontSize: "15px", color: "#000" }}
                          />{" "}
                          {visitorDetails?.createdBy?.name}(
                          {visitorDetails?.createdBy?.role})
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          <Schedule
                            style={{ fontSize: "15px", color: "#000" }}
                          />{" "}
                          {moment(visitorDetails?.createdAt).format(
                            "DD/MM/YYYY - h:mm A"
                          )}
                        </Typography>
                        {visitorDetails?.idProof ? (
                          <Typography
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              color: "#000",
                              fontWeight: "bold",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              setseeVisitorId(visitorDetails?.idProof);
                              setIdModal(true);
                            }}
                          >
                            <Key style={{ fontSize: "15px", color: "#000" }} />{" "}
                            See Id
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                          >
                            <Key style={{ fontSize: "15px", color: "#000" }} />{" "}
                            No id added
                          </Typography>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {type === "visitor" && (
              <VisitorLogsTable
                pleasewait={pleasewait}
                entryExitLogsData={entryExitLogsData}
                type={type}
                page={page}
              />
            )}
            {type === "staff" && (
              <StaffLogsTable
                pleasewait={pleasewait}
                entryExitLogsData={entryExitLogsData}
                type={type}
                page={page}
                staffDetails={visitorDetails}
              />
            )}
            {type === "resident" && (
              <ResidentLogTable
                pleasewait={pleasewait}
                entryExitLogsData={entryExitLogsData}
                type={type}
                page={page}
              />
            )}
            {entryExitLogsData?.length > 0 && type !== "staff" && (
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 10 }}
              >
                <Pagination
                  className={classes.pagination}
                  count={count}
                  color="primary"
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </Grid>
            )}
            {!entryExitLogsData?.length && !pleasewait && (
              <Grid container style={{ justifyContent: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 17,
                    marginTop: 7,
                    fontWeight: "bolder",
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  No Entry / Exit Logs
                </Typography>
              </Grid>
            )}
          </div>
        </div>
      </main>
      <VisitorIdShowModal
        idModal={idModal}
        setIdModal={setIdModal}
        accomapningVisitorData={seeVisitorId}
      />
      <Dialog
        open={staffFlatInfo}
        keepMounted
        onClose={handleCloseDialogBox}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 15,
              fontWeight: "bolder",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Information of &nbsp;
            <span style={{ color: "#2f45c5", fontWeight: "bold" }}>
              {visitorDetails?.name}
            </span>
            &nbsp; {handleFlatName()} Details
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 15,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {handleFlatName()} Status
            {visitorDetails?.status === "pending" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="orange"
                icon={
                  <AccessTimeFilledIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                PENDING
              </Tag>
            )}
            {visitorDetails?.status === "reject" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="red"
                icon={
                  <DangerousIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                REJECTED
              </Tag>
            )}
            {visitorDetails?.status === "allowed" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="green"
                icon={
                  <CheckCircleIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                ALLOWED
              </Tag>
            )}
            {visitorDetails?.status === "expired" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="red"
                icon={
                  <TimerOff
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                EXPIRED
              </Tag>
            )}
          </Typography>
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Action By -{" "}
            {visitorDetails?.actionBy?.name
              ? visitorDetails?.actionBy?.name
              : "No Actioned yet"}
            {visitorDetails?.actionBy?.role && (
              <span
                style={{
                  width: "50px",
                  padding: 5,
                  textTransform: "capitalize",
                  marginLeft: 5,
                }}
                class="badge bg-primary"
              >
                {visitorDetails?.actionBy?.role}
              </span>
            )}
          </span>
          &nbsp; &nbsp;
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Action Time -
            {visitorDetails?.actionTime
              ? moment(visitorDetails?.actionTime).format("DD/MM/YYYY - h:mm A")
              : "No Actioned Yet"}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handlePropertyName()} Name
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Number
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
                marginRight: 20,
              }}
            >
              {handleFlatName()} Shift Timing
            </Typography>
          </Grid>
          <Grid>
            <List>
              {visitorDetails?.flatsStatus?.length > 0 ? (
                <>
                  {visitorDetails?.flatsStatus?.map((flat) => {
                    return (
                      <ListItem>
                        <ApartmentIcon
                          style={{
                            fontSize: 15,
                            color: "#6c757d",
                            marginRight: 4,
                            marginLeft: -17,
                          }}
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={5}>
                            <Typography style={{ fontSize: 15 }}>
                              {flat?.property?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography style={{ fontSize: 15 }}>
                              {flat?.flat?.number}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              style={{
                                fontSize: 15,
                                textAlign: "end",
                                marginLeft: 15,
                              }}
                            >
                              {formatTime(flat?.shiftFrom)} &nbsp;
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "#6c757d",
                                }}
                              >
                                TO
                              </span>{" "}
                              &nbsp;{formatTime(flat?.shiftTo)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </>
              ) : (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 15,
                      marginBottom: 5,
                    }}
                  >
                    No {handleFlatName()} Added
                  </Typography>
                </Grid>
              )}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogBox}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EntryExitLogs;
