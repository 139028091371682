import React from "react";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "../styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3d3fb8",
  500: "#007FFF",
  600: "#343cac",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
  1000: "#2f45c5",
  1100: "#2e3094",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: ${blue[1100]};
  min-width: 60vw;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CustomizedTab(props) {
  const classes = useStyles();
  const { selectedHeader, setselectedHeader, headers } = props;

  const handleChange = (event, newValue) => {
    setselectedHeader(newValue);
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      md={12}
      container
      style={{ marginTop: 18, justifyContent: "center" }}
    >
      <TabsUnstyled value={selectedHeader} onChange={handleChange}>
        <TabsList value={selectedHeader}>
          {headers?.map((tb) => {
            return <Tab value={tb}>{tb}</Tab>;
          })}
        </TabsList>
      </TabsUnstyled>
    </Grid>
  );
}
export default CustomizedTab;
