import React from "react";
import { Typography, Paper, Grid, Modal, Button } from "@material-ui/core";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const PendingApprovalModal = ({ isPendingApproval, setisPendingApproval }) => {
  return (
    <Modal open={isPendingApproval}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Typography style={{ textAlign: "center" }}>
          <PendingActionsIcon style={{ fontSize: 70, color: "#D2042D" }} />
        </Typography>
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          You currently have a pending request to join the society. Please
          review and approve the request to complete the process.
        </Typography>
        <Grid container style={{ justifyContent: "center" }}>
          <Button
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
            onClick={() => {
              setisPendingApproval(false);
            }}
          >
            Close
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default PendingApprovalModal;
