import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../style";
import { Close, ErrorOutline } from "@material-ui/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "@mui/material";

function AddNoticeType(props) {
  const { open, setOpen, handleNoticeType } = props;
  const classes = useStyles();
  const [noticeTypeName, setnoticeTypeName] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);

  const handleAddNoticeType = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        name: noticeTypeName,
        societyId: fetchSocietyId(),
      };
      const res = await api.addNoticeType(sendData);
      if (res?.data?.success) {
        handleNoticeType();
        setnoticeTypeName("");
        toast.success("Notice Type Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setOpen(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  return (
    <Modal open={open}>
      <Paper
        style={{
          backgroundColor: "#ffff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          width: "29vw",
          overflow: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <span className="page-header">Add Notice Type</span>

          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <div className="col-sm-12 mb-2">
          <label className="form-label fs-6">Notice Type</label>

          <input
            className="form-control p-2"
            type="text"
            placeholder="Enter Type Name"
            required
            value={noticeTypeName}
            onChange={(event) => setnoticeTypeName(event.target.value)}
          />
        </div>

        <div className="col-sm-12 d-flex row justify-content-center">
          {errorMsg && (
            <Typography
              style={{ marginTop: 20, color: "red", textAlign: "center" }}
            >
              <ErrorOutline style={{ marginRight: 3 }} /> {errorMsg}
            </Typography>
          )}
          <Button
            variant="contained"
            size="large"
            disabled={loader}
            style={{
              padding: "8px",
              paddingLeft: 15,
              paddingRight: 15,
              width: "60%",
              backgroundColor: "#3862DD",
              color: "#fff",
            }}
            onClick={() => {
              handleAddNoticeType();
            }}
          >
            {loader && <LoadingOutlined />}&nbsp; + Add Notice Type
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default AddNoticeType;
