import React, { useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Card,
  Divider,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import {
  AccessTime,
  Apartment,
  Cake,
  Close,
  Domain,
  Home,
  Mail,
  Phone,
} from "@material-ui/icons";
import { BASE_URL } from "../../utils/base";
import moment from "moment";
import { Key } from "@mui/icons-material";
import {
  handleFlatName,
  handleFloorName,
  handlePropertyName,
} from "../../contextAPI/NamingConvention";
import { AuthContext } from "../../contextAPI/context";

function UserProfileDetailsModal(props) {
  const { isOpen, setIsOpen, user } = props;
  const { authState } = useContext(AuthContext);
  const { plant } = authState;

  return (
    <Modal open={isOpen}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          width: "50vw",
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Profile Details
          </Typography>

          <IconButton
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Card
          style={{
            backgroundolor: " #fff",
            borderRadius: "7px",
            display: "flex",
            overflow: "hidden",
            width: "47.4vw",
            height: "35vh",
          }}
        >
          <div
            style={{
              backgroundImage:
                "radial-gradient(circle at 48.7% 44.3%, rgb(30, 144, 231) 0%, rgb(56, 113, 209) 22.9%, rgb(38, 76, 140) 76.7%, rgb(31, 63, 116) 100.2%)",
              color: "#fff",
              padding: "20px",
              maxWidth: "350px",
              minWidth: "350px",
            }}
          >
            <Grid container style={{ justifyContent: "center" }}>
              <img
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 50,
                }}
                src={
                  user?.image
                    ? `${BASE_URL}/user/${user?.image}`
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
                alt="profile"
              />
            </Grid>
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                color: "#ffffff",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {user?.name ? user?.name : "-"}
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                color: "#ffffff",
                fontSize: 15,
                textDecoration: "capitalize",
              }}
            >
              ({plant ? "Employee" : user?.role ? user?.role : "-"})
            </Typography>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: "#000",
              }}
            />
            <Grid container>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  <Apartment style={{ fontSize: "18px" }} />
                  &nbsp;
                  {handlePropertyName()} : &nbsp;
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  {user?.property ? user?.property?.name : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  <Domain style={{ fontSize: "18px" }} />
                  &nbsp; {handleFloorName()} : &nbsp;
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  {user?.floor ? user?.floor?.name : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  <Home style={{ fontSize: "18px" }} />
                  &nbsp; {handleFlatName()} : &nbsp;
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                lg={6}
                md={6}
                style={{ justifyContent: "flex-start" }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                >
                  {user?.flat ? user?.flat?.name : "-"}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              padding: "15px",
              position: "relative",
              width: "100%",
            }}
          >
            <Typography
              style={{
                color: "#000",
                textAlign: "start",
                fontWeight: "bold",
                fontSize: 18,
                opacity: 1,
                marginBottom: 10,
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Other Information
            </Typography>
            <Divider
              style={{
                marginTop: 0,
                marginBottom: 10,
                backgroundColor: "#000",
              }}
            />
            <Grid conatiner>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontSize: 14,
                  opacity: 1,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <AccessTime style={{ fontSize: "18px" }} />
                &nbsp; Created At : &nbsp;
                <span style={{ color: "#6c757d" }}>
                  {" "}
                  {moment(user?.createdAt).format("DD/MM/YYYY - h:mm A")}
                </span>
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  color: "#000",
                  textAlign: "start",
                  fontSize: 14,
                  opacity: 1,
                  marginBottom: 10,
                  fontWeight: 800,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <Mail style={{ fontSize: "18px" }} />
                &nbsp; Email : &nbsp;
                <span style={{ color: "#6c757d" }}>
                  {user?.email ? user?.email : "-"}
                </span>
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontSize: 14,
                  opacity: 1,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <Phone style={{ fontSize: "18px" }} />
                &nbsp; Phone : &nbsp;
                <span style={{ color: "#6c757d" }}>
                  {" "}
                  {user?.phone ? user?.phone : "-"}
                </span>
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontSize: 14,
                  opacity: 1,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <Key style={{ fontSize: "18px" }} />
                &nbsp; UniqueId : &nbsp;
                <span style={{ color: "#6c757d" }}>
                  {" "}
                  {user?.uniqueId ? user?.uniqueId : "-"}
                </span>
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontSize: 14,
                  opacity: 1,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <Cake style={{ fontSize: "18px" }} />
                &nbsp; DOB : &nbsp;
                <span style={{ color: "#6c757d" }}>
                  {" "}
                  {user?.DOB ? user?.DOB : "-"}
                </span>
              </Typography>
            </Grid>
          </div>
        </Card>
      </Paper>
    </Modal>
  );
}

export default UserProfileDetailsModal;
