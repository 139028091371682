import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

export default function MixedComplaintsGraphQV({ data }) {
  return (
    <ResponsiveContainer width="100%" height={370}>
      <BarChart data={data} margin={{ top: 5, bottom: 5 }}>
        <XAxis
          type="category"
          dataKey={"sectorName"}
          axisLine={false}
          interval={0}
          style={{
            fontSize: 8,
            color: "#0000",
            fontWeight: "bold",
          }}
        />
        <YAxis hide axisLine={false} type="number" />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="inTime"
          fill="#3862DD"
          stackId="a"
          name="Sector wise Within SLA"
          barSize={70}
          // radius={[10, 10, 0, 0]}
        >
          <LabelList
            dataKey="inTime"
            position="center"
            fill="#fff"
            style={{
              fontSize: 9,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />
        </Bar>
        <Bar
          dataKey="outOfTime"
          stackId="a"
          fill="#ADD9F9"
          name="Sector wise Beyond SLA"
          radius={[10, 10, 0, 0]}
          barSize={70}
        >
          <LabelList
            dataKey="outOfTime"
            position="middle"
            fill="#fff"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 9,
              fontWeight: "bolder",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
