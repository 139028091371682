import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import MonthlyPendingComplaintGraphDV from "../../Components/Complaints/QuickView/MonthlyPendingComplaintGraphDV";
import SectorComplaintGraphDV from "../../Components/Complaints/QuickView/SectorComplaintGraphDV";
import CategoryComplaintGraphDV from "../../Components/Complaints/QuickView/CategoryComplaintGraphDV";
import MixedComplaintGraphDV from "../../Components/Complaints/QuickView/MixedComplaintGraphDV";
import QuickViewNew from "../../Components/Complaints/QuickView/QuickViewNew";

const QuickviewRoutes = {
  path: "/misdashboard",
  element: <NavigationDrawer />,
  children: [
    {
        path: "quickview",
        element: <QuickViewNew />
    },
    {
        path: "monthlypendingcomplaintgraphdv",
        element: <MonthlyPendingComplaintGraphDV />
    },
    {
        path: "sectorcomplaintgraphdv",
        element: <SectorComplaintGraphDV />
    },
    {
        path: "categorycomplaintgraphdv",
        element: <CategoryComplaintGraphDV />
    },
    {
        path: "mixedcomplaintgraphdv",
        element: <MixedComplaintGraphDV />
    },
]

};

export default QuickviewRoutes;