import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import RefreshIcon from "@mui/icons-material/Refresh";
import TypeTable from "./TypeTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as api from "../../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import AddType from "./AddType";
import { resolveErrorMessage } from "../../../services/service";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";

function Type(props) {
  const { type, setloader, loader } = props;
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addTypeModal, setaddTypeModal] = useState(false);
  const [gateManagementTypes, setgateManagementTypes] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Settings"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleGetManagementTypes = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      if (type === "visitor") {
        const res = await api.getVisitorType({ params: { societyId: fetchSocietyId()}});
        if (res?.data?.success) {
          setgateManagementTypes(res?.data?.VisitorType);
          setloader(false);
        }
      } else {
        const res = await api.getStaffType({
          params: { societyId: fetchSocietyId() },
        });
        if (res?.data?.success) {
          setgateManagementTypes(res?.data?.StaffType);
          setloader(false);
        }
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetManagementTypes();
  }, []);

  return (
    <>
      <Grid container>
        <Grid container item xs={4} md={4} lg={4}>
          <Typography
            container
            style={{
              fontWeight: "bold",
              fontSize: 20,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              marginLeft: 5,
            }}
          >
            {type === "visitor" ? "Visitor" : "Staff"} Type
            {!loader ? (
              <RefreshIcon
                title="refresh type"
                onClick={handleGetManagementTypes}
                style={{
                  marginLeft: 5,
                  marginTop: -4,
                  cursor: "pointer",
                  color: "#000",
                }}
              />
            ) : (
              <LoadingOutlined
                title="laoding..."
                style={{
                  marginLeft: 5,
                  color: "#000",
                  marginTop: -4,
                }}
              />
            )}
          </Typography>
        </Grid>

        {isReadWrite && (
          <Grid container item xs={8} lg={8} sm={8} justifyContent={"flex-end"}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #7D858D",
                width: "100px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginRight: 5,
                marginLeft: 10,
              }}
              onClick={() => {
                setaddTypeModal(true);
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    textAlign: "center",
                  }}
                >
                  <AddCircleIcon
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                  &nbsp;Add
                </p>
              </div>
            </div>
          </Grid>
        )}
        <Grid container style={{ marginTop: 10 }}>
          <TypeTable types={gateManagementTypes} />
        </Grid>
      </Grid>
      <AddType
        addTypeModal={addTypeModal}
        setaddTypeModal={setaddTypeModal}
        type={type}
        handleGetManagementTypes={handleGetManagementTypes}
      />
      {!loader && gateManagementTypes?.length === 0 && (
        <Typography
          style={{
            color: "#6c757d",
            textAlign: "center",
            fontSize: 22,
            marginTop: 10,
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          No {type === "visitor" ? "Visitor" : "Staff"} Type
        </Typography>
      )}
    </>
  );
}
export default Type;
