import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import useStyles from "../styles";
import { useNavigate, useParams } from "react-router";
import { navigateToVendorDetails } from "../../../Navigation/Navigations";
import { BASE_URL } from "../../../utils/base";
import { InfoOutlined, Sync } from "@material-ui/icons";
import StarIcon from "@mui/icons-material/Star";
import { Modal, Tag } from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api";
import { AuthContext } from "../../../contextAPI/context";
import { GETUSER, VENDORS } from "../../../contextAPI/actions/auth.actions";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";

function SubVendorTable({
  allSubVendor,
  isReadWrite,
  page,
  type,
  setVendor,
  setcategoryModal,
  seteditVendorModal,
  handleGetSubVendor,
}) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const { authDispatch } = useContext(AuthContext);

  const handleDeleteVendorModal = (row, isDeleted, handleGetSubVendor) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: isDeleted
        ? `Are you sure you want to delete ${row.name}?`
        : `Are you sure you want to Recover ${row.name}?`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        handleVendorDelete(row, isDeleted);
      },
    });
  };

  const handleVendorDelete = async (row, isDeleted) => {
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        vendorId: row._id,
        delete: isDeleted,
      };
      const res = await api.deleteVendor(sendData);
      if (res?.data?.success) {
        let updatedVendors = res.data.society.vendors.filter((vn) => {
          return vn.delete === false;
        });
        authDispatch({
          type: VENDORS,
          payload: updatedVendors,
        });
        const res1 = await api.getUser({ params: { token: refreshToken } });
        authDispatch({
          type: GETUSER,
          payload: res1.data,
        });

        if (isDeleted) {
          toast.success("Vendor Deleted Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.success("Vendor Recovered Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
        handleGetSubVendor();
      }
    } catch (error) {
      console.log(error);
      resolveErrorMessage(error);
    }
  };
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow container>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Sr. No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Company Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Employee Name
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Email
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Contact
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Category - SubCategory
            </TableCell>

            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Rating
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Status
            </TableCell>

            {isReadWrite && (
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {allSubVendor?.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {(page - 1) * 50 + (index + 1)}.
              </TableCell>
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                <Typography className={classes.textname}>
                  {row.companyName ? row.companyName : "-"}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                <Grid
                  container
                  style={{ justifyContent: "center", cursor: "pointer" }}
                >
                  <Avatar
                    onClick={() => {
                      localStorage.setItem("fromQuickView", "false");
                      navigate(navigateToVendorDetails({ vendorId: row._id }), {
                        state: { mainVendor: false, vendorId: id },
                      });
                    }}
                    alt={row.name}
                    src={
                      row.image
                        ? `${BASE_URL}/user/${row.image}`
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    className={classes.avatar}
                  />
                </Grid>
                <Typography
                  onClick={() => {
                    localStorage.setItem("fromQuickView", "false");
                    navigate(navigateToVendorDetails({ vendorId: row._id }), {
                      state: { mainVendor: false, vendorId: id },
                    });
                  }}
                  className={classes.textname}
                >
                  {row.name}
                </Typography>
                <span
                  style={{
                    width: row?.isSubVendor ? "80px" : "58px",
                    padding: 5,
                    textTransform: "capitalize",
                    marginLeft: 5,
                  }}
                  class="badge bg-primary"
                >
                  {row?.isSubVendor ? "Sub-Vendor" : "Vendor"}
                </span>
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {row.email}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {row.phone}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                <InfoOutlined
                  onClick={() => {
                    setVendor(row);
                    setcategoryModal(true);
                  }}
                  style={{ color: "#2f45c5", cursor: "pointer" }}
                />
              </TableCell>

              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {Math.round(row.averageRating * 10) / 10}
                <StarIcon style={{ fontSize: 18, marginBottom: 2 }} />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "capitalize",
                }}
              >
                <Tag
                  style={{
                    cursor: "pointer",
                    padding: 3,
                    fontSize: "11px",
                  }}
                  color={
                    row.approvalStatus === "approved"
                      ? "green"
                      : row.approvalStatus === "pending"
                      ? "gold"
                      : "red"
                  }
                >
                  {row.approvalStatus}
                </Tag>
              </TableCell>

              {isReadWrite && (
                <>
                  {type === "all" ? (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Tag
                        color="blue"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          seteditVendorModal(true);
                          setVendor(row);
                        }}
                        icon={<FormOutlined style={{ fontSize: "20px" }} />}
                      >
                        EDIT
                      </Tag>
                      <Tag
                        style={{ cursor: "pointer" }}
                        color="red"
                        onClick={() => {
                          handleDeleteVendorModal(row, true);
                          setVendor(row);
                        }}
                        icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                      >
                        DELETE
                      </Tag>
                    </TableCell>
                  ) : (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Tag
                        style={{ cursor: "pointer" }}
                        color="green"
                        onClick={() => {
                          handleDeleteVendorModal(row, false);
                          setVendor(row);
                        }}
                        icon={<Sync style={{ fontSize: "18px" }} />}
                      >
                        RECOVER
                      </Tag>
                    </TableCell>
                  )}
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SubVendorTable;
