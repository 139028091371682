import React, { useState } from "react";
import { Card, Grid, Icon, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";
import useStyles from "../style";
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material";
import { navigateToDailyHelpDetails } from "../../../Navigation/Navigations";

function DailyHelpCard(props) {
  const { stf, setIsEdited, setEditCategory, setDeleteCategory } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const BoxIcons = styled(Icon)(({ theme }) => ({
    fontSize: "65px",
    color: "#2e3094",
    [theme.breakpoints.down("md")]: { fontSize: "40px" },
    [theme.breakpoints.down("lg")]: { fontSize: "40px" },
    height: "87px",
  }));
  const Title = styled("h6")(({ theme }) => ({
    fontWeight: "bolder",
    fontSize: "18px",
    fontFamily: "Montserrat, sans-serif",
    color: "#000",
    opacity: 1,
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: 5,
    [theme.breakpoints.down("lg")]: { fontSize: "16px" },
    [theme.breakpoints.down("md")]: { fontSize: "12px" },
  }));

  return (
    <>
      <Grid
        item
        xs={3}
        onClick={() => {
          navigate(navigateToDailyHelpDetails({staffType:stf?.staffType?.name}));
        }}
      >
        <section>
          <Card className={classes.cardStyle}>
            <Grid
              onClick={() => {
                navigate(navigateToDailyHelpDetails({staffType:stf?.staffType?.name}));
              }}
            >
              <Grid container style={{ justifyContent: "center", marginTop: 15 }}>
                <BoxIcons component={GroupIcon} />
              </Grid>
              <Title>{stf?.staffType?.name}</Title>
              <Title>{stf?.count ? stf?.count : "No"} contacts</Title>
            </Grid>
          </Card>
        </section>
      </Grid>
    </>
  );
}

export default DailyHelpCard;
