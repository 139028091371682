import React, { useContext, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import SectorModal from "./AccessTypeModal/SectorModal";
import PropertyModal from "./AccessTypeModal/PropertyModal";
import ApiCall from "../../../api/ApiCall";
import { AllGroupRoutes } from "../../../api/Routes";
import { toast } from "react-toastify";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../contextAPI/context";
import FloorModal from "./AccessTypeModal/FloorModal";
import FlatModal from "./AccessTypeModal/FlatModal";
import CategoryModal from "./AccessTypeModal/CategoryModal";
import {
  handleFlatName,
  handlePropertyName,
} from "../../../contextAPI/NamingConvention";
import ZoneModal from "./AccessTypeModal/ZoneModal";

const AddGroup = ({ addGroup, setAddGroup, handleGetGroups }) => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [groupName, setgroupName] = useState("");
  const [permissionType, setPermissionType] = useState("Choose Type");
  //sectors
  const [sectorModal, setSectorModal] = useState(false);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // property
  const [propertyModal, setPropertyModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectAllProperty, setSelectAllProperty] = useState(false);
  // floor
  const [floorModal, setFloorModal] = useState(false);
  const [selectedFloors, setSelectedFloors] = useState([]);
  const [selectAllFloors, setSelectAllFloors] = useState(false);
  // flat
  const [flatModal, setFlatModal] = useState(false);
  const [selectedFlats, setSelectedFlats] = useState([]);
  const [selectAllFlats, setSelectAllFlats] = useState({});
  const uniqueFlats = selectedFlats.reduce((acc, flat) => {
    if (!acc.find((item) => item._id === flat._id)) {
      acc.push(flat);
    }
    return acc;
  }, []);

  //Category
  const [categoryModal, setCategoryModal] = useState(false);
  const [selectedCategory, setselectedCategory] = useState([]);

  //Sub Category
  const [selectedZone, setselectedZone] = useState([]);
  const [zoneModal, setzoneModal] = useState(false);

  const startApiCall = () => {
    seterrorMessage("");
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
  };

  const handleReset = () => {
    setAddGroup(false);
    setLoader(false);
    seterrorMessage("");
    setgroupName("");
    setPermissionType("Choose Type");
    setSelectedSector([]);
    setSelectAll(false);
    setSelectedProperty([]);
    setSelectAllProperty(false);
    setSelectedFloors([]);
    setSelectAllFloors(false);
    setSelectedFlats([]);
    setSelectAllFlats({});
  };

  const handleResetSelectedValues = () => {
    setSelectedSector([]);
    setSelectAll(false);
    setSelectedProperty([]);
    setSelectAllProperty(false);
    setSelectedFloors([]);
    setSelectAllFloors(false);
    setSelectedFlats([]);
    setSelectAllFlats({});
    setselectedCategory([]);
    setselectedZone([]);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    handleResetSelectedValues();
    setPermissionType(value);
    if (value === "sectors") {
      setSectorModal(true);
    } else if (value === "properties") {
      setPropertyModal(true);
    } else if (value === "floors") {
      setFloorModal(true);
    } else if (value === "flats") {
      setFlatModal(true);
    } else if (value === "categories") {
      setCategoryModal(true);
    } else if (value === "categoryandzone") {
      setzoneModal(true);
    }
  };

  const handleAddGroup = async () => {
    startApiCall();
    const allProperties = selectedProperty?.map((pr) => pr?._id);
    const allFloors = selectedFloors?.map((flr) => flr?._id);
    // const uniqueFlats = selectedFlats.reduce((acc, flat) => {
    //   if (!acc.find((item) => item._id === flat._id)) {
    //     acc.push(flat);
    //   }
    //   return acc;
    // }, []);
    const allFlats = uniqueFlats?.map((flt) => flt?._id);
    const allCategory = selectedCategory?.map((cat) => cat?._id);
    const allZone = selectedZone.map((zone) => zone);
    const data = {
      societyId: fetchSocietyId(),
      name: groupName,
      accessType: permissionType,
      createdBy: user?._id,
      sectors: selectedSector,
      properties: allProperties,
      floors: allFloors,
      flats: allFlats,
      zones: allZone,
      categories: allCategory,
    };
    const res = await ApiCall("post", AllGroupRoutes.addGroup, data);
    if (res?.success) {
      toast.success("Group Created Successfully!");
      handleReset();
      handleGetGroups();
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <>
      <Modal open={addGroup}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            minWidth: "400px",
            maxWidth: "35vw",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Add Group
            </Typography>
            {!loader && (
              <IconButton onClick={handleReset}>
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 12,
                }}
              >
                Group Name :
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 4,
                  outline: 0,
                  marginTop: 6,
                }}
                placeholder="Enter group name"
                value={groupName}
                onChange={(event) => setgroupName(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 12,
                }}
              >
                Choose Type :
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Select
                value={permissionType}
                onChange={(event) => handleChange(event)}
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderRadius: 10,
                  width: "98%",
                  fontSize: "13px",
                  padding: 8,
                  paddingLeft: 6,
                  marginBottom: 10,
                  border: "1px solid #6c757d",
                  textAlign: "center",
                }}
              >
                <MenuItem selected value="Choose Type">
                  Choose Type
                </MenuItem>
                <MenuItem selected value="sectors">
                  Sector Wise
                </MenuItem>
                <MenuItem selected value="properties">
                  {handlePropertyName()} Wise
                </MenuItem>
                {/* <MenuItem selected value="floors">
                  Floor Wise
                </MenuItem> */}
                <MenuItem selected value="flats">
                  {handleFlatName()} Wise
                </MenuItem>
                <MenuItem selected value="categories">
                  Category Wise
                </MenuItem>
                <MenuItem selected value="categoryandzone">
                  Zone Wise
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          {errorMessage && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMessage}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "150px",
                backgroundColor:
                  permissionType === "Choose Type" || loader
                    ? "#7D858D"
                    : "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                if (!groupName && permissionType === "Choose Type") {
                  toast.error("all fields are required");
                } else if (!groupName && permissionType !== "Choose Type") {
                  toast.error("all fields are required");
                } else {
                  seterrorMessage("");
                  handleAddGroup();
                }
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <AddIcon
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  {loader ? "Loading...." : "Create Group"}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
      {sectorModal && (
        <SectorModal
          sectorModal={sectorModal}
          setSectorModal={setSectorModal}
          setPermissionType={setPermissionType}
          setSelectedSector={setSelectedSector}
          selectedSector={selectedSector}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
        />
      )}
      {propertyModal && (
        <PropertyModal
          propertyModal={propertyModal}
          setPropertyModal={setPropertyModal}
          setPermissionType={setPermissionType}
          setSelectedProperty={setSelectedProperty}
          selectedProperty={selectedProperty}
          setSelectAllProperty={setSelectAllProperty}
          selectAllProperty={selectAllProperty}
        />
      )}
      {floorModal && (
        <FloorModal
          floorModal={floorModal}
          setFloorModal={setFloorModal}
          setPermissionType={setPermissionType}
          setSelectedFloors={setSelectedFloors}
          selectedFloors={selectedFloors}
          setSelectAllFloors={setSelectAllFloors}
          selectAllFloors={selectAllFloors}
        />
      )}
      {flatModal && (
        <FlatModal
          flatModal={flatModal}
          setFlatModal={setFlatModal}
          setPermissionType={setPermissionType}
          selectedFlats={selectedFlats}
          setSelectedFlats={setSelectedFlats}
          setSelectAllFlats={setSelectAllFlats}
          selectAllFlats={selectAllFlats}
        />
      )}
      {categoryModal && (
        <CategoryModal
          categoryModal={categoryModal}
          setCategoryModal={setCategoryModal}
          selectedCategory={selectedCategory}
          setselectedCategory={setselectedCategory}
          setPermissionType={setPermissionType}
        />
      )}
      {zoneModal && (
        <ZoneModal
          zoneModal={zoneModal}
          setzoneModal={setzoneModal}
          selectedZone={selectedZone}
          setselectedZone={setselectedZone}
          setPermissionType={setPermissionType}
          selectedCategory={selectedCategory}
          setselectedCategory={setselectedCategory}
        />
      )}
    </>
  );
};

export default AddGroup;
