import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  Menu,
  Checkbox,
  InputLabel,
  Box,
} from "@material-ui/core";
import useStyles from "../../Vendors/styles";
import { useNavigate } from "react-router-dom";
import MonthlyReportTable from "../AllComplaints/MonthlyReportTable";
import DailyReportTable from "../AllComplaints/DailyReportTable";

function Vendors() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [chooseDataType, setchooseDataType] = useState("Monthly Report");
  const [chooseDate, setchooseDate] = useState("");
  const [chooseMonth, setchooseMonth] = useState("2022-02");

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Complaint Reports
                </Typography>

                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "68px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/helpdesk");
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        marginTop: "-12px",
                      }}
                    >
                      &nbsp;Back
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid container style={{ marginTop: 40 }}>
                <Grid>
                  <Typography
                    style={{
                      color: "#6c757d",
                      fontSize: 12,
                      marginTop: -19,
                      marginRight: 8,
                      marginLeft: 5,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Choose Data type
                  </Typography>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={chooseDataType}
                    onChange={(event) => setchooseDataType(event.target.value)}
                    style={{
                      color: "#212529",
                      backgroundColor: "#fff",
                      fontWeight: "bold",
                      borderRadius: 6,
                      width: "250px",
                      marginTop: 15,
                      height: 30,
                      fontSize: "13px",
                      marginLeft: 10,
                      border: "1px solid #2f45c5",
                      padding: 20,
                    }}
                  >
                    <MenuItem selected value="Monthly Report">
                      Monthly Report
                    </MenuItem>
                    <MenuItem selected value="Daily Report">
                      Daily Report
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid style={{ marginLeft: 40 }}>
                  <Typography
                    style={{
                      color: "#6c757d",
                      fontSize: 12,
                      marginTop: -19,
                      marginRight: 8,
                      marginLeft: 5,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                   {chooseDataType === "Monthly Report" ?"Choose Month":"Choose Date"} 
                  </Typography>
                  {chooseDataType === "Monthly Report" ? (
                    <input
                      type="month"
                      style={{
                        borderRadius: 10,
                        padding: 10,
                        width: 190,
                        height: 50,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      onChange={(event) => setchooseMonth(event.target.value)}
                      value={chooseMonth}
                    />
                  ) : (
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 10,
                        width: 190,
                        height: 50,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      onChange={(event) => setchooseDate(event.target.value)}
                      value={chooseDate}
                    />
                  )}
                </Grid>
              </Grid>

              {chooseDataType === "Monthly Report" && (
                <Grid style={{ marginTop: 25 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Monthly Report
                  </Typography>
                  <MonthlyReportTable />
                </Grid>
              )}
              {chooseDataType === "Daily Report" && (
                <Grid style={{ marginTop: 25 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Daily Report
                  </Typography>
                  <DailyReportTable />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
    </div>
  );
}

export default Vendors;
