import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import { Close } from "@material-ui/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ChooseCategoryDropDown from "../../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import ApiCall from "../../../../api/ApiCall";
import { AreaRoutes } from "../../../../api/Routes";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

function ZoneModal({
  zoneModal,
  setzoneModal,
  selectedZone,
  setselectedZone,
  setPermissionType,
  selectedCategory,
  setselectedCategory,
}) {
  const classes = useStyles();
  const [searchKey, setsearchKey] = useState("");
  const [loading, setloading] = useState(false);
  const [allZones, setallZones] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");

  const handleGetZones = async () => {
    setloading(true);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
      },
      page: 1,
      limit: 200,
    };

    const res = await ApiCall("post", AreaRoutes.getZone, data);
    setloading(false);

    if (res?.success) {
      setallZones(res?.zones);
    } else {
      seterrorMessage(res.error);
    }
  };

  const handleZoneSelection = (zone) => {
    const updatedZones = selectedZone.includes(zone)
      ? selectedZone.filter((z) => z !== zone)
      : [...selectedZone, zone];

    setselectedZone(updatedZones);
  };

  const handleSelectAllZones = (selectAll) => {
    const updatedZones = selectAll ? allZones : [];
    setselectedZone(updatedZones);
  };

  useEffect(() => {
    handleGetZones();
  }, []);

  const hasSelectedZones = selectedZone.length > 0;

  return (
    <Modal open={zoneModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "800px",
          maxWidth: "75vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              fontSize: 17,
            }}
          >
            Select Zone
          </Typography>
          <IconButton
            onClick={() => {
              setzoneModal(false);
              setPermissionType("Choose Type");
              setselectedZone([]);
              setselectedCategory([]);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <ChooseCategoryDropDown
            setCategory={setselectedCategory}
            category={selectedCategory}
            isMultiple={true}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
          />
        </Grid>

        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          <Grid
            item
            style={{
              marginTop: "5px",
              overflowY: "scroll",
              maxHeight: "60vh",
              padding: "8px",
              justifyContent: "center",
              display: "flex",
              pointerEvents: selectedCategory?.length === 0 && "none",
            }}
            xs={selectedCategory?.length > 0 ? 8 : 12}
          >
            {!loading && allZones?.length > 0 && (
              <TableContainer component={Paper} style={{ marginTop: 8 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSelectAllZones(e.target.checked)
                          }
                          checked={selectedZone.length === allZones.length}
                        />
                        &nbsp; Sr. No.
                      </TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allZones?.map((zone, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input
                            type="checkbox"
                            onChange={() => handleZoneSelection(zone)}
                            checked={selectedZone.includes(zone)}
                          />
                          &nbsp;&nbsp; {index + 1}.
                        </TableCell>
                        <TableCell>{zone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!loading && allZones?.length === 0 && selectedCategory && (
              <Typography>No zone available</Typography>
            )}
            {loading && <LoadingOutlined style={{ fontSize: 24 }} />}
          </Grid>
          {selectedCategory?.length > 0 && (
            <Grid item xs={4} style={{ padding: "8px" }}>
              <div
                className="d-flex flex-column overflow-auto scrollable p-3"
                style={{ height: "calc(60vh - 40px)" }}
              >
                <div
                  style={{
                    color: "#6c757d",
                  }}
                  className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
                >
                  Selected Category:
                </div>
                <div>
                  {selectedCategory?.map((category, index) => {
                    return (
                      <p
                        className="text-dark fw-bold"
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        {index + 1}. {category?.categoryName}
                      </p>
                    );
                  })}
                </div>
                {selectedZone?.length > 0 && (
                  <>
                    <div
                      style={{
                        color: "#6c757d",
                      }}
                      className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
                    >
                      Selected Zones:
                    </div>
                    <div>
                      {selectedZone.map((zone, zoneIndex) => (
                        <p
                          key={zoneIndex}
                          className="text-dark fw-bold"
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {zoneIndex + 1}. {zone}
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container style={{ justifyContent: "center" }}>
          <div
            className="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor: hasSelectedZones ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (hasSelectedZones) {
                setzoneModal(false);
              } else {
                toast.error("Please Select Category and Zone");
              }
            }}
          >
            <div className="card-body">
              <p
                className="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default ZoneModal;
