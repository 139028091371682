import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import CategoryComplaintGraphQV from "./CategoryComplaintGraphQV";
import MonthlyPendingComplaintGraphQV from "./MonthlyPendingComplaintGraphQV";
import MixedComplaintsGraphQV from "./MixedComplaintGraphQV";
import SectorComplaintGraphQV from "./SectorComplaintGraphQV";

const QuickViewGraphs = ({
  categoryComplaintGraphData,
  monthlyPendingComplaintGraphData,
  mixedComplaintsGraphData,
  sectorGraphData,
  setcategoryWiseStatus,
  setSectorWiseStatus,
  setDownloadCsv,
  setCsvData,
  setHeaders,
  setGraphTypeName
}) => {

  const downloadCsvCategoryWise = () => {
    const temp = [];
    categoryComplaintGraphData.forEach((data) =>
      temp.push({
        category: data.categoryName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "category",
        name: "Category",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
    setGraphTypeName("Category Wise Complaint");
  };

  const downloadCsvVendorWise = () => {
    const temp = [];
    monthlyPendingComplaintGraphData?.forEach((data) =>
      temp.push({
        vendor: data.vendorName,
        total: data.totalComplaints,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "vendor",
        name: "Vendor",
      },
      {
        key: "total",
        name: "Total Complaints",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
    setGraphTypeName("Vendor Wise Complaint");
  };

  const downloadCsvSLaWise = () => {
    const temp = [];
    mixedComplaintsGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
    setGraphTypeName("Sla/ Beyond Sla Complaint");
  };

  const downloadCsvSectorWise = () => {
    const temp = [];
    sectorGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
    setGraphTypeName("Sector Wise Complaint");
  };

  return (
    <>
      <Grid container item spacing={2} xs={9} style={{ marginBottom: 10 }}>
        {/* CATEGORY WISE COMPLAINT */}
        <Grid item xs={6}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Complaints
            </Typography>
            <span className="pointer" onClick={downloadCsvCategoryWise}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                  stroke="#0C8CE9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex justify-content-end">
              <select
                className="form-select form-select-md pointer"
                style={{
                  color: "#0C8CE9",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  height: 40,
                  fontSize: "16px",
                  padding: 8,
                  paddingLeft: 8,
                  border: "1px solid #0C8CE9",
                  textAlign: "start",
                  width: "120px",
                }}
                onChange={(event) => {
                  localStorage.setItem(
                    "stausForCategoryWise",
                    event.target.value
                  );
                  setcategoryWiseStatus(event.target.value);
                }}
                value={localStorage.getItem("stausForCategoryWise")}
              >
                <option value="assigned">Assigned</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
                <option value="cancel">Cancelled</option>
              </select>
            </div>
            <CategoryComplaintGraphQV data={categoryComplaintGraphData} />
          </div>
        </Grid>
        {/* Vendor wise complaints */}
        <Grid item xs={6}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Vendor SLA
            </Typography>
            <span className="pointer" onClick={downloadCsvVendorWise}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                  stroke="#0C8CE9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "12px 6px",
            }}
          >
            <MonthlyPendingComplaintGraphQV
              data={monthlyPendingComplaintGraphData}
            />
          </div>
        </Grid>
        {/* SLA / Beyond SLA complaints */}
        <Grid item xs={6}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Sector SLA
            </Typography>
            <span className="pointer" onClick={downloadCsvSLaWise}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                  stroke="#0C8CE9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "12px 6px",
            }}
          >
            <MixedComplaintsGraphQV data={mixedComplaintsGraphData} />
          </div>
        </Grid>
        {/* SECTOR WISE COMPLAINTS*/}
        <Grid item xs={6}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Sector Complaints
            </Typography>
            <span className="pointer" onClick={downloadCsvSectorWise}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                  stroke="#0C8CE9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex justify-content-end">
              <select
                className="form-select form-select-md pointer"
                style={{
                  color: "#0C8CE9",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  height: 40,
                  fontSize: "16px",
                  padding: 8,
                  paddingLeft: 8,
                  border: "1px solid #0C8CE9",
                  textAlign: "start",
                  width: "120px",
                }}
                onChange={(event) => {
                  localStorage.setItem(
                    "stausForSectorWise",
                    event.target.value
                  );
                  setSectorWiseStatus(event.target.value);
                }}
                value={localStorage.getItem("stausForSectorWise")}
              >
                <option value="assigned">Assigned</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
                <option value="cancel">Cancelled</option>
              </select>
            </div>
            <SectorComplaintGraphQV data={sectorGraphData} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default QuickViewGraphs;
