import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";


export default function MixedComplaintsGraph({ data }) {
  return (
    <ResponsiveContainer width="100%" height={440}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis hide   axisLine={false} type="number" />
        <YAxis
          type="category"
          interval={0}
          dataKey="vendorName"
          tick={{
            transform: `translate(${"100%" + 10}, 0)`,
          }}
          style={{ fontSize: 11,width:"700px",color:"#0000",fontWeight:"bold"}}
        />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="totalComplaints"
          stackId="a"
          fill="#11224D"
          name="Total Complaints"
        >
          <LabelList
            dataKey="totalComplaints"
            position="middle"
            fill="#fff"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 12,
              fontWeight: "bolder",
            }}          />
        </Bar>
        <Bar dataKey="inTime" stackId="a" fill="#5B84C4" name="Within SLA">
          <LabelList
            dataKey="inTime"
            position="middle"
            fill="#fff"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 12,
              fontWeight: "bolder",
            }}          />
        </Bar>
        <Bar dataKey="outOfTime" stackId="a" fill="#FB9B50" name="Beyond SLA">
          <LabelList
            dataKey="outOfTime"
            position="middle"
            fill="#fff"
   style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 12,
              fontWeight: "bolder",
            }}          />
        </Bar>

      </BarChart>
    </ResponsiveContainer>
  );
}
