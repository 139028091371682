import React, { useContext, useEffect, useState } from "react";
import useStyles from "../../People/styles";
import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Cancel, CheckCircle } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import ApiCall from "../../../api/ApiCall";
import { AllResidentRoutes } from "../../../api/Routes";
import { AuthContext } from "../../../contextAPI/context";
import { toast } from "react-toastify";
import { Circle } from "@mui/icons-material";
import VendorConfirmModal from "../../People/Resident/PendingRequests/VendorConfirmModal";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";

const VendorRequest = ({
  allVendorRequest,
  handleGetAllVendorRequests,
  statusForResidentData,
  usermoduleswithpermissions,
  page,
}) => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [selectedStatus, setselectedStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [IsChangeStatus, setIsChangeStatus] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [vendorCategory, setvendorCategory] = useState([]);
  const [vendorSubCategory, setvendorSubCategory] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Resident"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleReset = () => {
    setIsChangeStatus(false);
    setSelectedUser([]);
    setselectedStatus("");
    seterrorMsg("");
    setloader(false);
  };

  const handleSetForStatusChange = (status, user) => {
    setSelectedUser(user);
    setselectedStatus(status);
    seterrorMsg("");
    setIsChangeStatus(true);
  };

  const handleSendApproval = (vendor) => {
    setSelectedUser(vendor);
    seterrorMsg("");
    setIsChangeStatus(true);
  };

  // change vendor status approved and reject
  const handleChangeVendorStatus = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const data = {
      userId: selectedUser?._id,
      actionBy: user?._id,
      approvalStatus: selectedStatus === "approve" ? "approved" : "rejected",
      societyId: fetchSocietyId(),
    };
    if (selectedStatus === "approve") {
      data.category = vendorCategory?.map((category) => category?._id);
      data.subCategory = vendorSubCategory?.map(
        (subCategory) => subCategory?._id
      );
    }
    const res = await ApiCall(
      "post",
      AllResidentRoutes.approveOrRejectVendorRequest,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllVendorRequests();
      toast.success(
        `Vendor ${
          selectedStatus === "approve" ? "Approved" : "Rejected"
        } Successfully`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  // send for approval
  const handleReSendForApproval = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const data = {
      userId: selectedUser?._id,
    };
    const res = await ApiCall(
      "post",
      AllResidentRoutes.sendForApprovalVendor,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllVendorRequests();
      toast.success("Vendor Send for Approval Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      setloader(false);
      seterrorMsg(res?.error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Phone
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Status
              </TableCell>

              {isReadWrite && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allVendorRequest?.map((vendor, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 50 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    <Grid container style={{ justifyContent: "center" }}>
                      <Avatar
                        alt={vendor?.name}
                        src={
                          vendor?.image
                            ? vendor?.image
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        className={classes.avatar}
                      />
                    </Grid>

                    <Typography className={classes.textname}>
                      {vendor?.name}
                    </Typography>
                    <span
                      style={{
                        width: vendor?.isSubVendor ? "80px" : "58px",
                        padding: 5,
                        textTransform: "capitalize",
                        marginLeft: 5,
                      }}
                      class="badge bg-primary"
                    >
                      {vendor?.isSubVendor ? "Sub-Vendor" : "Vendor"}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {vendor?.email}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {vendor?.phone}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Typography
                      style={{
                        color:
                          vendor?.approvalStatus === "pending"
                            ? "rgb(240,173,78)"
                            : "rgb(239 13 39)",
                        fontWeight: "bold",
                        fontSize: 12,
                        marginTop: 4,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      &nbsp;
                      {vendor?.approvalStatus === "pending"
                        ? "PENDING"
                        : "REJECTED"}
                    </Typography>
                  </TableCell>

                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "center", cursor: "pointer" }}
                      >
                        {statusForResidentData === "rejected" ? (
                          <Typography
                            style={{
                              color: "#2f45c5",
                              fontFamily: "Poppins, sans-serif",
                              textTransform: "capitalize",
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              handleSendApproval(vendor);
                            }}
                          >
                            Send For Approval
                          </Typography>
                        ) : (
                          <>
                            <Tooltip
                              title="Approve Request"
                              placement="top"
                              arrow
                            >
                              <CheckCircle
                                onClick={() =>
                                  handleSetForStatusChange("approve", vendor)
                                }
                                style={{
                                  color: "green",
                                  fontSize: "20px",
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              onClick={() =>
                                handleSetForStatusChange("reject", vendor)
                              }
                              title="Reject Request"
                              placement="top"
                              arrow
                            >
                              <Cancel
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  marginLeft: 4,
                                }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <VendorConfirmModal
        title={
          statusForResidentData === "rejected"
            ? `ARE YOU SURE YOU WANT TO SEND FOR APPROVAL THIS VENDOR ?`
            : `ARE YOU SURE YOU WANT TO ${selectedStatus} THIS VENDOR ?`
        }
        description="Choose Category, Subcategory and You can confirm by clicking below."
        isOpen={IsChangeStatus}
        loader={loader}
        errorMsg={errorMsg}
        handleConfirmation={
          statusForResidentData === "rejected"
            ? handleReSendForApproval
            : handleChangeVendorStatus
        }
        rejectConfirmation={handleReset}
        selectedStatus={selectedStatus}
        vendorCategory={vendorCategory}
        setvendorCategory={setvendorCategory}
        vendorSubCategory={vendorSubCategory}
        setvendorSubCategory={setvendorSubCategory}
        searchKey={searchKey}
        setsearchKey={setsearchKey}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default VendorRequest;
