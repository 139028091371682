import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Grid, Paper, Avatar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { ArrowRightAlt } from "@material-ui/icons";
import { BASE_URL } from "../utils/base";
import InfoIcon from "@mui/icons-material/Info";
import { SentimentDissatisfied } from "@mui/icons-material";
import { navigateToReadWriteAccess } from "../Navigation/Navigations";
import {
  handleFlatName,
  handleFloorName,
  handlePropertyName,
} from "../contextAPI/NamingConvention";

function AddMemberTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    allAdmins,
    allOwners,
    allTenants,
    allVendors,
    setOpen,
    setSelectedUser,
    user,
    isReadWrite,
  } = props;
  return (
    <>
      {/* ALL ADMIN */}
      {allAdmins?.length > 0 && (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 22,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              marginLeft: 15,
            }}
          >
            Admins :{" "}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow container>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Permissions
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                    >
                      Give Access
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allAdmins?.map((admin, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            alt="name"
                            style={{ border: "1px solid #000" }}
                            src={
                              admin?.image
                                ? `${BASE_URL}/user/${admin.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography>{admin?.name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {admin?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {admin?.phone}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {admin?.permissions?.length > 0
                          ? admin?.permissions?.length + " Module Access"
                          : "No Permission"}
                        {admin?.permissions?.length > 0 && (
                          <InfoIcon
                            title="Permission Info"
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 19,
                            }}
                            onClick={() => {
                              setSelectedUser(admin);
                              setOpen(true);
                            }}
                          />
                        )}
                      </TableCell>

                      {isReadWrite && (
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (user?._id === admin?._id) {
                              //nothing happend
                            } else {
                              setSelectedUser(admin);
                              navigate(
                                navigateToReadWriteAccess({
                                  memberId: admin._id,
                                })
                              );
                            }
                          }}
                        >
                          {user?._id === admin?._id ? (
                            <>
                              <SentimentDissatisfied />
                              <br></br> Cannot give access by yourself
                            </>
                          ) : (
                            <ArrowRightAlt style={{ fontSize: 25 }} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* ALL OWNERS */}
      {allOwners?.length > 0 && (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 22,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              marginLeft: 15,
            }}
          >
            Owners :{" "}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow container>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Emp ID
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handlePropertyName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handleFloorName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handleFlatName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Permissions
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                    >
                      Give Access
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allOwners?.map((owner, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            alt="name"
                            style={{ border: "1px solid #000" }}
                            src={
                              owner?.image
                                ? `${BASE_URL}/user/${owner.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography>{owner?.name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.phone}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.empId ? owner?.empId : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.property?.name ? owner?.property?.name : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.floor?.name ? owner?.floor?.name : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.flat?.name ? owner?.flat?.name : "-"}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {owner?.permissions?.length > 0
                          ? owner?.permissions?.length + " Module Access"
                          : "No Permission"}
                        {owner?.permissions?.length > 0 && (
                          <InfoIcon
                            title="Permission Info"
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 19,
                            }}
                            onClick={() => {
                              setSelectedUser(owner);
                              setOpen(true);
                            }}
                          />
                        )}
                      </TableCell>

                      {isReadWrite && (
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              user?._id === owner?._id ||
                              user?.email === owner?.email
                            ) {
                              //nothing happend
                            } else {
                              setSelectedUser(owner);
                              navigate(
                                navigateToReadWriteAccess({
                                  memberId: owner._id,
                                })
                              );
                            }
                          }}
                        >
                          {user?._id === owner?._id ||
                          user?.email === owner?.email ? (
                            <>
                              <SentimentDissatisfied />
                              <br></br> Cannot give access by yourself
                            </>
                          ) : (
                            <ArrowRightAlt style={{ fontSize: 25 }} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {/* ALL TENANTS */}
      {allTenants?.length > 0 && (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 22,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              marginLeft: 15,
            }}
          >
            Tenants :{" "}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow container>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Emp ID
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handlePropertyName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handleFloorName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    {handleFlatName()}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Permissions
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                    >
                      Give Access
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allTenants?.map((tenant, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            alt="name"
                            style={{ border: "1px solid #000" }}
                            src={
                              tenant?.image
                                ? `${BASE_URL}/user/${tenant.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography>{tenant?.name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.email ? tenant?.email : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.phone ? tenant?.phone : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.empId ? tenant?.empId : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.property?.name ? tenant?.property?.name : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.floor?.name ? tenant?.floor?.name : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.flat?.name ? tenant?.flat?.name : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {tenant?.permissions?.length > 0
                          ? tenant?.permissions?.length + " Module Access"
                          : "No Permission"}
                        {tenant?.permissions?.length > 0 && (
                          <InfoIcon
                            title="Permission Info"
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 19,
                            }}
                            onClick={() => {
                              setSelectedUser(tenant);
                              setOpen(true);
                            }}
                          />
                        )}
                      </TableCell>
                      {isReadWrite && (
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              user?._id === tenant?._id ||
                              user?.email === tenant?.email
                            ) {
                              //nothing happend
                            } else {
                              setSelectedUser(tenant);
                              navigate(
                                navigateToReadWriteAccess({
                                  memberId: tenant._id,
                                })
                              );
                            }
                          }}
                        >
                          {user?._id === tenant?._id ||
                          user?.email === tenant?.email ? (
                            <>
                              <SentimentDissatisfied />
                              <br></br> Cannot give access by yourself
                            </>
                          ) : (
                            <ArrowRightAlt style={{ fontSize: 25 }} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* ALL VENDORS */}
      {allVendors?.length > 0 && (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 22,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              marginLeft: 15,
            }}
          >
            Vendors :{" "}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow container>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Subcategory
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Permissions
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                    >
                      Give Access
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allVendors?.map((vendor, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            alt="name"
                            style={{ border: "1px solid #000" }}
                            src={
                              vendor?.image
                                ? `${BASE_URL}/user/${vendor.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography>{vendor?.name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.email ? vendor?.email : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.phone ? vendor?.phone : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.category?.categoryName
                          ? vendor?.category?.categoryName
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.subCategory?.subcategoryName
                          ? vendor?.subCategory?.subcategoryName
                          : "-"}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.permissions?.length > 0
                          ? vendor?.permissions?.length + " Module Access"
                          : "No Permission"}
                        {vendor?.permissions?.length > 0 && (
                          <InfoIcon
                            title="Permission Info"
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 19,
                            }}
                            onClick={() => {
                              setSelectedUser(vendor);
                              setOpen(true);
                            }}
                          />
                        )}
                      </TableCell>

                      {isReadWrite && (
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              user?._id === vendor?._id ||
                              user?.email === vendor?.email
                            ) {
                              //nothing happend
                            } else {
                              setSelectedUser(vendor);
                              navigate(
                                navigateToReadWriteAccess({
                                  memberId: vendor._id,
                                })
                              );
                            }
                          }}
                        >
                          {user?._id === vendor?._id ||
                          user?.email === vendor?.email ? (
                            <>
                              <SentimentDissatisfied />
                              <br></br> Cannot give access by yourself
                            </>
                          ) : (
                            <ArrowRightAlt style={{ fontSize: 25 }} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default AddMemberTable;
