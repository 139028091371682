import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import { styled } from "@mui/material";
import React, { useState, useContext } from "react";
import LineChart from "./Charts/LineChart";
import useStyles from "./style";
import { CheckCircle, Timeline, TrendingFlat } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import AddNotice from "../Notices/AddNotice/AddNotice";
import { AuthContext } from "../../contextAPI/context";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as api from "../../api/index";
import { useEffect } from "react";
import { BASE_URL } from "../../utils/base";
import moment from "moment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CollectionsIcon from "@mui/icons-material/Collections";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { Tag } from "antd";
import { Apartment } from "@material-ui/icons";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  QuestionAnswer,
  Campaign,
} from "@mui/icons-material";
import DiscussionCards from "./DiscussionCards";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { RestartAlt } from "@mui/icons-material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ComplaintStatCard from "./ComplaintStatCard";
import ComplaintGraphData from "./ComplaintGraphData";
import Loading from "../../contextAPI/Loading";
import { resolveErrorMessage } from "../../services/service";
import {
  navigateInitialDiscussions,
  navigateInitialNotice,
  navigateToNoticeDetails,
} from "../../Navigation/Navigations";
import { handlePropertyName } from "../../contextAPI/NamingConvention";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Resident Request Graph",
      font: {
        size: 20,
        weight: "bold",
        family: "Montserrat, sans-serif",
      },
    },
    datalabels: {
      color: "#FFFFFF",
      anchor: "top",
      rotation: 270,
      display: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        return value > 0; // display labels with a value greater than 0
      },
      font: {
        size: 16,
        weight: "bold",
        family: "Montserrat, sans-serif",
      },
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        callback: function (value) {
          if (value % 1 === 0) {
            return value;
          }
        },
      },
    },
  },
};
const Dashboard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [AddNoticeModal, setAddNoticeModal] = useState(false);
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const [getNotices, setgetNotices] = useState([]);
  const [notices, setnotices] = useState([]);
  const [pleasewait, setpleasewait] = useState(false);
  const [alldiscussions, setalldiscussions] = useState([]);
  const [graphData, setgraphData] = useState([]);
  const [filterStatusDate, setfilterStatusDate] = useState("");
  const [residentRequestGraphData, setresidentRequestGraphData] = useState([]);
  var date = moment(new Date()).format("YYYY-MM-DD");
  const [entryData, setentryData] = useState([]);
  const [exitData, setexitData] = useState([]);
  const [interval, setInterval] = useState([]);
  const [selectedRoleForGraph, setselectedRoleForGraph] = useState("visitor");
  const [gateManagementOtherStat, setgateManagementOtherStat] = useState([]);
  const [statusGraph, setstatusGraph] = useState([]);
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [complaintdate, setcomplaintDate] = useState("");
  const [graphStatus, setgraphStatus] = useState("all");
  const [countComplaints, setCountomplaints] = useState(0);
  const [loader, setloader] = useState(false);
  const currentYear = new Date().getFullYear().toString();
  const [name, setname] = useState([]);
  const [newComplaint, setnewComplaint] = useState([]);
  const [assignComplaint, setassignComplaint] = useState([]);
  const [closeComplaint, setcloseComplaint] = useState([]);
  const [resolveComplaint, setresolveComplaint] = useState([]);
  const [cancelComplaint, setcancelComplaint] = useState([]);

  const statusTypeComplaint = [
    {
      type: "All Complaints",
      icon: "all",
    },
    {
      type: "New ",
      icon: "unassigned",
    },
    {
      type: "Assigned ",
      icon: "assigned",
    },
    {
      type: "Resolved ",
      icon: "resolved",
    },
    {
      type: "Closed ",
      icon: "closed",
    },
    {
      type: "Cancelled ",
      icon: "cancel",
    },
  ];

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "Good Morning";
    if (currentHour < 17) return "Good Afternoon";
    return "Good Evening";
  };

  const handleGetAllDiscussions = async () => {
    setpleasewait(true);
    setTimeout(() => {
      setpleasewait(false);
    }, 40000);
    try {
      const res = await api.getallDiscussions({
        params: { societyId: fetchSocietyId(), pageLimit: 20, pageNumber: 1 },
      });
      if (res?.data?.success) {
        setalldiscussions(res?.data?.discussion?.discussion);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };
  const data = {
    labels: residentRequestGraphData.map((item) => item.month),
    datasets: [
      {
        label: "Total Approved",
        data: residentRequestGraphData.map((item) => item.totalApproved),
        backgroundColor: "#74B72E",
        barThickness: 30,
      },
      {
        label: "Total Rejected",
        data: residentRequestGraphData.map((item) => item.totalRejected),
        backgroundColor: "#FF2E2E",
        barThickness: 30,
      },
      {
        label: "Total Pending",
        data: residentRequestGraphData.map((item) => item.totalPending),
        backgroundColor: "#ffa12e",
        barThickness: 30,
      },
    ],
  };

  const RequestGraphData = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        year: currentYear,
      };
      const res = await api.pendingRequestGraph(sendData);
      if (res?.data?.success) {
        setresidentRequestGraphData(res?.data?.user);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };
  const handleGraphData = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 60000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        date:
          complaintdate === ""
            ? moment(new Date()).format("DD-MM-YY")
            : moment(date).format("DD-MM-YY"),
        filter: "all",
      };
      const res = await api.statusWisegraphData(sendData);
      if (res?.data?.success) {
        setstatusGraph(res?.data?.complaintStatus?.compStatusGraphData);
        setCountomplaints(res?.data?.complaintStatus);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const changeGraph = async (type) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 60000);
    try {
      let sendData = {};
      if (type === "apply") {
        sendData = {
          societyId: fetchSocietyId(),
          date:
            complaintdate === ""
              ? moment(new Date()).format("DD-MM-YY")
              : moment(complaintdate).format("DD-MM-YY"),
          startTime: startTime ? startTime : null,
          endTime: endTime ? endTime : null,
          filter: graphStatus,
        };
      } else if (type === "reset") {
        setcomplaintDate("");
        setstartTime("");
        setendTime("");
        sendData = {
          societyId: fetchSocietyId(),
          date: moment(new Date()).format("DD-MM-YY"),
          startTime: "",
          endTime: "",
          filter: "all",
        };
      }
      const res = await api.statusWisegraphData(sendData);
      if (res?.data?.success) {
        setstatusGraph(res?.data?.complaintStatus?.compStatusGraphData);
        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        let temp5 = [];
        let temp6 = [];
        res?.data?.complaintStatus?.compStatusGraphData.map((stat) => {
          temp1.push(
            formatTime(stat.name.split("-")[0])
              .concat("-")
              .concat(formatTime(stat.name.split("-")[1]))
          );
          temp2.push(stat.newComplaint);
          temp3.push(stat.assignComplaint);
          temp4.push(stat.cancelComplaint);
          temp5.push(stat.resolveComplaint);
          temp6.push(stat.closeComplaint);
        });
        setname(temp1);
        setnewComplaint(temp2);
        setassignComplaint(temp3);
        setcancelComplaint(temp4);
        setresolveComplaint(temp5);
        setcloseComplaint(temp6);
        setCountomplaints(res?.data?.complaintStatus);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    if (fetchSocietyId() !== null) {
      handleGraphData();
      RequestGraphData();
    }
  }, [fetchSocietyId()]);
  useEffect(() => {
    changeGraph("apply");
  }, [graphStatus]);

  var cardStyle = {
    height: "100%",
    boxShadow: "0 3px 6px #4a6078",
    borderRadius: "8px",
  };

  const sectionForCards = {
    height: "100%",
  };

  function formatTime(timeString) {
    if (timeString) {
      const [hourString, minute] = timeString?.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }
  }

  const handleAllGraphData = async () => {
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        date: filterStatusDate === "" ? date : filterStatusDate,
        role: selectedRoleForGraph,
      };
      const res = await api.getAllGraphData(sendData);
      if (res?.data?.success) {
        setgraphData(res?.data?.visitor);
        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        res?.data?.visitor.map((graph) => {
          temp1.push(
            formatTime(graph.interval.split("-")[0])
              .concat("-")
              .concat(formatTime(graph.interval.split("-")[1]))
          );
          temp2.push(graph.entry);
          temp3.push(graph.exit);
        });

        setInterval(temp1);
        setentryData(temp2);
        setexitData(temp3);
      }
    } catch (error) {
      resolveErrorMessage(error);
    }
  };

  const handleAllNotice = async () => {
    try {
      setpleasewait(true);
      const res = await api.getAllNotice({
        params: {
          pageLimit: 100,
          pageNumber: 1,
          societyId: fetchSocietyId(),
        },
      });

      let temp = [...res?.data?.notices?.notices]?.reverse();
      setnotices(res?.data?.notices?.notices);
      setgetNotices(temp[0]);
      setpleasewait(false);
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };
  const handleGetOtherStatData = async () => {
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        date: filterStatusDate === "" ? date : filterStatusDate,
        role: selectedRoleForGraph.toLowerCase(),
      };
      const res = await api.getOtherStatforgateManagement(sendData);
      if (res?.data?.success) {
        setgateManagementOtherStat(res?.data?.response);
      }
    } catch (error) {
      resolveErrorMessage(error);
    }
  };
  useEffect(() => {
    handleAllNotice();
  }, []);

  useEffect(() => {
    if (fetchSocietyId() !== null) {
      handleGetAllDiscussions();
    }
  }, [fetchSocietyId()]);

  useEffect(() => {
    if (fetchSocietyId() !== null) {
      handleAllGraphData();
      handleGetOtherStatData();
    }
  }, [filterStatusDate, selectedRoleForGraph, fetchSocietyId()]);

  const handleChangeDate = (isSub) => {
    var temp = new Date(filterStatusDate === "" ? date : filterStatusDate);
    temp.setDate(isSub ? temp.getDate() - 1 : temp.getDate() + 1);
    setfilterStatusDate(moment(temp).format("YYYY-MM-DD"));
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          height: "100vh",
          opacity: fetchSocietyId() === null || loader ? 0.4 : "",
          pointerEvents: fetchSocietyId() === null || loader ? "none" : "",
          overflow: fetchSocietyId() === null || loader ? "hidden" : "auto",
        }}
      >
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container>
            <Grid item sm={12} lg={7} xs={12}>
              <Grid>
                <Card
                  className={classes.paperContainer}
                  style={{
                    position: "relative",
                    color: "white",
                    marginBottom: 10,
                    marginTop: 5,
                    padding: "1rem",
                  }}
                >
                  <div>
                    <span>
                      <Typography
                        style={{
                          fontSize: "34px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {getGreeting()}, {user?.name}
                      </Typography>
                    </span>
                  </div>
                  <div className="icon">
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Welcome to the SocioHood Community and Township Management
                      System
                    </Typography>
                  </div>
                </Card>
              </Grid>
              <div
                class="card"
                style={
                  {
                    // borderRadius: 15,
                    // marginLeft: 3,
                    // height: "84vh",
                  }
                }
              >
                <div class="card-body">
                  <Grid
                    container
                    style={{
                      // display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bolder",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                      }}
                    >
                      Notice Board
                    </Typography>
                    {notices?.length > 0 && (
                      <div
                        class="card"
                        style={{
                          height: 30,
                          width: "135px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginTop: 3,
                          boxShadow: "0 3px 6px #7D858D",
                        }}
                        onClick={() => {
                          navigate(navigateInitialNotice({}));
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                marginRight: 4,
                                fontSize: "17px",
                              }}
                            />
                            &nbsp; See Notices
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                  {notices?.length > 0 && !pleasewait && (
                    <Grid
                      container
                      style={{ marginTop: 10, justifyContent: "center" }}
                    >
                      <Card style={{ height: 420, width: "100%" }}>
                        <CardHeader
                          style={{ fontFamily: "Poppins, sans-serif" }}
                          avatar={
                            <Avatar
                              src={
                                getNotices?.publishedBy?.image
                                  ? `${BASE_URL}/user/${getNotices?.publishedBy?.image}`
                                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                              }
                            />
                          }
                          action={
                            <div>
                              <Badge style={{ marginRight: 12 }}>
                                <VisibilityIcon style={{ fontSize: 18 }} />
                                <i
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "#03045e",
                                    marginLeft: 5,
                                  }}
                                >
                                  {getNotices?.viewedBy?.length}
                                </i>
                              </Badge>
                              <Badge style={{ marginRight: 10, marginLeft: 5 }}>
                                <VisibilityOffIcon style={{ fontSize: 18 }} />
                                <i
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "#03045e",
                                    marginLeft: 5,
                                  }}
                                >
                                  {getNotices?.unreadBy}
                                </i>
                              </Badge>
                            </div>
                          }
                          title={
                            <Grid
                              container
                              style={{ justifyContent: "space-between" }}
                            >
                              {getNotices?.publishedBy?.name}
                              {getNotices?.isPublished === "published" ? (
                                <Tag
                                  style={{
                                    cursor: "pointer",
                                    padding: 6,
                                    width: "105px",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    marginLeft: 10,
                                    marginTop: -8,
                                  }}
                                  color="green"
                                  icon={
                                    <PublishedWithChangesIcon
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                >
                                  PUBLISHED
                                </Tag>
                              ) : (
                                <Tag
                                  style={{
                                    cursor: "pointer",
                                    padding: 6,
                                    width: "75px",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    marginLeft: 10,

                                    marginTop: -8,
                                  }}
                                  color="blue"
                                  icon={
                                    <UnpublishedIcon
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                >
                                  Draft
                                </Tag>
                              )}
                              <Typography></Typography>
                            </Grid>
                          }
                          subheader={
                            <Typography
                              style={{
                                marginTop: -6,
                                fontSize: 13,
                                color: "#6c757d",
                              }}
                            >
                              {moment(getNotices?.createdAt).format(
                                "DD MMM, YYYY, HH:mm"
                              )}
                            </Typography>
                          }
                        />

                        <CardMedia
                          component="img"
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                            maxWidth: "90%",
                            maxHeight: "80%",
                          }}
                          image={
                            getNotices?.images?.length
                              ? getNotices?.images[0]?.Location
                                ? getNotices?.images[0]?.Location
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                          }
                          alt="notice Image"
                          onClick={() => {
                            navigate(
                              navigateToNoticeDetails({
                                noticeId: getNotices?._id,
                              })
                            );
                          }}
                        />
                      </Card>
                      <Card
                        style={{ height: 95, width: "100%", marginTop: 10 }}
                      >
                        <CardContent>
                          <Typography
                            color="text.secondary"
                            style={{ fontFamily: "Poppins, sans-serif" }}
                            title={getNotices?.noticeDescription}
                          >
                            {getNotices?.noticeDescription?.length > 150
                              ? getNotices?.noticeDescription
                                  .slice(0, 150)
                                  .concat("...")
                              : getNotices?.noticeDescription}
                          </Typography>
                        </CardContent>
                        <div>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontWeight: "bolder",
                                  fontSize: 13,
                                  marginLeft: 15,
                                  marginBottom: 8,
                                }}
                              >
                                <CollectionsIcon
                                  style={{ fontSize: 18, marginRight: 5 }}
                                />
                                {getNotices?.images?.length > 0
                                  ? getNotices?.images?.length + " Images"
                                  : "No Images"}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontWeight: "bolder",
                                  fontSize: 13,
                                  marginLeft: 15,
                                  marginBottom: 8,
                                }}
                              >
                                <AttachmentIcon
                                  style={{ fontSize: 18, marginRight: 5 }}
                                />
                                {getNotices?.attachments?.length > 0
                                  ? getNotices?.attachments?.length +
                                    " Attachments"
                                  : "No Attachments"}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  fontWeight: "bolder",
                                  fontSize: 13,
                                  marginLeft: 15,
                                  marginBottom: 8,
                                }}
                              >
                                <Apartment
                                  style={{ fontSize: 18, marginRight: 5 }}
                                />
                                {getNotices?.propertyName?.length > 0
                                  ? getNotices?.propertyName?.length +
                                    `Selected ${handlePropertyName()}`
                                  : `No ${handlePropertyName()}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  )}
                  {notices?.length === 0 && !pleasewait && (
                    <Grid>
                      <Grid container style={{ justifyContent: "center" }}>
                        <Campaign
                          style={{
                            color: "#023e8a",
                            fontSize: 68,
                            textAlign: "center",
                          }}
                        />
                      </Grid>
                      <Typography
                        style={{
                          color: "#6c757d",
                          textAlign: "center",
                          fontSize: 28,
                          marginTop: 6,
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        No Notice
                      </Typography>
                      <Grid container style={{ justifyContent: "center" }}>
                        <div
                          class="card"
                          style={{
                            height: 30,
                            boxShadow: "0 3px 6px #7D858D",
                            width: "171px",
                            backgroundColor: "#2f45c5",
                            color: "#ffffff",
                            cursor: "pointer",
                            marginLeft: 8,
                            marginTop: 12,
                          }}
                          onClick={() => {
                            navigate(navigateInitialNotice({}));
                          }}
                        >
                          <div class="card-body ">
                            <p
                              class="card-text"
                              style={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                fontFamily: "Montserrat, sans-serif",
                                marginTop: "-12px",
                                marginBottom: "20px",
                                textAlign: "center",
                              }}
                            >
                              Notice Page&nbsp;&nbsp;
                              <TrendingFlat
                                style={{ marginTop: -3, fontSize: "15px" }}
                              />
                            </p>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {notices?.length === 0 && pleasewait && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <LoadingOutlined style={{ fontSize: 50 }} />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item lg={5} sm={12} xs={12}>
              <ContentBox className="analytics">
                {alldiscussions?.length > 0 && (
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: -4,
                      marginBottom: 10,
                    }}
                  >
                    RECENT DISCUSSIONS
                  </Typography>
                )}
                {alldiscussions?.slice(0, 8)?.map((discussion) => {
                  return <DiscussionCards discussion={discussion} />;
                })}
                {!pleasewait && alldiscussions?.length === 0 && (
                  <Grid style={{ marginTop: 50 }}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <QuestionAnswer
                        style={{
                          color: "#023e8a",
                          fontSize: 68,
                          textAlign: "center",
                        }}
                      />
                    </Grid>
                    <Typography
                      style={{
                        color: "#6c757d",
                        textAlign: "center",
                        fontSize: 28,
                        marginTop: 6,
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      No Discussions
                    </Typography>
                    <Grid container style={{ justifyContent: "center" }}>
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #7D858D",
                          width: "171px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginLeft: 8,
                          marginTop: 12,
                        }}
                        onClick={() => {
                          navigate(navigateInitialDiscussions({}));
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            Discussion Page&nbsp;&nbsp;
                            <TrendingFlat
                              style={{ marginTop: -3, fontSize: "15px" }}
                            />
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {pleasewait && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      textAlign: "center",
                    }}
                  >
                    Loading Discussions...
                    <LoadingOutlined
                      style={{ fontSize: 20, color: "#6c757d", marginLeft: 12 }}
                    />
                  </Typography>
                )}
              </ContentBox>
            </Grid>

            <Grid container style={{ marginTop: 12 }}>
              <Grid item lg={12} sm={12} xs={12}>
                <div
                  class="card"
                  style={
                    {
                      // borderRadius: 15
                      // minHeight: "86vh",
                      // maxHeight: "88vh",
                    }
                  }
                >
                  <div class="card-body">
                    <Grid
                      container
                      style={{
                        marginBottom: 15,
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#6c757d",
                            fontSize: 22,
                            fontWeight: "bolder",
                          }}
                        >
                          <Timeline style={{ marginRight: 4 }} />
                          GATE MANAGEMENT ENTRY & EXIT
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          container
                          style={{
                            color: "#2f45c5",
                            textAlign: "left",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          <span
                            style={{
                              color: "#6c757d",
                              fontSize: 14,
                              fontWeight: "bolder",
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            Change Date :{" "}
                          </span>
                          <ArrowCircleLeft
                            style={{ fontSize: 32, cursor: "pointer" }}
                            onClick={() => {
                              handleChangeDate(true);
                            }}
                          />
                          <input
                            type="date"
                            style={{
                              borderRadius: 6,
                              padding: 10,
                              border: "2px solid #6c757d",
                              marginBottom: 8,
                              outline: 0,
                              height: 40,
                              width: "166px",
                              marginLeft: 10,
                              marginRight: 10,
                              fontWeight: "bold",
                            }}
                            value={
                              filterStatusDate === "" ? date : filterStatusDate
                            }
                            onChange={(e) =>
                              setfilterStatusDate(e.target.value)
                            }
                          />
                          <ArrowCircleRight
                            style={{ fontSize: 32, cursor: "pointer" }}
                            onClick={() => {
                              handleChangeDate(false);
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={8}
                      spacing={2}
                      style={{
                        marginBottom: 20,
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <Grid container>
                          <div
                            class="card"
                            style={{
                              height: 34,
                              boxShadow: "0 3px 6px #4a6078",
                              width: "160px",
                              color: "#ffffff",
                              cursor: "pointer",
                              borderTop: "2px solid #2f45c5",
                            }}
                            onClick={() => {
                              setselectedRoleForGraph("visitor");
                            }}
                          >
                            <div class="card-body ">
                              <p
                                class="card-text"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "Montserrat, sans-serif",
                                  marginTop: "-12px",
                                  marginBottom: "20px",
                                  textAlign: "center",
                                  color: "#000",
                                }}
                              >
                                Visitor
                                {selectedRoleForGraph === "visitor" && (
                                  <CheckCircle
                                    style={{
                                      color: "rgb(36 202 38)",
                                      fontSize: "15px",
                                      marginLeft: 2,
                                      marginTop: -4,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <div
                            class="card"
                            style={{
                              height: 34,
                              boxShadow: "0 3px 6px #4a6078",
                              width: "160px",
                              color: "#ffffff",
                              cursor: "pointer",
                              borderTop: "2px solid #d6bf3c",
                            }}
                            onClick={() => {
                              setselectedRoleForGraph("staff");
                            }}
                          >
                            <div class="card-body ">
                              <p
                                class="card-text"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "Montserrat, sans-serif",
                                  marginTop: "-12px",
                                  marginBottom: "20px",
                                  textAlign: "center",
                                  color: "#000",
                                }}
                              >
                                Staff
                                {selectedRoleForGraph === "staff" && (
                                  <CheckCircle
                                    style={{
                                      color: "rgb(36 202 38)",
                                      fontSize: "15px",
                                      marginLeft: 2,
                                      marginTop: -4,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <div
                            class="card"
                            style={{
                              height: 34,
                              boxShadow: "0 3px 6px #4a6078",
                              width: "160px",
                              color: "#ffffff",
                              cursor: "pointer",
                              borderTop: "2px solid #b33644",
                            }}
                            onClick={() => {
                              setselectedRoleForGraph("resident");
                            }}
                          >
                            <div class="card-body ">
                              <p
                                class="card-text"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "Montserrat, sans-serif",
                                  marginTop: "-12px",
                                  marginBottom: "20px",
                                  textAlign: "center",
                                  color: "#000",
                                }}
                              >
                                Resident{" "}
                                {selectedRoleForGraph === "resident" && (
                                  <CheckCircle
                                    style={{
                                      color: "rgb(36 202 38)",
                                      fontSize: "15px",
                                      marginLeft: 2,
                                      marginTop: -4,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={8} sm={12} xs={12}>
                        <Card>
                          <CardContent>
                            <LineChart
                              data={graphData}
                              entry={entryData}
                              exit={exitData}
                              interval={interval}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={4} sm={12} xs={12}>
                        <Grid container spacing={2} style={sectionForCards}>
                          <Grid item xs={6}>
                            <Card
                              className={classes.cardColor1}
                              style={cardStyle}
                            >
                              <CardActionArea>
                                <PeopleAltIcon
                                  style={{
                                    color: "#fff",
                                    fontSize: 40,
                                    marginLeft: 13,
                                    marginTop: 6,
                                  }}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    style={{
                                      fontSize: 18,
                                    }}
                                  >
                                    Total {selectedRoleForGraph}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    style={{
                                      fontSize: 20,
                                      fontFamily: "Montserrat, sans-serif",
                                    }}
                                  >
                                    {gateManagementOtherStat?.total}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              className={classes.cardColor1}
                              style={cardStyle}
                            >
                              <CardActionArea>
                                <DirectionsWalkIcon
                                  style={{
                                    color: "#fff",
                                    fontSize: 40,
                                    marginLeft: 13,
                                    marginTop: 6,
                                  }}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    component="div"
                                    style={{
                                      fontSize: 18,
                                    }}
                                  >
                                    Total {selectedRoleForGraph} Exits
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    style={{
                                      fontSize: 20,
                                      fontFamily: "Montserrat, sans-serif",
                                    }}
                                  >
                                    {gateManagementOtherStat?.totalExit}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              className={classes.cardColor1}
                              style={cardStyle}
                            >
                              <CardActionArea>
                                <HourglassBottomIcon
                                  style={{
                                    color: "#fff",
                                    fontSize: 40,
                                    marginLeft: 13,
                                    marginTop: 6,
                                  }}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    style={{
                                      fontSize: 18,
                                    }}
                                  >
                                    Total {selectedRoleForGraph} Entry
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    style={{
                                      fontSize: 20,
                                      fontFamily: "Montserrat, sans-serif",
                                    }}
                                  >
                                    {gateManagementOtherStat?.totalEntry}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                          {selectedRoleForGraph === "staff" && (
                            <>
                              <Grid item xs={6}>
                                <Card
                                  className={classes.cardColor1}
                                  style={cardStyle}
                                >
                                  <CardActionArea>
                                    <PersonOffIcon
                                      style={{
                                        color: "#fff",
                                        fontSize: 40,
                                        marginLeft: 13,
                                        marginTop: 6,
                                      }}
                                    />
                                    <CardContent>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        style={{
                                          fontSize: 18,
                                        }}
                                      >
                                        Total Approved
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "Montserrat, sans-serif",
                                        }}
                                      >
                                        {gateManagementOtherStat?.totalApproved}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                              <Grid item xs={6}>
                                <Card
                                  className={classes.cardColor1}
                                  style={cardStyle}
                                >
                                  <CardActionArea>
                                    <ConnectWithoutContactIcon
                                      style={{
                                        color: "#fff",
                                        fontSize: 38,
                                        marginLeft: 13,
                                        marginTop: 6,
                                      }}
                                    />
                                    <CardContent>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        style={{
                                          fontSize: 18,
                                        }}
                                      >
                                        Total Rejected
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "Montserrat, sans-serif",
                                        }}
                                      >
                                        {gateManagementOtherStat?.totalRejected}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                            </>
                          )}
                          {selectedRoleForGraph !== "resident" && (
                            <Grid item xs={6}>
                              <Card
                                className={classes.cardColor1}
                                style={cardStyle}
                              >
                                <CardActionArea>
                                  <PeopleAltIcon
                                    style={{
                                      color: "#fff",
                                      fontSize: 38,
                                      marginLeft: 13,
                                      marginTop: 6,
                                    }}
                                  />
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                      style={{
                                        fontSize: 18,
                                      }}
                                    >
                                      Total Pending
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "Montserrat, sans-serif",
                                      }}
                                    >
                                      {gateManagementOtherStat?.totalPending}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          )}
                          {selectedRoleForGraph === "visitor" && (
                            <>
                              <Grid item xs={12}>
                                <Card
                                  className={classes.cardColor1}
                                  style={cardStyle}
                                >
                                  <CardActionArea>
                                    <PersonOffIcon
                                      style={{
                                        color: "#fff",
                                        fontSize: 40,
                                        marginLeft: 13,
                                        marginTop: 6,
                                      }}
                                    />
                                    <CardContent>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        style={{
                                          fontSize: 18,
                                        }}
                                      >
                                        Total Approved & Rejected
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "Montserrat, sans-serif",
                                        }}
                                      >
                                        {
                                          gateManagementOtherStat?.totalApprovedOrRejected
                                        }
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              item
              lg={12}
              sm={12}
              xs={12}
              spacing={2}
              style={{
                marginTop: 15,
                marginLeft: 2,
              }}
            >
              <div
                class="card"
                style={{
                  marginRight: 8,
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div class="card-body">
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontSize: 22,
                        fontWeight: "bolder",
                        marginBottom: 7,
                      }}
                    >
                      Mini Dashboard for Complaints (
                      {complaintdate === ""
                        ? moment(new Date()).format("DD-MM-YY")
                        : complaintdate}
                      )
                    </Typography>
                  </Typography>
                  <Grid
                    container
                    style={{ justifyContent: "flex-end", marginTop: 16 }}
                  >
                    <Typography
                      container
                      style={{
                        color: "#6c757d",
                        textAlign: "left",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      Date :&nbsp;
                      <input
                        type="date"
                        style={{
                          borderRadius: 6,
                          padding: 10,
                          border: "2px solid #6c757d",
                          marginBottom: 8,
                          outline: 0,
                          height: 40,
                          width: "146px",
                          marginRight: 6,
                        }}
                        value={
                          complaintdate === ""
                            ? moment(new Date()).format("YYYY-MM-DD")
                            : complaintdate
                        }
                        onChange={(e) => setcomplaintDate(e.target.value)}
                      />
                      &nbsp; Time :&nbsp;
                      <input
                        type="time"
                        style={{
                          borderRadius: 6,
                          padding: 10,
                          border: "2px solid #6c757d",
                          marginBottom: 8,
                          outline: 0,
                          height: 40,
                        }}
                        onChange={(e) => setstartTime(e.target.value)}
                      />
                      &nbsp;To&nbsp;
                      <input
                        type="time"
                        style={{
                          borderRadius: 6,
                          padding: 10,
                          border: "2px solid #6c757d",
                          marginBottom: 8,
                          outline: 0,
                          height: 40,
                        }}
                        disabled={startTime === ""}
                        onChange={(e) => setendTime(e.target.value)}
                      />
                    </Typography>
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 5,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            changeGraph("apply");
                          }}
                        >
                          <ManageSearchIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Apply
                        </p>
                      </div>
                    </div>
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 5,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            changeGraph("reset");
                          }}
                        >
                          <RestartAlt
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          RESET
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 12 }}>
                    {statusTypeComplaint?.map((ct) => {
                      return (
                        <ComplaintStatCard
                          type={ct.type}
                          icon={ct.icon}
                          countComplaints={countComplaints}
                          graphStatus={graphStatus === ct.icon ? true : false}
                          setgraphStatus={setgraphStatus}
                        />
                      );
                    })}
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: 16 }}
                  >
                    <Grid item xs={12} sm={12} lg={10}>
                      <ComplaintGraphData
                        data={statusGraph}
                        name={name}
                        new={newComplaint}
                        assign={assignComplaint}
                        cancel={cancelComplaint}
                        resolve={resolveComplaint}
                        close={closeComplaint}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid
              container
              item
              lg={12}
              sm={12}
              xs={12}
              spacing={2}
              style={{
                marginTop: 15,
                marginLeft: 2,
              }}
            >
              <div
                class="card"
                style={{
                  marginRight: 8,
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div class="card-body">
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontSize: 22,
                        fontWeight: "bolder",
                        marginBottom: 7,
                      }}
                    >
                      Resident Request Graph of {currentYear}
                    </Typography>
                  </Typography>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: 10 }}
                  >
                    <Grid item xs={12} sm={12} lg={10}>
                      <Bar options={options} data={data} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>

          <AddNotice
            AddNoticeModal={AddNoticeModal}
            setAddNoticeModal={setAddNoticeModal}
          />
        </main>
      </div>
      {(fetchSocietyId() === null || loader) && (
        <Loading loader={fetchSocietyId() === null || loader} />
      )}
    </>
  );
};
export default Dashboard;
