import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../style";
import {
  Avatar,
  Button,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { BASE_URL } from "../../../utils/base";
import moment from "moment";
import { Tag } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { QuestionMark } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import AddEmergencyNumber from "./AddEmergencyNumber";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";

function EmergencyNumberTable(props) {
  const classes = useStyles();
  const [deleteEmergencyContact, setDeleteEmergencyConatct] = useState(false);
  const [editEmergenyNumber, seteditEmergenyNumber] = useState(false);
  const [emergenyNumberData, setemergenyNumberData] = useState([]);
  const {
    allEmergencyNumber,
    loader,
    page,
    setloader,
    handleGetEmegencyNumber,
    isReadWrite,
  } = props;
  const [errorMsg, seterrorMsg] = useState("");

  const confirmModalDelete = (
    <div>
      <Modal open={deleteEmergencyContact}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.confirmModal}
        >
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 19,
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: 7,
              textTransform: "uppercase",
            }}
          >
            ARE YOU SURE YOU WANT TO DELETE THIS CONTACT ?
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
          </Typography>

          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            A confirmation message will appear when this process is complete.You
            can confirm by clicking below.
          </Typography>
          {loader && (
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              Please wait ... &nbsp;
              <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
            </Typography>
          )}
          <Grid container style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                setDeleteEmergencyConatct(false);
              }}
              style={{
                backgroundColor: "#C60C30",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                handleDeleteEmergencyNumber();
              }}
              style={{
                backgroundColor: "#1e2082",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );

  const handleDeleteEmergencyNumber = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        emergencyContactId: emergenyNumberData?._id,
      };
      const res = await api.deleteEmergencyNumber(sendData);
      if (res?.data?.success) {
        toast.success("Emergency Number Deleted ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setDeleteEmergencyConatct(false);
        handleGetEmegencyNumber();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact No
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created By
              </TableCell>
              {isReadWrite && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {!loader && (
            <TableBody>
              {allEmergencyNumber?.map((contact, index) => {
                return (
                  <TableRow key={contact?._id}>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page - 1) * 50 + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {contact?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {contact?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar
                          style={{ cursor: "pointer" }}
                          alt={contact?.createdBy?.name}
                          src={
                            contact?.createdBy?.image
                              ? `${BASE_URL}/user/${contact?.createdBy?.image}`
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                      <Typography
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        className={classes.textname}
                      >
                        {contact?.createdBy?.name}
                        <br />
                        {moment(contact?.createdAt).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </Typography>
                    </TableCell>
                    {isReadWrite && (
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Tag
                          color="blue"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            seteditEmergenyNumber(true);
                            setemergenyNumberData(contact);
                          }}
                          icon={<FormOutlined style={{ fontSize: "20px" }} />}
                        >
                          EDIT
                        </Tag>
                        <Tag
                          style={{ cursor: "pointer" }}
                          color="red"
                          onClick={() => {
                            setDeleteEmergencyConatct(true);
                            setemergenyNumberData(contact);
                          }}
                          icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                        >
                          DELETE
                        </Tag>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!loader && allEmergencyNumber?.length === 0 && (
        <Typography
          style={{
            color: "#6c757d",
            textAlign: "center",
            fontSize: 22,
            marginTop: 10,
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          No Conatct
        </Typography>
      )}
      {confirmModalDelete}
      <AddEmergencyNumber
        editEmergenyNumber={editEmergenyNumber}
        seteditEmergenyNumber={seteditEmergenyNumber}
        emergenyNumberData={emergenyNumberData}
        handleGetEmegencyNumber={handleGetEmegencyNumber}
        type="Edit"
      />
    </>
  );
}

export default EmergencyNumberTable;
