import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Paper,
  Grid,
  CssBaseline,
  Button,
  List,
  ListItem,
  Avatar,
} from "@material-ui/core";
import useStyles from "./style";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import Poll from "./Poll";
import AddPoll from "./AddPoll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingOutlined } from "@ant-design/icons";
import { deepPurple } from "@material-ui/core/colors";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import PollCards from "./PollCards";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import Chip from "@mui/material/Chip";
import { Pagination } from "@mui/material";
import Loading from "../../../contextAPI/Loading";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";

function AllPoll() {
  const classes = useStyles();
  const [poll, setPoll] = useState([]);
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addPollModal, setAddPollModal] = useState(false);
  const [openIndividualVotingDetails, setOpenIndividualVotingDetails] =
    useState(false);
  const [individualVoters, setIndividualVoters] = useState([]);
  const [loader2, setloader2] = useState(false);
  const [loading, setloading] = useState(false);
  const [count, setCount] = React.useState(1);
  const [page, setPage] = useState(1);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  var date = moment(new Date()).format("YYYY-MM-DD");
  const [search, setsearch] = useState("");
  const [filterPollType, setfilterPollType] = useState("all");
  const [pollCount, setPollCount] = useState(0);
  const [filterPollStatusType, setfilterPollStatusType] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [pageLimit] = useState(9);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Poll")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleCloseDialog = () => {
    setOpenIndividualVotingDetails(false);
  };

  const handleGetPoll = async () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageLimit: pageLimit,
        pageNumber: localStorage.getItem("pollPageNumber")
          ? localStorage.getItem("pollPageNumber")
          : 1,
        startDate: startDate,
        endDate: endDate === "" ? date : endDate,
        viewerType: filterPollType,
        searchKey: search,
        status: filterPollStatusType,
        isDeleted: filterPollStatusType === "deleted" ? true : false,
      };
      const res = await api.getAllPollData(sendData);
      if (res?.data?.success) {
        setPoll(res?.data?.poll?.poll);
        setPollCount(res?.data?.poll);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetPoll();
  }, [startDate, page, search, filterPollType, filterPollStatusType]);

  const handleResetFilter = () => {
    setstartDate("");
    setendDate("");
    setsearch("");
    setPage(1);
    setfilterPollStatusType("");
    setfilterPollType("all");
    localStorage.removeItem("pollPageNumber");
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    localStorage.setItem("pollPageNumber", value);
  };

  const removeStartDate = () => {
    setstartDate("");
    handleGetPoll();
  };
  const removeEndDate = () => {
    setendDate("");
    handleGetPoll();
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={classes.content}
        style={{
          filter: loading ? "blur(1px)" : "",
          overflow: loading ? "hidden" : "auto",
        }}
      >
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            sm={12}
            style={{
              pointerEvents: loading ? "none" : "",
            }}
          >
            <Paper
              style={{
                padding: 12,
                boxShadow: "0px 0px 0px",
              }}
            >
              <Grid container className="justify-content-between">
                <Grid
                  container
                  item
                  xs={12}
                  lg={5}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Poll Lists
                    {!loading ? (
                      <RefreshIcon
                        title="refresh Poll Page"
                        onClick={() => {
                          handleResetFilter();
                          handleGetPoll();
                          setPage(1);
                        }}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                      />
                    )}
                  </Typography>

                  <div
                    class="d-flex align-items-center rounded-10p border px-2 overflow-hidden gap-1 ms-2 h-100"
                    style={{
                      minWidth: "250px",
                    }}
                  >
                    <div>
                      <SearchIcon />
                    </div>
                    <input
                      className="h-100 border-0 w-100"
                      style={{ outline: "none" }}
                      type="text"
                      placeholder="search by title"
                      onChange={(e) => {
                        setsearch(e.target.value);
                        setPage(1);
                      }}
                      value={search}
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  lg={7}
                  sm={12}
                  style={{
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginTop: 12,
                      marginRight: 8,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Start Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 160,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    onChange={(event) => {
                      setstartDate(event.target.value);
                      setPage(1);
                    }}
                    placeholder="Choose Start Date"
                    value={startDate}
                    max={date}
                  />

                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginTop: 12,
                      marginRight: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    End Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 160,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    min={startDate}
                    max={date}
                    disabled={!startDate}
                    onChange={(event) => {
                      setendDate(event.target.value);
                      setPage(1);
                    }}
                    placeholder="Choose End Date"
                    value={endDate === "" ? date : endDate}
                  />

                  <div
                    className="search-button mt-2"
                    onClick={() => {
                      setPage(1);
                      handleGetPoll();
                    }}
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="search-icon"
                      >
                        <path
                          d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Grid>
                <div className="d-flex justify-content-end mt-3 w-100">
                  {isReadWrite && (
                    <div
                      className="btn btn-outline-custom"
                      style={{ width: "200px", borderRadius: "10px" }}
                      onClick={() => {
                        setAddPollModal(true);
                      }}
                    >
                      +&nbsp;Add Poll
                    </div>
                  )}
                </div>
              </Grid>

              <Grid container spacing={2} className="mt-3 d-flex gap-3 px-2">
                <PollCards
                  count={pollCount}
                  filterPollType={filterPollType}
                  setfilterPollType={setfilterPollType}
                  setPage={setPage}
                  filterPollStatus={filterPollStatusType}
                  setfilterPollStatus={setfilterPollStatusType}
                />
              </Grid>

              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 10 }}
              >
                {startDate && (
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginLeft: 6,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Chip
                      style={{
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                      }}
                      label={startDate}
                      onDelete={removeStartDate}
                    />
                  </Typography>
                )}
                {endDate && (
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginLeft: 6,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Chip
                      style={{
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                      }}
                      label={endDate}
                      onDelete={removeEndDate}
                    />
                  </Typography>
                )}
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                lg={12}
                className="mt-4 row g-3"
              >
                {poll?.map((pollData, index) => {
                  return (
                    <Poll
                      pollData={pollData}
                      handleGetPoll={handleGetPoll}
                      setOpenIndividualVotingDetails={
                        setOpenIndividualVotingDetails
                      }
                      setIndividualVoters={setIndividualVoters}
                      isReadWrite={isReadWrite}
                      setPage={setPage}
                      page={page}
                      pageLimit={pageLimit}
                      index={index}
                    />
                  );
                })}
              </Grid>
              {poll.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={pollCount?.totalPage}
                    color="primary"
                    page={
                      parseInt(localStorage.getItem("pollPageNumber"))
                        ? parseInt(localStorage.getItem("pollPageNumber"))
                        : page
                    }
                    onChange={handlePageChange}
                  />
                </Grid>
              )}

              {!loading && poll?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 31,
                    marginTop: 30,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Polls
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          maxWidth="lg"
          open={openIndividualVotingDetails}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 17,
                fontWeight: "bolder",
              }}
            >
              Voter Details of
              {individualVoters?.name?.length > 25
                ? individualVoters?.name.slice(0, 25).concat("...")
                : individualVoters?.name}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            {individualVoters?.voters?.length !== 0 && !loader2 && (
              <>
                <Grid
                  container
                  style={{ justifyContent: "center", marginBottom: 7 }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000000",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    Voter's Name
                  </Typography>
                </Grid>
                <Grid>
                  <List>
                    {individualVoters?.voters?.map(
                      (individualVoters, index) => {
                        return (
                          <ListItem>
                            <Typography
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                color: "#000",
                                fontSize: 15,
                                marginBottom: 5,
                                marginLeft: -10,
                                width: "80px",
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <Grid container>
                              <Avatar
                                style={{ textTransform: "capitalize" }}
                                sx={{ bgcolor: deepPurple[500] }}
                              >
                                {individualVoters?.voterName?.slice(0, 1)}
                              </Avatar>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  color: "#000",
                                  fontSize: 15,
                                  marginTop: 4,
                                  marginLeft: 5,
                                }}
                              >
                                {individualVoters?.voterName} (
                                {individualVoters?.voterRole
                                  ? individualVoters?.voterRole
                                  : "-"}
                                )
                              </Typography>
                            </Grid>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid>
              </>
            )}
            {individualVoters?.voters?.length === 0 && (
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontSize: 15,
                  marginTop: 4,
                  marginLeft: 5,
                  textAlign: "center",
                }}
              >
                NO VOTERS
              </Typography>
            )}
            {loader2 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  textAlign: "center",
                }}
              >
                Loading Voters...
                <LoadingOutlined
                  style={{ fontSize: 20, color: "#2f45c5", marginLeft: 12 }}
                />
              </Typography>
            )}
            {individualVoters?.length === 0 && !loader2 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  textAlign: "center",
                }}
              >
                NO VOTERS
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        <AddPoll
          addPollModal={addPollModal}
          setAddPollModal={setAddPollModal}
          handleGetPoll={handleGetPoll}
          setPage={setPage}
        />
      </main>
      {loading && <BanterLoader />}
    </div>
  );
}

export default AllPoll;
