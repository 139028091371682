import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import Modal from "@mui/material/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import * as api from "../../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";

function AddType(props) {
  const classes = useStyles();
  const { addTypeModal, setaddTypeModal, handleGetManagementTypes, type } =
    props;
  const [name, setname] = useState("");
  const [loader, setloader] = useState(false);

  const handleAddType = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let res = [];
      if (type === "visitor") {
        res = await api.AddVisitorType({ name,societyId:fetchSocietyId() });
      } else {
        res = await api.AddStaffType({ name ,societyId:fetchSocietyId()});
      }

      if (res?.data?.success) {
        toast.success(`${type} Type Added`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleGetManagementTypes();
        setaddTypeModal(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  return (
    <Modal open={addTypeModal}>
      <Paper
        className={classes.ModalView}
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          maxWidth: "350px",
          minWidth: "250px",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
        }}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
              textTransform: "capitalize",
            }}
          >
            Add {type} Type
          </Typography>
          <IconButton
            onClick={() => {
              setaddTypeModal(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Enter a name
          </Typography>

          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 4,
              outline: 0,
              marginTop: 6,
            }}
            placeholder="Type Name"
            value={name}
            maxLength={30}
            onChange={(event) => {
              setname(event.target.value);
            }}
          />
        </Grid>

        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "110px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={handleAddType}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                  opacity: loader ? 0.5 : "",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <AddCircleIcon
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}

                {!loader ? "Add" : `Adding...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddType;
