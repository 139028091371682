import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { List, ListItem, Typography } from "@material-ui/core";
import { Grid, Paper, Button, Avatar } from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Tag } from "antd";
import moment from "moment";
import { TimerOff } from "@material-ui/icons";
import { HourglassOutlined, LoadingOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HailIcon from "@mui/icons-material/Hail";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import {
  handleFlatName,
  handlePropertyName,
} from "../../../contextAPI/NamingConvention";
import { navigateToLog } from "../../../Navigation/Navigations";

function GateManagementTable(props) {
  const classes = useStyles();
  const { staff, pleasewait, page, pageLimit } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [staffInfo, setstaffInfo] = useState([]);
  const handleOpenDialog = (visitor) => {
    setstaffInfo(visitor);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function formatTime(timeString) {
    if (timeString) {
      const [hourString, minute] = timeString?.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Staff Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact No
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Type
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created By
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Creation Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Entry Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Exit Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {handleFlatName()} Details
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                IN / OUT
              </TableCell>
            </TableRow>
          </TableHead>

          {!pleasewait && (
            <TableBody>
              {staff?.map((row, index) => {
                if (row.entryTime) {
                  var entryTime = moment(row.entryTime).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }
                if (row.exitTime) {
                  var exitTime = moment(row?.exitTime).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }
                if (row.createdAt) {
                  var createdAt = moment(row?.createdAt).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }

                return (
                  <TableRow key={row?._id}>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page - 1) * pageLimit + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar
                          onClick={() => {
                            navigate(
                              navigateToLog({
                                type: "staff",
                                id: row._id,
                              })
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          alt={row.name}
                          src={
                            row.image
                              ? row?.image
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                      <Typography
                        onClick={() => {
                          navigate(
                            navigateToLog({
                              type: "staff",
                              id: row._id,
                            })
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        className={classes.textname}
                      >
                        {row.name}{" "}
                        {row?.isDeleted && (
                          <span
                            style={{
                              color: "red",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "13px",
                            }}
                          >
                            (Deleted)
                          </span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.staffType}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.createdBy?.name ? row?.createdBy?.name : "-"}
                      {row?.createdBy?.role && (
                        <span
                          style={{
                            width: "50px",
                            padding: 5,
                            textTransform: "capitalize",
                            marginLeft: 5,
                          }}
                          class="badge bg-primary"
                        >
                          {row?.createdBy?.role}
                        </span>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {createdAt ? createdAt : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {entryTime ? entryTime : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {exitTime ? exitTime : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        minWidth: 180,
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Typography style={{ marginRight: 4 }}>
                          {row?.status === "allowed" && (
                            <CheckCircleIcon
                              style={{
                                fontSize: 15,
                                color: "#008000",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status === "pending" && (
                            <AccessTimeFilledIcon
                              style={{
                                fontSize: 17,
                                color: "#ff8800",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status === "reject" && (
                            <DangerousIcon
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status === "expired" && (
                            <TimerOff
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 4,
                              }}
                            />
                          )}
                        </Typography>
                        <span>
                          <Tag
                            title={`${
                              row?.flatsStatus?.length
                            } More ${handleFlatName()} Info`}
                            color="blue"
                            onClick={() => {
                              handleOpenDialog(row);
                            }}
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              marginLeft: 3,
                            }}
                          >
                            {row?.flatsStatus?.length}
                            <InfoIcon
                              title="More Info"
                              style={{
                                color: "#2f45c5",
                                cursor: "pointer",
                                marginLeft: 5,
                                fontSize: 19,
                              }}
                            />
                          </Tag>
                        </span>
                      </Grid>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.checkInStatus === "in" && (
                        <Tag
                          style={{
                            cursor: "pointer",
                            padding: 6,
                            width: "80px",
                            fontSize: "11px",
                            marginBottom: 15,
                            fontWeight: "bold",
                          }}
                          color="green"
                          icon={
                            <DirectionsWalkIcon
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            />
                          }
                        >
                          IN
                        </Tag>
                      )}
                      {row?.checkInStatus === "out" && (
                        <Tag
                          style={{
                            cursor: "pointer",
                            padding: 6,
                            width: "80px",
                            fontSize: "11px",
                            marginBottom: 15,
                            fontWeight: "bold",
                          }}
                          color="red"
                          icon={
                            <HailIcon
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            />
                          }
                        >
                          OUT
                        </Tag>
                      )}
                      {row?.checkInStatus === "waiting" && (
                        <>
                          {row?.status === "allowed" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                                marginLeft: -10,
                              }}
                              color="#ff8800"
                              icon={
                                <HourglassOutlined
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginRight: 5,
                                  }}
                                />
                              }
                            >
                              WAITING
                            </Tag>
                          )}
                          {row?.status === "reject" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                                marginLeft: -10,
                              }}
                              color="red"
                              icon={
                                <DangerousIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginRight: 5,
                                  }}
                                />
                              }
                            >
                              REJECTED
                            </Tag>
                          )}

                          {row?.status === "expired" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                                marginLeft: -10,
                              }}
                              color="yellow"
                              icon={
                                <TimerOff
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginRight: 5,
                                  }}
                                />
                              }
                            >
                              EXPIRED
                            </Tag>
                          )}
                          {row?.status === "pending" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "180px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                                marginLeft: -10,
                              }}
                              color="orange"
                              icon={
                                <AccessTimeFilledIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginRight: 7,
                                  }}
                                />
                              }
                            >
                              NOT APPROVED YET
                            </Tag>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!staff?.length && !pleasewait && (
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              marginTop: 7,
              fontWeight: "bolder",
            }}
          >
            No Staff
          </Typography>
        </Grid>
      )}

      {pleasewait && (
        <Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <LoadingOutlined
              style={{ fontSize: 31, color: "#2f3aa3", marginTop: 10 }}
            />
          </Grid>
          <Typography style={{ textAlign: "center", color: "#2f3aa3" }}>
            Loading Staff....
          </Typography>
        </Grid>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 15,
              fontWeight: "bolder",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Information of &nbsp;
            <span style={{ color: "#2f45c5", fontWeight: "bold" }}>
              {staffInfo?.name}
            </span>
            &nbsp; {handleFlatName()} Details
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 15,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {handleFlatName} Status
            {staffInfo?.status === "pending" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="orange"
                icon={
                  <AccessTimeFilledIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                PENDING
              </Tag>
            )}
            {staffInfo?.status === "reject" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="red"
                icon={
                  <DangerousIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                REJECTED
              </Tag>
            )}
            {staffInfo?.status === "allowed" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="green"
                icon={
                  <CheckCircleIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                ALLOWED
              </Tag>
            )}
            {staffInfo?.status === "expired" && (
              <Tag
                style={{
                  cursor: "pointer",
                  padding: 6,
                  width: "100px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
                color="red"
                icon={
                  <TimerOff
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: 7,
                    }}
                  />
                }
              >
                EXPIRED
              </Tag>
            )}
          </Typography>
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Action By -{" "}
            {staffInfo?.actionBy?.name
              ? staffInfo?.actionBy?.name
              : "No Actioned yet"}
            {staffInfo?.actionBy?.role && (
              <span
                style={{
                  width: "50px",
                  padding: 5,
                  textTransform: "capitalize",
                  marginLeft: 5,
                }}
                class="badge bg-primary"
              >
                {staffInfo?.actionBy?.role}
              </span>
            )}
          </span>
          &nbsp; &nbsp;
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Action Time -
            {staffInfo?.actionTime
              ? moment(staffInfo?.actionTime).format("DD/MM/YYYY - h:mm A")
              : "No Actioned Yet"}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handlePropertyName()} Name
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Number
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginRight: 20,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Shift Timing
            </Typography>
          </Grid>
          <Grid>
            <List>
              {staffInfo?.flatsStatus?.map((flat) => {
                return (
                  <ListItem>
                    <ApartmentIcon
                      style={{
                        fontSize: 15,
                        color: "#6c757d",
                        marginRight: 4,
                        marginLeft: -17,
                      }}
                    />
                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Grid item xs={5}>
                        <Typography style={{ fontSize: 15 }}>
                          {flat?.property?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 15 }}>
                          {flat?.flat?.number}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            fontSize: 15,
                            textAlign: "end",
                            marginLeft: 15,
                          }}
                        >
                          {formatTime(flat?.shiftFrom)} &nbsp;
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#6c757d",
                            }}
                          >
                            TO
                          </span>{" "}
                          &nbsp;{formatTime(flat?.shiftTo)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GateManagementTable;
