import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "../style";
import { Close, ErrorOutline } from "@material-ui/icons";
import { toast } from "react-toastify";
import * as api from "../../../api/index";
import { LoadingOutlined } from "@ant-design/icons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AuthContext } from "../../../contextAPI/context";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import CreateIcon from "@mui/icons-material/Create";

function AddLocalContact(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const {
    localDirectoryModal,
    setLocalDirectoryModal,
    handleLocalDirectory,
    allCategory,
    categoryname,
    editlocalDirectory,
    setEditLocalDirectory,
    type,
    contactData,
    id,
  } = props;
  const [category, setCategory] = useState("all");
  const [contactName, setContactName] = useState("");
  const [loader, setloader] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (type === "Edit") {
      setContactName(contactData.name);
      setContactNumber(contactData.phone);
    }
  }, [editlocalDirectory]);

  const handlereset = () => {
    setLocalDirectoryModal(false);
    seterrorMsg("");
    setContactName("");
    setCategory("");
    setContactNumber("");
    setloader(false);
  };

  const handleAddLocalContact = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (contactNumber.length !== 10) {
      setloader(false);
      seterrorMsg("Number Must be 10 character long");
    } else {
      try {
        const sendContactData = {
          name: contactName,
          category: id,
          phone: contactNumber,
          price: "medium",
          society: fetchSocietyId(),
          createdBy: user?._id,
        };
        const res = await api.addLocalDirectory(sendContactData);
        if (res?.data?.success) {
          toast.success("Conatct Added ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          handleLocalDirectory();
          handlereset();
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };
  const handleEditContact = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        localDirectoryId: contactData?._id,
        name: contactName,
        category: id,
        phone: contactNumber,
      };
      const res = await api.editLocalDirectoryContact(sendData);
      if (res?.data?.success) {
        toast.success("Contact Edited ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleLocalDirectory();
        setEditLocalDirectory(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <div>
      <Modal open={type === "Edit" ? editlocalDirectory : localDirectoryModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            maxWidth: "650px",
            minWidth: "350px",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              {type === "Edit" ? "Edit Local Contact" : " Add Local Contact"}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  if (type === "Edit") {
                    setEditLocalDirectory(false);
                  } else {
                    handlereset();
                  }
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid
            container
            style={{
              justifyContent: "start",
              marginTop: 8,
            }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 16,
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Category :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={7}>
              {/* <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="all"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  width: "150px",
                  borderRadius: 6,
                  height: 40,
                  fontSize: "13px",
                  border: "1px solid #283f59",
                  textAlign: "center",
                  borderBottom: "none",
                  marginRight: 10,
                }}
              >
                <MenuItem selected value="all">
                  All Category
                </MenuItem>
                {allCategory.map((cate) => {
                  return (
                    <MenuItem selected value={cate?.category?.name}>
                      {cate?.category?.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 18,
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                  textTransform: "capitalize",
                }}
              >
                {type === "Edit" ? contactData.category?.name : categoryname}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent: "start",
              marginTop: 8,
            }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 16,
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Contact Name :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={7}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "90%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Contact Name"
                onChange={(event) => setContactName(event.target.value)}
                value={contactName}
                maxLength={150}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent: "start",
              marginTop: 8,
            }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 16,
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Contact Number :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={7}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "90%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Contact Number"
                onChange={(event) => setContactNumber(event.target.value)}
                value={contactNumber}
                type="number"
              />
            </Grid>
          </Grid>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "130px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                if (type === "Edit") {
                  handleEditContact();
                } else {
                  handleAddLocalContact();
                }
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                    opacity: loader ? 0.5 : "",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <>
                      {type === "Edit" ? (
                        <CreateIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      ) : (
                        <AddCircleIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}
                    </>
                  )}

                  {type === "Edit"
                    ? !loader
                      ? "Edit"
                      : `Editing...`
                    : !loader
                    ? "Add"
                    : `Adding...`}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddLocalContact;
