import React, { useState, useContext, useEffect, useRef } from "react";
import AllComplaintsTable from "./AllComplaintsTable";
import { Typography, Grid, Select, MenuItem, Card } from "@material-ui/core";
import useStyles from "../styles";
import AddComplaint from "../AddComplaint/AddComplaint";
import {
  KeyboardBackspaceRounded,
  LocationSearching,
  FilterList,
} from "@material-ui/icons";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { Pagination } from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import NewComplaintsTable from "../NewComplaints/NewComplaintsTable";
import AssignComplaintsTable from "../AssignComplaints/AssignComplaintsTable";
import ClosedComplaintsTable from "../ClosedComplaints/ClosedComplaintsTable";
import ResolvedComplaintsTable from "../ResolvedComplaints/ResolvedComplaintsTable";
import CancelComplaintsTable from "../CancelComplaints/CancelComplaintsTable";
import { useNavigate } from "react-router-dom";
import { FilterAlt, Print, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import PrintMultipleComplaint from "../Print/PrintMultipleComplaint";
import Chip from "@mui/material/Chip";
import { navigateInitialHelpdesk } from "../../../Navigation/Navigations";
import AddressFilterModal from "../../../GlobalComponents/AddressFilterModal/AddressFilterModal";
import CategoryFilterModal from "../../../GlobalComponents/CategoryFilterModal/CategoryFilterModal";
import CsvForComplaintModal from "./CsvForComplaintModal";
import ChooseVendorDropDown from "../../../GlobalComponents/DropDowns/ChooseVendorDropDown";
import SuccessAddComplaint from "../AddComplaint/SuccessAddComplaint";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";

function AllComplaints() {
  const classes = useStyles();
  const [addComplaintModal, setaddComplaintModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, plant } = authState;
  const [allComplaints, setallComplaints] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [page, setPage] = useState(
    parseInt(localStorage.getItem("pageNumber"))
      ? parseInt(localStorage.getItem("pageNumber"))
      : 1
  );
  const [pageCount, setpageCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  var date = moment(new Date()).format("YYYY-MM-DD");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setendDate] = useState();
  const [searchKey, setsearchKey] = useState("");
  const { complaintType } = useParams();

  const [searchType, setsearchType] = useState("Complaint Number");
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [loader, setloader] = useState(false);
  const [isMultiplePrint, setisMultiplePrint] = useState(false);
  const [selectedComplaintForPrint, setselectedComplaintForPrint] = useState(
    []
  );
  const [ComplaintForPrint, setComplaintForPrint] = useState([]);
  const componentRef = useRef();
  const searchTypesItems = ["Complaint Number", "Complainer Phone"];
  const [isReset, setisReset] = useState(false);
  const [downloadCsvModalOpen, setdownloadCsvModalOpen] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [vendorSearch, setvendorSearch] = useState("");

  //ADDRESS FILTER MODAL
  const [isOpenAddressFilterModal, setisOpenAddressFilterModal] =
    useState(false);
  const [isOpenZoneFilterModal, setIsOpenZoneFiltterModal] = useState(false);
  const [selectedZone, setselectedZone] = useState("");
  const [sector, setsector] = useState("Select Sector");
  const [property, setproperty] = useState("Select Property");
  const [floor, setfloor] = useState("Select Floor");
  const [flat, setflat] = useState("Select flat");

  //CATEGORY FILTER MODAL
  const [isOpenCategoryFilterModal, setisOpenCategoryFilterModal] =
    useState(false);
  const [category, setcategory] = useState([]);
  const [subcategory, setsubcategory] = useState([]);

  // FOR CSV
  const valueRef = useRef(null);
  const [fetchComplaints, setfetchComplaints] = useState([]);
  const [successComplaintModal, setSuccessComplaintModal] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Helpdesk"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleFilterReset = () => {
    localStorage.setItem("searchKey", "");
    localStorage.setItem("searchType", "Complaint Number");
    localStorage.setItem("startdate", "");
    localStorage.setItem("enddate", "");
    localStorage.removeItem("setVendorId");
    localStorage.removeItem("setVendorName");
    localStorage.removeItem("zone");
    setVendor([]);
    setvendorSearch("");
    handleResetCategoryFilter();
    handleResetAddressFilter();
    setisReset(true);
  };

  const handleResetAddressFilter = () => {
    localStorage.setItem("sectorForFilter", "Select Sector");
    setsector("Select Sector");
    localStorage.setItem("propertyForFilter", "Select Property");
    setproperty("Select Property");
    localStorage.setItem("floorForFilter", "Select Floor");
    setfloor("Select Floor");
    localStorage.setItem("flatForFilter", "Select Flat");
    setflat("Select Flat");
    localStorage.removeItem("zone");
    setselectedZone("");
  };
  const handleResetCategoryFilter = () => {
    localStorage.setItem("categoryForFilter", "Select Category");
    setcategory("Select Sector");
    localStorage.setItem("subcategoryForFilter", "Select Subcategory");
    setsubcategory("Select Subcategory");
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    localStorage.setItem("pageNumber", value);
  };

  const backToDefaultPage = async (value) => {
    localStorage.setItem("pageNumber", value);
    setPage(value);
  };
  const handleApiCall = () => {
    if (localStorage.getItem("pageNumber") == 1) {
      handleGetComplaints();
    } else {
      backToDefaultPage(1);
    }
  };

  const handleGetComplaints = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        filter: complaintType,
        pageNumber: localStorage.getItem("pageNumber")
          ? localStorage.getItem("pageNumber")
          : 1,
        searchKey: localStorage.getItem("searchKey")
          ? localStorage.getItem("searchKey")
          : "",
        searchTypesItems: localStorage.getItem("searchType")
          ? localStorage.getItem("searchType")
          : "Complaint Number",
        startDate:
          localStorage.getItem("startdate") === ""
            ? ""
            : localStorage.getItem("startdate"),
        endDate:
          localStorage.getItem("enddate") === ""
            ? date
            : localStorage.getItem("enddate"),
        userId: user._id,
        categoryId:
          localStorage.getItem("categoryForFilter") !== "Select Category"
            ? localStorage.getItem("categoryForFilter")
            : "",

        subCategoryId:
          localStorage.getItem("subcategoryForFilter") !== "Select Subcategory"
            ? localStorage.getItem("subcategoryForFilter")
            : "",

        property:
          localStorage.getItem("propertyForFilter") !== "Select Property"
            ? localStorage.getItem("propertyForFilter")
            : "",

        floor:
          localStorage.getItem("floorForFilter") !== "Select Floor"
            ? localStorage.getItem("floorForFilter")
            : "",

        flat:
          localStorage.getItem("flatForFilter") !== "Select Flat"
            ? localStorage.getItem("flatForFilter")
            : "",

        address:
          localStorage.getItem("propertyForFilter") !== "Select Property"
            ? "Selected Address"
            : "",
        sector:
          localStorage.getItem("sectorForFilter") === "Select Sector"
            ? ""
            : localStorage.getItem("sectorForFilter"),
        vendorId: localStorage.getItem("setVendorId")
          ? localStorage.getItem("setVendorId")
          : vendor?._id,
        zone:
          localStorage.getItem("zone") !== "Select Zone"
            ? localStorage.getItem("zone")
            : "",
        pageLimit: 50,
        addressGroupIds: user?.isAdmin
          ? ""
          : user?.addressGroupId?.[0]?.addressGroupId?._id,
      };
      const res = await api.getComplaint(sendData);
      if (res?.data?.success) {
        setisReset(false);
        settotalCount(res.data.data.count);
        setallComplaints(res.data.data.complaints);
        setpageCount(res.data.data.totalPages);
        setloader(false);
        setselectedComplaintForPrint(selectedComplaintForPrint);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const getAllComplaintsById = async () => {
    try {
      const sendData = {
        complainsIds: selectedComplaintForPrint,
        societyId: fetchSocietyId(),
      };
      const res = await api.getAllComplaintByIds(sendData);
      if (res?.data?.success) {
        setComplaintForPrint(res?.data?.data);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetComplaints();
  }, [page, isReset]);

  useEffect(() => {
    if (isMultiplePrint) {
      getAllComplaintsById();
    }
  }, [selectedComplaintForPrint]);

  const removeStartDate = () => {
    localStorage.setItem("startdate", "");
    setStartDate("");
    handleApiCall();
  };
  const removeEndDate = () => {
    localStorage.setItem("enddate", "");
    setendDate("");
    handleApiCall();
  };
  const removeZone = () => {
    localStorage.removeItem("zone");
    setselectedZone("");
    handleApiCall();
  };
  const removeSectorFilter = () => {
    localStorage.setItem("sectorForFilter", "Select Sector");
    setsector("Select Sector");
    handleApiCall();
  };
  const removePropertyFilter = () => {
    localStorage.setItem("propertyForFilter", "Select Property");
    setproperty("Select Property");
    handleApiCall();
  };
  const removeFloorFilter = () => {
    localStorage.setItem("floorForFilter", "Select Floor");
    setfloor("Select Floor");
    handleApiCall();
  };
  const removeFlatFilter = () => {
    localStorage.setItem("flatForFilter", "Select Flat");
    setflat("Select Flat");
    handleApiCall();
  };
  const removeCategory = () => {
    localStorage.setItem("categoryForFilter", "Select Category");
    setcategory("Select Category");
    handleApiCall();
  };
  const removeSubcategory = () => {
    localStorage.setItem("subcategoryForFilter", "Select Subcategory");
    setsubcategory("Select Subcategory");
    handleApiCall();
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Grid item lg={12} sm={12} xs={12}>
            <div class="card" style={{ borderRadius: 6 }}>
              <div class="card-body">
                <Grid
                  container
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    container
                    item
                    lg={3}
                    xs={3}
                    sm={3}
                    style={{
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="back-button"
                      onClick={() => {
                        navigate(navigateInitialHelpdesk({}));
                      }}
                    >
                      <div>
                        <p className="back-button-text">
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          &nbsp;Back to home
                        </p>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    container
                    item
                    lg={6}
                    xs={6}
                    sm={6}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div
                      class="box"
                      style={{
                        minWidth: "250px",
                        marginLeft: 10,
                        border: "1px solid #E1E1E1",
                        borderRadius: 10,
                      }}
                    >
                      <input
                        onChange={(e) => {
                          localStorage.setItem("searchKey", e.target.value);
                          setsearchKey(e.target.value);
                        }}
                        type="text"
                        placeholder="search..."
                        value={
                          localStorage.getItem("searchKey") === ""
                            ? ""
                            : localStorage.getItem("searchKey")
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            if (searchKey === "") {
                              toast.error("Please enter search input first");
                            } else {
                              handleApiCall();
                            }
                          }
                        }}
                      />
                      <div className="searchIcon">
                        <SearchIcon
                          onClick={() => {
                            if (searchKey === "") {
                              toast.error("Please enter search input first");
                            } else {
                              handleApiCall();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <FilterAlt
                      style={{
                        color: "#6c757d",
                        marginRight: 5,
                        marginTop: 10,
                      }}
                    />
                    <Select
                      value={
                        localStorage.getItem("searchType")
                          ? localStorage.getItem("searchType")
                          : localStorage.setItem(
                              "searchType",
                              "Complaint Number"
                            )
                      }
                      onChange={(event) => {
                        localStorage.setItem("searchType", event.target.value);
                        setsearchType(event.target.value);
                      }}
                      style={{
                        color: "#212529",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        borderRadius: 6,
                        width: "180px",
                        height: 34,
                        fontSize: "13px",
                        padding: 8,
                        paddingLeft: 6,
                        marginLeft: 2,
                        border: "1px solid #6c757d",
                        textAlign: "center",
                        marginTop: 5,
                      }}
                    >
                      {searchTypesItems?.map((item) => {
                        return (
                          <MenuItem
                            selected
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Montserrat,sans-serif",
                            }}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>

                  <Grid
                    container
                    item
                    lg={3}
                    xs={3}
                    sm={3}
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    {isReadWrite && (
                      <div
                        style={{
                          color: "#ffffff",
                          cursor: "pointer",
                          border: "1px solid #E1E1E1",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          setaddComplaintModal(true);
                        }}
                      >
                        <div>
                          <p
                            class="card-text"
                            style={{
                              fontSize: "15px",
                              fontFamily: "Poppins",
                              textAlign: "center",
                              color: "#03045E",
                              padding: "12px 10px",
                              fontWeight: 500,
                            }}
                          >
                            <AddIcon
                              style={{
                                marginRight: 3,
                                fontSize: "15px",
                              }}
                            />
                            Add Complaint
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{
                    marginTop: 6,
                    marginBottom: 10,
                  }}
                >
                  <Grid container item lg={5}>
                    {complaintType === "all" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #0204AA",
                            borderRadius: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                            }}
                          >
                            All Complaints
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#0204AA"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    {complaintType === "unassigned" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #EE383D",
                            borderRadius: "8px",
                            width: "145px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                            }}
                          >
                            Unassigned
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#EE383D"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    {complaintType === "assigned" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #EBBB0E",
                            borderRadius: "8px",
                            width: "145px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                              textAlign: "center",
                            }}
                          >
                            Assigned
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#EBBB0E"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    {complaintType === "resolved" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #04D000",
                            borderRadius: "6px",
                            width: "145px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                              textAlign: "center",
                            }}
                          >
                            Resolved
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#04D000"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    {complaintType === "closed" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #9747FF",
                            borderRadius: "8px",
                            width: "145px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                              textAlign: "center",
                            }}
                          >
                            Closed
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#9747FF"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    {complaintType === "cancel" && (
                      <div style={{ position: "relative", cursor: "pointer" }}>
                        <Card
                          style={{
                            padding: "12px",
                            border: "2px solid #23779A",
                            borderRadius: "8px",
                            width: "145px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontSize: "18px",
                              textAlign: "center",
                            }}
                          >
                            Cancel
                          </Typography>
                        </Card>
                        <div
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-60%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <span style={{ position: "relative" }}>
                            <svg
                              width="71"
                              height="40"
                              viewBox="0 0 71 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                                fill="#23779A"
                              />
                            </svg>
                            <span
                              className="fs-sm"
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "51%",
                                width: "60px",
                                transform: "translate(-50%, -50%)",
                                color: "#ffffff",
                                fontWeight: 600,
                                textAlign: "center",
                                textWrap: "nowrap",
                              }}
                            >
                              {totalCount?.length > 8
                                ? totalCount.slice(0, 8).concat("..")
                                : totalCount}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    container
                    item
                    lg={7}
                    style={{
                      justifyContent: "flex-end",
                      marginTop: 10,
                      gridGap: "10px",
                    }}
                  >
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: "10px 16px",
                        border: "1px solid #E1E1E1",
                        outline: 0,
                        marginTop: 10,
                      }}
                      onChange={(event) => {
                        localStorage.setItem("startdate", event.target.value);
                        setStartDate(event.target.value);
                      }}
                      placeholder="Choose Start Date"
                      max={date}
                      value={
                        localStorage.getItem("startdate") === ""
                          ? ""
                          : localStorage.getItem("startdate")
                      }
                    />
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: "10px 16px",
                        border: "1px solid #E1E1E1",
                        outline: 0,
                        marginTop: 10,
                      }}
                      min={localStorage.getItem("startdate")}
                      disabled={!startDate}
                      onChange={(event) => {
                        localStorage.setItem("enddate", event.target.value);
                        setendDate(event.target.value);
                      }}
                      placeholder="Choose End Date"
                      value={
                        localStorage.getItem("enddate") === ""
                          ? date
                          : localStorage.getItem("enddate")
                      }
                      max={date}
                    />
                    <div
                      style={{
                        height: 30,
                        width: "60px",
                        backgroundColor: "#E9E9FF",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginTop: 20,
                        borderRadius: "10px",
                      }}
                      onClick={handleApiCall}
                    >
                      <div>
                        <p
                          class="card-text"
                          style={{
                            fontSize: "15px",
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            textAlign: "center",
                            color: "#03045E",
                            marginTop: 3,
                            marginLeft: 8,
                          }}
                        >
                          <Search
                            style={{
                              marginRight: 10,
                              fontSize: "25px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    container
                    item
                    lg={3}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Grid item xs={8} style={{ marginLeft: "6px" }}>
                      <ChooseVendorDropDown
                        vendor={vendor}
                        setVendor={setVendor}
                        setsearchKey={setvendorSearch}
                        searchKey={vendorSearch}
                        isLocal={true}
                      />
                    </Grid>
                    <div
                      style={{
                        height: 30,
                        width: "60px",
                        backgroundColor: "#E9E9FF",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginTop: 10,
                        borderRadius: "10px",
                      }}
                      onClick={handleApiCall}
                    >
                      <div>
                        <p
                          class="card-text"
                          style={{
                            fontSize: "15px",
                            ontWeight: 400,
                            fontFamily: "Poppins",
                            textAlign: "center",
                            color: "#03045E",
                            marginTop: 3,
                            marginLeft: 8,
                          }}
                        >
                          <Search
                            style={{
                              marginRight: 10,
                              fontSize: "25px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    lg={9}
                    style={{ justifyContent: "flex-end", marginTop: 20 }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        marginTop: "8px",
                        marginRight: 6,
                      }}
                      onClick={handleFilterReset}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.3337 12.8336C23.0483 10.7805 22.0959 8.87816 20.6231 7.41964C19.1502 5.96111 17.2387 5.0273 15.1829 4.76204C13.1272 4.49678 11.0412 4.91479 9.24639 5.95168C7.45157 6.98857 6.04745 8.58682 5.25033 10.5002M4.66699 5.83356V10.5002H9.33366M4.66699 15.1667C4.95231 17.2197 5.90475 19.1221 7.37758 20.5806C8.85042 22.0391 10.7619 22.9729 12.8177 23.2382C14.8735 23.5034 16.9594 23.0854 18.7543 22.0485C20.5491 21.0117 21.9532 19.4134 22.7503 17.5M23.3337 22.1667V17.5H18.667"
                          stroke="#03045E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#E9E9FF",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginRight: 6,
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        onClick={() => {
                          setisOpenAddressFilterModal(true);
                        }}
                      >
                        <p
                          className="card-text"
                          style={{
                            fontSize: "15px",
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            textAlign: "center",
                            color: "#03045E",
                            padding: "10px 12px",
                          }}
                        >
                          <LocationSearching style={{ fontSize: 22 }} />
                          &nbsp; Filter By Address
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#E9E9FF",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginRight: 6,
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setisOpenCategoryFilterModal(true);
                      }}
                    >
                      <div>
                        <p
                          className="card-text"
                          style={{
                            fontSize: "15px",
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            textAlign: "center",
                            color: "#03045E",
                            padding: "10px 12px",
                          }}
                        >
                          <FilterList /> &nbsp; Filter By Category
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#E9E9FF",
                        cursor: "pointer",
                        marginLeft: 5,
                        marginRight: 6,
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setfetchComplaints([]);
                        valueRef.current = null;
                        setdownloadCsvModalOpen(true);
                      }}
                    >
                      <div>
                        <p
                          class="card-text"
                          style={{
                            fontSize: "15px",
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            textAlign: "center",
                            color: "#03045E",
                            padding: "10px 12px",
                          }}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: 6 }}
                          >
                            <path
                              d="M4.66699 19.8334V22.1667C4.66699 22.7856 4.91282 23.3791 5.35041 23.8167C5.78799 24.2542 6.38149 24.5001 7.00033 24.5001H21.0003C21.6192 24.5001 22.2127 24.2542 22.6502 23.8167C23.0878 23.3791 23.3337 22.7856 23.3337 22.1667V19.8334M8.16699 12.8334L14.0003 18.6667M14.0003 18.6667L19.8337 12.8334M14.0003 18.6667V4.66675"
                              stroke="#03045E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Download CSV
                        </p>
                      </div>
                    </div>
                    {complaintType === "all" && (
                      <div
                        style={{
                          backgroundColor: "#E9E9FF",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginRight: 6,
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          onClick={() => {
                            setselectedComplaintForPrint([]);
                            setisMultiplePrint(!isMultiplePrint);
                          }}
                        >
                          <p
                            className="card-text"
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              fontFamily: "Poppins",
                              textAlign: "center",
                              color: "#03045E",
                              padding: "10px 12px",
                            }}
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.0563 12.0237H18.082V6.27143C18.082 6.1229 18.023 5.98047 17.918 5.87545C17.8129 5.77043 17.6705 5.71143 17.522 5.71143H9.79061C9.64209 5.71143 9.49965 5.77043 9.39463 5.87545C9.28961 5.98047 9.23061 6.1229 9.23061 6.27143V12.0232H5.25629C5.10777 12.0232 4.96533 12.0822 4.86031 12.1872C4.75529 12.2922 4.69629 12.4347 4.69629 12.5832V19.852C4.69629 20.0005 4.75529 20.1429 4.86031 20.248C4.96533 20.353 5.10777 20.412 5.25629 20.412H9.11693V22.4084C9.11693 22.5569 9.17593 22.6993 9.28095 22.8044C9.38597 22.9094 9.52841 22.9684 9.67693 22.9684H17.4083C17.5568 22.9684 17.6993 22.9094 17.8043 22.8044C17.9093 22.6993 17.9683 22.5569 17.9683 22.4084V20.412H22.0569C22.2054 20.412 22.3478 20.353 22.4528 20.248C22.5579 20.1429 22.6168 20.0005 22.6168 19.852V12.5837C22.6163 12.2741 22.3654 12.0237 22.0563 12.0237ZM10.3506 6.83143H16.962V12.0232H10.3506V6.83143ZM16.8477 21.8489H10.2364V18.3047H16.8477V21.8489ZM21.4963 19.292H17.9677V17.7441C17.9677 17.5956 17.9087 17.4532 17.8037 17.3482C17.6987 17.2431 17.5563 17.1841 17.4077 17.1841H9.67637C9.52785 17.1841 9.38541 17.2431 9.28039 17.3482C9.17537 17.4532 9.11637 17.5956 9.11637 17.7441V19.292H5.81573V13.1437H9.67637C9.71445 13.1516 9.74973 13.1667 9.79005 13.1667H17.522C17.5623 13.1667 17.5981 13.1516 17.6356 13.1437H21.4957L21.4963 19.292Z"
                                fill="#03045E"
                              />
                            </svg>
                            &nbsp;
                            {isMultiplePrint
                              ? "Cancel Print"
                              : "Print Complaint"}
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  style={{ marginTop: 8, justifyContent: "space-between" }}
                >
                  <Grid container item xs={4}>
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <TravelExploreIcon />
                      &nbsp; total search result{" "}
                      <span style={{ fontSize: 15 }}>{totalCount}</span>{" "}
                      complaints
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <>
                      {localStorage.getItem("startdate") && (
                        <Typography
                          style={{
                            color: "#283f59",
                            fontSize: 14,
                            marginLeft: 6,
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <Chip
                            style={{
                              backgroundColor: "rgba(54, 162, 235, 0.2)",
                            }}
                            label={localStorage.getItem("startdate")}
                            onDelete={removeStartDate}
                          />
                        </Typography>
                      )}
                      {localStorage.getItem("enddate") && (
                        <Typography
                          style={{
                            color: "#283f59",
                            fontSize: 14,
                            marginLeft: 6,
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <Chip
                            style={{
                              backgroundColor: "rgba(54, 162, 235, 0.2)",
                            }}
                            label={localStorage.getItem("enddate")}
                            onDelete={removeEndDate}
                          />
                        </Typography>
                      )}
                      {localStorage.getItem("zone") && (
                        <Typography
                          style={{
                            color: "#283f59",
                            fontSize: 14,
                            marginLeft: 6,
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <Chip
                            style={{
                              backgroundColor: "rgba(54, 162, 235, 0.2)",
                            }}
                            label={localStorage.getItem("zone")}
                            onDelete={removeZone}
                          />
                        </Typography>
                      )}
                      {localStorage.getItem("sectorForFilter") &&
                        localStorage.getItem("sectorForFilter") !==
                          "Select Sector" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("sectorForFilter")}
                              onDelete={removeSectorFilter}
                            />
                          </Typography>
                        )}
                      {localStorage.getItem("propertyForFilter") &&
                        localStorage.getItem("propertyForFilter") !==
                          "Select Property" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("propertyDetails")}
                              onDelete={removePropertyFilter}
                            />
                          </Typography>
                        )}
                      {localStorage.getItem("floorForFilter") &&
                        localStorage.getItem("floorForFilter") !==
                          "Select Floor" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("floorName")}
                              onDelete={removeFloorFilter}
                            />
                          </Typography>
                        )}
                      {localStorage.getItem("flatForFilter") &&
                        localStorage.getItem("flatForFilter") !==
                          "Select Flat" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("flatName")}
                              onDelete={removeFlatFilter}
                            />
                          </Typography>
                        )}
                      {localStorage.getItem("categoryForFilter") &&
                        localStorage.getItem("categoryForFilter") !==
                          "Select Category" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("categoryDetails")}
                              onDelete={removeCategory}
                            />
                          </Typography>
                        )}
                      {localStorage.getItem("subcategoryForFilter") &&
                        localStorage.getItem("subcategoryForFilter") !==
                          "Select Subcategory" && (
                          <Typography
                            style={{
                              color: "#283f59",
                              fontSize: 14,
                              marginLeft: 6,
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <Chip
                              style={{
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                              }}
                              label={localStorage.getItem("subcategoryDetails")}
                              onDelete={removeSubcategory}
                            />
                          </Typography>
                        )}
                    </>
                  </Grid>
                </Grid>

                {isMultiplePrint && (
                  <Grid container>
                    <Typography
                      style={{
                        color: selectedComplaintForPrint?.length
                          ? "#283f59"
                          : "red",
                        fontSize: 13,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        marginTop: 4,
                      }}
                    >
                      <Print />
                      &nbsp;
                      {selectedComplaintForPrint?.length > 0
                        ? `Selected ${selectedComplaintForPrint?.length} complaint for print`
                        : "Please Select atleast one complaint for print"}
                    </Typography>
                    {selectedComplaintForPrint?.length > 0 && (
                      <>
                        <ReactToPrint
                          trigger={() => (
                            <div
                              class="card"
                              style={{
                                height: 30,
                                boxShadow: "0 3px 6px #4a6078",
                                width: "60px",
                                backgroundColor: "#2f45c5",
                                color: "#ffffff",
                                cursor: "pointer",
                                marginLeft: 5,
                                marginRight: 6,
                              }}
                            >
                              <div class="card-body ">
                                <p
                                  class="card-text"
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    marginTop: "-14px",
                                    marginBottom: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Print />
                                </p>
                              </div>
                            </div>
                          )}
                          content={() => componentRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <PrintMultipleComplaint
                            ref={componentRef}
                            ComplaintForPrint={ComplaintForPrint}
                            plant={plant}
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                )}
                {complaintType === "all" && (
                  <AllComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    isMultiplePrint={isMultiplePrint}
                    selectedComplaintForPrint={selectedComplaintForPrint}
                    setselectedComplaintForPrint={setselectedComplaintForPrint}
                    plant={plant}
                  />
                )}
                {complaintType === "assigned" && (
                  <AssignComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    plant={plant}
                  />
                )}
                {complaintType === "unassigned" && (
                  <NewComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    plant={plant}
                  />
                )}
                {complaintType === "resolved" && (
                  <ResolvedComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    plant={plant}
                  />
                )}
                {complaintType === "closed" && (
                  <ClosedComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    plant={plant}
                  />
                )}
                {complaintType === "cancel" && (
                  <CancelComplaintsTable
                    allComplaints={allComplaints}
                    page={page}
                    complaintType={complaintType}
                    plant={plant}
                  />
                )}
                {allComplaints?.length > 0 && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Pagination
                      className={classes.pagination}
                      count={pageCount}
                      color="primary"
                      page={
                        parseInt(localStorage.getItem("pageNumber"))
                          ? parseInt(localStorage.getItem("pageNumber"))
                          : page
                      }
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}
                {!loader && allComplaints?.length === 0 && (
                  <Typography
                    style={{
                      color: "#6c757d",
                      textAlign: "center",
                      fontSize: 22,
                      marginTop: 30,
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      textTransform: "uppercase",
                    }}
                  >
                    No {complaintType !== "all" ? complaintType : ""} Complaints
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <AddComplaint
            addComplaintModal={addComplaintModal}
            setaddComplaintModal={setaddComplaintModal}
            handleRefresh={handleGetComplaints}
            setSuccessComplaintModal={setSuccessComplaintModal}
          />
        </main>
      </div>

      <CategoryFilterModal
        isOpenCategoryFilterModal={isOpenCategoryFilterModal}
        setisOpenCategoryFilterModal={setisOpenCategoryFilterModal}
        category={category}
        setcategory={setcategory}
        subcategory={subcategory}
        setsubcategory={setsubcategory}
        isLocal={true}
        handleCategorySearch={handleGetComplaints}
      />
      <AddressFilterModal
        isSector={true}
        sector={sector}
        setsector={setsector}
        isOpenAddressFilterModal={isOpenAddressFilterModal}
        setisOpenAddressFilterModal={setisOpenAddressFilterModal}
        property={property}
        setproperty={setproperty}
        floor={floor}
        setfloor={setfloor}
        flat={flat}
        setflat={setflat}
        isLocal={true}
        handleFilterByAddress={handleGetComplaints}
        selectedZone={selectedZone}
        setselectedZone={setselectedZone}
      />
      <CsvForComplaintModal
        downloadCsvModalOpen={downloadCsvModalOpen}
        setdownloadCsvModalOpen={setdownloadCsvModalOpen}
        user={user}
        valueRef={valueRef}
        fetchComplaints={fetchComplaints}
        setfetchComplaints={setfetchComplaints}
        plant={plant}
      />
      {successComplaintModal && (
        <SuccessAddComplaint
          successComplaintModal={successComplaintModal}
          setSuccessComplaintModal={setSuccessComplaintModal}
        />
      )}

      {loader && <BanterLoader />}
    </>
  );
}

export default AllComplaints;
