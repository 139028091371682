import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api";
import CategoryIcon from "@mui/icons-material/Category";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { NoBackpackSharp } from "@mui/icons-material";
import {  toast } from "react-toastify";
import { CATEGORIES } from "../../../contextAPI/actions/auth.actions";
import { AuthContext } from "../../../contextAPI/context";

function AddCategory(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const {
    addComplaintCategory,
    setAddComplaintCategory,
    getComplaintCategory,
  } = props;
  const [categoryName, setCategoryName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const handleAddCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      if (categoryName === "") {
        setloader(false);
        seterrorMsg("category name is required");
      } else {
        const sendData = {
          societyId: fetchSocietyId(),
          categoryName: categoryName,
        };
        const res = await api.createComplaintCategory(sendData);
        if (res?.data?.success) {
          authDispatch({
            type: CATEGORIES,
            payload: res.data.society.complaintCategories,
          });
          setAddComplaintCategory(false);
          getComplaintCategory();
          setloader(false);
          seterrorMsg("");
          toast.success("Category Added Successfully");

        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  return (
    <Modal open={addComplaintCategory}>
      <Paper
        className={classes.ModalView}
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          // width: "29vw",
          overflow: "auto",
          minWidth:"300px",
          maxWidth:"29vw"
        }}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Category
          </Typography>
          <IconButton
            onClick={() => {
              setAddComplaintCategory(false);
              setloader(false);
              seterrorMsg("");
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Enter a name
          </Typography>
          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 4,
              outline: 0,
              marginTop: 6,
            }}
            onChange={(event) => setCategoryName(event.target.value)}
            placeholder="Category Name"
          />
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "150px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (!loader) {
                    handleAddCategory();
                  } else {
                  }
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <CategoryIcon
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}

                {!loader ? "Add Category" : `Adding...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddCategory;
