import React from "react";
import useStyles from "./style";
import { Button, Grid, Modal, Typography } from "@mui/material";
import { Paper } from "@material-ui/core";
import { QuestionMark } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";

function ConfirmModal({
  open,
  setOpen,
  text,
  loader,
  handleReset,
  handleConfirm,
}) {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <Paper
        style={{ backgroundColor: "#e6e7f0" }}
        className={classes.confirmModal}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          {text}
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          A confirmation message will appear when this process is complete.You
          can confirm by clicking below.
        </Typography>
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              handleReset(false);
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
            }}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CONFIRM
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default ConfirmModal;
