import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    paddingTop: "40px",
    textAlign: "center",
  },
  header: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Arial, sans-serif",
    color: "#010101",
    marginTop: "15px",
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Arial, sans-serif",
    color: "#010101",
    marginBottom: 10,
  },
  textLeft: {
    fontSize: 17,
    fontFamily: "Arial, sans-serif",
    color: "#000",
    fontWeight: "bolder",
  },
  textRight: {
    fontSize: 17,
    fontFamily: "Arial, sans-serif",
    color: "#010101",
    fontWeight: "bold",
  },
  secondPageContent: {
    pageBreakBefore: "always",
    marginTop: "40px",
    textAlign: "center",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "100%",
    textAlign: "center",
  },

  headerRowStyle: {
    border: "1px solid black",
    padding: "8px",
    backgroundColor: "#f2f2f2",
  },

  cellStyle: {
    border: "1px solid black",
    padding: "8px",
  },
}));
