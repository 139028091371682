import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import * as api from "../../../api";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import GuardTable from "./GuardTable";
import { LoadingOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { resolveErrorMessage } from "../../../services/service";

function Guard(props) {
  const [guards, setguards] = useState([]);
const {setloader,loader} = props

  const getGuards = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      const res = await api.getAllGuard({
        params: { societyId: fetchSocietyId() },
      });
      if (res?.data?.success) {
        setguards(res?.data?.guards);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error)
    }
  };
  useEffect(() => {
    getGuards();
  }, []);


  return (

        <Grid item lg={12} sm={12} xs={12}>
              <Grid container>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginLeft:5
                    }}
                  >
                    All Guards
                    {!loader ? (
                      <RefreshIcon
                        title="refresh guard Page"
                        onClick={getGuards}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
              </Grid>
              <Grid container style={{ marginTop: 10 }}>
                <GuardTable guards={guards} getGuards={getGuards} />
              </Grid>
              {!loader && guards?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Guard
                </Typography>
              )}
        </Grid>
  );
}
export default Guard;
