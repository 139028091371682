import axios from "axios";
import { BASE_URL } from "../utils/base";
import { decrypyData } from "../utils/encryption";
import { resolveErrorMessage } from "../services/service";
import { toast } from "react-toastify";

const API = axios.create({ baseURL: BASE_URL });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("refreshToken")) {
    req.headers.token = `${JSON.parse(localStorage.getItem("refreshToken"))}`;
  }
  req.headers.version = decrypyData(localStorage.getItem("version"));
  req.headers.platform = "dashboard";
  return req;
});

const ApiCall = async (type, route, data) => {
  try {
    let response;
    if (type === "post") {
      response = await API.post(route, data);
    } else {
      response = await API.get(route, data);
    }

    var success = true;
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    toast.error(
      `Something went wrong, ${resolveErrorMessage(error)},${
        error?.response?.status
      }`
    );
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default ApiCall;
