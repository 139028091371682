import React, { useState, useContext } from "react";
import * as api from "../../../../api/index";
import useStyles from "./styles";
import { Typography, Grid } from "@material-ui/core";
import { LoadingOutlined } from "@ant-design/icons";
import SocietyTable from "./SocietyTable";
import { AuthContext } from "../../../../contextAPI/context";
import { DomainAddOutlined } from "@mui/icons-material";
import { navigateToAddSocietyRequest } from "../../../../Navigation/Navigations";
import { useNavigate } from "react-router-dom";

const Society = () => {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { societies } = authState;
  const navigate = useNavigate();
  return (
    <div
      className={classes.root}
      style={{
        filter: societies === undefined ? "blur(1px)" : "",
        pointerEvents: societies === undefined ? "none" : "",
        overflow: societies === undefined ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 8,
                  }}
                >
                  {`Society Management > `}
                  <span style={{ color: "#000" }}>Society</span>
                </Typography>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "180px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 15,
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    navigate(navigateToAddSocietyRequest({}));
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                   
                    >
                      <DomainAddOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      Society Requests
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                container
                style={{ marginTop: 13, justifyContent: "center" }}
              >
                <SocietyTable
                  societies={societies}
                  authDispatch={authDispatch}
                />
              </Grid>
              {societies?.length === 0 && societies !== undefined && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Society available
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {societies === undefined && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading Society...
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default Society;
