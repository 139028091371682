import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Visitor from "../../Components/GateManagement/Visitor/Visitor";
import EntryExitLogs from "../../Components/GateManagement/Logs/EntryExitLogs";

const VisitorRoutes = {
  path: "/gatemanagement",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <Visitor />,
    },
    {
      path: "visitor",
      element: <Visitor />,
    },
    {
      path: "visitor/logs/:id",
      element: <EntryExitLogs />,
    },
  ],
};
export default VisitorRoutes;
