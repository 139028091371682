import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

export default function CategoryComplaintGraph({ data }) {
  return (
    <ResponsiveContainer width="100%" height={440}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 5, bottom: 5 }}
        id="category-data"
        // height="60vh"
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          type="category"
          dataKey="categoryName"
          style={{
            fontSize: 11,
            width: "1000px",
            color: "#0000",
            fontWeight: "bold",
          }}
          //   interval={0}
        />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="numberOfComplaints"
          fill="#11224D"
          name="Category wise Complaints"
        >
          <LabelList
            dataKey="numberOfComplaints"
            position="center"
            fill="#fff"
            style={{
              fontSize: 15,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
