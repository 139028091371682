import React from "react";
import { Avatar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BASE_URL } from "../../utils/base";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import { navigateToMeetingDetails } from "../../Navigation/Navigations";

function AllMeeting(props) {
  const navigate = useNavigate();
  const { meeting, page } = props;
  const localPageNumber = parseInt(localStorage.getItem("meetingPageNumber"))
    ? parseInt(localStorage.getItem("meetingPageNumber"))
    : page;

  const getStatusInfo = (status) => {
    switch (status) {
      case "inprogress":
        return {
          color: "rgb(202 133 36)",
          text: "INPROGRESS",
        };
      case "upcoming":
        return {
          color: "rgb(36 103 202)",
          text: "UPCOMING",
        };
      case "completed":
        return {
          color: "rgb(36 202 38)",
          text: "COMPLETED",
        };
      case "cancelled":
        return {
          color: "rgb(239 13 39)",
          text: "CANCELLED",
        };
      default:
        return {
          color: "#000000",
          text: "UNKNOWN",
        };
    }
  };
  const { color, text } = getStatusInfo(meeting?.status);

  return (
    <div className="col-md-4">
      <div
        className="card text-poppins w-100"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          borderRadius: "15px",
        }}
      >
        <div className="card-body">
          <div
            className="d-flex flex-column justify-content-between gap-3"
            style={{ height: "260px" }}
          >
            <div className="d-flex align-items-center gap-3">
              <Avatar
                src={
                  meeting?.addedBy?.image
                    ? `${BASE_URL}/user/${meeting?.addedBy?.image}`
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
              />
              <div>
                <p className="card-title-main text-capitalize mb-1">
                  {meeting?.addedBy?.name?.length > 18
                    ? meeting?.addedBy?.name.slice(0, 18).concat("...")
                    : meeting?.addedBy?.name}
                  &nbsp;
                  {meeting?.addedBy?.role && meeting?.addedBy?.role}
                </p>
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <p className="mb-0 text-secondary d-flex align-items-center gap-1 text-poppins">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 10.3333L13 13V6.33333M1 13C1 14.5759 1.31039 16.1363 1.91345 17.5922C2.5165 19.0481 3.40042 20.371 4.51472 21.4853C5.62902 22.5996 6.95189 23.4835 8.4078 24.0866C9.86371 24.6896 11.4241 25 13 25C14.5759 25 16.1363 24.6896 17.5922 24.0866C19.0481 23.4835 20.371 22.5996 21.4853 21.4853C22.5996 20.371 23.4835 19.0481 24.0866 17.5922C24.6896 16.1363 25 14.5759 25 13C25 11.4241 24.6896 9.86371 24.0866 8.4078C23.4835 6.95189 22.5996 5.62902 21.4853 4.51472C20.371 3.40042 19.0481 2.5165 17.5922 1.91345C16.1363 1.31039 14.5759 1 13 1C11.4241 1 9.86371 1.31039 8.4078 1.91345C6.95189 2.5165 5.62902 3.40042 4.51472 4.51472C3.40042 5.62902 2.5165 6.95189 1.91345 8.4078C1.31039 9.86371 1 11.4241 1 13Z"
                        stroke="#979797"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {moment(meeting?.scheduleTime).format("h:mm A")}-
                    {moment(meeting?.endMeetingTime).format("h:mm A")}
                  </p>
                  <div className="d-flex align-items-center gap-1">
                    {meeting?.viewerType === "societyWise" ? (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                          stroke="#5E5E5E"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 9H2.25L9 2.25L15.75 9H14.25"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.75 9V14.25C3.75 14.6478 3.90804 15.0294 4.18934 15.3107C4.47064 15.592 4.85218 15.75 5.25 15.75H12.75C13.1478 15.75 13.5294 15.592 13.8107 15.3107C14.092 15.0294 14.25 14.6478 14.25 14.25V9"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 9H10.5V12H7.5V9Z"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                    <span className="text-primary-theme fs-12p">
                      {meeting?.viewerType === "societyWise" ? (
                        <>Society Wise</>
                      ) : (
                        <>
                          {meeting?.properties?.length}&nbsp;
                          {handlePropertyName()}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className="mb-0 card-text-main">
                {meeting?.meetingTitle?.length > 26
                  ? meeting?.meetingTitle.slice(0, 26).concat("...")
                  : meeting?.meetingTitle}
              </p>
              <p className="mb-0">
                {meeting?.description?.length > 50
                  ? meeting?.description.slice(0, 50).concat("...")
                  : meeting?.description}
              </p>
            </div>

            <div className="d-flex align-items-center justify-content-between gap-3">
              <div
                style={{
                  boxSizing: "border-box",
                  width: "auto",
                  height: "34px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: color,
                  border: `1px solid ${color}`,
                  padding: "10px",
                }}
              >
                {text}
              </div>

              <span
                className="btn btn-icon rounded-circle"
                onClick={() => {
                  navigate(
                    navigateToMeetingDetails({ meetingId: meeting?._id })
                  );
                }}
              >
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 53 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.042 25H41.9587M41.9587 25L28.7087 37.5M41.9587 25L28.7087 12.5"
                    stroke="white"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="meeting-main-card">
        <div class="meeting-main-icon">
          {" "}
          <Avatar
            src={
              meeting?.addedBy?.image
                ? `${BASE_URL}/user/${meeting?.addedBy?.image}`
                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
            }
          />
        </div>
        <div class="meeting-main-title">
          {meeting?.addedBy?.name}{" "}
          {meeting?.addedBy?.role && meeting?.addedBy?.role}
        </div>
        <div class="meeting-main-clock-icon">
          <svg
            width="18"
            height="18"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 10.3333L13 13V6.33333M1 13C1 14.5759 1.31039 16.1363 1.91345 17.5922C2.5165 19.0481 3.40042 20.371 4.51472 21.4853C5.62902 22.5996 6.95189 23.4835 8.4078 24.0866C9.86371 24.6896 11.4241 25 13 25C14.5759 25 16.1363 24.6896 17.5922 24.0866C19.0481 23.4835 20.371 22.5996 21.4853 21.4853C22.5996 20.371 23.4835 19.0481 24.0866 17.5922C24.6896 16.1363 25 14.5759 25 13C25 11.4241 24.6896 9.86371 24.0866 8.4078C23.4835 6.95189 22.5996 5.62902 21.4853 4.51472C20.371 3.40042 19.0481 2.5165 17.5922 1.91345C16.1363 1.31039 14.5759 1 13 1C11.4241 1 9.86371 1.31039 8.4078 1.91345C6.95189 2.5165 5.62902 3.40042 4.51472 4.51472C3.40042 5.62902 2.5165 6.95189 1.91345 8.4078C1.31039 9.86371 1 11.4241 1 13Z"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="meeting-main-time">
          {moment(meeting?.scheduleTime).format("h:mm A")}-{" "}
          {moment(meeting?.endMeetingTime).format("h:mm A")}
        </div>
        <div class="meeting-main-members-title">
          {" "}
          {meeting?.meetingTitle?.length > 26
            ? meeting?.meetingTitle.slice(0, 26).concat("...")
            : meeting?.meetingTitle}
          <br />
          <p className="font-1">
            {meeting?.description?.length > 50
              ? meeting?.description.slice(0, 50).concat("...")
              : meeting?.description}
          </p>
        </div>
        <div className="meeting-main-member-icon1">
          <div
            style={{
              boxSizing: "border-box",
              width: "auto",
              height: "34px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: color,
              border: `1px solid ${color}`,
              padding: "10px",
            }}
          >
            {text}
          </div>
        </div>

        <div
          class="meeting-main-arrow-frame pointer"
          onClick={() => {
            navigate(navigateToMeetingDetails({ meetingId: meeting?._id }));
          }}
        >
          <div class="meeting-main-arrow-icon">
            <svg
              width="42"
              height="42"
              viewBox="0 0 53 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.042 25H41.9587M41.9587 25L28.7087 37.5M41.9587 25L28.7087 12.5"
                stroke="white"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="meeting-main-society-wise">
          {meeting?.viewerType === "societyWise" ? (
            <>Society Wise</>
          ) : (
            <>
              {meeting?.properties?.length}&nbsp;{handlePropertyName()}
            </>
          )}
        </div>
        <div class="meeting-main-building-icon">
          {meeting?.viewerType === "societyWise" ? (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                stroke="#5E5E5E"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.75 9H2.25L9 2.25L15.75 9H14.25"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.75 9V14.25C3.75 14.6478 3.90804 15.0294 4.18934 15.3107C4.47064 15.592 4.85218 15.75 5.25 15.75H12.75C13.1478 15.75 13.5294 15.592 13.8107 15.3107C14.092 15.0294 14.25 14.6478 14.25 14.25V9"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 9H10.5V12H7.5V9Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default AllMeeting;
