import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from "@mui/system";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {},
    backgroundColor: "#e6e7f0",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),

    minHeight: "100vh",
  },

  toolbar: theme.mixins.toolbar,

  tableContainer: {
    borderRadius: 10,
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),

  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "32vw",
    borderRadius: 6,
  },
  settingModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "43vw",
    borderRadius: 6,
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e6e7f0",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  familyCard: {
    boxShadow: "0 3px 6px #7D858D",
    height: "42vh",
    padding: "15px",
    borderRadius: 5,
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));
