import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import ApprovedRequests from "../../Components/People/Resident/ApprovedRequests/ApprovedRequests";
import FamilyMember from "../../Components/People/FamilyMember/FamilyMember";
import PendingRequests from "../../Components/People/Resident/PendingRequests/PendingRequests";
import RequestHistory from "../../Components/People/Resident/PendingRequests/RequestHistory/RequestHistory";

const PeopleResidentRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "approved/residents",
      element: <ApprovedRequests />,
    },
    {
      path: "familymember/:id",
      element: <FamilyMember />,
    },
    {
      path: "pending/residents",
      element: <PendingRequests />,
    },
    {
      path: "request/history",
      element: <RequestHistory />,
    },
  ],
};
export default PeopleResidentRoutes;
