import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../../utils/base";
import { LoadingOutlined } from "@ant-design/icons";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { Input } from "antd";
import { getPlantWiseRoleSegregation } from "../../../../../GlobalFunctions/GlobalFunctions";

function VendorVisit(props) {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    setupdateProcecssModal,
    user,
    classes,
    handlegetComplaintById,
    plant
  } = props;

  const { TextArea } = Input;
  const [message, setmessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setmessage("");
    seterrorMessage("");
    setloader(false);
  };

  const handleVendorVisit = async () => {
    if (message.trim() === "" || message?.length < 8) {
      seterrorMessage(
        "Please enter a vendor visit message of at least 8 character!"
      );
    } else {
      callingApi();
      const sendData = {
        complaintId: complaint._id,
        complainerId: complaint.complainer._id,
        complaintLifeCycle: "Vendor visit",
        vendorId: complaint.vendor._id,
        adminId: user._id,
        description: message,
        updatedBy:getPlantWiseRoleSegregation(user,plant),
        updatedFor:"Vendor"
      };
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.complaintLifeCycle,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Vendor Visit  Added Successfully");
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  return (
    <Modal open={selectProcess === "Vendor Visit"}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          // width: "29vw",
          minWidth: "300px",
          maxWidth: "29vw",
          overflow: "auto",
          pointerEvents: loader ? "none" : "",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Vendor Visit
          </Typography>

          <IconButton
            onClick={() => {
              seterrorMessage("");
              setmessage("");
              setselectProcess("Select Process");
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        <Grid container style={{ justifyContent: "center" }}>
          <Grid
            container
            style={{
              padding: 6,
              borderRadius: 6,
              backgroundColor: "#4b8edb",
              color: "#fff",
              textAlign: "center",
              border: "2px solid #283f59",
              width: 300,
              marginBottom: 10,
            }}
          >
            <Grid item xs={2}>
              <img
                class="round"
                src={
                  complaint?.vendor?.image
                    ? `${BASE_URL}/user/${complaint?.vendor?.image}`
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
                alt="user"
                height="50px"
                width="50px"
                style={{ borderRadius: 50 }}
              />
            </Grid>

            <Grid item xs={10}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {complaint?.vendor?.name}
              </Typography>
              <Typography style={{ fontSize: 13 }}>
                {complaint?.vendor?.email}
              </Typography>
              <Typography style={{ fontSize: 13 }}>
                {complaint?.vendor?.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <TextArea
          style={{
            marginTop: 10,
            height: 100,
            borderRadius: 10,
          }}
          placeholder="Type Some Message"
          maxLength={500}
          value={message}
          onChange={(event) => setmessage(event.target.value)}
        />
        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={() => {
              handleVendorVisit();
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleVendorVisit();
              }
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  ""
                )}
                {!loader ? "Submit" : `Submitting...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default VendorVisit;
