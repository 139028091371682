import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
} from "@material-ui/core";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import { BASE_URL } from "../../../utils/base";
import { LoadingOutlined } from "@ant-design/icons";

function ViwersAndPropertyModal(props) {
  const {
    openOtherInfoModal,
    handleCloseDialog,
    otherInfoKey,
    otherInfo,
    loader2,
  } = props;
  return (
    <Dialog
      maxWidth="lg"
      open={openOtherInfoModal}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            color: "#6c757d",
            fontSize: 17,
            fontWeight: "bolder",
          }}
        >
          {otherInfoKey === "viewers"
            ? "DISCUSSION VIEWERS INFO"
            : `Discussion ${handlePropertyName()} Info`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {otherInfoKey === "viewers" && !loader2 && (
          <>
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                Sr. No.
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 20,
                }}
              >
                Viewer Name
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 40,
                }}
              >
                Viewer Role
              </Typography>
            </Grid>
            <Grid>
              <List>
                {otherInfo?.viewedBy?.map((viewers, index) => {
                  return (
                    <ListItem>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                        }}
                      >
                        {index + 1}.&nbsp;&nbsp;
                      </Typography>

                      <Grid container>
                        {viewers?.image ? (
                          <Avatar
                            sx={{ width: 29, height: 29 }}
                            alt={viewers?.name}
                            src={`${BASE_URL}/user/${viewers.image}`}
                          />
                        ) : (
                          <Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={viewers?.name}
                          >
                            {viewers?.name?.slice(0, 1)}
                          </Avatar>
                        )}
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontSize: 15,
                            marginTop: 5,
                            marginLeft: 10,
                            textTransform: "capitalize",
                          }}
                        >
                          {viewers?.name}
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                          width: "80px",
                        }}
                      >
                        {viewers?.role ? viewers?.role : "-"}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </>
        )}

        {otherInfoKey === "properties" && !loader2 && (
          <>
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                Sr. No.
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 20,
                }}
              >
                {handlePropertyName()} Name
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 40,
                }}
              >
                {handlePropertyName()} Sector
              </Typography>
            </Grid>
            <Grid>
              <List>
                {otherInfo?.properties?.map((prop, index) => {
                  return (
                    <ListItem>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                        }}
                      >
                        {index + 1}.&nbsp;&nbsp;
                      </Typography>

                      <Grid container>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontSize: 15,
                            marginLeft: 40,
                            textTransform: "capitalize",
                            marginTop: -4,
                          }}
                        >
                          {prop?.name}
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                          width: "80px",
                        }}
                      >
                        {prop?.sector}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </>
        )}
        {loader2 && (
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 18,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              textAlign: "center",
            }}
          >
            Loading ...
            <LoadingOutlined
              style={{ fontSize: 20, color: "#2f45c5", marginLeft: 12 }}
            />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViwersAndPropertyModal;
