import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import LocalDirectory from "../../Components/Help/LocalDirectory/LocalDirectory";
import LocalDirectoryDetails from "../../Components/Help/LocalDirectory/LocalDirectoryDetails";

const LocalDirectoryRoutes = {
  path: "/help",
  element: <NavigationDrawer />,
  children: [
    {
        path: "localdirectory",
        element: <LocalDirectory />
    },
    {
        path: "localdirectorydetails/:categoryname/:id",
        element: <LocalDirectoryDetails />
    },
]
};
export default LocalDirectoryRoutes;
