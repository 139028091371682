import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { LoadingOutlined } from "@ant-design/icons";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PeopleIcon from "@mui/icons-material/People";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import SearchIcon from "@mui/icons-material/Search";
import { BASE_URL } from "../../../utils/base";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";

function ViewEscalationProfile(props) {
  const classes = useStyles();
  const {
    showEscalationProfile,
    setshowEscalationProfile,
    escalationProfile,
    loader3,
    categoryId,
    handleGetEscalationProfile,
    isReadWrite
  } = props;
  const [addUpdateEscalationProfile, setaddUpdateEscalationProfile] =
    useState(false);
  const [chooseProfileSection, setchooseProfileSection] = useState(false);
  const [selectedRole, setselectedRole] = useState("admin");
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [allUsers, setallUsers] = useState([]);
  const [choosedLevel, setchoosedLevel] = useState("");
  const [officerLevel1, setofficerLevel1] = useState([]);
  const [officerLevel2, setofficerLevel2] = useState([]);
  const [officerLevel3, setofficerLevel3] = useState([]);
  const [daysLevel1, setdaysLevel1] = useState("");
  const [hourseLevel1, sethourseLevel1] = useState("");
  const [daysLevel2, setdaysLevel2] = useState("");
  const [hourseLevel2, sethourseLevel2] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [loader2, setloader2] = useState(false);

  const getAllUser = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        role: selectedRole,
        societyId: fetchSocietyId(),
        searchKey: search,
      };
      const res = await api.getAllUserFromSociety(sendData);
      if (res?.data?.success) {
        if (selectedRole === "admin") {
          setallUsers(res?.data?.persons?.admins);
        } else if (selectedRole === "owner") {
          setallUsers(res?.data?.persons?.owners);
        } else if (selectedRole === "tenant") {
          setallUsers(res?.data?.persons?.tenants);
        } else {
          setallUsers(res?.data?.persons?.vendors);
        }
      }
      setloader(false);
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error)
    }
  };


  useEffect(() => {
    getAllUser();
  }, [search,selectedRole]);

  const handleAddAutoEscalation = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    seterrorMsg("");
    let temp1 =
      24 * parseInt(daysLevel1 === "" ? 0 : daysLevel1) +
      parseInt(hourseLevel1 === "" ? 0 : hourseLevel1);
    let temp2 =
      24 * parseInt(daysLevel2 === "" ? 0 : daysLevel2) +
      parseInt(hourseLevel2 === "" ? 0 : hourseLevel2);

    if (temp1 === 0) {
      setloader2(false);
      seterrorMsg("Please Choose Deadline from Level1 to Level2");
    }
    else if (temp2 === 0) {
      setloader2(false);
      seterrorMsg("Please Choose Deadline from Level2 to Level3");
    } else if (!officerLevel1.email) {
      setloader2(false);
      seterrorMsg("Please Choose Officer for Level1");
    } else if (!officerLevel2.email) {
      setloader2(false);
      seterrorMsg("Please Choose Officer for Level2");
    } else if (!officerLevel3.email) {
      setloader2(false);
      seterrorMsg("Please Choose Officer for Level3");
    } else {
      try {
        const sendData = {
          email1: officerLevel1.email,
          email2: officerLevel2.email,
          email3: officerLevel3.email,
          complaintcategoryId: categoryId,
          deadline2: temp1.toString(),
          deadline3: temp2.toString(),
        };

        const res = await api.addAutoEscalation(sendData);
        if (res?.data?.success) {
          handleGetEscalationProfile()
          toast.success("Escalation Profile Updated Successfully")
          handleAfterClose();

        }
      } catch (error) {
        setloader2(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  const handleAfterClose = () => {
    seterrorMsg("");
    setaddUpdateEscalationProfile(false);
    setloader2(false);
    setdaysLevel1("");
    sethourseLevel1("");
    setdaysLevel2("");
    sethourseLevel2("");
    setofficerLevel1([]);
    setofficerLevel2([]);
    setofficerLevel3([]);
    setchoosedLevel("");
  };


  return (
    <>
      {/* SHOW ESCALATION PROFILE */}
      <Modal open={showEscalationProfile}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.deleteModal}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Escalate Profiles
            </Typography>

            <IconButton
              onClick={() => {
                setshowEscalationProfile(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>

          {Object.keys(escalationProfile)?.length > 0 && (
            <>
              {/* LEVEL1 */}
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "15px",
                }}
              >
                Level 1 Profile
              </Typography>
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "13px",
                }}
              >
                <MarkEmailUnreadIcon
                  style={{
                    fontSize: 17,
                    marginRight: 4,
                    color: "#212529",
                  }}
                />
                Email&nbsp;:&nbsp;
                <span style={{ color: "#254c71" }}>
                  {escalationProfile?.levelOne?.email}
                </span>
              </Typography>
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "13px",
                }}
              >
                <AccessTimeFilledIcon
                  style={{
                    fontSize: 17,
                    marginRight: 4,
                    color: "#212529",
                  }}
                />
                Deadline from Level-1 to Level-2 &nbsp;:&nbsp;
                <span style={{ color: "#254c71" }}>
                  {escalationProfile?.levelTwo?.deadline}
                  &nbsp;Hours
                </span>
              </Typography>
              <Divider
                style={{
                  marginTop: 8,
                  marginBottom: 15,
                  backgroundColor: "#fff",
                }}
              />
              {/* LEVEL2 */}
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "15px",
                }}
              >
                Level 2 Profile
              </Typography>
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "13px",
                }}
              >
                <MarkEmailUnreadIcon
                  style={{
                    fontSize: 17,
                    marginRight: 4,
                  }}
                />
                Email&nbsp;:&nbsp;
                <span style={{ color: "#254c71" }}>
                  {escalationProfile?.levelTwo?.email}
                </span>
              </Typography>
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "13px",
                }}
              >
                <AccessTimeFilledIcon
                  style={{
                    fontSize: 17,
                    marginRight: 4,
                  }}
                />
                Deadline from Level-2 to Level-3 &nbsp;:&nbsp;
                <span style={{ color: "#254c71" }}>
                  {escalationProfile?.levelTwo?.deadline}
                  &nbsp;Hours
                </span>
              </Typography>
              <Divider
                style={{
                  marginTop: 8,
                  marginBottom: 15,
                  backgroundColor: "#fff",
                }}
              />
              {/* LEVEL3 */}
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "15px",
                }}
              >
                Level 3 Details
              </Typography>
              <Typography
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "98%",
                  padding: 5,
                  paddingLeft: 24,
                  fontSize: "13px",
                }}
              >
                <MarkEmailUnreadIcon
                  style={{
                    fontSize: 17,
                    marginRight: 4,
                  }}
                />
                Email&nbsp;:&nbsp;
                <span style={{ color: "#254c71" }}>
                  {escalationProfile?.levelThree?.email}
                </span>
              </Typography>
            </>
          )}
          {Object.keys(escalationProfile)?.length === 0 && !loader3 && (
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 17,
                marginTop: 7,
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              No Esacalation Profile Added
            </Typography>
          )}
          {Object.keys(escalationProfile)?.length === 0 && loader3 && (
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 17,
                marginTop: 7,
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              Loading Profiles...{" "}
              <LoadingOutlined
                style={{ fontSize: 20, color: "#2f45c5", marginLeft: 8 }}
              />
            </Typography>
          )}
      {  
      isReadWrite &&
      <Grid
            container
            style={{
              justifyContent: "center",
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "200px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginRight: 15,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setaddUpdateEscalationProfile(true);
                  }}
                >
                  {Object.keys(escalationProfile)?.length === 0
                    ? "Add Escalation Profiles"
                    : "Update Escalation Profiles"}
                </p>
              </div>
            </div>
          </Grid>}
        </Paper>
      </Modal>
      {/* ADD OR UPDATE ESCALATION PROFILE */}
      <Modal open={addUpdateEscalationProfile}>
        <Paper
          style={{
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            width: "45vw",
            maxHeight: "82vh",
            overflow: "auto",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                color: "#212529",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Poppins, sans-serif",
                fontSize: 17,
                opacity: 1,
              }}
            >
              {Object.keys(escalationProfile)?.length === 0
                ? "Add Escalation Profiles"
                : "Update Escalation Profiles"}
            </Typography>

            {!loader2 && (
              <IconButton
                onClick={() => {
                  handleAfterClose();
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>

          {/* LEVEL_1 */}
          <Typography
            style={{
              color: "#212529",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
              marginTop: 5,
              marginBottom: 6,
              textAlign: "left",
              fontSize: "15px",
            }}
          >
            Level-1 Auto Escalation
          </Typography>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              boxShadow: "1px 6px 10px #7D858D",
            }}
          >
            <>
              <Grid container style={{ justifyContent: "center" }}>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "220px",
                    backgroundColor: "rgb(38 40 104)",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setchoosedLevel("1");
                        setchooseProfileSection(true);
                      }}
                    >
                      {" "}
                      <TaskAltIcon style={{ marginRight: 4, fontSize: 20 }} />
                      Choose Officer Level-1
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <Typography
                  style={{
                    color: "#212529",
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 5,
                    textAlign: "center",
                    marginBottom: 5,
                    fontSize: "14px",
                  }}
                >
                  Deadline from Level-1 to Level-2
                </Typography>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <input
                  type="number"
                  style={{
                    height: "40px",
                    display: "flex",
                    minWidth: "220px",
                    maxWidth: "250px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                    padding: 10,
                    border: "1px solid #fff",
                    marginBottom: 8,
                    marginRight: 12,
                    outline: 0,
                  }}
                  onChange={(event) => setdaysLevel1(event.target.value)}
                  placeholder="Enter Days"
                  min={1}
                  value={daysLevel1}
                />
                <input
                  type="number"
                  style={{
                    height: "40px",
                    display: "flex",
                    minWidth: "220px",
                    maxWidth: "250px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                    padding: 10,
                    border: "1px solid #fff",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => sethourseLevel1(event.target.value)}
                  placeholder="Enter Hours"
                  min={1}
                  value={hourseLevel1}
                />
              </Grid>

              {Object.keys(officerLevel1)?.length > 0 && (
                <>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Choosed Officer :
                  </Typography>
                  <Grid
                    container
                    style={{
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "rgb(38 40 104)",
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={2} style={{ marginTop: 5 }}>
                      <img
                        class="round"
                        src={
                          officerLevel1?.image
                            ? `${BASE_URL}/user/${officerLevel1.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt="user"
                        height="50px"
                        width="50px"
                        style={{ borderRadius: 50 }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        style={{
                          textAlign: "left",
                          fontSize: 15,
                        }}
                      >
                        <MarkEmailUnreadIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        {officerLevel1?.email}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        <AdminPanelSettingsIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {officerLevel1?.role ? officerLevel1?.role : "Vendor"}
                        </span>
                      </Typography>
                      <Typography
                        container
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <AccessTimeFilledIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        Total Deadline Hours from Level-1 to Level-2 :&nbsp;
                        {24 * parseInt(daysLevel1 === "" ? 0 : daysLevel1) +
                          parseInt(hourseLevel1 === "" ? 0 : hourseLevel1)}
                        Hours
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Paper>

          {/* LEVEL_2 */}

          <Typography
            style={{
              color: "#212529",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
              marginTop: 6,
              marginBottom: 6,
              textAlign: "left",
              fontSize: "15px",
            }}
          >
            Level-2 Auto Escalation
          </Typography>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              boxShadow: "1px 6px 10px #7D858D",
            }}
          >
            <>
              <Grid container style={{ justifyContent: "center" }}>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "220px",
                    backgroundColor: "rgb(38 40 104)",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setchoosedLevel("2");
                        setchooseProfileSection(true);
                      }}
                    >
                      {" "}
                      <TaskAltIcon style={{ marginRight: 4, fontSize: 20 }} />
                      Choose Officer Level-2
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <Typography
                  style={{
                    color: "#212529",
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 5,
                    textAlign: "center",
                    marginBottom: 5,
                    fontSize: "14px",
                  }}
                >
                  Deadline from Level-2 to Level-3
                </Typography>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <input
                  type="number"
                  style={{
                    height: "40px",
                    display: "flex",
                    minWidth: "220px",
                    maxWidth: "250px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                    padding: 10,
                    border: "1px solid #fff",
                    marginBottom: 8,
                    marginRight: 12,
                  }}
                  onChange={(event) => setdaysLevel2(event.target.value)}
                  placeholder="Enter Days"
                  min={1}
                  value={daysLevel2}
                />
                <input
                  type="number"
                  style={{
                    height: "40px",
                    display: "flex",
                    minWidth: "220px",
                    maxWidth: "250px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                    padding: 10,
                    border: "1px solid #fff",
                    marginBottom: 8,
                  }}
                  onChange={(event) => sethourseLevel2(event.target.value)}
                  placeholder="Enter Hours"
                  min={1}
                  value={hourseLevel2}
                />
              </Grid>

              {Object.keys(officerLevel2)?.length > 0 && (
                <>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Choosed Officer :
                  </Typography>
                  <Grid
                    container
                    style={{
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "rgb(38 40 104)",
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={2} style={{ marginTop: 5 }}>
                      <img
                        class="round"
                        src={
                          officerLevel2?.image
                            ? `${BASE_URL}/user/${officerLevel2.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt="user"
                        height="50px"
                        width="50px"
                        style={{ borderRadius: 50 }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        style={{
                          textAlign: "left",

                          fontSize: 17,
                        }}
                      >
                        <MarkEmailUnreadIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        {officerLevel2?.email}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        <AdminPanelSettingsIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {officerLevel2?.role ? officerLevel2?.role : "Vendor"}
                        </span>
                      </Typography>
                      <Typography
                        container
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <AccessTimeFilledIcon
                          style={{
                            fontSize: 17,
                            marginRight: 4,
                          }}
                        />
                        Total Deadline Hours from Level-2 to Level-3 :&nbsp;
                        {24 * parseInt(daysLevel2 === "" ? 0 : daysLevel2) +
                          parseInt(hourseLevel2 === "" ? 0 : hourseLevel2)}
                        Hours
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Paper>

          {/* LEVEL_3 */}
          <Typography
            style={{
              color: "#212529",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
              marginTop: 6,
              marginBottom: 6,
              textAlign: "left",
              fontSize: "15px",
            }}
          >
            Level-3 Auto Escalation
          </Typography>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              boxShadow: "1px 6px 10px #7D858D",
            }}
          >
            <Grid container style={{ justifyContent: "center" }}>
              <div
                class="card"
                style={{
                  height: 30,
                  boxShadow: "0 3px 6px #4a6078",
                  width: "220px",
                  backgroundColor: "rgb(38 40 104)",
                  color: "#ffffff",
                  cursor: "pointer",
                  marginRight: 15,
                }}
              >
                <div class="card-body ">
                  <p
                    class="card-text"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "-12px",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setchoosedLevel("3");
                      setchooseProfileSection(true);
                    }}
                  >
                    {" "}
                    <TaskAltIcon style={{ marginRight: 4, fontSize: 20 }} />
                    Choose Officer Level-3
                  </p>
                </div>
              </div>
            </Grid>
            {Object.keys(officerLevel3)?.length > 0 && (
              <>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Choosed Officer :
                </Typography>
                <Grid
                  container
                  style={{
                    padding: 6,
                    borderRadius: 6,
                    marginTop: 6,
                    backgroundColor: "rgb(38 40 104)",
                    color: "#fff",
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={2}>
                    <img
                      class="round"
                      src={
                        officerLevel3?.image
                          ? `${BASE_URL}/user/${officerLevel1.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      alt="user"
                      height="50px"
                      width="50px"
                      style={{ borderRadius: 50 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      style={{
                        textAlign: "left",
                        fontSize: 15,
                      }}
                    >
                      <MarkEmailUnreadIcon
                        style={{
                          fontSize: 17,
                          marginRight: 4,
                        }}
                      />
                      {officerLevel3?.email}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    >
                      <PeopleIcon
                        style={{
                          fontSize: 17,
                          marginRight: 4,
                        }}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {officerLevel3?.role ? officerLevel3?.role : "Vendor"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "200px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!loader2) {
                     handleAddAutoEscalation()
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  {Object.keys(escalationProfile)?.length === 0 ? (
                    <>
                      {loader2 && (
                        <LoadingOutlined
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}
                      {!loader2 ? "Add Escalation Profiles" : `Adding...`}
                    </>
                  ) : (
                    <>
                      {loader2 && (
                        <LoadingOutlined
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}
                      {!loader2 ? "Update Escalation Profiles" : `Updating...`}
                    </>
                  )}

                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>

      {/* SELECT PROFILE SECTION */}
      <Modal open={chooseProfileSection}>
        <Paper
          style={{
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            width: "50vw",
            maxHeight: "90vh",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                color: "#000",
                textAlign: "center",
                fontWeight: "bold",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Escalation Profile for Level - {choosedLevel}
            </Typography>

            <IconButton
              onClick={() => {
                setchooseProfileSection(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid
            container
            style={{ marginTop: 8, justifyContent: "space-between" }}
          >
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginTop: 6,
                }}
              >
                Selected Role :
                <Select
                  value={selectedRole}
                  onChange={(event) => setselectedRole(event.target.value)}
                  style={{
                    color: "#212529",
                    backgroundColor: "#fff",
                    fontWeight: "bold",
                    borderRadius: 6,
                    width: "180px",
                    height: 37,
                    fontSize: "13px",
                    padding: 8,
                    paddingLeft: 6,
                    marginLeft: 5,
                    border: "1px solid #6c757d",
                    textAlign: "center",
                  }}
                >
                  <MenuItem
                    selected
                    value="admin"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <ManageAccountsIcon
                      style={{ marginRight: 4, fontSize: 20 }}
                    />
                    Management
                  </MenuItem>
                  <MenuItem
                    selected
                    value="owner"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <ApartmentIcon style={{ marginRight: 4, fontSize: 20 }} />
                    Owner
                  </MenuItem>
                  <MenuItem
                    selected
                    value="tenant"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <ApartmentIcon style={{ marginRight: 4, fontSize: 20 }} />
                    Tenant
                  </MenuItem>
                  <MenuItem
                    selected
                    value="vendor"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <AccountCircleIcon
                      style={{ marginRight: 4, fontSize: 20 }}
                    />
                    Vendor
                  </MenuItem>
                </Select>
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ marginTop: -4 }}>
              <div class="box">
                <input
                  type="text"
                  placeholder="name or email or numb.."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
              </div>
            </Grid>
          </Grid>
          {allUsers?.length > 0 && (
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  marginLeft: 12,
                  marginTop: -8,
                }}
              >
                Total results Found :&nbsp;
                <span style={{ color: "#000", fontSize: 16 }}>
                  {allUsers?.length}
                </span>
              </Typography>
            </Grid>
          )}

          <Grid
            container
            style={{
              justifyContent: "space-between",
              // border: "1px solid #000",
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              backgroundColor: "#2f45c5",
              marginTop: 10,
              padding: 12,
              paddingLeft: 17,
              paddingRight: 17,
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Name
            </Typography>
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Email
            </Typography>
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
                marginRight: -50,
              }}
            >
              Phone
            </Typography>
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Action
            </Typography>
          </Grid>

          <TableContainer
            component={Paper}
            style={{ overflow: "scroll", height: "60vh" }}
          >
            {!loader && (
              <Table style={{ height: "max-content" }}>
                <TableBody style={{ padding: 2 }}>
                  {allUsers?.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell
                        align="left"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                          width: "160px",
                        }}
                      >
                        {index + 1}.&nbsp;{row?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                          width: "160px",
                        }}
                      >
                        {row?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                        }}
                      >
                        {row?.phone}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        onClick={() => {
                          if (choosedLevel === "1") {
                            setofficerLevel1(row);
                            setchooseProfileSection(false);
                          } else if (choosedLevel === "2") {
                            setofficerLevel2(row);
                            setchooseProfileSection(false);
                          } else {
                            setofficerLevel3(row);
                            setchooseProfileSection(false);
                          }
                        }}
                      >
                        <Tooltip
                          title={`Select ${row?.name}`}
                          placement="top"
                          arrow
                        >
                          <TaskAltIcon
                            onClick={() => {
                              if (choosedLevel === "1") {
                                setofficerLevel1(row);
                                setchooseProfileSection(false);
                              } else if (choosedLevel === "2") {
                                setofficerLevel2(row);
                                setchooseProfileSection(false);
                              } else {
                                setofficerLevel3(row);
                                setchooseProfileSection(false);
                              }
                            }}
                            style={{ color: "#2f45c5", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {loader && (
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                Loading.... <LoadingOutlined style={{ color: "#2f45c5" }} />
              </Typography>
            )}
            {!loader && allUsers?.length === 0 && (
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                No User Found
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Modal>
    </>
  );
}

export default ViewEscalationProfile;
