import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import ApprovedManager from "../../Components/People/Manager/ApprovedManager";
import ManagerPendingRequest from "../../Components/People/Manager/ManagerPendingRequest";

const ManagerRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <ApprovedManager />,
    },
    {
      path: "manager",
      element: <ApprovedManager />,
    },
    {
      path: "manager/request",
      element: <ManagerPendingRequest />,
    },
  ],
};
export default ManagerRoutes;
