import { CheckCircle } from "@material-ui/icons";
import allnotice from "../../assets/allnotice.png";

const NoticeTotalCards = (props) => {
  const { isPublished, setisPublished, count, setPage } = props;

  const icons = {
    all: {
      icon: allnotice,
      count: count?.allCount,
      title: "All Notice",
      type: "all",
    },
    published: {
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 22 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 1V13L1 21V25H21V21L17 13V1M11 25V35M3 1H19"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      count: count?.publishedCount,
      title: "Published",
      type: "published",
    },
    unpublished: {
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L37 37M25 4L18.502 10.498M13.362 13.364L9 15L6 18L20 32L23 29L24.64 24.628M27.5 19.502L34 13M13 25L4 34M24 3L35 14"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      count: count?.UnpublishedCount,
      title: "Unpublished",
      type: "unpublished",
    },
    deleted: {
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14H40M10 14L12 38C12 39.0609 12.4214 40.0783 13.1716 40.8284C13.9217 41.5786 14.9391 42 16 42H32C33.0609 42 34.0783 41.5786 34.8284 40.8284C35.5786 40.0783 36 39.0609 36 38L38 14M18 14V8C18 7.46957 18.2107 6.96086 18.5858 6.58579C18.9609 6.21071 19.4696 6 20 6H28C28.5304 6 29.0391 6.21071 29.4142 6.58579C29.7893 6.96086 30 7.46957 30 8V14M20 24L28 32M28 24L20 32"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      count: count?.deleted,
      title: "Deleted",
      type: "deleted",
    },
  };

  const NoticeCard = ({ icon, title, count, active, type }) => {
    return (
      <div
        className={`card rounded-10p pointer ${active ? "border-success" : ""}`}
        onClick={() => {
          setisPublished(type);
          setPage(1);
        }}
        style={{
          boxShadow: `${
            active
              ? "rgba(36, 202, 38, 0.4) 0px 1px 2px 0px, rgba(36, 202, 38, 0.15) 0px 2px 6px 2px"
              : "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }`,
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center gap-2">
            {typeof icon === "string" ? (
              <img
                src={icon}
                alt="Icon"
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              icon
            )}
            <div>
              <p
                className={`text-nowrap mb-0 text-poppins h6 ${
                  active ? "text-dark" : "text-secondary"
                }`}
              >
                {title}
              </p>
              <p
                className={`mb-0 text-poppins ${
                  active ? "text-dark" : "text-secondary"
                }`}
              >
                {count}
              </p>
            </div>
            {isPublished === type && (
              <CheckCircle
                style={{
                  color: "rgb(36 202 38)",
                  fontSize: "30px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center gap-2 pointer">
      {Object?.keys(icons)?.map((key) => (
        <div key={key}>
          <NoticeCard
            icon={icons[key]?.icon}
            title={icons[key]?.title}
            count={icons[key]?.count}
            active={isPublished === icons[key]?.type}
            type={icons[key]?.type}
          />
        </div>
      ))}
    </div>
  );
};

export default NoticeTotalCards;
