import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "../style";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import LocalDirectoryDetailsTable from "./LocalDirectoryDetailsTable";
import AddLocalContact from "./AddLocalContact";
import * as api from "../../../api/index";
import Loading from "../../../contextAPI/Loading";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Pagination } from "@mui/material";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../contextAPI/context";
import { navigateToLocalDirectory } from "../../../Navigation/Navigations";

function LocalDirectoryDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const { categoryname, id } = useParams();
  const [searchKey, setSearchKey] = useState("");
  const [filterCategory, setfilterCategory] = useState("all");
  const [allLocalContact, setAllLocalContact] = useState([]);
  const [loader, setloader] = useState(false);
  const [localDirectoryModal, setLocalDirectoryModal] = useState(false);
  const [count, setCount] = React.useState(0);
  const [allCategory, setAllCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [errorMsg, seterrorMsg] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Local Directory")
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleLocalDirectory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        society: fetchSocietyId(),
        searchKey: searchKey,
        category: id,
        pageLimit: 50,
        pageNumber: page,
      };
      const res = await api.getLocalDirectory(sendData);
      if (res?.data?.success) {
        setCount(res.data?.directory?.totalPage);
        setAllLocalContact(res?.data?.directory?.localDirectories);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleGetDirectoryCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        society: fetchSocietyId(),
      };
      const res = await api.getLocalDirectoryCategory(sendData);
      if (res?.data?.success) {
        setAllCategory(res?.data?.directory);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleLocalDirectory();
  }, [page, searchKey, filterCategory]);

  useEffect(() => {
    handleGetDirectoryCategory();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={classes.content}
        style={{
          filter: loader ? "blur(1px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
        }}
      >
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={5}
                  lg={5}
                  sm={5}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    {`Local Directory > `}{" "}
                    <span style={{ color: "#000" }}>{categoryname}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Local Directory Page"
                        onClick={handleLocalDirectory}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  lg={7}
                  sm={7}
                  style={{
                    justifyContent: "flex-end",
                    marginBottom: 12,
                  }}
                >
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search by title"
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(1);
                      }}
                      value={searchKey}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                  <div>
                    {/* <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="all"
                      value={filterCategory}
                      onChange={(event) =>
                        setfilterCategory(event.target.value)
                      }
                      style={{
                        color: "#212529",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        width: "150px",
                        borderRadius: 6,
                        height: 40,
                        fontSize: "13px",
                        border: "1px solid #283f59",
                        textAlign: "center",
                        borderBottom: "none",
                        marginRight: 10,
                      }}
                    >
                      <MenuItem selected value="all">
                        All Category
                      </MenuItem>
                      {allCategory.map((cate) => {
                        return (
                          <MenuItem selected value={cate?.name}>
                            {cate?.name}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 7,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setLocalDirectoryModal(true);
                          }}
                        >
                          <AddIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginTop: 7,
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      navigate(navigateToLocalDirectory({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        BACK
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 15 }}>
                <LocalDirectoryDetailsTable
                  allLocalContact={allLocalContact}
                  page={page}
                  loader={loader}
                  setloader={setloader}
                  handleLocalDirectory={handleLocalDirectory}
                  isReadWrite={isReadWrite}
                />
              </Grid>
              {!loader && allLocalContact?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Local Conatct
                </Typography>
              )}
              {allLocalContact?.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddLocalContact
          localDirectoryModal={localDirectoryModal}
          setLocalDirectoryModal={setLocalDirectoryModal}
          handleLocalDirectory={handleLocalDirectory}
          allCategory={allCategory}
          categoryname={categoryname}
          id={id}
        />
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default LocalDirectoryDetails;
