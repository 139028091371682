import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import SocietyRequestTable from "./SocietyRequestTable";
import useStyles from "../style";
import { AuthContext } from "../../contextAPI/context";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../services/service";
import * as api from "../../api/index";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import RefreshIcon from "@mui/icons-material/Refresh";

const SocietyRequest = () => {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const [status, setstatus] = useState("Approved");
  const [societyRequests, setsocietyRequests] = useState([]);
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [allCount, setallCount] = useState({
    approved: 0,
    pending: 0,
    rejected: 0,
  });
  const [isConfirmResend, setisConfirmResend] = useState(false);
  const [page, setpage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);

  const startApiCall = () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const endApiCall = () => {
    setloader(false);
  };

  const handleGetAllSocietyRequestForAdmin = async () => {
    startApiCall();
    seterrorMessage("");
    try {
      let sendData = {
        adminId: user?._id,
        societyId: fetchSocietyId(),
        status: status.toLocaleLowerCase(),
        pageNumber: page,
        pageLimit: pageLimit,
      };
      const res = await api.societyRequestbyAdmin(sendData);
      if (res?.data?.success) {
        setsocietyRequests(res?.data?.adminRequests?.adminRequests);
        setallCount({
          approved: res?.data?.adminRequests?.approved,
          pending: res?.data?.adminRequests?.pending,
          rejected: res?.data?.adminRequests?.rejected,
        });
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMessage(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetAllSocietyRequestForAdmin();
  }, [status]);

  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginLeft: 8,
                    }}
                  >
                    {`S2 Admin > `}
                    <span style={{ color: "#000" }}>Society Requests</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Society Request"
                        onClick={handleGetAllSocietyRequestForAdmin}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: 13, justifyContent: "center" }}
              >
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={{ fontFamily: "Montserrat, sans-serif" }}
                    value={status}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Approved"
                      control={<Radio style={{ color: "rgb(36 202 38)" }} />}
                      label={`Approved ${allCount?.approved}`}
                    />
                    <FormControlLabel
                      value="Pending"
                      control={<Radio style={{ color: "rgb(202 133 36)" }} />}
                      label={`Pending ${allCount?.pending}`}
                    />
                    <FormControlLabel
                      value="Rejected"
                      control={<Radio style={{ color: "rgb(239 13 39)" }} />}
                      label={`Rejected ${allCount?.rejected}`}
                    />
                  </RadioGroup>
                </Grid>
                {societyRequests?.length > 0 && !loader && (
                  <SocietyRequestTable
                    status={status}
                    societyRequests={societyRequests}
                    isConfirmResend={isConfirmResend}
                    setisConfirmResend={setisConfirmResend}
                    handleGetAllSocietyRequestForAdmin={
                      handleGetAllSocietyRequestForAdmin
                    }
                    loader={loader}
                    setloader={setloader}
                  />
                )}
              </Grid>
              {societyRequests?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No {status} Requests
                  </Typography>
                </Grid>
              )}
              {loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    Loading.... <LoadingOutlined />
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
    </div>
  );
};

export default SocietyRequest;
