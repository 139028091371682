import React, { useState } from "react";
import useStyles from "../../styles";
import { Grid, Avatar, Typography, Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BASE_URL } from "../../../../utils/base";
import { Circle } from "@mui/icons-material";
import { resolveErrorMessage } from "../../../../services/service";
import * as api from "../../../../api";
import ConfirmModal from "../../../../contextAPI/ConfirmModal";
import { toast } from "react-toastify";
import UserProfileDetailsModal from "../../../../GlobalComponents/UserProfileDetailsModal/UserProfileDetailsModal";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";

function RejectedRequeststable(props) {
  const classes = useStyles();
  const { allRejectedRequests, page, getAllPendingRequests, user, plant } =
    props;
  const [selectedUser, setselectedUser] = useState([]);
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [IsChangeStatus, setIsChangeStatus] = useState(false);
  const [isShowUserProfileDetails, setisShowUserProfileDetails] =
    useState(false);

  const rejectConfirmation = () => {
    setIsChangeStatus(false);
  };

  const handleChangeResidentStatus = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        userId: selectedUser?._id,
        actionBy: user?._id,
        societyId: fetchSocietyId(),
      };
      const res = await api.changeResidentRequestsIntoPending(sendData);
      if (res?.data?.success) {
        setloader(false);
        setIsChangeStatus(false);
        getAllPendingRequests();
        toast.success("Resident Send for Approval Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleSetForStatusChange = (resident) => {
    setselectedUser(resident);
    seterrorMsg("");
    setIsChangeStatus(true);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Time
              </TableCell>

              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Unique ID
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Phone
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Status
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRejectedRequests?.map((resident, index) => (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setselectedUser(resident);
                    setisShowUserProfileDetails(true);
                  }}
                >
                  <Grid container style={{ justifyContent: "center" }}>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      alt={resident.name}
                      src={
                        resident.image
                          ? `${BASE_URL}/user/${resident.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      className={classes.avatar}
                    />
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {resident?.name}
                  </Typography>
                  <span
                    style={{
                      width: `${plant ? "60px" : "50px"} `,
                      padding: 5,
                      textTransform: "capitalize",
                      marginLeft: 5,
                    }}
                    class={"badge bg-primary"}
                  >
                    {plant ? "Employee" : resident?.role}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {moment(resident?.actionTime).format("DD/MM/YYYY - h:mm A")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {resident?.uniqueId}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {resident?.email}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {resident?.phone}
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {resident?.approvalRequest?.status === "rejected" && (
                    <Typography
                      style={{
                        color: "rgb(239 13 39)",
                        fontWeight: "bold",
                        fontSize: 12,
                        marginTop: 4,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      &nbsp;REJECTED
                    </Typography>
                  )}{" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "capitalize",
                    fontSize: "13px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#2f45c5",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      handleSetForStatusChange(resident);
                    }}
                  >
                    Send For Approval
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={`ARE YOU SURE YOU WANT TO SEND FOR APPROVAL THIS RESIDENT ?`}
        description="You can confirm by clicking below."
        loader={loader}
        handleConfirmation={handleChangeResidentStatus}
        rejectConfirmation={rejectConfirmation}
        isOpen={IsChangeStatus}
        errorMsg={errorMsg}
      />
      <UserProfileDetailsModal
        isOpen={isShowUserProfileDetails}
        setIsOpen={setisShowUserProfileDetails}
        user={selectedUser}
      />
    </>
  );
}

export default RejectedRequeststable;
