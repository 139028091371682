import {
  Avatar,
  Box,
  Card,
  Fab,
  Grid,
  Hidden,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import { Span } from "./Typography.js";
import { format } from "date-fns";
import { Fragment } from "react";
import { ArrowRightAlt } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { Tooltip, Typography } from "@material-ui/core";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { BASE_URL } from "../../utils/base";
import AvatarGroup from "@mui/material/AvatarGroup";
import { navigateToDiscussionDetails } from "../../Navigation/Navigations.jsx";

const ProjectName = styled(Span)(({ theme }) => ({
  marginLeft: 24,
  fontWeight: "500",
  color: "#03045e",
  [theme.breakpoints.down("sm")]: { marginLeft: 4 },
}));

const StarOutline = styled(Fab)(() => ({
  marginLeft: 0,
  boxShadow: "none",
  background: "#08ad6c !important",
  backgroundColor: "rgba(9, 182, 109, 1) !important",
}));

const DateRange = styled(Fab)(({ theme }) => ({
  marginLeft: 0,
  boxShadow: "none",
  color: "white !important",
  background: `${theme.palette.error.main} !important`,
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: "1.3em !important",
  height: "1.3em !important",
}));

const DiscussionCards = (props) => {
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;
  const { discussion } = props;
  const navigate = useNavigate();
  return (
    <Fragment>
      <Card sx={{ py: 1, px: 2 }} className="project-card">
        <Grid container alignItems="center">
          <Tooltip
            title={
              <>
                <TextsmsIcon />
                Total Comments:
                {discussion?.commentSection?.length > 0
                  ? discussion?.commentSection?.length + " Comments"
                  : "No Comments"}
              </>
            }
            placement="top"
            arrow
          >
            <Grid item md={5} xs={7}>
              <Box display="flex" alignItems="center">
                {/* <Checkbox /> */}
                <Hidden smDown>
                  <img
                    style={{ objectFit: "contain", height: 50, width: 50 }}
                    src={
                      discussion?.images[0]
                        ? discussion?.images[0]?.Location
                        : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                    }
                  />
                </Hidden>

                <ProjectName>
                  {discussion?.discussionTitle?.length > 12
                    ? discussion?.discussionTitle.slice(0, 12).concat("...")
                    : discussion?.discussionTitle}
                </ProjectName>
              </Box>
            </Grid>
          </Tooltip>

          <Grid item xs={4} sm={3} md={3} style={{ width: "22px" }}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontWeight: "bold",
                position: "relative",
              }}
            >
              {format(new Date(discussion?.createdAt).getTime(), "MM/dd/yyyy")}
            </Typography>
          </Grid>

          <Hidden smDown>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display="flex"
                position="relative"
                marginLeft="-0.875rem !important"
                justifyContent="flex-end"
              >
                {discussion?.viewedBy?.slice(0, 3)?.map((view) => {
                  return (
                    <AvatarGroup max={discussion?.viewedBy?.length}>
                      {view?.image ? (
                        <Avatar
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                          }}
                          alt={view?.name}
                          src={`${BASE_URL}/user/${view.image}`}
                        />
                      ) : (
                        <Avatar
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                          }}
                          alt={view?.name}
                        >
                          {view?.name?.slice(0, 1)}
                        </Avatar>
                      )}
                    </AvatarGroup>
                  );
                })}
                {discussion?.viewedBy?.length > 3 && (
                  <StyledAvatar style={{ fontSize: "1.4em" }}>
                    +{discussion?.viewedBy?.length - 3}
                  </StyledAvatar>
                )}
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={"View Details"} placement="top" arrow>
                <IconButton
                  onClick={() => {
                    navigate(
                      navigateToDiscussionDetails({
                        disucssionId: discussion._id,
                      })
                    );
                  }}
                >
                  <ArrowRightAlt
                    style={{
                      fontSize: 25,
                      cursor: "pointer",
                      color: "#2f45c5",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Box py={1} />
    </Fragment>
  );
};

export default DiscussionCards;
