import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "../../../contextAPI/Loading";
import ManagerPendingTable from "./ManagerPendingTable";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import MakeManagerModal from "../Resident/ApprovedRequests/MakeManagerModal";
import ApiCall from "../../../api/ApiCall";
import { AllResidentRoutes } from "../../../api/Routes";
import { toast } from "react-toastify";
import ConfirmModal from "../../../contextAPI/ConfirmModal";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router";
import { navigateToManager } from "../../../Navigation/Navigations";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";

function ManagerPendingRequest() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [isReadWrite, setIsReadWrite] = useState(false);

  // Bulk Manager
  const [addManager, setaddManager] = useState(false);
  const [removeManager, setremoveManager] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("SelectGroup");
  const [managerData, setmanagerData] = useState([]);
  const [allManager, setallManager] = useState([]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Manager"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const rejectConfirmation = () => {
    setaddManager(false);
    setremoveManager(false);
    setSelectedGroup("SelectGroup");
    seterrorMessage("");
  };

  //Make Manager
  const handleMakeManger = async () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);

    const data = {
      userId: managerData?._id,
      addressGroupId: selectedGroup,
      isApproved: removeManager ? false : true,
      requestId: managerData?.pendingRequests?.[0]?._id,
      societyId: fetchSocietyId(),
    };
    const res = await ApiCall("post", AllResidentRoutes.makeManager, data);
    if (res?.success) {
      rejectConfirmation();
      handleGetPendingManger();
      toast.success("Manager approved successfully!");
      setloader(false);
    } else {
      seterrorMessage(res?.error);
      setloader(false);
    }
  };

  //GET MANAGER
  const handleGetPendingManger = async () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);

    const data = {
      filters: {
        status: "pending",
        society: fetchSocietyId(),
      },
      page: page,
      limit: 20,
    };
    const res = await ApiCall("post", AllResidentRoutes.getManager, data);
    if (res?.success) {
      setallManager(res.data?.data);
      setCount(res?.data?.totalPages);
      setloader(false);
    } else {
      seterrorMessage(res?.error);
      setloader(false);
    }
  };
  useEffect(() => {
    handleGetPendingManger();
  }, []);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className="col-sm-12">
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Manager Request
                    {!loader ? (
                      <RefreshIcon
                        title="Refresh Manager Page"
                        onClick={handleGetPendingManger}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <div
                    className="back-button"
                    onClick={() => {
                      navigate(navigateToManager());
                    }}
                  >
                    <div>
                      <p className="back-button-text">
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        &nbsp;Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {allManager?.length > 0 && (
                <>
                  <Grid style={{ marginTop: 16, marginRight: 20 }}>
                    <ManagerPendingTable
                      setaddManager={setaddManager}
                      setmanagerData={setmanagerData}
                      setremoveManager={setremoveManager}
                      allManager={allManager}
                      page={page}
                      isReadWrite={isReadWrite}
                    />
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <Pagination
                        className={classes.pagination}
                        count={count}
                        color="primary"
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {!loader && allManager?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 30,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Pending Manager
                </Typography>
              )}
            </div>
          </div>
        </div>
      </main>
      {addManager && (
        <MakeManagerModal
          addManager={addManager}
          setaddManager={setaddManager}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          handleMakeManger={handleMakeManger}
          errorMessage={errorMessage}
          loader2={loader}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <ConfirmModal
        title={`${"ARE YOU SURE YOU WANT TO REJECT MANAGER REQUEST FOR THIS USER"}`}
        description="You can confirm by clicking below."
        loader={loader}
        handleConfirmation={handleMakeManger}
        rejectConfirmation={rejectConfirmation}
        isOpen={removeManager}
        errorMsg={errorMessage}
      />
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default ManagerPendingRequest;
