import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minHeight: "100vh",
  },
  tableContainer: {
    borderRadius: 10,
    margin: "15px 0px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },

  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));