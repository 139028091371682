import { makeStyles, useTheme } from "@material-ui/core/styles";
const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: "all 0.3s ease",
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    backgroundColor: "#ffffff",
    color: "#03045E",
    justifyContent: "space-between",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: "all 0.3s ease",
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: "all 0.3s ease",
    backgroundColor: "#fff",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7.5) + 1,
    },
    backgroundColor: "#fff",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#fff",
    height: "60px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },

  appHover: {
    "&:hover": {
      backgroundColor: "rgba(46, 48, 148, 0.1)",
      cursor: "pointer",
      borderRadius: "10px",
    },
  },
  subHover: {
    "&:hover": {
      border: "rgba(46, 48, 148, 0.1)",
      cursor: "pointer",
      borderRadius: "25px",
      padding: "4px",
    },
  },
  imageProfile: {},
  text: {
    color: "#03045e",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#ffffff",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },

  buttonDelete: {
    marginTop: theme.spacing(3),
    backgroundColor: "#fff",
    padding: 8,
    fontWeight: "bold",
    fontSize: 13,
    width: 180,
    color: "#C60C30",
    "&:hover": {
      backgroundColor: "#C60C30",
      color: "#fff",
    },
  },
}));
