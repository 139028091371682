import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Paper,
  Select,
  MenuItem,
  Modal,
} from "@material-ui/core";
import {
  Close,
  Edit,
  Email,
  ErrorOutline,
  Person,
  Phone,
} from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getAllSocietiesProperty,
  getFloorFlat,
  getPropertyFloor,
} from "../../../../contextAPI/AddressInfo";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../services/service";
import * as api from "../../../../api";
import {
  handlePropertyName,
  handleFloorName,
  handleFlatName,
} from "../../../../contextAPI/NamingConvention";
import SearchAddress from "../../../../GlobalComponents/SearchAddress/SearchAddress";

function ChangeAddressModal(props) {
  const classes = useStyles();
  const {
    changeAddressModalOpen,
    setchangeAddressModalOpen,
    changeAddressUser,
    getAllPendingRequests,
    user,
    type,
    userAddressDetails,
    flatRole,
  } = props;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [selectedProperty, setselectedProperty] = useState("Property");
  const [selectedFloor, setselectedFloor] = useState("Floor");
  const [selectedFlat, setselectedFlat] = useState("Flat");
  const [allProperties, setallProperties] = useState([]);
  const [allFloors, setallFloors] = useState([]);
  const [allFlats, setallFlats] = useState([]);
  //selection / property Modal
  const [propertyId, setPropertyId] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  useEffect(() => {
    if (userAddressDetails) {
      setselectedProperty(
        userAddressDetails?.property?._id
          ? userAddressDetails?.property?._id
          : "Property"
      );
      setselectedFloor("Floor");
      setselectedFlat("Flat");
    }
  }, [changeAddressUser, changeAddressModalOpen]);

  useEffect(async () => {
    if (changeAddressModalOpen) {
      setallProperties(await getAllSocietiesProperty());
    }
  }, [changeAddressModalOpen]);

  useEffect(async () => {
    if (propertyId?._id !== "" && changeAddressModalOpen) {
      setallFloors(await getPropertyFloor(propertyId?._id));
    }
  }, [propertyId]);

  useEffect(async () => {
    if (selectedFloor !== "Floor" && changeAddressModalOpen) {
      setallFlats(await getFloorFlat(selectedFloor));
    }
  }, [selectedFloor]);

  useEffect(() => {
    setselectedFloor("Floor");
    setselectedFlat("Flat");
  }, [propertyId]);

  const handleReset = () => {
    setchangeAddressModalOpen(false);
    seterrorMsg("");
    setPropertyId([]);
    setselectedFloor("Floor");
    setselectedFlat("Flat");
    setloader(false);
  };

  const handleChangeAddress = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (
      propertyId === "Property" ||
      selectedFlat === "Flat" ||
      selectedFloor === "Floor"
    ) {
      setloader(false);
      seterrorMsg("All * fields are required");
    } else {
      try {
        let sendData = {
          userId: changeAddressUser._id,
          flatId: selectedFlat,
          propertyId: propertyId?._id,
          floorId: selectedFloor,
          societyId: fetchSocietyId(),
          adminId: user?._id,
          oldFlatId: userAddressDetails?._id,
          role: flatRole || null,
        };
        let res;
        if (type == "Approved") {
          res = await api.approvedUserChangeAddress(sendData);
        } else {
          res = await api.editResidentOccupiedFlat(sendData);
        }
        if (res?.data?.success) {
          getAllPendingRequests();
          toast.success("Resident Address Changed Successfully");
          handleReset();
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  return (
    <Modal open={changeAddressModalOpen}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          width: "35vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Change Address
          </Typography>
          {!loader && (
            <IconButton
              onClick={() => {
                handleReset();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          )}
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        <Grid
          style={{ border: "1px solid #6c757d", borderRadius: 8, padding: 6 }}
        >
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "800",
              fontSize: 14,
              textAlign: "center",
            }}
          >
            <Person />
            &nbsp;
            {changeAddressUser?.name}
            {/* <span
              style={{
                width: "50px",
                padding: 5,
                textTransform: "capitalize",
                marginLeft: 5,
              }}
              class={"badge bg-primary"}
            >
              {changeAddressUser?.role}
            </span> */}
          </Typography>

          {changeAddressUser?.email && (
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                textAlign: "center",
              }}
            >
              <Email />
              &nbsp;
              {changeAddressUser?.email}
            </Typography>
          )}
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "800",
              fontSize: 14,
              textAlign: "center",
            }}
          >
            <Phone />
            &nbsp;
            {changeAddressUser?.phone}
          </Typography>
        </Grid>

        {/* CHANGE PROPERTY */}
        <Grid container justifyContent={"flex-start"} style={{ marginTop: 8 }}>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              {handlePropertyName()} &nbsp;<span>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SearchAddress
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              setPropertyId={setPropertyId}
              propertyId={propertyId}
              isMultiple={false}
            />
          </Grid>
        </Grid>

        {/* CHANGE FLOOR */}
        <Grid container justifyContent={"flex-start"} style={{ marginTop: 6 }}>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              {handleFloorName()} &nbsp;<span>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              value={selectedFloor}
              onChange={(event) => setselectedFloor(event.target.value)}
              style={{
                display: "block",
                color: "#343a40",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "#fff",
                borderRadius: 6,
                fontSize: 16,
                width: "98%",
                padding: 7,
                paddingLeft: 20,
                height: 46,
              }}
              disabled={!propertyId?._id || loader}
            >
              <MenuItem
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat,sans-serif",
                }}
                selected
                value="Floor"
              >
                {handleFloorName()}
                {propertyId?._id && (
                  <>
                    {allFloors?.allFloors?.length > 0
                      ? `(${allFloors?.allFloors?.length})`
                      : ""}
                  </>
                )}
              </MenuItem>
              {allFloors?.allFloors?.map((pr) => {
                return (
                  <MenuItem
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                    selected
                    value={pr?._id}
                  >
                    {pr?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        {/* CHANGE FLAT */}
        <Grid container justifyContent={"flex-start"} style={{ marginTop: 6 }}>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 16,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              {handleFlatName()}&nbsp;<span>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              value={selectedFlat}
              onChange={(event) => setselectedFlat(event.target.value)}
              style={{
                display: "block",
                color: "#343a40",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "#fff",
                borderRadius: 6,
                fontSize: 16,
                width: "98%",
                padding: 7,
                paddingLeft: 20,
                height: 46,
              }}
              disabled={selectedFloor === "Floor" || loader}
            >
              <MenuItem
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat,sans-serif",
                }}
                selected
                value="Flat"
              >
                {handleFlatName()}
                {selectedFloor !== "Floor" && <> ({allFlats?.length})</>}
              </MenuItem>
              {allFlats?.map((pr) => {
                return (
                  <MenuItem
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    selected
                    value={pr?._id}
                  >
                    {pr?.name}&nbsp;({pr.occupancyStatus})
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        {errorMsg && (
          <Typography
            style={{
              marginTop: 8,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
              marginLeft: 25,
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}

        <Grid
          container
          item
          style={{ justifyContent: "flex-end", marginLeft: -50, marginTop: 8 }}
        >
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "169px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!loader) {
                handleChangeAddress();
              } else {
                // NOTHING HAPPENED
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                }}
              >
                <>
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <Edit
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  Change Address
                </>
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default ChangeAddressModal;
