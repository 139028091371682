import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "../ComplaintCategory/styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import ExploreIcon from "@mui/icons-material/Explore";
import { startApiCall } from "../../../GlobalFunctions/GlobalFunctions";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import ApiCall from "../../../api/ApiCall";
import { AreaRoutes } from "../../../api/Routes";
import { toast } from "react-toastify";

const AddArea = ({
  addArea,
  setAddArea,
  editArea,
  isEditArea,
  setisEditArea,
  handleGetAllArea,
}) => {
  const classes = useStyles();
  const [areaName, setAreaName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (isEditArea) {
      setAreaName(editArea?.name);
    }
  }, [isEditArea]);

  const handleReset = () => {
    setAddArea(false);
    setloader(false);
    seterrorMsg("");
    setAreaName("");
  };

  const handleAddArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      name: areaName,
      societyId: fetchSocietyId(),
    };
    const res = await ApiCall("post", AreaRoutes.addArea, data);
    if (res?.success) {
      toast.success("Area Added Successfully!");
      handleReset();
      handleGetAllArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  const handleEditArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      name: areaName,
      areaId: editArea?._id,
    };
    const res = await ApiCall("post", AreaRoutes.editArea, data);
    if (res?.success) {
      toast.success("Area Edited Successfully!");
      setisEditArea(false);
      handleReset();
      handleGetAllArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  return (
    <Modal open={addArea || isEditArea}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          minWidth: "300px",
          maxWidth: "29vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {isEditArea ? "Edit" : "Add"} Area
          </Typography>
          <IconButton
            onClick={() => {
              if (isEditArea) {
                setisEditArea(false);
                setloader(false);
                seterrorMsg("");
              } else {
                handleReset();
              }
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Area Name
          </Typography>
          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 4,
              outline: 0,
              marginTop: 6,
            }}
            placeholder="Enter Area"
            value={areaName}
            onChange={(event) => setAreaName(event.target.value)}
          />
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "150px",
              backgroundColor: loader
              ? "#7D858D"
              : "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            disabled={loader}
            onClick={() => {
              if (isEditArea && !loader) {
                handleEditArea();
              } else if (!loader){
                handleAddArea();
              } else {
                //Nothing happened
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {isEditArea ? (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ExploreIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Edit Area" : `Editing...`}
                  </>
                ) : (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ExploreIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Add Area" : `Adding...`}
                  </>
                )}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AddArea;
