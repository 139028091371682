import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import SocietyRequest from "../../S2Admin/SocietyRequest/SocietyRequest";

const AdminSocietyRequests = {
  path: "/s2admin",
  element: <NavigationDrawer />,
  children: [
    {
      path: "societyrequest/admin",
      element: <SocietyRequest />,
    },
  ],
};
export default AdminSocietyRequests;
