import { Grid } from "@material-ui/core";
import { AddCircle, DeleteOutline } from "@material-ui/icons";
import { Typography } from "@mui/material";
import React from "react";

const AddMoreComplaintModal = (props) => {
  const {
    complainTitle,
    addMoreComplaint,
    property,
    floor,
    flat,
    handleRemoveComplaint,
    handleMultipleComplaint,
    addedComplaint,
    setaddSubMoreComplaintModal,
    loader
  } = props;


  return (
    <>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              marginTop: 8,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Complaint Title
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <input
            style={{
              borderRadius: 16,
              padding: 8,
              width: "98%",
              border: "1px solid #E1E1E1",
              marginBottom: 8,
              outline: 0,
            }}
            type="text"
            disabled={addMoreComplaint}
            placeholder="Enter Complaint Title"
            value={complainTitle}
            maxLength={150}
          />
        </Grid>
      </Grid>

      <Typography
        style={{
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          marginTop: 5,
          marginLeft: 5,
          marginBottom: 4,
        }}
      >
        Complaint Address :&nbsp;
        <span style={{ fontWeight: "bold" }}>
          {property?.name} - {floor?.name} - {flat?.name}&nbsp;
        </span>
      </Typography>
      <Grid
        container
        style={{ justifyContent: "space-evenly", marginTop: "10px" }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            color: "#283f59",
            fontSize: 14,
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          Total Added Complaints : {addedComplaint?.length}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            color: "#283f59",
            fontSize: 14,
            cursor: "pointer",
            fontWeight: "bold",
            marginLeft: 10,
          }}
          onClick={() => {
            setaddSubMoreComplaintModal(true);
          }}
        >
          <span style={{ textDecoration: "underline" }}>
            Add more complaint
          </span>
          <AddCircle
            style={{
              marginLeft: 2,
            }}
          />
        </Typography>
      </Grid>
      <Grid
        style={{
          minHeight: "300px",
          maxHeight: "480px",
          overflow: "auto",
        }}
      >
        {addedComplaint?.map((com, index) => {
          return (
            <Grid
              style={{
                border: "1px solid #c8c8c8",
                borderRadius: "12px",
                padding: "10px",
                marginTop: 10,
              }}
            >
              <Grid container style={{ justifyContent: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 15,
                    marginBottom: 5,
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Complaint {index + 1}
                  {index >= 1 && (
                    <DeleteOutline
                      onClick={() => {
                        handleRemoveComplaint(index);
                      }}
                      style={{
                        marginTop: -3,
                        marginLeft: 3,
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Typography>
              </Grid>

              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: 4,
                    marginLeft: 5,
                  }}
                >
                  Category :
                  <span style={{ fontWeight: "bold", color: "#283f59" }}>
                    &nbsp; {com?.category?.categoryName}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",

                    marginTop: 4,
                    marginLeft: 5,
                  }}
                >
                  Sub Category :
                  <span style={{ fontWeight: "bold", color: "#283f59" }}>
                    &nbsp;{com?.subcategory?.subcategoryName}
                  </span>
                </Typography>
              </Grid>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                  marginLeft: 5,
                  marginBottom: 4,
                }}
              >
                Complaint Description :
                <span style={{ fontWeight: "bold", color: "#283f59" }}>
                  &nbsp; {com?.desc}
                </span>
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
        <div
          class="card"
          style={{
            height: 35,
            boxShadow: "0 3px 6px #7D858D",
            width: "150px",
            backgroundColor: "#2f45c5",
            color: "#ffffff",
            cursor: "pointer",
            marginLeft: "12px",
          }}
          onClick={() => {
            if (!loader) {
              handleMultipleComplaint();
            } else {
              //NOTHING HAPPENED
            }
          }}
          disabled={loader}
        >
          <div class="card-body ">
            <p
              class="card-text"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginTop: "-10px",
              }}
            >
              <>{!loader ? "Submit complaint" : `Submiting...`}</>
            </p>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default AddMoreComplaintModal;
