import React from "react";
import {
  Typography,
  Grid,
  Paper,
Button
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "./style";
import { LoadingOutlined } from "@ant-design/icons";
import { QuestionMark } from "@mui/icons-material";

function RemoveResidentModal(props) {
  const classes = useStyles();
  const {
    removeResident,
    handleCheckUser,
    password,
    setpassword,
    setremoveResident,
    seterrorMsg,
    loader3,
    errorMsg
  } = props;
  
  return (
    <div>
      <Modal open={removeResident}>
      <Paper
        style={{ backgroundColor: "#e6e7f0" }}
        className={classes.confirmModal}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          ARE YOU SURE YOU WANT TO REMOVE THIS RESIDENT ?
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          A confirmation message will appear when this process is complete.You
          can confirm by clicking below.
        </Typography>

        <Grid container style={{ justifyContent: "center", marginTop: "7px" }}>
          <input
            onChange={(event) => {
              setpassword(event.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleCheckUser();
              }
            }}
            type="password"
            placeholder="Enter your admin password here"
            style={{
              padding: 10,
              border: "1px solid black",
              width: "300px",
              height: "50px",
              borderRadius: 6,
            }}
            value={password}
          />
        </Grid>
        {loader3 && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        {errorMsg && (
          <Typography
            style={{
              color: "#C60C30",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
              fontWeight: "bold",
            }}
          >
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setpassword("");
              seterrorMsg("");
              setremoveResident(false);
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleCheckUser();
            }}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CONFIRM
          </Button>
        </Grid>
      </Paper>
    </Modal>
    </div>
  );
}

export default RemoveResidentModal;
