import React from "react";
import { useNavigate } from "react-router";
import { navigateToComplaintTableRoute } from "../../Navigation/Navigations";
import { Card, Grid, Typography } from "@material-ui/core";

function HeplDeskComplaintTypeCard({ handleFilterComplaint, complaintCount }) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      style={{
        paddingTop: 30,
        gridGap: "60px",
        paddingBottom: 30,
      }}
    >
      <div
        className="pointer me-2"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(navigateToComplaintTableRoute({ complaintType: "all" }));
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #0204AA",
            borderRadius: "8px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
            }}
          >
            All Complaints
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#0204AA"
              />
            </svg>

            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.all?.length > 8
                ? complaintCount?.all.slice(0, 8).concat("..")
                : complaintCount?.all}
            </span>
          </span>
        </div>
      </div>

      <div
        className="pointer"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(
            navigateToComplaintTableRoute({
              complaintType: "unassigned",
            })
          );
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #EE383D",
            borderRadius: "8px",
            width: "145px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
            }}
          >
            Unassigned
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#EE383D"
              />
            </svg>
            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.unassigned?.length > 8
                ? complaintCount?.unassigned.slice(0, 8).concat("..")
                : complaintCount?.unassigned}
            </span>
          </span>
        </div>
      </div>

      <div
        className="pointer"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(
            navigateToComplaintTableRoute({
              complaintType: "assigned",
            })
          );
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #EBBB0E",
            borderRadius: "8px",
            width: "145px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Assigned
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#EBBB0E"
              />
            </svg>
            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.assigned?.length > 8
                ? complaintCount?.assigned.slice(0, 8).concat("..")
                : complaintCount?.assigned}
            </span>
          </span>
        </div>
      </div>

      <div
        className="pointer"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(
            navigateToComplaintTableRoute({
              complaintType: "resolved",
            })
          );
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #04D000",
            borderRadius: "8px",
            width: "145px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Resolved
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#04D000"
              />
            </svg>
            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.resolved?.length > 8
                ? complaintCount?.resolved.slice(0, 8).concat("..")
                : complaintCount?.resolved}
            </span>
          </span>
        </div>
      </div>

      <div
        className="pointer"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(navigateToComplaintTableRoute({ complaintType: "closed" }));
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #9747FF",
            borderRadius: "8px",
            width: "145px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Closed
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#9747FF"
              />
            </svg>
            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.closed?.length > 8
                ? complaintCount?.closed.slice(0, 8).concat("..")
                : complaintCount?.closed}
            </span>
          </span>
        </div>
      </div>

      <div
        className="pointer"
        style={{ position: "relative" }}
        onClick={() => {
          handleFilterComplaint();
          navigate(navigateToComplaintTableRoute({ complaintType: "cancel" }));
        }}
      >
        <Card
          style={{
            padding: "12px",
            border: "2px solid #23779A",
            borderRadius: "8px",
            width: "145px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Cancel
          </Typography>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span style={{ position: "relative" }}>
            <svg
              width="71"
              height="40"
              viewBox="0 0 71 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78437 13.6786C-2.28527 8.64786 1.27729 0 8.41939 0H50.5497C52.6607 0 54.6862 0.834419 56.1847 2.3214L68.2775 14.3214C71.4308 17.4505 71.4308 22.5495 68.2775 25.6786L56.1847 37.6786C54.6862 39.1656 52.6607 40 50.5497 40H8.4194C1.27729 40 -2.28528 31.3521 2.78436 26.3214L3.43213 25.6786C6.58543 22.5495 6.58543 17.4505 3.43214 14.3214L2.78437 13.6786Z"
                fill="#23779A"
              />
            </svg>
            <span
              className="fs-sm"
              style={{
                position: "absolute",
                top: "60%",
                left: "51%",
                width: "60px",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontWeight: 600,
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              {complaintCount?.cancel?.length > 8
                ? complaintCount?.cancel.slice(0, 8).concat("..")
                : complaintCount?.cancel}
            </span>
          </span>
        </div>
      </div>
    </Grid>
  );
}

export default HeplDeskComplaintTypeCard;
