import React from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Card,
  Divider,
  CardMedia,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import useStyles from "../styles";

function VisitorIdShowModal(props) {
  const classes = useStyles();
  const { idModal, setIdModal, accomapningVisitorData } = props;
  const cardStyle = {
    display: "block",
    height: "10vw",
    borderRadius: "20px",
  };

  return (
    <div>
      <Modal open={idModal}>
        <Paper
          style={{
            backgroundColor: "#e8f0fa",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            height: "500",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
            padding: 15,
            outline: 0,
            width: "45vw",
          }}
          className={classes.imageStyle}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: 400,
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              ID Details
            </Typography>
            <IconButton
              onClick={() => {
                setIdModal(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Card sx={cardStyle}>
            <CardMedia
              component="img"
              width="100%"
              height={600}
                image={accomapningVisitorData}
              style={{ objectFit: "contain", cursor: "pointer" }}
              alt="id"
            />
          </Card>
        </Paper>
      </Modal>
    </div>
  );
}

export default VisitorIdShowModal;
