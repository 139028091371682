import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Grid, Typography } from "@material-ui/core";
import { navigateToAdvanceVendorSetting } from "../../../Navigation/Navigations";
import { Apartment, ArrowLeft, Info, Search } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Divider } from "@mui/material";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { Circle } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import ChooseSectorDropDown from "../../../GlobalComponents/DropDowns/ChooseSectorDropDown";
import ShowPropertyModal from "./ShowPropertyModal";
import ChooseSec from "./ChooseSec";

const ViewPrevSettings = () => {
  const classes = useStyles();
  const { id, categoryName, sectorName } = useParams();
  const navigate = useNavigate();
  const [submitLoader, setsubmitLoader] = useState(false);
  const [sector, setsector] = useState("Select Sector");
  const [isShowProperty, setisShowProperty] = useState(false);
  const [ExpProp, setExpProp] = useState(null);
  const [isSectorConfigured, setisSectorConfigured] = useState();

  const isSectorSelected = () => {
    return !sector?.includes("Sector");
  };

  useEffect(() => {
    if (sectorName) {
      setsector(sectorName);
    }
  }, [sectorName]);

  const [allData, setallData] = useState(null);

  //FINAL API
  const getVendorConf = async () => {
    setsubmitLoader(true);
    setTimeout(() => {
      setsubmitLoader(false);
    }, 40000);
    try {
      let sendData = {
        filters: {
        sector,
          categoryId: id,
          societyId: fetchSocietyId(),
        },
      };
      const res = await api.getPrevvendorConfiguration(sendData);
      if (res?.data?.success) {
        setallData(res?.data?.vendor);
        setsubmitLoader(false);
      }
    } catch (error) {
      setsubmitLoader(false);
      resolveErrorMessage(error);
    }
  };

  
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body">
              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Category
                  <span style={{ color: "#000" }}>{` > ${categoryName} `}</span>
                  <span
                    style={{ color: "#000" }}
                  >{` > Previous Vendor Settings `}</span>
                </Typography>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(
                        navigateToAdvanceVendorSetting({
                          categoryId: id,
                          categoryName: categoryName,
                        })
                      );
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <ArrowLeft />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "20px",
                }}
              >
                <Grid container item xs={12} style={{ alignItems: "center" }}>
                  <Typography variant="h6" style={{ marginRight: "8px" }}>
                    Choose Sector :
                  </Typography>
                  <Typography style={{ width: "300px" }}>
                    <ChooseSec
                      sector={sector}
                      setsector={setsector}
                      isLocal={false}
                      setisSectorConfigured={setisSectorConfigured}
                    />
                  </Typography>
                  <button
                    onClick={getVendorConf}
                    disabled={!isSectorSelected() || submitLoader}
                    className={classes.selectPropButton}
                    style={{
                      borderRadius: "10px",
                      backgroundColor:
                        (!isSectorSelected() || submitLoader) && "#c8c8c8",
                    }}
                  >
                    <Search />
                    &nbsp; Find Configuration
                  </button>
                </Grid>
                <Grid
                  container
                  style={{
                    justifyContent: "flex-end",
                  }}
                >
                  {" "}
                  &nbsp;
                </Grid>
              </Grid>
              <Divider
                style={{
                  marginTop: "15px",
                  backgroundColor: "#6c757d",
                }}
              />
              {isSectorSelected() &&
                !submitLoader &&
                allData?.result?.length > 0 && (
                  <>
                    {allData?.result[0]?.configuration?.map((obj, index) => {
                      return (
                        <Grid key={index}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 22,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              marginLeft: 15,
                              marginTop: "12px",
                            }}
                          >
                            <Circle style={{ marginRight: 4, fontSize: 14 }} />
                            {obj?.day}
                          </Typography>
                          <TableContainer
                            component={Paper}
                            className={classes.tableContainer}
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.tableHeaderCell}
                                    sx={{
                                      color: "white",
                                      fontFamily: "Poppins, sans-serif",
                                    }}
                                  >
                                    Vendor Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableHeaderCell}
                                    sx={{
                                      color: "white",
                                      fontFamily: "Poppins, sans-serif",
                                    }}
                                  >
                                    Selected Properties
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {obj?.vendors?.map((dt, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "19px",
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                          }}
                                        >
                                          {" "}
                                          {dt?.vendor?.name}
                                        </p>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "black",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "19px",
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                          }}
                                        >
                                          {allData?.allPropsCount -
                                            dt?.properties?.length || 0} / {allData?.allPropsCount}
                                          &nbsp;
                                          <Info
                                            onClick={() => {
                                              setExpProp(
                                                dt?.properties
                                                  ? dt?.properties
                                                  : null
                                              );
                                              setisShowProperty(true);
                                            }}
                                            style={{ marginTop: "-4px" }}
                                            className={classes.addCircleButton}
                                          />
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      );
                    })}
                  </>
                )}
              {!isSectorSelected() && !submitLoader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "rgb(239 13 39)",
                      margin: "20px",
                    }}
                  >
                    Please Select a sector for configuration information
                  </Typography>
                </Grid>
              )}
              {submitLoader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#2f45c5",
                      margin: "20px",
                    }}
                  >
                    Loading configuration information.....
                  </Typography>
                </Grid>
              )}
              {isSectorSelected() &&
                !submitLoader &&
                allData?.result?.length === 0 && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#2f45c5",
                        margin: "20px",
                      }}
                    >
                      No Configuration Found for {sector}
                    </Typography>
                  </Grid>
                )}
            </div>
          </div>
        </Grid>
        <ShowPropertyModal
          setisShowProperty={setisShowProperty}
          isShowProperty={isShowProperty}
          sector={sector}
          expProp={ExpProp}
          dayWiseAllData={[]}
          currentIndexForSubCount={0}
          isView={true}
        />
      </main>
    </div>
  );
};

export default ViewPrevSettings;
