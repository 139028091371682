import React from "react";
import { Grid, Paper, IconButton, Divider } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import useStyles from "../ComplaintCategory/styles";

const CloseReasonModal = ({
  actionTypeModal,
  setActionTypeModal,
  complaintData,
  setcomplaintData,
}) => {
  const classes = useStyles();

  return (
    <Modal open={actionTypeModal}>
      <Paper
        className={classes.ModalView}
        style={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          className="justify-content-between align-items-center"
        >
          <h4 className="mb-0 ms-3">Close reason</h4>
          <IconButton
            onClick={() => {
              setcomplaintData("");
              setActionTypeModal(false);
            }}
          >
            <Close />
          </IconButton>
        </Grid>
        <Divider />
        <Grid container item>
          <div class="p-3">
            <span>{complaintData}</span>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default CloseReasonModal;
