import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import {
  navigateExistingToPrevVendorSetting,
  navigateToComplaintCategory,
  navigateToPrevVendorSetting,
} from "../../../Navigation/Navigations";
import {
  AddCircle,
  Apartment,
  ArrowLeft,
  Info,
  Visibility,
} from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Divider } from "@mui/material";
import SelectVendor from "./SelectVendor";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import SelectPropertyModal from "./SelectPropertyModal";
import { CheckCircle, QuestionMark } from "@mui/icons-material";
import ShowPropertyModal from "./ShowPropertyModal";
import { toast } from "react-toastify";
import Loading from "../../../contextAPI/Loading";
import ChooseSec from "./ChooseSec";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";

function AdvanceVendorSetting() {
  const classes = useStyles();
  const { id, categoryName } = useParams();
  const navigate = useNavigate();
  const [loaderGetVendor, setloaderGetVendor] = useState(false);
  const [submitLoader, setsubmitLoader] = useState(false);

  //Property Modal
  const [openPropertyModal, setopenPropertyModal] = useState(false);
  const [allPropertyCount, setallPropertyCount] = useState();
  const [isShowProperty, setisShowProperty] = useState(false);

  //SELECT SECTOR
  const [sector, setsector] = useState("Select Sector");
  const [isSectorSelected, setisSectorSelected] = useState(false);

  //day
  const day = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [dayWiseSelection, setdayWiseSelection] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const [selectedDay, setselectedDay] = useState("");
  const [currentIndexForSubCount, setcurrentIndexForSubCount] = useState(0);
  //VENDOR STATE
  const [AllVendor, setAllVendor] = useState(null);
  const [vendor, setvendor] = useState("Select Vendor");
  const [isSectorConfigured, setisSectorConfigured] = useState();

  //VENDOR API
  const handleGetVendor = async () => {
    setloaderGetVendor(true);
    setTimeout(() => {
      setloaderGetVendor(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 500,
        societyId: fetchSocietyId(),
        pageNumber: 1,
        searchKey: "",
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setAllVendor(res?.data?.vendor?.vendor);
        setloaderGetVendor(false);
      }
    } catch (error) {
      setloaderGetVendor(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetVendor();
  }, []);

  // DAY WISE STATE MANAGEMENT
  const [dayWiseAllData, setdayWiseAllData] = useState([]);

  //DYNAMIC COUNT
  const [dynamicCount, setdynamicCount] = useState({
    Monday: 1,
    Tuesday: 1,
    Wednesday: 1,
    Thursday: 1,
    Friday: 1,
    Saturday: 1,
    Sunday: 1,
  });

  const handleAddDynamicCount = (day) => {
    let arr = { ...dynamicCount };
    arr[day] = arr[day] + 1;
    setdynamicCount({ ...arr });
  };

  const handleDayWiseSelection = (day) => {
    let arr = { ...dayWiseSelection };
    arr[day] = true;
    setdayWiseSelection({ ...arr });
  };
  const getDynamicCount = (day) => {
    return dynamicCount[day];
  };

  const checkIsVendorSelected = (index, day) => {
    if (dayWiseAllData?.length > 0) {
      let newArr = dayWiseAllData?.filter((obj) => obj.day === day);
      if (newArr?.length > 0 && newArr[index]?.vendor !== "Select Vendor") {
        return newArr[index]?.vendor === "" ||
          newArr[index]?.vendor === undefined
          ? true
          : false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const checkIsPropertySelected = (index, day) => {
    if (dayWiseAllData?.length > 0) {
      let newArr = dayWiseAllData?.filter((obj) => obj.day === day);
      if (newArr?.length > 0) {
        return newArr[index]?.properties?.length > 0 ? true : false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkIsAddVisible = (index, day) => {
    if (dayWiseAllData?.length > 0) {
      let newArr = dayWiseAllData?.filter((obj) => obj.day === day);
      if (newArr?.length > 0 && newArr[index]?.isException) {
        return newArr[index]?.properties?.length > 0 ? true : false;
      } else if (newArr?.length > 0 && !newArr[index]?.isException) {
        return newArr[index]?.exceptionproperties?.length > 0 ? true : false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleCheckDayPropertyCount = (index, day) => {
    const newArr = dayWiseAllData?.filter((obj) => obj.day === day) || [];
    let arr = 0;
    if (newArr?.length > 0) {
      arr = newArr[index].selectedCount
        ? newArr[index].selectedCount
        : newArr[index].properties?.length;
    }

    return arr;
  };

  const usedVendorDayWise = (day, vendorId) => {
    const newArr = dayWiseAllData?.filter((obj) => obj.day === day) || [];
    let temp = [];
    newArr?.map((dt) => {
      temp.push(dt?.vendor);
    });
    return temp?.includes(vendorId) ? true : false || false;
  };

  const NextButtonCheck = (day) => {
    const newArr = dayWiseAllData?.filter((obj) => obj.day === day) || [];
    let isEnable = false;
    if (newArr?.length > 0) {
      if (newArr?.length === 1 && !newArr[0]?.properties) {
        isEnable =
          !newArr[0]?.vendor?.includes("Select") &&
          newArr[0]?.vendor !== undefined
            ? true
            : false;
      } else {
        const count = newArr.reduce((accumulator, dt) => {
          if (
            !dt?.vendor?.includes("Select") &&
            newArr[0]?.vendor !== undefined
          ) {
            const value = dt?.selectedCount || dt?.properties?.length || 0;
            return accumulator + value;
          } else {
            return 0;
          }
        }, 0);
        isEnable = allPropertyCount === count;
      }
    }
    return isEnable;
  };

  const getDayWiseCount = (day) => {
    const newArr = dayWiseAllData?.filter((obj) => obj.day === day) || [];
    let finalC = 0;
    if (newArr?.length === 1 && newArr[0]?.isException === undefined) {
      finalC = allPropertyCount;
    } else if (newArr?.length > 0 && newArr[0]?.isException !== undefined) {
      const count = newArr.reduce((accumulator, dt) => {
        const value = dt?.selectedCount || dt?.properties?.length || 0;
        return accumulator + value;
      }, 0);
      finalC = count;
    }
    return finalC;
  };

  //FINAL API
  const finalSubmit = async () => {
    setsubmitLoader(true);
    setTimeout(() => {
      setsubmitLoader(false);
    }, 40000);
    try {
      let sendData = {
        categoryId: id,
        societyId: fetchSocietyId(),
        subCategoryIds: [],
        isAllSubCategory: true,
        vendorArray: dayWiseAllData,
        sector,
      };
      const res = await api.vendorConfiguration(sendData);
      if (res?.data?.success) {
        resetAll();
        toast.success("Vendor Configuration Changed Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setsubmitLoader(false);
      }
    } catch (error) {
      setsubmitLoader(false);
      resolveErrorMessage(error);
    }
  };

  const resetAll = () => {
    setdayWiseAllData([]);
    setsector("Select Sector");
    setisSectorSelected(false);
    setdayWiseSelection({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    });
    setdynamicCount({
      Monday: 1,
      Tuesday: 1,
      Wednesday: 1,
      Thursday: 1,
      Friday: 1,
      Saturday: 1,
      Sunday: 1,
    });
  };

  const isCheckForFirstIndex = (day) => {
    let isChecked = false;
    const filteredArr = dayWiseAllData.filter((obj) => obj.day === day);
    if (
      !filteredArr[0]?.vendor?.includes("Select") &&
      (filteredArr[0]?.properties?.length === 0 || !filteredArr[0]?.properties)
    ) {
      isChecked = true;
    }
    return isChecked;
  };
  const isDayWiseExistData = (day) => {
    const filteredArr = dayWiseAllData.filter((obj) => obj.day === day);
    return filteredArr?.length > 0 ? true : false;
  };

  const checkIsDisabledFinalButton = () => {
    for (let day in dayWiseSelection) {
      if (dayWiseSelection[day] === false) {
        return true;
      }
    }
    return false;
  };
  return (
    <div
      className={classes.root}
      style={{
        filter: submitLoader ? "blur(1px)" : "",
        pointerEvents: submitLoader ? "none" : "",
        overflow: submitLoader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body">
              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Category
                  <span style={{ color: "#000" }}>{` > ${categoryName} `}</span>
                  <span
                    style={{ color: "#000" }}
                  >{` > Advance Vendor Settings `}</span>
                </Typography>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "190px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(
                        navigateToPrevVendorSetting({
                          categoryId: id,
                          categoryName: categoryName,
                        })
                      );
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <Visibility />
                        &nbsp; Existing Settings
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(navigateToComplaintCategory({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <ArrowLeft />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "20px",
                }}
              >
                {!isSectorSelected && (
                  <Grid container item xs={6} style={{ alignItems: "center" }}>
                    <Typography variant="h6" style={{ marginRight: "8px" }}>
                      Sector :
                    </Typography>
                    <span className="col-9">
                      <ChooseSec
                        sector={sector}
                        setsector={setsector}
                        setisSectorConfigured={setisSectorConfigured}
                      />
                    </span>
                  </Grid>
                )}
                {!isSectorSelected && (
                  <Grid
                    container
                    style={{
                      justifyContent: "flex-start",
                      marginLeft: "85px",
                    }}
                  >
                    <button
                      disabled={sector?.includes("Select") === "Select Sector"}
                      onClick={() => {
                        setisSectorSelected(true);
                      }}
                      className={classes.selectPropButton}
                      style={{
                        backgroundColor:
                          sector?.includes("Select") && "#c8c8c8",
                        marginLeft: "20px",
                      }}
                    >
                      Save and Continue
                    </button>{" "}
                    &nbsp;
                  </Grid>
                )}
                {isSectorSelected && isSectorConfigured?.isAdded && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <Apartment style={{ color: "#6c757d" }} /> &nbsp; Selected
                      Sector :{" "}
                      <span style={{ fontSize: "18px" }}> {sector}&nbsp;</span>
                      <span style={{ color: "rgb(36 202 38)" }}>
                        <CheckCircle style={{ color: "rgb(36 202 38)" }} />
                        &nbsp; Already Configured
                      </span>
                      &nbsp;
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {isSectorSelected && isSectorConfigured?.isAdded && (
                <Typography
                  style={{
                    margin: "10px",
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    textAlign: "center",
                  }}
                >
                  Note : If you wish to make any changes or overrides to
                  the&nbsp;
                  <span
                    onClick={() => {
                      navigate(
                        navigateExistingToPrevVendorSetting({
                          categoryId: id,
                          categoryName: categoryName,
                          sectorName: sector,
                        })
                      );
                    }}
                    style={{
                      color: "#2f45c5",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    existing settings
                  </span>{" "}
                  ,kindly fill up the below details again.
                </Typography>
              )}
              {isSectorSelected && (
                <Grid container style={{ justifyContent: "center" }}>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      resetAll();
                      setisSectorSelected(false);
                    }}
                  >
                    Re-Enter All
                  </button>
                </Grid>
              )}

              <Divider
                style={{
                  marginTop: "15px",
                  backgroundColor: "#6c757d",
                }}
              />

              <Grid
                style={{
                  filter: !isSectorSelected ? "blur(1px)" : "",
                  pointerEvents: !isSectorSelected ? "none" : "",
                }}
              >
                {day.map((dt, index) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          textAlign: "center",
                          marginBottom: "10px",
                          marginTop: "12px",
                        }}
                      >
                        Vendor Configuration for {dt}&nbsp;{" "}
                        {isCheckForFirstIndex(dt) && (
                          <span
                            style={{ marginLeft: "10px", color: "#2f45c5" }}
                          >
                            <Apartment />
                            &nbsp; {handlePropertyName()} Selection : Default
                            All
                          </span>
                        )}
                        {!isCheckForFirstIndex(dt) && allPropertyCount > 0 && (
                          <span
                            style={{ marginLeft: "10px", color: "#2f45c5" }}
                          >
                            <Apartment />
                            &nbsp; {handlePropertyName()} Selection :{" "}
                            {getDayWiseCount(dt)} / {allPropertyCount}
                          </span>
                        )}
                      </Typography>

                      <Grid container>
                        {Array(getDynamicCount(dt))
                          .fill(1)
                          ?.map((obj, ind) => {
                            return (
                              <Grid style={{ marginRight: "15px" }}>
                                <SelectVendor
                                  width="270px"
                                  AllVendor={AllVendor}
                                  day={dt}
                                  loaderGetVendor={loaderGetVendor}
                                  setdayWiseAllData={setdayWiseAllData}
                                  dayWiseAllData={dayWiseAllData}
                                  setvendor={setvendor}
                                  vendor={vendor}
                                  dynamicCount={dynamicCount}
                                  dynamicCountIndex={ind}
                                  getDynamicCount={getDynamicCount}
                                  usedVendorDayWise={usedVendorDayWise}
                                  dayWiseSelection={dayWiseSelection}
                                />
                                <Grid
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {ind + 1 === getDynamicCount(dt) &&
                                  !checkIsPropertySelected(ind, dt) ? (
                                    <Typography
                                      style={{
                                        color:
                                          checkIsVendorSelected(ind, dt) ||
                                          ind + 1 !== getDynamicCount(dt)
                                            ? "#c8c8c8"
                                            : "#2f45c5",
                                        marginRight: "10px",
                                        fontFamily: "Montserrat, sans-serif",
                                        cursor:
                                          checkIsVendorSelected(ind, dt) ||
                                          ind + 1 !== getDynamicCount(dt)
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          checkIsVendorSelected(ind, dt) ||
                                          ind + 1 !== getDynamicCount(dt)
                                        ) {
                                          //NOTHING HAPPEND
                                        } else {
                                          setcurrentIndexForSubCount(ind);
                                          setselectedDay(dt);
                                          setopenPropertyModal(true);
                                        }
                                      }}
                                    >
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Select Exception {handlePropertyName()}
                                      </span>
                                      <QuestionMark
                                        style={{ color: "rgb(239 13 39)" }}
                                      />
                                      {isCheckForFirstIndex(dt) &&
                                        "Default :  all"}
                                    </Typography>
                                  ) : (
                                    <span
                                      style={{
                                        fontFamily: "Montserrat, sans-serif",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <CheckCircle
                                        style={{ color: "rgb(36 202 38)" }}
                                      />
                                      &nbsp; Selected :{" "}
                                      {handleCheckDayPropertyCount(ind, dt)}
                                      &nbsp;
                                      <Info
                                        onClick={() => {
                                          setcurrentIndexForSubCount(ind);
                                          setselectedDay(dt);
                                          setisShowProperty(true);
                                        }}
                                        style={{ marginTop: "-4px" }}
                                        className={classes.addCircleButton}
                                      />
                                      &nbsp;
                                    </span>
                                  )}
                                  &nbsp;
                                  {checkIsAddVisible(ind, dt) &&
                                    ind + 1 === getDynamicCount(dt) && (
                                      <AddCircle
                                        style={{ marginTop: "-4px" }}
                                        className={classes.addCircleButton}
                                        onClick={() =>
                                          handleAddDynamicCount(dt)
                                        }
                                      />
                                    )}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                      <Grid container style={{ justifyContent: "flex-end" }}>
                        <button
                          className="btn btn-danger"
                          disabled={!isDayWiseExistData(dt)}
                          onClick={() => {
                            dayWiseSelection[dt] = false;
                            const filteredArr = dayWiseAllData.filter(
                              (obj) => obj.day !== dt
                            );
                            dynamicCount[dt] = 1;
                            setdayWiseAllData([...filteredArr]);
                          }}
                        >
                          Reset
                        </button>
                        &nbsp;
                        <button
                          disabled={!NextButtonCheck(dt)}
                          onClick={() => {
                            if (!dayWiseSelection[dt]) {
                              handleDayWiseSelection(dt);
                            } else {
                              toast.success(`${dt} already saved`);
                            }
                          }}
                          className={
                            dayWiseSelection[dt]
                              ? "btn btn-success"
                              : "btn btn-primary"
                          }
                        >
                          {dayWiseSelection[dt] ? (
                            <>
                              <CheckCircle
                                style={{ color: "rgb(36 202 38)" }}
                              />{" "}
                              Saved
                            </>
                          ) : (
                            `Save ${dt}`
                          )}
                        </button>
                      </Grid>

                      <Divider
                        style={{
                          marginTop: "15px",
                          backgroundColor: "#6c757d",
                        }}
                      />
                    </>
                  );
                })}
              </Grid>
            </div>
            <Grid
              container
              style={{
                justifyContent: "flex-end",
                filter: !isSectorSelected ? "blur(1px)" : "",
                pointerEvents: !isSectorSelected ? "none" : "",
              }}
            >
              <button
                onClick={finalSubmit}
                style={{
                  backgroundColor:
                    checkIsDisabledFinalButton() || submitLoader
                      ? "#c8c8c8"
                      : "#2f45c5",
                  marginBottom: "25px",
                }}
                disabled={checkIsDisabledFinalButton() || submitLoader}
                className={classes.selectPropButton}
              >
                {submitLoader
                  ? "Loading..."
                  : `Save Final Configuration for Sector : ${sector}`}
              </button>{" "}
              &nbsp;
            </Grid>
          </div>
        </Grid>

        <SelectPropertyModal
          openPropertyModal={openPropertyModal}
          setopenPropertyModal={setopenPropertyModal}
          selectedDay={selectedDay}
          setdayWiseAllData={setdayWiseAllData}
          dayWiseAllData={dayWiseAllData}
          vendor={vendor}
          dynamicCount={dynamicCount}
          getDynamicCount={getDynamicCount}
          setallPropertyCount={setallPropertyCount}
          sector={sector}
          currentIndexForSubCount={currentIndexForSubCount}
          dayWiseSelection={dayWiseSelection}
        />
        <ShowPropertyModal
          setisShowProperty={setisShowProperty}
          isShowProperty={isShowProperty}
          sector={sector}
          selectedDay={selectedDay}
          dayWiseAllData={dayWiseAllData}
          getDynamicCount={getDynamicCount}
          currentIndexForSubCount={currentIndexForSubCount}
        />
        <Loading loader={submitLoader} />
      </main>
    </div>
  );
}

export default AdvanceVendorSetting;
