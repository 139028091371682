import React from "react";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { Circle } from "@mui/icons-material";
import { Timer } from "@material-ui/icons";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import useStyles from "../style";
import moment from "moment";
import { Key } from "@mui/icons-material";
import { AccountCircle } from "@material-ui/icons";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

function AdminDetailsCard(props) {
  const classes = useStyles();
  const { adminDetails } = props;
  return (
    <div>
      <Grid container xs={12} lg={12} md={12}>
        <Paper className={classes.detailscard}>
          <div
            style={{
              backgroundColor: "#2A265F",
              color: "#fff",
              padding: "30px",
              maxWidth: "500px",
              minWidth: "430px",
            }}
          >
            <Typography
              style={{
                fontSize: 15,
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                  border: "1px solid #e6e7f0",
                  height: 46,
                  width: 46,
                  borderRadius: 50,
                  marginLeft: 15,
                  marginRight: 15,
                }}
                src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                alt="Sociohood"
              />
            </Typography>
            <Typography
              style={{
                fontSize: 20,
                fontFamily: "Montserrat, sans-serif",
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 15,
              }}
            >
              &nbsp;{adminDetails?.name}
            </Typography>
            <Divider
              style={{
                marginBottom: 10,
                backgroundColor: "#FFFFFF",
                width: "100%",
                alignItems: "center",
                alignSelf: "center",
              }}
            />
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-5">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    <AlternateEmailIcon style={{ fontSize: "medium" }} />
                    &nbsp;Email :
                  </Typography>
                </div>
                <div className="col-md-7">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    {adminDetails?.email}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-5">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    <PhoneIcon style={{ fontSize: "medium" }} />
                    &nbsp;Phone :
                  </Typography>
                </div>
                <div className="col-md-7">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    {adminDetails?.phone}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-5">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    <Timer style={{ fontSize: "medium" }} />
                    &nbsp;Creation Time :
                  </Typography>
                </div>
                <div className="col-md-7">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    {moment(adminDetails?.createdAt).format(
                      "DD/MM/YYYY - h:mm A"
                    )}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
          <div
            style={{
              padding: "30px",
              position: "relative",
              maxWidth: "600px",
              minWidth: "460px",
            }}
          >
            <Grid container style={{ justifyContent: "space-between" }}>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#000",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: -4,
                }}
              >
                Other Information
              </Typography>
            </Grid>
            <Divider
              style={{
                marginBottom: 10,
                backgroundColor: "#6c757d",
                width: "100%",
                alignItems: "center",
                alignSelf: "center",
              }}
            />
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    1.&nbsp;
                    <Key style={{ fontSize: "18px" }} />
                    &nbsp;Unique ID :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontWeight: "bold",
                    }}
                  >
                    {adminDetails?.adminUniqueId}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    2.&nbsp;
                    <AccountCircle style={{ fontSize: "18px" }} />
                    &nbsp;Profile Type :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontWeight: "bold",
                    }}
                  >
                    {adminDetails?.profileType}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    3.&nbsp;
                    <GroupWorkIcon style={{ fontSize: "18px" }} />
                    &nbsp;Role :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {adminDetails?.role}
                  </Typography>
                </div>
              </div>
            </Grid>
            {/* <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    4.&nbsp;
                    <Circle style={{ fontSize: 15 }} />
                    &nbsp;Admin Level :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    2
                  </Typography>
                </div>
              </div>
            </Grid> */}
            <Grid item>
              <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    4.&nbsp;
                    <Circle style={{ fontSize: 15 }} />
                    &nbsp;Status :
                  </Typography>
                </div>
                <div className="col-md-6">
                  {adminDetails?.approvalStatus === "approved" && (
                    <Typography
                      style={{
                        color: "rgb(36 202 38)",
                        fontWeight: "bold",
                        fontSize: 12,
                        marginTop: 4,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      &nbsp;APPROVED
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </div>
  );
}

export default AdminDetailsCard;
