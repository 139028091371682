import React, { useCallback, useEffect, useState } from "react";
import useStyles from "../../styles";
import { Grid, Avatar, Typography, Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BASE_URL } from "../../../../utils/base";
import UserProfileDetailsModal from "../../../../GlobalComponents/UserProfileDetailsModal/UserProfileDetailsModal";
import { navigateToResidentFamilyMember } from "../../../../Navigation/Navigations";
import ChangeAddressModal from "../PendingRequests/ChangeAddressModal";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";

function ApprovedRequeststable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    allApprovalRequests,
    page,
    isReadWrite,
    getAllApprovedRequests,
    user,
    setaddManager,
    showCheckBox,
    checkedManager,
    setcheckedManager,
    selectAllChecked,
    setSelectAllChecked,
    setremoveManager,
    selectableHeader,
    setmanagerData,
    plant,
  } = props;
  const [selectedUser, setselectedUser] = useState([]);
  const [isShowUserProfileDetails, setisShowUserProfileDetails] =
    useState(false);
  const [changeAddressModalOpen, setchangeAddressModalOpen] = useState(false);
  const [changeAddressUser, setchangeAddressUser] = useState([]);
  const [oldFlatId, setoldFlatId] = useState(null);
  const [flatRole, setflatRole] = useState(null);

  const handleManagerCheckboxChange = (resident) => {
    const currentPageCheckedGroups = checkedManager[page] || [];
    const groupIndex = currentPageCheckedGroups?.indexOf(resident._id);

    if (groupIndex === -1) {
      const newCheckedManager = {
        ...checkedManager,
        [page]: [...currentPageCheckedGroups, resident?._id],
      };
      setcheckedManager(newCheckedManager);
    } else {
      const newCheckedManager = {
        ...checkedManager,
        [page]: [
          ...currentPageCheckedGroups?.slice(0, groupIndex),
          ...currentPageCheckedGroups?.slice(groupIndex + 1),
        ],
      };
      setcheckedManager(newCheckedManager);
    }
  };

  const handleSelectAllCheckboxChange = useCallback(() => {
    let newCheckedEmp = { ...checkedManager };
    let newSelectAllChecked = { ...selectAllChecked };

    if (newSelectAllChecked[page]) {
      newCheckedEmp[page] = [];
      newSelectAllChecked = { ...newSelectAllChecked, [page]: false };
    } else {
      newCheckedEmp[page] = allApprovalRequests
        ?.filter((grp) => !grp?.isManager)
        ?.map((grp) => grp?._id);
      newSelectAllChecked = { ...newSelectAllChecked, [page]: true };
    }

    setcheckedManager(newCheckedEmp);
    setSelectAllChecked(newSelectAllChecked);
  }, [
    checkedManager,
    selectAllChecked,
    allApprovalRequests,
    page,
    setcheckedManager,
    setSelectAllChecked,
  ]);

  useEffect(() => {
    const currentPageCheckedGroups = checkedManager[page] || [];
    const allGroupsOnPageChecked = allApprovalRequests
      .filter((grp) => !grp.isManager)
      .every((grp) => currentPageCheckedGroups.includes(grp._id));

    setSelectAllChecked((prevSelectAllChecked) => ({
      ...prevSelectAllChecked,
      [page]: allGroupsOnPageChecked,
    }));
  }, [page, allApprovalRequests, checkedManager, setSelectAllChecked]);
  const handleMakeManagerClick = (resident) => {
    const newCheckedManager = { ...checkedManager };
    newCheckedManager[page] = [resident._id];
    setcheckedManager(newCheckedManager);
  };

  const allUser =
    selectableHeader !== "Manager"
      ? allApprovalRequests
      : allApprovalRequests?.filter((resident) =>
          resident?.approvedRequests?.some(
            (data) =>
              data?.userRole === "manager" &&
              data?.societyId === fetchSocietyId()
          )
        );

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {/* {showCheckBox && (
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    className="form-check-input "
                    onChange={handleSelectAllCheckboxChange}
                    checked={selectAllChecked[page]}
                  />
                )} */}
                &nbsp;&nbsp; No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Phone
              </TableCell>
              {selectableHeader !== "Manager" && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Primary Address
                </TableCell>
              )}
              {isReadWrite && plant && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allUser?.map((resident, index) => {
              const filteredResidentAddress =
                resident?.approvedRequests?.filter?.(
                  (data) => data?.flatId?.society?._id === fetchSocietyId()
                );
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {/* {showCheckBox && (
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ cursor: "pointer" }}
                      value={resident?._id}
                      onChange={() => handleManagerCheckboxChange(resident)}
                      checked={(checkedManager[page] || [])?.includes(
                        resident?._id
                      )}
                    />
                  )}{" "} */}
                    {(page - 1) * 50 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setselectedUser(resident);
                      navigate(
                        navigateToResidentFamilyMember({
                          residentId: resident?._id,
                        })
                      );
                    }}
                  >
                    <Grid container style={{ justifyContent: "center" }}>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        alt={resident.name}
                        src={
                          resident.image
                            ? `${BASE_URL}/user/${resident.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        className={classes.avatar}
                      />
                    </Grid>
                    <Typography
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "capitalize",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {resident?.name}
                      <br />
                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        {resident?.familyMembers?.length > 0
                          ? resident?.familyMembers?.length + " Family Member"
                          : " "}
                      </span>
                      {resident?.managerSocieties?.some(
                        (data) =>
                          data?.isManager &&
                          data?.societyId === fetchSocietyId()
                      ) && (
                        <span
                          style={{
                            width: "58px",
                            padding: 5,
                            textTransform: "capitalize",
                            marginLeft: 5,
                          }}
                          class={"badge bg-primary"}
                        >
                          Manager
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {moment(resident?.actionTime).format("DD/MM/YYYY - h:mm A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    {resident?.email || resident?.email !== ""
                      ? resident?.email
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    {resident?.phone}
                  </TableCell>
                  {selectableHeader !== "Manager" && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "capitalize",
                      }}
                    >
                      {filteredResidentAddress?.length > 0 &&
                        filteredResidentAddress?.map((fr, index) => {
                          return (
                            <>
                              <Typography>
                                {index + 1}. {fr?.flatId?.property?.name} -{" "}
                                {fr?.flatId?.floor?.name} - {fr?.flatId?.number}
                                {fr?.flatId?._id === resident?.flat?._id &&
                                  ` (Switched)`}
                                <span
                                  style={{
                                    width: `${plant ? "68px" : "58px"}`,
                                    padding: 5,
                                    textTransform: "capitalize",
                                    marginLeft: 5,
                                  }}
                                  class={"badge bg-primary"}
                                >
                                  {plant ? "Employee" : fr?.userRole}
                                </span>
                                {isReadWrite && fr?.flatId?.property && (
                                  <Typography
                                    style={{
                                      color: "#2f45c5",
                                      fontFamily: "Poppins, sans-serif",
                                      textTransform: "capitalize",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      setflatRole(fr?.userRole);
                                      setoldFlatId(fr?.flatId);
                                      setchangeAddressUser(resident);
                                      setchangeAddressModalOpen(true);
                                    }}
                                  >
                                    Change
                                  </Typography>
                                )}
                              </Typography>
                            </>
                          );
                        })}
                      {filteredResidentAddress?.length === 0 && "-"}
                    </TableCell>
                  )}
                  {isReadWrite && plant && (
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                      className={`${
                        resident?.managerSocieties?.some(
                          (data) =>
                            data?.isManager &&
                            data?.societyId === fetchSocietyId()
                        )
                          ? "text-danger"
                          : "text-primary"
                      } fw-bold`}
                      onClick={() => {
                        if (
                          resident?.managerSocieties?.some(
                            (data) =>
                              data?.isManager &&
                              data?.societyId === fetchSocietyId()
                          )
                        ) {
                          setmanagerData(resident);
                          setremoveManager(true);
                        } else {
                          setmanagerData(resident);
                          setaddManager(true);
                        }
                      }}
                    >
                      {resident?.managerSocieties?.some(
                        (data) =>
                          data?.isManager &&
                          data?.societyId === fetchSocietyId()
                      )
                        ? "Remove Manager"
                        : "Make Manager"}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <UserProfileDetailsModal
        isOpen={isShowUserProfileDetails}
        setIsOpen={setisShowUserProfileDetails}
        user={selectedUser}
      />
      <ChangeAddressModal
        changeAddressModalOpen={changeAddressModalOpen}
        setchangeAddressModalOpen={setchangeAddressModalOpen}
        changeAddressUser={changeAddressUser}
        getAllPendingRequests={getAllApprovedRequests}
        user={user}
        type="Approved"
        userAddressDetails={oldFlatId}
        flatRole={flatRole}
      />
    </>
  );
}

export default ApprovedRequeststable;
