import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  MenuItem,
  CssBaseline,
  Select,
} from "@material-ui/core";
import useStyles from "./style";
import AllNotices from "./AllNotices";
import AddNotice from "./AddNotice/AddNotice";
import * as api from "../../api/index";
import { useEffect, useContext } from "react";
import "./style.css";
import { AuthContext } from "../../contextAPI/context";
import moment from "moment";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../services/service";
import NoticeTotalCards from "./NoticeTotalCards";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import Pagination from "@mui/material/Pagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";

function Notices(props) {
  const classes = useStyles();
  const [noticeType, setNoticeType] = useState("All");
  const [allNoticeType, setAllNoticeType] = useState([]);
  const [AddNoticeModal, setAddNoticeModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [property, setproperty] = useState("Select Property");
  const [floor, setfloor] = useState("Select Floor");
  const [flat, setflat] = useState("Select Flat");
  const { authState } = useContext(AuthContext);
  const { properties, usermoduleswithpermissions } = authState;
  const [allFloors, setallFloors] = useState([]);
  const [allflats, setallflats] = useState([]);
  const [getNotices, setgetNotices] = useState([]);
  const [pleasewait, setpleasewait] = useState(false);
  const [isPublished, setisPublished] = useState("all");
  const [noticeCount, setnoticeCount] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageLimit] = useState(9);
  const [isReadWrite, setIsReadWrite] = useState(false);
  var newDate = moment(new Date()).add(-3, "months").format("YYYY-MM-DD");
  var date = moment(new Date()).format("YYYY-MM-DD");

  const handleNoticeType = async () => {
    try {
      const res = await api.getNoticeType({
        params: { societyId: fetchSocietyId() },
      });
      if (res?.data?.success) {
        setAllNoticeType(res?.data?.noticeType);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleNoticeType();
    setisPublished("all");
  }, []);

  useEffect(() => {
    let temp = [];
    temp = properties?.filter((pro) => {
      return pro._id === property;
    });
    setallFloors(temp?.[0]?.floors);
  }, [property]);

  useEffect(() => {
    let temp = [];
    temp = allFloors?.filter((pro) => {
      return pro._id === floor;
    });
    setallflats(temp?.[0]?.flats);
  }, [floor]);

  const handlePageChange = async (event, value) => {
    setPage(value);
    localStorage.setItem("noticePageNumber", value);
  };

  const handleGetNotices = async (type) => {
    try {
      setpleasewait(true);
      setTimeout(() => {
        setpleasewait(false);
      }, 40000);
      let res = {};
      if (type === "all") {
        res = await api.getAllNotice({
          params: {
            startDate: startDate ? startDate : newDate,
            endDate: endDate ? endDate : date,
            type: noticeType == "All" ? null : noticeType,
            publishedStatus:
              isPublished === "published"
                ? "published"
                : isPublished === "unpublished"
                ? "unpublished"
                : isPublished === "all"
                ? ""
                : isPublished === "deleted"
                ? ""
                : isPublished,
            pageLimit: pageLimit,
            pageNumber: localStorage.getItem("noticePageNumber")
              ? localStorage.getItem("noticePageNumber")
              : 1,
            isDeleted: isPublished === "deleted" ? true : false,
            societyId: fetchSocietyId(),
          },
        });
      } else if (type === "reset") {
        setPage(1);
        setNoticeType("All");
        setisPublished("all");
        setproperty("Select Property");
        setfloor("Select Floor");
        setflat("Select Flat");
        localStorage.removeItem("noticePageNumber");
        setStartDate("");
        setEndDate("");
        res = await api.getAllNotice({
          params: {
            startDate: newDate,
            endDate: date,
            type: null,
            publishedStatus:
              isPublished === "published"
                ? "published"
                : isPublished === "unpublished"
                ? "unpublished"
                : isPublished === "all"
                ? ""
                : isPublished === "deleted"
                ? ""
                : isPublished,
            pageLimit: 10,
            pageNumber: page,
            isDeleted: isPublished === "deleted" ? true : false,
            societyId: fetchSocietyId(),
          },
        });
      }

      if (res?.data?.success) {
        setnoticeCount(res?.data?.notices);
        setgetNotices(res?.data?.notices?.notices);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetNotices("all");
  }, [page, noticeType, startDate, isPublished]);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Notice"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main
          className={classes.content}
          style={{
            filter: pleasewait ? "blur(1px)" : "",
            overflow: pleasewait ? "hidden" : "auto",
          }}
        >
          <div className={classes.toolbar} />
          <Grid container>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              style={{
                height: "100vh",
                pointerEvents: pleasewait ? "none" : "",
              }}
            >
              <Paper
                style={{
                  padding: 12,
                  boxShadow: "0px 0px 0px",
                  overflow: "visible",
                  minHeight: "100vh",
                }}
              >
                <Grid container className="justify-content-between">
                  <Grid
                    container
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    style={{ marginTop: 8 }}
                  >
                    <span className="page-header">
                      Notice Board&nbsp;
                      {!pleasewait ? (
                        <RefreshIcon
                          title="Refresh Notice Page"
                          onClick={() => {
                            handleGetNotices("reset");
                            setPage(1);
                          }}
                          className="pointer ms-1 text-bg-dark"
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                          }}
                        />
                      )}
                    </span>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    lg={10}
                    sm={10}
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: 12,
                      marginTop: -8,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        marginTop: 22,
                        marginRight: 8,
                      }}
                    >
                      Choose Notice Type :&nbsp;
                    </Typography>
                    <Select
                      value={noticeType}
                      onChange={(event) => {
                        setNoticeType(event.target.value);
                        setPage(1);
                      }}
                      style={{
                        color: "#212529",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        borderRadius: 6,
                        width: "130px",
                        padding: 8,
                        height: 40,
                        fontSize: "13px",
                        textTransform: "capitalize",
                        textAlign: "center",
                        border: "1px solid #6c757d",
                        marginTop: 10,
                        marginRight: 8,
                      }}
                    >
                      <MenuItem selected value="All">
                        All
                      </MenuItem>
                      {allNoticeType?.map((notice) => {
                        return (
                          <MenuItem
                            style={{ textTransform: "capitalize" }}
                            selected
                            value={notice?._id}
                          >
                            {notice?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 22,
                        marginRight: 8,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Start Date :
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 5,
                        width: 150,
                        height: 40,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      onChange={(event) => {
                        setPage(1);
                        setStartDate(event.target.value);
                      }}
                      placeholder="Choose Start Date"
                      value={startDate ? startDate : newDate}
                      max={endDate ? endDate : date}
                    />
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 22,
                        marginRight: 8,
                        marginLeft: 10,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      End Date :
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 5,
                        width: 150,
                        height: 40,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      disabled={!startDate}
                      onChange={(event) => {
                        setPage(1);
                        setEndDate(event.target.value);
                      }}
                      placeholder="Choose End Date"
                      value={endDate ? endDate : date}
                      max={date}
                      min={startDate}
                    />
                    <div
                      className="search-button mt-2"
                      onClick={() => {
                        setPage(1);
                        handleGetNotices("all");
                      }}
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="search-icon"
                        >
                          <path
                            d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {isReadWrite && (
                      <Grid
                        container
                        className="d-flex justify-content-end mt-2"
                      >
                        <div
                          className="btn btn-outline-custom"
                          style={{ width: "200px", borderRadius: "10px" }}
                          onClick={() => {
                            setAddNoticeModal(true);
                          }}
                        >
                          +&nbsp;Add Notice
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-3 d-flex gap-3 px-2">
                  <NoticeTotalCards
                    count={noticeCount}
                    isPublished={isPublished}
                    setisPublished={setisPublished}
                    setPage={setPage}
                  />
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  xs={12}
                  lg={12}
                  className="d-flex justify-content-center mt-3"
                >
                  <AllNotices getNotices={getNotices} pleasewait={pleasewait} />
                </Grid>
                {getNotices.length > 0 && (
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: 10 }}
                  >
                    <Pagination
                      className={classes.pagination}
                      count={noticeCount?.totalPage}
                      color="primary"
                      page={
                        parseInt(localStorage.getItem("noticePageNumber"))
                          ? parseInt(localStorage.getItem("noticePageNumber"))
                          : page
                      }
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
      {AddNoticeModal && (
        <AddNotice
          AddNoticeModal={AddNoticeModal}
          setAddNoticeModal={setAddNoticeModal}
          handleGetNotices={handleGetNotices}
          setPage={setPage}
          page={page}
        />
      )}

      {pleasewait && <BanterLoader />}
    </>
  );
}

export default Notices;
