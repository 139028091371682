import React from "react";
import { Typography, Paper, Grid, Modal, Button } from "@material-ui/core";
import { LoadingOutlined } from "@ant-design/icons";
import { QuestionMark } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";

function ConfirmModal(props) {
  const {
    title,
    description,
    loader,
    handleConfirmation,
    rejectConfirmation,
    isOpen,
    errorMsg,
  } = props;

  return (
    <Modal open={isOpen}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          {description}
        </Typography>
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        {errorMsg && (
          <Typography
            style={{
              marginTop: 8,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            disabled={loader}
            onClick={() => {
              if (!loader) {
                rejectConfirmation();
              }
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleConfirmation();
            }}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            {loader ? "Confirming.." : "CONFIRM"}
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}
export default ConfirmModal;
