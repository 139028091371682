import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getSocietySectors } from "../../../../GlobalFunctions/GlobalFunctions";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { Close } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const SectorModal = ({
  sectorModal,
  setSectorModal,
  setPermissionType,
  setSelectedSector,
  selectedSector,
  setSelectAll,
  selectAll,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allSector, setallSector] = useState([]);

  const handleGetSector = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    const res = await getSocietySectors({ societyId: fetchSocietyId() });

    setallSector(res?.sector);
    setLoading(false);
  };

  useEffect(() => {
    handleGetSector();
  }, []);

  const handleSelectAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedSector(isChecked ? allSector : []);
  };

  const handleSectorCheckboxChange = (sector) => {
    const isChecked = selectedSector.includes(sector);
    const newSelectedSectors = isChecked
      ? selectedSector.filter((s) => s !== sector)
      : [...selectedSector, sector];

    setSelectedSector(newSelectedSectors);
    setSelectAll(newSelectedSectors.length === allSector.length);
  };

  return (
    <Modal open={sectorModal}>
      <Paper
        style={{
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: selectedSector?.length > 0 ? "700px" : "300px",
          maxWidth: selectedSector?.length > 0 ? "75vw" : "35vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select Sector
          </Typography>
          <IconButton
            onClick={() => {
              setSectorModal(false);
              setPermissionType("Choose Type");
              setSelectedSector([]);
              setSelectAll(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          <Grid item xs={selectedSector?.length > 0 ? 8 : 12}>
            <TableContainer
              component={Paper}
              className="scrollable"
              style={{ overflow: "scroll", height: "60vh" }}
            >
              {!loading && (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          className="form-check-input "
                          onChange={handleSelectAllCheckboxChange}
                          checked={selectAll}
                        />
                        &nbsp; Sr. No.
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSector?.map((sec, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              className="form-check-input"
                              checked={selectedSector.includes(sec)}
                              onChange={() => handleSectorCheckboxChange(sec)}
                            />
                            &nbsp;&nbsp;
                            {index + 1} .
                          </TableCell>
                          <TableCell
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: "normal",
                            }}
                          >
                            {sec}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
              )}
            </TableContainer>
          </Grid>
          {selectedSector?.length > 0 && (
            <Grid item xs={4}>
              <Typography
                style={{
                  color: "#6c757d",
                }}
                className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
              >
                Selected Sector : {selectedSector?.length}
              </Typography>
              <div
                className="d-flex flex-column gap-3 overflow-auto scrollable p-3"
                style={{ height: "calc(60vh - 40px)" }}
              >
                {selectedSector?.map((sec, index) => {
                  return (
                    <p
                      className="mb-0 text-dark"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {index + 1}. {sec}
                    </p>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor:
                selectedSector?.length > 0 ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (selectedSector?.length > 0) {
                setSectorModal(false);
              } else {
                toast.error("Please Select Sector");
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SectorModal;
