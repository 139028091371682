import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "../style";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import Loading from "../../../contextAPI/Loading";
import DailyHelpDetailsTable from "./DailyHelpDetailsTable";
import { useNavigate, useParams } from "react-router-dom";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import { resolveErrorMessage } from "../../../services/service";
import { Pagination } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { navigateToDailyHelp } from "../../../Navigation/Navigations";

function DailyHelpDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { staffType } = useParams();
  const [staffTypeDetails, setStaffTypeDetails] = useState([]);
  const [search, setsearch] = useState("");
  const [pageLimit, setpageLimit] = useState("50");
  const [count, setcount] = useState(0);
  const [page, setPage] = useState(1);
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleViewStaffs = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const res = await api.staffManagement({
        params: {
          societyId: fetchSocietyId(),
          searchKey: search,
          pageLimit,
          pageNumber: page,
          staffType: staffType,
        },
      });
      if (res?.data?.success) {
        setStaffTypeDetails(res?.data?.Staff?.staff);
        setcount(res?.data?.Staff?.totalPage);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleViewStaffs();
  }, [search, page]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={classes.content}
        style={{
          filter: loader ? "blur(1px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
        }}
      >
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={5}
                  lg={5}
                  sm={5}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    {`Daily Help Details > `}
                    <span style={{ color: "#000" }}>{staffType}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Staff Page"
                        onClick={handleViewStaffs}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  lg={7}
                  sm={7}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search..."
                      onChange={(e) => {
                        setsearch(e.target.value);
                        setPage(1);
                      }}
                      value={search}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginTop: 7,
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      navigate(navigateToDailyHelp({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        BACK
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <DailyHelpDetailsTable
                  staffTypeDetails={staffTypeDetails}
                  loader={loader}
                  page={page}
                  pageLimit={pageLimit}
                />
              </Grid>
              {staffTypeDetails?.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default DailyHelpDetails;
