import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Group from "../../Components/People/Group/Group";
import GroupDetails from "../../Components/People/Group/GroupDetails/GroupDetails";

const GroupRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <Group />,
    },
    {
      path: "group",
      element: <Group />,
    },
    {
      path: "groupdetails/:id",
      element: <GroupDetails />,
    },
  ],
};
export default GroupRoutes;
