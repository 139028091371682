import React, { useContext, useEffect, useState } from "react";
import useStyles from "../style";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Close } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { Switch } from "antd";
import { resolveErrorMessage } from "../../services/service";
import * as api from "../../api/index";
import { AuthContext } from "../../contextAPI/context";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import ConfirmSwitchModal from "./ConfirmSwitchModal";
import { Divider } from "@mui/material";
import { handlePropertyName } from "../../contextAPI/NamingConvention";

function Configuration() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [isEdit, setIsEdit] = useState(false);
  const [isEdit2, setIsEdit2] = useState(false);
  const [isEdit3, setIsEdit3] = useState(false);
  const [editPropertyName, setEditPropertyName] = useState("");
  const [editFloorName, setEditFloorName] = useState("");
  const [editFlatName, setEditFlatName] = useState("");
  const [checked, setChecked] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [password, setpassword] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const [allSociety, setAllSociety] = useState([]);
  const [allAdditionalContacts, setallAdditionalContacts] = useState([]);
  const [enableReopenComplaint, setenableReopenComplaint] = useState(false);
  const [enableVisitorId, setenableVisitorId] = useState(false);

  // const handleChange = (checked) => {
  //   setChecked(checked);
  //   if (checked) {
  //     setConfirmModal(true);
  //   }
  // };

  const handleChange2 = (event) => {
    if (enableVisitorId !== event) {
      setenableVisitorId(!enableVisitorId);
    } else {
      toast.error("Visitor Id is Enable");
    }
  };

  const handleChange3 = (event) => {
    if (enableReopenComplaint !== event) {
      setenableReopenComplaint(!enableReopenComplaint);
    } else {
      toast.error("Reopen Complaint is Enable");
    }
  };

  const handlereset = () => {
    setConfirmModal(false);
    setEditPropertyName("");
    setEditFloorName("");
    setEditFlatName("");
    setIsEdit(false);
    setIsEdit2(false);
    setIsEdit3(false);
    setloader(false);
  };

  const handleCheckUser = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    seterrorMsg("");
    try {
      if (password === "") {
        seterrorMsg("Please enter your admin password for remove resident");
        setloader(false);
      } else {
        const sendData = {
          userId: user?._id,
          societyId: fetchSocietyId(),
          password,
        };
        const res = await api.userCheckForGivePermission(sendData);
        if (res.data.success) {
          handleEditConfiguraion();
          setConfirmModal(false);
          seterrorMsg("");
          setpassword("");
          setloader(false);
        }
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleGetConfiguration = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        adminId: user?._id,
        societyId: fetchSocietyId(),
      };
      const res = await api.getSocietyConfiguration(sendData);
      if (res.data.success) {
        setAllSociety(res?.data?.config);
        setenableReopenComplaint(res?.data?.config?.reopenComplaint);
        setenableVisitorId(res?.data?.config?.needIdentityCard);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetConfiguration();
  }, []);

  const handleEditConfiguraion = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        adminId: user?._id,
        societyId: fetchSocietyId(),
        property: editPropertyName
          ? editPropertyName
          : allSociety?.propertyNaming?.property,
        floor: editFloorName
          ? editFloorName
          : allSociety?.propertyNaming?.floor,
        flat: editFlatName ? editFlatName : allSociety?.propertyNaming?.flat,
        reopenComplaint: enableReopenComplaint,
        needIdentityCard: enableVisitorId,
      };
      const res = await api.editSocietyConfiguration(sendData);
      if (res.data.success) {
        toast.success("Edit Configuration Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleGetConfiguration();
        handlereset();
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid container item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {`Settings > `}
                    <span style={{ color: "#000" }}>Configurations</span>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Society Name :&nbsp;
                    <span style={{ color: "#000", fontSize: 17 }}>
                      {allSociety?.society?.name}
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginLeft: 12,
                      marginRight: 18,
                    }}
                  >
                    Unique Id :&nbsp;
                    <span style={{ color: "#000", fontSize: 17 }}>
                      {allSociety?.society?.societyUniqueId}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid container style={{ marginTop: 20 }}>
                <Grid
                  container
                  item
                  xs={3}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginTop: 10,
                    }}
                  >
                    <CircleIcon style={{ fontSize: "13px" }} />
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginLeft: "20%",
                      marginTop: 10,
                    }}
                  >
                    Multi Tier :
                  </Typography>
                </Grid> */}
              {/* <Grid container item xs={6} style={{ marginTop: 8 }}>
                  <Typography style={{ marginLeft: "20%" }}>
                    <Switch checked={checked} onChange={handleChange} />
                    &nbsp; Enable Multi Tier
                  </Typography>
                </Grid> */}
              {/* </Grid> */}
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  container
                  item
                  xs={3}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginTop: 10,
                    }}
                  >
                    <CircleIcon style={{ fontSize: "13px" }} />
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginLeft: "20%",
                      marginTop: 10,
                    }}
                  >
                    Visitor Id :
                  </Typography>
                </Grid>
                <Grid container item xs={6} style={{ marginTop: 8 }}>
                  <Typography style={{ marginLeft: "20%" }}>
                    <Switch
                      checked={enableVisitorId}
                      onChange={handleChange2}
                    />
                    &nbsp; Enable Visitor Id
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  container
                  item
                  xs={3}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginTop: 10,
                    }}
                  >
                    <CircleIcon style={{ fontSize: "13px" }} />
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginLeft: "20%",
                      marginTop: 10,
                    }}
                  >
                    Reopen Complaint :
                  </Typography>
                </Grid>
                <Grid container item xs={6} style={{ marginTop: 8 }}>
                  <Typography style={{ marginLeft: "20%" }}>
                    <Switch
                      checked={enableReopenComplaint}
                      onChange={handleChange3}
                    />{" "}
                    &nbsp;{" "}
                    {!enableReopenComplaint
                      ? "Enable Reopen Complaint"
                      : "Disable Reopen Complaint"}
                  </Typography>
                </Grid>
                <Grid container item style={{ justifyContent: "center" }}>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #7D858D",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginTop: 15,
                    }}
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Submit
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"}>
                <Divider
                  style={{
                    marginTop: "15px",
                    marginBottom: 10,
                    backgroundColor: "#000",
                    width: "70%",
                  }}
                />
              </Grid>
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  container
                  item
                  xs={3}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginTop: 10,
                    }}
                  >
                    <CircleIcon style={{ fontSize: "13px" }} />
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      marginLeft: "20%",
                      marginTop: 10,
                    }}
                  >
                    Society Naming :
                  </Typography>
                </Grid>
                <Grid container item xs={6} style={{ marginTop: 8 }}>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        textAlign: "end",
                      }}
                    >
                      {handlePropertyName()} Name : &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isEdit ? (
                      <input
                        type="text"
                        style={{
                          borderRadius: 5,
                          padding: 5,
                          width: "98%",
                          border: "1px solid #416082",
                          marginBottom: 2,
                          outline: 0,
                        }}
                        placeholder={`Enter ${handlePropertyName()} Name`}
                        value={editPropertyName}
                        onChange={(event) =>
                          setEditPropertyName(event.target.value)
                        }
                      />
                    ) : (
                      <>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                          }}
                        >
                          {allSociety?.propertyNaming?.property}
                          <Tooltip
                            title="edit property name here"
                            placement="top"
                          >
                            <EditIcon
                              onClick={() => {
                                setIsEdit(true);
                              }}
                              style={{
                                marginLeft: 10,
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                            />
                          </Tooltip>
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {isEdit ? (
                      <>
                        <IconButton
                          onClick={() => {
                            setIsEdit(false);
                          }}
                        >
                          <Close
                            style={{
                              fontSize: 20,
                              color: "#6c757d",
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        textAlign: "end",
                        marginTop: 15,
                      }}
                    >
                      Floor Name : &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isEdit2 ? (
                      <input
                        type="text"
                        style={{
                          borderRadius: 5,
                          padding: 5,
                          width: "98%",
                          border: "1px solid #416082",
                          marginBottom: 2,
                          outline: 0,
                          marginTop: 15,
                        }}
                        placeholder="Enter floor Name"
                        value={editFloorName}
                        onChange={(event) =>
                          setEditFloorName(event.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          marginTop: 15,
                        }}
                      >
                        {allSociety?.propertyNaming?.floor}
                        <Tooltip title="edit floor name here" placement="top">
                          <EditIcon
                            onClick={() => {
                              setIsEdit2(true);
                            }}
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {isEdit2 ? (
                      <>
                        <IconButton
                          onClick={() => {
                            setIsEdit2(false);
                          }}
                        >
                          <Close
                            style={{
                              fontSize: 20,
                              color: "#6c757d",
                              marginTop: 15,
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        textAlign: "end",
                        marginTop: 15,
                      }}
                    >
                      Flat Name : &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isEdit3 ? (
                      <input
                        type="text"
                        style={{
                          borderRadius: 5,
                          padding: 5,
                          width: "98%",
                          border: "1px solid #416082",
                          marginBottom: 2,
                          outline: 0,
                          marginTop: 15,
                        }}
                        placeholder="Enter flat Name"
                        value={editFlatName}
                        onChange={(event) =>
                          setEditFlatName(event.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          marginTop: 15,
                        }}
                      >
                        {allSociety?.propertyNaming?.flat}
                        <Tooltip title="edit flat name here" placement="top">
                          <EditIcon
                            onClick={() => {
                              setIsEdit3(true);
                            }}
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {isEdit3 ? (
                      <>
                        <IconButton
                          onClick={() => {
                            setIsEdit3(false);
                          }}
                        >
                          <Close
                            style={{
                              fontSize: 20,
                              color: "#6c757d",
                              marginTop: 15,
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid container item style={{ justifyContent: "center" }}>
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #7D858D",
                        width: "100px",
                        backgroundColor:
                          isEdit || isEdit2 || isEdit3 ? "#2f45c5" : "#7D858D",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: 15,
                      }}
                      onClick={() => {
                        if (isEdit || isEdit2 || isEdit3) {
                          handleEditConfiguraion();
                        } else {
                          toast.error(
                            "Please edit atleast one before submitting",
                            {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                            }
                          );
                        }
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          Submit
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"}>
                <Divider
                  style={{
                    marginTop: "15px",
                    marginBottom: 10,
                    backgroundColor: "#000",
                    width: "70%",
                  }}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
      <ConfirmSwitchModal
        checked={checked}
        setChecked={setChecked}
        enableReopenComplaint={enableReopenComplaint}
        setenableReopenComplaint={setenableReopenComplaint}
        enableVisitorId={enableVisitorId}
        setenableVisitorId={setenableVisitorId}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        classes={classes}
        handleCheckUser={handleCheckUser}
        setpassword={setpassword}
        password={password}
        loader={loader}
        handleEditConfiguraion={handleEditConfiguraion}
        errorMsg={errorMsg}
        type={"enableSetting"}
      />
    </div>
  );
}

export default Configuration;
