import React, { useContext, useEffect, useRef } from "react";
import { Typography, Paper, Grid, Modal, Button } from "@material-ui/core";
import { LoadingOutlined } from "@ant-design/icons";
import { QuestionMark } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";
import ChooseCategoryDropDown from "../../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import MultipleSubCategory from "../../../Vendors/AddVendor/MultipleSubCategory";
import { AuthContext } from "../../../../contextAPI/context";

const VendorConfirmModal = (props) => {
  const {
    title,
    description,
    loader,
    handleConfirmation,
    rejectConfirmation,
    isOpen,
    errorMsg,
    selectedStatus,
    vendorCategory,
    setvendorCategory,
    vendorSubCategory,
    setvendorSubCategory,
    searchKey,
    setsearchKey,
    selectedUser,
  } = props;
  const prevCategoryLength = useRef(vendorCategory?.length);
  const { authState } = useContext(AuthContext);
  const { plant } = authState;

  useEffect(() => {
    setvendorCategory(selectedUser?.categories);
    setvendorSubCategory(selectedUser?.subcategories);
  }, [selectedUser]);

  const isDisabled =
    selectedStatus === "approve" &&
    (!vendorCategory?.some((item) => item?._id) ||
      (selectedUser?.isSubVendor &&
        !vendorSubCategory?.some((item) => item?._id)));

  useEffect(() => {
    if (vendorCategory?.length < prevCategoryLength?.current) {
      if (vendorCategory?.length === 0) {
        setvendorSubCategory([]);
      } else {
        setvendorSubCategory((prevSubCategories) =>
          prevSubCategories?.filter((subCategory) =>
            vendorCategory?.some(
              (category) => category?._id === subCategory?.categoryId
            )
          )
        );
      }
    }

    prevCategoryLength.current = vendorCategory?.length || 0;
  }, [vendorCategory, setvendorSubCategory]);

  return (
    <Modal open={isOpen}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 500,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          {description}
        </Typography>
        {selectedStatus === "approve" && (
          <>
            <Grid container style={{ marginLeft: -10, marginTop: 8 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#283f59",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    marginTop: 2,
                    marginLeft: 15,
                    marginBottom: 5,
                  }}
                >
                  Category
                </Typography>
              </Grid>
              {selectedUser?.isSubVendor && (
                <Grid container item xs={6}>
                  <Typography
                    style={{
                      color: "#283f59",
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "600",
                      marginTop: 2,
                      marginLeft: 15,
                      marginBottom: 5,
                    }}
                  >
                    Subcategory
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid container item xs={selectedUser?.isSubVendor ? 6 : 12}>
                <ChooseCategoryDropDown
                  setCategory={setvendorCategory}
                  category={vendorCategory}
                  isMultiple={plant ? true : false}
                  searchKey={searchKey}
                  setsearchKey={setsearchKey}
                />
              </Grid>
              {selectedUser?.isSubVendor && (
                <Grid container item xs={6}>
                  <MultipleSubCategory
                    setSubCategory={setvendorSubCategory}
                    subCategory={vendorSubCategory}
                    isMultiple={plant ? true : false}
                    searchKey={searchKey}
                    setsearchKey={setsearchKey}
                    category={vendorCategory}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}

        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        {errorMsg && (
          <Typography
            style={{
              marginTop: 8,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            disabled={loader}
            onClick={() => {
              if (!loader) {
                rejectConfirmation();
              }
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleConfirmation();
            }}
            disabled={isDisabled}
            style={{
              backgroundColor: isDisabled ? "#7D858D" : "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            {loader ? "Confirming.." : "CONFIRM"}
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default VendorConfirmModal;
