export const LOGINUSER = "LOGINUSER";
export const GETUSER = "GETUSER";
export const LOGOUT = "LOGOUT";
export const SOCIETIES = "SOCIETIES";
export const PROPERTIES = "PROPERTIES";
export const FLATS = "FLATS";
export const PROPERTYTYPE = "PROPERTYTYPE";
export const FLATTYPE = "FLATTYPE";
export const CATEGORIES = "CATEGORIES";
export const COMPLAINT = "COMPLAINT";
export const VENDORS = "VENDORS";
export const COMPLAINTLIFECYCLE = "COMPLAINTLIFECYCLE";
export const APPROVALREQUEST = "APPROVALREQUEST";
export const ADMIN = "ADMIN";
export const ALLVENDORS = "ALLVENDORS";
export const DELETEVENDORS = "DELETEVENDORS";
export const FECTHEDVENDOR = "FECTHEDVENDOR";
export const PERMISSIONMODULES = "PERMISSIONMODULES";
export const USERMODULESWITHPERSMISSIONS = "USERMODULESWITHPERSMISSIONS";
export const VERSIONHANDLE = "VERSIONHANDLE";
export const SWITCHEDSOCIETYID = "SWITCHEDSOCIETYID";
export const MEMBERTYPE = "MEMBERTYPE";
export const NAMINGCONVENTION = "NAMINGCONVENTION";
export const SOCIETYCONFIGURATION = "SOCIETYCONFIGURATION";
export const PLANT = "PLANT";
