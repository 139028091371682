import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import ConfigurationSetting from "../../Components/Settings/ConfigurationSetting.jsx/ConfigurationSetting";

const ConfigurationSettingRoutes = {
  path: "/settings",
  element: <NavigationDrawer />,
  children: [
    {
      path: "configurationsetting",
      element: <ConfigurationSetting />,
    },
  ],
};

export default ConfigurationSettingRoutes;
