import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Apartment, CalendarTodayOutlined, Person } from "@material-ui/icons";
import { HourglassOutlined } from "@ant-design/icons";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HailIcon from "@mui/icons-material/Hail";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import InfoIcon from "@mui/icons-material/Info";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { handleFlatName } from "../../../contextAPI/NamingConvention";

function StaffLogsTable(props) {
  const classes = useStyles();
  const { entryExitLogsData, pleasewait, type, page, staffDetails } = props;
  const [openMore, setOpenMore] = useState(false);
  const [moreInfoVisitor, setmoreInfoVisitor] = useState([]);

  const handleMoreInfoDialog = (visitor) => {
    setmoreInfoVisitor(visitor);
    setOpenMore(true);
  };

  function formatTime(timeString) {
    if (timeString) {
      const [hourString, minute] = timeString?.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }
  }

  const handleCloseMoreInfoDialog = () => {
    setOpenMore(false);
  };

  return (
    <>
      <Grid container style={{ marginTop: 10 }}>
        {entryExitLogsData?.map((log) => {
          return (
            <>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  marginLeft: 10,
                  marginTop: 8,
                  marginBottom: 6,
                }}
              >
                <CalendarTodayOutlined
                  style={{ marginRight: 4, fontSize: 21 }}
                />
                Date :&nbsp;
                <span style={{ color: "#000" }}>{log?.date}</span>
              </Typography>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sr. No
                      </TableCell> */}

                      <TableCell
                        className={classes.tableHeaderCell}
                        align="center"
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Action
                      </TableCell>

                      <TableCell
                        className={classes.tableHeaderCell}
                        align="center"
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Action By - Time
                      </TableCell>

                      <TableCell
                        className={classes.tableHeaderCell}
                        align="center"
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        More Info
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!pleasewait && (
                    <TableBody>
                      {log?.checkInActionTime
                        ?.map((row, index) => {
                          return (
                            <TableRow key={row?._id}>
                              {/* <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {(page - 1) * 50 + (index + 1)}.
                            </TableCell> */}

                              <TableCell
                                align="center"
                                sx={{
                                  color: "black",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                {row?.checkInStatus === "in" && (
                                  <Tag
                                    style={{
                                      cursor: "pointer",
                                      padding: 6,
                                      width: "100px",
                                      fontSize: "11px",
                                      marginBottom: 15,
                                      fontWeight: "bold",
                                    }}
                                    color="green"
                                    icon={
                                      <DirectionsWalkIcon
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    }
                                  >
                                    IN
                                  </Tag>
                                )}
                                {row?.checkInStatus === "out" && (
                                  <Tag
                                    style={{
                                      cursor: "pointer",
                                      padding: 6,
                                      width: "100px",
                                      fontSize: "11px",
                                      marginBottom: 15,
                                      fontWeight: "bold",
                                    }}
                                    color="red"
                                    icon={
                                      <HailIcon
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    }
                                  >
                                    OUT
                                  </Tag>
                                )}
                                {row?.checkInStatus === "waiting" && (
                                  <Tag
                                    style={{
                                      cursor: "pointer",
                                      padding: 6,
                                      width: "110px",
                                      fontSize: "11px",
                                      marginBottom: 15,
                                      fontWeight: "bold",
                                    }}
                                    color="#ff8800"
                                    icon={
                                      <HourglassOutlined
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    }
                                  >
                                    WAITING
                                  </Tag>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "black",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                {moment(row?.actionTime).format(
                                  "DD/MM/YYYY - h:mm A"
                                )}

                                <br></br>
                                <Typography
                                  style={{ marginTop: 3, fontSize: 12 }}
                                >
                                  {row?.checkInActionBy?.name
                                    ? row?.checkInActionBy?.name
                                    : "-"}
                                  <span
                                    style={{
                                      width: "50px",
                                      padding: 5,
                                      textTransform: "capitalize",
                                      marginLeft: 5,
                                    }}
                                    class="badge bg-primary"
                                  >
                                    {row?.checkInActionBy?.role
                                      ? row?.checkInActionBy?.role
                                      : "-"}
                                  </span>
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "black",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                <InfoIcon
                                  style={{
                                    color: "#2f45c5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleMoreInfoDialog(row);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                        .reverse()}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </>
          );
        })}

        {pleasewait && (
          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <Typography style={{ textAlign: "center", color: "#2f3aa3" }}>
              Loading Logs....{" "}
              <LoadingOutlined
                style={{ fontSize: 31, color: "#2f3aa3", marginLeft: 6 }}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openMore}
        keepMounted
        onClose={handleCloseMoreInfoDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 14,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            More details about the Staff
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontSize: 15,
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: 5,
                textDecoration: "underline",
              }}
            >
              <DirectionsCarIcon />
              Vehicle Info
            </Typography>
            {moreInfoVisitor?.vehicle?.name ||
            moreInfoVisitor?.vehicle?.vehicleType ||
            moreInfoVisitor?.vehicle?.number ? (
              <>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle Name :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.name
                      ? moreInfoVisitor?.vehicle?.name
                      : "-"}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle Type :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.vehicleType
                      ? moreInfoVisitor?.vehicle?.vehicleType
                      : "-"}
                  </span>
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle No. :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.number
                      ? moreInfoVisitor?.vehicle?.number
                      : "-"}
                  </span>
                </Typography>
              </>
            ) : (
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 17,
                  marginTop: 7,
                  fontWeight: "bolder",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                No Vehicle Info
              </Typography>
            )}
          </Grid>
          <Grid>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontSize: 15,
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: 5,
                textDecoration: "underline",
                marginTop: 10,
              }}
            >
              <Person />
              Personal Info
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              Aadhar No (last 4 digit) :{" "}
              <span style={{ fontWeight: "bold" }}>
                {staffDetails?.document ? staffDetails?.document : "-"}
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              Staff type :{" "}
              <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                {moreInfoVisitor?.visitorType}
              </span>
            </Typography>
          </Grid>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#000",
              fontSize: 15,
              fontWeight: "bolder",
              textAlign: "center",
              marginBottom: 5,
              textDecoration: "underline",
              marginTop: 10,
            }}
          >
            <Apartment />
            {handleFlatName()} Details
          </Typography>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Name
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
                marginLeft: 30,
              }}
            >
              {handleFlatName()} Number
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginRight: 10,
                marginBottom: 5,
                marginLeft: 25,
              }}
            >
              {handleFlatName()} Shift Timing
            </Typography>
          </Grid>
          <Grid>
            <List>
              {moreInfoVisitor?.selectedFlat?.map((flat) => {
                return (
                  <ListItem>
                    <ApartmentIcon
                      style={{
                        fontSize: 15,
                        color: "#6c757d",
                        marginRight: 4,
                        marginLeft: -17,
                        marginBottom: 8,
                      }}
                    />
                    <Grid
                      container
                      style={{
                        justifyContent: "space-between",
                        marginTop: -10,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 15,
                          marginRight: 20,
                          marginLeft: 5,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        {flat?.flat?.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 20,
                          marginLeft: 45,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        {flat?.flat?.number}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 15,
                          textAlign: "end",
                          marginLeft: 35,
                          marginRight: -10,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        {formatTime(flat?.shiftFrom)} &nbsp;
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#6c757d",
                          }}
                        >
                          TO
                        </span>{" "}
                        &nbsp;{formatTime(flat?.shiftTo)}
                      </Typography>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMoreInfoDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StaffLogsTable;
