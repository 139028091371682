import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { LoadingOutlined } from "@ant-design/icons";
import { DomainAddOutlined } from "@mui/icons-material";

function AddPriorityContactModal(props) {
  const classes = useStyles();
  const {
    addContactModal,
    setaddContactModal,
    name,
    setname,
    phone,
    setphone,
    organisation,
    setorganisation,
    handleAddOrDeleteContact,
    loader,
    subcategory,
    errorMessage,
    seterrorMessage,
  } = props;

  return (
    <Modal open={addContactModal}>
      <Paper
        className={classes.ModalView}
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "auto",
          minWidth: "300px",
          maxWidth: "29vw",
        }}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 14,
              opacity: 1,
            }}
          >
            Add Priority Contact
          </Typography>
          {!loader && (
            <IconButton
              onClick={() => {
                setname("");
                setphone("");
                setorganisation("");
                setaddContactModal(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          )}
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              fontSize: "14px",
              marginTop: 6,
              marginRight: 7,
              marginBottom: 5,
            }}
          >
            Subcategory Name : {subcategory?.subcategoryName}
          </Typography>
          <input
            type="text"
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 8,
              outline: 0,
            }}
            onChange={(event) => setname(event.target.value)}
            placeholder="Enter Name"
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <input
            type="text"
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 8,
              outline: 0,
            }}
            onChange={(event) => setorganisation(event.target.value)}
            placeholder="Enter Organisation / Position"
            value={organisation}
          />
        </Grid>
        <Grid item xs={12}>
          <input
            type="number"
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 8,
              outline: 0,
            }}
            onChange={(event) => {
              setphone(event.target.value);
            }}
            placeholder="Enter Phone Number"
            value={phone}
            max="10"
          />
        </Grid>

        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor:
                name === "" || phone?.length < 10 || organisation === ""
                  ? "#7D858D"
                  : "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (
                !loader &&
                name !== "" &&
                phone?.length === 10 &&
                organisation !== ""
              ) {
                handleAddOrDeleteContact();
              } else if (phone?.length !== 10) {
                seterrorMessage("please enter 10 digit valid phone number");
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <DomainAddOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}

                {!loader ? `Add Contact` : `Adding...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddPriorityContactModal;
