import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import useStyle from "../styles";
import moment from "moment";
import Logo from "../../../../../assets/SociohoodLogo.png";

const JmrInvoice = React.forwardRef((props, ref) => {
  const classes = useStyle();
  const { jmrItemData, jmrData, complaintNo } = props;

  return (
    <div ref={ref} style={{ padding: 20 }}>
      <Grid container justifyContent={"space-between"}>
        <Grid container item xs={4}>
          <div>
            <div>
              <img src={Logo} alt="repairo-logo" />
            </div>
          </div>
        </Grid>
        <Grid container item xs={8} justifyContent={"flex-end"}>
          <div>
            <h3>Sociohood building</h3>
            <div>M6, Vijay Aman Apartment Main Road, Kankarbagh</div>
            <div>Patna Bihar 800020 India</div>
            <div>Pin - 122003</div>
            <a href="https://www.sociohood.com">www.sociohood.com</a>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "#000",
            marginBottom: 10,
          }}
        >
          Complaint Number:{" "}
          <span style={{ fontWeight: "normal" }}>{complaintNo}</span>
        </Typography>
      </Grid>
      {jmrItemData.map((jmr) => {
        const reversedItemDetails = jmr?.itemDetails?.slice().reverse();
        return (
          <>
            <Grid
              container
              style={{ justifyContent: "flex-start", marginTop: "10px" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: 10,
                }}
              >
                Vendor Name:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {jmr?.vendor?.name}
                </span>
              </Typography>
            </Grid>
            <Grid container justifyContent={"flex-start"}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: 10,
                }}
              >
                Vendor Email:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {jmr?.vendor?.email}
                </span>
              </Typography>
            </Grid>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow container>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Sr No.
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Sub Location
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Item Code
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Item Description
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Item UOM
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Rate
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      M/F
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Nos
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Length
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Breadth
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      D/H
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      style={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "6px",
                      }}
                    >
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reversedItemDetails.map((data, index) => {
                    return (
                      <TableRow
                      // key={row.name}
                      >
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {index + 1}.
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {jmrData?.location}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.subLocation}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.itemCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.itemDescription}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.itemUom}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.basePrice}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.mf}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.nos}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.itemLength}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.itembreadth}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.height}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.quantity}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "6px",
                          }}
                        >
                          {data?.totalPriceForItem}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent={"flex-end"}>
              <Typography
                style={{
                  color: "#00042A",
                  marginBottom: 6,
                  textAlign: "left",
                  fontSize: "16px",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                Sub Total : ₹ {jmr.totalPriceForVendorJmr}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            </Grid>
          </>
        );
      })}
    </div>
  );
});

export default JmrInvoice;
