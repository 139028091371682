import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { ArrowRightAlt, DeleteOutlined } from "@material-ui/icons";
import {
  navigateToAdvanceVendorSetting,
  navigateToComplaintSubCategory,
} from "../../../Navigation/Navigations";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as api from "../../../api";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import { isMemberTypeS2Admin } from "../../../GlobalFunctions/GlobalFunctions";
import { AuthContext } from "../../../contextAPI/context";

function CategoryTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const { allCategory, page, getComplaintCategory } = props;

  const handleDeleteCategoryModal = (category, isDeleted) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${category?.categoryName}?`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        handleCategoryDelete(category);
      },
    });
  };

  const handleCategoryDelete = async (category) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        categoryId: category?._id,
      };
      const res = await api.deleteCategory(sendData);
      if (res?.data?.success) {
        toast.success("Category Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        getComplaintCategory();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Category Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sub Category
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                OR
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Advance Vendor Option
              </TableCell>
              {isMemberTypeS2Admin(user?.role) && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allCategory?.map((category, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 50 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {category?.categoryName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        navigateToComplaintSubCategory({
                          categoryId: category._id,
                          categoryName: category?.categoryName,
                        })
                      )
                    }
                  >
                    {category?.subcategories?.length > 0
                      ? category?.subcategories?.length
                      : "No"}{" "}
                    Subcategories&nbsp;
                    <ArrowRightAlt
                      title="go to subcategory page"
                      style={{ fontSize: 25, cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          navigateToComplaintSubCategory({
                            categoryId: category._id,
                            categoryName: category?.categoryName,
                          })
                        )
                      }
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#303F9F",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      if (category?.isMultipleVendor) {
                        navigate(
                          navigateToAdvanceVendorSetting({
                            categoryId: category._id,
                            categoryName: category?.categoryName,
                          })
                        );
                      }
                    }}
                  >
                    {category?.isMultipleVendor ? (
                      <>
                        <SettingsIcon
                          style={{
                            marginRight: 4,
                            fontSize: "15px",
                          }}
                        />
                        Advance Vendor Settings
                      </>
                    ) : (
                      <span style={{ color: "#c8c8c8" }}>NA</span>
                    )}
                  </TableCell>
                  {isMemberTypeS2Admin(user?.role) && (
                    <TableCell align="center">
                      <Tag
                        style={{ cursor: "pointer" }}
                        color="red"
                        onClick={() => {
                          handleDeleteCategoryModal(category, true);
                        }}
                        icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                      >
                        DELETE
                      </Tag>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CategoryTable;
