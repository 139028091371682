import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  content2: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },

  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },

  buttonDelete: {
    borderRadius: 5,
    marginTop: theme.spacing(3),
    backgroundColor: "#4b8edb",
    padding: 7,
    fontWeight: "bold",
    fontSize: 15,
    width: 140,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4b8edb",
      color: "#fff",
    },
  },

  tableContainer: {
    borderRadius: 10,
    margin: "15px 0px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
}));
