import React from "react";
import Dashboard from "../../Components/Dashboard/dashboard";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import ProfileDetails from "../../Components/ProfileDetails/ProfileDetails";


const HomeRoutes = {
  path: "/",
  element: <NavigationDrawer />,
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "profile",
      element: <ProfileDetails />,
    },
  ],
};
export default HomeRoutes;
