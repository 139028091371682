import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "../style";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import Loading from "../../../contextAPI/Loading";
import AddCategory from "./AddCategory";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import LocalDirectoryCard from "./LocalDirectoryCard";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";

function LocalDirectory() {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Local Directory")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleGetDirectoryCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        society: fetchSocietyId(),
        searchKey: searchKey,
      };
      const res = await api.getLocalDirectoryCategory(sendData);
      if (res?.data?.success) {
        setAllCategory(res?.data?.directory);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetDirectoryCategory();
  }, [searchKey]);
  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={5}
                  lg={5}
                  sm={5}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    Local Directory
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Local Directory Page"
                        onClick={handleGetDirectoryCategory}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  lg={7}
                  sm={7}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search by category"
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        // setPage(1);
                      }}
                      value={searchKey}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "140px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: 7,
                        marginLeft: 8,
                      }}
                      onClick={() => {
                        setAddCategoryModal(true);
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <AddIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Category
                        </p>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container style={{ overflow: "hidden" }}>
                {allCategory?.map((cate) => {
                  return (
                    <LocalDirectoryCard
                      cate={cate}
                      handleGetDirectoryCategory={handleGetDirectoryCategory}
                      isReadWrite={isReadWrite}
                    />
                  );
                })}
              </Grid>
              {!loader && allCategory?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Local Directory
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddCategory
          addCategoryModal={addCategoryModal}
          setAddCategoryModal={setAddCategoryModal}
          handleGetDirectoryCategory={handleGetDirectoryCategory}
        />
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default LocalDirectory;
