import React, { useState, useContext, useEffect } from "react";
import { Typography, Grid, Divider, Avatar } from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Rating } from "@mui/material";
import * as api from "../../../api";
import { AuthContext } from "../../../contextAPI/context";
import { LoadingOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../utils/base";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";
import { Pagination } from "@mui/material";
import { resolveErrorMessage } from "../../../services/service";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { navigateToVendorDetails } from "../../../Navigation/Navigations";
import { KeyboardBackspaceRounded } from "@material-ui/icons";

function VendorComplaints(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user, fetchedvendor } = authState;
  const { id, status } = useParams();
  const navigate = useNavigate();
  const [pleasewait, setpleasewait] = useState(false);
  const [vendorImage, setvendorImage] = useState("");
  const [vendor, setvendor] = useState([]);
  const [Complaints, setComplaints] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [allVendor, setAllVendor] = useState([]);

  useEffect(() => {
    handleVendorComplaintFind();
  }, [authState]);

  useEffect(() => {
    let temp = [];
    temp = allVendor?.filter((v) => {
      return v._id === id;
    });
    setvendor(temp[0]);
  }, [allVendor]);

  const handleGetVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setAllVendor(res?.data?.vendor?.vendor);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetVendor();
  }, [search, page]);

  const handleVendorComplaintFind = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        vendorId: id,
        societyId: fetchSocietyId(),
        pageNumber: 1,
        filter: status,
      };
      const res = await api.vendorFindComplaint(sendData);
      if (res?.data?.success) {
        setComplaints(res.data.vendor?.complaints);
        setpleasewait(false);
        settotalCount(res.data.vendor?.count);
      }
    } catch (error) {
      console.log(error);
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    // setpleasewait(true)
    const sendData = {
      societyId: fetchSocietyId(),
      filter: status,
      pageNumber: value,
      vendorId: id,
    };
    const res = await api.vendorFindComplaint(sendData);
    try {
      setComplaints(res.data.vendor?.complaints);
      setpleasewait(false);
      settotalCount(res.data.vendor?.count);
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setpleasewait(false);
    }
  };

  return (
    <div className={classes.root}>
      <main className={classes.content2}>
        <div className={classes.toolbar} />
        <div class="card" style={{ borderRadius: 10, marginLeft: 10 }}>
          <div class="card-body">
            <Grid
              container
              style={{
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    textTransform: "capitalize",
                  }}
                >
                  Vendor {status} Complaints
                </div>
              </Typography>
              <div
                class="card"
                style={{
                  height: 30,
                  boxShadow: "0 3px 6px #4a6078",
                  width: "100px",
                  backgroundColor: "#2f45c5",
                  color: "#ffffff",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => {
                  navigate(navigateToVendorDetails({ vendorId: id }));
                }}
              >
                <div class="card-body ">
                  <p
                    class="card-text"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "-12px",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    <KeyboardBackspaceRounded
                      style={{ marginRight: 10, fontSize: "15px" }}
                    />
                    &nbsp;BACK
                  </p>
                </div>
              </div>
              <div class="card-container">
                {!pleasewait && (
                  <Grid container>
                    <Grid item xs={4}>
                      <img
                        class="round"
                        src={
                          vendor?.image
                            ? `${BASE_URL}/user/${vendor?.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt="user"
                        height="100px"
                        width="100px"
                        style={{ marginTop: 6 }}
                      />
                      <h3
                        style={{
                          color: "#fff",
                          fontSize: 18,
                          marginTop: 5,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendor?.name}&nbsp;
                      </h3>
                      <p>
                        <AttachEmailIcon
                          style={{ fontSize: 15, marginRight: 5 }}
                        />
                        {vendor?.email}
                        <br />
                        <LocalPhoneIcon
                          style={{ fontSize: 15, marginRight: 5 }}
                        />
                        {vendor?.phone}
                      </p>

                      <span
                        class="owner"
                        style={{
                          marginLeft: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        {Math.round(vendor?.averageRating * 10) / 10}
                        <StarIcon style={{ fontSize: 20, marginBottom: 2 }} />
                      </span>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          marginTop: 18,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Complaint Category : {vendor?.category?.categoryName}
                      </Typography>
                      <br></br>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sub Category : {vendor?.subCategory?.subcategoryName}
                      </Typography>
                      <br></br>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Address : {vendor?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <div class="skills" style={{ backgroundColor: "#fff" }}>
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 23,
                      fontWeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {status} Complaints
                  </h6>
                </div>
              </div>

              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sr. No.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Complaint ID
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Complainer
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Address
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Subcategory
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Deadline
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Priority
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!pleasewait ? (
                      Complaints?.map((row, index) => {
                        var date = moment(row.createdAt).format(
                          "DD/MM/YYYY - h:mm A"
                        );
                        return (
                          <TableRow key={row.index}>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {(page - 1) * 10 + (index + 1)}.
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row.complaintNumber}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row.complaintTitle}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {date}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row?.complainer?.name}-{row?.complainer?.role}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row?.complainer?.flat
                                ? row?.complainer?.property?.name +
                                  "-" +
                                  row?.complainer?.floor?.name +
                                  "-" +
                                  row?.complainer?.flat?.name
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row?.category?.categoryName}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row?.subcomplaintCategory?.subcategoryName}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row.deadline ? row.deadline : "0"}&nbsp;hours{" "}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row.priority}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadingOutlined
                          style={{
                            fontSize: 50,
                            color: "#2f3aa3",
                            fontWeight: "normal",
                          }}
                        />
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {Complaints?.length > 0 && status !== "transferred" && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={totalCount}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </main>
    </div>
  );
}

export default VendorComplaints;
