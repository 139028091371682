import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Tooltip } from "@material-ui/core";
import useStyles from "../styles";
import { Circle, RestartAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import moment from "moment";
import ConfirmModal from "../../../../../contextAPI/ConfirmModal";

function SocietyRequestTable(props) {
  const classes = useStyles();
  const {
    societyRequests,
    status,
    isConfirmResend,
    setisConfirmResend,
    handleAddSocietyRequest,
    loader,
    errorMessage,
    seterrorMessage,
    setSociety,
  } = props;

  const handleClose = () => {
    setisConfirmResend(false);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Society Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {status === "pending" ? "Requested" : "status"} Time
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                City
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Unique ID
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {societyRequests?.map((society, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {society?.society?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {moment(society?.actionTime).format("DD/MM/YYYY - h:mm A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.society?.city}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                  >
                    {society?.society?.societyUniqueId}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                  >
                    {society?.status === "approved" && (
                      <Typography
                        style={{
                          color: "rgb(36 202 38)",
                          fontWeight: "bold",
                          fontSize: 12,
                          marginTop: 4,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        <Circle style={{ fontSize: 15, marginRight: 6 }} />
                        &nbsp;APPROVED
                      </Typography>
                    )}
                    {society?.status === "pending" && (
                      <Typography
                        style={{
                          color: "rgb(202 133 36)",
                          fontWeight: "bold",
                          fontSize: 12,
                          marginTop: 4,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        <Circle style={{ fontSize: 15, marginRight: 6 }} />
                        &nbsp;PENDING
                      </Typography>
                    )}
                    {society?.status === "rejected" && (
                      <Typography
                        style={{
                          color: "rgb(239 13 39)",
                          fontWeight: "bold",
                          fontSize: 12,
                          marginTop: 4,
                        }}
                      >
                        <Circle style={{ fontSize: 15, marginRight: 6 }} />
                        &nbsp;REJECTED
                        <Tooltip title={"Resend Request"} placement="up" arrow>
                          <RestartAlt
                            onClick={() => {
                              setSociety(society?.society?._id)
                              seterrorMessage(false)
                              setisConfirmResend(true);
                            }}
                            style={{
                              fontSize: 23,
                              marginLeft: 6,
                              color: "#2f45c5",
                              marginTop: -3,
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={`ARE YOU SURE YOU WANT TO RESEND THIS REQUEST`}
        description="You can confirm by clicking below."
        loader={loader}
        handleConfirmation={handleAddSocietyRequest}
        rejectConfirmation={handleClose}
        isOpen={isConfirmResend}
        errorMsg={errorMessage}
      />
    </>
  );
}

export default SocietyRequestTable;
