import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Divider,
  Table,
  Checkbox,
  TableContainer,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api/index";
import Loading from "../../../contextAPI/Loading";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";

const SelectPropertyModal = (props) => {
  const {
    openPropertyModal,
    setopenPropertyModal,
    selectedDay,
    setdayWiseAllData,
    dayWiseAllData,
    vendor,
    sector,
    dynamicCount,
    getDynamicCount,
    setallPropertyCount,
    currentIndexForSubCount,
    dayWiseSelection,
  } = props;

  const classes = useStyles();
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const [allProperties, setallProperties] = useState(null);
  const [totalCount, settotalCount] = useState(0);
  const [propertyModalPage, setpropertyModalPage] = useState(1);
  const [propertyId, setpropertyId] = useState([]);

  const handleGetProperties = async (isException, exceptionProperty) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        filters: {
          sector,
          searchKey: search,
          societyId: fetchSocietyId(),
          exceptionProperty,
          isException,
        },
        page: 1,
        limit: 2000,
      };
      const res = await api.vendorConfigurationGetProperty(sendData);
      if (res?.data?.success) {
        setallProperties(res?.data?.properties?.data);
        if (search === "") {
          settotalCount(res?.data?.properties?.totalCounts);
        }
        setallPropertyCount(res?.data?.properties?.allProperties);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    if (openPropertyModal) {
      dayWiseSelection[selectedDay] = false;
      if (getDynamicCount(selectedDay) > 1) {
        let newArr = dayWiseAllData?.filter((obj) => obj.day === selectedDay);
        let arr = newArr[newArr?.length - 2]?.exceptionproperties
          ? newArr[newArr?.length - 2]?.exceptionproperties
          : newArr[newArr?.length - 2]?.properties;
        handleGetProperties(true, arr);
      } else {
        handleGetProperties(false, []);
      }
    }
  }, [search, sector, openPropertyModal]);

  const checkVendor = () => {
    if (dayWiseAllData?.length > 0) {
      let newArr = dayWiseAllData?.filter((obj) => obj.day === selectedDay);
      if (newArr) {
        return newArr[currentIndexForSubCount]?.vendor !== "" ||
          newArr[currentIndexForSubCount]?.vendor !== undefined
          ? newArr[currentIndexForSubCount]?.vendor
          : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const handleSaveProperties = () => {
    setsearch("");
    let newArr = dayWiseAllData?.find(
      (obj) => obj.day === selectedDay && obj.vendor === checkVendor()
    );
    if (newArr) {
      if (getDynamicCount(selectedDay) === 1) {
        newArr["properties"] = propertyId;
        newArr["isException"] = true;
        newArr["selectedCount"] = totalCount - propertyId?.length;
      } else {
        newArr["properties"] = allProperties
          .filter((p) => !propertyId?.includes(p?._id))
          .map((x) => x?._id);
        newArr["exceptionproperties"] = allProperties
          .filter((p) => propertyId?.includes(p?._id))
          .map((x) => x?._id);
        newArr["isException"] = false;
      }
    }
    setpropertyId([]);
    setopenPropertyModal(false);
  };

  useEffect(() => {
    let newArr = dayWiseAllData?.filter((obj) => obj.day === selectedDay);
    let arr = newArr[newArr?.length - 1]?.exceptionproperties;
    if (getDynamicCount(selectedDay) > 1) {
      setallProperties(arr);
    }
  }, [getDynamicCount(selectedDay)]);

  return (
    <Modal open={openPropertyModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "450px",
          maxWidth: "45vw",
          overflow: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Select Exception {handlePropertyName()}
          </Typography>

          <IconButton
            onClick={() => {
              setsearch("");
              setpropertyId([]);
              setopenPropertyModal(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid>
          <>
            <Grid
              container
              style={{ marginBottom: "10px", justifyContent: "space-between" }}
            >
              <span>Selected : {propertyId?.length}</span>
              <div class="box" style={{ backgroundColor: "#fff" }}>
                <input
                  type="text"
                  placeholder="search by name"
                  autoFocus
                  onChange={(event) => {
                    setpropertyModalPage(1);
                    setsearch(event.target.value);
                  }}
                  value={search}
                />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
              </div>
            </Grid>

            <TableContainer
              component={Paper}
              style={{
                borderRadius: 10,
                marginRight: 10,
                height: 500,
              }}
            >
              <Table style={{ height: "max-content" }}>
                {!loader && (
                  <TableBody>
                    {allProperties?.map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell
                          align="center"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            padding: "4px",
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={propertyId.includes(row?._id)}
                            key={row?._id}
                            onChange={() => {
                              if (propertyId.includes(row?._id)) {
                                var indexNo = propertyId.indexOf(row?._id);
                                propertyId.splice(indexNo, 1);
                                setpropertyId([...propertyId]);
                              } else {
                                propertyId.push(row?._id);
                                setpropertyId([...propertyId]);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <Typography className={classes.textname}>
                            {index + 1}. {row?.name}&nbsp;({row?.type})
                            {/* {row?._id} */}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {loader && (
                  <span style={{ padding: "10px", textAlign: "center" }}>
                    Loading {handlePropertyName()}....
                  </span>
                )}
                {!loader && allProperties?.length === 0 && (
                  <span style={{ padding: "10px", textAlign: "center" }}>
                    No {handlePropertyName()}
                  </span>
                )}
              </Table>
            </TableContainer>
          </>
          <Grid
            container
            style={{ justifyContent: "flex-end", marginTop: "10px" }}
          >
            <button
              className={classes.selectPropButton}
              disabled={totalCount === propertyId?.length}
              onClick={handleSaveProperties}
              style={{
                backgroundColor: totalCount === propertyId?.length && "#c8c8c8",
              }}
            >
              {propertyId?.length > 0 ? "Save & Next" : "Select all & Next"}
            </button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SelectPropertyModal;
