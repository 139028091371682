import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import * as api from "../../api/index";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import { CheckCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import { AuthContext } from "../../contextAPI/context";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function SearchAddress(props) {
  const { setsearchKey, searchKey, setPropertyId, propertyId, isMultiple } =
    props;
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allProperties, setallProperties] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageForProperty, setpageForProperty] = useState(1);

  useEffect(() => {
    handleGetProperties();
  }, [pageForProperty, searchKey]);

  const handleGetProperties = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: pageForProperty,
        searchKey,
        pageLimit: 25,
        addressGroupIds: user?.isAdmin
          ? ""
          : user?.addressGroupId?.[0]?.addressGroupId?._id,
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        if (pageForProperty === 1) {
          setallProperties(res?.data?.properties?.properties);
          settotalCount(res?.data?.properties?.totalCount);
        } else {
          setallProperties((prev) => [
            ...prev,
            ...res?.data?.properties?.properties,
          ]);
          settotalCount(res?.data?.properties?.totalCount);
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      resolveErrorMessage(error);
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== allProperties?.length
    ) {
      setpageForProperty((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        onChange={(event, value) => {
          setPropertyId(value);
        }}
        id="country-select-demo"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        classes={classes}
        options={allProperties}
        autoHighlight
        freeSolo
        value={propertyId}
        getOptionLabel={(option) =>
          option?.name ? `${option?.name} - ${option?.type}` : ""
        }
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option.id}>
                {propertyId?.length > 0 && propertyId.includes(option) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option.name}&nbsp;-&nbsp;{option?.type}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpageForProperty(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={
              loading
                ? "Loading..."
                : `Select ${handlePropertyName()} (${totalCount})`
            }
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default SearchAddress;
