import React, { useState, useEffect } from "react";
import {
  Grid,
} from "@material-ui/core";

import ClipLoader from "react-spinners/FadeLoader";


function Loading(props) {
const {loader} = props

  

  return (
    <Grid
    style={{
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    
   <ClipLoader
      color="#2f45c5"
      loading={loader}
      size={22}
    />
  </Grid>
  );
}

export default Loading;
