import axios from "axios";
import { BASE_URL } from "../utils/base";
import { decrypyData } from "../utils/encryption";

const API = axios.create({ baseURL: BASE_URL });

// const API = axios.create({ baseURL: 'http://localhost:8000' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("refreshToken")) {
    req.headers.token = `${JSON.parse(localStorage.getItem("refreshToken"))}`;
  }
  req.headers.version = decrypyData(localStorage.getItem("version"));
  req.headers.platform = "dashboard";
  return req;
});
//AUTH ROUTES
export const userVerifyemail = (data) =>
  API.get("auth/user/register/verify-mail", data);
export const managementRegister = (data) =>
  API.post("s2admin/admin-s1admin", data);
export const getAllSocities = () => API.get("user/get-societyId");

//MANAGEMENT ROUTES
export const managementLogin = (data) =>
  API.post("auth/management/login", data);
export const updateAdmin = (data) =>
  API.post("management/update-admin-image", data);
export const getUser = (data) => API.get("management/get-user", data);
export const createSociety = (data) => API.post("management/society", data);
export const createProperty = (data) => API.post("management/property", data);
export const createFlat = (data) => API.post("management/flat", data);
export const createPropertyType = (data) =>
  API.post("management/propertyType", data);
export const createFlatType = (data) => API.post("management/flatType", data);
export const createComplaintCategory = (data) =>
  API.post("management/add-complaint-category", data);
export const fetchSocietyDetailsById = (data) =>
  API.get("management/get-societyById", data);
export const createSubComplaintCategory = (data) =>
  API.post("management/add-sub-complaint-category", data);
export const editSubComplaintCategory = (data) =>
  API.post("management/edit-sub-complaint-category", data);
export const searchUser = (data) => API.post("management/search-user", data);
export const getComplaint = (data) =>
  API.post("management/get-complaints", data);
export const createVendor = (data) => API.post("management/add-vendor", data);
export const editVendor = (data) => API.post("management/edit-vendor", data);
export const deleteVendor = (data) =>
  API.post("management/delete-vendor", data);
export const assignVendor = (data) =>
  API.post("management/assign-vendor", data);
export const notifyVendor = (data) =>
  API.post("management/notify-vendor", data);
export const notifyCustomer = (data) =>
  API.post("management/notify-customer", data);
export const changePriority = (data) =>
  API.post("management/change-priority", data);
export const changeStatus = (data) =>
  API.post("management/resolve-or-close-complaint", data);
export const reopenComplaint = (data) =>
  API.post("management/re-open-complaint", data);
export const searchComplaint = (data) =>
  API.post("management/search-complaint", data);
export const countComplaint = (data) =>
  API.get("management/count-complaint", data);
export const approveResidantRequests = (data) =>
  API.post("management/approve-request", data);

export const changeResidentRequests = (data) =>
  API.post("management/change-request-status", data);

export const complaintLifeCycle = (data) =>
  API.post("management/complaint-life-cycle", data);
export const addAutoEscalation = (data) =>
  API.post("management/add-complaintAutoEscalate-level", data);
export const statusWisegraphData = (data) =>
  API.post("management/complaintStatusGraph", data);

export const searchVendor = (data) => API.get("management/search-vendor", data);

export const ComplaintCategory = (data) =>
  API.get("management/searchComplaint-category", data);

export const SearchComplaintSubCategory = (data) =>
  API.get("management/search-Subcategory", data);

export const searchAllUsers = (data) =>
  API.post("management/get-admin-or-user-or-vendor", data);

export const fetchApprovalRequests = (data) =>
  API.post("management/get-user-requests", data);

export const fetchAllManagement = (data) =>
  API.post("management/fetch-all-management", data);

export const forgotPassword = (data) =>
  API.post("/management/forgotpassword", data);
export const validateOtp = (data) => API.post("/management/validateotp", data);
export const resetPassword = (data) =>
  API.post("/management/resetPassword", data);
export const FetchCsvComplaint = (data) =>
  API.post("/management/fetch-data-for-csv", data);
export const shutDownScheduler = (data) =>
  API.post("/management/shut-down", data);
export const resetScheduler = (data) => API.post("/reset/reset", data);
export const fetchVendorById = (data) =>
  API.post("/vendor/fetch-vendor-by-Id", data);
export const editResidentOccupiedFlat = (data) =>
  API.post("/management/resident-change-address", data);
export const changeStatusToCancel = (data) =>
  API.post("/management/cancel-life-cycle", data);

export const vendorFindComplaint = (data) =>
  API.post("/vendor/vendor-get-complaints-admin", data);

export const serachType = (data) => API.post("/management/search-Type", data);
export const serachFlat = (data) => API.get("/management/search-flat", data);
export const searchProperty = (data) =>
  API.get("/management/search-property", data);
export const userComplaints = (data) =>
  API.post("/user/user-get-complaints", data);
export const getJmr = (data) => API.post("/management/get-jmr", data);
export const addJmrItem = (data) => API.post("/management/add-jmr", data);
export const editJmrItem = (data) => API.post("/management/edit-jmr", data);
export const deleteJmrItem = (data) => API.post("/management/delete-jmr", data);
export const JmrVendorFinalize = (data) =>
  API.post("/management/freeze-vendor-jmr-by-admin", data);
export const JmrFullFinalize = (data) =>
  API.post("/management/freeze-jmr-by-admin", data);
export const getAllNotice = (data) => API.get("/notice/get-notices", data);
export const getAllNoticeById = (data) =>
  API.post("/notice/get-notice-by-noticeId", data);
export const getNoticeType = (data) => API.get("notice/get-notice-type", data);
export const addNotice = (data) => API.post("notice/add-notice", data);
export const editNotice = (data) => API.post("notice/update-notice", data);

export const addNoticeType = (data) => API.post("notice/add-notice-type", data);
export const gateManagement = (data) => API.get("/visitor/get-visitor", data);
export const staffManagement = (data) => API.get("/staff/get-staff", data);
export const getVisitorType = (data) =>
  API.get("/visitor/get-visitor-type", data);
export const addVisitorType = (data) =>
  API.post("/visitor/add-visitor-type", data);
export const gateManagementEntryExitGraph = (data) =>
  API.post("visitor/entry-graph", data);
export const getEntryExitlogs = (data) =>
  API.post("visitor/visitor-checkin-log", data);
export const getallDiscussions = (data) =>
  API.get("discussion/all-discussions", data);
export const getallDiscussionsByID = (data) =>
  API.post("discussion/discussion-by-id", data);
export const addDiscussion = (data) =>
  API.post("discussion/add-discussion", data);
export const gateManagementUserCount = (data) =>
  API.post("management/getmanagement-count", data);

//USER ROUTES
export const userLogin = (data) => API.post("auth/user/login", data);
export const logout = (data) => API.post("auth/user/logout", data);
export const createComplaint = (data) => API.post("user/add-complaint", data);
export const getComplaintById = (data) => API.post("/user/get-complaint", data);
export const downloadAttachments = (key) =>
  API.get(`/notice/attachments/${key}`);
export const downloadImages = (key) => API.get(`/notice/images/${key}`);

export const filterResidants = (data) =>
  API.post("management/filter-residants", data);

export const addComment = (data) => API.post("discussion/add-comment", data);

export const addReply = (data) => API.post("discussion/add-reply", data);

export const getAllGuard = (data) => API.get("guard/get-all-guard", data);

export const aproveGuardByAdmin = (data) =>
  API.post("guard/guard-approve", data);

export const getMsiData = (data) => API.post("/management/msi-data", data);
export const getMsiFyGraphData = (data) =>
  API.post("/management/msi-fy-graph-data", data);
export const getSectorWiseGraphData = (data) =>
  API.post("/management/msi-sector-wise-graph-data", data);
export const getSlaGraphData = (data) =>
  API.post("/management/msi-sla-graph-data", data);
export const getVendorWiseGraphData = (data) =>
  API.post("/management/msi-vendor-wise-graph-data", data);
export const getCategoryWiseGraphData = (data) =>
  API.post("/management/msi-category-wise-graph-data", data);
export const getVendorPerformanceGraphData = (data) =>
  API.post("/management/msi-vendor-performance-data", data);
export const getMeeting = (data) => API.get("/meeting/get-meeting", data);
export const addMeetingData = (data) => API.post("/meeting/add-meeting", data);
export const getMeetingCategory = (data) =>
  API.get("/meeting/get-category", data);
export const addMeetingCategory = (data) =>
  API.post("/meeting/add-category", data);
export const getMeetingById = (data) =>
  API.post("/meeting/get-meeting-by-id", data);
export const getAllUserFromSociety = (data) =>
  API.post("/management/get-all-users", data);
export const givePermissionMember = (data) =>
  API.post("/member/add-member", data);
export const userCheckForGivePermission = (data) =>
  API.post("/member/user-check", data);
export const getAllMembersFromSociety = (data) =>
  API.post("member/get-all-members", data);
export const fetchUser = (data) => API.post("user/get-user-By-Id", data);
export const getComplaintCategory = (data) =>
  API.post("/management/get-complaint-category", data);
export const getSubComplaintCategory = (data) =>
  API.post("/management/get-subcomplaint-category", data);
export const getAllProperties = (data) => API.post("/visitor/properties", data);

// export const getAllProperties = (data) => API.post("/visitor/properties", data);

export const getAllFlats = (data) => API.post("/visitor/flats", data);
export const getAllFloors = (data) => API.post("/visitor/floors", data);
export const getEscalationProfile = (data) =>
  API.post("/management/get-catgory-autoescalate-levels", data);
export const getQuickViewOtherData = (data) =>
  API.post("/management/quick-view-data", data);
export const countGateManagement = (data) =>
  API.post("/visitor/get-visitor-count", data);
export const addAssetData = (data) => API.post("/assets/add-event", data);
export const editAssetData = (data) => API.post("/assets/edit-event", data);
export const getAssetData = (data) => API.post("/assets/get-event", data);
export const addSlotData = (data) => API.post("/assets/add-slot", data);
export const getSlotData = (data) => API.post("/assets/get-slot", data);
export const getAssetDetails = (data) =>
  API.post("/assets/get-asset-by-id", data);
export const changeStatusforAssetSlot = (data) =>
  API.post("/assets/disable-slot", data);
export const changeAssetOffDay = (data) =>
  API.post("/assets/add-day-off", data);
export const changeAssetMaintenanceDay = (data) =>
  API.post("/assets/set-maintenance-dates", data);
export const changeStatusforAsset = (data) =>
  API.post("/assets/disable-event", data);
export const getSlotDetails = (data) =>
  API.post("assets/get-slot-details", data);
export const changeStatusforSlotBooking = (data) =>
  API.post("assets/change-booking-status", data);
export const getuserModulesWithPermissions = (data) =>
  API.post("member/get-module-permissions", data);
export const getAllPollData = (data) => API.post("poll/getAllPoll", data);
export const addPollData = (data) => API.post("poll/addPoll", data);
export const getVotedBy = (data) => API.post("poll/voteByUser", data);
export const getVotersData = (data) => API.post("poll/getPollById", data);
export const getVotersFromOption = (data) =>
  API.post("poll/optionsDetailByOptionId", data);
export const getOptionDetailsData = (data) =>
  API.post("poll/getOptionDetail", data);
export const getAllGraphData = (data) =>
  API.post("visitor/visitor-graph", data);
export const getOtherStatforgateManagement = (data) =>
  API.post("visitor/totalVisitorData", data);
export const deletePoll = (data) => API.post("poll/deletePoll", data);
export const discussionOtherInfo = (data) =>
  API.post("discussion/populate-prop-view", data);
export const cancelMeeting = (data) => API.post("meeting/cancel-meeting", data);
export const updateMeeting = (data) => API.post("meeting/update-meeting", data);
export const getAccountHeader = (data) =>
  API.post("account/getAccountHeader", data);
export const addAccount = (data) => API.post("account/addAccount", data);
export const getTemplateData = (data) =>
  API.post("account/getAllInvoices", data);
export const addGroup = (data) => API.post("account/addAccountGroup", data);
export const addSubAccount = (data) =>
  API.post("account/addSubAccountHeader", data);
export const addInvoice = (data) => API.post("account/createInvoice", data);
export const getAllResidentRequests = (data) =>
  API.post("user/resident-requests", data);
export const changeResidentRequestsIntoPending = (data) =>
  API.post("management/changeToPendingStatus", data);
export const pendingRequestGraph = (data) =>
  API.post("user/userGraphData", data);
export const AddVisitorType = (data) =>
  API.post("visitor/add-visitor-type", data);
export const AddStaffType = (data) => API.post("staff/add-staff-type", data);
export const getStaffType = (data) => API.get("staff/get-staff-type", data);
export const getFlatHistory = (data) =>
  API.post("user/flat-residing-history", data);
export const getAllAddFlatRequestsFromResident = (data) =>
  API.post("user/findOwnerInFlat", data);
export const getAllResident = (data) => API.post("user/getAllResident", data);
export const getAllRequestHistory = (data) =>
  API.post("user/getRequestHistory", data);
export const getAllComplaintByIds = (data) =>
  API.post("/management/get-complainsByComplainIds", data);
export const addDocument = (data) =>
  API.post("document/create-documents", data);
export const getAllDocuments = (data) =>
  API.post("document/get-AllDocuments", data);
export const getDocumentById = (data) =>
  API.post("document/get-document-by-id", data);
export const createFolder = (data) => API.post("document/create-folder", data);
export const getAllFolder = (data) => API.post("document/get-All-Folder", data);
export const getFolderById = (data) => API.post("document/getFolderById", data);
export const removeFolder = (data) => API.post("document/remove-folder", data);
export const editFolder = (data) => API.post("document/edit-folder", data);
export const documentByFolder = (data) =>
  API.post("document/get-document-byFolderId", data);
export const moveDocument = (data) => API.post("document/move-document", data);
export const removeDocument = (data) =>
  API.post("document/remove-document", data);
export const getGraphData = (data) => API.post("vendor/vendorGraphData", data);
export const deleteNotice = (data) => API.post("notice/delete-notice", data);
export const deleteDiscussion = (data) =>
  API.post("/discussion/delete-discussion", data);
export const getFamilyMember = (data) =>
  API.post("family/getFamilyMember", data);
export const getLocalDirectory = (data) =>
  API.post("/help/get-local-directory", data);
export const addLocalDirectory = (data) =>
  API.post("/help/add-local-directory", data);
export const getLocalDirectoryCategory = (data) =>
  API.post("/help/get-local-DirectoryCategory", data);
export const getEmegencyNumber = (data) =>
  API.post("/help/get-emergency-contacts", data);
export const addEmergencyNumber = (data) =>
  API.post("/help/add-emergency-contacts", data);
export const getStaffCount = (data) => API.post("/staff/staff-count", data);
export const addLocalDirectoryCategory = (data) =>
  API.post("/help/add-local-DirectoryCategory", data);
export const getAllVendor = (data) => API.post("vendor/getAllVendor", data);
export const getTimeline = (data) =>
  API.post("/timeline/get-All-timeline", data);
export const getAdminDetails = (data) =>
  API.post("management/get-managementById", data);
export const editLocalDirectoryCategory = (data) =>
  API.post("/help/editLocalDirectoryCategory", data);
export const deleteLocalDirectoryCategory = (data) =>
  API.post("/help/removeLocalDirectoryCategory", data);
export const deleteLocalDirectoryContact = (data) =>
  API.post("/help/removeLocalDirectory", data);
export const editLocalDirectoryContact = (data) =>
  API.post("/help/edit-local-directory", data);
export const deleteEmergencyNumber = (data) =>
  API.post("/help/delete-emergency-contact", data);
export const editEmergencyNumber = (data) =>
  API.post("/help/edit-emergency-contact", data);
export const editStaffType = (data) => API.post("staff/editStaff", data);
export const deleteStaffType = (data) =>
  API.post("staff/delete-staff-type", data);
export const removeResidentFromFlat = (data) =>
  API.post("management/remove-user-from-flat", data);
export const getAdditionalContacts = (data) =>
  API.post("/management/getEmergencyNumber", data);

export const getManagementAllProperties = (data) =>
  API.post("/management/get-all-properties", data);

export const addEmergencyContactforComplaint = (data) =>
  API.post("/management/add-EmergencyNumberToSociety", data);
export const getSocietyConfiguration = (data) =>
  API.post("/s2admin/get-society-configuration", data);
export const editSocietyConfiguration = (data) =>
  API.post("/s2admin/edit-society-configuration", data);

export const getAdminRequest = (data) =>
  API.post("/s2admin/admin-request", data);

export const approveAdminRequest = (data) =>
  API.post("/s2admin/approve-admin-request", data);

export const getApproveAdminDetails = (data) =>
  API.post("/s2admin/adminDetails", data);

export const approveOrRejectSocietyRequest = (data) =>
  API.post("/s1admin/approveOrReject-admin-society-request", data);

export const societyRequestbyAdmin = (data) =>
  API.post("/s2admin/society-request-by-admin", data);

export const approvedUserChangeAddress = (data) =>
  API.post("/management/resident-change-address-afterUpdate", data);
export const getJMR = (data) => API.post("/jmr/get-jmr", data);
export const freezeFullJMR = (data) =>
  API.post("/jmr/freeze-jmr-by-admin", data);
export const freezeJMRForVendor = (data) =>
  API.post("/jmr/freeze-vendor-jmr-by-admin", data);
export const getJmrItem = (data) => API.get("/jmr/get-items", data);
export const addIteminJmr = (data) => API.post("/jmr/add-item-in-jmr", data);
export const editIteminJmr = (data) => API.post("/jmr/edit-item-in-jmr", data);
export const delteJMRItem = (data) =>
  API.post("/jmr/delte-item-from-jmr", data);

export const getJmrItems = (data) => API.get("jmr/get-items", data);
export const addJmrItems = (data) => API.post("jmr/add-items", data);
export const editJmrItems = (data) => API.post("jmr/edit-items", data);
export const deleteJmrItems = (data) => API.post("jmr/delete-items", data);
export const vendorConfigurationGetProperty = (data) =>
  API.post("vendor/get-sector-wise-property", data);
export const vendorConfiguration = (data) =>
  API.post("vendor/add-multiple-sector-wise-vendor", data);
export const getPrevvendorConfiguration = (data) =>
  API.post("vendor/get-sector-wise-vendor", data);
export const getSectorForAdvanceVendor = (data) =>
  API.post("management/check-sector-for-multiple-vendor", data);
export const getCurrentResidentInFlat = (data) =>
  API.post("user/get-flat-owner-and-tenant", data);
export const editConfiguration = (data) =>
  API.post("management/edit-society", data);
export const getAttendee = (data) =>
  API.post("meeting/get-meeting-attendee", data);

export const getComplaintCount = (data) =>
  API.post("management/complaints-counts", data);

export const getResidentCSV = (data) => API.post("user/csv-user-data", data);
export const deleteCategory = (data) =>
  API.post("management/delete-complaint-category", data);
export const deleteSubCategory = (data) =>
  API.post("management/delete-complaint-subcategory", data);
export const getCurrentResidentInProperty = (data) =>
  API.post("user/get-user-from-property", data);

export const getSubSubComplaintCategory = (data) =>
  API.post("/management/get-sub-sub-category", data);
export const addSubSubCategory = (data) =>
  API.post("/management/add-sub-sub-category", data);
export const deleteSubSubCategory = (data) =>
  API.post("/management/delete-sub-sub-category", data);
export const editSubSubCategory = (data) =>
  API.post("/management/edit-sub-sub-category", data);
