import React from "react";
import AllComplaints from "../../Components/Complaints/AllComplaints/AllComplaints";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import HelpDesk from "../../Components/Complaints/HelpDesk";
import ComplaintReports from "../../Components/Complaints/AllComplaints/ComplaintReports";
import ComplaintDetails from "../../Components/Complaints/ComplaintDetails/ComplaintDetails";
import JMR from "../../Components/Complaints/ComplaintDetails/JMR/JMR";
import AllItem from "../../Components/Complaints/ComplaintDetails/JMR/JMRItems/AllItems";

const HelpdeskRoutes = {
  path: "/complaints",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <HelpDesk />,
    },
    {
      path: "helpdesk",
      element: <HelpDesk />,
    },
    {
      path: "complaints/:complaintType",
      element: <AllComplaints />,
    },
    {
      path: "complaintdetails/:complaintType/:id",
      element: <ComplaintDetails />,
    },
    {
      path: "complaintdetails/:complaintType/:id/:complaintNo/jmr",
      element: <JMR />,
    },
    {
      path: "complaintreports",
      element: <ComplaintReports />,
    },

    {
      path: "jmr-items",
      element: <AllItem />,
    },
  ],
};
export default HelpdeskRoutes;
