import React, { useContext, useEffect, useState } from "react";
import useStyles from "../../ComplaintCategory/styles";
import { Grid, Typography } from "@material-ui/core";
import { AuthContext } from "../../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import ExploreIcon from "@mui/icons-material/Explore";
import SubAreaTable from "./SubAreaTable";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { navigateToComplaintArea } from "../../../../Navigation/Navigations";
import AddSubArea from "./AddSubArea";
import { startApiCall } from "../../../../GlobalFunctions/GlobalFunctions";
import { AreaRoutes } from "../../../../api/Routes";
import ApiCall from "../../../../api/ApiCall";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { Pagination } from "@mui/material";
import BanterLoader from "../../../../GlobalComponents/Loaders/BanterLoader";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";

const SubArea = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id, areaName } = useParams();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [allSubArea, setAllSubArea] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [pageLimit] = useState(30);
  const [addSubArea, setAddSubArea] = useState(false);
  const [editSubarea, setEditSubarea] = useState([]);
  const [isEditSubarea, setisEditSubarea] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Area")
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetAllSubArea = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        area: id,
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", AreaRoutes.getSubArea, data);
    if (res?.success) {
      setAllSubArea(res?.area?.data);
      setCount(res?.area?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllSubArea();
  }, [page]);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        handleGetAllSubArea(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      handleGetAllSubArea("");
    }
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(navigateToComplaintArea());
                      }}
                    >{` > Area `}</span>{" "}
                    <span style={{ color: "#000" }}>{` > Subarea `}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Subarea Page"
                        onClick={handleGetAllSubArea}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "160px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAddSubArea(true);
                          }}
                        >
                          <ExploreIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Subarea
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(navigateToComplaintArea());
                    }}
                  >
                    <div class="card-body">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 30, justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 12,
                  }}
                >
                  Area Name :&nbsp;
                  <span
                    style={{
                      color: "#000",
                      fontSize: 17,
                      textTransform: "capitalize",
                    }}
                  >
                    {areaName}
                  </span>
                </Typography>
                <div
                  class="box"
                  style={{
                    minWidth: "230px",
                    marginLeft: 10,
                    border: "1px solid #E1E1E1",
                    borderRadius: 10,
                  }}
                >
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                    type="text"
                    placeholder="search..."
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </Grid>
              {!loader && allSubArea?.length > 0 && (
                <Grid
                  style={{ marginTop: 16, marginBottom: 10, marginRight: 10 }}
                >
                  <SubAreaTable
                    allSubArea={allSubArea}
                    page={page}
                    pageLimit={pageLimit}
                    setEditSubarea={setEditSubarea}
                    setisEditSubarea={setisEditSubarea}
                    editSubarea={editSubarea}
                    handleGetAllSubArea={handleGetAllSubArea}
                    isReadWrite={isReadWrite}
                  />
                </Grid>
              )}
              {allSubArea?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Subarea
                  </Typography>
                </Grid>
              )}
              {allSubArea?.length > 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
        <AddSubArea
          addSubArea={addSubArea}
          setAddSubArea={setAddSubArea}
          id={id}
          editSubarea={editSubarea}
          isEditSubarea={isEditSubarea}
          setisEditSubarea={setisEditSubarea}
          handleGetAllSubArea={handleGetAllSubArea}
        />
      </main>
      {loader && <BanterLoader />}
    </div>
  );
};

export default SubArea;
