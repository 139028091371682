import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },

  buttonDelete: {
    borderRadius: 5,
    marginTop: theme.spacing(3),
    backgroundColor: "#4b8edb",
    padding: 7,
    fontWeight: "bold",
    fontSize: 15,
    width: 140,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4b8edb",
      color: "#fff",
    },
  },

  /* For the table */
  /* table: {
    minWidth: 450,
  }, */
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.info.dark),
    cursor: "pointer",
    border: "1px solid black",
  },
  textname: {
    color: "black",
    cursor: "pointer",
    fontFamily: "Poppins, sans-serif",
    "&:hover": {
      color: "#2f3aa3",
    },
  },
  name: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.info.main,
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
}));
