import React from "react";
import AllPoll from "../../Components/Communication/Poll/AllPoll";
import PollDetails from "../../Components/Communication/Poll/PollDetails";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";

const PollRoutes = {
    path: "/communication",
    element: <NavigationDrawer />,
    children: [
        {
            path: "allpoll",
            element: <AllPoll />
        },
        {
            path: "polldetails/:id",
            element: <PollDetails />
        }
    ]
}
export default PollRoutes;