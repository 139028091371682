import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Paper, Button, Avatar, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import { TablePagination } from "@mui/material";
import { Modal, Tag } from "antd";
import * as api from "../../../api";
import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { Check } from "@material-ui/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";

function GuardTable(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const { guards, getGuards } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Meeting"
        )
      );
    }
  }, [usermoduleswithpermissions]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (guardId, isApproved) => {
    Modal.confirm({
      title: "Confirm",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
      content: "Are you sure you want to Approve this Guard?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        handleActionRequests(guardId, isApproved);
      },
    });
  };
  const handleActionRequests = async (guardId, isApproved) => {
    try {
      const sendData = {
        guardId,
        societyId: fetchSocietyId(),
        isApproved,
      };
      const res = await api.aproveGuardByAdmin(sendData);
      if (res?.data?.success) {
        getGuards();
        toast.success("Approved Request Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      errorModal(error.response.data.message);
    }
  };
  const errorModal = (error) => {
    Modal.error({
      title: "Error Occured",
      icon: <InfoCircleOutlined style={{ color: "red" }} />,
      content: error,
    });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created Date
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Gate Number
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Guard Type
              </TableCell>
              {isReadWrite && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {guards
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                var date = moment(row.createdAt).format("DD/MM/YYYY - h:mm A");

                return (
                  <TableRow key={row.name}>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {rowsPerPage * page - 1 + (index + 2)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {date}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar alt={row.name} className={classes.avatar} />
                      </Grid>
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row.email ? row.email : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.gateNumber?.map((gate) => {
                        return <Typography>{gate?.gateNumber}</Typography>;
                      })}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row.guardType ? row.guardType : "-"}
                    </TableCell>
                    {isReadWrite && (
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Tag
                          style={{
                            cursor: "pointer",
                            padding: 3,
                            width: row.isApproved ? "100px" : "160px",
                            fontSize: "11px",
                          }}
                          color={row.isApproved ? "green" : "orange"}
                          onClick={() => {
                            if (row.isApproved) {
                              toast.success("Guard is Already Approved", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                              });
                            } else {
                              handleModal(row._id, true);
                            }
                          }}
                          icon={
                            row.isApproved ? (
                              <Check style={{ fontSize: "18px" }} />
                            ) : (
                              <QuestionCircleOutlined
                                style={{ fontSize: "18px" }}
                              />
                            )
                          }
                        >
                          {row.isApproved ? "APPROVED" : "WANT TO APPROVE?"}
                        </Tag>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {guards?.length >= 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={guards?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

export default GuardTable;
