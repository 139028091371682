import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { List, ListItem, Typography } from "@material-ui/core";
import { Grid, Paper, Button, Avatar } from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Tag } from "antd";
import moment from "moment";
import { TimerOff } from "@material-ui/icons";
import { HourglassOutlined, LoadingOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HailIcon from "@mui/icons-material/Hail";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PersonOff from "@mui/icons-material/PersonOff";
import { handleFlatName } from "../../../contextAPI/NamingConvention";
import { navigateToLog } from "../../../Navigation/Navigations";

function VisitorTable(props) {
  const classes = useStyles();

  const { visitors, pleasewait, page, pageLimit } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [moreInfoVisitor, setmoreInfoVisitor] = useState([]);

  const handleOpenDialog = (visitor) => {
    setmoreInfoVisitor(visitor);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Phone
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Type
              </TableCell>

              {/* <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Purpose
              </TableCell> */}
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Creation By / Time
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Entry
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Exit
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {handleFlatName()} Status
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                IN / OUT
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Temp - Mask
              </TableCell>
            </TableRow>
          </TableHead>

          {!pleasewait && (
            <TableBody>
              {visitors?.map((row, index) => {
                if (row.entryTime) {
                  var entryTime = moment(row.entryTime).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }
                if (row.exitTime) {
                  var exitTime = moment(row?.exitTime).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }

                return (
                  <TableRow key={row?._id}>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page - 1) * pageLimit + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar
                          onClick={() => {
                            navigate(
                              navigateToLog({
                                type: "visitor",
                                id: row._id,
                              })
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          alt={row.name}
                          src={
                            row?.image
                              ? row?.image
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                      <Typography
                        onClick={() => {
                          navigate(
                            navigateToLog({
                              type: "visitor",
                              id: row._id,
                            })
                          );
                        }}
                        className={classes.textname}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.visitorType}
                    </TableCell>

                    {/* <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.visitPurpose}
                      </TableCell> */}
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.createdBy?.name ? row?.createdBy?.name : "-"}
                      <span
                        style={{
                          width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class="badge bg-primary"
                      >
                        {row?.createdBy?.role ? row?.createdBy?.role : "-"}
                      </span>
                      <br></br>{" "}
                      <Typography style={{ marginTop: 3, fontSize: 13 }}>
                        {" "}
                        {moment(row?.creationTime).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {entryTime ? entryTime : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {exitTime ? exitTime : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        minWidth: 180,
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Typography style={{ marginRight: 4 }}>
                          {row?.status[0]?.status === "allowed" && (
                            <CheckCircleIcon
                              style={{
                                fontSize: 15,
                                color: "#008000",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status[0]?.status === "pending" && (
                            <AccessTimeFilledIcon
                              style={{
                                fontSize: 17,
                                color: "#ff8800",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status[0]?.status === "reject" && (
                            <DangerousIcon
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status[0]?.status === "expired" && (
                            <TimerOff
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 4,
                              }}
                            />
                          )}
                          {row?.status[0]?.flatNumber}
                        </Typography>
                        <span>
                          <Tag
                            title={`${
                              parseInt(row?.status?.length) - 1
                            } More ${handleFlatName()} Info`}
                            color="blue"
                            onClick={() => {
                              handleOpenDialog(row);
                            }}
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              marginLeft: 3,
                            }}
                          >
                            {row?.status?.length > 1
                              ? "+ " + (parseInt(row?.status?.length) - 1)
                              : ""}
                            <InfoIcon
                              title="More Info"
                              style={{
                                color: "#2f45c5",
                                cursor: "pointer",
                                marginLeft: row?.status?.length > 1 ? 5 : 0,
                                fontSize: 19,
                              }}
                            />
                          </Tag>
                        </span>
                      </Grid>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row.checkInStatus ? (
                        <>
                          {row.checkInStatus === "in" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                              }}
                              color="green"
                              icon={
                                <DirectionsWalkIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                />
                              }
                            >
                              IN
                            </Tag>
                          )}
                          {row.checkInStatus === "out" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                              }}
                              color="red"
                              icon={
                                <HailIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                />
                              }
                            >
                              OUT
                            </Tag>
                          )}
                          {row.checkInStatus === "allowed" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "100px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                              }}
                              color="green"
                              icon={
                                <HailIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                />
                              }
                            >
                              ALLOWED
                            </Tag>
                          )}
                          {row.checkInStatus === "not allowed" && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "140px",
                                fontSize: "11px",
                                marginBottom: 15,
                                fontWeight: "bold",
                              }}
                              color="orange"
                              icon={
                                <PersonOff
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                />
                              }
                            >
                              NOT ALLOWED
                            </Tag>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography>-</Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {row?.temperature ? row?.temperature + "° F" : "-"} -
                      {row?.isMasked ? (
                        <Tag color="#87d068">Yes</Tag>
                      ) : (
                        <Tag color="#cd201f">No</Tag>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!visitors?.length && !pleasewait && (
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              marginTop: 7,
              fontWeight: "bolder",
            }}
          >
            No Visitors
          </Typography>
        </Grid>
      )}

      {pleasewait && (
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <Typography style={{ textAlign: "center", color: "#2f3aa3" }}>
            Loading Visitors....{" "}
            <LoadingOutlined
              style={{ fontSize: 31, color: "#2f3aa3", marginLeft: 6 }}
            />
          </Typography>
        </Grid>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 13,
              fontWeight: "bolder",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Accepted / Rejected by {handleFlatName()} owners
          </Typography>
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 18,
            }}
          >
            Accepted By - {moreInfoVisitor?.totalAllowed}{" "}
          </span>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 18,
            }}
          >
            Rejected By - {moreInfoVisitor?.totalRejected}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Number
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              Action By - Time
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginRight: 5,
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Status
            </Typography>
          </Grid>
          <Grid>
            <List>
              {moreInfoVisitor?.status?.map((flat) => {
                if (flat.actionTime) {
                  var time = moment(flat.actionTime).format(
                    "DD/MM/YYYY - h:mm A"
                  );
                }
                return (
                  <ListItem>
                    <ApartmentIcon
                      style={{
                        fontSize: 15,
                        color: "#6c757d",
                        marginRight: 4,
                        marginLeft: -17,
                      }}
                    />
                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Typography
                        style={{
                          fontSize: 15,
                          marginRight: 20,
                          marginLeft: 5,
                          marginTop: 8,
                          marginBottom: 8,
                        }}
                      >
                        {flat?.flatNumber}
                      </Typography>
                      {flat?.actionBy?.name ? (
                        <Typography
                          style={{
                            fontSize: 13,
                            textAlign: "center",
                            marginRight: 20,
                            marginLeft: 50,
                            marginBottom: 5,
                          }}
                        >
                          {flat?.actionBy?.name ? flat?.actionBy?.name : "-"}(
                          {flat?.actionBy?.role ? flat?.actionBy?.role : "-"})
                          <br></br>
                          {time ? time : "-"}
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            fontSize: 13,
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: 8,
                            marginBottom: 8,
                          }}
                        >
                          No Action
                        </Typography>
                      )}
                      {flat?.status === "allowed" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 13,
                              marginRight: -10,
                              marginLeft: 35,
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            <CheckCircleIcon
                              style={{
                                fontSize: 15,
                                color: "#008000",
                                marginRight: 5,
                              }}
                            />
                            ALLOWED
                          </Typography>
                        </>
                      )}
                      {flat?.status === "reject" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 13,
                              marginRight: -10,
                              marginLeft: 35,
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            <DangerousIcon
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 5,
                              }}
                            />
                            NOT ALLOWED
                          </Typography>
                        </>
                      )}

                      {flat?.status === "pending" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 13,
                              marginRight: -10,
                              marginLeft: 35,
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            <HourglassOutlined
                              style={{
                                fontSize: 17,
                                color: "#ff8800",
                                marginRight: 5,
                              }}
                            />
                            PENDING
                          </Typography>
                        </>
                      )}
                      {flat?.status === "expired" && (
                        <>
                          <Typography
                            style={{
                              fontSize: 13,
                              marginRight: -10,
                              marginLeft: 35,
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            <HourglassOutlined
                              style={{
                                fontSize: 17,
                                color: "#D92402",
                                marginRight: 5,
                              }}
                            />
                            EXPIRED
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default VisitorTable;
