import React from "react";
import AllComplaints from "../../Components/Complaints/AllComplaints/AllComplaints";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import HelpDesk from "../../Components/Complaints/HelpDesk";
import ComplaintReports from "../../Components/Complaints/AllComplaints/ComplaintReports";
import ComplaintCategory from "../../Components/Complaints/ComplaintCategory/ComplaintCategory";
import ComplaintSubCategory from "../../Components/Complaints/ComplaintSubCategory/ComplaintSubCategory";
import ComplaintDetails from "../../Components/Complaints/ComplaintDetails/ComplaintDetails";
import ComplaintSubSubCategory from "../../Components/Complaints/ComplaintSubCategory/ComplaintSubSubCateogry/ComplaintSubSubCategory";

const CategoryRoutes = {
  path: "/complaints",
  element: <NavigationDrawer />,
  children: [
    {
      path: "complaintcategory",
      element: <ComplaintCategory />,
    },

    {
      path: "subcategory/:id/:categoryName",
      element: <ComplaintSubCategory />,
    },

    {
      path: "subsubcategory/:id/:categoryName/:subcategoryId/:subcategoryName",
      element: <ComplaintSubSubCategory />,
    },
  ],
};
export default CategoryRoutes;
