import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import Modal from "@mui/material/Modal";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import * as api from "../../../../../api/index";
import { AddCircle, Close, ErrorOutline } from "@material-ui/icons";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import EditIcon from "@mui/icons-material/Edit";
import { Input } from "antd";

function AddItems(props) {
  const classes = useStyles();
  const {
    addItemModal,
    setAddItemModal,
    isEdited,
    setisEdited,
    editItemData,
    handleItems,
    type,
  } = props;
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemCategory, setitemCategory] = useState("");
  const [itemUOM, setitemUOM] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [itemBasePrice, setitemBasePrice] = useState("");
  const [itemEscalation, setitemEscalation] = useState(0);
  const [itemDescription, setitemDescription] = useState("");
  const [totalAmount, settotalAmount] = useState(0);
  const [loader, setloader] = useState(false);
  const { TextArea } = Input;

  useEffect(() => {
    if (type === "Edited") {
      setItemName(editItemData?.itemName);
      setitemCategory(editItemData?.itemCategory);
      setItemCode(editItemData?.itemCode);
      setitemDescription(editItemData?.itemDescription);
      setitemBasePrice(editItemData?.basePrice);
      setitemUOM(editItemData?.uom);
      setitemEscalation(editItemData?.escalation);
      settotalAmount(
        editItemData?.basePrice * (editItemData?.escalation / 100) +
          editItemData?.basePrice
      );
    }
  }, [isEdited, editItemData, type]);

  useEffect(() => {
    settotalAmount(
      Number(itemBasePrice) +
        (Number(itemBasePrice) * Number(itemEscalation)) / 100
    );
  }, [itemBasePrice, itemEscalation]);

  const handlereset = () => {
    setAddItemModal(false);
    seterrorMsg("");
    setItemName("");
    setitemCategory("");
    setItemCode("");
    setitemDescription("");
    setitemBasePrice("");
    setitemUOM("");
    setitemEscalation(0);
    settotalAmount(0);
    setloader(false);
  };

  const addNewItem = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (itemName.length <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Name");
    } else if (itemCode.length <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Code");
    } else if (itemBasePrice.length <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Base Rate");
    } else if (itemDescription.length <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Description");
    } else if (itemUOM.length <= 0) {
      setloader(false);
      seterrorMsg("Please Enter UOM");
    } else {
      try {
        const sendItemData = {
          societyId: fetchSocietyId(),
          itemName: itemName,
          itemCategory: itemCategory,
          itemCode: itemCode,
          itemDescription: itemDescription,
          basePrice: itemBasePrice,
          uom: itemUOM,
          escalation: itemEscalation,
        };
        const res = await api.addJmrItems(sendItemData);
        if (res?.data?.success) {
          setAddItemModal(false);
          toast.success("Add New Item Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          handleItems();
          handlereset();
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  const editItem = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendItemData = {
        itemId: editItemData?._id,
        societyId: fetchSocietyId(),
        itemName: itemName,
        itemCategory: itemCategory,
        itemCode: itemCode,
        itemDescription: itemDescription,
        basePrice: itemBasePrice,
        uom: itemUOM,
        escalation: itemEscalation,
      };
      const res = await api.editJmrItems(sendItemData);
      if (res?.data?.success) {
        setisEdited(false);
        toast.success("Edit Item Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleItems();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <div>
      <Modal open={addItemModal || isEdited}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            maxWidth: "35vw",
            minWidth: "320px",
            overflow: "auto",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Grid container item xs={8}>
              <Typography
                style={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: "Montserrat, sans-serif",
                  textAlign: "start",
                  marginLeft: 10,
                  fontSize: "1rem",
                  opacity: 1,
                }}
              >
                {type === "Edited" ? "Edit Item" : "Add Item"}
              </Typography>
            </Grid>
            <Grid
              conatiner
              item
              xs={4}
              style={{
                textAlign: "end",
              }}
            >
              {!loader && (
                <IconButton
                  onClick={() => {
                    if (type === "Edited") {
                      setisEdited(false);
                    } else {
                      handlereset();
                    }
                  }}
                >
                  <Close
                    style={{
                      color: "#000",
                      fontSize: 20,
                      marginTop: -10,
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid container style={{ justifyContent: "flex-start" }}>
            <Grid item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Name :&nbsp;
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Item Name"
                onChange={(event) => setItemName(event.target.value)}
                value={itemName}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ justifyContent: "flex-start", marginTop: "8px" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Item Category:&nbsp;
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Item Category"
                onChange={(event) => setitemCategory(event.target.value)}
                value={itemCategory}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Code :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                UOM :&nbsp;
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                type="text"
                placeholder="Enter Code"
                onChange={(event) => setItemCode(event.target.value)}
                value={itemCode}
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                type="text"
                placeholder="Enter UOM"
                onChange={(event) => setitemUOM(event.target.value)}
                value={itemUOM}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Base Price :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Escalation(%) :&nbsp;
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                type="number"
                placeholder="Enter Base Price "
                onChange={(event) => setitemBasePrice(event.target.value)}
                value={itemBasePrice}
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "95%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                type="number"
                placeholder="Enter Escalation"
                onChange={(event) => setitemEscalation(event.target.value)}
                value={itemEscalation}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "10px" }}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: "0.9rem",
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              Total Amount :&nbsp;
            </Typography>
            <input
              disabled
              style={{
                borderRadius: 10,
                padding: 10,
                width: "55%",
                border: "1px solid #416082",
                marginBottom: 2,
                outline: 0,
                color: "#000",
              }}
              type="number"
              placeholder="Total Amount "
              value={totalAmount}
            />
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <TextArea
              placeholder="Description here"
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              onChange={(event) => {
                setitemDescription(event.target.value);
              }}
              value={itemDescription}
              maxLength={250}
            />
          </Grid>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          {type !== "Edited" ? (
            <Grid
              container
              item
              style={{ justifyContent: "center", marginTop: 10 }}
            >
              <div
                class="card"
                style={{
                  height: 30,
                  boxShadow: "0 3px 6px #7D858D",
                  width: "127px",
                  backgroundColor: "#2f45c5",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!loader) {
                    addNewItem();
                  } else {
                    //NOTHING HAPPENED
                  }
                }}
              >
                <div class="card-body ">
                  <p
                    class="card-text"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "-12px",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <>
                      {loader ? (
                        <LoadingOutlined
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      ) : (
                        <AddCircle
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}

                      {!loader ? "Add Item" : `Adding...`}
                    </>
                  </p>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              style={{ justifyContent: "center", marginTop: 10 }}
            >
              <div
                class="card"
                style={{
                  height: 30,
                  boxShadow: "0 3px 6px #7D858D",
                  width: "127px",
                  backgroundColor: "#2f45c5",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!loader) {
                    editItem();
                  } else {
                    //NOTHING HAPPENED
                  }
                }}
              >
                <div class="card-body ">
                  <p
                    class="card-text"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "-12px",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <>
                      {loader ? (
                        <LoadingOutlined
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      ) : (
                        <EditIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}

                      {!loader ? "Edit Item" : `Editing...`}
                    </>
                  </p>
                </div>
              </div>
            </Grid>
          )}
        </Paper>
      </Modal>
    </div>
  );
}

export default AddItems;
