import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Vendors from "../../Components/Vendors/Vendors";
import DeletedVendorPage from "../../Components/Vendors/DeletedVendors";
import VendorProfile from "../../Components/Vendors/VendorDetails/VendorDetails";
import VendorReviewTable from "../../Components/Vendors/VendorDetails/VendorReviewTable";
import VendorComplaints from "../../Components/Vendors/VendorDetails/VendorComplaints";
import ApproveVendor from "../../Components/Vendors/ApproveVendor/ApproveVendor";
import SubVendor from "../../Components/Vendors/SubVendor/SubVendor";

const VendorRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "vendors",
      element: <Vendors />,
    },
    {
      path: "deletedvendors",
      element: <DeletedVendorPage />,
    },
    {
      path: "vendorreviewpage/:id",
      element: <VendorReviewTable />,
    },
    {
      path: "vendorprofile/:id",
      element: <VendorProfile />,
    },
    {
      path: "vendorcomplaints/:status/:id",
      element: <VendorComplaints />,
    },
    {
      path: "vendorapprove",
      element: <ApproveVendor />,
    },
    {
      path: "subvendor/:id",
      element: <SubVendor />,
    },
  ],
};

export default VendorRoutes;
