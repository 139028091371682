import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import SocietyRequestTable from "./SocietyRequestTable";
import useStyles from "../styles";
import { navigateToSocietyManagement } from "../../../../../Navigation/Navigations";
import {
  DomainAddOutlined,
  KeyboardBackspaceRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddSocietyModal from "./AddSocietyModal";
import { AllSocietyRoutes } from "../../../../../api/Routes";
import { AuthContext } from "../../../../../contextAPI/context";
import ApiCall from "../../../../../api/ApiCall";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { handleGetCheckModulePermission } from "../../../../../contextAPI/checkModulesPermission";
import RefreshIcon from "@mui/icons-material/Refresh";

const SocietyRequest = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user, usermoduleswithpermissions } = authState;
  const [addSocietyModal, setaddSocietyModal] = useState(false);
  const [status, setstatus] = useState("Approved");
  const [societyRequests, setsocietyRequests] = useState([]);
  const [loader, setloader] = useState(true);
  const [society, setSociety] = useState("Choose Society");
  const [errorMessage, seterrorMessage] = useState("");
  const [allCount, setallCount] = useState({
    approved: 0,
    pending: 0,
    rejected: 0,
  });
  const [isConfirmResend, setisConfirmResend] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Manage"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const startApiCall = () => {
    seterrorMessage("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const endApiCall = () => {
    seterrorMessage("");
    setSociety("Choose Society");
    setloader(false);
    setisConfirmResend(false);
    setaddSocietyModal(false);
  };
  const handleGetAllSocietyRequest = async () => {
    startApiCall();
    const res = await ApiCall("post", AllSocietyRoutes.getAllSocietyRequests, {
      adminId: user?._id,
      status: status.toLocaleLowerCase(),
    });
    if (res?.success) {
      setsocietyRequests(res?.data?.admin);
      setallCount({
        approved: res?.data?.approved,
        pending: res?.data?.pending,
        rejected: res?.data?.rejected,
      });
      endApiCall();
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllSocietyRequest();
  }, [status]);

  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  const handleAddSocietyRequest = async () => {
    startApiCall();
    const res = await ApiCall("post", AllSocietyRoutes.addSocietyRequests, {
      requestedSocietyId: society,
      adminId: user?._id,
      resend: isConfirmResend,
    });
    if (res?.success) {
      handleGetAllSocietyRequest();
      toast.success(
        isConfirmResend
          ? "Resend Society Request Successfully"
          : "Add Society Request Successfully"
      );
      endApiCall();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginLeft: 8,
                    }}
                  >
                    {`Society Management > `}
                    <span style={{ color: "#000" }}>Society Requests</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Society Request"
                        onClick={handleGetAllSocietyRequest}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container>
                    {isReadWrite && (
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "190px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginRight: 15,
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          setaddSocietyModal(true);
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <DomainAddOutlined
                              style={{
                                marginRight: 10,
                                fontSize: "15px",
                              }}
                            />
                            Add Society Request
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "120px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                      onClick={() => {
                        navigate(navigateToSocietyManagement({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: 13, justifyContent: "center" }}
              >
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={{ fontFamily: "Montserrat, sans-serif" }}
                    value={status}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Approved"
                      control={<Radio style={{ color: "rgb(36 202 38)" }} />}
                      label={`Approved ${allCount?.approved}`}
                    />
                    <FormControlLabel
                      value="Pending"
                      control={<Radio style={{ color: "rgb(202 133 36)" }} />}
                      label={`Pending ${allCount?.pending}`}
                    />
                    <FormControlLabel
                      value="Rejected"
                      control={<Radio style={{ color: "rgb(239 13 39)" }} />}
                      label={`Rejected ${allCount?.rejected}`}
                    />
                  </RadioGroup>
                </Grid>
                {societyRequests?.length > 0 && !loader && (
                  <SocietyRequestTable
                    status={status}
                    societyRequests={societyRequests}
                    isConfirmResend={isConfirmResend}
                    setisConfirmResend={setisConfirmResend}
                    handleAddSocietyRequest={handleAddSocietyRequest}
                    loader={loader}
                    errorMessage={errorMessage}
                    seterrorMessage={seterrorMessage}
                    setSociety={setSociety}
                  />
                )}
              </Grid>
              {societyRequests?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No {status} Requests
                  </Typography>
                </Grid>
              )}
              {loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    Loading.... <LoadingOutlined />
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
        {addSocietyModal && (
          <AddSocietyModal
            addSocietyModal={addSocietyModal}
            endApiCall={endApiCall}
            loader={loader}
            society={society}
            setSociety={setSociety}
            errorMessage={errorMessage}
            handleAddSocietyRequest={handleAddSocietyRequest}
          />
        )}
      </main>
    </div>
  );
};

export default SocietyRequest;
