import React, { useState, useContext } from "react";
import * as api from "../../../../../api/index";
import useStyles from "../../../styles";
import { Typography, Grid, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { handleGetCheckModulePermission } from "../../../../../contextAPI/checkModulesPermission";
// import { AuthContext } from "../../../../../contextAPI/context";
import HistoryTable from "./HistoryTable";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Loading from "../../../../../contextAPI/Loading";
import SearchIcon from "@mui/icons-material/Search";
import { resolveErrorMessage } from "../../../../../services/service";
import { navigateToResident } from "../../../../../Navigation/Navigations";

const RequestHistory = () => {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();
  // const { authState, authDispatch } = useContext(AuthContext);
  // const { usermoduleswithpermissions,user } = authState;
  const { flatId, societyName, flatName, propertyId, propertyName } =
    useParams();
  const [historyData, sethistoryData] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const [filterStatus, setfilterStatus] = useState("All");
  // useEffect(async () => {
  //   if (usermoduleswithpermissions) {
  //     setIsReadWrite(
  //       await handleGetCheckModulePermission(
  //         usermoduleswithpermissions,
  //         "Settings"
  //       )
  //     );
  //   }
  // }, [usermoduleswithpermissions]);

  const getResidentHistory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        pageLimit: 50,
        searchKey: search,
        status: filterStatus === "All" ? "" : filterStatus,
      };
      const res = await api.getAllRequestHistory(sendData);
      if (res?.data?.success) {
        setCount(res?.data?.user?.count);
        sethistoryData(res?.data?.user?.residentHistory);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error)
    }
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getResidentHistory();
  }, [page, search, filterStatus]);

  return (
    <>
      <div
        className={classes.root}
        style={{
          filter: loader ? "blur(1px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid item lg={12} sm={12} xs={12}>
            <div className="card" style={{ borderRadius: 10 }}>
              <div className="card-body" style={{ borderRadius: 10 }}>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                      }}
                    >
                      Request History
                      {!loader ? (
                        <RefreshIcon
                          title="refresh History Page"
                          onClick={getResidentHistory}
                          style={{
                            marginLeft: 5,
                            marginTop: -4,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: -4,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={8}
                    lg={8}
                    md={8}
                    item
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div class="box" style={{ marginTop: "-5px" }}>
                      <input
                        type="text"
                        placeholder="search history"
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setPage(1);
                        }}
                        value={search}
                      />
                      <div>
                        <SearchIcon />
                      </div>
                    </div>
                    <Select
                      onChange={(event) => {
                        setfilterStatus(event.target.value);
                        setPage(1);
                      }}
                      style={{
                        color: "#212529",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        borderRadius: 6,
                        width: "160px",
                        height: 34,
                        fontSize: "13px",
                        padding: 8,
                        border: "1px solid #6c757d",
                        textAlign: "center",
                        marginRight: 10,
                      }}
                      value={filterStatus}
                    >
                      <MenuItem
                        selected
                        value="All"
                        style={{
                          color: "#000",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        selected
                        value="approved"
                        style={{
                          color: "#000",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        Approved
                      </MenuItem>
                      <MenuItem
                        selected
                        value="rejected"
                        style={{
                          color: "#000",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        Rejected
                      </MenuItem>
                    </Select>
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "90px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                    navigate(navigateToResident({status:"pending"}))

                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  style={{ justifyContent: "space-between", marginTop: 18 }}
                >
                   <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 14,
                    marginLeft: 8,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <TravelExploreIcon />
                  &nbsp; total search result{" "}
                  <span style={{ fontSize: 15 }}>{historyData?.totalPages}</span> history
                </Typography>
               
                </Grid> */}

                <Grid container style={{ marginTop: 15 }}>
                  <HistoryTable
                    historyData={historyData}
                    flatName={flatName}
                    page={page}
                    count={count}
                  />
                </Grid>
                {historyData?.length === 0 && !loader && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontSize: 17,
                        marginTop: 7,
                        fontWeight: "bolder",
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      No History Available 
                    </Typography>
                  </Grid>
                )}

                {historyData?.length > 0 && (
                  <Grid container style={{ justifyContent: "center", marginTop: 20, marginBottom: 20, }}>
                    <Pagination
                      className={classes.pagination}
                      count={count}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </main>
      </div>
      <Loading loader={loader} />
    </>
  );
};

export default RequestHistory;
