import { Grid } from "@mui/material";
import React from "react";
import "./signal.css";

function SignalLoader() {
  return (
    <Grid
      style={{
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "30%",
        transform: "translate(-50%, -50%)",
      }}
      className="d-flex"
    >
      <div className="loader socio">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            S
          </text>
        </svg>
      </div>

      <div className="loader socio">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            o
          </text>
        </svg>
      </div>

      <div className="loader socio">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            c
          </text>
        </svg>
      </div>

      <div className="loader socio">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            i
          </text>
        </svg>
      </div>
      <div className="loader socio">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            o
          </text>
        </svg>
      </div>
      <div className="loader hood">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            H
          </text>
        </svg>
      </div>
      <div className="loader hood">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            o
          </text>
        </svg>
      </div>
      <div className="loader hood">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            o
          </text>
        </svg>
      </div>
      <div className="loader hood">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
          <text
            x="50%"
            y="60%"
            text-anchor="middle"
            fill="#03045E"
            font-size="24"
            font-weight="bold"
          >
            d
          </text>
        </svg>
      </div>
    </Grid>
  );
}

export default SignalLoader;
