import React, { useState } from "react";
import { Typography, Grid, Paper, CssBaseline } from "@material-ui/core";
import useStyles from "../styles";
import Gaurd from "../Guard/Guard";
import Loading from "../../../contextAPI/Loading";
import Type from "../Type/Type";
import CustomizedTab from "../../../GlobalComponents/CustomizedTab/CustomizedTab";

function AllSettings() {
  const classes = useStyles();
  const [selectedHeader, setselectedHeader] = React.useState("All Guards");
  const [loader, setloader] = useState(false);

  const settingHeader = ["All Guards", "Visitor Type", "Staff Type"];

  return (
    <>
      <div
        className={classes.root}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <CssBaseline />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  {`Gate Management > `}
                  <span style={{ cursor: "pointer", color: "#000" }}>
                    Settings
                  </span>
                </Typography>
                <CustomizedTab
                  headers={settingHeader}
                  selectedHeader={selectedHeader}
                  setselectedHeader={setselectedHeader}
                />
                <Grid style={{ marginTop: 10 }}>
                  {selectedHeader === "All Guards" && (
                    <Gaurd setloader={setloader} loader={loader} />
                  )}
                  {selectedHeader === "Visitor Type" && (
                    <Type
                      type="visitor"
                      setloader={setloader}
                      loader={loader}
                    />
                  )}
                  {selectedHeader === "Staff Type" && (
                    <Type type="staff" setloader={setloader} loader={loader} />
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
      <Loading loader={loader} />
    </>
  );
}
export default AllSettings;
