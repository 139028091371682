import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import MemberCommittee from "../../MemberCommittee/MemberCommittee";
import AddMemberCommittee from "../../MemberCommittee/AddMemberCommittee";
import ReadWriteAccess from "../../MemberCommittee/ReadWriteAccess";

const MemberRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "membercommittee",
      element: <MemberCommittee />,
    },
    {
      path: "addmember",
      element: <AddMemberCommittee />,
    },
    {
      path: "readwriteaccess/:id",
      element: <ReadWriteAccess />,
    },
  ],
};
export default MemberRoutes;
