import React from "react";
import { Typography, Grid, Button, List, ListItem } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { handlePropertyName } from "./NamingConvention";

function PropertyInfo(props) {
  const { openPropertyInfoModal, setopenPropertyInfoModal, propertyInfo } =
    props;
  const handleCloseDialog = () => {
    setopenPropertyInfoModal(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={openPropertyInfoModal}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              fontWeight: "bolder",
            }}
          >
            {handlePropertyName()} Information
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                Sr. No.
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 20,
                }}
              >
                {handlePropertyName()} Name
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000000",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 40,
                }}
              >
                {handlePropertyName()} Type - Sector
              </Typography>
            </Grid>
            <Grid>
              <List>
                {propertyInfo?.map((prop, index) => {
                  return (
                    <ListItem>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                        }}
                      >
                        {index + 1}.&nbsp;&nbsp;
                      </Typography>

                      <Grid container>
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontSize: 15,
                            marginLeft: 40,
                            textTransform: "capitalize",
                            marginTop: -4,
                          }}
                        >
                          {prop?.name}
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 5,
                          marginLeft: -10,
                          width: "80px",
                        }}
                      >
                        {prop?.type} - {prop?.sector}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </>
          {/* {loader2 && (
    <Typography
      style={{
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "Montserrat, sans-serif",
        color: "#6c757d",
        textAlign: "center",
      }}
    >
      Loading ...
      <LoadingOutlined
        style={{ fontSize: 20, color: "#2f45c5", marginLeft: 12 }}
      />
    </Typography>
  )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PropertyInfo;
