import React, { useState } from "react";
import OtpInput from "otp-input-react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { LoadingOutlined } from "@ant-design/icons";

const ResolveComplaint = (props) => {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    setupdateProcecssModal,
    user,
    classes,
    handlegetComplaintById,
  } = props;

  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [OTP, setOTP] = useState("");
  
  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setOTP("");
    seterrorMessage("");
    setloader(false);
  };

  const handleResolveComplaint = async () => {
    if (OTP.trim() === "") {
      seterrorMessage("Please enter resolve OTP!");
    } else {
      callingApi();
      const sendDataForLifeCycle = {
        complaintId: complaint?._id,
        complainerId: complaint?.complainer?._id,
        complaintLifeCycle: "Mark as resolved",
        adminId: user?._id,
        otp: OTP,
      };
      const sendDataForResolve = {
        complaintId: complaint?._id,
        status: "resolved",
        otp: OTP,
      };
      const res2 = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.complaintLifeCycle,
        sendDataForLifeCycle
      );
      if (res2?.success) {
        const res = await ApiCall(
        "post",
          ComplaintUpdateProcessRoutes.resolveOrCloseComplaint,
          sendDataForResolve
        );
        handleReset();
        toast.success("Complaint Resolved Successfully");
      } else {
        setloader(false);
        seterrorMessage(res2.error);
      }
    }
  };

  return (
    <Modal open={selectProcess === "Mark As Resolved"}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "auto",
          minWidth: "300px",
          maxWidth: "32vw",
          pointerEvents: loader ? "none" : ""
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {selectProcess}
          </Typography>

          <IconButton
            onClick={() => {
              if (!loader) {
                seterrorMessage("");
                setOTP("");
                setselectProcess("Select Process");
              }
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}{" "}
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              color: "#",
              fontWeight: "bold",
              textAlign: "center",
              textTransform: "capitalize",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Complaint Current Status :{" "}
            {complaint?.status === "unassigned" && (
              <span
                style={{
                  padding: 7,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                class={"badge bg-warning"}
              >
                {complaint?.status}
              </span>
            )}
            {complaint?.status === "assigned" && (
              <span
                style={{
                  padding: 7,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                class={"badge bg-info"}
              >
                {complaint?.status}
              </span>
            )}
            {complaint?.status === "resolved" && (
              <span
                style={{
                  padding: 7,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                class={"badge bg-success"}
              >
                {complaint?.status}
              </span>
            )}
            {complaint?.status === "closed" && (
              <span
                style={{
                  padding: 7,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                class={"badge bg-danger"}
              >
                {complaint?.status}
              </span>
            )}
            {complaint?.status === "cancel" && (
              <span
                style={{
                  padding: 7,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                class={"badge bg-danger"}
              >
                {complaint?.status}
              </span>
            )}
          </Typography>
        </Grid>
        <Typography
          style={{
            color: "#283f59",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 16,
            marginBottom: 6,
            marginTop: 16,
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          Enter 6 digit OTP
        </Typography>
        <Grid container style={{ justifyContent: "center", marginTop: 16 }}>
          <OtpInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
        </Grid>
        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={() => {
              handleResolveComplaint();
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  ""
                )}
                {!loader ? "Submit" : `Submitting...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ResolveComplaint;
