import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchAddress from "../../../../GlobalComponents/SearchAddress/SearchAddress";
import { Close } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { getSocietyFloors } from "../../../../GlobalFunctions/GlobalFunctions";
import { toast } from "react-toastify";
import { handleFloorName } from "../../../../contextAPI/NamingConvention";

const FloorModal = ({
  floorModal,
  setFloorModal,
  setPermissionType,
  setSelectedFloors,
  selectedFloors,
  setSelectAllFloors,
  selectAllFloors,
}) => {
  const classes = useStyles();
  const [property, setproperty] = useState("Select Property");
  const [searchKey, setsearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [allFloors, setallFloors] = useState([]);

  const handleGetFloors = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    const res = await getSocietyFloors({ propertyId: property?._id });
    setallFloors(res?.allFloors);
    setLoading(false);
  };

  useEffect(() => {
    if (property?._id) {
      handleGetFloors();
    }
  }, [property]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelectedFloors = allFloors.map((floor) => ({
        name: floor.name,
        _id: floor._id,
        propertyName: property?.name,
        propertyId: property?._id,
      }));

      setSelectedFloors([
        ...selectedFloors.filter(
          (selected) => selected.propertyId !== property?._id
        ),
        ...newSelectedFloors,
      ]);
      setSelectAllFloors(true);
    } else {
      setSelectedFloors(
        selectedFloors.filter(
          (selected) => selected.propertyId !== property?._id
        )
      );
      setSelectAllFloors(false);
    }
  };

  const handleCheckboxChange = (floor) => {
    const isSelected = selectedFloors.some(
      (selected) =>
        selected._id === floor._id && selected.propertyId === property?._id
    );

    const newSelectedFloors = isSelected
      ? selectedFloors.filter(
          (selected) =>
            !(
              selected._id === floor._id &&
              selected.propertyId === property?._id
            )
        )
      : [
          ...selectedFloors,
          {
            name: floor.name,
            _id: floor._id,
            propertyName: property?.name,
            propertyId: property?._id,
          },
        ];
    setSelectedFloors(newSelectedFloors);
    setSelectAllFloors(newSelectedFloors?.length === allFloors?.length);
  };

  useEffect(() => {
    const hasSelectedFloorsForProperty = selectedFloors.some(
      (selected) => selected.propertyId === property?._id
    );

    setSelectAllFloors(
      hasSelectedFloorsForProperty &&
        selectedFloors.filter(
          (selected) => selected.propertyId === property?._id
        ).length === allFloors.length
    );
  }, [property, selectedFloors, allFloors]);

  return (
    <Modal open={floorModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: selectedFloors?.length > 0 ? "800px" : "300px",
          maxWidth: selectedFloors?.length > 0 ? "75vw" : "35vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select Floor
          </Typography>
          <IconButton
            onClick={() => {
              setFloorModal(false);
              setPermissionType("Choose Type");
              setSelectedFloors([]);
              setSelectAllFloors(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <SearchAddress
            searchKey={searchKey}
            setsearchKey={setsearchKey}
            setPropertyId={setproperty}
            propertyId={property}
            isMultiple={false}
          />
        </Grid>
        {/* {allFloors?.length > 0 && ( */}
        <Grid
          container
          style={{
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Grid item xs={selectedFloors?.length > 0 ? 8 : 12}>
            {!loading && allFloors?.length > 0 && (
              <TableContainer
                component={Paper}
                className="scrollable"
                style={{ overflowY: "scroll", height: "40vh" }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="text-nowrap"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          className="form-check-input "
                          onChange={handleSelectAll}
                          checked={selectAllFloors}
                        />
                        &nbsp; Sr. No.
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allFloors?.map((flr, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              className="form-check-input"
                              checked={selectedFloors.some(
                                (selected) => selected._id === flr._id
                              )}
                              onChange={() => handleCheckboxChange(flr)}
                            />
                            &nbsp;&nbsp;
                            {index + 1}.
                          </TableCell>
                          <TableCell
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: "normal",
                            }}
                          >
                            {flr?.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {loading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </div>
            )}
          </Grid>
          {selectedFloors?.length > 0 && (
            <Grid item xs={4}>
              <div
                style={{
                  color: "#6c757d",
                }}
                className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
              >
                Selected {handleFloorName()} : {selectedFloors?.length}
              </div>
              <div
                className="d-flex flex-column gap-3 overflow-auto scrollable p-3"
                style={{ height: "calc(60vh - 40px)" }}
              >
                {selectedFloors?.map((flr, index) => {
                  return (
                    <p
                      className="mb-0 text-dark"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {index + 1}. {flr?.propertyName}, {flr?.name}
                    </p>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>
        {/* )} */}

        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor:
                selectedFloors?.length > 0 ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (selectedFloors?.length > 0) {
                setFloorModal(false);
              } else {
                toast.error(`Please Select ${handleFloorName()}`);
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default FloorModal;
