import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Paper, Tooltip } from "@material-ui/core";
import useStyles from "../style";
import { Cancel, CheckCircle, Circle, RestartAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import moment from "moment";
import ConfirmModal from "../../contextAPI/ConfirmModal";
import { resolveErrorMessage } from "../../services/service";
import * as api from "../../api/index";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { useNavigate } from "react-router";
import { navigateToReadWriteAccess } from "../../Navigation/Navigations";
import { toast } from "react-toastify";

function SocietyRequestTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    societyRequests,
    status,
    isConfirmResend,
    setisConfirmResend,
    handleGetAllSocietyRequestForAdmin,
    setloader,
    loader,
  } = props;
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedStatus, setselectedStatus] = useState("");
  const [IsApprove, setIsApprove] = useState(false);
  const [societyData, setsocietyData] = useState([]);

  const handleRedirectPage = () => {
    navigate(navigateToReadWriteAccess({ memberId: societyData?._id }), {
      state: "societyRequest",
    });
  };

  const handleClose = () => {
    setisConfirmResend(false);
  };
  const handleConfirm = () => {
    setisConfirmResend(false);
  };

  const handleApproveSociety = () => {
    handleRedirectPage();
    setIsApprove(false);
  };
  const handleRejectSociety = () => {
    handleRejectSocietyRequest();
    setIsApprove(false);
  };

  const handleCloseSocietyApproval = () => {
    setIsApprove(false);
  };

  const handleStatusChange = (status) => {
    setselectedStatus(status);
    seterrorMessage("");
    setIsApprove(true);
  };

  const startApiCall = () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleRejectSocietyRequest = async () => {
    startApiCall();
    seterrorMessage("");
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        adminId: societyData?._id,
        isApproved: false,
      };
      const res = await api.approveOrRejectSocietyRequest(sendData);
      if (res?.data?.success) {
        toast.success("Scoiety has been rejected successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setloader(false);
        handleGetAllSocietyRequestForAdmin();
      }
    } catch (error) {
      setloader(false);
      seterrorMessage(resolveErrorMessage(error));
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Email
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Contact
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {status === "Pending" ? "Requested" : "Status"} Time
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                City
              </TableCell>
              {status !== "Pending" && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Status
                </TableCell>
              )}

              {status === "Pending" && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {societyRequests?.map((society, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.admin?.name}
                    <br />({society?.admin?.role})
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.admin?.email}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.admin?.phone}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {moment(society?.actionTime).format("DD/MM/YYYY - h:mm A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {society?.society?.city}
                  </TableCell>
                  {status !== "Pending" && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      {society?.status === "approved" && (
                        <Typography
                          style={{
                            color: "rgb(36 202 38)",
                            fontWeight: "bold",
                            fontSize: 12,
                            marginTop: 4,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <Circle style={{ fontSize: 15, marginRight: 6 }} />
                          &nbsp;APPROVED
                        </Typography>
                      )}
                      {society?.status === "rejected" && (
                        <Typography
                          style={{
                            color: "rgb(239 13 39)",
                            fontWeight: "bold",
                            fontSize: 12,
                            marginTop: 4,
                          }}
                        >
                          <Circle style={{ fontSize: 15, marginRight: 6 }} />
                          &nbsp;REJECTED
                        </Typography>
                      )}
                    </TableCell>
                  )}

                  {status === "Pending" && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Approve Request" placement="top" arrow>
                          <CheckCircle
                            style={{
                              color: "green",
                              fontSize: "27px",
                            }}
                            onClick={() => {
                              handleStatusChange("APPROVE");
                              setsocietyData(society?.admin);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Reject Request" placement="top" arrow>
                          <Cancel
                            style={{
                              color: "red",
                              fontSize: "27px",
                              marginLeft: 4,
                            }}
                            onClick={() => {
                              handleStatusChange("REJECT");
                              setsocietyData(society?.admin);
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={
          status === "Pending"
            ? `ARE YOU SURE YOU WANT TO ${selectedStatus} THIS REQUEST`
            : `ARE YOU SURE YOU WANT TO RESEND THIS REQUEST`
        }
        description="You can confirm by clicking below."
        loader={loader}
        handleConfirmation={
          status === "Pending"
            ? selectedStatus === "APPROVE"
              ? handleApproveSociety
              : handleRejectSociety
            : handleConfirm
        }
        rejectConfirmation={
          status === "Pending" ? handleCloseSocietyApproval : handleClose
        }
        isOpen={status === "Pending" ? IsApprove : isConfirmResend}
        errorMsg={errorMessage}
      />
    </>
  );
}

export default SocietyRequestTable;
