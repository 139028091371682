import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyle from "./styles";
import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import AddJmritem from "./AddJmrItem";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../services/service";
import * as api from "../../../../api/index";
import { MessageOutlined } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function JmrTable(props) {
  const { jmrItem, jmrData, handleGetJmr, jmr } = props;
  const classes = useStyle();
  const [jmeEditModal, setjmeEditModal] = useState(false);
  const [jmrtableitem, setjmrtableitem] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [vendorId, setvendorId] = useState([]);

  const reversedItemDetails = jmr?.itemDetails?.slice().reverse();

  const handleDeleteJmr = async (itemID) => {
    try {
      let sendData = {
        itemId: itemID,
        jmrId: jmrData?._id,
        vendorId: vendorId,
      };
      const res = await api.delteJMRItem(sendData);
      if (res.data.success) {
        handleGetJmr();
        toast.info("JMR Item has been Dleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleConfirmdelelte = (itemID) => {
    Modal.confirm({
      title: "Confirm",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      content: "Are you sure you want to Delete this item?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        handleDeleteJmr(itemID);
      },
    });
  };
  const handleSeeDescription = (description) => {
    Modal.confirm({
      title: "Item Description",
      icon: <MessageOutlined style={{ fontSize: 20, color: "blue" }} />,
      content: description,
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Location
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sub Location
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Item Code
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Item Description
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Item UOM
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Rate
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                M/F
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Nos
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Length
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Breadth
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                D/H
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Quantity
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Amount
              </TableCell>
              {jmrData?.isFinalFreezed === false && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {reversedItemDetails.map((data, index) => {
              return (
                <TableRow
                // key={row.name}
                >
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {jmrData?.location}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.subLocation}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.itemCode}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.itemDescription.length > 8 ? (
                      <>
                        {data?.itemDescription.slice(0, 8)}
                        <MessageOutlined
                          onClick={() => {
                            handleSeeDescription(data?.itemDescription);
                          }}
                          style={{
                            marginLeft: 6,
                            cursor: "pointer",
                            color: "blue",
                            fontSize: 16,
                          }}
                        />
                      </>
                    ) : (
                      data?.itemDescription
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.itemUom}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.basePrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.mf}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.nos}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.itemLength}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.itembreadth}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.height}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.quantity}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    {data?.totalPriceForItem}
                  </TableCell>
                  {jmrData?.isFinalFreezed === false && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "#2f45c5",
                        }}
                        onClick={() => {
                          setjmeEditModal(true);
                          setjmrtableitem(data);
                          setvendorId(jmr?.vendor?._id);
                        }}
                      />{" "}
                      <DeleteIcon
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "red",
                        }}
                        onClick={() => {
                          setvendorId(jmr?.vendor?._id);
                          handleConfirmdelelte(data?._id);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <AddJmritem
        jmeEditModal={jmeEditModal}
        setjmeEditModal={setjmeEditModal}
        jmrItem={jmrItem}
        jmrData={jmrData}
        handleGetJmr={handleGetJmr}
        type={"Edit"}
        jmrtableitem={jmrtableitem}
        vendorId={vendorId}
      />
    </>
  );
}

export default JmrTable;
