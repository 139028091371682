import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@material-ui/core";
import { handleFloorName } from "../../contextAPI/NamingConvention";
import { getSocietyFloors } from "../../GlobalFunctions/GlobalFunctions";

function ChooseFloorDropDown(props) {
  const { property, floor, setfloor } = props;

  const [loading, setLoading] = useState(false);
  const [allFloors, setallFloors] = useState([]);

  useEffect(() => {
    if (property?._id) {
      handleGetFloors();
    }
  }, [property]);

  const handleGetFloors = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);

    const res = await getSocietyFloors({ propertyId: property?._id });

    setallFloors(res?.allFloors);
    setLoading(false);
  };

  useEffect(() => {
    if (floor && allFloors.length > 0) {
      const selectedFloor = allFloors?.find(flr => flr.name === floor.name);
      if (selectedFloor) {
        setfloor(selectedFloor);
      }
    }
  }, [allFloors]);

  return (
    <Grid container item xs={12}>
      <Select
        value={floor?.name || "Select Floor"}
        onChange={(event) => {
          const selectedFloor = allFloors?.find(flr => flr.name === event.target.value);
          setfloor(selectedFloor || "Select Floor");
        }}
        style={{
          display: "block",
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          backgroundColor: "#fff",
          borderRadius: 6,
          fontSize: 16,
          width: "97%",
          padding: 8,
          paddingLeft: 20,
          height: 52,
          outline: 0,
          border: "1px solid #283f59",
        }}
        disabled={!property?._id || loading}
      >
        <MenuItem value="Select Floor">
          Select {handleFloorName()}{" "}
          {property?._id && allFloors?.length > 0 ? `(${allFloors?.length})` : ""}
        </MenuItem>
        {allFloors.map((flr) => (
          <MenuItem key={flr._id} value={flr.name}>
            {flr.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}

export default ChooseFloorDropDown;
