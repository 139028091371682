import React from "react";
import useStyles from "../../styles";
import { Grid, Avatar, Typography, Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const VendorApproveRequest = ({ allApprovedVendor, page }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Time
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Email
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Phone
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Category
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Sub Category
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allApprovedVendor?.map((vendor, index) => {
            return (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "capitalize",
                  }}
                >
                  <Grid container style={{ justifyContent: "center" }}>
                    <Avatar
                      alt={vendor?.name}
                      src={
                        vendor?.image
                          ? vendor?.image
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      className={classes.avatar}
                    />
                  </Grid>
                  <Typography className={classes.textname}>
                    {vendor?.name}
                  </Typography>

                  <span
                    style={{
                      width: vendor?.isSubVendor ? "80px" : "58px",
                      padding: 5,
                      textTransform: "capitalize",
                      marginLeft: 5,
                    }}
                    class="badge bg-primary"
                  >
                    {vendor?.isSubVendor ? "Sub-Vendor" : "Vendor"}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {moment(vendor?.actionTime).format("DD/MM/YYYY - h:mm A")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {vendor?.email}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {vendor?.phone}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {vendor?.category ? vendor?.category?.categoryName : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {vendor?.subCategory
                    ? vendor?.subCategory?.subcategoryName
                    : "-"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VendorApproveRequest;
