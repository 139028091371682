import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../ComplaintCategory/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Cancel, CheckCircle, ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Typography, Grid, Paper, Tooltip } from "@material-ui/core";
import DeadlineApprovalModal from "./DeadlineApprovalModal";
import InfoIcon from "@mui/icons-material/Info";
import { navigateToComplaintDetails } from "../../../Navigation/Navigations";
import { useNavigate } from "react-router";

const ApprovalTable = ({
  isReadWrite,
  setActionTypeModal,
  handleApproveComplaint,
  loader,
  allRequest,
  page,
  setcomplaintData,
  complaintData,
  errorMessage,
  setIsConfirm,
  isConfirm,
  rejectConfirmation,
  deadline,
  setdeadline,
  feedback,
  setfeedback,
  reason,
  setreason,
  stars,
  setstars,
  imageTest,
  setimageTest,
  loader2,
  OTP,
  setOTP,
  limit
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const statusClasses = {
    unassigned: "badge bg-warning",
    assigned: "badge bg-info",
    resolved: "badge bg-success",
    closed: "badge bg-danger",
    cancel: "badge bg-danger",
  };

  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(date).toLocaleString("en-GB", options).replace(",", " -");
  };

  const calculateDeadline = (createdAt, deadlineHours) => {
    const createdDate = new Date(createdAt);
    createdDate?.setHours(createdDate?.getHours() + Number(deadlineHours));
    return createdDate;
  };
  const calculateExtendedDeadline = (
    createdAt,
    deadlineHours,
    extendedHours
  ) => {
    const deadlineDate = calculateDeadline(createdAt, deadlineHours);
    deadlineDate?.setHours(deadlineDate?.getHours() + Number(extendedHours));
    return deadlineDate;
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="text-nowrap">
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Title
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Vendor
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Deadline
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Current Status
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Requested Time / hours
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Reason
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Request Type
              </TableCell>
              {isReadWrite && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allRequest?.map((complaint, index) => {
              const deadline = calculateDeadline(
                complaint?.complaintId?.createdAt,
                complaint?.complaintId?.deadline
              );
              const extendedDeadline = calculateExtendedDeadline(
                complaint?.complaintId?.createdAt,
                complaint?.complaintId?.deadline,
                complaint?.extendedDeadline
              );
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 40 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    onClick={() => {
                      navigate(
                        navigateToComplaintDetails({
                          complaintType: "all",
                          complaintId: complaint?.complaintId?._id,
                        })
                      );
                    }}
                  >
                    <Grid container style={{ marginLeft: -25, width: 140 }}>
                      <Typography
                        style={{
                          textDecoration: "underline",
                          fontSize: 13,
                          cursor: "pointer",
                          color: "#42628a",
                          fontWeight: "bold",
                        }}
                      >
                        {complaint?.complaintId?.complaintNumber}
                      </Typography>
                      <CopyToClipboard
                        text={complaint?.complaintId?.complaintNumber}
                        onCopy={() => {
                          toast.success("Copy to Clipboard", {
                            position: "top-right",
                            autoClose: 500,
                            hideProgressBar: false,
                            closeOnClick: true,
                          });
                        }}
                      >
                        <ContentCopy
                          style={{
                            fontSize: 12,
                            marginTop: 3,
                            marginLeft: 3,
                            color: "#5a80ad",
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {complaint?.complaintId?.complaintTitle?.length > 35
                      ? complaint?.complaintId?.complaintTitle
                          .slice(0, 35)
                          .concat("...")
                      : complaint?.complaintId?.complaintTitle}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {complaint?.raisedBy?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {formatDate(deadline)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        padding: 7,
                        fontSize: 10,
                        textTransform: "capitalize",
                      }}
                      className={statusClasses[complaint.complaintId?.status]}
                    >
                      {complaint.complaintId?.status}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {complaint?.extendedDeadline
                      ? `${formatDate(extendedDeadline)} (${
                          complaint?.extendedDeadline
                        } hrs) `
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "blue",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      onClick={() => {
                        setcomplaintData(complaint?.reason);
                        setActionTypeModal(true);
                      }}
                    >
                      <InfoIcon />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {complaint?.requestType === "deadline extension"
                      ? "Deadline extension"
                      : "Close complaint"}
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid
                        container
                        style={{ justifyContent: "center", cursor: "pointer" }}
                      >
                        <Tooltip title="Approve Request" placement="top" arrow>
                          <CheckCircle
                            onClick={() => {
                              setcomplaintData({
                                data: complaint,
                                status: "approved",
                              });
                              setIsConfirm(true);
                            }}
                            style={{
                              color: "green",
                              fontSize: "20px",
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          onClick={() => {
                            setcomplaintData({
                              data: complaint,
                              status: "rejected",
                            });
                            setIsConfirm(true);
                          }}
                          title="Reject Request"
                          placement="top"
                          arrow
                        >
                          <Cancel
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginLeft: 4,
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeadlineApprovalModal
        title={
          complaintData?.status === "approved"
            ? `Are You Sure You Want To Approve This Request`
            : `Are You Sure You Want To Reject This Request`
        }
        loader={loader}
        handleConfirmation={handleApproveComplaint}
        rejectConfirmation={rejectConfirmation}
        isOpen={isConfirm}
        errorMsg={errorMessage}
        complaintData={complaintData}
        deadline={deadline}
        setdeadline={setdeadline}
        feedback={feedback}
        setfeedback={setfeedback}
        reason={reason}
        setreason={setreason}
        stars={stars}
        setstars={setstars}
        imageTest={imageTest}
        setimageTest={setimageTest}
        loader2={loader2}
        OTP={OTP}
        setOTP={setOTP}
      />
    </>
  );
};

export default ApprovalTable;
