import { Grid } from "@mui/material";
import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

const PageLaoder = (props) => {
    const {loader} = props
  return (
    <Grid
    style={{
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
   <FadeLoader
      color="#1977f2"
      loading={loader}
      size={22}
    />
  </Grid>
  );
};

export default PageLaoder;
