import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Grid, Paper, Button, Avatar } from "@material-ui/core";
import useStyles from "./styles";
import { BASE_URL } from "../utils/base";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { ArrowRightAlt, SentimentDissatisfied } from "@material-ui/icons";
import { navigateToReadWriteAccess } from "../Navigation/Navigations";
import moment from "moment";
import { AuthContext } from "../contextAPI/context";

function MemberTable(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;
  const navigate = useNavigate();
  const { allMembers, setOpen, setSelectedUser, isReadWrite, user } = props;
  return (
    <>
      {allMembers?.length > 0 && (
        <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow container>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Sr.
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Last updated
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeaderCell}
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Account Type
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Permissions
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                      sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                    >
                      Edit Permissions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allMembers?.map((member, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {moment(member?.updatedAt).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            alt="name"
                            style={{ border: "1px solid #000" }}
                            src={
                              member?.user?.image
                                ? `${BASE_URL}/user/${member?.user?.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography>
                          {member?.user?.name}(
                          {(plant && member?.user?.role === "tenant") ||
                          member?.user?.role === "owner"
                            ? "Employee"
                            : member?.user?.role
                            ? member?.user?.role
                            : "-"}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {member?.user?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {member?.user?.phone}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        {member?.accountType}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {member?.permissions?.length > 0
                          ? member?.permissions?.length + " Module Access"
                          : "No Permission"}
                        {member?.permissions?.length > 0 && (
                          <InfoIcon
                            title="Permission Info"
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 19,
                            }}
                            onClick={() => {
                              setSelectedUser(member);
                              setOpen(true);
                            }}
                          />
                        )}
                      </TableCell>
                      {isReadWrite && (
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          {user?._id === member?.user?._id ? (
                            <>
                              <SentimentDissatisfied />
                              <br></br> Cannot give permissions by yourself
                            </>
                          ) : (
                            <ArrowRightAlt
                              onClick={() => {
                                navigate(
                                  navigateToReadWriteAccess({
                                    memberId: member?.user?._id,
                                  })
                                );
                              }}
                              style={{ fontSize: 25, cursor: "pointer" }}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default MemberTable;
