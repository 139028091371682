import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import AdminApproval from "../../S2Admin/AdminApproval/AdminApproval";
import AdminDetails from "../../S2Admin/AdminApproval/AdminDetails/AdminDetails";
import Pending from "../../S2Admin/AdminApproval/Pending/Pending";

const AdminApprovalRoutes = {
  path: "/s2admin",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <AdminApproval />,
    },
    {
      path: "adminapproval",
      element: <AdminApproval />,
    },

    {
      path: "adminapproval/pending",
      element: <Pending />,
    },
    {
      path: "details/:adminId",
      element: <AdminDetails />,
    },
  ],
};
export default AdminApprovalRoutes;
