import React, { useState, useContext, useEffect } from "react";
import { Typography, Grid, Paper, Divider } from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { AuthContext } from "../../../contextAPI/context";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Card } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BuildIcon from "@mui/icons-material/Build";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as api from "../../../api";
import {
  ArrowRightAlt,
  Cancel,
  KeyboardBackspaceRounded,
  Visibility,
} from "@material-ui/icons";
import { BASE_URL } from "../../../utils/base";
import StarIcon from "@mui/icons-material/Star";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { resolveErrorMessage } from "../../../services/service";
import Loading from "../../../contextAPI/Loading";
import {
  navigateInitialQuickView,
  navigateToVendor,
  navigateToVendorComplaints,
  navigateToVendorReview,
  navigateToSubVendor,
} from "../../../Navigation/Navigations";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Vendor Graph",
      font: {
        size: 20,
        weight: "bold",
        family: "Montserrat, sans-serif",
      },
    },
    datalabels: {
      color: "#FFFFFF",
      anchor: "top",
      rotation: 270,
      display: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        return value > 0; // display labels with a value greater than 0
      },
      font: {
        size: 16,
        weight: "bold",
        family: "Montserrat, sans-serif",
      },
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        callback: function (value) {
          if (value % 1 === 0) {
            return value;
          }
        },
      },
    },
  },
};

const labels = [
  "All Complaints",
  "Resolved Complaints",
  "Pending Complaints",
  "Closed Complaints",
  "Cancelled Complaints",
];

function VendorDetails(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [vendor, setvendor] = useState([]);
  const { authState, authDispatch } = useContext(AuthContext);
  const { vendors, allvendors } = authState;
  const [value, setValue] = React.useState(4);
  const { id } = useParams();
  const [allComplaints, setallComplaints] = useState(0);
  const [pendingComplaints, setpendingComplaints] = useState(0);
  const [cancelledComplaints, setcancelledComplaints] = useState(0);
  const [transferredComplaints, settransferredComplaints] = useState(0);
  const [resolvedComplaints, setresolvedComplaints] = useState(0);
  const [closedComplaints, setclosedComplaints] = useState(0);
  const [pleasewait, setpleasewait] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [onTime, setonTime] = useState(0);
  const [notOnTime, setnotOnTime] = useState(0);
  const [vendorImage, setvendorImage] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [totalReviews, setTotalReviews] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const currentYear = new Date().getFullYear().toString();
  const [vendorGraphData, setVendorGraphData] = useState([]);
  const [loader, setloader] = useState(false);

  var cardStyle = {
    height: "100%",
    boxShadow: "0 3px 6px #4a6078",
    borderRadius: "8px",
  };

  useEffect(() => {
    handleVendorComplaintCount();
  }, []);

  const handleVendorComplaintCount = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        vendorId: id,
      };
      const res = await api.fetchVendorById(sendData);
      if (res?.data?.success) {
        setVendorName(res?.data?.vendor?.vendor?.name);
        setvendor(res?.data?.vendor?.vendor);
        setEmail(res?.data?.vendor?.vendor?.email);
        setPhone(res?.data?.vendor?.vendor?.phone);
        setallComplaints(res.data.vendor?.vendor?.complaintStatus?.all);
        setpendingComplaints(res.data.vendor?.vendor?.complaintStatus.pending);
        settransferredComplaints(
          res.data.vendor?.vendor?.complaintStatus.transferred
            ? res.data.vendor?.vendor?.complaintStatus.transferred
            : 0
        );
        setcancelledComplaints(
          res.data.vendor?.vendor?.complaintStatus.cancelled
        );
        setresolvedComplaints(
          res.data.vendor?.vendor?.complaintStatus.resolved
        );
        setclosedComplaints(res.data.vendor?.vendor?.complaintStatus.closed);
        setAverageRating(
          res?.data?.vendor?.vendor?.averageRating
            ? res?.data?.vendor?.vendor?.averageRating
            : 0
        );
        setonTime(res.data.vendor?.deadlineCheckTrue);
        setnotOnTime(res.data.vendor?.deadlineCheckFalse);
        setTotalRatings(res?.data?.vendor?.ratingCount);
        setTotalReviews(res?.data?.vendor?.reviewsCount);
        setpleasewait(false);
        setvendorImage(res?.data?.vendor?.vendor?.image);
      }
    } catch (error) {
      console.log(error);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleVendorGraphData();
  }, []);

  const handleVendorGraphData = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        year: currentYear,
        vendorId: id,
      };
      const res = await api.getGraphData(sendData);
      if (res?.data?.success) {
        setVendorGraphData(res?.data?.vendor);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const data = {
    labels: vendorGraphData.map((item) => item.month),
    datasets: [
      {
        label: "All",
        data: vendorGraphData.map((item) => item.all),
        backgroundColor: "rgba(53, 162, 235, 0.7)",
        borderColor: "rgba(54, 162, 235, 1)",
        barThickness: 40,
      },
      {
        label: "Pending",
        data: vendorGraphData.map((item) => item.pending),
        backgroundColor: "rgba(255, 206, 86, 0.7)",
        borderColor: "rgba(255, 206, 86, 1)",
        barThickness: 40,
      },
      {
        label: "Resolved",
        data: vendorGraphData.map((item) => item.resolved),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
        borderColor: "rgba(75, 192, 192, 1)",
        barThickness: 40,
      },
      {
        label: "Closed",
        data: vendorGraphData.map((item) => item.closed),
        backgroundColor: "rgba(153, 102, 255, 0.7)",
        borderColor: "rgba(153, 102, 255, 1)",
        barThickness: 40,
      },
      {
        label: "Cancelled",
        data: vendorGraphData.map((item) => item.cancelled),
        backgroundColor: "rgba(255, 99, 132, 7)",
        borderColor: "rgba(255, 99, 132, 1)",
        barThickness: 40,
      },
    ],
  };

  return (
    <div className={classes.root}>
      <main className={classes.content2}>
        <div className={classes.toolbar} />
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid item lg={6}>
            <Typography
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 22,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                }}
              >
                Vendor Profile
              </div>
            </Typography>
          </Grid>

          <Grid container item lg={6} style={{ justifyContent: "flex-end" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "100px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => {
                if (localStorage.getItem("fromQuickView") === "true") {
                  navigate(navigateInitialQuickView({}));
                } else if (location?.state?.mainVendor === true) {
                  navigate(navigateToVendor({}));
                } else if (location?.state?.mainVendor === false) {
                  navigate(
                    navigateToSubVendor({
                      vendorId: location?.state?.vendorId,
                    })
                  );
                }
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <KeyboardBackspaceRounded
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                  BACK
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={9} lg={9} md={9}>
            <Paper className={classes.card}>
              <div
                style={{
                  backgroundColor: "#2A265F",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "500px",
                  minWidth: "450px",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#FFFFFF",
                    fontSize: 15,
                    marginTop: -7,
                    textTransform: "uppercase",
                    opacity: 0.7,
                  }}
                >
                  Vendor Details
                </Typography>
                <Divider
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />
                <Grid
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img
                    class="round"
                    src={
                      vendorImage
                        ? `${BASE_URL}/user/${vendorImage}`
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt="user"
                    height="60px"
                    width="60px"
                    marginBottom="10"
                  />
                  <h4
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {vendorName}&nbsp;
                  </h4>

                  {averageRating !== 0 ? (
                    <>
                      {/*  <span style={{ textAlign: "center" }}>
              <Rating name="simple-controlled" readOnly value={averageRating} />
            </span> */}

                      <Typography
                        style={{
                          color: "#fff",
                          fontFamily: "Poppins, sans-serif",
                          marginTop: 5,
                          fontSize: 12,
                        }}
                      >
                        <span
                          style={{
                            marginLeft: 6,
                            textTransform: "capitalize",
                            color: "#231e39",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            fontSize: "13px",
                            fontWeight: "bold",
                            padding: "3px 7px",
                          }}
                        >
                          {Math.round(averageRating * 10) / 10}
                          <StarIcon style={{ fontSize: 18, marginBottom: 2 }} />
                        </span>{" "}
                        &nbsp;{totalRatings} Ratings & {totalReviews} reviews
                      </Typography>
                      <br />
                      <Grid container item style={{ justifyContent: "center" }}>
                        <div
                          class="card"
                          style={{
                            height: 30,
                            boxShadow: "0 3px 6px #7D858D",
                            width: "140px",
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            cursor: "pointer",
                            marginRight: 5,
                            marginLeft: 10,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            navigate(navigateToVendorReview({ vendorId: id }), {
                              state: {
                                mainVendor: location?.state?.mainVendor,
                              },
                            });
                          }}
                          disableElevation
                        >
                          <div class="card-body ">
                            <p
                              class="card-text"
                              style={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                fontFamily: "Montserrat, sans-serif",
                                marginTop: "-12px",
                                textAlign: "center",
                                textTransform: "capitalize",
                              }}
                            >
                              <Visibility style={{ fontSize: 16 }} />
                              &nbsp;All Reviews
                            </p>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Typography style={{ color: "#fff", textAlign: "center" }}>
                      No Review Recieved
                    </Typography>
                  )}
                </Grid>
              </div>
              {/* right side */}
              <div
                style={{
                  padding: "20px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000000",
                    fontSize: 15,
                    marginTop: -7,
                    textTransform: "uppercase",
                    opacity: 0.7,
                  }}
                >
                  Profile Information
                </Typography>
                <Divider
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#000000",
                    width: "100%",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />
                <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 13, marginBottom: 6 }}
                  >
                    <div className="col-md-4">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        1.&nbsp; &nbsp;Type :
                      </Typography>
                    </div>
                    <div className="col-md-8">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {location?.state?.mainVendor ? "Vendor" : "Sub-Vendor"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {/* <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginBottom: 6 }}
                  >
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        2.&nbsp; &nbsp;Category :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {vendor?.category?.categoryName}
                      </Typography>
                    </div>
                  </div>
                </Grid> */}
                {/* <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginBottom: 6 }}
                  >
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        2.&nbsp; &nbsp;Sub Category :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {vendor?.subCategory?.subcategoryName}
                      </Typography>
                    </div>
                  </div>
                </Grid> */}
                <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginBottom: 6 }}
                  >
                    <div className="col-md-4">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        2.&nbsp; &nbsp;Address :
                      </Typography>
                    </div>
                    <div className="col-md-8 overflow-hidden">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {vendor?.address}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginBottom: 6 }}
                  >
                    <div className="col-md-4">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        3.&nbsp; &nbsp;Email :
                      </Typography>
                    </div>
                    <div className="col-md-8">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {vendor?.email}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginBottom: 6 }}
                  >
                    <div className="col-md-4">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        4.&nbsp; &nbsp;Phone :
                      </Typography>
                    </div>
                    <div className="col-md-8">
                      <Typography
                        style={{
                          fontSize: 17,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontWeight: "bold",
                        }}
                      >
                        {vendor?.phone}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </div>
            </Paper>
            <Grid item xs={12} sm={12} lg={12}>
              <Bar options={options} data={data} style={{ height: "45vh" }} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={3}
            lg={3}
            md={3}
            style={{
              marginTop: 30,
              gridGap: "10px",
              marginBottom: 30,
              justifyContent: "center",
            }}
          >
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
              onClick={() => {
                navigate(
                  navigateToVendorComplaints({
                    complaintStatus: "all",
                    vendorId: id,
                  })
                );
              }}
            >
              <div
                style={{
                  backgroundImage:
                    "radial-gradient(circle at 48.7% 44.3%, rgb(30, 144, 231) 0%, rgb(56, 113, 209) 22.9%, rgb(38, 76, 140) 76.7%, rgb(31, 63, 116) 100.2%)",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <ErrorIcon style={{ fontSize: 24, textAlign: "center" }} />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    All Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {allComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
              onClick={() => {
                navigate(
                  navigateToVendorComplaints({
                    complaintStatus: "resolved",
                    vendorId: id,
                  })
                );
              }}
            >
              <div
                style={{
                  backgroundColor: "#004b23",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <PriorityHighIcon
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                    }}
                  />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Resolved Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {resolvedComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
              onClick={() => {
                navigate(
                  navigateToVendorComplaints({
                    complaintStatus: "assigned",
                    vendorId: id,
                  })
                );
              }}
            >
              <div
                style={{
                  backgroundColor: "#ff9500",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <BuildIcon
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                    }}
                  />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Pending Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {pendingComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
              onClick={() => {
                navigate(
                  navigateToVendorComplaints({
                    complaintStatus: "closed",
                    vendorId: id,
                  })
                );
              }}
            >
              <div
                style={{
                  backgroundColor: "#941b0c",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <CheckCircleIcon
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                    }}
                  />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {" "}
                    Closed Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {closedComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
              onClick={() => {
                navigate(
                  navigateToVendorComplaints({
                    complaintStatus: "cancel",
                    vendorId: id,
                  })
                );
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(239 13 39)",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <Cancel
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                    }}
                  />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Cancelled Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {cancelledComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
            <Card
              style={{
                height: 80,
                boxShadow: "0 3px 6px #4a6078",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundolor: " #fff",
                width: 235,
                color: "#ffffff",
                display: "flex",
              }}
            >
              <div
                style={{
                  backgroundColor: "#023e8a",
                  color: "#fff",
                  padding: "20px",
                  maxWidth: "70px",
                  minWidth: "60px",
                }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <GppMaybeIcon
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                    }}
                  />
                </Grid>
              </div>
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "12px",
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Transferred Complaints
                  </Typography>
                </Grid>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      color: "#000",
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: 18,
                      opacity: 1,
                      marginBottom: 10,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {transferredComplaints}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    <ArrowRightAlt style={{ color: "#000" }} />
                  </Typography>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </main>
      {loader && <Loading />}
    </div>
  );
}

export default VendorDetails;
