import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@material-ui/core";

const SelectVendor = (props) => {
  const {
    day,
    AllVendor,
    width,
    setdayWiseAllData,
    dayWiseAllData,
    vendor,
    setvendor,
    dynamicCount,
    dynamicCountIndex,
    getDynamicCount,
    usedVendorDayWise,
    dayWiseSelection
  } = props;

  const handleChangeVendor = (e) => {
    dayWiseSelection[day]=false
    setvendor(e.target.value);
    let temp = { vendor: e.target.value, day };
    let newArr = dayWiseAllData.filter((obj) => obj.day === day);
    if (newArr?.length > 0) {
      if (newArr?.length === getDynamicCount(day)) {
        newArr[newArr?.length - 1].vendor = e.target.value;
      } else if (newArr?.length < getDynamicCount(day)) {
        setdayWiseAllData([...dayWiseAllData, temp]);
      }
    } else {
      setdayWiseAllData([...dayWiseAllData, temp]);
    }
  };

 const checkVendor = () =>{
  if (dayWiseAllData?.length > 0) {
    let newArr = dayWiseAllData?.filter((obj) => obj.day === day);
    if (newArr) {
      return newArr[dynamicCountIndex]?.vendor !== "" ||
        newArr[dynamicCountIndex]?.vendor !== undefined
        ? newArr[dynamicCountIndex]?.vendor
        : null;
    } else {
      return null;
    }
  } else {
    return null;
  }
 } 
  return (
    <Grid container item xs={12}>
      <Select
        value={checkVendor()?checkVendor():"Select Vendor"}
        disabled={getDynamicCount(day)!==dynamicCountIndex+1}
        onChange={handleChangeVendor}
        style={{
          display: "block",
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          backgroundColor: "#fff",
          borderRadius: 6,
          fontSize: 16,
          width: width,
          padding: 8,
          paddingLeft: 20,
          height: 52,
          outline: 0,
          border: "1px solid #283f59",
        }}
      >
        <MenuItem value="Select Vendor">Select Vendor for {day}</MenuItem>
        {AllVendor?.map((vn) => {
          return  <MenuItem disabled={usedVendorDayWise(day,vn?._id)} value={vn?._id}>{vn?.name} </MenuItem>;
        })}
      </Select>
    </Grid>
  );
};

export default SelectVendor;
