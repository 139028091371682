import React from "react";
import { Button, Grid, Modal, Paper, Typography } from "@material-ui/core";
import { QuestionMark } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";

function ConfirmSwitchModal(props) {
  const {
    classes,
    confirmModal,
    setConfirmModal,
    handleCheckUser,
    setpassword,
    password,
    loader,
    handleEditConfiguraion,
    errorMsg,
    type,
    handleDeleteEmergencyContact,
  } = props;

  return (
    <div>
      <div>
        <Modal open={confirmModal}>
          <Paper
            style={{ backgroundColor: "#e6e7f0" }}
            className={classes.confirmModal}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
                textTransform: "uppercase",
              }}
            >
              ARE YOU SURE YOU WANT TO{" "}
              {type === "enableSetting"
                ? "MAKE THE CHANGES"
                : "DELETE THIS CONTACT ?"}
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              A confirmation message will appear when this process is
              complete.You can confirm by clicking below.
            </Typography>
            {errorMsg && (
              <Typography
                style={{
                  color: "#ff0000",
                  fontSize: 14,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                }}
              >
                {errorMsg}
              </Typography>
            )}
            {type === "enableSetting" && (
              <Grid
                container
                style={{ justifyContent: "center", marginTop: "7px" }}
              >
                <input
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleCheckUser();
                    }
                  }}
                  type="password"
                  placeholder="Enter your admin password here"
                  style={{
                    padding: 10,
                    border: "1px solid black",
                    width: "300px",
                    height: "50px",
                    borderRadius: 6,
                  }}
                  value={password}
                />
              </Grid>
            )}
            {loader && (
              <Typography
                style={{
                  color: "#6c757d",
                  fontSize: 14,
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                }}
              >
                Please wait ... &nbsp;
                <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
              </Typography>
            )}

            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={() => {
                  setConfirmModal(false);
                }}
                style={{
                  backgroundColor: "#C60C30",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  if (type === "enableSetting") {
                    handleCheckUser();
                  } else {
                    handleDeleteEmergencyContact();
                  }
                }}
                style={{
                  backgroundColor: "#1e2082",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CONFIRM
              </Button>
            </Grid>
          </Paper>
        </Modal>
      </div>
    </div>
  );
}

export default ConfirmSwitchModal;
