import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Paper } from "@material-ui/core";
import useStyles from "./style";
import { Circle } from "@mui/icons-material";
import { Pagination } from "@mui/material";

function MeetingAttendStatusTable(props) {
  const classes = useStyles();
  const { handlePageChange, attendeeData, page, totalCount, pageCount } = props;
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Email
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Phone
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Role
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendeeData?.map((user, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 10 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {user?.userId?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {user?.userId?.email ? user?.userId?.email : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {user?.userId?.phone ? user?.userId?.phone : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {user?.userId?.role ? user?.userId?.role : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      style={{
                        color:
                          user?.status === "interested"
                            ? "rgb(36 202 38)"
                            : user?.status === "going"
                            ? "rgb(36 103 202)"
                            : "rgb(239 13 39)",
                      }}
                    >
                      <Circle style={{ fontSize: 15, marginRight: 6 }} />
                      {user?.status}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        style={{
          padding: 10,
          justifyContent: "center",
        }}
      >
        <Pagination
          count={totalCount}
          color="primary"
          page={pageCount}
          onChange={handlePageChange}
        />
      </Grid>
    </>
  );
}

export default MeetingAttendStatusTable;
