import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "45vw",
    borderRadius: 6,
    backgroundColor: "#e6e7f0",
  },
  addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    backgroundColor: "#4b8edb",
    color: "#fff",
    outline: 0,
    border: "1px solid #4b8edb",
  },
  paper: {
    padding: 18,
    justifyContent: "center",
    borderRadius: 6,
    height: "100%",
    /* cursor: "pointer", */
    // width: "45vw",
    backgroundColor: "#ebeef0",
  },

  imgWrap: {
    position: "relative",
    width: "450px",
    height: "220px",
    overflow: "hidden",
    borderRadius: 10,
    /* border: "2px solid #4a6078", */
    cursor: "pointer",
    padding: 0,
    backgroundColor: "#4a6078",
    backgroundSize: "10px",
  },

  roundButton: {
    borderRadius: 10,
  },

  stickGrid: {
    position: "sticky",
    top: 70,
    overflow: "auto",
  },

  hoverText: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CED4DA",
    },
  },
  roundButton: {
    borderRadius: 100,
    marginTop: 18,
  },
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    maxWidth: "450px",
    minWidth: "300px",
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  pagination: {
    "& button": {
      borderRadius: "50%",
      padding: theme.spacing(1),
      "&.Mui-selected": {
        color: "#fff",
      },
    },
  },
}));
