import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Approval from "../../Components/Complaints/Approval/Approval";

const ApprovalRoutes = {
  path: "/complaints",
  element: <NavigationDrawer />,
  children: [
    {
      path: "approval",
      element: <Approval />,
    },
  ],
};
export default ApprovalRoutes;
