import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import EmergencyNumber from "../../Components/Help/EmergencyNumber/EmergencyNumber";

const EmergencyRoutes = {
  path: "/help",
  element: <NavigationDrawer />,
  children: [
    {
      path: "emergencynumber",
      element: <EmergencyNumber />,
    },
  ],
};
export default EmergencyRoutes;
