import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Resident from "../../Components/GateManagement/Resident/Residents";
import EntryExitLogs from "../../Components/GateManagement/Logs/EntryExitLogs";

const ResidentRoutes = {
  path: "/gatemanagement",
  element: <NavigationDrawer />,
  children: [
    {
      path: "resident",
      element: <Resident />,
    },
    {
      path: "resident/logs/:id",
      element: <EntryExitLogs />,
    },
  ],
};

export default ResidentRoutes;