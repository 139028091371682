import React from "react";
import LifeCycleTable from "./LifeCycleTable";
import { Grid, Typography } from "@material-ui/core";
import { ExportJsonCsv } from "react-export-json-csv";
import {
  CachedOutlined,
  CloudDownloadOutlined,
  FilterList,
} from "@material-ui/icons";

const ComplaintLifeCycle = (props) => {
  const {
    complaint,
    user,
    handlegetComplaintById,
    isReadWrite,
    csvData,
    headers,
    setupdateProcecssModal,
    loader,
    setloader,
  } = props;
  return (
    <>
      <Typography
        style={{
          fontSize: 25,
          fontFamily: "Poppins",
          fontWeight: 500,
          color: "#000000",
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        Lifecycle of Complaint
      </Typography>
      <div class="card">
        <div class="card-body">
          <Grid container style={{ justifyContent: "flex-end" }}>
            <Grid item>
              <div
                style={{
                  cursor: "pointer",
                  marginTop: "8px",
                  marginRight: 10,
                }}
                onClick={handlegetComplaintById}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3337 12.8336C23.0483 10.7805 22.0959 8.87816 20.6231 7.41964C19.1502 5.96111 17.2387 5.0273 15.1829 4.76204C13.1272 4.49678 11.0412 4.91479 9.24639 5.95168C7.45157 6.98857 6.04745 8.58682 5.25033 10.5002M4.66699 5.83356V10.5002H9.33366M4.66699 15.1667C4.95231 17.2197 5.90475 19.1221 7.37758 20.5806C8.85042 22.0391 10.7619 22.9729 12.8177 23.2382C14.8735 23.5034 16.9594 23.0854 18.7543 22.0485C20.5491 21.0117 21.9532 19.4134 22.7503 17.5M23.3337 22.1667V17.5H18.667"
                    stroke="#03045E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </Grid>
            <ExportJsonCsv
              fileTitle={
                complaint.complaintNumber +
                "(" +
                complaint.complaintTitle +
                ")-Lifecycle"
              }
              headers={headers}
              items={csvData}
              style={{
                backgroundColor: "#E9E9FF",
                border: "none",
                color: "#03045E",
                marginRight: 2,
                padding: "10px 14px",
                borderRadius: 10,
                cursor: "pointer",
                justifyContent: "flex-end",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 6 }}
              >
                <path
                  d="M4.66699 19.8334V22.1667C4.66699 22.7856 4.91282 23.3791 5.35041 23.8167C5.78799 24.2542 6.38149 24.5001 7.00033 24.5001H21.0003C21.6192 24.5001 22.2127 24.2542 22.6502 23.8167C23.0878 23.3791 23.3337 22.7856 23.3337 22.1667V19.8334M8.16699 12.8334L14.0003 18.6667M14.0003 18.6667L19.8337 12.8334M14.0003 18.6667V4.66675"
                  stroke="#03045E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Download CSV
            </ExportJsonCsv>
            {isReadWrite && (
              <div
                style={{
                  backgroundColor: "#E9E9FF",
                  color: "#ffffff",
                  cursor: "pointer",
                  marginLeft: 5,
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setupdateProcecssModal(true);
                }}
              >
                <div>
                  <p
                    class="card-text"
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      fontFamily: "Poppins",
                      // marginTop: "-12px",
                      textAlign: "center",
                      color: "#03045E",
                      padding: "10px 12px",
                    }}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: 6 }}
                    >
                      <path
                        d="M6.99935 4.66675V9.33341M6.99935 14.0001V23.3334M13.9993 4.66675V16.3334M13.9993 21.0001V23.3334M20.9993 4.66675V5.83341M20.9993 10.5001V23.3334M4.66602 9.33341H9.33268V14.0001H4.66602V9.33341ZM11.666 16.3334H16.3327V21.0001H11.666V16.3334ZM18.666 5.83341H23.3327V10.5001H18.666V5.83341Z"
                        stroke="#03045E"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Update Process
                  </p>
                </div>
              </div>
            )}
          </Grid>
          <LifeCycleTable
            complaintLifeCycle={complaint?.complaintLifeCycle}
            user={user}
            complaint={complaint}
            loader={loader}
            setloader={setloader}
          />
        </div>
      </div>
    </>
  );
};

export default ComplaintLifeCycle;
