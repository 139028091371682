import React from "react";
import { Col, Row } from "antd";
import Logo from "../../../assets/SociohoodLogo.png";
import moment from "moment";
import { Grid } from "@material-ui/core";
import "./print_table.css";

const PrintMultipleComplaint = React.forwardRef((props, ref) => {
  const { ComplaintForPrint, plant } = props;
  return (
    <>
      <div
        ref={ref}
        style={{ paddingTop: "20px", paddingLeft: "5px", paddingRight: "5px" }}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col span={2}>
            <img
              src={Logo}
              alt="sociohood"
              style={{
                width: "150px",
                height: "120px",
                marginTop: "-10px",
                padding: "20px",
              }}
            />
          </Col>
          <Col span={10}>
            <h3>Sociohood building</h3>
            <div>M6, Vijay Aman Apartment Main Road, Kankarbagh</div>
            <div>Patna Bihar 800020 India</div>
            <a href="https://www.sociohood.com">www.sociohood.com</a>
          </Col>
        </Row>
        <table className="print-table">
          <thead>
            <tr>
              <th>Sr.</th>
              <th> Title</th>
              <th> Launched Date</th>
              <th> Number</th>
              <th> Complainer</th>
              <th> Address</th>
              <th> Category</th>
              <th> Sub category</th>
              {plant && (
                <>
                  <th> Area</th>
                  <th> Subarea</th>
                  <th> Zone</th>
                </>
              )}
              <th> Deadline</th>
              <th> Status</th>
            </tr>
          </thead>
          <tbody>
            {ComplaintForPrint?.map((complaint, index) => {
              return (
                <tr>
                  <td>{index + 1}.</td>
                  <td>{complaint?.complaintTitle}</td>
                  <td>
                    {moment(complaint.createdAt).format(
                      "DD/MM/YYYY - h:mm:ss A"
                    )}
                  </td>
                  <td>{complaint?.complaintNumber}</td>
                  <td>
                    {complaint?.complainer?.name}({complaint?.complainer?.role})
                    <br />
                    {"Phone: " + complaint?.complainer?.phone}
                  </td>
                  <td>
                    {complaint?.property?.name +
                      "-" +
                      complaint?.floor?.name +
                      "-" +
                      complaint?.flat?.number}
                  </td>
                  <td>{complaint?.category?.categoryName}</td>
                  <td>{complaint?.subcomplaintCategory?.subcategoryName}</td>
                  {plant && (
                    <>
                      <td>{complaint?.area ? complaint?.area?.name : "-"}</td>
                      <td>{complaint?.subArea ? complaint?.subArea?.name : "-"}</td>
                      <td>{complaint?.subArea ? complaint?.subArea?.zone : "-"}</td>
                    </>
                  )}
                  <td>{complaint?.deadline}&nbsp;hrs.</td>
                  <td>
                    {complaint?.status}
                    <br></br>
                    priority : {complaint?.priority}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default PrintMultipleComplaint;
