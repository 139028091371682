import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import { Grid, Typography } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import Loading from "../../../contextAPI/Loading";
import { LoadingOutlined } from "@ant-design/icons";
import ApprovedManagerTable from "./ApprovedManagerTable";
import MakeManagerModal from "../Resident/ApprovedRequests/MakeManagerModal";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import { AllResidentRoutes } from "../../../api/Routes";
import { useNavigate } from "react-router";
import { navigateToManagerRequest } from "../../../Navigation/Navigations";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { Pagination } from "@mui/material";
import ConfirmModal from "../../../contextAPI/ConfirmModal";
import { ArrowRightAlt } from "@mui/icons-material";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import SearchIcon from "@mui/icons-material/Search";

function ApprovedManager() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [allManager, setallManager] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [search, setsearch] = useState("");

  // Bulk Manager
  const [checkedManager, setcheckedManager] = useState({});
  const [showCheckBox, setshowCheckBox] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [addManager, setaddManager] = useState(false);
  const [removeManager, setremoveManager] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("SelectGroup");
  const [managerData, setmanagerData] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Manager"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const rejectConfirmation = () => {
    setshowCheckBox(false);
    setaddManager(false);
    setcheckedManager({});
    seterrorMessage("");
    setSelectAllChecked(false);
    setremoveManager(false);
    setSelectedGroup("SelectGroup");
  };

  //Remove Manager
  const handleRemoveManger = async () => {
    setloader2(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader2(false);
    }, 40000);

    const data = {
      userId: managerData?._id,
      addressGroupId: managerData?.managerSocieties?.[0]?.addressGroupId?._id,
    };
    const res = await ApiCall("post", AllResidentRoutes.removeManager, data);
    if (res?.success) {
      rejectConfirmation();
      handleGetApprovedManger();
      toast.success("Manager removed successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };
  //GET MANAGER
  const handleGetApprovedManger = async () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);

    const data = {
      filters: {
        status: "approved",
        society: fetchSocietyId(),
        searchKey: search,
      },
      page: page,
      limit: 30,
    };
    const res = await ApiCall("post", AllResidentRoutes.getManager, data);
    if (res?.success) {
      setallManager(res.data?.data);
      setCount(res?.data?.totalPages);
      setloader(false);
    } else {
      seterrorMessage(res?.error);
      setloader(false);
    }
  };
  useEffect(() => {
    handleGetApprovedManger();
  }, [page,debouncedSearch]);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        setDebouncedSearch(search);
      }, 600);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      setDebouncedSearch("");
    }
  }, [search, 600]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className="col-sm-12">
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Manager
                    {!loader ? (
                      <RefreshIcon
                        title="Refresh Manager Page"
                        onClick={handleGetApprovedManger}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <div class="box" style={{ marginTop: "-7px" }}>
                  <input
                    type="text"
                    placeholder="search..."
                    onChange={(e) => {
                      setPage(1);
                      setsearch(e.target.value);
                    }}
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
                <Grid>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "170px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginLeft: 10,
                      marginTop: 7,
                    }}
                    onClick={() => {
                      navigate(navigateToManagerRequest());
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        Manager Request{" "}
                        <ArrowRightAlt
                          style={{
                            marginLeft: 5,
                            fontSize: "15px",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {allManager?.length > 0 && (
                <>
                  <Grid style={{ marginTop: 16, marginRight: 20 }}>
                    <ApprovedManagerTable
                      setaddManager={setaddManager}
                      setmanagerData={setmanagerData}
                      setremoveManager={setremoveManager}
                      allManager={allManager}
                      page={page}
                      isReadWrite={isReadWrite}
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      count={count}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Grid>
                </>
              )}
              {!loader && allManager?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 30,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Approved Manager
                </Typography>
              )}
            </div>
          </div>
        </div>
      </main>
      {addManager && (
        <MakeManagerModal
          addManager={addManager}
          setaddManager={setaddManager}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          handleMakeManger={handleRemoveManger}
          errorMessage={errorMessage}
          loader2={loader2}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {removeManager && (
        <ConfirmModal
          title={`${"ARE YOU SURE YOU WANT TO REMOVE THIS MANAGER"}`}
          description="You can confirm by clicking below."
          loader={loader2}
          handleConfirmation={handleRemoveManger}
          rejectConfirmation={rejectConfirmation}
          isOpen={removeManager}
          errorMsg={errorMessage}
        />
      )}
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default ApprovedManager;
