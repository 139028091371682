import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import DailyHelp from "../../Components/Help/DailyHelp/DailyHelp";
import DailyHelpDetails from "../../Components/Help/DailyHelp/DailyHelpDetails";

const DailyHelpRoutes = {
  path: "/help",
  element: <NavigationDrawer />,
  children: [
    {
      path: '',
      element: <DailyHelp />
    },
    {
      path: 'dailyhelp',
      element: <DailyHelp />
    },
    {
      path: 'dailyhelpdetails/:staffType',
      element: <DailyHelpDetails />
    }
  ]
};
export default DailyHelpRoutes;
