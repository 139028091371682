import React, { useState, useContext, useEffect } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import useStyles from "./styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReadWriteAccessTable from "./ReadWriteAccessTable";
import { AuthContext } from "../contextAPI/context";
import { fetchSocietyId } from "../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../services/service";
import { Error, KeyboardBackspaceRounded } from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BASE_URL } from "../utils/base";
import * as api from "../../src/api/index";
import { PrivacyTip, Mood } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import {
  navigateToAdminDetails,
  navigateToGroups,
  navigateToMember,
  navigateToPendingApprovals,
  navigateToS2AdminSocietyRequest,
} from "../Navigation/Navigations";
import { toast } from "react-toastify";
import ApiCall from "../api/ApiCall";
import { AllGroupRoutes } from "../api/Routes";

function ReadWriteAccess() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, permissionmodules } = authState;

  const [readAccessModules, setreadAccessModules] = useState([]);
  const [readWriteAcessModules, setreadWriteAcessModules] = useState([]);
  const [success, setSuccess] = useState(false);
  const [userCheckModal, setuserCheckModal] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [password, setpassword] = useState("");
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [fetcheduser, setfetcheduser] = useState([]);
  const [pendingPageAccess, setpendingPageAccess] = useState("");
  const [otherMainModules, setotherMainModules] = useState([]);

  useEffect(() => {
    setpendingPageAccess(location.state);
  }, []);

  const selectAllforReadAccess = (module) => {
    module?.subModules
      ?.filter((m) => m.isHideFromAccess === false)
      ?.map((subModule) => {
        if (
          readAccessModules?.includes(subModule?.moduleName) &&
          !readWriteAcessModules?.includes(subModule?.moduleName)
        ) {
          readAccessModules.splice(
            readAccessModules.indexOf(subModule?.moduleName),
            1
          );
          setreadAccessModules([...readAccessModules]);
        } else if (!readWriteAcessModules?.includes(subModule?.moduleName)) {
          readAccessModules.push(subModule?.moduleName);
          setreadAccessModules([...readAccessModules]);
        }
      });
  };
  const startApiCall = () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleApproveSocietyRequest = async () => {
    startApiCall();
    seterrorMsg("");
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        adminId: id,
        isApproved: true,
      };
      const res = await api.approveOrRejectSocietyRequest(sendData);
      if (res?.data?.success) {
        toast.success("Scoiety has been approved successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const selectAllforReadWriteAccess = (module) => {
    module?.subModules
      ?.filter((m) => m.isHideFromAccess === false)
      ?.map((subModule) => {
        if (
          readWriteAcessModules?.includes(subModule?.moduleName) &&
          !readAccessModules?.includes(subModule?.moduleName)
        ) {
          readWriteAcessModules.splice(
            readWriteAcessModules.indexOf(subModule?.moduleName),
            1
          );
          setreadWriteAcessModules([...readWriteAcessModules]);
        } else if (!readAccessModules?.includes(subModule?.moduleName)) {
          readWriteAcessModules.push(subModule?.moduleName);
          setreadWriteAcessModules([...readWriteAcessModules]);
        }
      });
  };

  const handleCloseDialog2 = () => {
    if (!loader2) {
      seterrorMsg("");
      setpassword("");
      setuserCheckModal(false);
    }
  };

  const handleChangeAdminStatus = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        adminId: id,
        isVerified: true,
      };
      const res = await api.approveAdminRequest(sendData);
      if (res?.data?.success) {
        setloader(false);
        toast.success("Admin has been approved Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleCheckUser = async () => {
    seterrorMsg("");
    try {
      if (password === "") {
        seterrorMsg("Please Enter your Password");
      } else {
        const sendData = {
          userId: user?._id,
          societyId: fetchSocietyId(),
          password,
        };
        const res = await api.userCheckForGivePermission(sendData);
        if (res.data.success) {
          seterrorMsg("");
          setpassword("");
          setuserCheckModal(false);
          handleGiveAccess();
          if (pendingPageAccess === "pending") {
            handleChangeAdminStatus();
          } else if (pendingPageAccess === "societyRequest") {
            handleApproveSocietyRequest();
          }
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const checkUserAccesses = async () => {
    let readAccesses = usermoduleswithpermissions
      ?.filter(
        (module) =>
          module?.permissionType === "read" && module?.moduleName !== "Member"
      )
      ?.map((x) => {
        return x.moduleName;
      });
    let readWriteAccesses = usermoduleswithpermissions
      ?.filter(
        (module) =>
          module?.permissionType === "read-write" &&
          module?.moduleName !== "Member"
      )
      ?.map((x) => {
        return x.moduleName;
      });
    let otherMainAccesses = usermoduleswithpermissions
      ?.filter(
        (module) =>
          module?.permissionType === "read-write" &&
          module?.moduleName === "Member"
      )
      ?.map((x) => {
        return x.moduleName;
      });
    setreadAccessModules(readAccesses);
    setreadWriteAcessModules(readWriteAccesses);
    setotherMainModules(otherMainAccesses);
  };

  const handleFetchUser = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        userId: id,
        societyId: fetchSocietyId(),
      };
      const res = await api.fetchUser(sendData);
      if (res.data.success) {
        setfetcheduser(res.data.user);

        let readAccesses = res.data.user.permissions
          ?.filter(
            (module) =>
              module?.permissionType === "read" &&
              module?.moduleName !== "Member"
          )
          ?.map((x) => {
            return x.moduleName;
          });

        let readWriteAccesses = res.data.user.permissions
          ?.filter(
            (module) =>
              module?.permissionType === "read-write" &&
              module?.moduleName !== "Member"
          )
          ?.map((x) => {
            return x.moduleName;
          });
        let otherMainAccesses = res.data.user.permissions
          ?.filter(
            (module) =>
              module?.permissionType === "read-write" &&
              module?.moduleName === "Member"
          )
          ?.map((x) => {
            return x.moduleName;
          });
        setreadAccessModules(readAccesses);
        setreadWriteAcessModules(readWriteAccesses);
        setotherMainModules(otherMainAccesses);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    if (usermoduleswithpermissions?.length > 0 && user?._id === id) {
      checkUserAccesses();
    } else if (
      usermoduleswithpermissions?.length > 0 &&
      user?._id !== id &&
      location.state !== "group"
    ) {
      handleFetchUser();
    }
  }, [usermoduleswithpermissions, id]);

  const handleGiveAccess = async () => {
    try {
      setloader2(true);
      setTimeout(() => {
        setloader2(false);
      }, 40000);
      let readTemp = [];
      if (
        readAccessModules?.includes("Society Type") ||
        readAccessModules?.includes("Society Manage")
      ) {
        readTemp = [];
      }
      if (
        !readAccessModules?.includes("Society Type") &&
        !readWriteAcessModules?.includes("Society Type")
      ) {
        readTemp.push({
          moduleName: "Society Type",
          permissionType: "read",
        });
      }
      if (
        !readAccessModules?.includes("Society Manage") &&
        !readWriteAcessModules?.includes("Society Manage")
      ) {
        readTemp.push({
          moduleName: "Society Manage",
          permissionType: "read",
        });
      }
      readAccessModules?.map((module) => {
        readTemp.push({
          moduleName: module,
          permissionType: "read",
        });
      });
      let readWriteTemp = [];
      readWriteAcessModules?.map((module) => {
        readWriteTemp.push({
          moduleName: module,
          permissionType: "read-write",
        });
      });
      otherMainModules?.map((module) => {
        readWriteTemp.push({
          moduleName: module,
          permissionType: "read-write",
        });
      });
      let mergedArray = readWriteTemp.concat(readTemp);

      const sendData = {
        userId: id,
        societyId: fetchSocietyId(),
        permissions: mergedArray,
        createdBy: user?._id,
        accountType: otherMainModules.includes("Member")
          ? "primary"
          : "secondary",
      };
      const res = await api.givePermissionMember(sendData);
      if (res.data.success) {
        setloader2(false);
        setSuccess(true);
      }
    } catch (error) {
      resolveErrorMessage(error);
    }
  };

  const handleGiveAccessToGroup = async () => {
    setloader3(true);
    setTimeout(() => {
      setloader3(false);
    }, 40000);
    let readTemp = [];
    if (
      readAccessModules?.includes("Society Type") ||
      readAccessModules?.includes("Society Manage")
    ) {
      readTemp = [];
    }
    if (
      !readAccessModules?.includes("Society Type") &&
      !readWriteAcessModules?.includes("Society Type")
    ) {
      readTemp.push({
        moduleName: "Society Type",
        permissionType: "read",
      });
    }
    if (
      !readAccessModules?.includes("Society Manage") &&
      !readWriteAcessModules?.includes("Society Manage")
    ) {
      readTemp.push({
        moduleName: "Society Manage",
        permissionType: "read",
      });
    }
    readAccessModules?.map((module) => {
      readTemp.push({
        moduleName: module,
        permissionType: "read",
      });
    });
    let readWriteTemp = [];
    readWriteAcessModules?.map((module) => {
      readWriteTemp.push({
        moduleName: module,
        permissionType: "read-write",
      });
    });
    otherMainModules?.map((module) => {
      readWriteTemp.push({
        moduleName: module,
        permissionType: "read-write",
      });
    });
    let mergedArray = readWriteTemp.concat(readTemp);

    const sendData = {
      addressGroupId: id,
      permissions: mergedArray,
    };
    const res = await ApiCall("post",AllGroupRoutes.editGroup,sendData);
    if (res.success) {
      setloader3(false);
      setSuccess(true);
    } else {
      setloader3(false);
    }
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid item lg={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {location.state === "group"
                      ? "Permission to Group"
                      : `User Permission to ${fetcheduser?.name}`}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (pendingPageAccess === "pending") {
                        navigate(navigateToPendingApprovals());
                      } else if (pendingPageAccess === "approved") {
                        navigate(navigateToAdminDetails({ adminId: id }));
                      } else if (pendingPageAccess === "societyRequest") {
                        navigate(navigateToS2AdminSocietyRequest());
                      } else if (pendingPageAccess === "group") {
                        navigate(navigateToGroups());
                      } else {
                        navigate(navigateToMember({}));
                      }
                    }}
                  >
                    <div class="card-body" onClick={() => {}}>
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        BACK
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 16, marginRight: 20 }}>
                <ReadWriteAccessTable
                  readAccessModules={readAccessModules}
                  setreadAccessModules={setreadAccessModules}
                  readWriteAcessModules={readWriteAcessModules}
                  setreadWriteAcessModules={setreadWriteAcessModules}
                  setuserCheckModal={setuserCheckModal}
                  modules={permissionmodules}
                  selectAllforReadAccess={selectAllforReadAccess}
                  selectAllforReadWriteAccess={selectAllforReadWriteAccess}
                  setotherMainModules={setotherMainModules}
                  otherMainModules={otherMainModules}
                />
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <div
            class="card"
            style={{
              height: 38,
              boxShadow: "0 3px 6px #7D858D",
              width: "220px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={() => {
              if (location.state === "group") {
                handleGiveAccessToGroup();
              } else {
                setuserCheckModal(true);
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-7.5px",
                }}
              >
                <PrivacyTip style={{ marginTop: -3 }} />
                &nbsp;&nbsp;Save & Give Permissions
              </p>
            </div>
          </div>
        </Grid>
      </main>
      <Dialog
        open={success}
        keepMounted
        onClose={() => {
          setSuccess(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontSize: 15,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                height: 100,
                width: 100,
                marginLeft: 5,
                marginRight: 10,
                borderTopRightRadius: 50,
                borderTopLeftRadius: 50,
              }}
              src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif"
              alt="Sociohood"
            />
          </Typography>

          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 14,
              fontWeight: "bolder",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            {/* <img
              style={{
                cursor: "pointer",
                border: "1px solid #e6e7f0",
                height: 46,
                width: 46,
                borderRadius: 50,
                marginRight: 5,
              }}
              src={
                fetcheduser?.image
                  ? `${BASE_URL}/user/${fetcheduser?.image}`
                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
              }
              alt="Sociohood"
            />
            {fetcheduser?.name} is now a Member Committee */}
            Permission Changed Successfully.
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Grid
            container
            style={{
              justifyContent: "center",
              marginTop: -20,
              marginBottom: 10,
            }}
          >
            <button
              className={classes.successButton}
              onClick={() => {
                setSuccess(false);
                window.scrollTo({
                  top: 1,
                  left: 0,
                  behavior: "smooth",
                });
                navigate(navigateToMember({}));
              }}
            >
              Back to Member Page
              <Mood style={{ marginLeft: 6, fontSize: 18, marginTop: -2 }} />
            </button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={userCheckModal}
        keepMounted
        onClose={handleCloseDialog2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            opacity: loader2 ? 0.4 : "",
          }}
        >
          <Typography
            style={{
              fontSize: 15,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                border: "1px solid #e6e7f0",
                height: 46,
                width: 46,
                borderRadius: 50,
                marginLeft: 15,
                marginRight: 15,
              }}
              src={
                user?.image
                  ? `${BASE_URL}/user/${user?.image}`
                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
              }
              alt="Sociohood"
            />
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {user?.name}
          </Typography>
          {(readAccessModules?.length > 0 ||
            readWriteAcessModules?.length > 0) && (
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
                fontWeight: "bolder",
                textAlign: "center",
                marginTop: 4,
              }}
            >
              Enter your Password
            </Typography>
          )}
        </DialogTitle>

        <DialogContent
          dividers
          style={{
            opacity: loader2 ? 0.4 : "",
          }}
        >
          {readAccessModules?.length > 0 ||
          readWriteAcessModules?.length > 0 ? (
            <Grid container style={{ justifyContent: "space-between" }}>
              <input
                onChange={(event) => {
                  setpassword(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleCheckUser();
                  }
                }}
                type="password"
                style={{
                  padding: 10,
                  border: "1px solid black",
                  width: "300px",
                  borderRadius: 6,
                  fontSize: "21px",
                }}
                value={password}
              />
            </Grid>
          ) : (
            <Grid>
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "12px",
                  marginTop: 10,
                }}
              >
                <Error style={{ color: "red", marginRight: 4 }} />
                Please check atleast one module
              </Typography>
            </Grid>
          )}
          {errorMsg && (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "12px",
                marginTop: 10,
              }}
            >
              <Error style={{ color: "red", marginRight: 4 }} />
              {errorMsg}
            </Typography>
          )}
        </DialogContent>
        {loader2 && (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              marginTop: 10,
              color: "#2f45c5",
            }}
          >
            Authenticating....&nbsp;
            <LoadingOutlined style={{ fontSize: "16px" }} />
          </Typography>
        )}
        <DialogActions>
          <Grid container style={{ justifyContent: "flex-end" }}>
            {(readAccessModules.length > 0 ||
              readWriteAcessModules.length > 0) && (
              <Button disabled={loader2} onClick={handleCheckUser}>
                Ok
              </Button>
            )}
            <Button disabled={loader2} onClick={handleCloseDialog2}>
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 15 }}
          />
          <Typography
            style={{
              color: "#6c757d",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -14,
              fontWeight: "bold",
              marginTop: 6,
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Fetching permissions...
          </Typography>
        </Grid>
      )}
    </div>
  );
}

export default ReadWriteAccess;
