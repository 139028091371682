import { Button, Grid, Modal, Paper, Typography } from "@material-ui/core";
import { LockClock } from "@mui/icons-material";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const ConfirmLogoutModal = (props) => {
  const { isConfirm, setisConfirm, confirmLogout, loader } = props;
  return (
    <Modal open={isConfirm}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#ffffff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
          }}
        >
          ARE YOU STILL HERE?
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <LockClock style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          In order to keep your account safe, we periodically check to see if
          you're still here. You can stay logged in by clicking below.
        </Typography>
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Logging out ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setisConfirm(false);
            }}
            disabled={loader}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            KEEP ME LOGGED IN
          </Button>
          <Button
            onClick={confirmLogout}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            LOGOUT
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ConfirmLogoutModal;
