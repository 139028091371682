import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../styles";
import { Paper } from "@material-ui/core";
import {
  handleFloorName,
  handlePropertyName,
} from "../../../../contextAPI/NamingConvention";

const GroupDetailsTable = ({ groupDetails }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {groupDetails?.accessType?.[0] === "sectors"
                  ? "Sector"
                  : groupDetails?.accessType?.[0] === "properties"
                  ? handlePropertyName()
                  : groupDetails?.accessType?.[0] === "floors"
                  ? handleFloorName()
                  : groupDetails?.accessType?.[0] === "categories"
                  ? "Categories"
                  : groupDetails?.accessType?.[0] === "categoryandzone"
                  ? "Categories"
                  : "Flat"}{" "}
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupDetails?.accessType?.[0] === "sectors" && (
              <>
                {groupDetails?.sectors?.map((sec, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {sec}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "properties" && (
              <>
                {groupDetails?.properties?.map((pr, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {pr?.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "floors" && (
              <>
                {groupDetails?.floors?.map((flr, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {flr?.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "flats" && (
              <>
                {groupDetails?.flats?.map((flt, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {flt?.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "flats" && (
              <>
                {groupDetails?.flats?.map((flt, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {flt?.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "categories" && (
              <>
                {groupDetails?.categories?.map((cat, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {cat?.categoryName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
            {groupDetails?.accessType?.[0] === "categoryandzone" && (
              <>
                {groupDetails?.categories?.map((cat, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {cat?.categoryName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {groupDetails?.accessType?.[0] === "categoryandzone" && (
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
          sx={{ marginTop: "30px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow container>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Sr No.
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Zone Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupDetails?.zones?.map((zone, index) => {
                return (
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                    >
                      {index + 1} .
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                    >
                      {zone}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default GroupDetailsTable;
