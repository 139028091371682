import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Card,
  Divider,
  CardMedia,
  Avatar,
  CardActionArea,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../styles";
import { useParams } from "react-router-dom";
import VisitorIdShowModal from "./VisitorIdShowModal";

function VisitorDetailsModal(props) {
  const { visitorModal, setVisitorModal, entryExitLogsData, visitorLogData } =
    props;
  const [idModal, setIdModal] = useState(false);
  const { id } = useParams();
  const classes = useStyles();
  const [accomapningVisitorData, setaccomapningVisitorData] = useState([]);
  const [showImageModal, setshowImageModal] = useState(false);
  const [showVisitorImage, setshowVisitorImage] = useState([]);
  
  return (
    <>
      <Modal open={visitorModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            position: "absolute",
            top: "45%",
            left: "55%",
            transform: "translate(-50%, -50%)",
            padding: 20,
            outline: 0,
          }}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: 400,
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
            Accompanying Visitor Details
            </Typography>
            <IconButton
              onClick={() => {
                setVisitorModal(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid>
            <TableContainer
              component={Paper}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "70vh",
              }}
              className={classes.tableContainer}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      align="center"
                    >
                      Sr No
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      align="center"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      align="center"
                    >
                      Phone Number
                    </TableCell>

                    {localStorage.getItem("ID") === "true" && (
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#303f9f",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        align="center"
                      >
                        ID
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visitorLogData?.groupVisitors?.map((row, index) => {
                    return (
                      <>
                        {id !== row?._id && (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {index + 1 - 1} .
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <Grid
                                container
                                style={{ justifyContent: "center" }}
                              >
                                <Avatar
                                  style={{ cursor: "pointer" }}
                                  alt={row.name}
                                  src={
                                    row.image
                                      ? row?.image
                                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                  }
                                  className={classes.avatar}
                                  onClick={() => {
                                    setshowVisitorImage(row);
                                    setshowImageModal(true);
                                  }}
                                />
                              </Grid>
                              {row?.name}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row?.phone}
                            </TableCell>
                            {localStorage.getItem("ID") === "true" && (
                              <TableCell
                                align="center"
                                sx={{
                                  color: "black",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                {row?.idProof ? (
                                  <Typography
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      color: "#1e2082",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      setaccomapningVisitorData(row?.idProof);
                                      setIdModal(true);
                                    }}
                                  >
                                    See ID
                                  </Typography>
                                ) : (
                                  "No ID added"
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Modal>
      {showImageModal && (
        <Modal open={showImageModal}>
          <Paper
            style={{
              backgroundColor: "#e8f0fa",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
              height: "500",
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              padding: 15,
              outline: 0,
              width: "45vw",
            }}
            className={classes.imageStyle}
          >
            <IconButton
              onClick={() => {
                setshowImageModal(false);
              }}
              style={{ textAlign: "flex-end" }}
            >
              <Close
                style={{
                  color: "#000",
                  fontSize: 20,
                  textAlign: "flex-end",
                }}
              />
            </IconButton>
            <Card style={{ padding: 4 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width="100%"
                  height={600}
                  style={{ objectFit: "contain" }}
                  image={showVisitorImage?.image}
                />
              </CardActionArea>
            </Card>
          </Paper>
        </Modal>
      )}
      <VisitorIdShowModal 
      idModal={idModal}
      setIdModal={setIdModal}
      accomapningVisitorData={accomapningVisitorData}
      />
    </>
  );
}

export default VisitorDetailsModal;
