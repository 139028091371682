import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, TextField } from "@mui/material";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import * as api from "../../api";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseSubSubCategoryDropDown({
  subCategory,
  setsearchKey,
  searchKey,
  setsubsubCategory,
  subsubCategory,
}) {
  const classes = useStyles();
  const [loader, setloader] = useState(true);
  const [totalCount, settotalCount] = useState(0);
  const [allSubSubCategory, setallSubSubCategory] = useState([]);
  const [page, setpage] = useState(1);

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loader &&
      totalCount !== allSubSubCategory?.length
    ) {
      setpage((prev) => prev + 1);
    }
  };
  const getComplaintSubSubCategory = async (search) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        filters: {
          society: fetchSocietyId(),
          searchKey: search,
          subCategoryId: subCategory?._id,
          isDeleted: false,
        },
        page: page,
        limit: 5000,
      };
      const res = await api.getSubSubComplaintCategory(sendData);
      if (res?.data?.success) {
        settotalCount(res.data.subSubCategory?.totalCounts);
        setallSubSubCategory(res.data?.subSubCategory?.data);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };
  useEffect(() => {
    if (subCategory?._id) {
      getComplaintSubSubCategory();
    }
  }, [page, searchKey, subCategory]);

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={false}
        disabled={!subCategory?._id || loader}
        onChange={(e, value) => {
          setsubsubCategory(value);
        }}
        id="select-subsubcategory"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        classes={classes}
        options={allSubSubCategory}
        autoHighlight
        value={subsubCategory}
        getOptionLabel={(option) => {
          return `${option?._id ? option.name : ""}`;
        }}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option._id}>
                {option.name}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpage(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={
              loader ? "Loading..." : `Select Sub-Subcategory (${totalCount})`
            }
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseSubSubCategoryDropDown;
