import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fff",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    /* backgroundColor: "#fff", */
    minHeight: "100vh",
  },

  pagination: {
    "& button": {
      borderRadius: "50%",
      padding: theme.spacing(1),
      "&.Mui-selected": {
        color: "#fff",
      },
    },
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "45vw",
    borderRadius: 6,
    backgroundColor: "#e6e7f0",
    cursor:"pointer"
  },

  addCircleButton:{
    color: "#2f45c5",
    cursor:"pointer"
  },
  selectPropButton:{
    backgroundColor: "#2f45c5",
    borderRadius:"3px",
    padding:"10px",
    border:"none",
    outline:0,
    color:"#fff",
    cursor:"pointer"
    
  },
  tableContainer: {
    borderRadius: 10,
    margin: "5px 5px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "17px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
}));
