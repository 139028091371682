import * as api from "../api";
import { fetchSocietyId } from "./fetchSociety";

export const getAllSocietiesProperty = async (pageNumber,searchKey,type,loader) => {
  try {
    loader = true
    let sendData = {
      societyId: fetchSocietyId(),
      pageNumber,
      searchKey,
      type:type==="All Type"? "":type,
    };
    const res = await api.getAllProperties(sendData);
    if (res?.data?.success) {
      let temp = [];
      temp.push(res.data?.properties);
      loader = false
      return temp?.[0];
    }
  } catch (error) {}
};
export const getPropertyFloor = async (propertyId,selectedFloor) => {
  try {
    let sendData = {
      propertyId,
      floorId: selectedFloor === "All Floors" ? "" : selectedFloor,

    };
    const res = await api.getAllFloors(sendData);
    if (res?.data?.success) {
      let temp = [];
      temp.push(res?.data?.floors);
      return temp?.[0];
    }
  } catch (error) {}
};
export const getFloorFlat = async (floorId) => {
  try {
    let sendData = {
      floorId,
    };
    const res = await api.getAllFlats(sendData);
    if (res?.data?.success) {
      let temp = [];
      temp.push(res?.data?.flats);
      return temp?.[0]
    }
  } catch (error) {}
};
