import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: "100vh",
  },
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "18px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  cardSection: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    minHeight: "160px",
    maxHeight: "160px",
    overflow: "hidden",
    borderRadius: ".3em",
    textDecoration: "none",
    margin: "0.35em",
    padding: "0.7em 0.8em",
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.6) 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, rgba(0, 0, 0, 0.3) 0px -3px 0px inset",
      transform: "scale(1.02)",
      transition: "all 0.3s ease-in-out",
    },
    [theme.breakpoints.down("md")]: { minHeight: "180px", maxHeight: "180px" },
  },
  cardStyle: {
    height: "125px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: ".4em",
    margin: "0.50em",
    boxShadow: "0 3px 6px #7D858D",
    "&:hover": {
      boxShadow: "0 3px 6px #7D858D",
      transform: "scale(1.02)",
      transition: "all 0.5s ease-in-out",
    },
    [theme.breakpoints.down("md")]: { minHeight: "130px", maxHeight: "130px" },
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "40vw",
    borderRadius: 6,
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));
