import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function VendorPerformanceGraph({ data, labels }) {
  const GraphData = {
    labels,
    datasets: [
      {
        label: "Vendor Performance Report",
        data,
        backgroundColor: [
          "#11224D",
          "#193A6F",
          "#2C599D",
          "#5B84C4",
          "#F98125",
          "#FB9B50",
          "#50d4d9",
          "#bea9ea",
          "#ffa48f",
          "#f6c78f",
          "#64B5F6",
          "#90CAF9",
          "#2196F3",
        ],
        borderColor: [
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Pie
      data={GraphData}
      
      options={{
        responsive: true,
        
        plugins: {
          datalabels: {
            display: "auto",
            color: "white",
          },
        },
      }}
      style={{ boxShadow: " 1px 2px 5px 3px #c8c8c8" }}
    ></Pie>
  );
}
