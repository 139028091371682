import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import useStyles from "../styles";
import { Close } from "@material-ui/icons";
import ChooseCategoryDropDown from "../../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import { toast } from "react-toastify";

function CategoryModal({
  categoryModal,
  setCategoryModal,
  selectedCategory,
  setselectedCategory,
  setPermissionType,
}) {
  const classes = useStyles();
  const [searchKey, setsearchKey] = useState("");
  return (
    <Modal open={categoryModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: selectedCategory?.length > 0 ? "800px" : "300px",
          maxWidth: selectedCategory?.length > 0 ? "75vw" : "35vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select Category
          </Typography>
          <IconButton
            onClick={() => {
              setCategoryModal(false);
              setPermissionType("Choose Type");
              setselectedCategory([]);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <ChooseCategoryDropDown
            setCategory={setselectedCategory}
            category={selectedCategory}
            isMultiple={true}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
          />
        </Grid>
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor:
                selectedCategory?.length > 0 ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (selectedCategory?.length > 0) {
                setCategoryModal(false);
              } else {
                toast.error("Please Select Category");
              }
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default CategoryModal;
