import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Paper,
  List,
  ListItem,
  Typography,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowRightAlt } from "@material-ui/icons";
import {
  navigateToGroupDetails,
  navigateToReadWriteAccess,
} from "../../../Navigation/Navigations";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Badge from "@mui/material/Badge";

function GroupTable({ allGroups, page, pageLimit }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [permissionModuleName, setpermissionModuleName] = useState([]);

  const handleCloseDialog = () => {
    setopen(false);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Group Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created At
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Created By
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Access Type
              </TableCell>
              {/* <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Permissions
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Give Access
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allGroups?.map((grp, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                  >
                    {(page - 1) * pageLimit + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {grp?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                  >
                    {moment(grp?.createdAt).format("DD/MM/YYYY, h:mm A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                  >
                    <Grid container style={{ justifyContent: "center" }}>
                      <Avatar
                        alt={grp?.createdBy?.name}
                        className={classes.avatar}
                      />
                    </Grid>
                    {grp?.createdBy?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {grp?.[grp?.accessType?.[0]]?.length}
                    &nbsp;
                    {grp?.accessType}
                    <ArrowRightAlt
                      onClick={() => {
                        navigate(
                          navigateToGroupDetails({
                            grpId: grp?._id,
                          })
                        );
                      }}
                      style={{ fontSize: 25, cursor: "pointer" }}
                    />
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    5 Permissions &nbsp;{" "}
                    <InfoIcon
                      style={{
                        fontSize: "24px",
                        color: "#2f45c5",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setpermissionModuleName(soc?.society?.permissions);
                        setopen(true);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <ArrowRightAlt
                      onClick={() => {
                        navigate(
                          navigateToReadWriteAccess({
                            memberId: grp?._id,
                          }),
                          {
                            state: "group",
                          }
                        );
                      }}
                      style={{ fontSize: 25, cursor: "pointer" }}
                    />
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Grid container justifyContent={"space-between"}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 13,
                fontWeight: "bolder",
                marginBottom: 10,
              }}
            >
              Module Permission &nbsp;&nbsp;&nbsp;{" "}
              {/* <Badge
                badgeContent={permissionModuleName?.length}
                color="warning"
              ></Badge>{" "} */}
              &nbsp;&nbsp;
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            xs={12}
            style={{ justifyContent: "space-between", width: "500px" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                }}
              >
                SL No.
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {" "}
                Module Name
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Permission Type
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ width: "500px" }}>
            <List>
              <ListItem>
                <Grid
                  container
                  xs={12}
                  style={{
                    justifyContent: "space-between",
                    width: "500px",
                  }}
                >
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: 15, textAlign: "start" }}>
                      1.
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontSize: 15,
                        textAlign: "start",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      HelpDesk
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: 15,
                        textAlign: "start",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      read-write
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GroupTable;
