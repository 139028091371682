import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import { CheckCircleOutline } from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ArrowRightAlt } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  handlePropertyName,
  handleFloorName,
} from "../../../../contextAPI/NamingConvention";
import {
  navigateToFlats,
  navigateToPropertyResident,
} from "../../../../Navigation/Navigations";
import EditIcon from "@mui/icons-material/Edit";
import { isMemberTypeS2Admin } from "../../../../GlobalFunctions/GlobalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { AllSocietyRoutes } from "../../../../api/Routes";

function PropertyTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { allProperties, page, societyName, user, handleGetProperties } = props;
  const [isEditProperty, setisEditProperty] = useState({
    newValue: "",
    prevValue: "",
    propertyId: "",
  });

  const handleEditProperty = async (propertyId, type) => {
    let sendData = {};
    if (type === "name") {
      sendData = {
        adminId: user?._id,
        propertyId: propertyId,
        societyId: fetchSocietyId(),
        name: isEditProperty?.newValue,
      };
    } else if (type === "type") {
      sendData = {
        adminId: user?._id,
        propertyId: propertyId,
        societyId: fetchSocietyId(),
        propertyType: isEditProperty?.newValue,
      };
    } else {
      sendData = {
        adminId: user?._id,
        propertyId: propertyId,
        societyId: fetchSocietyId(),
        sector: isEditProperty?.newValue,
      };
    }
    const res = await ApiCall("post", AllSocietyRoutes.editProperty, sendData);
    if (res?.success) {
      setisEditProperty({ newValue: "", prevValue: "", propertyId: "" });
      handleGetProperties();
      toast.success(`${handlePropertyName()} has been updated successfully!`);
    } else {
      toast.error(res.error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {/* Property Name */}
                {handlePropertyName()} Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sector
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Type
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Unique ID
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Resident
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Total {handleFloorName()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allProperties?.map((prop, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 100 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {isMemberTypeS2Admin(user?.role) ? (
                      <>
                        {isEditProperty?.propertyId === prop?._id &&
                        isEditProperty?.prevValue === prop?.name ? (
                          <>
                            <input
                              value={isEditProperty?.newValue}
                              onChange={(e) => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  newValue: e.target.value,
                                  prevValue: prop?.name,
                                });
                              }}
                            />
                            &nbsp;
                            <CheckCircleOutline
                              onClick={() => {
                                if (isEditProperty?.newValue === "") {
                                  toast.error("Please input atleast one word");
                                } else {
                                  handleEditProperty(prop?._id, "name");
                                }
                              }}
                              title="Submit"
                              style={{
                                color: "#303f9f",
                                fontSize: "18px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {prop?.name}&nbsp;
                            <EditIcon
                              onClick={() => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  prevValue: prop?.name,
                                  newValue: prop?.name,
                                });
                              }}
                              title="Edit Name"
                              style={{
                                color: "#303f9f",
                                fontSize: "16px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>{prop?.name}</>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {isMemberTypeS2Admin(user?.role) ? (
                      <>
                        {isEditProperty?.propertyId === prop?._id &&
                        isEditProperty?.prevValue === prop?.sector ? (
                          <>
                            <input
                              value={isEditProperty?.newValue}
                              onChange={(e) => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  newValue: e.target.value,
                                  prevValue: prop?.sector,
                                });
                              }}
                            />
                            &nbsp;
                            <CheckCircleOutline
                              onClick={() => {
                                if (isEditProperty?.newValue === "") {
                                  toast.error("Please input at least one word");
                                } else {
                                  handleEditProperty(prop?._id, "sector");
                                }
                              }}
                              title="Submit"
                              style={{
                                color: "#303f9f",
                                fontSize: "18px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {prop?.sector}&nbsp;
                            <EditIcon
                              onClick={() => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  prevValue: prop?.sector,
                                  newValue: prop?.sector,
                                });
                              }}
                              title="Edit Sector"
                              style={{
                                color: "#303f9f",
                                fontSize: "16px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>{prop?.sector}</>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {isMemberTypeS2Admin(user?.role) ? (
                      <>
                        {isEditProperty?.propertyId === prop?._id &&
                        isEditProperty?.prevValue === prop?.type ? (
                          <>
                            <input
                              value={isEditProperty?.newValue}
                              onChange={(e) => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  newValue: e.target.value,
                                  prevValue: prop?.type,
                                });
                              }}
                            />
                            &nbsp;
                            <CheckCircleOutline
                              onClick={() => {
                                if (isEditProperty?.newValue === "") {
                                  toast.error("Please input at least one word");
                                } else {
                                  handleEditProperty(prop?._id, "type");
                                }
                              }}
                              title="Submit"
                              style={{
                                color: "#303f9f",
                                fontSize: "18px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {prop?.type}&nbsp;
                            <EditIcon
                              onClick={() => {
                                setisEditProperty({
                                  propertyId: prop?._id,
                                  prevValue: prop?.type,
                                  newValue: prop?.type,
                                });
                              }}
                              title="Edit Type"
                              style={{
                                color: "#303f9f",
                                fontSize: "16px",
                                cursor: "pointer",
                                marginTop: -2,
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>{prop?.type}</>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {prop?.propertyUniqueId}
                    <CopyToClipboard
                      title="Copy Unique ID"
                      text={prop?.propertyUniqueId}
                      onCopy={() => {
                        toast.success("Copy to Clipboard", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: false,
                          closeOnClick: true,
                        });
                      }}
                    >
                      <ContentCopy
                        style={{
                          fontSize: 17,
                          marginLeft: 6,
                          color: "#000",
                          cursor: "pointer",
                        }}
                      />
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        navigateToPropertyResident({
                          societyName: societyName,
                          propertyId: prop?._id,
                          propertyName: prop?.name,
                        })
                      );
                    }}
                  >
                    <span>
                      Residents&nbsp;
                      <ArrowRightAlt style={{ fontSize: 25 }} />
                    </span>
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        navigateToFlats({
                          societyName: societyName,
                          propertyId: prop?._id,
                          propertyName: prop?.name,
                        })
                      );
                    }}
                  >
                    {prop?.floors?.length} {handleFloorName()} &nbsp;
                    <ArrowRightAlt style={{ fontSize: 25 }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PropertyTable;
