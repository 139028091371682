import Checkbox from "@mui/material/Checkbox";
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useStyles from "./styles";
import Badge from "@mui/material/Badge";
import allMainModules from "../Navigation/AllModules";
import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../contextAPI/context";

function Row(props) {
  const [isOpenSubModule, setisOpenSubModule] = useState("");
  const {
    readAccessModules,
    setreadAccessModules,
    readWriteAcessModules,
    setreadWriteAcessModules,
    selectAllforReadAccess,
    selectAllforReadWriteAccess,
    otherMainModules,
    setotherMainModules,
  } = props.other;
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;
  const [isMemberDisable, setisMemberDisable] = useState(true);
  const isAllReadWriteAccess = (moduleName) => {
    return moduleName?.subModules
      ?.filter((m) => m.isHideFromAccess === false)
      ?.every((i) => readWriteAcessModules?.includes(i.moduleName));
  };
  const isAllReadAccess = (moduleName) => {
    return moduleName?.subModules
      ?.filter((m) => m.isHideFromAccess === false)
      .every((i) => readAccessModules?.includes(i.moduleName));
  };

  const getLengthOfSubModules = (modulesName) => {
    return modulesName.subModules?.filter((m) => m.isHideFromAccess === false)
      .length;
  };

  const getAllModulesAndSubmodulesLength = () => {
    let temp = [];
    {
      allMainModules
        ?.filter((m) => m.isHideFromAccess === false)
        ?.map((module) => {
          module.subModules
            ?.filter((m) => {
              const shouldShow = m.isPlant ? plant : true;
              return shouldShow && m.isHideFromAccess === false;
            })
            ?.map((subModule, index) => {
              temp.push(subModule.moduleName);
            });
        });
      return temp?.length;
    }
  };

  useEffect(() => {
    if (getAllModulesAndSubmodulesLength() <= readWriteAcessModules?.length) {
      setisMemberDisable(false);
    } else {
      otherMainModules.pop();
      setisMemberDisable(true);
    }
  }, [readWriteAcessModules]);

  return (
    <React.Fragment>
      {allMainModules
        ?.filter((m) => m.isHideFromAccess === false)
        ?.map((module, index) => {
          return (
            <>
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      setisOpenSubModule(
                        isOpenSubModule === module.moduleName
                          ? ""
                          : module.moduleName
                      )
                    }
                  >
                    {isOpenSubModule === module.moduleName ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {index + 1}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {module.moduleName}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {getLengthOfSubModules(module)} Submodules
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse
                    in={isOpenSubModule === module.moduleName}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      style={{
                        margin: 1,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          color: "#000000",
                          opacity: 0.6,
                          fontSize: "18px",
                          textAlign: "left",
                          marginTop: 4,
                        }}
                      >
                        <span style={{ color: "#000000" }}>{module.icon} </span>{" "}
                        {module.moduleName}'s Submodules &nbsp;&nbsp;
                        <Badge
                          badgeContent={getLengthOfSubModules(module)}
                          color="secondary"
                        ></Badge>
                      </Typography>
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.tableHeaderCell}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Sr. No.
                            </TableCell>
                            <TableCell
                              className={classes.tableHeaderCell}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Submodule Name
                            </TableCell>
                            <TableCell
                              className={classes.tableHeaderCell}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Read
                              <Checkbox
                                disabled={
                                  module.moduleName === "Settings"
                                    ? true
                                    : isAllReadWriteAccess(module)
                                }
                                checked={
                                  module.moduleName === "Settings"
                                    ? true
                                    : isAllReadAccess(module)
                                }
                                onClick={() => {
                                  selectAllforReadAccess(module);
                                }}
                                style={{
                                  color: isAllReadWriteAccess(module)
                                    ? "#c8c8c8"
                                    : "white",
                                  fontSize: 10,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableHeaderCell}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Read-Write
                              <Checkbox
                                disabled={isAllReadAccess(module)}
                                checked={isAllReadWriteAccess(module)}
                                onClick={() => {
                                  selectAllforReadWriteAccess(module);
                                }}
                                style={{
                                  color: isAllReadAccess(module)
                                    ? "#c8c8c8"
                                    : "white",
                                  fontSize: 10,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {module.subModules
                            ?.filter((m) => {
                              const shouldShow = m.isPlant ? plant : true;
                              return shouldShow && m.isHideFromAccess === false;
                            })
                            ?.map((subModule, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    color: "black",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  {indexAsAlphabet(index)}.
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    color: "black",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  {subModule?.moduleName}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "black",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      module.moduleName === "Settings"
                                        ? true
                                        : readAccessModules.includes(
                                            subModule?.moduleName
                                          )
                                    }
                                    disabled={
                                      module.moduleName === "Settings"
                                        ? true
                                        : readWriteAcessModules.includes(
                                            subModule?.moduleName
                                          )
                                    }
                                    key={index}
                                    onChange={() => {
                                      if (
                                        readAccessModules.includes(
                                          subModule?.moduleName
                                        )
                                      ) {
                                        readAccessModules.splice(
                                          readAccessModules.indexOf(
                                            subModule?.moduleName
                                          ),
                                          1
                                        );
                                        setreadAccessModules([
                                          ...readAccessModules,
                                        ]);
                                      } else {
                                        setreadAccessModules([
                                          ...readAccessModules,
                                          subModule?.moduleName,
                                        ]);
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "black",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  <Checkbox
                                    checked={readWriteAcessModules.includes(
                                      subModule?.moduleName
                                    )}
                                    disabled={readAccessModules.includes(
                                      subModule?.moduleName
                                    )}
                                    onChange={() => {
                                      if (
                                        readWriteAcessModules.includes(
                                          subModule?.moduleName
                                        )
                                      ) {
                                        readWriteAcessModules.splice(
                                          readWriteAcessModules.indexOf(
                                            subModule?.moduleName
                                          ),
                                          1
                                        );
                                        setreadWriteAcessModules([
                                          ...readWriteAcessModules,
                                        ]);
                                      } else {
                                        setreadWriteAcessModules([
                                          ...readWriteAcessModules,
                                          subModule?.moduleName,
                                        ]);
                                      }
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "black",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <Tooltip
            title={
              "To enable this option, all modules must have read-write permissions."
            }
            placement="right"
            arrow
          >
            <Info style={{ color: "#2f45c5", cursor: "pointer" }} />
          </Tooltip>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "black",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          9.
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "black",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
            textAlign: "center",
          }}
        >
          Member
        </TableCell>

        <TableCell
          align="right"
          sx={{
            color: "black",
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
          }}
        >
          {" "}
          Read & Write Access &nbsp;
          <Checkbox
            checked={otherMainModules?.includes("Member")}
            disabled={isMemberDisable}
            onChange={() => {
              if (otherMainModules.includes("Member")) {
                otherMainModules.splice(otherMainModules.indexOf("Member"), 1);
                setotherMainModules([...otherMainModules]);
              } else {
                setotherMainModules([...otherMainModules, "Member"]);
              }
            }}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const indexAsAlphabet = (index) => {
  const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];

  return alphabet[index];
};
export default function ReadWriteAccessTable(props) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            />
            <TableCell
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Sr. No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Module Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              No. of Submodules
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row other={props} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
