import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Modal,
  MenuItem,
  Card,
  Icon,
} from "@material-ui/core";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "../style";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { IconButton, Menu, Paper } from "@mui/material";
import { Delete, QuestionMark } from "@mui/icons-material";
import AddCategory from "./AddCategory";
import { LoadingOutlined } from "@ant-design/icons";
import * as api from "../../../api/index";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { navigateToLocalDirectoryDetails } from "../../../Navigation/Navigations";

function LocalDirectoryCard(props) {
  const { cate, handleGetDirectoryCategory, isReadWrite } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [delteCategory, sedelteCategory] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [anchorEl, setAnchorEl] = React.useState("");
  const openEdit = Boolean(anchorEl);

  const BoxIcons = styled(Icon)(({ theme }) => ({
    fontSize: "65px",
    color: "#1e2082",
    [theme.breakpoints.down("md")]: { fontSize: "40px" },
    [theme.breakpoints.down("lg")]: { fontSize: "40px" },
  }));

  const Title = styled("h6")(({ theme }) => ({
    fontWeight: "bolder",
    fontSize: "16px",
    fontFamily: "Montserrat, sans-serif",
    color: "#000",
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: 5,
    [theme.breakpoints.down("md")]: { fontSize: "11px", marginTop: 5 },
    [theme.breakpoints.down("lg")]: { fontSize: "14px", marginTop: 5 },
  
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmModalDelete = (
    <div>
      <Modal open={delteCategory}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.confirmModal}
        >
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 19,
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: 7,
              textTransform: "uppercase",
            }}
          >
            ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY ?
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
          </Typography>

          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            A confirmation message will appear when this process is complete.You
            can confirm by clicking below.
          </Typography>
          {loader && (
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              Please wait ... &nbsp;
              <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
            </Typography>
          )}
          <Grid container style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                sedelteCategory(false);
              }}
              style={{
                backgroundColor: "#C60C30",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                handleDeleteCategory();
              }}
              style={{
                backgroundColor: "#1e2082",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );

  const handleDeleteCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        society: fetchSocietyId(),
        localDirectoryCategoryId: cate?.category?._id,
      };
      const res = await api.deleteLocalDirectoryCategory(sendData);
      if (res?.data?.success) {
        toast.success("Category Deleted ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        sedelteCategory(false);
        handleGetDirectoryCategory();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <>
      <Grid item xs={2}>
        <section>
          <Card
            className={classes.cardSection}
            href="#"
            style={{
              borderTop: "3px solid rgb(36 103 202)",
            }}
          >
            {isReadWrite && (
              <Grid container justifyContent={"flex-end"}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openEdit ? "long-menu" : undefined}
                  aria-expanded={openEdit ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openEdit}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 1,
                    style: {
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <MenuItem
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 14,
                      marginRight: 5,
                      fontFamily: "Poppins, sans-serif",
                    }}
                    onClick={() => {
                      setEditCategory(true);
                    }}
                  >
                    <EditIcon style={{ fontSize: 22 }} />
                    &nbsp; Edit
                  </MenuItem>
                  {cate?.count === 0 && (
                    <MenuItem
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: 14,
                        marginRight: 5,
                        fontFamily: "Poppins, sans-serif",
                      }}
                      onClick={() => {
                        sedelteCategory(true);
                      }}
                    >
                      <Delete style={{ color: "red" }} />
                      &nbsp; Delete
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
            )}

            <Grid
              container
              item
              onClick={() => {
                navigate(
                  navigateToLocalDirectoryDetails({
                    categoryname: cate?.category?.name,
                    directoryId: cate?.category?._id,
                  })
                );
              }}
              style={{
                marginTop: isReadWrite ? 0 : 40,
                justifyContent: "center",
              }}
            >
              <BoxIcons component={ContactPhoneIcon} />
            </Grid>
            <Title
              onClick={() => {
                navigate(
                  navigateToLocalDirectoryDetails({
                    categoryname: cate?.category?.name,
                    directoryId: cate?.category?._id,
                  })
                )
              }}
            >
              {cate?.category?.name}
            </Title>
            <Title
              onClick={() => {
                navigate(
                  navigateToLocalDirectoryDetails({
                    categoryname: cate?.category?.name,
                    directoryId: cate?.category?._id,
                  })
                );
              }}
            >
              <span style={{ color: "#2e3094" }}> {cate?.count} Contacts </span>{" "}
            </Title>
          </Card>
        </section>
      </Grid>
      {confirmModalDelete}
      <AddCategory
        editCategory={editCategory}
        setEditCategory={setEditCategory}
        cate={cate}
        handleGetDirectoryCategory={handleGetDirectoryCategory}
        type="Edit"
      />
    </>
  );
}

export default LocalDirectoryCard;
