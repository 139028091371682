import React from "react";
import { Grid, IconButton, Modal, Paper, Typography } from "@material-ui/core";
import useStyles from "../styles";
import { Close } from "@material-ui/icons";

const SuccessAddComplaint = ({
  successComplaintModal,
  setSuccessComplaintModal,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal open={successComplaintModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            maxWidth: "45vw",
            height: "45vh",
            overflow: "auto",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              justifyContent: "end",
              textAlign: "end",
            }}
          >
            <IconButton
              onClick={() => {
                setSuccessComplaintModal(false);
              }}
            >
              <Close
                style={{
                  color: "#000",
                  fontSize: 20,
                  marginTop: -10,
                }}
              />
            </IconButton>
          </Grid>
          <Grid container style={{ marginTop: "5%", justifyContent: "center" }}>
            <div className="h-440p d-flex flex-column align-items-center justify-content-center">
              <svg
                width="136"
                height="136"
                viewBox="0 0 136 136"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M76.1295 4.82006C71.9283 -0.311124 64.0815 -0.311106 59.8809 4.82006L53.9297 12.0888C53.5218 12.5869 52.8414 12.7693 52.2391 12.5418L43.451 9.22238C37.2473 6.8792 30.4517 10.8025 29.3791 17.3468L27.8597 26.6172C27.7556 27.2526 27.2575 27.7507 26.6221 27.8548L17.3517 29.3742C10.8075 30.4468 6.88403 37.2423 9.22727 43.4461L12.5466 52.2342C12.7742 52.8366 12.5918 53.517 12.0936 53.9248L4.82495 59.8758C-0.306239 64.077 -0.306227 71.9238 4.82495 76.125L12.0936 82.0758C12.5918 82.4837 12.7742 83.1642 12.5466 83.7666L9.22727 92.5547C6.88409 98.7581 10.8074 105.554 17.3517 106.627L26.6221 108.146C27.2575 108.25 27.7556 108.748 27.8597 109.384L29.3791 118.654C30.4517 125.198 37.2472 129.121 43.451 126.778L52.2391 123.459C52.8414 123.232 53.5218 123.413 53.9297 123.912L59.8809 131.18C64.0815 136.312 71.9283 136.312 76.1295 131.18L82.0804 123.912C82.4883 123.413 83.1687 123.232 83.7711 123.459L92.5593 126.778C98.7633 129.121 105.559 125.198 106.631 118.654L108.151 109.384C108.255 108.748 108.753 108.25 109.388 108.146L118.659 106.627C125.203 105.554 129.126 98.7581 126.783 92.5547L123.464 83.7666C123.236 83.1642 123.419 82.4837 123.917 82.0758L131.186 76.125C136.317 71.9238 136.317 64.077 131.186 59.8758L123.917 53.9248C123.419 53.517 123.236 52.8366 123.464 52.2342L126.783 43.4461C129.126 37.2423 125.203 30.4468 118.659 29.3742L109.388 27.8548C108.753 27.7507 108.255 27.2526 108.151 26.6172L106.631 17.3468C105.559 10.8026 98.7633 6.87914 92.5593 9.22238L83.7711 12.5418C83.1687 12.7693 82.4883 12.5869 82.0804 12.0888L76.1295 4.82006Z"
                  fill="#4DC4FF"
                />
              </svg>
              <svg
                width="76"
                height="52"
                viewBox="0 0 76 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-70%" }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M74.1818 1.818C75.9392 3.57534 75.9392 6.42462 74.1818 8.18196L32.1818 50.1818C31.3382 51.026 30.1934 51.5 29 51.5C27.8066 51.5 26.6618 51.026 25.818 50.1818L1.818 26.1818C0.0606651 24.4244 0.0606651 21.5756 1.818 19.8182C3.57534 18.0608 6.42463 18.0608 8.18197 19.8182L29 40.6358L67.8182 1.818C69.5756 0.0606651 72.4244 0.0606651 74.1818 1.818Z"
                  fill="#ECEFF1"
                />
              </svg>
            </div>
          </Grid>
          <Typography
            style={{
              ontFamily: "Poppins",
              fontSize: "22px",
              fontWeight: 400,
              marginTop: "10%",
              textAlign: "center",
            }}
          >
            Your complaint was submitted successfully
          </Typography>
        </Paper>
      </Modal>
    </div>
  );
};

export default SuccessAddComplaint;
