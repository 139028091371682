import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import MaintenancePage from "../../Components/MaintenancePage/MaintenancePage";
import { AuthContext } from "../../contextAPI/context";
import Login from "../../EntryPoints/Login/Login";
import Signup from "../../EntryPoints/Signup/Signup";

const AuthRoutes = (props) => {
  const { authState, authDispatch } = useContext(AuthContext);
  const { maintenance } = authState;
  // const [isMaintenance, setisMaintenance] = useState(false);

  // useEffect(() => {
  //   setisMaintenance(maintenance);
  // }, [authState]);

  return (
    <Routes>
      <Route
        path="/*"
        element={maintenance ? <MaintenancePage /> : <Login />}
      />
      <Route
        path="/login"
        element={maintenance ? <MaintenancePage /> : <Login />}
      />
      <Route
        exact
        path="/signup"
        element={maintenance ? <MaintenancePage /> : <Signup />}
      />
    </Routes>
  );
};

export default AuthRoutes;
