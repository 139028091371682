import React from "react";
import Alldiscussions from "../../Components/Discussion/AllDiscussions/Alldiscussions";
import IndividualDiscussions from "../../Components/Discussion/IndividualDiscussions";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";

const DiscussionRoutes = {
    path: "/communication",
    element: <NavigationDrawer />,
    children: [
        {
            path: "alldiscussions",
            element: <Alldiscussions />
        },
        {
            path: "individualdiscusscion/:id",
            element: <IndividualDiscussions />
        }
    ]
}
export default DiscussionRoutes;
