import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useStyles from "../Properties/styles";

const PropertyResidentTable = ({ allResidents, page }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        style={{ tableLayout: "fixed" }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Sr.&nbsp;{" "}
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Role
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Phone No
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Email
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Address
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allResidents.map((resident, index) => (
            <TableRow key={index}>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {(page - 1) * 50 + (index + 1)}.&nbsp;{" "}
              </TableCell>

              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {resident?.name}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                className="text-capitalize"
              >
                {resident?.role === "manger" ? "Manager" : resident?.role}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {resident?.phone}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {resident?.email?.length ? resident?.email : "-"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {resident?.flat?.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyResidentTable;
