import React, { useEffect, useState } from "react";
import { CssBaseline, Grid, Paper, Typography } from "@material-ui/core";
import useStyle from "../style";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import { navigateToApprovedApprovals } from "../../../Navigation/Navigations";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api/index";
import { resolveErrorMessage } from "../../../services/service";
import ConfirmModal from "../../../contextAPI/ConfirmModal";
import { toast } from "react-toastify";
import Loading from "../../../contextAPI/Loading";
import { Checkbox, Pagination } from "@mui/material";
import Badge from "@mui/material/Badge";
import PendingTable from "./PendingTable";
import RejectTable from "./RejectTable";
import { navigateToReadWriteAccess } from "../../../Navigation/Navigations";

function Pending() {
  const navigate = useNavigate();
  const classes = useStyle();
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [allAdminRequests, setallAdminRequests] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [IsApprove, setIsApprove] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("");
  const [rejectedAdminCount, setrejectedAdminCount] = useState(0);
  const [statusForAdminData, setStatusForAdminData] = useState("pending");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleViewRejectedAdminReq = () => {
    setStatusForAdminData(
      statusForAdminData === "pending" ? "rejected" : "pending"
    );
  };

  const handleRedirectPage = () => {
    navigate(navigateToReadWriteAccess({ memberId: adminId?._id }), {
      state: "pending",
    });
  };

  const handleAdminRequest = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        status: statusForAdminData,
        pageNumber: page,
        pageLimit: 50,
      };
      const res = await api.getAdminRequest(sendData);
      if (res?.data?.success) {
        setallAdminRequests(res?.data?.admin?.users);
        setrejectedAdminCount(res?.data?.admin?.totalRejected);
        setCount(res?.data?.admin?.totalPages);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleAdminRequest();
  }, [page, statusForAdminData]);

  const rejectConfirmation = () => {
    setIsApprove(false);
  };

  const handleChangeAdminStatus = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        adminId: adminId?._id,
        isVerified: selectedStatus === "approve" ? true : false,
      };
      const res = await api.approveAdminRequest(sendData);
      if (res?.data?.success) {
        setloader(false);
        setIsApprove(false);
        handleAdminRequest();
        toast.success("Admin Status Change Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleStatusChangeForAdmin = (status) => {
    setselectedStatus(status);
    seterrorMsg("");
    setIsApprove(true);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <Grid container>
                  <Grid
                    container
                    item
                    xs={6}
                    style={{ justifyContent: "space-between", marginTop: 2 }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginBottom: 15,
                      }}
                    >
                      Admin Request
                      <RefreshIcon
                        title="refresh S2 Admin Approval"
                        onClick={handleAdminRequest}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateToApprovedApprovals({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          BACK
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginTop: 10,
                      marginRight: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Checkbox
                      checked={statusForAdminData === "rejected"}
                      onClick={handleViewRejectedAdminReq}
                      style={{ color: "#283f59" }}
                    />
                    &nbsp;&nbsp;
                    <span style={{ color: "#283f59", marginTop: 5 }}>
                      View Rejected Admin
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={rejectedAdminCount}
                      color={"error"}
                      style={{ marginTop: -2 }}
                    ></Badge>
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 10 }}>
                  {statusForAdminData === "pending" ? (
                    <PendingTable
                      allAdminRequests={allAdminRequests}
                      setAdminId={setAdminId}
                      handleStatusChangeForAdmin={handleStatusChangeForAdmin}
                      page={page}
                    />
                  ) : (
                    <RejectTable
                      allAdminRejectedRequests={allAdminRequests}
                      setAdminId={setAdminId}
                      handleStatusChangeForAdmin={handleStatusChangeForAdmin}
                      page={page}
                    />
                  )}
                </Grid>
                {allAdminRequests?.length > 0 && (
                  <Grid
                    container
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      count={count}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}
                {!loader && allAdminRequests?.length === 0 && (
                  <Typography
                    style={{
                      color: "#6c757d",
                      textAlign: "center",
                      fontSize: 22,
                      marginTop: 30,
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {statusForAdminData === "pending"
                      ? "No Pending Admins"
                      : "No Rejected Admins"}
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </main>
        {loader && <Loading loader={loader} />}
        <ConfirmModal
          title={`ARE YOU SURE YOU WANT TO ${selectedStatus} THIS ADMIN ?`}
          description="A confirmation message will appear when this process is
                      complete.You can confirm by clicking below."
          isOpen={IsApprove}
          rejectConfirmation={rejectConfirmation}
          handleConfirmation={
            selectedStatus === "approve"
              ? handleRedirectPage
              : handleChangeAdminStatus
          }
          errorMsg={errorMsg}
          loader={loader}
        />
      </div>
    </>
  );
}

export default Pending;
