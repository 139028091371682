import React from "react";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";


const ErrorRoute = {
  path: "*",
  element: <ErrorPage />,
};
export default ErrorRoute;
