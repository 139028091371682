import React, { useState } from "react";
import { EntryPoints } from "../../api/Routes";
import ApiCall from "../../api/ApiCall";
import { toast } from "react-toastify";
import { EmailOutlined } from "@mui/icons-material";
import OtpInput from "otp-input-react";

const EmailVerify = ({
  phone,
  email,
  setemail,
  seterrorMessage,
  completeSecondStep,
}) => {
  const [loader, setLoader] = useState("");
  const [OTP, setOTP] = useState("");
  const [isShowOtp, setIsShowOtp] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [isEmailValid, setisEmailValid] = useState(true);

  const validateEmail = (e) => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setisEmailValid(true);
      setemail(e.target.value);
    } else {
      setemail(e.target.value);
      setisEmailValid(false);
    }
  };

  const startApiCall = () => {
    seterrorMessage("");
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
  };

  const handleSendOtpForSignUp = async (e) => {
    e.preventDefault();
    startApiCall();
    const data = {
      email,
    };
    const res = await ApiCall("post", EntryPoints.sendOtpForSignup, data);
    if (res?.success) {
      toast.success("Send OTP Successfully");
      setIsShowOtp(true);
      setLoader(false);
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  const handleVerifyOtpForSignUp = async (e) => {
    e.preventDefault();
    startApiCall();
    const data = {
      phone,
      email,
      otp: OTP,
      role: "admin",
    };
    const res = await ApiCall("post", EntryPoints.verifyOtpForSignup, data);
    if (res?.success) {
      toast.success("Verify OTP Successfully");
      setOTP("");
      setIsShowOtp(false);
      completeSecondStep();
      setLoader(false);
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <>
      {!isShowOtp && (
        <form onSubmit={handleSendOtpForSignUp}>
          <div class="form-detalis">
            <div class="input-main">
              <EmailOutlined style={{ color: "#303f9f" }} />

              <div class="be-main" style={{ marginLeft: "22px" }}></div>
              <div class="input-box">
                <input
                  type="email"
                  class="input"
                  required
                  placeholder="Email Address"
                  onChange={(e) => {
                    validateEmail(e);
                  }}
                  value={email}
                  style={{ marginLeft: "9px" }}
                />
              </div>

              {isEmailValid ? (
                <svg
                  class="icon-continue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              ) : (
                <>
                  <svg
                    style={{ color: "red" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      fill="red"
                    ></path>{" "}
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      fill="red"
                    ></path>{" "}
                  </svg>
                </>
              )}
            </div>
          </div>

          <button
            type="submit"
            class="continue"
            disabled={loader}
            style={{ opacity: loader ? 0.8 : "" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  marginTop: 10,
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {loader ? "Sending....." : "Send Otp"}
              </p>
            </div>
          </button>
        </form>
      )}
      {isShowOtp && (
        <form onSubmit={handleVerifyOtpForSignUp}>
          <div class="form-detalis">
            <p
              style={{
                fontSize: "16px",
                marginTop: 10,
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
            >
              Please enter the verification code that has been sent to your
              email address : {email}
            </p>
            <div class="input-main">
              <div class="input-box">
                <OtpInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  renderSeparator={<span>-</span>}
                  disabled={false}
                  shouldAutoFocus={true}
                  style={{ marginLeft: 10 }}
                />
              </div>
            </div>
            <button
              type="submit"
              class="continue"
              disabled={loader}
              style={{ opacity: loader ? 0.8 : "" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Loading...." : "Verify"}
                </p>
              </div>
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default EmailVerify;
