import { Box, Card, Grid, styled } from "@mui/material";
import { Cancel, CheckCircle } from "@material-ui/icons";
import ErrorIcon from "@mui/icons-material/Error";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BuildIcon from "@mui/icons-material/Build";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "17px !important",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.6,
    fontSize: "44px",
    color: theme.palette.primary.main,
  },
}));

const Heading = styled("h6")(({ theme }) => ({
  margin: 0,
  marginTop: "4px",
  fontSize: "24px",
  fontWeight: "500",
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const Title = styled("h6")(({ theme }) => ({
  margin: 0,
  marginTop: "4px",
  fontSize: "18px",
  fontWeight: "400",
  color: "#6c757d",
  fontFamily: "Montserrat, sans-serif",
}));
const ComplaintStatCard = (props) => {
  const {
    type,
    icon,
    graphStatus,
    setgraphStatus,
    countComplaints
  } = props;

  return (
    <Grid
      item
      xs={4}
      lg={4}
      sm={4}
      style={{ cursor: "pointer" }}
      onClick={() => {
        setgraphStatus(icon);
      }}
    >
      <StyledCard elevation={6}>
        <ContentBox>
          {icon === "all" && (
            <ErrorIcon
              style={{
                fontSize: "44px",
                color: "#2f45c5",
              }}
            />
          )}
          {icon === "unassigned" && (
            <GppMaybeIcon
              style={{
                fontSize: "44px",
                color: "#023e8a",
              }}
            />
          )}
          {icon === "assigned" && (
            <PriorityHighIcon
              style={{
                fontSize: "44px",
                color: "#004b23",
              }}
            />
          )}
          {icon === "resolved" && (
            <BuildIcon
              style={{
                fontSize: "44px",
                color: "#ff9500",
              }}
            />
          )}
          {icon === "closed" && (
            <CheckCircleIcon
              style={{
                fontSize: "44px",
                color: "#941b0c",
              }}
            />
          )}
          {icon === "cancel" && (
            <Cancel
              style={{
                fontSize: "44px",
                color: "rgb(239 13 39)",
              }}
            />
          )}

          <Box ml="12px">
            <Title>
              {type}
              {graphStatus && (
                <CheckCircle
                  style={{
                    color: "rgb(36 202 38)",
                    fontSize: "20px",
                    marginLeft: 5,
                  }}
                />
              )}
            </Title>
          </Box>
        </ContentBox>
        {icon === "all" && <Heading>{countComplaints?.allComplaints}</Heading>}
        {icon === "unassigned" && <Heading>{countComplaints?.newComplaints}</Heading>}
        {icon === "assigned" && <Heading>{countComplaints?.assignedComplaints}</Heading>}
        {icon === "resolved" && <Heading>{countComplaints?.resolvedComplaints}</Heading>}
        {icon === "closed" && <Heading>{countComplaints?.closedComplaints}</Heading>}
        {icon === "cancel" && <Heading>{countComplaints?.cancelledComplaints}</Heading>}
      </StyledCard>
    </Grid>
  );
};

export default ComplaintStatCard;
