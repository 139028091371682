import React, { useState, useContext, useEffect } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { AddCircle, Close } from "@material-ui/icons";
import useStyles from "../style";
import * as api from "../../../api/index";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { ErrorOutline } from "@material-ui/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { AuthContext } from "../../../contextAPI/context";
import { toast } from "react-toastify";
import CreateIcon from "@mui/icons-material/Create";

function AddEmergencyNumber(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const {
    addContactModal,
    setAddContactModal,
    handleGetEmegencyNumber,
    editEmergenyNumber,
    seteditEmergenyNumber,
    type,
    emergenyNumberData,
  } = props;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (type === "Edit") {
      setName(emergenyNumberData.name);
      setPhoneNumber(emergenyNumberData.phone);
    }
  }, [editEmergenyNumber]);

  const handlereset = () => {
    setAddContactModal(false);
    seterrorMsg("");
    setName("");
    setPhoneNumber("");
    setloader(false);
  };

  const addNewContact = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        society: fetchSocietyId(),
        name: name,
        phone: phoneNumber,
        createdBy: user?._id,
      };
      const res = await api.addEmergencyNumber(sendData);
      if (res?.data?.success) {
        toast.success("Conatct Added ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleGetEmegencyNumber();
        handlereset();
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleEditEmergencyNumber = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        emergencyContactId: emergenyNumberData?._id,
        name: name,
        phone: phoneNumber,
      };
      const res = await api.editEmergencyNumber(sendData);
      if (res?.data?.success) {
        toast.success("Emergency Number Edited ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        seteditEmergenyNumber(false);
        handleGetEmegencyNumber();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <div>
      <Modal open={type === "Edit" ? editEmergenyNumber : addContactModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            maxWidth: "400px",
            minWidth: "350px",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              {type === "Edit" ? "Edit Contact" : "Add New Contact"}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  if (type === "Edit") {
                    seteditEmergenyNumber(false);
                  } else {
                    handlereset();
                  }
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid container>
            <Grid container item xs={3}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Name :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "100%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <Grid container item xs={3}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Phone :&nbsp;
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "100%",
                  border: "1px solid #416082",
                  marginBottom: 2,
                  outline: 0,
                }}
                placeholder="Enter Phone Number"
                type="number"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
            </Grid>
          </Grid>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid
            container
            item
            style={{ justifyContent: "center", marginTop: 10 }}
          >
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #7D858D",
                width: "150px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => {
                if (type === "Edit") {
                  handleEditEmergencyNumber();
                } else {
                  addNewContact();
                }
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <>
                        {type === "Edit" ? (
                          <CreateIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                        ) : (
                          <AddCircle
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                        )}
                      </>
                    )}
                    {type === "Edit"
                      ? !loader
                        ? "Edit Contact"
                        : `Editing...`
                      : !loader
                      ? "Add Contact"
                      : `Adding...`}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddEmergencyNumber;
