import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import useStyles from "./style";
import { Error, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { AuthContext } from "../../contextAPI/context";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { Input } from "antd";
import * as api from "../../api/index";
import { AddCircle, Close, Schedule } from "@material-ui/icons";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../services/service";
import moment from "moment";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import SearchAddress from "../../GlobalComponents/SearchAddress/SearchAddress";

function AddMeeting(props) {
  const classes = useStyles();
  const {
    AddMeetingModal,
    setAddMeetingModal,
    handleMeeting,
    isEdited,
    setisEdited,
    editableMeeting,
    handleIndividualMeeting,
    setPage,
  } = props;
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [search, setsearch] = useState("");
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingStartDate, setMeetingStartDate] = useState("");
  const [meetingEndDate, setMeetingEndDate] = useState("");
  const [meetingStartTime, setMeetingStartTime] = useState("");
  const [meetingEndTime, setMeetingEndTime] = useState("");
  const [meetingDesc, setMeetingDesc] = useState("");
  const [meetingCategory, setMeetingCategory] = useState("Select Category");
  const [virtualAddress, setVirtualAddress] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [addressType, setAddressType] = useState("Select Address Type");
  const [categoryAddModal, setCategoryAddModal] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [meetingCategories, setmeetingCategories] = useState([]);
  const [meetingCategoryName, setmeetingCategoryName] = useState("");
  const { TextArea } = Input;
  const [loader, setloader] = useState(false);
  const [meetingViewerType, setmeetingViewerType] = useState("societyWise");
  var date = moment(new Date()).format("YYYY-MM-DD");

  const [propertyNames, setpropertyNames] = useState([]);

  //selection / property Modal
  const [propertyId, setPropertyId] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  useEffect(() => {
    if (editableMeeting) {
      setMeetingTitle(editableMeeting?.meetingTitle);
      setMeetingDesc(editableMeeting?.description);
      setAddressType(editableMeeting?.addressType);
      setMeetingCategory(editableMeeting?.category?._id);
      setPhysicalAddress(editableMeeting?.physicalAddress);
      setVirtualAddress(editableMeeting?.virtualAddress);
      let temp1 = [];
      let temp2 = [];
      editableMeeting?.properties?.map((pr) => {
        temp1.push(pr);
        temp2.push(pr.name);
      });
      setPropertyId(temp1);
      setpropertyNames(temp2);
      setMeetingStartDate(editableMeeting?.scheduleTime?.split("T")[0]);
      setMeetingEndDate(editableMeeting?.endMeetingTime?.split("T")[0]);
      setMeetingStartTime(
        moment(editableMeeting?.scheduleTime).format("HH:mm")
      );
      setMeetingEndTime(
        moment(editableMeeting?.endMeetingTime).format("HH:mm")
      );

      setmeetingViewerType(editableMeeting?.viewerType);
    }
  }, [isEdited]);

  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  const handlereset = () => {
    setAddMeetingModal(false);
    seterrorMsg("");
    setMeetingTitle("");
    setsearch("");
    setMeetingStartDate("");
    setMeetingEndDate("");
    setMeetingStartTime("");
    setMeetingEndTime("");
    setMeetingDesc("");
    setMeetingCategory("Select Category");
    setAddressType("Select Address Type");
    setmeetingViewerType("societyWise");
    setPropertyId([]);
    setVirtualAddress("");
    setPhysicalAddress("");
    setloader(false);
  };

  const isInvalidProperty = () => {
    let invalid = false;
    propertyId?.map((p) => {
      if (!p?.societyId) {
        invalid = true;
      } else {
        invalid = false;
      }
    });
    return invalid;
  };

  const addNewMeeting = async () => {
    setPage(1);
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (virtualAddress !== "" && !isUrlValid(virtualAddress)) {
      setloader(false);
      seterrorMsg("Please enter a valid URL for virtual address");
    } else if (virtualAddress === "" && physicalAddress?.length <= 0) {
      setloader(false);
      seterrorMsg("Please enter a address");
    } else if (
      addressType === "both" &&
      (virtualAddress === "" || physicalAddress === "")
    ) {
      setloader(false);
      seterrorMsg("Please enter both address");
    } else if (
      meetingViewerType === "propertyWise" &&
      propertyId?.length === 0
    ) {
      setloader(false);
      seterrorMsg("Please select atleast one property.");
    } else if (meetingCategory === "Select Category") {
      setloader(false);
      seterrorMsg("Please select a meeting category.");
    } else if (isInvalidProperty()) {
      setloader(false);
      seterrorMsg("Please select a valid property");
    } else {
      try {
        const sendMeetingData = {
          schedulingDate: meetingStartDate,
          schedulingTime: meetingStartTime,
          meetingTitle: meetingTitle,
          endDate: meetingEndDate,
          endTime: meetingEndTime,
          addedBy: user?._id,
          description: meetingDesc,
          category: meetingCategory,
          addressType: addressType,
          virtualAddress: virtualAddress,
          physicalAddress: physicalAddress,
          viewerType: meetingViewerType,
          societyId: fetchSocietyId(),
          propertyId: propertyId?.map((x) => {
            return x._id;
          }),
        };
        const res = await api.addMeetingData(sendMeetingData);
        if (res?.data?.success) {
          setAddMeetingModal(false);
          toast.success("Meeting has been Scheduled Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          handleMeeting();
          handlereset();
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  const editMeeting = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendMeetingData = {
        meetingId: editableMeeting?._id,
        schedulingDate: meetingStartDate,
        schedulingTime: meetingStartTime,
        meetingTitle: meetingTitle,
        endDate: meetingEndDate,
        endTime: meetingEndTime,
        addedBy: user?._id,
        description: meetingDesc,
        category: meetingCategory,
        addressType: addressType,
        virtualAddress: virtualAddress,
        physicalAddress: physicalAddress,
        viewerType: meetingViewerType,
        societyId: fetchSocietyId(),
        propertyId: propertyId?.map((x) => {
          return x._id;
        }),
      };
      const res = await api.updateMeeting(sendMeetingData);
      if (res?.data?.success) {
        setisEdited(false);
        handleIndividualMeeting();
        toast.success("Meeting has been Reschuled Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleAddMeetingCategory = async () => {
    seterrorMsg("");
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    try {
      const sendData = {
        name: meetingCategoryName,
        societyId: fetchSocietyId(),
      };
      const res = await api.addMeetingCategory(sendData);
      if (res?.data?.success) {
        handleMeetingCategory();
        setmeetingCategoryName("");
        toast.success("Meeting Category Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setCategoryAddModal(false);
        setloader2(false);
      }
    } catch (error) {
      setloader2(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleMeetingCategory = async () => {
    try {
      const res = await api.getMeetingCategory({
        params: {
          societyId: fetchSocietyId(),
        },
      });
      if (res?.data?.success) {
        setmeetingCategories(res?.data?.category);
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleMeetingCategory();
  }, []);

  return (
    <div>
      <Modal open={AddMeetingModal || isEdited}>
        <Paper
          style={{
            backgroundColor: "#ffff",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            maxHeight: "83vh",
            minWidth: "60vw",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              // textAlign: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Grid container item xs={8}>
              <span className="page-header">
                {isEdited ? "Reschedule Meeting" : "Schedule Meeting"}
              </span>
            </Grid>
            <Grid
              conatiner
              item
              xs={4}
              style={{
                textAlign: "end",
              }}
            >
              {!loader && (
                <IconButton
                  onClick={() => {
                    if (isEdited) {
                      setisEdited(false);
                    } else {
                      handlereset();
                    }
                  }}
                >
                  <Close
                    style={{
                      color: "#000",
                      fontSize: 20,
                      marginTop: -10,
                    }}
                    className="pointer"
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>

          {/* MEETING TITLE */}

          <div
            className="col-sm-12 d-flex justify-content-between scrollable"
            style={{ height: "66vh", overflowY: "auto", overflowX: "hidden" }}
          >
            <div className="col-sm-6 row p-3 mb-4">
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6"> Title</label>

                <input
                  className="form-control p-2"
                  type="text"
                  placeholder="Enter Meeting Title"
                  required
                  maxLength={150}
                  value={meetingTitle}
                  onChange={(event) => setMeetingTitle(event.target.value)}
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">Start Date</label>

                <input
                  className="form-control p-2"
                  type="date"
                  min={date}
                  placeholder="Choose Start Date"
                  onChange={(event) => {
                    setMeetingStartDate(event.target.value);
                    setMeetingEndDate("");
                  }}
                  value={meetingStartDate}
                />
              </div>

              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">End Date</label>

                <input
                  className="form-control p-2"
                  type="date"
                  min={meetingStartDate}
                  placeholder="Choose End Date"
                  onChange={(event) => setMeetingEndDate(event.target.value)}
                  value={meetingEndDate}
                />
              </div>

              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">Description</label>

                <TextArea
                  placeholder="Description here"
                  className="custom"
                  rows={7}
                  onChange={(event) => setMeetingDesc(event.target.value)}
                  value={meetingDesc}
                  maxLength={250}
                  disabled={isEdited}
                />
              </div>
              {(addressType === "virtual" || addressType === "both") && (
                <div className="col-sm-12 mb-3">
                  <label className="form-label fs-6">Virtual Address</label>

                  <input
                    className="form-control p-2"
                    type="text"
                    placeholder="Enter Virtual Address"
                    onChange={(event) => {
                      if (addressType === "virtual") {
                        setPhysicalAddress("");
                        setVirtualAddress(event.target.value);
                      } else {
                        setVirtualAddress(event.target.value);
                      }
                    }}
                    value={virtualAddress}
                  />
                </div>
              )}
              {(addressType === "physical" || addressType === "both") && (
                <div className="col-sm-12 mb-5 pb-3">
                  <label className="form-label fs-6"> Physical Address</label>

                  <input
                    className="form-control p-2"
                    type="text"
                    placeholder="Enter Physical Address"
                    onChange={(event) => {
                      if (addressType === "physical") {
                        setVirtualAddress("");
                        setPhysicalAddress(event.target.value);
                      } else {
                        setPhysicalAddress(event.target.value);
                      }
                    }}
                    value={physicalAddress}
                    maxLength={80}
                  />
                </div>
              )}
            </div>
            <div className="col-sm-6 row p-3 mb-4">
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">
                  {" "}
                  Choose Category :&nbsp;
                  {!isEdited && (
                    <AddCircle
                      title="Add Category"
                      onClick={() => {
                        seterrorMsg("");
                        setCategoryAddModal(true);
                      }}
                      style={{
                        color: "#2f3aa3",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </label>

                <Select
                  value={meetingCategory}
                  disabled={isEdited}
                  onChange={(event) => setMeetingCategory(event.target.value)}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                >
                  <MenuItem selected value="Select Category">
                    Select Category
                  </MenuItem>
                  {meetingCategories?.map((category) => {
                    return (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        selected
                        value={category?._id}
                      >
                        {category?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6"> Start Time</label>

                <input
                  type="time"
                  className="form-control p-2"
                  disabled={!meetingStartDate}
                  placeholder="Choose Start Time"
                  onChange={(event) => setMeetingStartTime(event.target.value)}
                  value={meetingStartTime}
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6"> End Time</label>

                <input
                  type="time"
                  className="form-control p-2"
                  disabled={!meetingEndDate}
                  placeholder="Choose End Time"
                  onChange={(event) => setMeetingEndTime(event.target.value)}
                  value={meetingEndTime}
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">Choose Viewer Type</label>

                <Select
                  value={meetingViewerType}
                  disabled={isEdited}
                  onChange={(event) => setmeetingViewerType(event.target.value)}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                  onClick={() => {
                    seterrorMsg("");
                  }}
                >
                  <MenuItem selected value="societyWise">
                    Society Wise
                  </MenuItem>
                  <MenuItem selected value="propertyWise">
                    {handlePropertyName()} Wise
                  </MenuItem>
                </Select>
              </div>
              {meetingViewerType === "propertyWise" && (
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">
                    {handlePropertyName()}
                  </label>

                  <SearchAddress
                    searchKey={searchKey}
                    setsearchKey={setsearchKey}
                    setPropertyId={setPropertyId}
                    propertyId={propertyId}
                    isMultiple={true}
                  />
                </div>
              )}
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6"> Choose Address Type</label>

                <Select
                  value={addressType}
                  onChange={(event) => setAddressType(event.target.value)}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                >
                  <MenuItem selected value="Select Address Type">
                    Select Address Type
                  </MenuItem>
                  <MenuItem selected value="virtual">
                    Virtual
                  </MenuItem>
                  <MenuItem selected value="physical">
                    Physical
                  </MenuItem>
                  <MenuItem selected value="both">
                    Both
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>

          <div className="col-sm-12 d-flex mt-2">
            <div className="col-sm-6">
              {errorMsg && (
                <Typography
                  style={{
                    marginTop: 20,
                    color: "red",
                    textAlign: "center",
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  <Error /> {errorMsg}
                </Typography>
              )}
            </div>

            <div className="col-sm-6 d-flex justify-content-end">
              {!isEdited ? (
                <Button
                  style={{
                    padding: "8px",
                    paddingLeft: 15,
                    paddingRight: 15,
                    width: "70%",
                    backgroundColor: "#3862DD",
                    color: "#fff",
                  }}
                  disabled={loader}
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (!loader) {
                      addNewMeeting();
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <Schedule
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}

                  {!loader ? "Schedule meeting" : `Scheduling...`}
                </Button>
              ) : (
                <Button
                  style={{
                    padding: "8px",
                    paddingLeft: 15,
                    paddingRight: 15,
                    width: "70%",
                    backgroundColor: "#3862DD",
                    color: "#fff",
                  }}
                  disabled={loader}
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (!loader) {
                      editMeeting();
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <Schedule
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}

                  {!loader ? "Reschule Meeting" : `Rescheduling...`}
                </Button>
              )}
            </div>
          </div>
        </Paper>
      </Modal>

      <Modal open={categoryAddModal}>
        <Paper
          style={{
            backgroundColor: "#ffff",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            width: "29vw",
            overflow: "auto",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <span className="page-header">Add Meeting Category</span>

            <IconButton
              onClick={() => {
                setCategoryAddModal(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <div className="col-sm-12 mb-2">
            <label className="form-label fs-6">Meeting Category</label>

            <input
              className="form-control p-2"
              type="text"
              placeholder="Enter Category Name"
              required
              value={meetingCategoryName}
              onChange={(event) => setmeetingCategoryName(event.target.value)}
            />
          </div>
          <div className="col-sm-12 d-flex row justify-content-center">
            {errorMsg && (
              <Typography
                style={{
                  marginTop: 20,
                  marginBottom: "8px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                <ErrorOutline style={{ marginRight: 3 }} /> {errorMsg}
              </Typography>
            )}

            <Button
              variant="contained"
              size="large"
              disabled={loader2}
              style={{
                padding: "8px",
                paddingLeft: 15,
                paddingRight: 15,
                width: "60%",
                backgroundColor: "#3862DD",
                color: "#fff",
              }}
              onClick={() => {
                handleAddMeetingCategory();
              }}
            >
              {loader2 && <LoadingOutlined />}&nbsp; + Add Category
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddMeeting;
