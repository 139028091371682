import React, { useContext, useEffect, useRef, useState } from "react";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import useStyle from "./styles";
import {
  Button,
  CssBaseline,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import JmrTable from "./JmrTable";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReactToPrint from "react-to-print";
import * as api from "../../../../api";
import { resolveErrorMessage } from "../../../../services/service";
import { AuthContext } from "../../../../contextAPI/context";
import { toast } from "react-toastify";
import Loading from "../../../../contextAPI/Loading";
import JmrInvoice from "../JMR/JMRInvoice/JmrInvoice";
import JmrBillInvoice from "../JMR/JMRInvoice/JmrBillInvoice";
import AddJmrItem from "./AddJmrItem";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { navigateToComplaintDetails } from "../../../../Navigation/Navigations";
import { Divider } from "@mui/material";

function JMR() {
  const navigate = useNavigate();
  const classes = useStyle();
  const { id, complaintType, complaintNo } = useParams();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const componentRef = useRef();
  const jmrInvoiceRef = useRef();
  const [vendorJmrFreezeConfirm, setvendorJmrFreezeConfirm] = useState(false);
  const [FinalJmrFreezeConfirm, setFinalJmrFreezeConfirm] = useState(false);
  const [pleasewait, setpleasewait] = useState(false);
  const [addJmrItem, setaddJmrItem] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [jmrItemData, setjmrItemData] = useState([]);
  const [jmrData, setjmrData] = useState([]);
  const [jmrItem, setjmrItem] = useState([]);
  const [page, setpage] = useState(1);
  const [pageLiimit, setpageLiimit] = useState(50);
  const [vendorId, setvendorId] = useState([]);
  const [vendorData, setvendorData] = useState([]);
  const [printIndividualIvoice, setprintIndividualIvoice] = useState([]);
  const [autoCompletesearchKey, setautoCompletesearchKey] = useState("");
  const [jmrItemPage, setjmrItemPage] = useState("");
  const [jmrItemTotalCount, setjmrItemTotalCount] = useState([]);

  const handleGetJmr = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        complaintId: id,
      };
      const res = await api.getJMR(sendData);
      if (res?.data?.success) {
        setjmrItemData(res?.data?.jmr?.vendors);
        setjmrData(res?.data?.jmr);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handlegetJmrItem = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        pageLimit: pageLiimit,
      };
      const res = await api.getJmrItem({ params: sendData });
      if (res.data.success) {
        setjmrItem(res.data?.item?.items);
        setjmrItemTotalCount(res.data?.item?.items?.length);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleFinalizeFullJmr = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        adminId: user?._id,
        jmrId: jmrData?._id,
        isFinalFreezed: true,
      };
      const res = await api.freezeFullJMR(sendData);
      setvendorJmrFreezeConfirm(false);
      if (res?.data?.success) {
        toast.success("JMR has been Finalized Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleGetJmr();
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  const handleJmrForVendor = async () => {
    setpleasewait(true);
    try {
      const sendData = {
        adminId: user?._id,
        vendorId: vendorId,
        jmrId: jmrData?._id,
        isFreezedAdmin: true,
      };
      const res = await api.freezeJMRForVendor(sendData);
      setvendorJmrFreezeConfirm(false);
      if (res?.data?.success) {
        toast.success("JMR has been Finalized Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleGetJmr();
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetJmr();
    handlegetJmrItem();
  }, []);

  const handleIndividualPrint = (jmr) => {
    setprintIndividualIvoice(jmr);
  };

  return (
    <div
      className={classes.root}
      style={{
        opacity: pleasewait ? 0.4 : "",
        pointerEvents: pleasewait ? "none" : "",
        overflow: pleasewait ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container>
                <Grid
                  container
                  item
                  xs={2}
                  style={{ justifyContent: "space-between", marginTop: 2 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginBottom: 15,
                    }}
                  >
                    JMR Details
                    {!pleasewait ? (
                      <RefreshIcon
                        title="refresh S2 Admin Approval"
                        onClick={handleGetJmr}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                      />
                    )}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  lg={10}
                  style={{ justifyContent: "flex-end" }}
                >
                  {jmrData?.complaint && (
                    <>
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "180px",
                          marginRight: "5px",
                          backgroundColor:
                            jmrData?.isFinalFreezed === false
                              ? "#2f45c5"
                              : "#7D858D",
                          color: "#ffffff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (jmrData?.isFinalFreezed === false) {
                            setFinalJmrFreezeConfirm(true);
                            setvendorJmrFreezeConfirm(true);
                          } else {
                            toast.error("Jmr is already finalized");
                          }
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <ThumbUpIcon
                              style={{
                                marginRight: 10,
                                fontSize: "15px",
                              }}
                            />
                            FINALIZE FULL JMR
                          </p>
                        </div>
                      </div>

                      <Grid item>
                        <ReactToPrint
                          trigger={() => (
                            <div
                              class="card"
                              style={{
                                height: 30,
                                boxShadow: "0 3px 6px #4a6078",
                                width: "180px",
                                marginRight: "5px",
                                backgroundColor: "#2f45c5",
                                color: "#ffffff",
                                cursor: "pointer",
                              }}
                            >
                              <div class="card-body ">
                                <p
                                  class="card-text"
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    marginTop: "-12px",
                                    marginBottom: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <LocalPrintshopIcon
                                    style={{
                                      marginRight: 10,
                                      fontSize: "15px",
                                    }}
                                  />
                                  Generate Invoice
                                </p>
                              </div>
                            </div>
                          )}
                          content={() => componentRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <JmrInvoice
                            ref={componentRef}
                            jmrItemData={jmrItemData}
                            jmrData={jmrData}
                            complaintNo={complaintNo}
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <ReactToPrint
                          trigger={() => (
                            <div
                              class="card"
                              style={{
                                height: 30,
                                boxShadow: "0 3px 6px #4a6078",
                                width: "180px",
                                marginRight: "5px",
                                backgroundColor: "#2f45c5",
                                color: "#ffffff",
                                cursor: "pointer",
                              }}
                            >
                              <div class="card-body ">
                                <p
                                  class="card-text"
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    marginTop: "-12px",
                                    marginBottom: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <LocalPrintshopIcon
                                    style={{
                                      marginRight: 10,
                                      fontSize: "15px",
                                    }}
                                  />
                                  Generate Bill
                                </p>
                              </div>
                            </div>
                          )}
                          content={() => jmrInvoiceRef.current}
                        />
                        <div style={{ display: "none" }}>
                          <JmrBillInvoice ref={jmrInvoiceRef} />
                        </div>
                      </Grid>
                    </>
                  )}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        navigateToComplaintDetails({
                          complaintType,
                          complaintId: id,
                        })
                      );
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        BACK
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                  }}
                >
                  Complaint Number:{" "}
                  <span style={{ fontWeight: "normal" }}>{complaintNo}</span>
                </Typography>
                {jmrData?.complaint && (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "#000",
                        marginBottom: 10,
                      }}
                    >
                      Complaint Title:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {jmrData?.complaint?.complaintTitle}
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "#000",
                        marginBottom: 10,
                      }}
                    >
                      Complainer Email:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {jmrData?.complaint?.complainerEmail}
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "#000",
                        marginBottom: 10,
                      }}
                    >
                      Complainer Address:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {jmrData?.complaint?.complaintAddress}
                      </span>
                    </Typography>
                  </>
                )}
              </Grid>
              <Divider
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  backgroundColor: "#000",
                  width: "23%",
                }}
              />
              {jmrData?.complaint ? (
                <Grid container>
                  {jmrItemData.map((jmr) => {
                    return (
                      jmr?.itemDetails?.length > 0 && (
                        <>
                          <Grid container xs={12}>
                            <Grid
                              container
                              item
                              direction="column"
                              xs={6}
                              style={{
                                justifyContent: "space-between",
                                marginTop: "20px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  color: "#000",
                                  marginBottom: 10,
                                }}
                              >
                                Vendor Name:{" "}
                                <span style={{ fontWeight: "normal" }}>
                                  {jmr?.vendor?.name}
                                </span>
                              </Typography>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  color: "#000",
                                  marginBottom: 10,
                                }}
                              >
                                Vendor Email:{" "}
                                <span style={{ fontWeight: "normal" }}>
                                  {jmr?.vendor?.email}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              style={{
                                justifyContent: "flex-end",
                                marginTop: "40px",
                              }}
                            >
                              {/* <Grid item>
                                <ReactToPrint
                                  trigger={() => (
                                    <div
                                      class="card"
                                      style={{
                                        height: 30,
                                        boxShadow: "0 3px 6px #4a6078",
                                        width: "50px",
                                        marginRight: "5px",
                                        backgroundColor: "#2f45c5",
                                        color: "#ffffff",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleIndividualPrint(jmr);
                                      }}
                                    >
                                      <div class="card-body ">
                                        <p
                                          class="card-text"
                                          style={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                            marginTop: "-12px",
                                            marginBottom: "20px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <ArrowCircleDownIcon
                                            style={{
                                              marginRight: 10,
                                              fontSize: "19px",
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  content={() => componentRef.current}
                                />
                                <div style={{ display: "none" }}>
                                  <JmrIndividualInvoice
                                    ref={componentRef}
                                    jmr={jmr}
                                    jmrData={jmrData}
                                    complaintNo={complaintNo}
                                  />
                                </div>
                              </Grid> */}
                              <div
                                class="card"
                                style={{
                                  height: 30,
                                  boxShadow: "0 3px 6px #4a6078",
                                  width: "230px",
                                  marginRight: "5px",
                                  backgroundColor:
                                    jmrData?.isFinalFreezed === false &&
                                    jmr?.isFreezedAdmin === false
                                      ? "#2f45c5"
                                      : "#7D858D",
                                  color: "#ffffff",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    jmrData?.isFinalFreezed === false &&
                                    jmr?.isFreezedAdmin === false
                                  ) {
                                    setFinalJmrFreezeConfirm(false);
                                    setvendorJmrFreezeConfirm(true);
                                    setvendorId(jmr?.vendor?._id);
                                  } else {
                                    toast.error(
                                      "Jmr is already finalized for Vendor"
                                    );
                                  }
                                }}
                              >
                                <div class="card-body ">
                                  <p
                                    class="card-text"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      fontFamily: "Montserrat, sans-serif",
                                      marginTop: "-12px",
                                      marginBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <ThumbUpIcon
                                      style={{
                                        marginRight: 10,
                                        fontSize: "15px",
                                      }}
                                    />
                                    FINALIZE JMR FOR VENDOR
                                  </p>
                                </div>
                              </div>
                              <div
                                class="card"
                                style={{
                                  height: 30,
                                  boxShadow: "0 3px 6px #4a6078",
                                  width: "150px",
                                  marginRight: "5px",
                                  backgroundColor:
                                    jmrData?.isFinalFreezed === false
                                      ? "#2f45c5"
                                      : "#7D858D",
                                  color: "#ffffff",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (jmrData?.isFinalFreezed === false) {
                                    setaddJmrItem(true);
                                    setvendorId(jmr?.vendor?._id);
                                    setvendorData(jmr);
                                  } else {
                                    toast.error(
                                      "Jmr is already finalized for Vendor"
                                    );
                                  }
                                }}
                              >
                                <div class="card-body ">
                                  <p
                                    class="card-text"
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      fontFamily: "Montserrat, sans-serif",
                                      marginTop: "-12px",
                                      marginBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginRight: 10,
                                        fontSize: "15px",
                                      }}
                                    />
                                    Add Jmr Item
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          <JmrTable
                            jmrItemData={jmrItemData}
                            jmrItem={jmrItem}
                            jmrData={jmrData}
                            handleGetJmr={handleGetJmr}
                            jmr={jmr}
                            complaintNo={complaintNo}
                          />
                          <Grid
                            container
                            style={{
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 16,
                                color: "#000",
                                marginBottom: 10,
                              }}
                            >
                              Sub Total: ₹{" "}
                              <span style={{ fontWeight: "normal" }}>
                                {jmr?.totalPriceForVendorJmr}{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 16,
                                color: "#000",
                                marginBottom: 10,
                              }}
                            >
                              Total No Of Items:{" "}
                              <span style={{ fontWeight: "normal" }}>
                                {jmr?.itemDetails?.length}{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </Typography>
                          </Grid>
                          <Grid container justifyContent={"center"}>
                            <Divider
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                backgroundColor: "#000",
                                width: "80%",
                              }}
                            />
                          </Grid>
                        </>
                      )
                    );
                  })}
                </Grid>
              ) : (
                <Grid container justifyContent={"center"}>
                  {" "}
                  <Typography
                    style={{
                      color: "#00042A",
                      marginBottom: 6,
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: 10,
                      fontWeight: "bold",
                    }}
                  >
                    No JMR
                  </Typography>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        {vendorJmrFreezeConfirm && (
          <Modal open={vendorJmrFreezeConfirm}>
            <Paper className={classes.deleteModal}>
              <Typography
                style={{
                  color: "#000",
                  fontSize: 15,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {FinalJmrFreezeConfirm
                  ? "Are you sure you want to finalize this JMR?"
                  : "Are you sure you want to finalize vendor's JMR?"}
              </Typography>
              <Grid container style={{ justifyContent: "space-evenly" }}>
                <Button
                  onClick={() => {
                    setvendorJmrFreezeConfirm(false);
                  }}
                  className={classes.buttonDelete}
                >
                  NO
                </Button>
                <Button
                  onClick={() => {
                    if (FinalJmrFreezeConfirm) {
                      handleFinalizeFullJmr();
                    } else {
                      handleJmrForVendor();
                    }
                  }}
                  className={classes.buttonDelete}
                >
                  YES
                </Button>
              </Grid>
            </Paper>
          </Modal>
        )}
        <AddJmrItem
          addJmrItem={addJmrItem}
          setaddJmrItem={setaddJmrItem}
          jmrItem={jmrItem}
          jmrData={jmrData}
          handleGetJmr={handleGetJmr}
          vendorId={vendorId}
          vendorData={vendorData}
          jmrItemTotalCount={jmrItemTotalCount}
          setjmrItemPage={setjmrItemPage}
          setautoCompletesearchKey={setautoCompletesearchKey}
          pleasewait={pleasewait}
        />
      </main>
      {pleasewait && <Loading />}
    </div>
  );
}

export default JMR;
