import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import useStyles from "../styles";

const ComplaintSubVendorInfoModal = (props) => {
  const classes = useStyles();
  const { subVendors, openSubVendorInfo, setopenSubVendorInfo, setsubVendors } =
    props;

  return (
    <Modal open={openSubVendorInfo}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          maxWidth: "550px",
          minWidth: "300px",
          height: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Process Details
          </Typography>

          <IconButton
            onClick={() => {
              setopenSubVendorInfo(false);
              setsubVendors([]);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid item xs={12}>
          {subVendors?.map((vd, index) => {
            return (
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {index + 1}. {vd?.name}
              </Typography>
            );
          })}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ComplaintSubVendorInfoModal;
