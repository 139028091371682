import { Close } from "@material-ui/icons";
import { Divider, IconButton, Modal, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChooseSubCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseSubCategoryDropDown";
import ChooseSubSubCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseSubSubCategoryDropDown";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { toast } from "react-toastify";
import { getPlantWiseRoleSegregation } from "../../../../../GlobalFunctions/GlobalFunctions";

function SubSubCategoryModal({
  selectProcess,
  classes,
  setselectProcess,
  complaint,
  user,
  setupdateProcecssModal,
  handlegetComplaintById,
  plant
}) {
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [subcategory, setsubcategory] = useState("Select Subcategory");
  const [searchKey, setsearchKey] = useState("");
  const [subsubCategory, setsubsubCategory] = useState(
    "Select Sub-Subcategory"
  );

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleUpdateSubSubCategory = async () => {
    callingApi();
    seterrorMessage("");
    if (subsubCategory === "Select Sub-Subcategory") {
      seterrorMessage("Please select a sub sub-category");
      setloader(false);
    } else {
      const sendData = {
        complaintId: complaint?._id,
        subsubComplaintId: subsubCategory?._id,
        deadline: subsubCategory?.deadline,
      };
      const sendData2 = {
        complaintId: complaint?._id,
        complainerId: complaint?.complainer._id,
        complaintLifeCycle: "Sub-Sub Complaint Category",
        adminId: user?._id,
        updatedBy: getPlantWiseRoleSegregation(user,plant),
        updatedFor: complaint?.creatorRole?complaint?.creatorRole:complaint?.complainer?.role,
      };
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.updateSubSubCategory,
        sendData
      );
      if (res?.success) {
        const res2 = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.complaintLifeCycle,
          sendData2
        );
        toast.success("Sub-Subcategory Updated Successfully");
        seterrorMessage("");
        setselectProcess("Select Process");
        setupdateProcecssModal(false);
        handlegetComplaintById();
        setloader(false);
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  useEffect(() => {
    if (complaint?.category?._id) {
      setsubcategory(complaint?.subcomplaintCategory);
    }
  }, [complaint]);

  return (
    <Modal
      open={selectProcess === "Sub-Subcategory"}
      style={{ pointerEvents: loader ? "none" : "" }}
    >
      <Paper
        style={{
          backgroundColor: "#fff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "400px",
          maxWidth: "40vw",
        }}
        className={classes.ModalView}
      >
        <div className="col-sm-12 d-flex text-align-center justify-content-between">
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
            }}
            className="fw-bold fs-6"
          >
            Type of Compliant
          </span>

          <IconButton
            onClick={() => {
              setselectProcess("Select Process");
              seterrorMessage("");
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </div>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <div className="col-sm-12 d-flex mt-3">
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-3 fs-6"
            >
              Sub-Category
            </span>
          </div>
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-3 fs-6"
            >
              Sub-Subcategory
            </span>
          </div>
        </div>
        <div className="col-sm-12 d-flex ms-2">
          <div className="col-sm-6 mt-2">
            <ChooseSubCategoryDropDown
              setSubCategory={setsubcategory}
              subCategory={subcategory}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              category={complaint?.category}
              isDisabled={true}
            />
          </div>
          <div className="col-sm-6 mt-2">
            <ChooseSubSubCategoryDropDown
              subCategory={subcategory}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              subsubCategory={subsubCategory}
              setsubsubCategory={setsubsubCategory}
            />
          </div>
        </div>
        {subsubCategory?._id && (
          <span
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#283f59",
              fontSize: "13px",
            }}
          >
            Total Deadline Hours : &nbsp;
            {subsubCategory?.deadline} Hours
          </span>
        )}

        {errorMessage && (
          <span className="col-sm-12 d-flex justify-content-center text-align-center text-danger fw-bold fs-6">
            {errorMessage}
          </span>
        )}
        <div className="col-sm-12 d-flex justify-content-center">
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "120px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={handleUpdateSubSubCategory}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {!loader ? "SAVE" : `Adding...`}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default SubSubCategoryModal;
