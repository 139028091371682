import React from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import useStyles from "./style";
import { BASE_URL } from "../../utils/base";
import {
  handlePropertyName,
  handleFloorName,
  handleFlatName,
} from "../../contextAPI/NamingConvention";

function FlatDetailsModal(props) {
  const classes = useStyles();
  const {
    isOpenFlatDetailsModal,
    setisOpenFlatDetailsModal,
    flatInfo,
    setremovedUser,
    setremoveResident,
    flatName,
    propertyName,
    isReadWrite,
  } = props;
  return (
    <div>
      <Modal open={isOpenFlatDetailsModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            minWidth: "300px",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Grid container item xs={8}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#2f45c5",
                  fontFamily: "Montserrat, sans-serif",
                  textAlign: "start",
                  marginLeft: 10,
                  fontSize: "1rem",
                  opacity: 1,
                }}
              >
                {handleFlatName()} Information
              </Typography>
            </Grid>
            <Grid
              conatiner
              item
              xs={4}
              style={{
                textAlign: "end",
              }}
            >
              <IconButton
                onClick={() => {
                  setisOpenFlatDetailsModal(false);
                }}
              >
                <Close
                  style={{
                    color: "#000",
                    fontSize: 20,
                    marginTop: -10,
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>

          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />

          <Typography
            style={{
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "0.9rem",
              marginLeft: 4,
              marginBottom: 4,
              marginTop: 8,
            }}
          >
            {handleFlatName()} Name :{" "}
            <span style={{ fontWeight: "bold" }}>{flatName}</span>
          </Typography>
          <Typography
            style={{
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "0.9rem",
              marginLeft: 4,
              marginBottom: 4,
              marginTop: 8,
            }}
          >
            {handlePropertyName()} Name :
            <span style={{ fontWeight: "bold" }}> {propertyName}</span>
          </Typography>
          <Typography
            style={{
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "0.9rem",
              marginLeft: 4,
              marginBottom: 4,
              marginTop: 8,
            }}
          >
            {handleFloorName()} Name :
            <span style={{ fontWeight: "bold" }}> Ground Floor</span>
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              fontWeight: "bold",
              color: "#2f45c5",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "13px",
              opacity: 1,
            }}
          >
            Current Residing Information
          </Typography>
          <Divider
            style={{
              marginTop: 6,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid container>
            <Typography
              style={{
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "0.9rem",
                marginLeft: 4,
                marginBottom: 4,
              }}
            >
              Current Owner{" "}
              {flatInfo?.owner
                ? `(${
                    flatInfo?.owner?.isResiding ? "Residing" : "Non-Residing"
                  })`
                : ""}
              :&nbsp;
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              {flatInfo?.owner ? (
                <>
                  <Tooltip
                    title={
                      <>
                        <p>
                          Name : {flatInfo?.owner?.name} (
                          {flatInfo?.owner?.role})
                        </p>
                        <p>Phone : {flatInfo?.owner?.phone}</p>
                      </>
                    }
                  >
                    <img
                      style={{
                        height: "26px",
                        width: "26px",
                        border: "1px solid #e6e7f0",
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                      src={
                        flatInfo?.owner?.image
                          ? `${BASE_URL}/user/${flatInfo?.owner?.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      alt="Sociohood"
                    />
                  </Tooltip>
                  &nbsp;
                  <span style={{ color: "#2f45c5" }}>
                    {flatInfo?.owner?.name}
                  </span>{" "}
                  {isReadWrite && (
                    <span
                      onClick={() => {
                        setremoveResident(true);
                        setremovedUser(flatInfo?.owner);
                      }}
                      style={{
                        textDecoration: "underline",
                        fontSize: "11px",
                        cursor: "pointer",
                        fontWeight: "normal",
                      }}
                    >
                      remove owner ?
                    </span>
                  )}
                </>
              ) : (
                "at present no owner exist"
              )}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "0.9rem",
                marginLeft: 4,
                marginBottom: 4,
              }}
            >
              Current Tenant{" "}
              :
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              {flatInfo?.tenants?.length > 0 ? (
                <>
                  {flatInfo?.tenants?.map((tn, index) => {
                    return (
                      <>
                        <Tooltip
                          title={
                            <>
                              <p>
                                Name : {tn?.name} ({tn?.role})
                              </p>
                              <p>Phone : {tn?.phone}</p>
                            </>
                          }
                        >
                          <img
                            style={{
                              height: "26px",
                              width: "26px",
                              border: "1px solid #e6e7f0",
                              cursor: "pointer",
                              borderRadius: "50%",
                            }}
                            src={
                              tn?.image
                                ? `${BASE_URL}/user/${tn?.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            alt="Sociohood"
                          />
                        </Tooltip>
                        &nbsp;
                        <span style={{ color: "#2f45c5" }}>
                          {tn?.name}
                        </span>{" "}
                        {isReadWrite && (
                          <span
                            onClick={() => {
                              setremoveResident(true);
                              setremovedUser(tn);
                            }}
                            style={{
                              textDecoration: "underline",
                              fontSize: "11px",
                              cursor: "pointer",
                              fontWeight: "normal",
                            }}
                          >
                            remove tenant ?
                          </span>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                "at present no tenant exist"
              )}
            </Typography>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default FlatDetailsModal;
