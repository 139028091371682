import React, { useState } from "react";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import { getPlantWiseRoleSegregation } from "../../../../../GlobalFunctions/GlobalFunctions";

const CancelComplaint = (props) => {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    setupdateProcecssModal,
    user,
    classes,
    handlegetComplaintById,
    plant
  } = props;

  const { TextArea } = Input;
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [message, setmessage] = useState("");

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setmessage("");
    seterrorMessage("");
    setloader(false);
  };

  const handleCancelComplaint = async () => {
    if (message.trim() === "" || message?.length < 8) {
      seterrorMessage(
        "Please type a cancel reason first of at least 8 characters"
      );
    } else {
      callingApi();
      const sendDataForLifeCycle = {
        complaintId: complaint?._id,
        complainerId: complaint?.complainer._id,
        complaintLifeCycle: "Complaint Cancelled",
        adminId: user._id,
        reasonDesc: message,
        updatedBy: getPlantWiseRoleSegregation(user, plant),
        updatedFor: complaint?.creatorRole?complaint?.creatorRole:complaint?.complainer?.role,
      };
      const sendDataForCancel = {
        complaintId: complaint?._id,
        societyId: fetchSocietyId(),
        adminId: user?._id,
      };
      const res2 = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.complaintLifeCycle,
        sendDataForLifeCycle
      );
      if (res2?.success) {
        const res = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.cancelComplaint,
          sendDataForCancel
        );
        handleReset();
        toast.success("Complaint Cancelled Successfully");
      } else {
        setloader(false);
        seterrorMessage(res2.error);
      }
    }
  };

  return (
    <Modal open={selectProcess === "Cancel Complaint"}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          minWidth: "250px",
          maxWidth: "450px",
          pointerEvents: loader ? "none" : "",
        }}
        className={classes.confirmModal}
      >
        <Typography
          style={{
            color: "#000",
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "capitalize",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          Complaint Current Status :{" "}
          {complaint?.status === "unassigned" && (
            <span
              style={{
                padding: 7,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              class={"badge bg-warning"}
            >
              {complaint?.status}
            </span>
          )}
          {complaint?.status === "assigned" && (
            <span
              style={{
                padding: 7,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              class={"badge bg-info"}
            >
              {complaint?.status}
            </span>
          )}
          {complaint?.status === "resolved" && (
            <span
              style={{
                padding: 7,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              class={"badge bg-success"}
            >
              {complaint?.status}
            </span>
          )}
          {complaint?.status === "closed" && (
            <span
              style={{
                padding: 7,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              class={"badge bg-danger"}
            >
              {complaint?.status}
            </span>
          )}
          {complaint?.status === "cancel" && (
            <span
              style={{
                padding: 7,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              class={"badge bg-danger"}
            >
              {complaint?.status}
            </span>
          )}
        </Typography>
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 10,
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          ARE YOU SURE YOU WANT TO CANCEL THIS COMPLAINT ?
        </Typography>

        <TextArea
          style={{
            marginTop: 10,
            height: 100,
            borderRadius: 10,
          }}
          placeholder="Type a reason for cancellation complaint"
          onChange={(event) => setmessage(event.target.value)}
        />

        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          A confirmation message will appear when this process is complete.You
          can confirm by clicking below.
        </Typography>
        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            disabled={loader}
            onClick={() => {
              seterrorMessage("");
              setmessage("");
              setselectProcess("Select Process");
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleCancelComplaint}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CONFIRM
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default CancelComplaint;
