import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "../style";
import { Close, ErrorOutline } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";
import * as api from "../../../api/index";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { AuthContext } from "../../../contextAPI/context";
import CreateIcon from "@mui/icons-material/Create";

function AddCategory(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const {
    addCategoryModal,
    setAddCategoryModal,
    handleGetDirectoryCategory,
    setEditCategory,
    editCategory,
    type,
    cate,
  } = props;
  const [loader, setloader] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (type === "Edit") {
      setCategoryName(cate?.category?.name);
    }
  }, [editCategory]);

  const handleAddCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (categoryName.length > 30) {
      setloader(false);
      seterrorMsg("Please write a category name under 30 letters");
    } else {
      try {
        let sendData = {
          name: categoryName,
          createdBy: user?._id,
          society: fetchSocietyId(),
        };
        const res = await api.addLocalDirectoryCategory(sendData);
        if (res?.data?.success) {
          toast.success("Category Added ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          handlereset();
          handleGetDirectoryCategory();
          setloader(false);
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };
  const handleEditCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (categoryName.length > 30) {
      setloader(false);
      seterrorMsg("Please write a category name under 30 letters");
    } else {
      try {
        let sendData = {
          localDirectoryCategoryId: cate?.category?._id,
          name: categoryName,
        };
        const res = await api.editLocalDirectoryCategory(sendData);
        if (res?.data?.success) {
          toast.success("Category Edited ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setEditCategory(false);
          handleGetDirectoryCategory();
          setloader(false);
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  const handlereset = () => {
    setCategoryName("");
    setAddCategoryModal(false);
  };

  return (
    <div>
      <Modal open={type === "Edit" ? editCategory : addCategoryModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            maxWidth: "400px",
            minWidth: "300px",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              {type === "Edit" ? "Edit Category" : " Add Category"}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  if (type === "Edit") {
                    setEditCategory(false);
                  } else {
                    handlereset();
                  }
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />

          <Grid container style={{ justifyContent: "center" }}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 16,
                marginLeft: 4,
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              Category
            </Typography>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 2,
                outline: 0,
              }}
              placeholder="Enter Category "
              onChange={(event) => setCategoryName(event.target.value)}
              value={categoryName}
            />
          </Grid>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}

          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "130px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                if (type === "Edit") {
                  handleEditCategory();
                } else {
                  handleAddCategory();
                }
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                    opacity: loader ? 0.5 : "",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <>
                      {type === "Edit" ? (
                        <CreateIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      ) : (
                        <AddCircleIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                      )}
                    </>
                  )}

                  {type === "Edit"
                    ? !loader
                      ? "Edit"
                      : `Editing...`
                    : !loader
                    ? "Add"
                    : `Adding...`}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddCategory;
