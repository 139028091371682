import * as api from "../api";

export const fetchSocietyId = () => {
  return localStorage.getItem("societyId");
};
export const fetchSocietyUniqueId = () => {
  return localStorage.getItem("societyUniqueId");
};
export const fetchSocietyDetails = async (key) => {
  try {
    const res = await api.fetchSocietyDetailsById({
      params:{ societyId:localStorage.getItem("societyId")} ,
    });
    return res.data.society[`${key}`]
  } catch (error) {
    console.log(error)
  }

};
