import React, { useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import * as api from "../../../../api/index";
import useStyles from "./styles";
import { Typography, Grid, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Apartment, KeyboardBackspaceRounded } from "@material-ui/icons";
import PropertyTable from "./PropertyTable";
import { AuthContext } from "../../../../contextAPI/context";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { Pagination } from "@mui/material";
import AddProperty from "./AddProperty";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../../../services/service";
import { handlePropertyName } from "../../../../contextAPI/NamingConvention";
import { navigateToSocietyManagement } from "../../../../Navigation/Navigations";
import { getAllPropertyFlatType } from "../../../../GlobalFunctions/GlobalFunctions";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const Properties = () => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions } = authState;
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const navigate = useNavigate();
  const { societyName } = useParams();
  const [count, setCount] = React.useState(0);
  const [selectedType, setselectedType] = useState("All Type");
  const [page, setPage] = useState(1);
  const [allProperties, setallProperties] = useState([]);
  const [addPropertyModal, setaddPropertyModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [allPropertyTypes, setallPropertyTypes] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Manage"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    handleGetProperties();
  }, [selectedType, page, debouncedSearch]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetProperties = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
        pageLimit: 100,
        type: selectedType === "All Type" ? "" : selectedType,
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        setCount(res.data?.properties?.totalPages);
        settotalCount(res.data?.properties?.totalCount);
        setallProperties(res.data?.properties?.properties);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleGetPropertyTypes = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    let sendData = {
      type: "property",
      societyId: fetchSocietyId(),
    };
    const res = await getAllPropertyFlatType(sendData);

    setallPropertyTypes(res);
    setloader2(false);
  };

  useEffect(() => {
    handleGetPropertyTypes();
  }, []);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      setDebouncedSearch("");
    }
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        opacity: loader || loader2 ? 0.7 : "",
        pointerEvents: loader || loader2 ? "none" : "",
        overflow: loader || loader2 ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {`Society Management > `}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(navigateToSocietyManagement({}));
                      }}
                    >
                      Society
                    </span>
                    <span style={{ color: "#000" }}>
                      {/* {` > Property `} */}
                      {` > ${handlePropertyName()}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginRight: 15,
                      }}
                    >
                      Select Type :
                      <Select
                        value={selectedType}
                        onChange={(event) => {
                          setPage(1);
                          setselectedType(event.target.value);
                        }}
                        style={{
                          color: "#212529",
                          backgroundColor: "#fff",
                          fontWeight: "bold",
                          borderRadius: 6,
                          width: "150px",
                          height: 34,
                          fontSize: "13px",
                          padding: 8,
                          paddingLeft: 6,
                          marginLeft: 8,
                          border: "1px solid #6c757d",
                          textAlign: "center",
                        }}
                      >
                        <MenuItem
                          selected
                          value="All Type"
                          style={{
                            color: "#6c757d",
                            fontFamily: "Montserrat,sans-serif",
                          }}
                        >
                          {loader2 ? "Loading..." : "All Type"}
                        </MenuItem>
                        {allPropertyTypes?.map((pr, index) => {
                          return (
                            <MenuItem
                              selected
                              value={pr.name}
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  marginTop: 3,
                                  color: "#6c757d",
                                }}
                              >
                                {index + 1}.
                              </span>
                              &nbsp;{pr.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Typography>
                    {isReadWrite && (
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "170px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginRight: 15,
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              setaddPropertyModal(true);
                            }}
                          >
                            <Apartment
                              style={{
                                marginRight: 10,
                                fontSize: "15px",
                              }}
                            />
                            Add {handlePropertyName()}
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "90px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                      onClick={() => {
                        navigate(navigateToSocietyManagement({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <div class="box">
                  <input
                    type="text"
                    placeholder="search by name"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </Grid>

              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 12,
                    marginTop: -8,
                  }}
                >
                  Society Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {societyName}
                  </span>
                </Typography>
                <Typography
                  style={{
                    color: "#283f59",
                    fontSize: 14,
                    marginRight: 12,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <TravelExploreIcon />
                  &nbsp; total result{" "}
                  <span style={{ fontSize: 15 }}>{totalCount}</span>{" "}
                  {handlePropertyName()}
                </Typography>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <PropertyTable
                  allProperties={allProperties}
                  page={page}
                  societyName={societyName}
                  user={user}
                  handleGetProperties={handleGetProperties}
                />
              </Grid>
              {allProperties?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No {handlePropertyName()}
                  </Typography>
                </Grid>
              )}
              {/* {loader && (
                <Typography
                  style={{
                    marginBottom: 10,
                    textAlign: "center",
                    fontSize: 15,
                    color: "#2f45c5",
                  }}
                >
                  page is loading.... <LoadingOutlined />
                </Typography>
              )} */}

              {allProperties?.length > 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading Property...
          </Typography>
        </Grid>
      )}
      <AddProperty
        addPropertyModal={addPropertyModal}
        setaddPropertyModal={setaddPropertyModal}
        handleGetProperties={handleGetProperties}
        allPropertyTypes={allPropertyTypes}
      />
    </div>
  );
};

export default Properties;
