import React, { useState, useEffect, useContext } from "react";
import {
  CssBaseline,
  Typography,
  Grid,
  Paper,
  Select,
  MenuItem,
  Button,
  Modal,
} from "@material-ui/core";
import { BASE_URL } from "../../../utils/base";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../api/index";
import { QuestionMark } from "@mui/icons-material";
import { Timer, CheckCircle, Home, InfoOutlined } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import Countdown from "react-countdown";
import { TimerOff } from "@material-ui/icons";
import { Tag } from "antd";
import { toast } from "react-toastify";
import PropertyInfo from "../../../contextAPI/PropertyInfo";
import moment from "moment";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../../services/service";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import { navigateInitialPoll } from "../../../Navigation/Navigations";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";

function PollDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [pollDetails, setPollDetails] = useState([]);
  const [loader, setloader] = useState(false);
  const [searchKey, setsearchKey] = useState("");
  const [filterOptions, setfilterOptions] = useState("all");
  const [page, setPage] = useState([1]);
  const [pollOptions, setpollOptions] = useState([]);
  const [countDown, setcountDown] = useState(1000);
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [confirmDeletePoll, setconfirmDeletePoll] = useState(false);
  const [openPropertyInfoModal, setopenPropertyInfoModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Poll")
      );
    }
  }, [usermoduleswithpermissions]);

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleTimer = (today, expireDay) => {
    if (expireDay) {
      let s = new Date(expireDay.toLocaleString());
      let t = new Date(s) - new Date(today);
      setcountDown(t);
    }
  };

  const confirmModalDeletePoll = (
    <div>
      {confirmDeletePoll && (
        <Modal open={confirmDeletePoll}>
          <Paper
            style={{ backgroundColor: "#e6e7f0" }}
            className={classes.confirmModal}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
                textTransform: "uppercase",
              }}
            >
              ARE YOU SURE YOU WANT TO DELETE THIS POLL ?
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              A confirmation message will appear when this process is
              complete.You can confirm by clicking below.
            </Typography>
            {loader && (
              <Typography
                style={{
                  color: "#6c757d",
                  fontSize: 14,
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                }}
              >
                Please wait ... &nbsp;
                <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
              </Typography>
            )}
            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={() => {
                  setconfirmDeletePoll(false);
                  setloader(false);
                }}
                style={{
                  backgroundColor: "#C60C30",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  handleDeletePoll();
                }}
                style={{
                  backgroundColor: "#1e2082",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CONFIRM
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
    </div>
  );
  const Completionist = () => (
    <Tag
      style={{
        cursor: "pointer",
        padding: 6,
        width: "105px",
        fontSize: "11px",
        fontWeight: "bold",
      }}
      color="green"
      icon={
        <CheckCircle
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        />
      }
    >
      COMPLETED
    </Tag>
  );

  const handlePaginaton = async (event, value, optionId) => {
    let temp = [];
    pollDetails?.optionsDetail?.map((op) => {
      if (op._id === optionId) {
        op.currentPage = value;
      }
      temp.push(op);
    });
    setpollOptions([...temp]);
    let temp2 = [];
    temp.map((t) => {
      temp2.push({ optionId: t._id, number: t.currentPage });
    });
    setPage([...temp2]);
  };

  const handleGetOptionDetailsData = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pollId: id,
        optionId: filterOptions === "all" ? "" : filterOptions,
        searchKey: searchKey,
        pageLimit: 500,
        page: [],
      };
      const res = await api.getOptionDetailsData(sendData);
      if (res?.data?.success) {
        setPollDetails(res?.data?.poll);
        handleTimer(new Date(), res?.data?.poll?.poll?.expiredInDate);
        setloader(false);
        setpollOptions(res?.data?.poll?.optionsDetail);
      }
    } catch (error) {
      console.log(error);
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleDeletePoll = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const res = await api.deletePoll({ pollId: id });
      if (res?.data?.success) {
        toast.success("Poll Deleted Successfully");
        navigate(navigateInitialPoll({}));
      }
    } catch (error) {
      console.log(error);
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetOptionDetailsData();
  }, [searchKey, filterOptions, page]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <PropertyInfo
                propertyInfo={pollDetails?.poll?.properties}
                openPropertyInfoModal={openPropertyInfoModal}
                setopenPropertyInfoModal={setopenPropertyInfoModal}
              />

              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <span
                    className="back-to-home pointer"
                    onClick={() => {
                      navigate(navigateInitialPoll({}));
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12H19M5 12L11 18M5 12L11 6"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    &nbsp; Back to Home
                  </span>
                </Grid>
                <Grid>
                  <Grid container>
                    <div class="box" style={{ minWidth: "250px" }}>
                      <input
                        type="text"
                        placeholder="search voters"
                        onChange={(e) => {
                          setsearchKey(e.target.value);
                        }}
                      />{" "}
                      <div className="searchIcon">
                        <SearchIcon />
                      </div>
                    </div>
                    <div>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="all"
                        value={filterOptions}
                        onChange={(event) =>
                          setfilterOptions(event.target.value)
                        }
                        style={{
                          color: "#212529",
                          backgroundColor: "#fff",
                          fontWeight: "bold",
                          width: "150px",
                          borderRadius: 6,
                          height: 40,
                          fontSize: "13px",
                          border: "1px solid #283f59",
                          textAlign: "center",
                          borderBottom: "none",
                          marginRight: 10,
                        }}
                      >
                        <MenuItem selected value="all">
                          All Options
                        </MenuItem>
                        {pollDetails?.poll?.options?.map((opt) => {
                          return (
                            <MenuItem selected value={opt?.optionId}>
                              {opt?.optionName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="ms-1 mt-3 justify-content-start">
                <span className="page-header">
                  Poll Details
                  {!loader ? (
                    <RefreshIcon
                      title="refresh Poll Details Page"
                      onClick={handleGetOptionDetailsData}
                      style={{
                        marginLeft: 5,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="laoding..."
                      style={{ marginLeft: 5, color: "#000" }}
                    />
                  )}
                </span>
              </Grid>
              {!pollDetails?.poll?.isDeleted && isReadWrite && (
                <div className="col-sm-12">
                  <div className="col-sm-12 d-flex justify-content-end mt-2 gap-2">
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-3 pointer"
                        onClick={() => {
                          setOpen(false);
                          setconfirmDeletePoll(true);
                        }}
                      >
                        <path
                          d="M4 7H20M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7M10 12L14 16M14 12L10 16"
                          stroke="#979797"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              )}

              <Grid container>
                <div className="polldetailscard mt-1">
                  <div className="profile-details-info-title">
                    Title: {pollDetails?.poll?.title}
                  </div>

                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-7">
                      <div className="profile-details-info-created-by">
                        Created by:{" "}
                        <span className="profile-details-info-created-by-user">
                          <img
                            style={{
                              cursor: "pointer",
                              border: "1px solid #e6e7f0",
                              height: 36,
                              width: 36,
                              borderRadius: 50,
                              marginRight: 8,
                            }}
                            src={
                              pollDetails?.poll?.createdBy?.image
                                ? `${BASE_URL}/user/${pollDetails?.poll?.createdBy?.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            alt="Sociohood"
                          />
                          <span>{pollDetails?.poll?.createdBy?.name}</span>
                        </span>
                      </div>
                      <div className="profile-details-info-created-on">
                        Created on:{" "}
                        <span className="profile-details-info-created-on-date">
                          {moment(pollDetails?.poll?.createdAt).format(
                            "DD/MM/YYYY - h:mm A"
                          )}
                        </span>
                      </div>
                      <div className="profile-details-info-expiry-date">
                        Complete (in days):{" "}
                        <span className="profile-details-info-expiry-date-value">
                          {pollDetails?.poll?.poleExpiredTime} Day
                        </span>
                      </div>
                      <div className="profile-details-info-expiry-date">
                        Complete Time:{" "}
                        <span className="profile-details-info-expiry-date-value">
                          {pollDetails?.poll?.isExpired ? (
                            <Completionist></Completionist>
                          ) : (
                            <>
                              <Timer
                                style={{
                                  fontSize: 20,
                                  color: "rgb(246 74 43)",
                                  marginRight: 5,
                                }}
                              />

                              <Countdown
                                date={Date.now() + parseInt(countDown)}
                              ></Countdown>
                            </>
                          )}
                        </span>
                      </div>
                      <div className="profile-details-info-poll-type">
                        Poll Type :{" "}
                        <span className="profile-details-info-poll-type-value">
                          {pollDetails?.poll?.viewerType === "societyWise" ? (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                                stroke="#5E5E5E"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <Home />
                          )}
                          {pollDetails?.poll?.viewerType === "societyWise" ? (
                            <>&nbsp;Society Wise</>
                          ) : (
                            <>
                              &nbsp;{pollDetails?.poll?.properties?.length}
                              &nbsp;{handlePropertyName()}&nbsp;{" "}
                              <InfoOutlined
                                onClick={() => {
                                  setopenPropertyInfoModal(true);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="pollmaincard">
                        <div className="pollmaincard-header h-20">
                          <div className="pollmaincard-ellipse">
                            <img
                              style={{
                                cursor: "pointer",
                                border: "1px solid #e6e7f0",
                                height: 36,
                                width: 36,
                                borderRadius: 50,
                                marginRight: 8,
                              }}
                              src={
                                pollDetails?.poll?.createdBy?.image
                                  ? `${BASE_URL}/user/${pollDetails?.poll?.createdBy?.image}`
                                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                              }
                              alt="Sociohood"
                            />
                          </div>
                          <div>
                            <div className="pollmaincard-title">
                              {pollDetails?.poll?.createdBy?.name}
                            </div>
                            <div className="pollmaincard-date-time">
                              {moment(pollDetails?.createdAt).format(
                                "DD/MM/YYYY - h:mm A"
                              )}
                            </div>
                          </div>
                          <div className="pollmaincard-society-wise">
                            <div className="pollmaincard-icon-building">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                                  stroke="#5E5E5E"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <span>
                              {pollDetails?.poll?.viewerType ===
                              "societyWise" ? (
                                <>&nbsp;Society Wise</>
                              ) : (
                                <>
                                  &nbsp;{pollDetails?.poll?.properties?.length}
                                  &nbsp;{handlePropertyName()}&nbsp;{" "}
                                  <InfoOutlined
                                    onClick={() => {
                                      setopenPropertyInfoModal(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pollmaincard-event-title h-10">
                          {pollDetails?.title?.length > 28
                            ? pollDetails?.title.slice(0, 28).concat("...")
                            : pollDetails?.title}
                        </div>

                        <div className="scrollable" style={{ height: "210px", overflow: "auto" }}>
                          {pollOptions?.map(function (option, i) {
                            return (
                              <div
                                className="poll-option-container pointer"
                                id={`poll-option-${i}`}
                                key={i}
                              >
                                <span className="poll-option-text">
                                  {option?.name}
                                </span>
                                <span
                                  className="poll-percentage-bar"
                                  style={{ width: `${option?.percentage}%` }}
                                ></span>
                                <span className="poll-percentage-value">
                                  {option?.percentage}%
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        <div className="d-flex justify-content-between h-10">
                          <div className="pollmaincard-end-date">
                            {pollDetails?.isExpired ? (
                              <Completionist></Completionist>
                            ) : (
                              <>
                                Ends at -{" "}
                                <TimerOff
                                  style={{
                                    fontSize: 15,
                                    color: "#D92402",
                                    marginRight: 4,
                                  }}
                                />
                                {moment(pollDetails?.expiredInDate).format(
                                  "DD/MM/YYYY - h:mm A"
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid container className="mt-4 ms-3">
                <span className="page-header">Total Votes</span>
              </Grid>
              <Grid container className="mt-4 ms-2">
                <div class="poll-vote-table-wrapper">
                  <table class="poll-vote-table-headers">
                    <thead>
                      <tr>
                        <th>Option</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Phone no.</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {pollOptions?.map((option) => {
                  return (
                    <div class="poll-vote-table-frame-427319798 mt-2">
                      <table class="poll-vote-table-content">
                        <tbody>
                          {option?.voters?.length !== 0 ? (
                            <>
                              {option?.voters?.map((voter, index) => {
                                return (
                                  <tr>
                                    <td class="poll-vote-table-option">
                                      {option?.name}
                                    </td>
                                    <td class="poll-vote-table-name">
                                      {voter?.voterName}
                                    </td>
                                    <td class="poll-vote-table-role">
                                      {voter?.voterRole}
                                    </td>
                                    <td class="poll-vote-table-email">
                                      {voter?.voterEmail
                                        ? voter?.voterEmail
                                        : "-"}
                                    </td>
                                    <td class="poll-vote-table-phone">
                                      {voter?.voterPhone
                                        ? voter?.voterPhone
                                        : "-"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <Grid
                              container
                              style={{ justifyContent: "center" }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Montserrat, sans-serif",
                                  color: "#6c757d",
                                  fontSize: 17,
                                  marginTop: 7,
                                  fontWeight: "bolder",
                                  marginBottom: 10,
                                  textAlign: "center",
                                }}
                              >
                                Option {option?.name} No Votes
                              </Typography>
                            </Grid>
                          )}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </main>
      {confirmModalDeletePoll}
      {loader && <BanterLoader />}
      {!loader && pollDetails?.length === 0 && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography
            style={{
              color: "#6c757d",
              textAlign: "center",
              fontSize: 38,
              marginTop: 6,
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            No Polls
          </Typography>
        </Grid>
      )}
    </div>
  );
}

export default PollDetails;
