import React, { useContext, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import SearchIcon from "@mui/icons-material/Search";
import * as api from "../../../api/index";
import useStyles from "./styles";
import { Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Pagination } from "@mui/material";
import AddCategory from "./AddCategory";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import CategoryTable from "./CategoryTable";
import { LoadingOutlined } from "@ant-design/icons";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";
import { toast } from "react-toastify";

const ComplaintCategory = () => {
  const classes = useStyles();
  const [allCategory, setallCategory] = useState([]);
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addComplaintCategory, setAddComplaintCategory] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [search, setSearch] = useState();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Category"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
    setSearch("");
  };

  const getComplaintCategory = async (search) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
      };
      const res = await api.getComplaintCategory(sendData);
      if (res?.data?.success) {
        setCount(res.data.category.count);
        setallCategory(res.data.category.complaintCategory);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    getComplaintCategory();
  }, [page]);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        getComplaintCategory(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      getComplaintCategory("");
    }
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader || loader2 ? "blur(1px)" : "",
        pointerEvents: loader || loader2 ? "none" : "",
        overflow: loader || loader2 ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint{" "}
                    <span style={{ color: "#000" }}>{` > Category `}</span>
                  </Typography>
                </Grid>
                <div
                  class="box"
                  style={{
                    minWidth: "250px",
                    marginLeft: 10,
                    border: "1px solid #E1E1E1",
                    borderRadius: 10,
                  }}
                >
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                    type="text"
                    placeholder="search..."
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          getComplaintCategory();
                        }
                      }
                    }}
                  />
                  <div className="searchIcon">
                    <SearchIcon
                      onClick={() => {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          getComplaintCategory();
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                container
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                {isReadWrite && (
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "147px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setAddComplaintCategory(true);
                        }}
                      >
                        <CategoryIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Add Category
                      </p>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid container>
                <CategoryTable
                  allCategory={allCategory}
                  page={page}
                  getComplaintCategory={getComplaintCategory}
                />
              </Grid>
              {allCategory?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Complaint Category
                  </Typography>
                </Grid>
              )}
              {loader2 && (
                <Typography
                  style={{
                    marginBottom: 10,
                    textAlign: "center",
                    fontSize: 15,
                    color: "#2f45c5",
                  }}
                >
                  page is loading.... <LoadingOutlined />
                </Typography>
              )}

              {allCategory?.length > 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              <AddCategory
                addComplaintCategory={addComplaintCategory}
                setAddComplaintCategory={setAddComplaintCategory}
                getComplaintCategory={getComplaintCategory}
              />
            </div>
          </div>
        </Grid>
      </main>
      {loader && <BanterLoader />}
    </div>
  );
};

export default ComplaintCategory;
