import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
  Select,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { Close, ErrorOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import ApiCall from "../../../../api/ApiCall";
import { AllGroupRoutes } from "../../../../api/Routes";
import { resolveErrorMessage } from "../../../../services/service";

function MakeManagerModal({
  addManager,
  setaddManager,
  selectedGroup,
  setSelectedGroup,
  handleMakeManger,
  rejectConfirmation,
  loader2,
  errorMessage,
}) {
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const [allGroup, setallGroup] = useState([]);

  const getAllAddressGroup = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        filters: { societyId: fetchSocietyId() },
        page: page,
        limit: 5000,
      };
      const res = await ApiCall("post", AllGroupRoutes.getGroup, sendData);
      if (res?.success) {
        setallGroup(res?.addressGroup?.data);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    getAllAddressGroup();
  }, []);

  return (
    <Modal open={addManager}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          pointerEvents: loader2 ? "hidden" : "auto",
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000000",
              fontWeight: 500,
              fontFamily: "Poppins",
              fontSize: "16px",
              textAlign: "center",
              marginLeft: 10,
              opacity: 1,
            }}
          >
            Make Manager
          </Typography>

          <IconButton
            onClick={() => {
              rejectConfirmation();
              setaddManager(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <div className="d-flex justify-content-center mt-3 mb-2">
          <div className="alert alert-secondary text-primary fs-8" role="alert">
            <strong>Note:</strong> Select a group to make the user manager
          </div>
        </div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                textAlign: "center",
                marginTop: 12,
              }}
            >
              Choose Group :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <Select
              value={selectedGroup}
              onChange={(event) => setSelectedGroup(event.target.value)}
              disabled={loader}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 10,
                width: "98%",
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                marginBottom: 10,
                border: "1px solid #6c757d",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              <MenuItem selected value="SelectGroup">
                Select Group
              </MenuItem>
              {allGroup?.map((group) => (
                <MenuItem
                  value={group?._id}
                  key={group?._id}
                  style={{ textTransform: "capitalize" }}
                >
                  {group?.name} - {group?.accessType?.[0]}{" "}
                  <Badge
                    badgeContent={group?.[group?.accessType?.[0]]?.length}
                    color="primary"
                    style={{ marginLeft: "15px" }}
                  ></Badge>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {errorMessage && (
            <Typography
              style={{
                marginTop: 8,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMessage}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "end", marginTop: "10px" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "150px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                handleMakeManger();
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {loader2 ? "Making" : "Make Manager"}&nbsp;{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default MakeManagerModal;
