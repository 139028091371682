import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from "@mui/system";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
    padding: 10,
  },

  toolbar: theme.mixins.toolbar,

  ModalView: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "45vw",
    borderRadius: 6,
  },
  addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    backgroundColor: "#4b8edb",
    color: "#fff",
    outline: 0,
    border: "1px solid #4b8edb",
  },
  paper: {
    padding: 20,
    marginTop: 10,
    boxShadow: "2px 3px 9px rgba(0, 0, 0, 0.75)",
    borderRadius: 10,
    height: "75vh",
    cursor: "pointer",
    width: "52vw",
    backgroundColor: "#6da4e3",
  },
  //dashboard
  cardLabel: {
    textTransform: "uppercase",
    color: "#6c757d",

    fontWeight: "bold",
    fontSize: 23,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  cardTitle: {
    textTransform: "capitalize",
    color: "#00000",
    fontSize: 25,

    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  paperContainer: {
    backgroundImage: `url(${"https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80"})`,
    backgroundRepeat: "x-repeat",
    borderRadius: "6px",
    width:"99%"
    /* backgroundImage: "linear-gradient(to bottom right, #516df6, #00d4ff);", */

    /*  backgroundColor: "#1fd1f9",
    backgroundImage: "linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)", */
  },
  cardColor1: {
    backgroundImage: "linear-gradient(315deg, #7f53ac 0%, #647dee 74%);",
    color: "white",
  },
  cardColor2: {
    color: "#ffffff",
    backgroundImage: "linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)",
  },
  cardColor3: {
    background: "linear-gradient(315deg, #06bcfb 0%, #4884ee 74%)",
    color: "#ffffff",
  },
  cardColor4: {
    background: "linear-gradient(315deg, #3bb78f 0%, #0bab64 74%)",
  },
  cardColor5: {
    background: "linear-gradient(147deg, #990000 0%, #ff0000 74%)",
  },
}));