import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@material-ui/core";
import { handleFlatName } from "../../contextAPI/NamingConvention";
import { getSocietyFlats } from "../../GlobalFunctions/GlobalFunctions";

function ChooseFlatDropDown(props) {
  const { floor, setflat, flat } = props;

  const [loading, setLoading] = useState(false);
  const [allFlats, setallFlats] = useState([]);

  useEffect(() => {
    if (floor?._id) {
      handleGetFlats();
    }
  }, [floor]);

  const handleGetFlats = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);

    const res = await getSocietyFlats({ floorId: floor?._id });
    setallFlats(res?.flats);
    setLoading(false);
  };

  useEffect(() => {
    if (flat && allFlats.length > 0) {
      const selectedFlat = allFlats?.find((flt) => flt.name === flat.name);
      if (selectedFlat) {
        setflat(selectedFlat);
      }
    }
  }, [allFlats]);

  return (
    <Grid container item xs={12}>
      <Select
        value={flat?.name || "Select Flat"}
        onChange={(event) => {
          const selectedFlat = allFlats?.find(
            (flt) => flt.name === event.target.value
          );
          setflat(selectedFlat);
        }}
        style={{
          display: "block",
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          backgroundColor: "#fff",
          borderRadius: 6,
          fontSize: 16,
          width: "97%",
          padding: 8,
          paddingLeft: 20,
          height: 52,
          outline: 0,
          border: "1px solid #283f59",
        }}
        disabled={!floor?._id || loading}
      >
        <MenuItem value="Select Flat">
          Select {handleFlatName()}{" "}
          {floor?._id && allFlats?.length > 0 ? `(${allFlats?.length})` : ""}
        </MenuItem>
        {allFlats?.map((flt) => {
          return (
            <MenuItem key={flt._id} value={flt.name}>
              {flt.name}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
}

export default ChooseFlatDropDown;
