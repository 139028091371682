import Modal from "@mui/material/Modal";
import { CardMedia } from "@material-ui/core";
import React from "react";
import Carousel from "react-elastic-carousel";

const DownloadImageModal = ({ defaultImage, notice, setOpenImageModal }) => {
  return (
    <Modal open={true} onClose={() => setOpenImageModal(false)}>
      <div
        className="modal-dialog-centered"
        style={{ maxWidth: "800px", margin: "auto", outline: "none" }}
      >
        <div
          className="modal-content shadow-lg"
          style={{ borderRadius: "0.5rem" }}
        >
          <div className="modal-body">
            <div className="position-relative">
              <button
                type="button"
                className="btn-close position-absolute"
                aria-label="Close"
                style={{ top: "10px", right: "10px" }}
                onClick={() => setOpenImageModal(false)}
              ></button>

              <Carousel itemsToShow={1}>
                {notice?.images.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "500px",
                      textAlign: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectPosition: "center",
                      }}
                      image={item?.Location || defaultImage}
                      alt={`Notice image ${index + 1}`}
                    />
                    <a
                      href={item?.Location}
                      download={`Notice-Image-${index + 1}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <button
                        className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle shadow-sm"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                      </button>
                    </a>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadImageModal;
