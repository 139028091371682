import React, { useState, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import useStyles from "./style";
import Modal from "@mui/material/Modal";
import { AddCircle, Close, Error } from "@material-ui/icons";
import * as api from "../../../api/index";
import { AuthContext } from "../../../contextAPI/context";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import SearchAddress from "../../../GlobalComponents/SearchAddress/SearchAddress";

function AddPoll(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const { addPollModal, setAddPollModal, handleGetPoll, setPage } = props;
  const [allOptions, setAllOptions] = useState([
    { options: "" },
    { options: "" },
  ]);
  const [pollTitle, setPollTitle] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [selectPropertyType, setselectPropertyType] = useState("societyWise");
  const [expirationTime, setexpirationTime] = useState("1");
  const pollExpiredTimings = ["1", "3", "5", "10", "15", "30"];

  //selection / property Modal
  const [propertyId, setPropertyId] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  const handlereset = () => {
    setAddPollModal(false);
    seterrorMsg("");
    setAllOptions([{ options: "" }, { options: "" }]);
    setPollTitle("");
    setselectPropertyType("societyWise");
    setPropertyId([]);
    setexpirationTime("1");
  };

  const handleOptinsAdd = (event, index) => {
    const { name, value } = event.target;
    const Option = [...allOptions];
    Option[index][name] = value;
    setAllOptions(Option);
  };

  const addNewOptions = () => {
    if (allOptions?.length > 5) {
      seterrorMsg("Maximum 6 options can be added");
    } else {
      seterrorMsg("");
      setAllOptions([...allOptions, { options: "" }]);
    }
  };

  const handleOptinsRemove = (index) => {
    seterrorMsg("");
    const Option = [...allOptions];
    Option.splice(index, 1);
    setAllOptions(Option);
  };

  const isInvalidProperty = () => {
    let invalid = false;
    propertyId?.map((p) => {
      if (!p?.societyId) {
        invalid = true;
      } else {
        invalid = false;
      }
    });
    return invalid;
  };

  const addNewPoll = async () => {
    setPage(1);
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    let isName = true;
    let temp = [];
    if (pollTitle === "") {
      setloader(false);
      seterrorMsg("Title can not be empty");
    } else if (Object.keys(allOptions)?.length < 2) {
      setloader(false);
      seterrorMsg("Option shoule be more than 2 ");
    } else if (
      selectPropertyType === "propertyWise" &&
      propertyId?.length === 0
    ) {
      setloader(false);
      seterrorMsg("Please select atleast one property.");
    } else if (isInvalidProperty()) {
      setloader(false);
      seterrorMsg("Please select a valid property");
    } else {
      allOptions?.map((opt) => {
        if (opt?.options === "") {
          setloader(false);
          isName = false;
          seterrorMsg("option name can't be empty");
        } else {
          temp.push(Object.values(opt).toString());
        }
      });
      if (isName) {
        try {
          const sendData = {
            viewerType: selectPropertyType,
            propertyId: propertyId?.map((x) => {
              return x._id;
            }),
            societyId: fetchSocietyId(),
            title: pollTitle,
            poleExpiredTime: expirationTime,
            optionNames: temp,
            createdBy: user?._id,
            maxPollOption: "6",
          };
          const res = await api.addPollData(sendData);
          if (res?.data?.success) {
            toast.success("Poll added Successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setloader(false);
            handleGetPoll();
            handlereset();
          }
        } catch (error) {
          setloader(false);
          seterrorMsg(resolveErrorMessage(error));
        }
      }
    }
  };

  return (
    <div>
      <Modal open={addPollModal}>
        <Paper
          style={{
            backgroundColor: "#ffff",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            minWidth: "60vw",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            stickyHeaders={true}
            style={{
              textAlign: "center",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <span className="page-header"> Add Poll</span>
            {!loader && (
              <IconButton
                onClick={() => {
                  handlereset();
                }}
                className="pointer"
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <div className="col-sm-12 row justify-content-between">
            <div className="col-sm-6 p-3">
              <div className="mb-4">
                <label className="form-label fs-6">Title</label>

                <input
                  className="form-control p-2"
                  type="text"
                  placeholder="Enter Poll Title"
                  value={pollTitle}
                  onChange={(event) => setPollTitle(event.target.value)}
                  maxLength={150}
                />
              </div>
              <div
                className="overflow-auto scrollable"
                style={{ maxHeight: "200px" }}
              >
                <label className="form-label fs-6">Option</label>

                {allOptions?.map((box, index) => {
                  return (
                    <div key={index} className="w-100 mb-3 position-relative">
                      <input
                        className="form-control p-2"
                        type="text"
                        name="options"
                        placeholder="Enter Option Name"
                        value={box.options}
                        onChange={(event) => handleOptinsAdd(event, index)}
                        maxLength={45}
                      />
                      {index > 1 && (
                        <IconButton
                          onClick={() => handleOptinsRemove(index)}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "8px",
                          }}
                        >
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4ZM30.42 27.58C30.6075 27.7659 30.7563 27.9871 30.8578 28.2308C30.9593 28.4746 31.0116 28.736 31.0116 29C31.0116 29.264 30.9593 29.5254 30.8578 29.7692C30.7563 30.0129 30.6075 30.2341 30.42 30.42C30.2341 30.6075 30.0129 30.7562 29.7692 30.8578C29.5254 30.9593 29.264 31.0116 29 31.0116C28.736 31.0116 28.4746 30.9593 28.2309 30.8578C27.9871 30.7562 27.7659 30.6075 27.58 30.42L24 26.82L20.42 30.42C20.2341 30.6075 20.0129 30.7562 19.7692 30.8578C19.5254 30.9593 19.264 31.0116 19 31.0116C18.736 31.0116 18.4746 30.9593 18.2309 30.8578C17.9871 30.7562 17.7659 30.6075 17.58 30.42C17.3926 30.2341 17.2438 30.0129 17.1422 29.7692C17.0407 29.5254 16.9884 29.264 16.9884 29C16.9884 28.736 17.0407 28.4746 17.1422 28.2308C17.2438 27.9871 17.3926 27.7659 17.58 27.58L21.18 24L17.58 20.42C17.2034 20.0434 16.9918 19.5326 16.9918 19C16.9918 18.4674 17.2034 17.9566 17.58 17.58C17.9566 17.2034 18.4674 16.9918 19 16.9918C19.5326 16.9918 20.0434 17.2034 20.42 17.58L24 21.18L27.58 17.58C27.9566 17.2034 28.4674 16.9918 29 16.9918C29.5326 16.9918 30.0434 17.2034 30.42 17.58C30.7966 17.9566 31.0082 18.4674 31.0082 19C31.0082 19.5326 30.7966 20.0434 30.42 20.42L26.82 24L30.42 27.58Z"
                              fill="#C9C9C9"
                            />
                          </svg>
                        </IconButton>
                      )}
                    </div>
                  );
                })}
                <div
                  className="text-primary d-flex justify-content-end pointer"
                  onClick={addNewOptions}
                  style={{
                    color: "#2f3aa3",
                    marginTop: 3,
                  }}
                >
                  + Add option
                </div>
              </div>
            </div>
            <div className="col-sm-6 row p-3 align-items-start gap-0">
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">
                  Expiration Time &nbsp;
                  <span className="text-secondary fs-7">( in days )</span>
                </label>

                <Select
                  value={expirationTime}
                  onChange={(event) => setexpirationTime(event.target.value)}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                >
                  {pollExpiredTimings?.map((ex) => {
                    return (
                      <MenuItem
                        selected
                        value={ex}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        &nbsp;{ex}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div className="col-sm-12 mb-3">
                <label className="form-label fs-6">
                  Choose Poll Type &nbsp;
                </label>

                <Select
                  value={selectPropertyType}
                  onChange={(event) =>
                    setselectPropertyType(event.target.value)
                  }
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                  onClick={() => {
                    seterrorMsg("");
                  }}
                >
                  <MenuItem selected value="societyWise">
                    Society Wise
                  </MenuItem>
                  <MenuItem selected value="propertyWise">
                    {handlePropertyName()} wise
                  </MenuItem>
                </Select>
              </div>

              {selectPropertyType === "propertyWise" && (
                <div className="col-sm-12 mb-3">
                  <label className="form-label fs-6">
                    {handlePropertyName()} &nbsp;
                  </label>

                  <SearchAddress
                    searchKey={searchKey}
                    setsearchKey={setsearchKey}
                    setPropertyId={setPropertyId}
                    propertyId={propertyId}
                    isMultiple={true}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-12 row mt-2">
            <div className="col-sm-6">
              {errorMsg && (
                <Typography
                  style={{
                    marginTop: 20,
                    color: "red",
                    textAlign: "center",
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  <Error /> {errorMsg}
                </Typography>
              )}
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <Button
                style={{
                  padding: "8px",
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: "70%",
                  backgroundColor: "#3862DD",
                  color: "#fff",
                }}
                disabled={loader}
                variant="contained"
                size="large"
                onClick={() => {
                  addNewPoll();
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <AddCircle
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}
                {!loader ? "Create Poll" : `Creating...`}
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddPoll;
