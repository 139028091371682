import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Modal,
  Divider,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contextAPI/context";
import useStyles from "../../Vendors/VendorsTable/styles";
import DailyGraph from "../AllComplaints/DailyGraph";
import { Close } from "@material-ui/icons";

function createData(
  departments,
  recieved,
  closed,
  pending,
  pendingwithsla,
  pendingbeyondsla,
  stat
) {
  return {
    departments,
    recieved,
    closed,
    pending,
    pendingwithsla,
    pendingbeyondsla,
    stat,
  };
}

const rows = [
  createData("House Keeping", 5, 10, 24, 4, 5),
  createData("Electrical", 25, 10, 24, 4, 5),
  createData("Civil", 5, 30, 24, 4, 5),
  createData("Water & Plumbing", 51, 10, 24, 4, 5),
  createData("Horticulture", 5, 11, 24, 4, 5),
];

function VendorsTable(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [openGraph, setOpenGraph] = useState(false);

  const handleGraphModal = () => {
    setOpenGraph(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Departments
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Recieved
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Closed
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Pending
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Pending with SLA
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Pending Beyond SLA
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Statistics
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row.index}>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {rowsPerPage * page - 1 + index + 2}.
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  <Typography className={classes.textname}>
                    {row.departments}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {row.recieved}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {row.closed}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {row.pending}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {row.pendingwithsla}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {row.pendingbeyondsla}
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setOpenGraph(true);
                    }}
                  >
                    Report Stat{row.stat}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {openGraph && (
        <Modal open={handleGraphModal}>
          <Paper
            style={{
              backgroundColor: "#e8f0fa",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
            }}
            className={classes.ModalView}
          >
            <Grid
              container
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginLeft: 10,
                  fontSize: 17,
                  opacity: 1,
                }}
              >
                Daily Report Stat
              </Typography>

              <IconButton
                onClick={() => {
                  setOpenGraph(false);
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            </Grid>
            <Divider
              style={{
                marginTop: 0,
                marginBottom: 10,
                backgroundColor: "#000",
              }}
            />
            <DailyGraph />
          </Paper>
        </Modal>
      )}


    </>
  );
}

export default VendorsTable;
