import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ChooseCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import ChooseSubCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseSubCategoryDropDown";
import { Input } from "antd";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { ErrorOutline } from "@mui/icons-material";
import { getPlantWiseRoleSegregation } from "../../../../../GlobalFunctions/GlobalFunctions";

function SubComplaintModal(props) {
  const {
    selectProcess,
    complaint,
    classes,
    setselectProcess,
    user,
    handlegetComplaintById,
    setupdateProcecssModal,
    plant
  } = props;
  const [errorMessage, seterrorMessage] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [complaintDesc, setcomplaintDesc] = useState("");
  const [loader, setloader] = useState(false);
  const [complainTitle, setcomplainTitle] = useState("");
  const [selectPriority, setselectPriority] = useState(complaint?.priority);
  const { TextArea } = Input;

  const handleReset = () => {
    handlegetComplaintById();
    setCategory([]);
    setSubCategory([]);
    setsearchKey("");
    setcomplaintDesc("");
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setloader(false);
  };

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleSubComplaint = async () => {
    callingApi();
    seterrorMessage("");
    if (!category?._id) {
      seterrorMessage("Please Choose Category");
      setloader(false);
    } else if (!subCategory?._id) {
      seterrorMessage("Please Choose Sub Category");
      setloader(false);
    } else {
      const sendData = {
        complaintId: complaint?._id,
        adminId: user?._id,
        categoryId: category?._id,
        subcomplaintCategoryId: subCategory?._id,
        priority: selectPriority,
        complaintTitle: complainTitle,
        description: complaintDesc,
        updatedBy: getPlantWiseRoleSegregation(user, plant),
        updatedFor: complaint?.creatorRole?complaint?.creatorRole:complaint?.complainer?.role,
      };
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.addSubComplaint,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Complaint Added Successfully");
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  useEffect(() => {
    if (complaint) {
      setCategory(complaint?.category);
      setSubCategory(complaint?.subcomplaintCategory);
    }
  }, [complaint]);

  return (
    <>
      <Modal open={selectProcess === "Sub Complaint"}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            minWidth: "400px",
            maxWidth: "40vw",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Sub Complaint
            </Typography>

            <IconButton
              onClick={() => {
                setselectProcess("Select Process");
                seterrorMessage("");
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Grid container style={{ justifyContent: "flex-start" }}>
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontWeight: "600",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "5px",
                  marginBottom: 5,
                  marginLeft: "5px",
                }}
              >
                Complaint Title :
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 8,
                  width: "98%",
                  border: "1px solid #4b8edb",
                  outline: 0,
                }}
                type="text"
                placeholder="Enter Complaint Title"
                onChange={(event) => setcomplainTitle(event.target.value)}
                maxLength={150}
                value={complainTitle}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ justifyContent: "flex-start", marginTop: "5px" }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontWeight: "600",
                  fontFamily: "Montserrat, sans-serif",
                  marginBottom: "8px",
                  marginLeft: "5px",
                  marginTop: "10px",
                }}
              >
                Complaint Priority :
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Select
                value={selectPriority}
                onChange={(event) => setselectPriority(event.target.value)}
                style={{
                  display: "block",
                  color:
                    selectPriority === "high" ? "rgb(36 202 38)" : "#d00000",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#fff",
                  borderRadius: 6,
                  fontSize: 16,
                  width: "68%",
                  padding: 7,
                  paddingLeft: 20,
                  height: 46,
                  fontWeight: "bold",
                }}
              >
                <MenuItem
                  selected
                  value="low"
                  style={{ color: "#d00000", fontWeight: "bold" }}
                >
                  LOW
                </MenuItem>
                <MenuItem
                  selected
                  value="high"
                  style={{ color: "rgb(36 202 38)", fontWeight: "bold" }}
                >
                  HIGH
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "8px" }}
          >
            <Grid item xs={6}>
              <ChooseCategoryDropDown
                category={category}
                setCategory={setCategory}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
                isMultiple={false}
              />
            </Grid>
            <Grid item xs={6}>
              <ChooseSubCategoryDropDown
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
                isMultiple={false}
                category={category}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ justifyContent: "flex-start", marginTop: "10px" }}
          >
            <TextArea
              placeholder="Description here"
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              onChange={(event) => setcomplaintDesc(event.target.value)}
              value={complaintDesc}
              maxLength={250}
            />
          </Grid>
          {errorMessage && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMessage}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 35,
                boxShadow: "0 3px 6px #7D858D",
                width: "162px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: "12px",
              }}
              onClick={() => {
                handleSubComplaint();
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-10px",
                  }}
                >
                  <>
                    {loader ? (
                      <>
                        <LoadingOutlined
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        "Adding"
                      </>
                    ) : (
                      "Add Sub Complaint"
                    )}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default SubComplaintModal;
