import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

export default function CategoryComplaintGraphQV({ data }) {
  return (
    <ResponsiveContainer width="100%" height={330}>
      <BarChart data={data} margin={{ top: 5, bottom: 5 }} id="category-data">
        <XAxis
          type="category"
          dataKey="categoryName"
          axisLine={false}
          interval={0}
          style={{
            fontSize: 8,
            color: "#0000",
            fontWeight: "bold",
          }}
        />
        <YAxis hide axisLine={false} type="number" />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="numberOfComplaints"
          fill="#0C8CE9"
          name="Category wise Complaints"
          barSize={70}
          radius={[10, 10, 0, 0]}
        >
          <LabelList
            dataKey="numberOfComplaints"
            position="center"
            fill="#fff"
            style={{
              fontSize: 9,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
