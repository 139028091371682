import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import { Grid, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { navigateToGroups } from "../../../../Navigation/Navigations";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import GroupDetailsTable from "./GroupDetailsTable";
import BanterLoader from "../../../../GlobalComponents/Loaders/BanterLoader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmModal from "../../../../contextAPI/ConfirmModal";
import ApiCall from "../../../../api/ApiCall";
import { AllGroupRoutes } from "../../../../api/Routes";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";

const GroupDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [groupDetails, setGroupDetails] = useState([]);
  const navigate = useNavigate();
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Area")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleReset = () => {
    setDeleteGroup(false);
  };

  const handleGetGroups = async () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        _id: id,
      },
      page: 1,
      limit: 10,
    };
    const res = await ApiCall("post", AllGroupRoutes.getGroup, data);
    if (res?.success) {
      setLoader(false);
      setGroupDetails(res?.addressGroup?.data?.[0]);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetGroups();
  }, []);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {` Group Details > `}
                    <span style={{ color: "#000" }}>{groupDetails?.name}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Resident Page"
                        onClick={handleGetGroups}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid style={{ display: "flex" }}>
                  {/* {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "140px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: 7,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        setDeleteGroup(true);
                      }}
                    >
                      <div class="card-body">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <DeleteOutlineIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Delete Group
                        </p>
                      </div>
                    </div>
                  )} */}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginTop: 7,
                    }}
                    onClick={() => {
                      navigate(navigateToGroups());
                    }}
                  >
                    <div class="card-body">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {!loader && (
                <>
                  <Grid style={{ marginTop: 16, marginBottom: 10 }}>
                    <GroupDetailsTable groupDetails={groupDetails} />
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Grid>
      </main>
      <ConfirmModal
        title={` ARE YOU SURE YOU WANT TO DELETE THIS GROUP ?`}
        description="You can confirm by clicking below."
        loader={loader2}
        isOpen={deleteGroup}
        errorMsg={errorMsg}
        rejectConfirmation={handleReset}
      />
      {loader && <BanterLoader />}
    </div>
  );
};

export default GroupDetails;
