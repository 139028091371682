import React from "react";
import Meeting from "../../Components/Communication/Meeting";
import MeetingDetails from "../../Components/Communication/MeetingDetails";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";

const MeetingRoutes = {
    path: "/communication",
    element: <NavigationDrawer />,
    children: [
        {
            path: "meeting",
            element: <Meeting />
        },
        {
            path: "meetingdetails/:id",
            element: <MeetingDetails />
        },
    ]
}
export default MeetingRoutes;