import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Area from "../../Components/Complaints/Area/Area";
import SubArea from "../../Components/Complaints/Area/SubArea/SubArea";

const AreaRoutes = {
    path: "/complaints",
    element: <NavigationDrawer />,
    children: [
      {
        path: "area",
        element: <Area />,
      },
      {
        path: "subarea/:id/:areaName",
        element: <SubArea />,
      },
    ],
  };
  export default AreaRoutes;