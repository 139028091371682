import { toast } from "react-toastify";
import AWS from "aws-sdk";

AWS.config.update({
  region: "ap-south-1",
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY,
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  ),
});

let s3 = new AWS.S3({
  apiVersion: "2022-04-04",
  params: { Bucket:process.env.REACT_APP_AWS_BUCKET_NAME },
});

//ADD IMAGE INTO LOCAL
export const handleUploadInLocal = async (event, type, setData) => {
  let maxSizeForImage = 1 * 1024 * 1024;
  let maxSizeForAttach = parseFloat(307200 / 1024);
  var file = event.target.files[0];
  if (type === "image" && file?.size > maxSizeForImage) {
    toast.error("Image size should be 1 mb or less.");
  } else if (
    type === "attach" &&
    parseFloat(file.size / 1024) > maxSizeForAttach
  ) {
    toast.error("Attach size should be 100KB or less.");
  } else {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setData({ data: reader.result, name: file.name, file });
        return setData;
      };
    }
  }
};

//UPLOAD IMAGE INTO AWS
export const handleUploadInDirectAws = async (file) => {
  let upload_params = {
    Bucket: "test-bucket-sociohood",
    Key: file.name,
    Body: file.file,
  };
  return  s3.upload(upload_params).promise()
  //ANOTHER WAY TO HANDLE PROMISE RETURN FUNCITON
//   new Promise((resolve, reject) => {
//     s3.upload(upload_params, (err, data) => {
//       if (err) {
//         reject(err);
//         console.log({ err });
//         toast.error("Something went wrong!Image uploading failed .");
//         return err
//       } else {
//         resolve(data);
//       }
//     });
//   });
};

//DELETE AWS IMAGE
export const handleDeleteAwsImage = async (img) => {
  // let delete_params = {
  //     Bucket: "test-bucket-sociohood",
  //     Key: img,
  //   };
  //   s3.deleteObject(delete_params, function (err, data) {
  //     if (err) console.log(err, err.stack);
  //     else console.log("deleted", img);
  //   });
};
