import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "../style";
import { Close, ErrorOutline } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";
import * as api from "../../../api/index";
import { LoadingOutlined } from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";

function AddCategory(props) {
  const classes = useStyles();
  const {
    addCategoryModal,
    setAddCategoryModal,
    handleStaffCount,
    editCategory,
    isEdited,
    setIsEdited,
  } = props;
  const [categoryName, setCategoryName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (editCategory) {
      setCategoryName(editCategory?.staffType?.name);
    }
  }, [isEdited]);

  const handlereset = () => {
    setAddCategoryModal(false);
    seterrorMsg("");
    setCategoryName("");
    setloader(false);
  };

  const handleAddCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        name: categoryName,
        societyId: fetchSocietyId(),
      };
      const res = await api.AddStaffType(sendData);
      if (res?.data?.success) {
        toast.success("Category Added Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleStaffCount();
        handlereset();
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleEditCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        staffTypeId: editCategory?.staffType?._id,
        name: categoryName,
      };
      const res = await api.editStaffType(sendData);
      if (res?.data?.success) {
        toast.success("Category Edit Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setIsEdited(false);
        handleStaffCount();
        handlereset();
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  return (
    <Modal open={addCategoryModal || isEdited}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          minWidth: "300px",
          maxWidth: "29vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {isEdited ? "Edit Category" : "Add Category"}
          </Typography>
          <IconButton
            onClick={() => {
              if (isEdited) {
                setIsEdited(false);
              } else {
                handlereset();
              }
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Category Name
          </Typography>
          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 4,
              outline: 0,
              marginTop: 6,
            }}
            placeholder="Enter Category"
            value={categoryName}
            onChange={(event) => setCategoryName(event.target.value)}
          />
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        {!isEdited ? (
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "150px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                handleAddCategory();
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <AddIcon
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}

                  {!loader ? "Add Category" : `Adding...`}
                </p>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "150px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
              onClick={() => {
                handleEditCategory();
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <EditIcon
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}

                  {!loader ? "Edit Category" : `Adding...`}
                </p>
              </div>
            </div>
          </Grid>
        )}
      </Paper>
    </Modal>
  );
}

export default AddCategory;
