import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Admin from "../../Components/AdminPage/Admins";

const AdminRoutes = {
  path: "/people",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <Admin />,
    },
    {
      path: "admins",
      element: <Admin />,
    },
  ],
};
export default AdminRoutes;
