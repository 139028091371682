import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import useStyles from "../style";
import * as api from "../../../api/index";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import AddCategory from "./AddCategory";
import DailyHelpCard from "./DailyHelpCard";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../contextAPI/context";
import { QuestionMark } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import Loading from "../../../contextAPI/Loading";

function DailyHelp() {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [staffType, setStaffType] = useState([]);
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [editCategory, setEditCategory] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Daily Help")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleStaffCount = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        searchKey: search,
      };
      const res = await api.getStaffCount(sendData);
      if (res?.data?.success) {
        setStaffType(res?.data?.staff);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleStaffCount();
  }, [search]);

  const handleDeleteStaffType = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        staffTypeId: editCategory?.staffType?._id,
      };
      const res = await api.deleteStaffType(sendData);
      if (res?.data?.success) {
        handleStaffCount();
        setDeleteCategory(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const confirmModalDelete = (
    <div>
      <Modal open={deleteCategory}>
        <Paper
          style={{ backgroundColor: "#e6e7f0" }}
          className={classes.confirmModal}
        >
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 19,
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: 7,
              textTransform: "uppercase",
            }}
          >
            ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY ?
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
          </Typography>

          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            A confirmation message will appear when this process is complete.You
            can confirm by clicking below.
          </Typography>
          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          {loader && (
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              Please wait ... &nbsp;
              <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
            </Typography>
          )}
          <Grid container style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                setDeleteCategory(false);
              }}
              style={{
                backgroundColor: "#C60C30",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                handleDeleteStaffType();
              }}
              style={{
                backgroundColor: "#1e2082",
                color: "#fff",
                marginTop: "12px",
                padding: 8,
                width: 180,
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    Daily Help
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Staff Page"
                        onClick={handleStaffCount}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search..."
                      onChange={(e) => {
                        setsearch(e.target.value);
                      }}
                      value={search}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "150px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 7,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAddCategoryModal(true);
                          }}
                        >
                          <AddIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Category
                        </p>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                lg={12}
                style={{ marginTop: 20 }}
              >
                {staffType?.map((stf) => {
                  return (
                    <DailyHelpCard
                      stf={stf}
                      setIsEdited={setIsEdited}
                      setEditCategory={setEditCategory}
                      setDeleteCategory={setDeleteCategory}
                    />
                  );
                })}
              </Grid>
              {!loader && staffType?.length === 0 && (
                <Typography
                  style={{
                    color: "#6c757d",
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  No Category
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddCategory
          addCategoryModal={addCategoryModal}
          setAddCategoryModal={setAddCategoryModal}
          editCategory={editCategory}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
          handleStaffCount={handleStaffCount}
        />
      </main>
      {confirmModalDelete}
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default DailyHelp;
