import React from "react";
import useStyles from "./style";

function TmlTable() {
  const classes = useStyles();
  return (
    <div>
      <table className={classes.tableStyle}>
        <thead>
          <tr className={classes.headerRowStyle}>
            <th colSpan="4">TML MOVEMENT RECORD</th>
          </tr>
          <tr className={classes.headerRowStyle}>
            <th className={classes.cellStyle}>TML Registration No.</th>
            <th className={classes.cellStyle}></th>
            <th className={classes.cellStyle}>Date :</th>
            <th className={classes.cellStyle}></th>
          </tr>
          <tr className={classes.headerRowStyle}>
            <th className={classes.cellStyle}>Gate Ways</th>
            <th className={classes.cellStyle}>Name</th>
            <th className={classes.cellStyle}>Sign & Out Date</th>
            <th className={classes.cellStyle}>Remarks</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className={classes.cellStyle}>SI Verification</td>
            <td className={classes.cellStyle}>Mr. Ranjan Naskar (Level - 1)</td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}>(Level - 2)</td>
          </tr>
          <tr>
            <td className={classes.cellStyle}>Audit Checking</td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}>(Level - 2)</td>
          </tr>
          <tr>
            <td className={classes.cellStyle}>Head Town Management</td>
            <td className={classes.cellStyle}>Mr. Kunal Ray</td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}>For Process / For Audit</td>
          </tr>
          <tr>
            <td className={classes.cellStyle}>Office Verification</td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}></td>
          </tr>
          <tr>
            <td className={classes.cellStyle}>Invoice No</td>
            <td className={classes.cellStyle}></td>
            <td className={classes.cellStyle}>SES No</td>
            <td className={classes.cellStyle}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TmlTable;
