import React from "react";
import useStyles from "./style";

function AbstractTable() {
  const classes = useStyles();
  return (
    <div>
      <table className={classes.tableStyle}>
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <th className={classes.cellStyle}>Sch Srl</th>
            <th className={classes.cellStyle}>Item Code</th>
            <th className={classes.cellStyle}>Item Description</th>
            <th className={classes.cellStyle}>UOM</th>
            <th className={classes.cellStyle}>Rate</th>
            <th className={classes.cellStyle}>% Escl</th>
            <th className={classes.cellStyle}>Total Qty</th>
            <th className={classes.cellStyle}>Amount(Rs)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className={classes.cellStyle}>1</td>
            <td className={classes.cellStyle}>06.01.01</td>
            <td className={classes.cellStyle}>
              Excavation in all soils upto 3m depth
            </td>
            <td className={classes.cellStyle}>Cum</td>
            <td className={classes.cellStyle}>251.46</td>
            <td className={classes.cellStyle}>0.00%</td>
            <td className={classes.cellStyle}>16.920</td>
            <td className={classes.cellStyle}>4249.63</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AbstractTable;
