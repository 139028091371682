import React from "react";
import { Avatar, Grid, Paper, Tooltip } from "@material-ui/core";
import useStyle from "../style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Cancel, CheckCircle } from "@mui/icons-material";

function PendingTable(props) {
  const classes = useStyle();
  const { allAdminRequests, setAdminId, handleStatusChangeForAdmin, page } = props;

  return (
    <>
      {allAdminRequests?.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow container>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Sr No.
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Created Date
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Contact
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Society
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAdminRequests?.map((admin, index) => {
                return (
                  <TableRow
                  // key={row.name}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page-1) * 50 + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {moment(admin?.updatedAt).format("DD/MM/YYYY - h:mm A")}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar alt={"Avtar"} className={classes.avatar} />
                      </Grid>
                      {admin?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.email}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {admin?.societies[0]?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip
                          title="Approve Request"
                          placement="top"
                          arrow
                          onClick={() => {
                            setAdminId(admin);
                            handleStatusChangeForAdmin("approve");
                          }}
                        >
                          <CheckCircle
                            style={{
                              color: "green",
                              fontSize: "27px",
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          title="Reject Request"
                          placement="top"
                          arrow
                          onClick={() => {
                            setAdminId(admin);
                            handleStatusChangeForAdmin("reject");
                          }}
                        >
                          <Cancel
                            style={{
                              color: "red",
                              fontSize: "27px",
                              marginLeft: 4,
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default PendingTable;
