import React, { useState, useEffect, useContext } from "react";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import { Check } from "@mui/icons-material";
import useStyles from "./style";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import * as api from "../../api/index";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/base";
import MeetingAttendStatusTable from "./MeetingAttendStatusTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddMeeting from "./AddMeeting";
import InfoIcon from "@mui/icons-material/Info";
import PropertyInfo from "../../contextAPI/PropertyInfo";
import { AuthContext } from "../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../services/service";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import { navigateInitialMeeting } from "../../Navigation/Navigations";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";
import ConfirmModal from "../../GlobalComponents/Modals/ConfirmModal";

function MeetingDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions, user } = authState;
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const { id } = useParams();
  const [meeting, setmeeting] = useState([]);
  const [copied, setcopied] = useState(true);
  const [confirmCancelMeeting, setconfirmCancelMeeting] = useState(false);
  const [isEdited, setisEdited] = useState(false);
  const [editableMeeting, seteditableMeeting] = useState([]);
  const [openPropertyInfoModal, setopenPropertyInfoModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [tab, setTab] = useState("going");
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [attendeeData, setattendeeData] = useState([]);

  const tabs = [
    {
      name: "GOING",
      value: "going",
    },
    {
      name: "NOT GOING",
      value: "notgoing",
    },
    {
      name: "INTERESTED",
      value: "interested",
    },
  ];

  const getStatusInfo = (status) => {
    switch (status) {
      case "inprogress":
        return {
          color: "rgb(202 133 36)",
          text: "INPROGRESS",
        };
      case "upcoming":
        return {
          color: "rgb(36 103 202)",
          text: "UPCOMING",
        };
      case "completed":
        return {
          color: "rgb(36 202 38)",
          text: "COMPLETED",
        };
      case "cancelled":
        return {
          color: "rgb(239 13 39)",
          text: "CANCELLED",
        };
      default:
        return {
          color: "#000000",
          text: "UNKNOWN",
        };
    }
  };

  const { color, text } = getStatusInfo(meeting?.status);

  const handleChange = (newValue) => {
    setTab(newValue);
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!copied) {
      setTimeout(() => {
        setcopied(true);
      }, 2000);
    }
  }, [copied]);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Meeting"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleIndividualMeeting = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        meetingId: id,
        societyId: fetchSocietyId(),
        userId: user?._id,
        page: 1,
        pageLimit: 10,
      };
      const res = await api.getMeetingById(sendData);
      if (res?.data?.success) {
        setmeeting(res?.data?.meeting?.meeting);
      }

      setloader(false);
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };
  const getMeetingAttendee = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    try {
      const sendData = {
        meetingId: id,
        type: tab,
        page: page,
        limit: 10,
      };
      const res = await api.getAttendee(sendData);
      if (res?.data?.success) {
        setattendeeData(res?.data?.meeting?.attendees);
        settotalCount(res?.data?.meeting?.totalAttendees);
        setpageCount(res?.data?.meeting?.totalPages);
        setloader2(false);
      }
    } catch (error) {
      setloader2(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    getMeetingAttendee();
  }, [tab, page]);

  useEffect(() => {
    handleIndividualMeeting();
  }, []);

  const handleCancelMeeting = async () => {
    try {
      const sendData = {
        meetingId: id,
        status: "cancelled",
      };
      const res = await api.cancelMeeting(sendData);
      if (res?.data?.success) {
        toast.success("Meeting Cancelled Successfully");
        handleIndividualMeeting();
        setconfirmCancelMeeting(false);
      }
    } catch (error) {
      console.log(error);
      resolveErrorMessage(error);
    }
  };

  const handleReset = () => {
    setconfirmCancelMeeting(false);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main
          className={classes.content}
          style={{
            filter: loader ? "blur(1px)" : "",
            pointerEvents: loader ? "none" : "",
            overflow: loader ? "hidden" : "auto",
          }}
        >
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <PropertyInfo
                  propertyInfo={meeting?.properties}
                  openPropertyInfoModal={openPropertyInfoModal}
                  setopenPropertyInfoModal={setopenPropertyInfoModal}
                />
                <Grid container className="ms-1 justify-content-start">
                  {" "}
                  <span
                    className="back-to-home pointer"
                    onClick={() => {
                      navigate(navigateInitialMeeting({}));
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12H19M5 12L11 18M5 12L11 6"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    &nbsp; Back to Home
                  </span>
                </Grid>
                <div className="col-sm-12 d-flex mt-3 w-100">
                  <div className="col-sm-6 w-50">
                    {" "}
                    <span className="page-header ms-3">
                      Meeting Details
                      {!loader ? (
                        <RefreshIcon
                          title="refresh Meeting Details Page"
                          onClick={handleIndividualMeeting}
                          style={{
                            marginLeft: 5,
                            marginTop: -4,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="loading..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: -4,
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="col-sm-6 w-50">
                    <span className="page-header ms-3">Creator Details</span>
                  </div>
                </div>
                <div className="col-sm-12 d-flex mb-1">
                  <div className="col-sm-6 d-flex justify-content-end mt-1 gap-2 w-50">
                    {meeting?.status === "upcoming" && isReadWrite && (
                      <>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="me-2 pointer"
                            onClick={() => {
                              setisEdited(true);
                              seteditableMeeting(meeting);
                            }}
                          >
                            <path
                              d="M18.415 10.5904C18.175 10.5904 17.9786 10.7868 17.9786 11.0268V21.0457C17.9786 21.5911 17.5335 22.0319 16.9881 22.0319H2.95461C2.40916 22.0319 1.96843 21.5868 1.96843 21.0457V7.01223C1.96843 6.46677 2.41352 6.02605 2.95461 6.02605H12.9692C13.2092 6.02605 13.4055 5.82968 13.4055 5.58968C13.4055 5.34968 13.2092 5.15332 12.9692 5.15332H2.95461C1.92916 5.15332 1.0957 5.98677 1.0957 7.01223V21.0501C1.0957 22.0755 1.92916 22.909 2.95461 22.909H16.9924C18.0179 22.909 18.8557 22.0755 18.8557 21.0501V11.0268C18.8513 10.7868 18.655 10.5904 18.415 10.5904Z"
                              fill="#979797"
                            />
                            <path
                              d="M22.5333 2.62659L21.3726 1.46586C20.8751 0.96841 20.0635 0.96841 19.5617 1.46586L12.4053 8.62223C12.3573 8.67023 12.3181 8.73132 12.3006 8.79677L11.1704 12.279C11.1181 12.436 11.1617 12.6062 11.2751 12.724C11.3581 12.807 11.4715 12.8506 11.585 12.8506C11.6286 12.8506 11.6766 12.8419 11.7202 12.8288L15.2024 11.6986C15.2679 11.6768 15.329 11.6419 15.377 11.5939L22.5333 4.4375C23.0351 3.93568 23.0351 3.12405 22.5333 2.62659ZM15.0715 10.6644L13.3348 8.92768L18.5362 3.72623L20.273 5.46296L15.0715 10.6644ZM12.9115 9.73932L14.2555 11.0833L12.2657 11.7291L12.9115 9.73932ZM21.9181 3.81786L20.8926 4.84332L19.1559 3.10659L20.1813 2.08114C20.3384 1.92405 20.6002 1.92405 20.7573 2.08114L21.9181 3.24186C22.0751 3.39896 22.0751 3.65641 21.9181 3.81786Z"
                              fill="#979797"
                            />
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="me-3 pointer"
                            onClick={() => {
                              setconfirmCancelMeeting(true);
                            }}
                          >
                            <path
                              d="M4 7H20M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7M10 12L14 16M14 12L10 16"
                              stroke="#979797"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-sm-6"></div>
                </div>

                <div className="col-sm-12 d-flex justify-content-between gap-3 w-100">
                  <div className="col-sm-6 w-50">
                    <div className="meeting-details-info">
                      <div className="d-flex justify-content-between">
                        {" "}
                        <div className="title">{meeting?.meetingTitle}</div>
                        <div className="meeting-details-info-status">
                          <div
                            className="meeting-details-info-status-text"
                            style={{
                              boxSizing: "border-box",
                              width: "auto",
                              height: "34px",
                              borderRadius: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: color,
                              border: `1px solid ${color}`,
                              padding: "10px",
                            }}
                          >
                            {text}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <span className="font-3 fw-bold"> Description</span>
                        <span className="mt-1">{meeting?.description}</span>
                      </div>

                      <div className="meeting-info">Meeting information</div>
                      <div className="col-sm-12 d-flex gap-4">
                        <div className="col-sm-6">
                          {" "}
                          <div className="label label-1">Start Date & Time</div>
                          <div className="frame frame-1">
                            <div className="date-time">
                              {" "}
                              {moment(meeting?.scheduleTime).format(
                                "DD/MM/YYYY - h:mm A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          {" "}
                          <div className="label label-2">End Date & Time</div>
                          <div className="frame frame-2">
                            <div className="date-time">
                              {" "}
                              {moment(meeting?.endMeetingTime).format(
                                "DD/MM/YYYY - h:mm A"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 d-flex gap-4">
                        <div className="col-sm-6">
                          <div className="label label-3 d-flex justify-content-between">
                            <span>Address Type </span>
                            <div className="date-time me-4 text-capitalize">
                              {" "}
                              {meeting?.addressType}
                            </div>
                          </div>
                          <div className="frame frame-3">
                            <div className="url text-truncate">
                              {meeting?.virtualAddress
                                ? meeting?.virtualAddress
                                : meeting?.physicalAddress}{" "}
                            </div>
                            {meeting?.virtualAddress && (
                              <div className="icon pointer">
                                <CopyToClipboard
                                  title="Copy Link"
                                  text={meeting?.virtualAddress}
                                  onCopy={() => {
                                    setTimeout(() => {
                                      setcopied(false);
                                    }, 1000);
                                  }}
                                >
                                  {copied ? (
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.012 14.737C1.70534 14.5622 1.45027 14.3095 1.27258 14.0045C1.09488 13.6995 1.00085 13.353 1 13V3C1 1.9 1.9 1 3 1H13C13.75 1 14.158 1.385 14.5 2M5 7.667C5 6.95967 5.28099 6.28131 5.78115 5.78115C6.28131 5.28099 6.95967 5 7.667 5H16.333C16.6832 5 17.03 5.06898 17.3536 5.20301C17.6772 5.33704 17.9712 5.53349 18.2189 5.78115C18.4665 6.0288 18.663 6.32281 18.797 6.64638C18.931 6.96996 19 7.31676 19 7.667V16.333C19 16.6832 18.931 17.03 18.797 17.3536C18.663 17.6772 18.4665 17.9712 18.2189 18.2189C17.9712 18.4665 17.6772 18.663 17.3536 18.797C17.03 18.931 16.6832 19 16.333 19H7.667C7.31676 19 6.96996 18.931 6.64638 18.797C6.32281 18.663 6.0288 18.4665 5.78115 18.2189C5.53349 17.9712 5.33704 17.6772 5.20301 17.3536C5.06898 17.03 5 16.6832 5 16.333V7.667Z"
                                        stroke="#979797"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <>
                                      <Check
                                        style={{
                                          fontSize: 17,
                                          marginLeft: 6,
                                          color: "#2f45c5",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <span
                                        style={{
                                          fontSize: 14,
                                          marginLeft: 6,
                                          fontFamily: "Montserrat, sans-serif",
                                          fontWeight: "bold",
                                          color: "#2f45c5",
                                        }}
                                      >
                                        &nbsp;Copied
                                      </span>
                                    </>
                                  )}
                                </CopyToClipboard>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label label-4">Category</div>
                          <div className="frame frame-4">
                            <div
                              className="date-time "
                              style={{ minWidth: "25vw" }}
                            >
                              {meeting?.category?.name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 d-flex">
                        <div className="col-sm-6">
                          <div className="label label-5">Type</div>
                          <div className="frame frame-5 ">
                            <div
                              className="date-time"
                              style={{ minWidth: "25vw" }}
                            >
                              {meeting?.viewerType === "societyWise" ? (
                                <>Society Wise</>
                              ) : (
                                <>
                                  {meeting?.properties?.length}&nbsp;
                                  {handlePropertyName()}
                                  <InfoIcon
                                    onClick={() => {
                                      setopenPropertyInfoModal(true);
                                    }}
                                    style={{
                                      color: "#2f45c5",
                                      cursor: "pointer",
                                      marginTop: -3,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 w-50">
                    <div class="meeting-details-contact-info-card">
                      <div class="meeting-details-contact-info-avatar">
                        <img
                          src={
                            meeting?.addedBy?.image
                              ? `${BASE_URL}/user/${meeting?.addedBy?.image}`
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          alt="Sociohood"
                        />
                      </div>
                      <div class="meeting-details-contact-info-details">
                        <div class="meeting-details-contact-info-item">
                          <div class="meeting-details-contact-info-label meeting-details-contact-info-name-label">
                            Name:
                          </div>
                          <div class="meeting-details-contact-info-edit-text text-capitalize">
                            {meeting?.addedBy?.name}{" "}
                            {meeting?.addedBy?.role &&
                              `(${meeting?.addedBy?.role})`}
                          </div>
                        </div>
                        <div class="meeting-details-contact-info-item">
                          <div class="meeting-details-contact-info-label meeting-details-contact-info-phone-label">
                            Phone no:
                          </div>
                          <div class="meeting-details-contact-info-edit-text">
                            {meeting?.addedBy?.phone}
                          </div>
                        </div>
                        <div class="meeting-details-contact-info-item">
                          <div class="meeting-details-contact-info-label meeting-details-contact-info-email-label">
                            Email:
                          </div>
                          <div class="meeting-details-contact-info-edit-text">
                            {meeting?.addedBy?.email}
                          </div>
                        </div>
                        <div class="meeting-details-contact-info-item">
                          <div class="meeting-details-contact-info-label meeting-details-contact-info-employee-id-label">
                            Creation Time :
                          </div>
                          <div class="meeting-details-contact-info-edit-text">
                            {moment(meeting?.createdAt).format(
                              "DD/MM/YYYY - h:mm A"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12} sm={12} style={{ marginTop: 6 }}>
            <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
              <Grid container className="gap-3">
                <span className="page-header w-30 mt-2 ms-3">
                  {" "}
                  Status{" "}
                  {!loader ? (
                    <RefreshIcon
                      title="Refresh Meeting Details Page"
                      onClick={getMeetingAttendee}
                      style={{
                        marginLeft: 5,
                        marginTop: -4,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="loading..."
                      style={{
                        marginLeft: 5,
                        color: "#000",
                        marginTop: -4,
                      }}
                    />
                  )}
                </span>
                <div
                  className="btn-group btn-sm d-sm-flex d-none w-70"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  {tabs?.map((tb) => {
                    return (
                      <button
                        onClick={() => {
                          handleChange(tb?.value);
                        }}
                        className={`btn fs-7 ${
                          tab === tb?.value ? "text-primary" : "text-dark"
                        }`}
                        style={{
                          borderBottom:
                            tab === tb?.value ? "2px solid #1944c2" : "none",
                          transition: "border-color 0.3s ease",
                          borderRadius: "0rem",
                        }}
                        key={tb?.value}
                      >
                        {tb?.icon}&nbsp; {tb?.name}
                      </button>
                    );
                  })}
                </div>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                {!loader2 && attendeeData?.length > 0 && (
                  <MeetingAttendStatusTable
                    meeting={meeting}
                    attendeeData={attendeeData}
                    tab={tab}
                    count={totalCount}
                    pageCount={pageCount}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                )}
                {!loader2 && attendeeData?.length === 0 && (
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      fontSize: 27,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    No Status Details
                  </Typography>
                )}
              </Grid>
            </Paper>
            <AddMeeting
              isEdited={isEdited}
              setisEdited={setisEdited}
              editableMeeting={editableMeeting}
              handleIndividualMeeting={handleIndividualMeeting}
            />
          </Grid>
        </main>
        <ConfirmModal
          open={confirmCancelMeeting}
          setOpen={setconfirmCancelMeeting}
          text={` ARE YOU SURE YOU WANT TO CANCEL THIS MEETING ?`}
          loader={loader}
          handleReset={handleReset}
          handleConfirm={handleCancelMeeting}
        />
        {(loader || loader2) && <BanterLoader />}
      </div>
    </>
  );
}

export default MeetingDetails;
