import React, { useContext, useEffect, useState } from "react";
import useStyles from "../ComplaintCategory/styles";
import { Grid, Typography } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../contextAPI/context";
import AreaTable from "./AreaTable";
import AddArea from "./AddArea";
import ExploreIcon from "@mui/icons-material/Explore";
import { startApiCall } from "../../../GlobalFunctions/GlobalFunctions";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import ApiCall from "../../../api/ApiCall";
import { AreaRoutes } from "../../../api/Routes";
import { Pagination } from "@mui/material";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";

const Area = () => {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [pageLimit] = useState(50);
  const [allArea, setAllArea] = useState([]);
  const [addArea, setAddArea] = useState(false);
  const [editArea, setEditArea] = useState([]);
  const [isEditArea, setisEditArea] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Area")
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetAllArea = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", AreaRoutes.getArea, data);
    if (res?.success) {
      setAllArea(res?.area?.data);
      setCount(res?.area?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllArea();
  }, [page]);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        handleGetAllArea(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      handleGetAllArea("");
    }
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint{" "}
                    <span style={{ color: "#000" }}>{` > Area `}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Area Page"
                        onClick={handleGetAllArea}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <div
                  class="box"
                  style={{
                    minWidth: "250px",
                    marginLeft: 10,
                    border: "1px solid #E1E1E1",
                    borderRadius: 10,
                  }}
                >
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                    type="text"
                    placeholder="search..."
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </Grid>
              <Grid
                container
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                {isReadWrite && (
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "130px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setAddArea(true);
                        }}
                      >
                        <ExploreIcon
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Add Area
                      </p>
                    </div>
                  </div>
                )}
              </Grid>
              {!loader && allArea?.length > 0 && (
                <Grid container>
                  <AreaTable
                    allArea={allArea}
                    page={page}
                    pageLimit={pageLimit}
                    editArea={editArea}
                    setEditArea={setEditArea}
                    setisEditArea={setisEditArea}
                    handleGetAllArea={handleGetAllArea}
                    isReadWrite={isReadWrite}
                  />
                </Grid>
              )}
              {allArea?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Area
                  </Typography>
                </Grid>
              )}
              {allArea?.length > 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
        <AddArea
          addArea={addArea}
          setAddArea={setAddArea}
          editArea={editArea}
          isEditArea={isEditArea}
          setisEditArea={setisEditArea}
          handleGetAllArea={handleGetAllArea}
        />
      </main>
      {loader && <BanterLoader />}
    </div>
  );
};

export default Area;
