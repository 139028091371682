import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "#e6e7f0",
    minHeight: "100vh",
  },
  addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    backgroundColor: "#4b8edb",
    color: "#fff",
    outline: 0,
    border: "1px solid #4b8edb",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "45vw",
    borderRadius: 6,
  },
  buttonDelete: {
    borderRadius: 5,
    marginTop: theme.spacing(2),
    backgroundColor: "#4b8edb",
    padding: 7,
    fontWeight: "bold",
    fontSize: 15,
    width: 140,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4b8edb",
      color: "#fff",
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  feedBackModal: {
    position: "absolute",
    top: "50%",
    left: "75%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "60vw",
    borderRadius: 6,
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },

  buttonDelete: {
    borderRadius: 5,
    marginTop: theme.spacing(3),
    backgroundColor: "#4b8edb",
    padding: 7,
    fontWeight: "bold",
    fontSize: 17,
    width: 170,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4b8edb",
      color: "#fff",
    },
  },

  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.info.dark),
    cursor: "pointer",
    border: "1px solid black",
  },
  textname: {
    color: "black",
    cursor: "pointer",
    fontFamily: "Poppins, sans-serif",
    "&:hover": {
      color: "#2f3aa3",
    },
  },
  name: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.info.main,
  },
  card: {
    backgroundolor: " #fff",
    borderRadius: "10px",
    boxShadow:
      "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
    display: "flex",
    maxWidth: " 100%",
    marginTop: 20,
    overflow: "hidden",
    width: "100%",
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    maxWidth: "450px",
    minWidth: "300px",
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
}));
