import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),

    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "35vw",
    borderRadius: 6,
  },
  hoverText: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#007aff",
    },
  },
  cardSection: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "19.75em",
    minHeight: "20.35em",
    maxHeight: "20.35em",
    overflow: "hidden",
    borderRadius: ".4em",
    textDecoration: "none",
    margin: "0.70em",
    padding: "1.8em 1.2em",
    boxShadow: "0 3px 6px #7D858D",
    "&:hover": {
      boxShadow: "1px 6px 10px #7D858D",
      transform: "scale(1.02)",
      transition: "all 0.5s ease-in-out",
    },
  },
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "15px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  voteButton: {
    backgroundColor: "#2f45c5",
    border: " 0 solid #e2e8f0",
    borderRadius: "1.5rem",
    boxSizing: "border-box",
    color: "#fff",
    cursor: "pointer",
    display: "inline-block",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: "bolder",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    textAlign: "center",
    textDecoration: "none #0d172a solid",
    textDecorationThickness: "auto",
    transition: "all .1s cubic-bezier(.4, 0, .2, 1)",
    boxShadow: "0px 1px 2px #2f45c5",
    userSelect: "none",
    touchAction: "manipulation",
    "&:hover": {
      backgroundColor: "rgba(20,167,62,1) ",
      color: "#fff",
      boxShadow: "0px 1px 2px rgba(102,247,113,1) 100%",
    },
  },
  confirmModal: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e6e7f0",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));
