import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchAddress from "../../../../GlobalComponents/SearchAddress/SearchAddress";
import { Close } from "@material-ui/icons";
import { getSocietyFloors } from "../../../../GlobalFunctions/GlobalFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import {
  handleFlatName,
  handleFloorName,
} from "../../../../contextAPI/NamingConvention";

const FlatModal = ({
  flatModal,
  setFlatModal,
  setPermissionType,
  selectedFlats,
  setSelectedFlats,
  setSelectAllFlats,
  selectAllFlats,
}) => {
  const classes = useStyles();
  const [property, setproperty] = useState("Select Property");
  const [searchKey, setsearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [allFlats, setallFlats] = useState([]);
  const [selectedFloor, setselectedFloor] = useState("All Floors");
  const [filteredFloors, setfilteredFloors] = useState([]);
  const floors = filteredFloors?.length === 0 ? allFlats : filteredFloors;

  const handleGetFlats = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);

    const res = await getSocietyFloors({
      propertyId: property?._id,
      floorId: selectedFloor === "All Floors" ? "" : selectedFloor,
    });
    setallFlats(res?.allFloors);
    setfilteredFloors(res?.filteredFloors);
    setLoading(false);
  };

  useEffect(() => {
    if (property?._id) {
      handleGetFlats();
    }
  }, [selectedFloor, property]);

  const handleSelectAll = (floor, isSelected) => {
    const newSelected = floor.flats.map((flat) => ({
      flat: flat.name,
      _id: flat._id,
      floorName: floor.name,
      propertyName: property.name,
    }));
    if (isSelected) {
      setSelectedFlats([...selectedFlats, ...newSelected]);
    } else {
      const filteredFlats = selectedFlats.filter(
        (flat) => flat.floorName !== floor.name
      );
      setSelectedFlats(filteredFlats);
    }
    setSelectAllFlats((prevState) => ({
      ...prevState,
      [floor.name]: isSelected,
    }));
  };

  const handleCheckboxChange = (floor, flat) => {
    const isSelected = selectedFlats.some(
      (selected) =>
        selected._id === flat._id && selected.floorName === floor.name
    );
    const newSelectedFlat = isSelected
      ? selectedFlats.filter((selected) => !(selected._id === flat._id))
      : [
          ...selectedFlats,
          {
            flat: flat.name,
            _id: flat._id,
            floorName: floor.name,
            propertyName: property.name,
          },
        ];
    const allSelected = floor.flats.every((floorFlat) =>
      newSelectedFlat.some(
        (selected) =>
          selected._id === floorFlat._id && selected.floorName === floor.name
      )
    );
    setSelectedFlats(newSelectedFlat);
    setSelectAllFlats((prevState) => ({
      ...prevState,
      [floor.name]: allSelected,
    }));
  };

  const uniqueFlats = selectedFlats.reduce((acc, flat) => {
    if (!acc.find((item) => item._id === flat._id)) {
      acc.push(flat);
    }
    return acc;
  }, []);

  useEffect(() => {
    const updateSelectAllFlats = {};
    floors.forEach((floor) => {
      const allSelected = floor.flats.every((flat) =>
        selectedFlats.some(
          (selected) =>
            selected._id === flat._id && selected.floorName === floor.name
        )
      );
      updateSelectAllFlats[floor.name] = allSelected;
    });
    setSelectAllFlats(updateSelectAllFlats);
  }, [selectedFlats, floors]);

  return (
    <Modal open={flatModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: selectedFlats?.length > 0 ? "800px" : "300px",
          maxWidth: selectedFlats?.length > 0 ? "75vw" : "35vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select {handleFlatName()}
          </Typography>
          <IconButton
            onClick={() => {
              setFlatModal(false);
              setPermissionType("Choose Type");
              setSelectedFlats([]);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <SearchAddress
            searchKey={searchKey}
            setsearchKey={setsearchKey}
            setPropertyId={setproperty}
            propertyId={property}
            isMultiple={false}
          />
        </Grid>
        {floors?.length > 0 && (
          <Grid container style={{ justifyContent: "flex-end", marginTop: 8 }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                marginRight: 15,
              }}
            >
              Change {handleFloorName()} :
              <Select
                value={selectedFloor}
                onChange={(event) => {
                  setselectedFloor(event.target.value);
                }}
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "150px",
                  height: 34,
                  fontSize: "13px",
                  padding: 8,
                  paddingLeft: 6,
                  marginLeft: 8,
                  border: "1px solid #6c757d",
                  textAlign: "center",
                }}
              >
                <MenuItem
                  selected
                  value="All Floors"
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat,sans-serif",
                  }}
                >
                  All {handleFloorName()}
                </MenuItem>
                {allFlats?.map((flr, index) => {
                  return (
                    <MenuItem
                      selected
                      value={flr._id}
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          marginTop: 3,
                          color: "#6c757d",
                        }}
                      >
                        {index + 1}.
                      </span>
                      &nbsp;{flr.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Typography>
          </Grid>
        )}
        <Grid
          container
          style={{
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Grid
            item
            xs={selectedFlats?.length > 0 ? 8 : 12}
            style={{ overflowY: "scroll", maxHeight: "60vh" }}
          >
            {!loading && (
              <>
                {floors?.map((floor, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 22,
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          marginLeft: 5,
                          marginTop: index > 0 ? 8 : "",
                        }}
                      >
                        {index + 1}.&nbsp;
                        {floor?.name}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className="scrollable"
                        style={{ marginTop: 8 }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="text-nowrap"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeaderCell}
                                sx={{
                                  color: "white",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ cursor: "pointer" }}
                                  className="form-check-input "
                                  onChange={(e) => {
                                    handleSelectAll(floor, e.target.checked);
                                  }}
                                  checked={selectAllFlats[floor.name] || false}
                                />
                                &nbsp; Sr. No.
                              </TableCell>
                              <TableCell
                                className={classes.tableHeaderCell}
                                sx={{
                                  color: "white",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                Name
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {floor?.flats?.map((flat, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      style={{ cursor: "pointer" }}
                                      className="form-check-input"
                                      checked={selectedFlats.some(
                                        (selected) =>
                                          selected._id === flat._id &&
                                          selected.floorName === floor.name
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(floor, flat)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    {index + 1}.
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontFamily: "Montserrat, sans-serif",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {flat?.name}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </React.Fragment>
                  );
                })}
              </>
            )}
            {loading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </div>
            )}
          </Grid>
          {selectedFlats?.length > 0 && (
            <Grid item xs={4}>
              <div
                style={{
                  color: "#6c757d",
                }}
                className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
              >
                Selected {handleFlatName()} : {uniqueFlats?.length}
              </div>
              <div
                className="d-flex flex-column gap-3 overflow-auto scrollable p-3"
                style={{ height: "calc(60vh - 40px)" }}
              >
                {uniqueFlats?.map((flt, index) => {
                  return (
                    <p
                      className="mb-0 text-dark"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {index + 1}. {flt?.propertyName}, {flt?.floorName},{" "}
                      {flt?.flat}
                    </p>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor: uniqueFlats?.length > 0 ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (uniqueFlats?.length > 0) {
                setFlatModal(false);
              } else {
                toast.error(`Please Select ${handleFlatName()}`);
              }
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default FlatModal;
