import { Grid, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { CheckCircle, History } from "@material-ui/icons";
import { Typography } from "antd";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { useParams } from "react-router";

function ChooseSec(props) {
  const { sector, setsector, setisSectorConfigured } = props;
  const [loading, setLoading] = useState(false);
  const [allSector, setallSector] = useState([]);
  const { id } = useParams();

  const handleGetSector = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    try {
      let sendData = {
        categoryId: id,
        societyId: fetchSocietyId(),
      };
      const res = await api.getSectorForAdvanceVendor(sendData);
      if (res?.data?.success) {
        setallSector(res?.data?.complaintCategory);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetSector();
  }, []);

  return (
    <Grid container item xs={12}>
      <Select
        value={sector}
        onChange={(event) => {
          setsector(event.target.value);
        }}
        style={{
          display: "block",
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          backgroundColor: "#fff",
          borderRadius: 6,
          fontSize: 16,
          width: "97%",
          padding: 8,
          paddingLeft: 20,
          height: 52,
          outline: 0,
          border: "1px solid #283f59",
        }}
        disabled={loading}
      >
        <MenuItem selected value="Select Sector">
          {loading ? "Loading..." : "Select Sector"}
        </MenuItem>
        {allSector?.map((sec) => {
          return (
            <MenuItem selected value={sec?.sector} onClick={()=>{
                setisSectorConfigured(sec)
            }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Typography>
                  {sec?.isAdded ? (
                    <CheckCircle style={{ color: "rgb(36 202 38)" }} />
                  ) : (
                    <History style={{ color: "orange" }} />
                  )}
                  &nbsp;
                  {sec?.sector}
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    color: sec?.isAdded ? "rgb(36 202 38)" : "orange",
                    marginRight: "20px",
                  }}
                >
                  {sec?.isAdded ? "Configured" : "Pending"}
                </Typography>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
}

export default ChooseSec;
