import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../contextAPI/Loading";
import { AllVendorRoutes } from "../../api/Routes";
import ApiCall from "../../api/ApiCall";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";

function VendorCategory({ categoryModal, handleCloseDialog, vendor }) {
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [categoryData, setcategoryData] = useState([]);
  const [vendorCategory, setvendorCategory] = useState([]);
  const [vendorSubCategory, setvendorSubCategory] = useState([]);

  const handleGetVendorCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);

    let sendData = {
      societyId: fetchSocietyId(),
      vendorId: vendor?._id,
    };
    const res = await ApiCall(
      "post",
      AllVendorRoutes.getVendorCategory,
      sendData
    );
    if (res?.success) {
      setcategoryData(res?.data);

      setloader(false);
    } else {
      setloader(false);
      seterrorMsg(res?.error);
    }
  };

  useEffect(() => {
    const uniqueCategories = new Set();
    const uniqueSubcategories = new Set();

    categoryData?.forEach((item) => {
      uniqueCategories?.add(item?.categoryId?.categoryName);

      uniqueSubcategories?.add(item?.subCategoryId?.subcategoryName);
    });

    setvendorCategory([...uniqueCategories] || []);
    setvendorSubCategory([...uniqueSubcategories] || []);
  }, [categoryData]);

  useEffect(() => {
    if (vendor?._id && categoryModal) {
      handleGetVendorCategory();
    }
  }, [vendor, categoryModal]);
  return (
    <Dialog
      open={categoryModal}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Grid container>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 13,
              fontWeight: "bolder",
              marginBottom: 10,
            }}
          >
            Category & Sub-Category&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {!loader && (
          <Grid
            container
            xs={12}
            style={{
              justifyContent: "flex-start",
              width: "400px",
              overflowX: "hidden",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <div className="d-flex col-sm-12">
              <div className="col-sm-4 fs-6 text-secondary">Category :</div>

              <div className="col-sm-8 fs-6">
                {vendorCategory?.map((category, index) => (
                  <div className="mt-1">
                    {index + 1}. {category}
                  </div>
                ))}
              </div>
            </div>
            {vendor?.isSubVendor && (
              <div className="d-flex col-sm-12 mt-2">
                <div className="col-sm-4 fs-6 text-secondary">
                  Sub-Category :
                </div>
                <div className="col-sm-8 fs-6">
                  {vendorSubCategory?.map((subcategory, index) => (
                    <div className="mt-1">
                      {index + 1}. {subcategory}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Grid>
        )}
        {loader && (
          <Grid
            container
            xs={12}
            style={{
              justifyContent: "space-between",
              width: "400px",
              minHeight: "10vh",
            }}
          >
            {" "}
            <Loading />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default VendorCategory;
