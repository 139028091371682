import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
  CssBaseline,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import Badge from "@mui/material/Badge";
import RefreshIcon from "@mui/icons-material/Refresh";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { navigateToPendingApprovals } from "../../Navigation/Navigations";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import * as api from "../../api/index";
import { resolveErrorMessage } from "../../services/service";
import Loading from "../../contextAPI/Loading";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { Close, FilterList, PersonSearch } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import { FindReplace } from "@material-ui/icons";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { ExportJsonCsv } from "react-export-json-csv";
import ApprovedTable from "./Approved/ApprovedTable";

function AdminApproval() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [allAdminApproval, setallAdminApproval] = useState([]);
  const [search, setsearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [csvData, setcsvData] = useState([]);
  const [totalPendingAdmin, settotalPendingAdmin] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [downloadCsv, setdownloadCsv] = useState(false);

  var date = moment(new Date()).format("YYYY-MM-DD");
  var newDate = moment(new Date()).add(-1, "months").format("YYYY-MM-DD");

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const headers = [
    {
      key: "index",
      name: "Sr. No.",
    },
    {
      key: "ApprovedDate",
      name: "Approved Time",
    },
    {
      key: "name",
      name: "Name",
    },
    {
      key: "role",
      name: "Role",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "contact",
      name: "Phone",
    },
    {
      key: "status",
      name: "Status",
    },
  ];

  const createCsvData = (admins) => {
    setloader(true);
    let temp = [];
    admins?.map((row, index) => {
      return temp.push({
        index: index + 1,
        ApprovedDate: moment(row?.actionTime).format("DD/MM/YYYY - h:mm A"),
        name: row.name,
        role: row.role,
        email: row.email,
        contact: row.phone,
        status: "APPROVED",
      });
    });
    setcsvData([...temp]);
    setloader(false);
  };

  const handleReset = () => {
    setPage(1);
    setsearch("");
    setStartDate("");
    setEndDate("");
  };

  const handleGetAdminApproval = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        status: "approved",
        pageNumber: page,
        pageLimit: 50,
        searchKey: search,
        startDate: startDate ? startDate : newDate,
        endDate: endDate ? endDate : date,
      };
      const res = await api.getAdminRequest(sendData);
      if (res?.data?.success) {
        setallAdminApproval(res?.data?.admin?.users);
        createCsvData(res?.data?.admin?.users);
        settotalPendingAdmin(res?.data?.admin?.totalPending);
        setCount(res?.data?.admin?.totalPages);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetAdminApproval();
  }, [page, search, startDate]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={4}
                  lg={4}
                  sm={4}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginBottom: 15,
                    }}
                  >
                    Admin Approvals
                    {!loader ? (
                      <RefreshIcon
                        title="refresh S2 Admin Approval"
                        onClick={handleGetAdminApproval}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={8}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div class="box" style={{ marginTop: "-5px" }}>
                    <input
                      type="text"
                      placeholder="search admin"
                      onChange={(e) => {
                        setsearch(e.target.value);
                        setPage(1);
                      }}
                      value={search}
                    />
                    <div>
                      <SearchIcon />
                    </div>
                  </div>

                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "160px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(navigateToPendingApprovals({}));
                    }}
                  >
                    <div class="card-body">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <Badge
                          badgeContent={totalPendingAdmin}
                          color="warning"
                        ></Badge>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        REQUESTS&nbsp;&nbsp; &nbsp;
                        <ArrowForwardIcon
                          style={{
                            marginRight: 8,
                            fontSize: "15px",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #7D858D",
                      width: "160px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    onClick={() => {
                      setdownloadCsv(true);
                      // fetchAdminDataForCsv();
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          textAlign: "center",
                        }}
                      >
                        <CloudDownloadOutlined
                          style={{ marginRight: 10, fontSize: "15px" }}
                        />
                        DOWNLOAD CSV
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  container
                  item
                  style={{ justifyContent: "flex-end", marginTop: 10 }}
                >
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginRight: 8,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: 12,
                    }}
                  >
                    Start Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 120,
                      height: 40,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                    value={startDate === "" ? newDate : startDate}
                    placeholder="Choose Start Date"
                    max={date}
                  />

                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 12,
                      marginTop: 12,
                      marginRight: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    End Date
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: 120,
                      height: 40,
                      border: "1px solid #416082",
                      outline: 0,
                    }}
                    min={startDate}
                    max={date}
                    disabled={!startDate}
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                    placeholder="Choose End Date"
                    value={endDate === "" ? date : endDate}
                  />
                  <div
                    className="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginLeft: 5,
                      marginTop: 5,
                      marginRight: 5,
                    }}
                    onClick={() => {
                      setPage(1);
                      handleGetAdminApproval();
                    }}
                  >
                    <div class="card-body ">
                      <p
                        className="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <PersonSearch
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        FIND
                      </p>
                    </div>
                  </div>
                  <div
                    className="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      handleReset();
                    }}
                  >
                    <div class="card-body ">
                      <p
                        className="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <FindReplace
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        RESET
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                <ApprovedTable
                  allAdminApproval={allAdminApproval}
                  loader={loader}
                  page={page}
                />
              </Grid>
              {allAdminApproval?.length > 0 && (
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              {!loader && allAdminApproval?.length === 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      color: "#6c757d",
                      textAlign: "center",
                      fontSize: 22,
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    No Admins
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
      <Modal open={downloadCsv}>
        <Paper
          style={{
            position: "absolute",
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            overflow: "hidden",
            width: "400px",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 10,
          }}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Downlaod Admin as CSV
            </Typography>

            <IconButton
              onClick={() => {
                setdownloadCsv(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 7,
              backgroundColor: "#000",
            }}
          />
          <Grid
            style={{
              border: "1px solid #6c757d",
              borderRadius: 8,
              padding: 6,
            }}
          >
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 15,
                marginBottom: 4,
                textAlign: "center",
              }}
            >
              <FilterList />
              &nbsp; Filter Applied
            </Typography>
            <Divider
              style={{
                marginTop: 0,
                marginBottom: 7,
                backgroundColor: "#6c757d",
                alignItems: "center",
              }}
            />
            <Grid container style={{ justifyContent: "space-between" }}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                Start Date : &nbsp;{startDate ? startDate : newDate}
              </Typography>

              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                End Date : &nbsp;{endDate ? endDate : date}
              </Typography>
            </Grid>
          </Grid>

          {csvData?.length > 0 && (
            <Typography
              style={{
                marginTop: 10,
                marginBottom: 10,
                textAlign: "center",
                color: "#2f45c5",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
            >
              {csvData?.length} Data for Download
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 8 }}
              >
                <ExportJsonCsv
                  fileTitle={`Approval Admins-${fetchSocietyId()}`}
                  headers={headers}
                  items={csvData}
                  style={{
                    fontSize: "11px",
                    height: 32,
                    width: "160px",
                    boxShadow: "0 3px 6px #4a6078",
                    backgroundColor: "#2f45c5",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#ffffff",
                    borderRadius: 5,
                    border: "2px solid #2f45c5",
                    textAlign: "center",
                    marginLeft: 6,
                  }}
                >
                  <CloudDownloadOutlined
                    style={{ marginRight: 10, fontSize: "15px" }}
                  />
                  DOWNLOAD CSV
                </ExportJsonCsv>
              </Grid>
            </Typography>
          )}
        </Paper>
      </Modal>
    </div>
  );
}

export default AdminApproval;
