import React, { useState } from "react";
import useStyles from "../ComplaintCategory/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowRightAlt, DeleteOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router";
import { navigateToComplaintSubArea } from "../../../Navigation/Navigations";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../../GlobalFunctions/GlobalFunctions";
import { AreaRoutes } from "../../../api/Routes";
import ConfirmModal from "../../../contextAPI/ConfirmModal";
import { toast } from "react-toastify";

const AreaTable = ({
  allArea,
  page,
  pageLimit,
  editArea,
  setEditArea,
  setisEditArea,
  handleGetAllArea,
  isReadWrite,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [deleteArea, setDeleteArea] = useState(false);

  const handleReset = () => {
    setDeleteArea(false);
    setLoader(false);
    seterrorMsg("");
  };

  const handleDeleteSubArea = async () => {
    startApiCall(seterrorMsg, setLoader);
    const data = {
      isDeleted: true,
      areaId: editArea?._id,
    };
    const res = await ApiCall("post", AreaRoutes.editArea, data);
    if (res?.success) {
      toast.success("Area Deleted Successfully!");
      handleReset();
      handleGetAllArea();
    } else {
      setLoader(false);
      seterrorMsg(res.error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Area Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Subarea
              </TableCell>
              {isReadWrite && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allArea?.map((area, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * pageLimit + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {area?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {area?.subAreaCount === 0 ? "No" : area?.subAreaCount}{" "}
                    Subarea
                    <ArrowRightAlt
                      title="go to subarea page"
                      style={{ fontSize: 25, cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          navigateToComplaintSubArea({
                            areaId: area?._id,
                            areaName: area?.name,
                          })
                        )
                      }
                    />
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span
                        className="badge bg-primary"
                        onClick={() => {
                          setEditArea(area);
                          setisEditArea(true);
                        }}
                        style={{ width: "50px", padding: 8, cursor: "pointer" }}
                      >
                        <EditIcon style={{ fontSize: "12px" }} />
                      </span>
                      {/* <span
                      className="badge bg-danger ms-2"
                      onClick={() => {
                        setEditArea(area)
                        setDeleteArea(true);
                      }}
                      style={{
                        width: "50px",
                        padding: 8,
                        cursor: "pointer",
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: "12px" }} />
                    </span> */}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={`ARE YOU SURE YOU WANT TO DELETE ${editArea?.name} ?`}
        description="You can confirm by clicking below."
        loader={loader}
        isOpen={deleteArea}
        errorMsg={errorMsg}
        rejectConfirmation={handleReset}
        handleConfirmation={handleDeleteSubArea}
      />
    </>
  );
};

export default AreaTable;
