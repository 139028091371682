import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  Divider,
  Tooltip,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { complaintSubcategoryRoutes } from "../../../api/Routes";
import ApiCall from "../../../api/ApiCall";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { isMemberTypeS2Admin } from "../../../GlobalFunctions/GlobalFunctions";
import { Switch } from "antd";
import {
  AddCircleOutlined,
  InfoOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import AddPriorityContactPlant from "./AddPriorityContactPlant";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { QuestionMark } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DomainAddOutlined } from "@mui/icons-material";

const PriorityContactPlant = ({
  seePriorityNumbers,
  setseePriorityNumbers,
  user,
  subcategory,
  getComplaintSubCategory,
}) => {
  const classes = useStyles();
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allAdditionalContacts, setallAdditionalContacts] = useState([]);
  const [isConfirm, setisConfirm] = useState("");
  const [addContactModal, setaddContactModal] = useState(false);
  const [deletedConatct, setdeletedConatct] = useState("");
  const [isDeleted, setisDeleted] = useState(false);

  const handleGetPriorityContacts = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const res = await ApiCall(
      "post",
      complaintSubcategoryRoutes.getPriorityContact,
      {
        subcategoryId: subcategory?._id,
      }
    );
    if (res?.success) {
      setallAdditionalContacts(res?.data?.contact);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleRemovePriorityContact = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const data = {
      subComplaintCategoryId: subcategory?._id,
      plantEmergencyContact: deletedConatct,
    };
    const res = await ApiCall(
      "post",
      complaintSubcategoryRoutes.removePriorityContactPlant,
      data
    );
    if (res?.success) {
      toast.success("Delete Priority Contact Successfully");
      setisConfirm("");
      setloader2(false);
      setaddContactModal(false);
      handleGetPriorityContacts();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  const handleChangePriorityStatus = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const res = await ApiCall(
      "post",
      complaintSubcategoryRoutes.changePriorityContactStatus,
      {
        subcategoryId: subcategory?._id,
        societyId: fetchSocietyId(),
        status: !subcategory?.emergency,
      }
    );
    if (res?.success) {
      toast.success(
        `${
          !subcategory?.emergency ? "Enable" : "Disable"
        } Priority Contact Successfully`
      );
      setisConfirm("");
      setloader2(false);
      setseePriorityNumbers(false);
      getComplaintSubCategory();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    handleGetPriorityContacts();
  }, [subcategory]);

  return (
    <Grid>
      <Modal open={seePriorityNumbers}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            padding: "10px",
            paddingRight: "20px",
          }}
          className={classes.deleteModal}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Priority Contacts&nbsp;
            </Typography>

            <IconButton
              onClick={() => {
                setseePriorityNumbers(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>
          <Divider
            style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
          />
          {isMemberTypeS2Admin(user?.role) && (
            <Grid
              container
              item
              xs={12}
              style={{ justifyContent: "space-between" }}
            >
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginTop: 6,
                  marginBottom: 10,
                  marginLeft: 10,
                }}
              >
                <Switch
                  checked={subcategory?.emergency}
                  disabled={allAdditionalContacts?.length === 0}
                  onChange={(e) => {
                    setisConfirm("change");
                  }}
                />{" "}
                &nbsp;
                {subcategory?.emergency ? "Enabled" : "Disabled"}&nbsp;{" "}
                <Tooltip
                  title={`When the emergency contact feature is enabled, the contacts added to the emergency list will receive a message regarding the complaint status (assigned,close,reopen)`}
                  placement="right"
                  arrow
                >
                  <InfoOutlined
                    style={{
                      cursor: "pointer",
                      color: "#2f45c5",
                      marginTop: "-2px",
                    }}
                  />
                </Tooltip>
              </Typography>
              <div
                class="card"
                style={{
                  height: 30,
                  boxShadow: "0 3px 6px #4a6078",
                  width: "143px",
                  backgroundColor: "#2f45c5",
                  color: "#ffffff",
                  cursor: "pointer",
                  marginRight: 15,
                }}
              >
                <div class="card-body ">
                  <p
                    class="card-text"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "-12px",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setaddContactModal(true);
                    }}
                  >
                    <AddCircleOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                    Add User
                  </p>
                </div>
              </div>
            </Grid>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            {!loader && allAdditionalContacts?.length > 0 && (
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{ maxHeight: 440 }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sr.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Phone
                      </TableCell>
                      {isMemberTypeS2Admin(user?.role) && (
                        <TableCell
                          align="center"
                          className={classes.tableHeaderCell}
                          sx={{
                            color: "white",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAdditionalContacts?.map((ac, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "black",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {index + 1}.
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "black",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {ac?.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: "normal",
                            }}
                          >
                            {ac?.role ? ac?.role : "Vendor"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "black",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {ac?.phone}
                          </TableCell>
                          {isMemberTypeS2Admin(user?.role) && (
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <DeleteOutline
                                onClick={() => {
                                  setisConfirm("delete");
                                  setisDeleted(true);
                                  setdeletedConatct(ac?._id);
                                }}
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {!loader && allAdditionalContacts?.length === 0 && (
              <Typography
                className={classes.contactText}
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "12px",
                }}
              >
                No Priority Contacts
              </Typography>
            )}
            {loader && (
              <Typography
                className={classes.contactText}
                style={{ fontFamily: "Montserrat, sans-serif" }}
              >
                Loading contacts...
                <LoadingOutlined
                  style={{ fontSize: 20, color: "#2f45c5", marginLeft: 8 }}
                />
              </Typography>
            )}
          </Grid>
        </Paper>
      </Modal>
      {addContactModal && (
        <AddPriorityContactPlant
          addContactModal={addContactModal}
          setaddContactModal={setaddContactModal}
          subcategory={subcategory}
          handleGetPriorityContacts={handleGetPriorityContacts}
          allAdditionalContacts={allAdditionalContacts}
        />
      )}
      {isConfirm !== "" && (
        <Modal open={isConfirm !== ""}>
          <Paper
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              padding: 20,
              outline: 0,
              width: 450,
              backgroundColor: "#e6e7f0",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
            }}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
                textTransform: "uppercase",
              }}
            >
              {`${
                isConfirm === "change"
                  ? `ARE YOU SURE YOU WANT TO ${
                      !subcategory?.emergency ? "ENABLE" : "DISABLE"
                    } PRIORITY CONTACTS`
                  : "ARE YOU SURE YOU WANT TO DELETE THIS CONTACT"
              }`}
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              You can confirm by clicking below.
            </Typography>
            {loader && (
              <Typography
                style={{
                  color: "#6c757d",
                  fontSize: 14,
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                }}
              >
                Please wait ... &nbsp;
                <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
              </Typography>
            )}
            {errorMessage && (
              <Typography
                style={{
                  marginTop: 8,
                  color: "red",
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                <ErrorOutline style={{ marginRight: 3 }} />
                {errorMessage}
              </Typography>
            )}
            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={() => {
                  setloader(false);
                  setisConfirm("");
                }}
                style={{
                  backgroundColor: "#C60C30",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  if (isConfirm === "change") {
                    handleChangePriorityStatus();
                  } else {
                    handleRemovePriorityContact();
                  }
                }}
                style={{
                  backgroundColor: "#1e2082",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                {loader2 ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <DomainAddOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}
                {loader2 ? "Loading....." : "CONFIRM"}
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
    </Grid>
  );
};

export default PriorityContactPlant;
