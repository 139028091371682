import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    /* backgroundColor: "#fff", */
    minHeight: "100vh",
  },
  content2: {
    flexGrow: 1,
    padding: theme.spacing(2),
    /* backgroundColor: "#fff", */
    minHeight: "100vh",
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },

  buttonDelete: {
    borderRadius: 5,
    marginTop: theme.spacing(3),
    backgroundColor: "#4b8edb",
    padding: 7,
    fontWeight: "bold",
    fontSize: 15,
    width: 140,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4b8edb",
      color: "#fff",
    },
  },

  tableContainer: {
    borderRadius: 10,
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
  name: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.info.main,
  },
  detailscard: {
    backgroundolor: " #fff",
    borderRadius: "10px",
    boxShadow:
      "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
    display: "flex",
    maxWidth: "100%",
    margin: "0.70em",
    overflow: "hidden",
    width: "100%",
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));