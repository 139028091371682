import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@material-ui/core";
import {
  fetchSocietyId,
} from "../../contextAPI/fetchSociety";
import { getAllSocieties } from "../../GlobalFunctions/GlobalFunctions";
import { makeStyles } from "@material-ui/core/styles";

function ChooseSocietyDropDown(props) {
  const { setSociety, society } = props;
  const [loading, setLoading] = useState(false);
  const [societies, setsocieties] = useState([]);

  useEffect(() => {
    handleGetSocieties();
  }, []);

  const handleGetSocieties = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    const res = await getAllSocieties();
    setsocieties(res);
    setLoading(false);
  };

  return (
    <Grid container item style={{ justifyContent: "center" }}>
      <Select
        value={society}
        onChange={(event) => {
          setSociety(event.target.value);
        }}
        style={{
          color: "#212529",
          backgroundColor: "#fff",
          fontWeight: "bold",
          borderRadius: 2,
          width: "250px",
          height: 50,
          fontSize: "13px",
          padding: 8,
          paddingLeft: 6,
          marginLeft: 8,
          border: "1px solid #6c757d",
          textAlign: "center",
          fontFamily: "Montserrat, sans-serif",
        }}
        disabled={loading}
      >
        <MenuItem
          style={{ fontFamily: "Montserrat, sans-serif" }}
          selected
          value="Choose Society"
        >
          {loading ? "Loading..." : `Choose Society (${societies?.length})`}
        </MenuItem>
        {societies?.map((soc, index) => {
          return (
            <MenuItem
              selected
              value={soc?._id}
              style={{ fontFamily: "Montserrat, sans-serif" }}
              disabled={soc?._id === fetchSocietyId()}
            >
              {index + 1}.&nbsp;
              <span style={{ fontWeight: "bold" }}>
                {soc?.name}{" "}
                {soc?._id === fetchSocietyId() && "(Already Logged in)"}
              </span>
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
}

export default ChooseSocietyDropDown;
