import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { Close } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import * as api from "../../../../api/index";
import { resolveErrorMessage } from "../../../../services/service";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { handlePropertyName } from "../../../../contextAPI/NamingConvention";

const PropertyModal = ({
  propertyModal,
  setPropertyModal,
  setPermissionType,
  setSelectedProperty,
  selectedProperty,
  setSelectAllProperty,
  selectAllProperty,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allProperties, setallProperties] = useState([]);
  const [count, setCount] = React.useState(0);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetProperties = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: "",
        pageLimit: 100,
        type: "",
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        setCount(res.data?.properties?.totalPages);
        setallProperties(res.data?.properties?.properties);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetProperties();
  }, [page]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllProperty(isChecked);
    if (isChecked) {
      const allSelected = allProperties.map((property) => ({
        name: property.name,
        _id: property._id,
      }));
      setSelectedProperty((prevSelected) => [
        ...prevSelected.filter(
          (selected) =>
            !allProperties.some((property) => property._id === selected._id)
        ),
        ...allSelected,
      ]);
    } else {
      setSelectedProperty((prevSelected) =>
        prevSelected.filter(
          (selected) =>
            !allProperties.some((property) => property._id === selected._id)
        )
      );
    }
  };

  const handleCheckboxChange = (property) => {
    const isChecked = selectedProperty.some(
      (selected) => selected._id === property._id
    );
    const newSelectedProperty = isChecked
      ? selectedProperty.filter((selected) => selected._id !== property._id)
      : [...selectedProperty, property];
    setSelectedProperty(newSelectedProperty);
    setSelectAllProperty(newSelectedProperty.length === allProperties.length);
  };

  const isTableHeaderChecked = allProperties.every((property) =>
    selectedProperty.some((selected) => selected._id === property._id)
  );

  return (
    <Modal open={propertyModal}>
      <Paper
        style={{
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: selectedProperty?.length > 0 ? "800px" : "300px",
          maxWidth: selectedProperty?.length > 0 ? "75vw" : "35vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select {handlePropertyName()}
          </Typography>
          <IconButton
            onClick={() => {
              setPropertyModal(false);
              setPermissionType("Choose Type");
              setSelectedProperty([]);
              setSelectAllProperty(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid
          container
          style={{
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Grid item xs={selectedProperty?.length > 0 ? 8 : 12}>
            <TableContainer
              component={Paper}
              className="scrollable"
              style={{ overflowY: "scroll", height: "60vh" }}
            >
              {!loading && (
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="text-nowrap"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          className="form-check-input "
                          onChange={handleSelectAll}
                          checked={isTableHeaderChecked}
                        />
                        &nbsp; Sr. No.
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allProperties?.map((pr, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              className="form-check-input"
                              checked={selectedProperty.some(
                                (selected) => selected._id === pr._id
                              )}
                              onChange={() => handleCheckboxChange(pr)}
                            />
                            &nbsp;&nbsp;
                            {(page - 1) * 100 + (index + 1)}.
                          </TableCell>
                          <TableCell
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: "normal",
                            }}
                          >
                            {pr?.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
              )}
            </TableContainer>
          </Grid>
          {selectedProperty?.length > 0 && (
            <Grid item xs={4}>
              <div
                style={{
                  color: "#6c757d",
                }}
                className="text-poppins text-center fw-bold py-2 bg-white shadow-sm"
              >
                Selected {handlePropertyName()} : {selectedProperty?.length}
              </div>
              <div
                className="d-flex flex-column gap-3 overflow-auto scrollable p-3"
                style={{ height: "calc(60vh - 40px)" }}
              >
                {selectedProperty?.map((pr, index) => {
                  return (
                    <p
                      className="mb-0 text-dark"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {index + 1}. {pr?.name}
                    </p>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>

        {allProperties?.length > 0 && (
          <Grid container style={{ justifyContent: "center", marginTop: 8 }}>
            <Pagination
              className={classes.pagination}
              count={count}
              color="primary"
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor:
                selectedProperty?.length > 0 ? "#2f45c5" : "#7D858D",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              if (selectedProperty?.length > 0) {
                setPropertyModal(false);
              } else {
                toast.error(`Please Select ${handlePropertyName()}`);
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Save
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default PropertyModal;
