import React, { useState, useContext } from "react";
import * as api from "../../../../api/index";
import useStyles from "./styles";
import { Typography, Grid, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Apartment, KeyboardBackspaceRounded } from "@material-ui/icons";
import FlatTable from "./FlatTable";
import AddFlat from "./AddFlat";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import { AuthContext } from "../../../../contextAPI/context";
import { resolveErrorMessage } from "../../../../services/service";
import {
  handlePropertyName,
  handleFloorName,
  handleFlatName,
} from "../../../../contextAPI/NamingConvention";
import {
  navigateToProperty,
  navigateToSocietyManagement,
} from "../../../../Navigation/Navigations";
import { getAllPropertyFlatType } from "../../../../GlobalFunctions/GlobalFunctions";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import EditFlat from "./EditFlat";

const Flats = () => {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions, user } = authState;
  const { propertyId, propertyName, societyName } = useParams();
  const [floors, setfloors] = useState([]);
  const [selectedFloor, setselectedFloor] = useState("All Floors");
  const [filteredFloors, setfilteredFloors] = useState([]);
  const [addFlatModal, setaddFlatModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [allFlatTypes, setallFlatTypes] = useState([]);
  const [isFlatEdit, setisFlatEdit] = useState(false);
  const [editedFlatDetails, seteditedFlatDetails] = useState([]);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Manage"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    handleGetFloors();
  }, [selectedFloor]);

  const handleGetFloors = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const res = await api.getAllFloors({
        propertyId,
        floorId: selectedFloor === "All Floors" ? "" : selectedFloor,
      });
      if (res?.data?.success) {
        setfloors(res?.data?.floors?.allFloors);
        setfilteredFloors(res?.data?.floors?.filteredFloors);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleGetFlatTypes = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    let sendData = {
      type: "flat",
      societyId: fetchSocietyId(),
    };
    const res = await getAllPropertyFlatType(sendData);

    setallFlatTypes(res);
    setloader2(false);
  };

  useEffect(() => {
    handleGetFlatTypes();
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader ? "blur(1px)" : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {`Society Management > `}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(navigateToSocietyManagement({}));
                      }}
                    >
                      Society
                    </span>
                    <span
                      onClick={() => {
                        navigate(navigateToProperty({ societyName }));
                      }}
                      style={{ cursor: "pointer" }}
                    >{` > ${handlePropertyName()}`}</span>
                    <span style={{ color: "#000" }}>
                      {/* {` > Floors & Flats`} */}
                      {` > ${handleFloorName()} & ${handleFlatName()}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginRight: 15,
                      }}
                    >
                      Change {handleFloorName()} :
                      <Select
                        value={selectedFloor}
                        onChange={(event) => {
                          setselectedFloor(event.target.value);
                        }}
                        style={{
                          color: "#212529",
                          backgroundColor: "#fff",
                          fontWeight: "bold",
                          borderRadius: 6,
                          width: "150px",
                          height: 34,
                          fontSize: "13px",
                          padding: 8,
                          paddingLeft: 6,
                          marginLeft: 8,
                          border: "1px solid #6c757d",
                          textAlign: "center",
                        }}
                      >
                        <MenuItem
                          selected
                          value="All Floors"
                          style={{
                            color: "#6c757d",
                            fontFamily: "Montserrat,sans-serif",
                          }}
                        >
                          All {handleFloorName()}
                        </MenuItem>
                        {floors?.map((flr, index) => {
                          return (
                            <MenuItem
                              selected
                              value={flr._id}
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  marginTop: 3,
                                  color: "#6c757d",
                                }}
                              >
                                {index + 1}.
                              </span>
                              &nbsp;{flr.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Typography>
                    {isReadWrite && (
                      <div
                        class="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "145px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginRight: 15,
                        }}
                      >
                        <div class="card-body ">
                          <p
                            class="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-12px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              setaddFlatModal(true);
                            }}
                          >
                            <Apartment
                              style={{
                                marginRight: 10,
                                fontSize: "15px",
                              }}
                            />
                            Add {handleFlatName()}
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "90px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                      onClick={() => {
                        navigate(navigateToProperty({ societyName }));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ justifyContent: "flex-end", marginTop: 18 }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Society Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {societyName}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 12,
                    marginRight: 18,
                  }}
                >
                  {/* Property Name :&nbsp; */}
                  {handlePropertyName()} Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {propertyName}
                  </span>
                </Typography>
              </Grid>

              <Grid container>
                <FlatTable
                  floors={
                    filteredFloors?.length === 0 ? floors : filteredFloors
                  }
                  propertyId={propertyId}
                  propertyName={propertyName}
                  societyName={societyName}
                  setisFlatEdit={setisFlatEdit}
                  user={user}
                  seteditedFlatDetails={seteditedFlatDetails}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading {handleFlatName()}...
          </Typography>
        </Grid>
      )}
      <AddFlat
        floors={floors}
        addFlatModal={addFlatModal}
        setaddFlatModal={setaddFlatModal}
        handleGetFloors={handleGetFloors}
        allFlatTypes={allFlatTypes}
        loader2={loader2}
      />
      {isFlatEdit && (
        <EditFlat
          isFlatEdit={isFlatEdit}
          setisFlatEdit={setisFlatEdit}
          user={user}
          editedFlatDetails={editedFlatDetails}
          allFlatTypes={allFlatTypes}
          handleGetFloors={handleGetFloors}
        />
      )}
    </div>
  );
};

export default Flats;
