import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import SocietyType from "../../Components/Settings/SocietyType/SocietyType";

const SocietyTypeRoutes = {
  path: "/settings",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <SocietyType />,
    },
    {
      path: "societytype",
      element: <SocietyType />,
    },
  ],
};

export default SocietyTypeRoutes;
