import React, { useState, memo, useContext, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import SociohoodLogo from "../../assets/SociohoodLogo.png";
import clsx from "clsx";
import { Divider, Grid, Paper } from "@material-ui/core";
import { ArrowRight } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import { AccountCircle, ArrowDropDown } from "@material-ui/icons";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useStyles from "./style";
import { Outlet } from "react-router-dom";
import { LockOutlined } from "@material-ui/icons";
import { AuthContext } from "../../contextAPI/context";
import Dashboard from "../Dashboard/dashboard";
import allMainModules from "../../Navigation/AllModules";
import Navbar from "../Navbar/Navbar";
import { isMemberTypeS2Admin } from "../../GlobalFunctions/GlobalFunctions";

function MiniDrawer() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, plant } = authState;
  const theme = useTheme();
  const [isIcon, setisIcon] = useState(true);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [reedirectPageName, setreedirectPageName] = useState("Dashboard");
  const location = useLocation();
  const pathName = location.pathname;
  const [loader, setloader] = useState(false);
  const [openModule, setopenModule] = useState("");
  const [recentPageOpenedPath, setrecentPageOpenedPath] = useState("");
  const [recentPageOpenedPathSubModule, setrecentPageOpenedPathSubModule] =
    useState("");

  const coummunicationSubModule = localStorage.getItem("subModuleName");

  useEffect(() => {
    if (coummunicationSubModule !== "Notice") {
      localStorage.removeItem("noticePageNumber");
    }
    if (coummunicationSubModule !== "Discussion") {
      localStorage.removeItem("discussionPageNumber");
    }
    if (coummunicationSubModule !== "Meeting") {
      localStorage.removeItem("meetingPageNumber");
    }
    if (coummunicationSubModule !== "Poll") {
      localStorage.removeItem("pollPageNumber");
    }
  }, [coummunicationSubModule]);

  const homeModules = [
    {
      moduleName: "dashboard",
      route: "/",
      icon: (
        <DashboardIcon
          style={{
            color: "#03045e",
            fontSize: 23,
            marginLeft: 4,
          }}
        />
      ),
    },
    {
      moduleName: "profile",
      route: "/profile",
      icon: (
        <AccountCircle
          style={{
            color: "#03045e",
            fontSize: 23,
            marginLeft: 4,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setrecentPageOpenedPath(pathName?.split("/")?.[1]);
    setrecentPageOpenedPathSubModule(pathName?.split("/")?.[2]);
  }, [pathName]);

  const handleRedirectPage = (key, route) => {
    navigate("/" + key + route);
  };
  const indexAsAlphabet = (index) => {
    const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];
    return alphabet[index];
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    setisIcon(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setisIcon(true);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            {isIcon && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            )}

            <img
              src={SociohoodLogo}
              // width="100px"
              height="40px"
              alt="Sociohood"
              color="inherit"
              aria-label="open drawer"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            />
            <Navbar pathName={pathName} />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, "scrollable border-end", {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx("scrollable border-end", {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Grid container justifyContent={"center"}>
              <Paper elevation={0} sx={{ maxWidth: 256 }}>
                <ListItemButton
                  component="a"
                  href="#customized-list"
                  style={{ justifyContent: "center" }}
                >
                  <Grid container xs={12} style={{ justifyContent: "center" }}>
                    <img
                      src={SociohoodLogo}
                      width="100px"
                      height="45px"
                      className={classes.imageProfile}
                      alt="Sociohood"
                    />
                  </Grid>
                </ListItemButton>
                <Divider />
              </Paper>
            </Grid>
            <Divider />

            <IconButton
              style={{ color: "#283f59", marginRight: "8px" }}
              onClick={handleDrawerClose}
            >
              {theme.direction == "rtl" ? (
                <MenuIcon />
              ) : (
                <MenuIcon style={{ fontSize: 26 }} />
              )}
            </IconButton>
          </div>
          {/* ****************** HOME MENU ******************* */}
          {open && (
            <Typography
              style={{
                textAlign: "center",
                marginBottom: -5,
                marginLeft: 10,
                fontFamily: "Montserrat, sans-serif",
                fontSize: 13,
                fontWeight: "bold",
                color: "#03045e",
                textAlign: "start",
                marginTop: 12,
              }}
            >
              HOME
            </Typography>
          )}
          <List>
            {homeModules?.map((module, index) => {
              return (
                <Tooltip title={module?.moduleName} placement="right" arrow>
                  <ListItem
                    className={classes.appHover}
                    key={module?.moduleName}
                    style={{
                      backgroundColor:
                        pathName === module?.route ||
                        pathName === "/" + module?.moduleName
                          ? "rgba(46, 48, 148, 0.1)"
                          : "#fff",
                      borderRadius:
                        pathName === module?.route ||
                        pathName === "/" + module?.moduleName
                          ? "10px"
                          : "2px",
                    }}
                    onClick={() => {
                      navigate(`/${module?.moduleName}`);
                      localStorage.setItem("subModuleName", "");
                    }}
                  >
                    <ListItemIcon>{module?.icon}</ListItemIcon>

                    <ListItemText
                      primary={module?.moduleName}
                      classes={{ primary: classes.text }}
                      style={{
                        marginLeft: -12,
                        fontFamily: "Montserrat, sans-serif",
                        textTransform: "capitalize",
                      }}
                      primaryTypographyProps={{
                        color: "#ffffff",
                        fontWeight: "medium",
                        variant: "body2",
                      }}
                    />
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>

          <Divider
            style={{
              marginBottom: 10,
            }}
          />
          {open && (
            <Typography
              style={{
                textAlign: "center",
                marginBottom: -5,
                marginLeft: 10,
                fontFamily: "Montserrat, sans-serif",
                fontSize: 13,
                fontWeight: "bold",
                color: "#03045e",
                textAlign: "start",
              }}
            >
              FEATURES
            </Typography>
          )}
          <List>
            {/* ****************** MAIN MENU ******************* */}
            {allMainModules
              ?.filter((m) => m.isHide === false)
              .map((module, index) => {
                let isShow =
                  module?.key === "s2admin"
                    ? isMemberTypeS2Admin(user?.role)
                      ? true
                      : false
                    : true;
                return (
                  isShow && (
                    <>
                      {/* ****************** MENU ******************* */}
                      <Tooltip
                        title={module.moduleName}
                        placement="right"
                        arrow
                      >
                        <ListItem
                          className={classes.appHover}
                          key={module.moduleName}
                          style={{
                            backgroundColor:
                              recentPageOpenedPath === module?.key
                                ? "rgba(46, 48, 148, 0.1)"
                                : "#fff",
                            borderRadius:
                              recentPageOpenedPath === module?.key
                                ? "10px"
                                : "2px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                          onClick={() => {
                            setopenModule(
                              openModule === module.moduleName
                                ? ""
                                : module.moduleName
                            );
                          }}
                        >
                          <ListItemIcon>
                            <Typography
                              style={{
                                color: "#03045e",
                                fontSize: 14,
                                marginTop: 1,
                                marginLeft: -8,
                                marginRight: 3,
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <span
                              style={{
                                color: "#03045e",
                              }}
                            >
                              {module.icon}
                            </span>
                          </ListItemIcon>

                          <ListItemText
                            primary={`${module.moduleName}`}
                            classes={{ primary: classes.text }}
                            style={{
                              marginLeft: -12,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                            primaryTypographyProps={{
                              color:
                                recentPageOpenedPath === module?.key
                                  ? "#fff"
                                  : "#03045e",
                              fontWeight: "medium",
                              variant: "body2",
                            }}
                          />

                          {module?.subModules?.length > 0 && (
                            <>
                              {module.moduleName === openModule ? (
                                <ArrowDropDown
                                  onClick={() => {
                                    setopenModule("");
                                  }}
                                  style={{
                                    color: "#03045e",
                                    fontSize: 26,
                                  }}
                                />
                              ) : (
                                <ArrowRight
                                  onClick={() => {
                                    setopenModule(module.moduleName);
                                  }}
                                  style={{
                                    color: "#03045e",
                                    fontSize: 26,
                                  }}
                                />
                              )}
                            </>
                          )}
                        </ListItem>
                      </Tooltip>

                      {/* ****************** SUB MENU ******************* */}
                      <Collapse
                        in={module.moduleName === openModule}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {module.subModules
                            ?.filter((m) => {
                              const shouldShow = m.isPlant ? plant : true;
                              return shouldShow && m.isHide === false;
                            })
                            .map((subModule, index) => {
                              return (
                                <Tooltip
                                  title={`${module.moduleName} > ${
                                    subModule.aliasName
                                      ? subModule?.aliasName
                                      : subModule.moduleName
                                  }`}
                                  placement="right"
                                  arrow
                                >
                                  <ListItem
                                    sx={{ pl: 4 }}
                                    className={`hover-0 ${classes.subHover}`}
                                    style={{
                                      borderRadius: "8px",
                                      padding: "5px",
                                      width: open ? "80%" : "",
                                      marginBottom: 3,
                                      marginLeft: open ? "30px" : "10px",
                                      backgroundColor:
                                        localStorage.getItem(
                                          "subModuleName"
                                        ) === subModule?.moduleName
                                          ? "rgba(46, 48, 148, 0.1)"
                                          : "#fff",
                                      border: `2px solid ${
                                        localStorage.getItem(
                                          "subModuleName"
                                        ) === subModule?.moduleName
                                          ? "#03045e"
                                          : "#fff"
                                      }`,
                                    }}
                                    onClick={() => {
                                      handleRedirectPage(
                                        module.key,
                                        subModule.route,
                                        subModule.moduleName
                                      );
                                      localStorage.setItem(
                                        "subModuleName",
                                        subModule?.moduleName
                                      );
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{
                                        color: "#03045e",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: "#03045e",
                                          fontSize: 14,
                                          marginRight: 3,
                                        }}
                                      >
                                        {indexAsAlphabet(index)}.
                                      </Typography>
                                      {subModule.icon}
                                    </ListItemIcon>

                                    <ListItemText
                                      primary={
                                        subModule.aliasName
                                          ? subModule?.aliasName
                                          : subModule.moduleName
                                      }
                                      style={{
                                        color: "#03045e",
                                        marginLeft: -8.5,
                                      }}
                                      primaryTypographyProps={{
                                        color: "#03045e",
                                        fontWeight: "medium",
                                        variant: "body2",
                                      }}
                                    />

                                    {!authState?.permissionmodules?.includes(
                                      subModule.moduleName
                                    ) && (
                                      <LockOutlined
                                        style={{
                                          color: "#f27666",
                                          fontSize: "16px",
                                        }}
                                      />
                                    )}
                                  </ListItem>
                                </Tooltip>
                              );
                            })}
                        </List>
                      </Collapse>
                    </>
                  )
                );
              })}
          </List>
        </Drawer>
        <main className={classes.content}>
          {pathName === "/" ? <Dashboard /> : <Outlet />}
        </main>
      </div>
    </>
  );
}
export default memo(MiniDrawer);
