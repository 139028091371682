import { CheckCircle } from "@material-ui/icons";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";

const PollCards = (props) => {
  const {
    filterPollType,
    setfilterPollType,
    count,
    setPage,
    filterPollStatus,
    setfilterPollStatus,
  } = props;

  const icons = {
    all: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3332_7317)">
            <path
              d="M30.0833 4.5H7.91667C6.175 4.5 4.75 5.925 4.75 7.66667V29.8333C4.75 31.575 6.175 33 7.91667 33H30.0833C31.825 33 33.25 31.575 33.25 29.8333V7.66667C33.25 5.925 31.825 4.5 30.0833 4.5ZM30.0833 29.8333H7.91667V7.66667H30.0833V29.8333ZM11.0833 15.5833H14.25V26.6667H11.0833V15.5833ZM17.4167 10.8333C17.4167 10.8333 17.4167 10 19 10C20.2408 10 20.5833 10.8333 20.5833 10.8333V18.75V26.6667H17.4167V10.8333ZM23.75 20.3333H26.9167V26.6667H23.75V20.3333Z"
              fill="#595BD4"
            />
          </g>
          <defs>
            <clipPath id="clip0_3332_7317">
              <rect width="38" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      count: count?.all,
      title: "All Polls",
      type: "all",
    },
    society: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.75 33.25H33.25M7.91667 33.25V11.0833L20.5833 4.75V33.25M30.0833 33.25V17.4167L20.5833 11.0833M14.25 14.25V14.2658M14.25 19V19.0158M14.25 23.75V23.7658M14.25 28.5V28.5158"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.society,
      title: "Society",
      type: "society",
    },
    property: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 44 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.4477 22.4793H5.81738L22.1538 6.08203L38.4903 22.4793H34.8599"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.4477 22.4793V35.2327C9.4477 36.1991 9.83018 37.1259 10.511 37.8093C11.1918 38.4926 12.1152 38.8766 13.078 38.8766H31.2296C32.1924 38.8766 33.1158 38.4926 33.7966 37.8093C34.4775 37.1259 34.8599 36.1991 34.8599 35.2327V22.4793"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5235 22.4793H25.7841V29.767H18.5235V22.4793Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.property,
      title: `${handlePropertyName()}`,
      type: "property",
    },
    ongoing: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 13.3333V20M20 26.6667H20.0167M5 20C5 21.9698 5.38799 23.9204 6.14181 25.7403C6.89563 27.5601 8.00052 29.2137 9.3934 30.6066C10.7863 31.9995 12.4399 33.1044 14.2597 33.8582C16.0796 34.612 18.0302 35 20 35C21.9698 35 23.9204 34.612 25.7403 33.8582C27.5601 33.1044 29.2137 31.9995 30.6066 30.6066C31.9995 29.2137 33.1044 27.5601 33.8582 25.7403C34.612 23.9204 35 21.9698 35 20C35 16.0218 33.4196 12.2064 30.6066 9.3934C27.7936 6.58035 23.9782 5 20 5C16.0218 5 12.2064 6.58035 9.3934 9.3934C6.58035 12.2064 5 16.0218 5 20Z"
            stroke="#0204AA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.ongoingPolls,
      title: "Ongoing",
      type: "ongoing",
    },

    completed: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1655 29.4335C12.9325 29.1539 11.7482 28.6911 10.6522 28.0608M19.8328 4.56543C22.6494 5.2087 25.1641 6.78916 26.9653 9.04805C28.7664 11.307 29.7473 14.1104 29.7473 16.9995C29.7473 19.8886 28.7664 22.6921 26.9653 24.951C25.1641 27.2099 22.6494 28.7903 19.8328 29.4336M6.48581 24.215C5.71302 23.0923 5.12609 21.8524 4.74756 20.543M4.42383 14.8743C4.65049 13.5285 5.08683 12.2535 5.69883 11.0848L5.93824 10.6527M9.78337 6.48643C11.1089 5.57395 12.5959 4.92203 14.1651 4.56543M12.7495 17L15.5828 19.8333L21.2495 14.1667"
            stroke="#14A72D"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.completed,
      title: "Completed",
      type: "completed",
    },
    deleted: {
      icon: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33301 9.33333H26.6663M13.333 14.6667V22.6667M18.6663 14.6667V22.6667M6.66634 9.33333L7.99967 25.3333C7.99967 26.0406 8.28063 26.7189 8.78072 27.219C9.28082 27.719 9.9591 28 10.6663 28H21.333C22.0403 28 22.7185 27.719 23.2186 27.219C23.7187 26.7189 23.9997 26.0406 23.9997 25.3333L25.333 9.33333M11.9997 9.33333V5.33333C11.9997 4.97971 12.1402 4.64057 12.3902 4.39052C12.6402 4.14048 12.9794 4 13.333 4H18.6663C19.02 4 19.3591 4.14048 19.6092 4.39052C19.8592 4.64057 19.9997 4.97971 19.9997 5.33333V9.33333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      count: count?.deleted,
      title: "Deleted",
      type: "deleted",
    },
  };

  const PollsCard = ({ icon, title, count, active, type }) => {
    return (
      <div
        className={`card rounded-10p pointer ${active ? "border-success" : ""}`}
        onClick={() => {
          if (type === "all" || type === "society" || type === "property") {
            setfilterPollStatus("");
            setfilterPollType(type);
            setPage(1);
          } else {
            setfilterPollType("");
            setfilterPollStatus(type);
            setPage(1);
          }
        }}
        style={{
          boxShadow: `${
            active
              ? "rgba(36, 202, 38, 0.4) 0px 1px 2px 0px, rgba(36, 202, 38, 0.15) 0px 2px 6px 2px"
              : "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }`,
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center gap-2">
            {typeof icon === "string" ? (
              <img
                src={icon}
                alt="Icon"
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              icon
            )}
            <div>
              <p
                className={`text-nowrap mb-0 text-poppins h6 ${
                  active ? "text-dark" : "text-secondary"
                }`}
              >
                {title}
              </p>
              <p className={`mb-0 text-poppins ${active ? "text-dark" : "text-secondary"}`}>
                {count}
              </p>
            </div>
            {(filterPollType === type || filterPollStatus === type) && (
              <CheckCircle
                style={{
                  color: "rgb(36 202 38)",
                  fontSize: "30px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {Object?.keys(icons)?.map((key) => (
        <div key={key}>
          <PollsCard
            icon={icons[key]?.icon}
            title={icons[key]?.title}
            count={icons[key]?.count}
            active={
              filterPollType === icons[key]?.type ||
              filterPollStatus === icons[key]?.type
            }
            type={icons[key]?.type}
          />
        </div>
      ))}
    </>
  );
};

export default PollCards;
