import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../styles";
import { Paper } from "@material-ui/core";

function TypeTable(props) {
  const classes = useStyles();
  const { types } = props;
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {types?.map((row, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {row.name}
                  </TableCell>
               
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default TypeTable;
