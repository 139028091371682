import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { getComplaintSubCategories } from "../../../GlobalFunctions/GlobalFunctions";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));
function MultipleSubCategory(props) {
  const {
    setsearchKey,
    searchKey,
    setSubCategory,
    subCategory,
    isMultiple,
    category,
    isDisabled,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [subCategories, setsubCategories] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageForSubCategory, setpageForSubCategory] = useState(1);

  useEffect(() => {
    if (category?.length > 0) {
      handleGetSubCategories();
    }
  }, [pageForSubCategory, searchKey, category]);

  const handleGetSubCategories = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      categoryId: category?.map((cat) => cat._id),
      pageNumber: pageForSubCategory,
      searchKey,
    };
    const res = await getComplaintSubCategories(sendData);
    if (pageForSubCategory === 1) {
      setsubCategories(res?.allsubcategories);
      settotalCount(res?.totalPages);
    } else {
      setsubCategories((prev) => [...prev, ...res?.allsubcategories]);
      settotalCount(res?.totalPages);
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== subCategories?.length
    ) {
      setpageForSubCategory((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        disabled={!category?.some((item) => item?._id) || isDisabled}
        onChange={(e, value) => {
          setSubCategory(value);
        }}
        id="multiple-category-search"
        style={{
          width: "98%",
        }}
        freeSolo
        classes={classes}
        options={subCategories}
        autoHighlight
        value={subCategory}
        getOptionLabel={(option) =>
          `${option?._id ? option?.subcategoryName : ""}`
        }
        getOptionSelected={(option, value) => option._id === value._id}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option._id}>
                {subCategory?.some((vendor) => vendor?._id === option?._id) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option.subcategoryName}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpageForSubCategory(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={
              loading ? "Loading..." : `Select SubCategory (${totalCount})`
            }
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default MultipleSubCategory;
