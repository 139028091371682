import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Modal,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close, ErrorOutline } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import ChooseCategoryDropDown from "../DropDowns/ChooseCategoryDropDown";
import ChooseSubCategoryDropDown from "../DropDowns/ChooseSubCategoryDropDown";

function CategoryFilterModal(props) {
  const {
    isOpenCategoryFilterModal,
    setisOpenCategoryFilterModal,
    category,
    setcategory,
    subcategory,
    setsubcategory,
    handleCategorySearch,
    isLocal,
  } = props;
  const [searchKey, setsearchKey] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  const handleReset = () => {
    setisOpenCategoryFilterModal(false);
    seterrorMsg("");
    setcategory("Select Category");
    setsubcategory("Select Subcategory");
  };

  const checkInvalid = () => {
    if (
      (category || category === null) &&
      subcategory === "Select Subcategory"
    ) {
      return category?._id ? false : true;
    }
    if (subcategory || subcategory === null) {
      return subcategory?._id ? false : true;
    }
  };

  const handleFilteredWithLocalSave = () => {
    if (checkInvalid()) {
      seterrorMsg("Please select valid details");
    } else {
      localStorage.setItem(
        "categoryForFilter",
        category?._id ? category?._id : category
      );
      localStorage.setItem(
        "subcategoryForFilter",
        subcategory?._id ? subcategory?._id : subcategory
      );
      localStorage.setItem(
        "categoryDetails",
        category?.categoryName ? category?.categoryName : category
      );
      localStorage.setItem(
        "subcategoryDetails",
        subcategory?.subcategoryName
          ? subcategory?.subcategoryName
          : subcategory
      );
      handleCategorySearch();
      setisOpenCategoryFilterModal(false);
    }
  };

  useEffect(() => {
    seterrorMsg("");
    setsubcategory("Select Subcategory");
  }, [category]);


  return (
    <Modal open={isOpenCategoryFilterModal}>
      <Paper
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          minWidth: "450px",
          maxWidth: "450px",
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Search By Category
          </Typography>

          <IconButton onClick={handleReset}>
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid
          container
          style={{
            justifyContent: "flex-start",
            marginBottom: 6,
            marginTop: 6,
          }}
        >
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              Category :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <ChooseCategoryDropDown
              setCategory={setcategory}
              category={category}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
            />
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: "flex-start", marginTop: 10 }}>
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              SubCategory :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <ChooseSubCategoryDropDown
              setSubCategory={setsubcategory}
              subCategory={subcategory}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              category={category}
            />
          </Grid>
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #7D858D",
              width: "120px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={() => {
              if (isLocal) {
                handleFilteredWithLocalSave();
              } else {
                if (checkInvalid()) {
                  seterrorMsg("Please select valid details");
                } else {
                  handleCategorySearch();
                  setisOpenCategoryFilterModal(false);
                }
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-14px",
                  textAlign: "center",
                }}
              >
                <SearchIcon
                  style={{
                    marginRight: 10,
                    fontSize: "25px",
                  }}
                />
                Search
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default CategoryFilterModal;
