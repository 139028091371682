import { Avatar, Grid, Paper } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { BASE_URL } from "../../../utils/base";
import { useNavigate } from "react-router";
import { navigateToGroupDetails } from "../../../Navigation/Navigations";

function ApprovedManagerTable({
  setaddManager,
  setmanagerData,
  setremoveManager,
  allManager,
  page,
  isReadWrite,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow container>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Sr No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Time
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Email
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Phone
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Group
            </TableCell>
            {isReadWrite && (
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {allManager?.map((manager, index) => {
            return (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <Grid container style={{ justifyContent: "center" }}>
                    <Avatar
                      style={{ cursor: "pointer", justifyContent: "center" }}
                      alt={manager.name}
                      src={
                        manager.image
                          ? `${BASE_URL}/user/${manager.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      className={classes.avatar}
                    />
                  </Grid>

                  {manager?.name}
                  <span
                    style={{
                      width: "58px",
                      padding: 5,
                      textTransform: "capitalize",
                      marginLeft: 5,
                    }}
                    class={"badge bg-primary"}
                  >
                    Manager
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {moment(manager?.createdAt).format("DD/MM/YYYY - h:mm A")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {manager?.email ? manager?.email : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {manager?.phone}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#0204aa",
                    fontFamily: "Poppins, sans-serif",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const addressGroupId =
                      manager?.managerSocieties?.[0]?.addressGroupId?._id;
                    if (addressGroupId) {
                      navigate(
                        navigateToGroupDetails({
                          grpId: addressGroupId,
                        })
                      );
                    }
                  }}
                >
                  {manager?.managerSocieties?.[0]?.addressGroupId
                    ? manager?.managerSocieties?.[0]?.addressGroupId?.name
                    : "-"}
                </TableCell>
                {isReadWrite && (
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer",
                    }}
                    className={`text-danger fw-bold`}
                    onClick={() => {
                      setmanagerData(manager);
                      setremoveManager(true);
                    }}
                  >
                    Remove Manager
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ApprovedManagerTable;
