import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import useStyles from "../../ComplaintCategory/styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import ExploreIcon from "@mui/icons-material/Explore";
import { startApiCall } from "../../../../GlobalFunctions/GlobalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { AreaRoutes } from "../../../../api/Routes";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";

const AddSubArea = ({
  addSubArea,
  setAddSubArea,
  id,
  editSubarea,
  isEditSubarea,
  setisEditSubarea,
  handleGetAllSubArea,
}) => {
  const classes = useStyles();
  const [subAreaName, setSubAreaName] = useState("");
  const [zone, setZone] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    if (isEditSubarea) {
      setSubAreaName(editSubarea?.name);
      setZone(editSubarea?.zone);
    }
  }, [isEditSubarea]);

  const handleReset = () => {
    setAddSubArea(false);
    setloader(false);
    seterrorMsg("");
    setSubAreaName("");
    setZone("");
  };

  const handleAddSubArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      name: subAreaName,
      societyId: fetchSocietyId(),
      area: id,
      zone,
    };
    const res = await ApiCall("post", AreaRoutes.addSubArea, data);
    if (res?.success) {
      toast.success("Subarea Added Successfully!");
      handleReset();
      handleGetAllSubArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  const handleEditSubArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      name: subAreaName,
      subAreaId: editSubarea?._id,
      zone,
    };
    const res = await ApiCall("post", AreaRoutes.editSubArea, data);
    if (res?.success) {
      toast.success("Subarea Added Successfully!");
      setisEditSubarea(false);
      handleReset();
      handleGetAllSubArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  return (
    <Modal open={addSubArea || isEditSubarea}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          minWidth: "300px",
          maxWidth: "29vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {isEditSubarea ? "Edit" : "Add"} Subarea
          </Typography>
          <IconButton
            onClick={() => {
              if (isEditSubarea) {
                setisEditSubarea(false);
                seterrorMsg("");
                setloader(false);
              } else {
                handleReset();
              }
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container item xs={3}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                textAlign: "center",
                marginTop: 12,
                marginLeft: 8,
              }}
            >
              Name :
            </Typography>
          </Grid>
          <Grid container item xs={9}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 4,
                outline: 0,
              }}
              placeholder="Enter Area"
              value={subAreaName}
              onChange={(event) => setSubAreaName(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Grid container item xs={3}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                textAlign: "center",
                marginTop: 12,
                marginLeft: 8,
              }}
            >
              Zone :
            </Typography>
          </Grid>
          <Grid container item xs={9}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 4,
                outline: 0,
              }}
              placeholder="Enter Zone"
              value={zone}
              onChange={(event) => setZone(event.target.value)}
            />
          </Grid>
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "150px",
              backgroundColor: loader
              ? "#7D858D"
              : "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            disabled={loader}
            onClick={() => {
              if (isEditSubarea && !loader) {
                handleEditSubArea();
              } else if(!loader){
                handleAddSubArea();
              } else {
                //Nothing happened
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {isEditSubarea ? (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ExploreIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Edit Subarea" : `Editing...`}
                  </>
                ) : (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ExploreIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Add Subarea" : `Adding...`}
                  </>
                )}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AddSubArea;
