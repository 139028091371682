import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import IndividualNotice from "../../Components/Notices/IndividualNotice";
import Notices from "../../Components/Notices/Notices";

const NoticeRoutes = {
    path: "/communication",
    element: <NavigationDrawer />,
    children: [
        {
            path: "",
            element: <Notices />
        },
        {
            path: "allnotices",
            element: <Notices />
        },
        {
            path: "noticeDetails/:id",
            element: <IndividualNotice />
        },
    ]
}
export default NoticeRoutes;
