import React, { useState } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowRightAlt, SentimentDissatisfied } from "@material-ui/icons";
import useStyle from "../style";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import { navigateToReadWriteAccess } from "../../../Navigation/Navigations";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";

function AdminSocietyTable(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const { adminDetails, user } = props;
  const { adminId } = useParams();
  const [permissionModuleName, setpermissionModuleName] = useState([]);

  const handleCloseDialog = () => {
    setopen(false);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Scociety Name
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Permissions
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                <>
                  {adminDetails?.approvalStatus === "approved"
                    ? "Edit Permissions"
                    : "Give Permissions"}
                </>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminDetails?.societies?.map((soc, index) => {
              console.log("soc", soc);
              return (
                <TableRow
                // key={row.name}
                >
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {soc?.society?.name}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {soc?.society?.permissions?.length
                      ? soc?.society?.permissions?.length
                      : "No"}
                    &nbsp; Permissions &nbsp;
                    {soc?.society?.permissions?.length ? (
                      <InfoIcon
                        style={{
                          fontSize: "24px",
                          color: "#2f45c5",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setpermissionModuleName(soc?.society?.permissions);
                          setopen(true);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {adminId === user?._id && (
                      <>
                        <SentimentDissatisfied />
                        <br></br> Cannot give permissions by yourself
                      </>
                    )}

                    {adminId !== user?._id &&
                      soc?.society?._id === fetchSocietyId() && (
                        <ArrowRightAlt
                          onClick={() => {
                            navigate(
                              navigateToReadWriteAccess({
                                memberId: adminId,
                              }),
                              { state: "approved" }
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: 26,
                          }}
                        />
                      )}
                    {adminId !== user?._id &&
                      soc?.society?._id !== fetchSocietyId() && (
                        <>Cannot give permissions into another society</>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Grid container justifyContent={"space-between"}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 13,
                fontWeight: "bolder",
                marginBottom: 10,
              }}
            >
              Module Permission &nbsp;&nbsp;&nbsp;{" "}
              <Badge
                badgeContent={permissionModuleName?.length}
                color="warning"
              ></Badge>{" "}
              &nbsp;&nbsp;
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            xs={12}
            style={{ justifyContent: "space-between", width: "500px" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                }}
              >
                SL No.
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {" "}
                Module Name
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  textAlign: "start",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Permission Type
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ width: "500px" }}>
            <List>
              <>
                {permissionModuleName?.map((per, index) => {
                  return (
                    <ListItem>
                      <Grid
                        container
                        xs={12}
                        style={{
                          justifyContent: "space-between",
                          width: "500px",
                        }}
                      >
                        <Grid item xs={3}>
                          <Typography
                            style={{ fontSize: 15, textAlign: "start" }}
                          >
                            {index + 1}.
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            style={{
                              fontSize: 15,
                              textAlign: "start",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {per?.moduleName}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            style={{
                              fontSize: 15,
                              textAlign: "start",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {per?.permissionType}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </>
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminSocietyTable;
