import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Society from "../../Components/Settings/SocietyManagement/Society/Society";
import Properties from "../../Components/Settings/SocietyManagement/Properties/Properties";
import Flats from "../../Components/Settings/SocietyManagement/Flats/Flats";
import AllTimeline from "../../Components/Timeline/Timeline";
import PropertyResident from "../../Components/Settings/SocietyManagement/PropertyResident/PropertyResident";

const SocietyManagementRoutes = {
  path: "/settings",
  element: <NavigationDrawer />,
  children: [
    {
      path: "societymanagement",
      element: <Society />,
    },
    {
      path: "properties/:societyName",
      element: <Properties />,
    },
    {
      path: "flats/:societyName/:propertyId/:propertyName",
      element: <Flats />,
    },
    {
      path: "timeline/:societyName/:propertyName/:propertyId/:flatName/:flatId",
      element: <AllTimeline />,
    },
    {
      path: "flats/:societyName/:propertyId/propertyResident/:propertyName",
      element: <PropertyResident />,
    },
  ],
};

export default SocietyManagementRoutes;
