import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,
  tableContainer: {
    borderRadius: 10,
    margin: "10px 10px",
    /* maxWidth: 1560, */
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  content2: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  ModalView: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 15,
    outline: 0,
    width: "55vw",
    borderRadius: 6,
  },
  addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    /* backgroundColor: "#4b8edb", */
    color: "#fff",
    outline: 0,
    border: "1px solid #2f3aa3",
  },
  /* addButton: {
    borderRadius: 5,
    padding: 11,
    fontSize: 14,
    backgroundColor: "#2f3aa3",
    color: "#fff",
    outline: 0,
  }, */
  imgWrap: {
    position: "relative",
    width: "80px",
    height: "80px",
    overflow: "hidden",
    borderRadius: "50%",
    cursor: "pointer",
    padding: 0,
    backgroundColor: "#ffff",
  },
  cardColor1: {
    backgroundImage: "linear-gradient(315deg, #7f53ac 0%, #647dee 74%);",
    color: "white",
  },
  cardColor2: {
    color: "#ffffff",
    backgroundImage: "linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)",
  },
  cardColor3: {
    background: "linear-gradient(315deg, #06bcfb 0%, #4884ee 74%)",
    color: "#ffffff",
  },
  cardColor4: {
    background: "linear-gradient(315deg, #3bb78f 0%, #0bab64 74%)",
  },
  cardColor5: {
    background: "linear-gradient(147deg, #990000 0%, #ff0000 74%)",
  },
  avatar: {
    // backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 10,
    outline: 0,
    width: 580,
    backgroundColor: "#e8f0fa",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  successButton:{
    backgroundColor: "#fff",
    border: "2px solid #179917",
    color: "#179917",
    marginRight: 2,
    padding: 6,
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontWeight:400,
    "&:hover": {
      backgroundColor: "#179917",
      color: "#fff",
    },
  },
  cancelButton:{
    backgroundColor: "#fff",
    border: "2px solid #c94836",
    color: "#c94836",
    marginRight: 2,
    padding: 6,
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontWeight:400,
    "&:hover": {
      backgroundColor: "#c94836",
      color: "#fff",
    },
  },
  pagination: {
    '& button': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      '&.Mui-selected': {
        color: '#fff',
      },
    },
  },
}));
