import { AES, enc } from "crypto-js";

export const encrypData = (data) => {
  let encryptedData = AES.encrypt(data, "chuprahosecrethein");
  return encryptedData.toString();
};
export const decrypyData = (data) => {
  try {
    let bytes = AES.decrypt(data, "chuprahosecrethein");
    const decryptedData = bytes.toString(enc.Utf8);
    return decryptedData;
  } catch (err) {
    console.log('UNABLE TO DECIPHER', err);
  }
};
