import { CssBaseline, Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import useStyles from "../style";
import AdminDetailsCard from "./AdminDetailsCard";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import AdminSocietyTable from "./AdminSocietyTable";
import { navigateToApprovedApprovals } from "../../../Navigation/Navigations";
import * as api from "../../../api/index";
import { resolveErrorMessage } from "../../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "../../../contextAPI/Loading";
import { AuthContext } from "../../../contextAPI/context";

function AdminDetails() {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const navigate = useNavigate();
  const { adminId } = useParams();
  const [adminDetails, setAdminDetails] = useState([]);
  const [loader, setloader] = useState(false);

  const handleAdminDetails = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        adminId: adminId,
      };
      const res = await api.getApproveAdminDetails(sendData);
      if (res?.data?.success) {
        setAdminDetails(res?.data?.admin)
      }
      setloader(false);
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleAdminDetails();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid item lg={12} sm={12} xs={12}>
            <div className="card" style={{ borderRadius: 10 }}>
              <div className="card-body" style={{ borderRadius: 10 }}>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid
                    container
                    item
                    xs={6}
                    lg={6}
                    sm={6}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginBottom: 15,
                      }}
                    >
                      Admin Details
                      {!loader ? (
                        <RefreshIcon
                          title="refresh Admin Details"
                          onClick={handleAdminDetails}
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: -4,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    lg={6}
                    sm={6}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateToApprovedApprovals({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          BACK
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container style={{ justifyContent: "center" }}>
                  <AdminDetailsCard adminDetails={adminDetails} />
                </Grid>
                <Grid
                  container
                  style={{
                    justifyContent: "start",
                    marginTop: 20,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000000",
                      opacity: 0.7,
                      marginBottom: 5,
                      marginLeft: 5
                    }}
                  >
                    Society Details
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 10 }}>
                  <AdminSocietyTable adminDetails={adminDetails} user={user} />
                </Grid>
              </div>
            </div>
          </Grid>
        </main>
        {loader && <Loading loader={loader} />}
      </div>
    </>
  );
}

export default AdminDetails;
