import React, { useState, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import { ApartmentOutlined, Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import * as api from "../../../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../../services/service";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contextAPI/context";
import {
  handleFloorName,
  handleFlatName,
} from "../../../../contextAPI/NamingConvention";

function AddFlat(props) {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const classes = useStyles();
  const {
    addFlatModal,
    setaddFlatModal,
    handleGetFloors,
    floors,
    allFlatTypes,
    loader2,
  } = props;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [selectedFloor, setselectedFloor] = useState("Select Floor");
  const [selectedFlatType, setselectedFlatType] = useState("Choose Flat Type");
  const [flatName, setflatName] = useState("");
  const [flatNumber, setflatNumber] = useState("");
  const [flatSize, setflatSize] = useState("");
  const [parkingNumber, setparkingNumber] = useState("");
  const [rUnit, setrUnit] = useState("");

  const handleAddFlat = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      if (
        selectedFloor === "Select Floor" ||
        selectedFlatType === "Choose Flat Type" ||
        flatName === "" ||
        flatNumber === "" ||
        parkingNumber === "" ||
        flatSize === ""
      ) {
        setloader(false);
        seterrorMsg("All * fields are required");
      } else {
        const sendData = {
          adminId: user?._id,
          floorId: selectedFloor,
          societyId: fetchSocietyId(),
          type: selectedFlatType,
          number: flatNumber,
          name: flatName,
          parkingNumber,
          size: flatSize,
          runit:rUnit
        };

        const res = await api.createFlat(sendData);
        if (res?.data?.success) {
          handleGetFloors();
          toast.success("Flat Added Successfully");
          handleReset();
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  const handleReset = () => {
    seterrorMsg("");
    setaddFlatModal(false);
    setloader(false);
    setselectedFloor("Select Floor");
    setselectedFlatType("Choose Flat Type");
    setflatName("");
    setflatNumber("");
    setparkingNumber("");
    setflatSize("");
  };

  return (
    <>
      <Modal open={addFlatModal}>
        <Paper
          className={classes.ModalView}
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            // width: "29vw",
            height: "auto",
            minWidth: "400px",
            maxWidth: "32vw",
          }}
        >
          <Grid
            container
            style={{ textAlign: "center", justifyContent: "space-between" }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Add {handleFlatName()}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  handleReset();
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
          />
          <Grid container style={{ marginTop: 8, justifyContent: "center" }}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: 6,
                marginRight: 7,
                marginLeft: 8,
              }}
            >
              Select {handleFloorName()} :
            </Typography>
            <Select
              value={selectedFloor}
              onChange={(event) => setselectedFloor(event.target.value)}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 6,
                width: "180px",
                height: 37,
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                marginBottom: 10,
                border: "1px solid #6c757d",
                textAlign: "center",
              }}
            >
              <MenuItem
                selected
                value="Select Floor"
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                Select {handleFloorName()}
              </MenuItem>
              {floors?.map((flr) => {
                return (
                  <MenuItem
                    selected
                    value={flr._id}
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    &nbsp;{flr.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {selectedFloor === "Select Floor" && (
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 5,
                textAlign: "center",
              }}
            >
              Please Select a {handleFloorName()} first{" "}
            </Typography>
          )}
          <Grid
            style={{
              opacity: selectedFloor === "Select Floor" ? 0.4 : "",
              pointerEvents: selectedFloor === "Select Floor" ? "none" : "",
            }}
          >
            <Grid container style={{ marginLeft: -10 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handleFlatName()} Type
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handleFlatName()} Number
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <Select
                  value={selectedFlatType}
                  onChange={(event) => setselectedFlatType(event.target.value)}
                  style={{
                    color: "#212529",
                    backgroundColor: "#fff",
                    fontWeight: "bold",
                    borderRadius: 10,
                    width: "180px",
                    height: 50,
                    fontSize: "13px",
                    padding: 8,
                    paddingLeft: 6,
                    marginBottom: 10,
                    border: "1px solid #6c757d",
                    textAlign: "center",
                  }}
                >
                  <MenuItem
                    selected
                    value="Choose Flat Type"
                    style={{
                      color: "#6c757d",
                      fontFamily: "Montserrat,sans-serif",
                    }}
                  >
                    {loader2 ? "Loading..." : `Choose ${handleFlatName()} Type`}
                  </MenuItem>
                  {allFlatTypes?.map((type) => {
                    return (
                      <MenuItem
                        selected
                        value={type.name}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        &nbsp;{type.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setflatNumber(event.target.value)}
                  placeholder="Enter Number"
                  value={flatNumber}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginLeft: -10 }}>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handleFlatName()} Name
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginBottom: 2,
                    marginLeft: 13,
                  }}
                >
                  {handleFlatName()} Size
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setflatName(event.target.value)}
                  placeholder="Enter Name"
                  value={flatName}
                />
              </Grid>
              <Grid container item xs={6}>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    width: "98%",
                    border: "1px solid #416082",
                    marginBottom: 8,
                    outline: 0,
                  }}
                  onChange={(event) => setflatSize(event.target.value)}
                  placeholder="Enter Size in square feet"
                  value={flatSize}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginTop: 6,
                    marginRight: 7,
                    marginBottom: 5,
                  }}
                >
                  Enter Parking Size
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    marginTop: 6,
                    marginRight: 7,
                    marginBottom: 5,
                  }}
                >
                  Enter R. Unit
                  <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
                </Typography>
              </Grid>
              <Grid container>
                <Grid container item xs={6}>
                  <input
                    type="text"
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      width: "98%",
                      border: "1px solid #416082",
                      marginBottom: 8,
                      outline: 0,
                    }}
                    onChange={(event) => setparkingNumber(event.target.value)}
                    placeholder="Enter parking in sqaure feet"
                    value={parkingNumber}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <input
                    type="text"
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      width: "98%",
                      border: "1px solid #416082",
                      marginBottom: 8,
                      outline: 0,
                    }}
                    onChange={(event) => setrUnit(event.target.value)}
                    placeholder="Enter R.Unit"
                    value={rUnit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "160px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!loader) {
                      handleAddFlat();
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ApartmentOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? `Add ${handleFlatName()}` : `Adding...`}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default AddFlat;
