import { useContext } from "react";
import { AuthContext } from "./context";

export const handlePropertyName = () => {
  return localStorage.getItem("property");
};
export const handleFloorName = () => {
  return localStorage.getItem("floor");
};
export const handleFlatName = () => {
  return localStorage.getItem("flat");
};

// export const useHandleNamingForSociety = (type) => {
//   const { authState, authDispatch } = useContext(AuthContext);
//   const { namingofSociety } = authState;
//   let property =
//     namingofSociety?.length > 0 ? namingofSociety?.property : "Property";
//   let floor = namingofSociety?.length > 0 ? namingofSociety?.floor : "Floor";
//   let flat = namingofSociety?.length > 0 ? namingofSociety?.flat : "Flat";
//   return type === "property" ? property : type === "floor" ? floor : flat;
// };
