import React from "react";
import { Grid, Modal, Paper, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const SignupSucessModal = (props) => {
  const { successModal, setsuccessModal, navigate } = props;
  return (
    <Modal open={successModal}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#ffffff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Grid container style={{ justifyContent: "center" }}>
          <CheckCircleOutlineIcon
            style={{
              color: "green",
              height: 100,
              width: 100,
            }}
          />
        </Grid>
        <Typography
          style={{
            marginTop: 15,
            fontSize: 18,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          Your account has been created Successfully
        </Typography>
        <Typography
          style={{
            marginTop: 15,
            fontSize: 14,
            color: "#6c757d",
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          Your Request is Waiting For Approval From Admin Side.
          <br /> -&nbsp;Sociodhood Team
        </Typography>
        <Grid container style={{ justifyContent: "center", marginTop: 15 }}>
          <buton
            style={{
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              backgroundColor: "#1e2082",
              color: "#ffff",
              marginTop: 13,
              padding: 8,
              width: 150,
              border: "none",
              cursor: "pointer",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            GOTO LOGIN
          </buton>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SignupSucessModal;
