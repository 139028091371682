import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e7f0",
    [theme.breakpoints.up("sm")]: {},
  },
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: "100vh",
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    outline: 0,
    width: 450,
    backgroundColor: "#ffffff",
    // "#2a52be",
    boxShadow: "2px 2px 8px #000",
    borderRadius: 7,
  },
  buttonDelete: {
    marginTop: theme.spacing(3),
    backgroundColor: "#fff",
    padding: 8,
    fontWeight: "bold",
    fontSize: 13,
    width: 180,
    color: "#C60C30",
    "&:hover": {
      backgroundColor: "#C60C30",
      color: "#fff",
    },
  },
}));
