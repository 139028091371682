import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

function LineChart(props) {
  const data = {
    labels: props.interval,
    datasets: [
      {
        label: "Entry",
        data: props.entry,
        backgroundColor: "#7884de",
        borderColor: "#7884de",
        tension: 0.4,
      },
      {
        label: "Exit",
        data: props.exit,
        borderColor: "#e67c97",
        backgroundColor: "#e67c97",
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChart;
