import React, { useState } from "react";
import { PhoneOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import ApiCall from "../../api/ApiCall";
import { EntryPoints } from "../../api/Routes";
import OtpInput from "otp-input-react";

const PhoneVerify = ({ phone, setphone, seterrorMessage, completeFirstStep }) => {
  const [loader, setLoader] = useState("");
  const [OTP, setOTP] = useState("");
  const [isShowOtp, setIsShowOtp] = useState("");

  const startApiCall = () => {
    seterrorMessage("");
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
  };

  const handleSendOtpForSignUp = async (e) => {
    e.preventDefault();
    startApiCall();
    const data = {
      phone,
    };
    const res = await ApiCall("post", EntryPoints.sendOtpForSignup, data);
    if (res?.success) {
      toast.success("Send OTP Successfully");
      setIsShowOtp(true);
      setLoader(false);
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  const handleVerifyOtpForSignUp = async (e) => {
    e.preventDefault();
    startApiCall();
    const data = {
      phone,
      otp: OTP,
      role: "admin",
    };
    const res = await ApiCall("post", EntryPoints.verifyOtpForSignup, data);
    if (res?.success) {
      toast.success("Verify OTP Successfully");
      setOTP("");
      setIsShowOtp(false);
      completeFirstStep();
      setLoader(false);
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <>
      {!isShowOtp && (
        <form onSubmit={handleSendOtpForSignUp}>
          <div class="form-detalis">
            <div class="input-main">
              <PhoneOutlined style={{ color: "#303f9f" }} />

              <div class="be-main"></div>
              <div class="input-box">
                <input
                  type="text"
                  class="input"
                  required
                  placeholder="Phone Number"
                  onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      setphone(e.target.value);
                    }
                  }}
                  value={phone}
                  maxLength={10}
                  pattern="^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$"
                  title="please enter a valid phone number"
                  style={{ marginLeft: "-15px" }}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="continue"
            disabled={loader}
            style={{ opacity: loader ? 0.8 : "" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  marginTop: 10,
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {loader ? "Sending....." : "Send Otp"}
              </p>
            </div>
          </button>
        </form>
      )}
      {isShowOtp && (
        <form onSubmit={handleVerifyOtpForSignUp}>
          <div class="form-detalis">
            <p
              style={{
                fontSize: "16px",
                marginTop: 10,
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Please enter the verification code that has been sent to your phone : {phone}
            </p>
            <div class="input-main">
              <div class="input-box">
                <OtpInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  renderSeparator={<span>-</span>}
                  disabled={false}
                  shouldAutoFocus={true}
                  style={{ marginLeft: 10 }}
                />
              </div>
            </div>
            <button
              type="submit"
              class="continue"
              disabled={loader}
              style={{ opacity: loader ? 0.8 : "" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Loading...." : "Verify"}
                </p>
              </div>
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default PhoneVerify;
