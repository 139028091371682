/* import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Text,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";

import "./style.css";

const blues = [
  ["#457AA6"],
  ["#457AA6", "#E3EBF2"],
  ["#264F73", "#457AA6", "#E3EBF2"],
  ["#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"],
  ["#1A334A", "#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"],
];

const getColor = (length, index) => {
  if (length <= blues.length) {
    return blues[length - 1][index];
  }

  return blues[blues.length - 1][index % blues.length];
};

const data = [
  { name: "Sec-106", complaint: 240 },
  { name: "Sec-107", complaint: 2210 },
  { name: "Sec-108", complaint: 2300 },
  { name: "Sec-109", complaint: 2000 },
  { name: "Sec-110", complaint: 200 },
  { name: "Sec-111", complaint: 123 },
  { name: "Sec-112", complaint: 201 },
  { name: "Sec-113", complaint: 3091 },
  { name: "Sec-114", complaint: 3091 },
  { name: "Sec-115", complaint: 3091 },
  { name: "Sec-116", complaint: 3091 },
  { name: "Sec-117", complaint: 3091 },
];

const YAxisLeftTick = ({ y, payload: { value } }) => {
  return (
    <Text x={0} y={y} textAnchor="start" verticalAnchor="middle" scaleToFit>
      {value}
    </Text>
  );
};

let ctx;

export const measureText14HelveticaNeue = (text) => {
  if (!ctx) {
    ctx = document.createElement("canvas").getContext("2d");
    ctx.font = "14px 'Helvetica Neue";
  }

  return ctx.measureText(text).width;
};

const BAR_AXIS_SPACE = 20;

const SimpleBarChart = ({ data, yKey, xKey }) => {
  const maxTextWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[yKey];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data, yKey]
  );
  return (
    <ResponsiveContainer width={"100%"} height={"100%"} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 10, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          yAxisId={0}
          dataKey={xKey}
          type="category"
          axisLine={false}
          tickLine={false}
          label={0}
          style={{ fontSize: 11 }}
          // tick={{
          //   transform: `translate(${"100%" + "10%"}, 0)`
          // }}
        />
        <YAxis
          orientation="right"
          yAxisId={1}
          dataKey={yKey}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => value.toLocaleString()}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
          }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey={yKey} minPointSize={2} barSize={32}>
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill={getColor(data.length, idx)} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default function SectorComplaintGraph() {
  return <SimpleBarChart data={data} xKey="name" yKey="complaint" />;
}
 */

import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

// const data = [
//     {
//         name: "Sec-106",
//         complaints: 20,
//         amt: 2100,
//     },
//     {
//         name: "Sec-107",
//         complaints: 30,
//         amt: 2210,
//     },
//     {
//         name: "Sec-108",
//         complaints: 20,
//         amt: 2290,
//     },
//     {
//         name: "Sec-109",
//         complaints: 27,
//         amt: 2000,
//     },
//     {
//         name: "Sec-110",
//         complaints: 18,
//         amt: 2181,
//     },
//     {
//         name: "Sec-111",
//         complaints: 23,
//         amt: 2500,
//     },
//     {
//         name: "Sec-112",
//         complaints: 34,
//         amt: 2100,
//     },
//     {
//         name: "Sec-113",
//         complaints: 14,
//         amt: 2100,
//     },
//     {
//         name: "Sec-114",
//         complaints: 24,
//         amt: 2100,
//     },
//     {
//         name: "Sec-115",
//         complaints: 46,
//         amt: 2100,
//     },
//     {
//         name: "sec-120",
//         complaints: 49,
//         amt: 2100,
//     },
//     {
//         name: "Sec-116",
//         complaints: 32,
//         amt: 2100,
//     },
//     {
//         name: "Sec-117",
//         complaints: 30,
//         amt: 2100,
//     },
//     {
//         name: "Sec-118",
//         complaints: 40,
//         amt: 2100,
//     },
//     {
//         name: "Sec-119",
//         complaints: 64,
//         amt: 2100,
//     },
//     // {
//     //   name: "Page H",
//     //   complaints: 34,
//     //   amt: 2100,
//     // },
//     // {
//     //   name: "Page I",
//     //   complaints: 34,
//     //   amt: 2100,
//     // },
//     // {
//     //   name: "Page J",
//     //   complaints: 44,
//     //   amt: 2100,
//     // },
// ];

export default function SectorComplaintGraph({ data }) {
  return (
    <ResponsiveContainer width="100%" height={440}>
      <BarChart data={data} layout="vertical" margin={{ top: 5, bottom: 5 }}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          hide
          axisLine={false}
          type="number"
          /* tick={{ fontStyle: "Poppins, sans-serif", fontWeight: "bold" }} */
        />
        <YAxis
          type="category"
          dataKey="sectorName"
          interval={0}
          style={{ fontSize: 11,width:"700px",color:"#0000",fontWeight:"bold"}}

        />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="numberOfComplaints"
          fill="#11224D"
          name="Sector wise Total Complaints"
        >
           <LabelList
            dataKey="numberOfComplaints"
            position="center"
            fill="#fff"
            style={{ fontSize: 15, fontFamily: "Montserrat, sans-serif",fontWeight:"bold" }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
