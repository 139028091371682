import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Close, ErrorOutline } from "@material-ui/icons";
import useStyles from "./styles";
import { LoadingOutlined } from "@ant-design/icons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useParams } from "react-router";
import * as api from "../../../../api/index";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../services/service";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function AddJmrItem(props) {
  const classes = useStyles();
  const autoCompleteClass = styles();
  const { id } = useParams();
  const {
    addJmrItem,
    setaddJmrItem,
    jmrItem,
    jmrData,
    handleGetJmr,
    jmeEditModal,
    setjmeEditModal,
    type,
    jmrtableitem,
    vendorId,
    vendorData,
    jmrItemTotalCount,
    setjmrItemPage,
    pleasewait,
    setautoCompletesearchKey,
  } = props;
  const [itemQuantity, setitemQuantity] = useState(1);
  const [itemTotalPrice, setitemTotalPrice] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const [jmrItemCode, setjmrItemCode] = useState("");
  const [itemLength, setitemLength] = useState("");
  const [itemBreadth, setitemBreadth] = useState("");
  const [jmrItemMF, setjmrItemMF] = useState(0);
  const [jmrItemHeight, setjmrItemHeight] = useState("");
  const [itemNos, setitemNos] = useState("");
  const [subLocation, setsubLocation] = useState("");

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !pleasewait &&
      jmrItemTotalCount !== jmrItem?.length
    ) {
      setjmrItemPage((prev) => prev + 1);
    }
  };

  const handleAddJmrItem = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (jmrItemCode.length <= 0) {
      setloader(false);
      seterrorMsg("Please Select Item");
    } else if (itemLength <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Length");
    } else if (itemBreadth <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Breadth");
    } else if (jmrItemHeight <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Height");
    } else if (itemNos <= 0) {
      setloader(false);
      seterrorMsg("Please Enter Nos");
    } else {
      try {
        const sendData = {
          complaintId: id,
          vendorId: vendorId,
          jmrId: jmrData?._id,
          itemId: jmrItemCode?._id,
          quantity: itemQuantity,
          mf: jmrItemMF,
          totalPrice: itemTotalPrice,
          itemLength: itemLength,
          itembreadth: itemBreadth,
          nos: itemNos,
          height: jmrItemHeight,
          subLocation: subLocation,
        };
        const res = await api.addIteminJmr(sendData);
        if (res.data.success) {
          setaddJmrItem(false);
          handleGetJmr();
          handleReset();
          toast.success("JMR Item has been Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setloader(false);
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  const handleEditJmrItem = async () => {
    setloader(true);
    try {
      const sendData = {
        jmrId: jmrData?._id,
        quantity: itemQuantity,
        jmritem: jmrtableitem?._id,
        vendorId: vendorId,
        totalPrice: itemTotalPrice,
        itemLength: itemLength,
        itembreadth: itemBreadth,
        nos: itemNos,
        height: jmrItemHeight,
        mf: jmrItemMF,
        subLocation: subLocation,
      };
      const res = await api.editIteminJmr(sendData);
      if (res.data.success) {
        setjmeEditModal(false);
        handleGetJmr();
        toast.success("JMR Item has been Edited Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setloader(false);
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  useEffect(() => {
    if (jmrtableitem) {
      setitemLength(jmrtableitem?.itemLength);
      setitemBreadth(jmrtableitem?.itembreadth);
      setjmrItemHeight(jmrtableitem?.height);
      setjmrItemMF(jmrtableitem?.mf);
      setitemNos(jmrtableitem?.nos);
      setitemQuantity(jmrtableitem?.quantity);
      setsubLocation(jmrtableitem?.subLocation);
      setitemTotalPrice(jmrtableitem?.totalPriceForVendorJmr);
    }
  }, [jmrtableitem]);

  useEffect(() => {
    if (type === "Edit") {
      setitemQuantity(
        itemLength *
          itemBreadth *
          jmrItemHeight *
          itemNos *
          (jmrItemMF <= 0 ? 1 : jmrItemMF)
      );
      setitemTotalPrice(itemQuantity * jmrtableitem?.basePrice);
    } else {
      setitemQuantity(
        itemLength *
          itemBreadth *
          jmrItemHeight *
          itemNos *
          (jmrItemMF === 0 ? 1 : jmrItemMF)
      );
      setitemTotalPrice(itemQuantity * jmrItemCode?.basePrice);
    }
  }, [
    itemQuantity,
    itemLength,
    itemBreadth,
    jmrItemHeight,
    jmrItemCode,
    jmrItemMF,
    itemNos,
  ]);

  const handleReset = () => {
    setjmrItemCode("");
    setitemTotalPrice("");
    setitemQuantity("");
    setjmrItemMF(0);
    setitemLength("");
    setitemBreadth("");
    setitemNos("");
    setjmrItemHeight("");
    setsubLocation("");
    seterrorMsg("");
  };

  return (
    <Modal open={addJmrItem || jmeEditModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          maxWidth: "45vw",
          minWidth: "450px",
          // height: "76vh",
          overflow: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            // textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid container item xs={8}>
            <Typography
              style={{
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "start",
                marginLeft: 10,
                fontSize: "1rem",
                opacity: 1,
              }}
            >
              {type === "Edit" ? "Edit Jmr" : "Add Jmr"}
            </Typography>
          </Grid>
          <Grid
            conatiner
            item
            xs={4}
            style={{
              textAlign: "end",
            }}
          >
            {/* {!loader && ( */}
            <IconButton
              onClick={() => {
                if (type === "Edit") {
                  setjmeEditModal(false);
                } else {
                  setaddJmrItem(false);
                  handleReset();
                }
              }}
            >
              <Close
                style={{
                  color: "#000",
                  fontSize: 20,
                  marginTop: -10,
                }}
              />
            </IconButton>
            {/* )} */}
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid container xs={12} justifyContent={"flex-start"}>
          <Grid xs={2}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 14,
                fontSize: "0.85rem",
              }}
            >
              Item Name : &nbsp;{" "}
            </Typography>
          </Grid>
          <Grid xs={8}>
            {type === "Edit" ? (
              <Typography
                style={{
                  color: "#00042A",
                  marginBottom: 6,
                  textAlign: "left",
                  fontSize: "15px",
                  marginTop: 10,
                }}
              >
                {jmrtableitem?.itemName}
              </Typography>
            ) : (
              <Autocomplete
                onChange={(e, value) => {
                  setjmrItemCode(value);
                }}
                id="country-select-demo"
                style={{
                  width: "95%",
                  backgroundColor: "#fff",
                }}
                freeSolo
                value={jmrItemCode}
                classes={autoCompleteClass}
                options={jmrItem}
                autoHighlight
                getOptionLabel={(option) =>
                  `${
                    option.itemName
                      ? `${option?.itemName} ${option?.uom}- (${option?.itemCode})`
                      : ""
                  }`
                }
                renderOption={(props, option) => {
                  return (
                    <div>
                      <li {...props} key={option.id}>
                        {jmrItemCode?.length > 0 &&
                          jmrItemCode.includes(option) && (
                            <CheckCircle
                              style={{
                                color: "rgb(36 202 38)",
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                            />
                          )}
                        {option?.itemName} {option?.uom}- ({option?.itemCode})
                      </li>
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    onChange={(e) => {
                      setjmrItemPage(1);
                      setautoCompletesearchKey(e.target.value);
                    }}
                    onBlur={() => {
                      setautoCompletesearchKey("");
                    }}
                    label={`Select Item (${jmrItemTotalCount})`}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScroll,
                }}
              />
            )}
          </Grid>
        </Grid>
        {(jmrItemCode !== "" || type === "Edit") && (
          <>
            <Grid container xs={12} style={{ marginTop: "8px" }}>
              <Grid xs={12}>
                <Typography
                  style={{
                    color: "#283f59",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    marginLeft: 4,
                    marginTop: 10,
                    fontSize: "0.85rem",
                  }}
                >
                  Item Description : &nbsp;
                  {type === "Edit"
                    ? jmrtableitem?.itemDescription
                    : jmrItemCode?.itemDescription}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: "8px" }}>
              <Grid xs={6}>
                <Typography
                  style={{
                    color: "#283f59",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "600",
                    marginLeft: 4,
                    marginTop: 10,
                    fontSize: "0.85rem",
                  }}
                >
                  Item BaseRate : &nbsp;
                  {type === "Edit"
                    ? jmrtableitem?.basePrice
                    : jmrItemCode?.basePrice}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container xs={12} style={{ marginTop: "8px" }}>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Item Length : &nbsp;
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Item Breadth : &nbsp;{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Length"
              value={itemLength}
              onChange={(event) => {
                setitemLength(event.target.value);
              }}
            />
          </Grid>
          <Grid xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Breadth"
              value={itemBreadth}
              onChange={(event) => {
                setitemBreadth(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ marginTop: "8px" }}>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Depth/Height : &nbsp;
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              M/f : &nbsp;{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Depth/height"
              value={jmrItemHeight}
              onChange={(event) => {
                setjmrItemHeight(event.target.value);
              }}
            />
          </Grid>
          <Grid xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter M/F"
              value={jmrItemMF}
              onChange={(event) => {
                setjmrItemMF(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ marginTop: "8px" }}>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Item Nos : &nbsp;
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Item Qnty : &nbsp;{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Nos"
              value={itemNos}
              onChange={(event) => {
                setitemNos(event.target.value);
              }}
            />
          </Grid>
          <Grid xs={6}>
            <input
              type="number"
              disabled
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Quantity"
              onChange={(event) => {
                setitemQuantity(event.target.value);
              }}
              value={itemQuantity}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ marginTop: "8px" }}>
          <Grid xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 10,
                fontSize: "0.85rem",
              }}
            >
              Sub Location : &nbsp;
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <input
              type="text"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Enter Sub Location"
              onChange={(event) => {
                setsubLocation(event.target.value);
              }}
              value={subLocation}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "8px" }}>
          <Grid xs={3}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginLeft: 4,
                marginTop: 15,
                fontSize: "0.85rem",
              }}
            >
              Total Price (₹) : &nbsp;
            </Typography>
          </Grid>
          <Grid xs={8}>
            <input
              type="number"
              disabled
              style={{
                borderRadius: 10,
                padding: 10,
                width: "96%",
                height: 45,
                border: "1px solid #416082",
                outline: 0,
                marginTop: 5,
              }}
              placeholder="Total Price"
              onChange={(event) => {
                setitemTotalPrice(event.target.value);
              }}
              value={itemTotalPrice}
            />
          </Grid>
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container item style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "167px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: "12px",
            }}
            onClick={() => {
              if (type === "Edit") {
                handleEditJmrItem();
              } else {
                handleAddJmrItem();
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                }}
              >
                <>
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <AddCircleOutlineIcon
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  {type === "Edit" ? "Edit Jmr item" : "Add Jmr Item"}
                </>
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddJmrItem;
