import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "../style";
import { Avatar, Grid, Paper, Typography } from "@material-ui/core";

function DailyHelpDetailsTable(props) {
  const classes = useStyles();
  const { staffTypeDetails, loader, page, pageLimit } = props;

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Staff Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact No
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Type
              </TableCell>
            </TableRow>
          </TableHead>
          {!loader && (
            <TableBody>
              {staffTypeDetails?.map((stf, index) => {
                return (
                  <TableRow key={stf?._id}>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page - 1) * pageLimit + (index + 1)}.
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar
                          style={{ cursor: "pointer" }}
                          alt={stf.name}
                          src={
                            stf.image
                              ? stf?.image
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                      <Typography
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        className={classes.textname}
                      >
                        {stf.name}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {stf?.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "capitalize",
                      }}
                    >
                      {stf?.staffType}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!staffTypeDetails?.length && !loader && (
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              marginTop: 7,
              fontWeight: "bolder",
            }}
          >
            No Staff
          </Typography>
        </Grid>
      )}
    </>
  );
}

export default DailyHelpDetailsTable;
