import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import { useNavigate } from "react-router";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { navigateToVendor } from "../../../Navigation/Navigations";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import Loading from "../../../contextAPI/Loading";
import VendorRequest from "./VendorRequest";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { AllResidentRoutes } from "../../../api/Routes";
import ApiCall from "../../../api/ApiCall";
import { AuthContext } from "../../../contextAPI/context";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "@mui/material";

function ApproveVendor() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const [allVendorRequest, setAllVendorRequest] = useState([]);
  const [count, setCount] = React.useState(0);
  const [statusForResidentData, setstatusForResidentData] = useState("pending");

  const handleViewRejectedReq = () => {
    setstatusForResidentData(
      statusForResidentData === "pending" ? "rejected" : "pending"
    );
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };
  const handleGetAllVendorRequests = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const data = {
      filters: {
        society: fetchSocietyId(),
        approvalStatus:
          statusForResidentData === "rejected" ? "rejected" : "pending",
      },
      page: page,
      limit: 50,
    };
    const res = await ApiCall(
      "post",
      AllResidentRoutes.getAllVendorRequests,
      data
    );
    if (res?.success) {
      setAllVendorRequest(res?.count?.data);
      setCount(res?.count?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllVendorRequests();
  }, [page, statusForResidentData]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Approve Vendor&nbsp;&nbsp;
                  {!loader ? (
                    <RefreshIcon
                      title="refresh request Page"
                      onClick={() => {
                        handleGetAllVendorRequests();
                      }}
                      style={{
                        marginLeft: 5,
                        marginTop: -4,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  ) : (
                    <LoadingOutlined
                      title="laoding..."
                      style={{
                        marginLeft: 5,
                        color: "#000",
                        marginTop: -4,
                      }}
                    />
                  )}
                </Typography>

                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(navigateToVendor({}));
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        marginTop: "-12px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      &nbsp;Back
                    </p>
                  </div>
                </div>
              </Grid>
              {!loader && (
                <Grid style={{ marginTop: 16, marginRight: 20 }}>
                  <Grid
                    container
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Checkbox
                      checked={statusForResidentData === "rejected"}
                      onClick={handleViewRejectedReq}
                      style={{ color: "#283f59", marginTop: 7 }}
                    />
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginTop: 10,
                        marginRight: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      View Rejected Request
                    </Typography>
                  </Grid>
                  {allVendorRequest?.length > 0 && (
                    <VendorRequest
                      allVendorRequest={allVendorRequest}
                      handleGetAllVendorRequests={handleGetAllVendorRequests}
                      statusForResidentData={statusForResidentData}
                      usermoduleswithpermissions={usermoduleswithpermissions}
                      page={page}
                    />
                  )}
                </Grid>
              )}
              {!loader && allVendorRequest?.length > 0 && (
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              {!loader && allVendorRequest?.length === 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      color: "#6c757d",
                      textAlign: "center",
                      fontSize: 22,
                      marginTop: 30,
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {statusForResidentData === "pending"
                      ? "No Pending Vendor"
                      : "No Rejected Vendor"}
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default ApproveVendor;
