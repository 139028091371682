import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

function ComplaintBarGraph(props) {
  const data = {
    labels: ["No. of Complaints"],
    datasets: [
      {
        label: "NewComplaint",
        data: props.new,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "#EE383D",
        borderWidth: 2,
        borderRadius: { topLeft: 16, topRight: 16 },
        borderSkipped: false,
      },
      {
        label: "AssignComplaint",
        data: props.assign,
        backgroundColor: "rgba(255, 206, 86, 0.6)",
        borderColor: "#EBBB0E",
        borderWidth: 2,
        borderRadius: { topLeft: 16, topRight: 16 },
        borderSkipped: false,
      },
      {
        label: "ResolveComplaint",
        data: props.resolve,
        backgroundColor: "rgba(4,208,0, 0.6)",
        borderColor: "#04D000",
        borderWidth: 2,
        borderRadius: { topLeft: 16, topRight: 16 },
        borderSkipped: false,
      },
      {
        label: "CloseComplaint",
        data: props.close,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "#9747FF",
        borderWidth: 2,
        borderRadius: { topLeft: 16, topRight: 16 },
        borderSkipped: false,
      },

      {
        label: "CancelComplaint",
        data: props.cancel,
        backgroundColor: "rgba(35,119,154, 0.6)",
        borderColor: "#23779A",
        borderWidth: 2,
        borderRadius: { topLeft: 16, topRight: 16 },
        borderSkipped: false,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#8c8c8b",
          font: {
            size: 12,
            weight: "500",
          },
        },
      },
      tooltip: {
        mode: "point",
        backgroundColor: "white",
        bodyColor: "#8c8c8b",
        bodyFont: {
          weight: 500,
        },
        borderColor: "rgba(0, 0, 0, 0.08)",
        borderWidth: 2,
        cornerRadius: 0,
        padding: {
          top: 4,
          bottom: 4,
          left: 8,
          right: 8,
        },
        displayColors: false,
      },
      //   datalabels: {
      //     display: false,
      //   },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#8c8c8b",
          font: {
            size: 12,
            weight: "500",
          },
          padding: 0,
        },
      },
      y: {
        display: true,
        grid: {
          color: "#ececec",
        },
        ticks: {
          color: "#8c8c8b",
          font: {
            size: 12,
            weight: "500",
          },
          padding: 6,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
}

export default ComplaintBarGraph;
