import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import AdvanceVendorSetting from "../../Components/Complaints/AdavnceVendorSetting/AdvanceVendorSetting";
import ViewPrevSettings from "../../Components/Complaints/AdavnceVendorSetting/ViewPrevSettings";

const AdvanceVendorRoutes = {
  path: "/complaints",
  element: <NavigationDrawer />,
  children: [
    {
      path: "advancevendor/:categoryName/:id",
      element: <AdvanceVendorSetting />,
    },
    {
      path: "prevsettings/:categoryName/:id",
      element: <ViewPrevSettings />,
    },
    {
      path: "prevsettings/:categoryName/:id/:sectorName",
      element: <ViewPrevSettings />,
    },
  ],
};
export default AdvanceVendorRoutes;

