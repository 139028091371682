import {
  LOGINUSER,
  GETUSER,
  LOGOUT,
  SOCIETIES,
  PROPERTIES,
  FLATS,
  PROPERTYTYPE,
  FLATTYPE,
  CATEGORIES,
  VENDORS,
  COMPLAINT,
  COMPLAINTLIFECYCLE,
  APPROVALREQUEST,
  ADMIN,
  ALLVENDORS,
  DELETEVENDORS,
  FECTHEDVENDOR,
  PERMISSIONMODULES,
  USERMODULESWITHPERSMISSIONS,
  VERSIONHANDLE,
  SWITCHEDSOCIETYID,
  MEMBERTYPE,
  NAMINGCONVENTION,
  SOCIETYCONFIGURATION,
  PLANT,
} from "./actions/auth.actions";

export const authReducer = (state, action) => {
  switch (action.type) {
    case LOGINUSER:
      // localStorage.clear();
      localStorage.setItem(
        "accessToken",
        JSON.stringify(action.payload.tokens.access.token)
      );
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(action.payload.tokens.refresh.token)
      );
      localStorage.setItem(
        "societyId",
        action.payload.user.societies
          ? action.payload.user.societies?.[0]
          : action.payload.user.society
      );

      return { user: action.payload.user };
    case GETUSER:
      localStorage.setItem("noticeType", "published");
      return { ...state, user: action.payload.user };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        user: null,
      };

    case SOCIETIES:
      return {
        ...state,
        societies: action.payload,
      };
    case SWITCHEDSOCIETYID:
      return {
        ...state,
        societyId: action.payload,
      };

    case PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };
    case FLATS:
      return {
        ...state,
        flats: action.payload,
      };
    case PROPERTYTYPE:
      return {
        ...state,
        propertytypes: action.payload,
      };
    case FLATTYPE:
      return {
        ...state,
        flattypes: action.payload,
      };

    case CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };
    case ALLVENDORS:
      return {
        ...state,
        allvendors: action.payload,
      };
    case DELETEVENDORS:
      return {
        ...state,
        deletedvendors: action.payload,
      };
    case COMPLAINT:
      return {
        ...state,
        complaint: action.payload.complaint,
        complaintPath: action.payload.pathName,
      };
    case COMPLAINTLIFECYCLE:
      return {
        ...state,
        complaintlifecycle: action.payload,
      };
    case APPROVALREQUEST:
      return {
        ...state,
        approvalrequests: action.payload,
      };
    case ADMIN:
      return {
        ...state,
        admins: action.payload,
      };
    case FECTHEDVENDOR:
      return {
        ...state,
        fetchedvendor: action.payload,
      };
    case PERMISSIONMODULES:
      return {
        ...state,
        permissionmodules: action.payload,
      };
    case USERMODULESWITHPERSMISSIONS:
      return {
        ...state,
        usermoduleswithpermissions: action.payload,
      };

    case VERSIONHANDLE:
      return {
        ...state,
        maintenance: action.payload,
      };
    case MEMBERTYPE:
      return {
        ...state,
        memberType: action.payload,
      };
    case NAMINGCONVENTION:
      localStorage.setItem(
        "property",
        action?.payload?.property ? action?.payload?.property : "Property"
      );
      localStorage.setItem(
        "flat",
        action?.payload?.length === 0 ? "Flat" : action?.payload?.flat
      );

      localStorage.setItem(
        "floor",
        action?.payload?.floor ? action?.payload?.floor : "Floor"
      );

      return {
        ...state,
        namingofSociety: action.payload,
      };
    case SOCIETYCONFIGURATION:
      let isReopen = action?.payload?.reopenComplaint === false ? false : true;
      let isVisitorId =
        action?.payload?.needIdentityCard === true ? true : false;

      localStorage.setItem("Reopen", isReopen);
      localStorage.setItem("ID", isVisitorId);

      return {
        ...state,
        societyconfigs: action.payload,
      };
    case PLANT:
      return {
        ...state,
        plant: action.payload,
      };
  }
};
