import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import { CssBaseline, Grid, Paper, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import AddItems from "./AddItems";
import ItemsTable from "./ItemsTable";
import { Pagination } from "@mui/material";
import * as api from "../../../../../api/index";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import Loading from "../../../../../contextAPI/Loading";

function AllItem() {
  const classes = useStyles();
  const [addItemModal, setAddItemModal] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [loader, setloader] = useState(false);
  const [count, setcount] = useState(0);
  const [page, setPage] = useState(1);
  const [errorMsg, seterrorMsg] = useState("");

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleItems = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 50000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: page,
        pageLimit: 50,
      };
      const res = await api.getJmrItems({ params: sendData });
      if (res?.data?.success) {
        setAllItems(res?.data?.item?.items);
        setcount(res?.data?.item?.totalPages);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleItems();
  }, [page]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12}>
            <Paper
              style={{
                padding: 12,
                boxShadow: "0px 0px 0px",
                minHeight: "100vh",
              }}
            >
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                  container
                  item
                  xs={5}
                  lg={5}
                  sm={5}
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginTop: "-5px",
                      marginBottom: 10,
                    }}
                  >
                    All Items
                    {!loader ? (
                      <RefreshIcon
                        title="refresh items Page"
                        onClick={handleItems}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  style={{
                    justifyContent: "flex-end",
                    marginBottom: 12,
                  }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #7D858D",
                      width: "127px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginLeft: 8,
                      marginTop: 7,
                    }}
                    onClick={() => {
                      setAddItemModal(true);
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <AddCircle
                          style={{ marginTop: -3, fontSize: "15px" }}
                        />
                        &nbsp;&nbsp; Add Item
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <ItemsTable
                  allItems={allItems}
                  loader={loader}
                  setloader={setloader}
                  page={page}
                  handleItems={handleItems}
                  errorMsg={errorMsg}
                  seterrorMsg={seterrorMsg}
                />
              </Grid>
              {allItems?.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        <AddItems
          addItemModal={addItemModal}
          setAddItemModal={setAddItemModal}
          handleItems={handleItems}
        />
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default AllItem;
