import React, { useState, useEffect } from "react";
import { Typography, CssBaseline, Grid } from "@material-ui/core";
import useStyles from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import * as api from "../../../api/index";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CheckCircle } from "@material-ui/icons";
import moment from "moment";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "@mui/material";
import ResidentTable from "./ResidentsTable";
import { resolveErrorMessage } from "../../../services/service";

function Resident(props) {
  const classes = useStyles();
  const [resident, setResident] = useState([]);
  const [pleasewait, setpleasewait] = useState(false);
  const [search, setsearch] = useState("");
  const [status, setStatus] = useState("");
  const [checkInStatus, setcheckInStatus] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  var date = moment(new Date()).format("YYYY-MM-DD");
  const [totalResident, settotalResident] = useState(0);
  const [totalIn, settotalIn] = useState(0);
  const [totalOut, settotalOut] = useState(0);
  const [filteredAllResident, setfilteredAllResident] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [pageLimit, setpageLimit] = useState("50");

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleRefreshPage = () => {
    handleCountResident();
    handleViewResident();
  };

  const handleViewResident = async () => {
    try {
      setpleasewait(true);
      setTimeout(() => {
        setpleasewait(false);
      }, 40000);
      const res = await api.gateManagement({
        params: {
          societyId: fetchSocietyId(),
          status,
          role: "resident",
          searchKey: search,
          checkInstatus: checkInStatus,
          startDate,
          endDate: endDate === "" ? date : endDate,
          pageLimit,
          pageNumber: page,
        },
      });
      if (res?.data?.success) {
        setResident(res?.data?.visitor?.resident);
        setCount(res?.data?.visitor?.count);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };

  const handleCountResident = async () => {
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        role: "resident",
        startDate,
        endDate: endDate === "" ? date : endDate,
      };
      const res = await api.countGateManagement(sendData);
      if (res?.data?.success) {
        settotalResident(res?.data?.visitor?.totalResident);
        settotalIn(res?.data?.visitor?.totalIn);
        settotalOut(res?.data?.visitor?.totalOut);
        setfilteredAllResident(res?.data?.visitor?.totalFilterVisitor);
      }
    } catch (error) {
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleViewResident();
    handleCountResident();
  }, [search, status, checkInStatus, startDate, page]);

  const handleResetFilter = () => {
    setStatus("");
    setcheckInStatus("");
    setsearch("");
    setstartDate("");
    setendDate("");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div class="card">
            <div class="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {`Gate Management > `}
                    <span style={{ cursor: "pointer", color: "#000" }}>
                      Resider
                    </span>
                    {!pleasewait ? (
                      <RefreshIcon
                        title="refresh Resident Page"
                        onClick={handleRefreshPage}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>

                <Grid>
                  <Grid container>
                    <div
                      class="card"
                      style={{
                        height: 34,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "160px",
                        color: "#ffffff",
                        cursor: "pointer",
                        borderTop: "2px solid #2f45c5",
                      }}
                      onClick={() => {
                        setcheckInStatus("");
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          All Resident{" "}
                          <span style={{ fontSize: "15px", marginLeft: 3 }}>
                            {totalResident}
                          </span>
                          {checkInStatus === "" && (
                            <CheckCircle
                              style={{
                                color: "rgb(36 202 38)",
                                fontSize: "15px",
                                marginLeft: 2,
                                marginTop: -4,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      class="card"
                      style={{
                        height: 34,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "160px",
                        color: "#ffffff",
                        cursor: "pointer",
                        borderTop: "2px solid #008000",
                        marginLeft: 7,
                      }}
                      onClick={() => {
                        setcheckInStatus("in");
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          IN
                          <span style={{ fontSize: "15px", marginLeft: 3 }}>
                            {totalIn}{" "}
                          </span>
                          {checkInStatus === "in" && (
                            <CheckCircle
                              style={{
                                color: "rgb(36 202 38)",
                                fontSize: "15px",
                                marginLeft: 2,
                                marginTop: -4,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      class="card"
                      style={{
                        height: 34,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "160px",
                        color: "#ffffff",
                        cursor: "pointer",
                        borderTop: "2px solid #D92402",
                        marginLeft: 7,
                      }}
                      onClick={() => {
                        setcheckInStatus("out");
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          OUT
                          <span style={{ fontSize: "15px", marginLeft: 3 }}>
                            {totalOut}{" "}
                          </span>
                          {checkInStatus === "out" && (
                            <CheckCircle
                              style={{
                                color: "rgb(36 202 38)",
                                fontSize: "15px",
                                marginLeft: 2,
                                marginTop: -4,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Typography
                style={{
                  fontSize: 15,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight:"bold"
                }}
              >
                Filter by resident creation time :
              </Typography> */}
              <Grid
                container
                style={{ justifyContent: "space-between", marginTop: 10 }}
              >
                <Grid></Grid>
                {/* <Grid>
                  <Grid
                    container
                    item
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: 12,
                      marginTop: -8,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 22,
                        marginRight: 8,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Start Date
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 10,
                        width: 170,
                        height: 50,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      onChange={(event) => {
                        setstartDate(event.target.value);
                      }}
                      placeholder="Choose Start Date"
                      value={startDate}
                      max={date}
                    />

                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 12,
                        marginTop: 22,
                        marginRight: 8,
                        marginLeft: 10,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      End Date
                    </Typography>
                    <input
                      type="date"
                      style={{
                        borderRadius: 10,
                        padding: 10,
                        width: 170,
                        height: 50,
                        border: "1px solid #416082",
                        outline: 0,
                        marginTop: 10,
                      }}
                      min={startDate}
                      max={date}
                      disabled={!startDate}
                      onChange={(event) => {
                        setendDate(event.target.value);
                      }}
                      placeholder="Choose End Date"
                      value={endDate === "" ? date : endDate}
                    />

                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 18,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleViewResident();
                          }}
                        >
                          <SearchIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          FIND
                        </p>
                      </div>
                    </div>
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "100px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginTop: 18,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleResetFilter();
                          }}
                        >
                          <RestartAlt
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          RESET
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid> */}

                <div class="box" style={{ marginTop: 10 }}>
                  <input
                    type="text"
                    placeholder="search..."
                    onChange={(e) => {
                      setsearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                  />
                  <div>
                    <SearchIcon />
                  </div>
                </div>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <ResidentTable
                  resident={resident}
                  pleasewait={pleasewait}
                  page={page}
                  pageLimit={pageLimit}
                />
              </Grid>
              {resident?.length > 0 && (
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
    </div>
  );
}

export default Resident;
