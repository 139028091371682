import React, { useEffect, useState } from "react";
import useStyles from "../Properties/styles";
import { Typography, Grid } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import PropertyResidentTable from "./PropertyResidentTable";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { navigateToProperty } from "../../../../Navigation/Navigations";
import { useNavigate, useParams } from "react-router";
import * as api from "../../../../api";
import { Pagination } from "@mui/material";
import Loading from "../../../../contextAPI/Loading";
import { LoadingOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { handlePropertyName } from "../../../../contextAPI/NamingConvention";

const PropertyResident = () => {
  const classes = useStyles();
  const { societyName, propertyName, propertyId } = useParams();
  const navigate = useNavigate();
  const [allResidents, setallResidents] = useState([]);
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [totalCount, settotalCount] = React.useState(0);
  const [page, setpage] = useState(1);
  const [limit] = useState("20");

  const handlePageChange = async (event, value) => {
    setpage(value);
  };

  const handleGetAllResidents = async () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);

    const sendData = {
      filters: {
        property: propertyId,
        searchKey: search,
      },
      page: page,
      limit: limit,
    };

    const res = await api.getCurrentResidentInProperty(sendData);
    if (res?.data?.success) {
      setallResidents(res?.data?.data?.data);
      settotalCount(res?.data?.totalPage);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    handleGetAllResidents();
  }, [page, search]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 6 }}>
            <div className="card-body">
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    {"Society Management > Society > Property"}&nbsp;
                    <span style={{ color: "#000" }}>{` > Resident `}</span>
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Discussion Page"
                        onClick={() => {
                          handleGetAllResidents();
                          setpage(1);
                        }}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid style={{ display: "flex" }}>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(
                        navigateToProperty({ societyName: societyName })
                      );
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 20, justifyContent: "flex-end" }}
              >
                <div className="box">
                  <input
                    type="text"
                    placeholder="search by name"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setpage(1);
                    }}
                    value={search}
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </Grid>

              <Grid container item lg={12} style={{ marginTop: 20 }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  {handlePropertyName()} Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {propertyName}
                  </span>
                </Typography>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                {allResidents?.length > 0 && !loader && (
                  <>
                    <PropertyResidentTable
                      allResidents={allResidents}
                      page={page}
                    />
                    <Pagination
                      className={classes.pagination}
                      count={totalCount}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </>
                )}
                {allResidents?.length === 0 && !loader && (
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 19,
                      color: "#2f3aa3",
                    }}
                  >
                    No Residents
                  </Typography>
                )}
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
};

export default PropertyResident;
