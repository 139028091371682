import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  Menu,
  Checkbox,
  InputLabel,
  Box,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import AddMemberTable from "./AddMemberTable";
import { fetchSocietyId } from "../contextAPI/fetchSociety";
import * as api from "../api/index";
import { LoadingOutlined } from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BASE_URL } from "../utils/base";
import { AuthContext } from "../contextAPI/context";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { handleGetCheckModulePermission } from "../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../services/service";
import { navigateToMember } from "../Navigation/Navigations";


function AddMemberCommittee() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user,usermoduleswithpermissions } = authState;
  const [allAdmins, setallAdmins] = useState([]);
  const [allOwners, setallOwners] = useState([]);
  const [allTenants, setallTenants] = useState([]);
  const [allVendors, setallVendors] = useState([]);
  const [selectedRole, setselectedRole] = useState("all");
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const roles = ["all", "admin", "owner", "tenant", "vendor"];
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if(usermoduleswithpermissions?.length){
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Member")
      );
    }
  }, [usermoduleswithpermissions]);
  
  const getAllUser = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        role: selectedRole,
        societyId: fetchSocietyId(),
        searchKey: search,
      };
      const res = await api.getAllUserFromSociety(sendData);
      if (res?.data?.success) {
        setallAdmins(res?.data?.persons?.admins);
        setallOwners(res?.data?.persons?.owners);
        setallTenants(res?.data?.persons?.tenants);
        setallVendors(res?.data?.persons?.vendors);
      }
      setloader(false);
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error)

    }
  };

  useEffect(() => {
    getAllUser();
  }, [search]);

  useEffect(() => {
    getAllUser();
  }, [selectedRole]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}
    style={{
      filter: loader ? "blur(1px)" : "",
      pointerEvents: loader ? "none" : "",
      overflow: loader ? "hidden" : "auto",
      height: "100vh",
    }}>
      <main
        className={classes.content}
  
      >
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 5,
                  }}
                >
                  All Sociohood Users
                </Typography>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      marginRight: 8,
                    }}
                  >
                    Change Role :
                    <Select
                      value={selectedRole}
                      onChange={(event) =>{
                        setselectedRole(event.target.value)
                   
                      }}
                      style={{
                        color: "#212529",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        borderRadius: 6,
                        width: "140px",
                        height: 30,
                        fontSize: "13px",
                        padding: 8,
                        paddingLeft: 6,
                        marginLeft: 8,
                        border: "1px solid #6c757d",
                        textAlign: "center",
                      }}
                    >
                      {roles?.map((type) => {
                        return (
                          <MenuItem selected value={type}>
                            <Typography
                              style={{
                                textTransform: "capitalize",
                                textAlign: "center",
                              }}
                            >
                              {type}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Typography>
                </Grid>

                <div class="box" style={{ marginTop: "-7px" }}>
                  <input
                    type="text"
                    placeholder="search..."
                    onChange={(e) => {
                      setsearch(e.target.value);
                    }}
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #7D858D",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    // marginTop: "-7px",
                  }}
                  onClick={() => {
                    navigate(navigateToMember({}));
                    
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      Back
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid style={{ marginTop: 16, marginRight: 20 }}>
                <AddMemberTable
                  allAdmins={allAdmins}
                  allOwners={allOwners}
                  allTenants={allTenants}
                  allVendors={allVendors}
                  setOpen={setOpen}
                  setSelectedUser={setSelectedUser}
                  user={user}
                  isReadWrite={isReadWrite}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 15 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -14,
              fontWeight: "bold",
              marginTop: 6,
            }}
          >
            Loading Users....
          </Typography>
        </Grid>
      )}

      {/* INFORMATION FOR ACCESS MODULES */}
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontSize: 15,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                border: "1px solid #e6e7f0",
                height: 46,
                width: 46,
                borderRadius: 50,
                marginLeft: 15,
                marginRight: 15,
              }}
              src={
                selectedUser?.image
                  ? `${BASE_URL}/user/${selectedUser?.image}`
                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
              }
              alt="Sociohood"
            />
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {selectedUser?.name}
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 14,
              fontWeight: "bolder",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            All Permission Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              Module Name
            </Typography>

            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,

                marginBottom: 5,
                marginLeft: 30,
              }}
            >
              Permission Type
            </Typography>
          </Grid>
          <Grid>
            <List>
              {selectedUser?.permissions?.length && (
                <>
                  {selectedUser?.permissions?.map((per, index) => {
                    return (
                      <ListItem>
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography style={{ fontSize: 15, marginLeft: -17 }}>
                            {index + 1}.&nbsp;&nbsp;{per?.moduleName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 15,
                              textTransform: "capitalize",
                            }}
                          >
                            {per?.permissionType}
                          </Typography>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </>
              )}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

export default AddMemberCommittee;
