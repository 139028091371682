import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Person, TimerOff } from "@material-ui/icons";
import { HourglassOutlined } from "@ant-design/icons";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import VisitorDetailsModal from "./VisitorDetailsModal";
import { handleFlatName } from "../../../contextAPI/NamingConvention";

function VisitorLogsTable(props) {
  const classes = useStyles();
  const { entryExitLogsData, pleasewait, type, page } = props;
  const [open, setOpen] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [moreInfoVisitor, setmoreInfoVisitor] = useState([]);
  const [visitorModal, setVisitorModal] = useState(false);
  const [visitorLogData, setVisitorLogData] = useState([]);

  const handleOpenDialog = (visitor) => {
    setmoreInfoVisitor(visitor);
    setOpen(true);
  };
  const handleMoreInfoDialog = (visitor) => {
    setmoreInfoVisitor(visitor);
    setOpenMore(true);
  };

  const handleCloseDialogBox = () => {
    setOpen(false);
  };
  const handleCloseMoreInfoDialog = () => {
    setOpenMore(false);
  };

  return (
    <>
      <Grid container style={{ marginTop: 10 }}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Sr. No
                </TableCell>

                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Entry
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Exit
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Accompanying Visitor
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Visit Purpose
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {handleFlatName()} Status
                </TableCell>

                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  More Info
                </TableCell>
              </TableRow>
            </TableHead>

            {!pleasewait && (
              <TableBody>
                {entryExitLogsData?.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {(page - 1) * 50 + (index + 1)}.
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.checkInActionTime[0] ? (
                          <>
                            {moment(
                              row?.checkInActionTime[0]?.actionTime
                            ).format("DD/MM/YYYY - h:mm A")}

                            <br></br>
                            <Typography style={{ marginTop: 3, fontSize: 12 }}>
                              {row?.checkInActionTime[0]?.checkInActionBy?.name
                                ? row?.checkInActionTime[0]?.checkInActionBy
                                    ?.name
                                : "-"}
                              <span
                                style={{
                                  width: "50px",
                                  padding: 5,
                                  textTransform: "capitalize",
                                  marginLeft: 5,
                                }}
                                class="badge bg-primary"
                              >
                                {row?.checkInActionTime[0]?.checkInActionBy
                                  ?.role
                                  ? row?.checkInActionTime[0]?.checkInActionBy
                                      ?.role
                                  : "-"}
                              </span>
                            </Typography>
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.checkInActionTime[1] ? (
                          <>
                            {moment(
                              row?.checkInActionTime[1]?.actionTime
                            ).format("DD/MM/YYYY - h:mm A")}

                            <br></br>
                            <Typography style={{ marginTop: 3, fontSize: 12 }}>
                              {row?.checkInActionTime[1]?.checkInActionBy?.name
                                ? row?.checkInActionTime[1]?.checkInActionBy
                                    ?.name
                                : "-"}
                              <span
                                style={{
                                  width: "50px",
                                  padding: 5,
                                  textTransform: "capitalize",
                                  marginLeft: 5,
                                }}
                                class="badge bg-primary"
                              >
                                {row?.checkInActionTime[1]?.checkInActionBy
                                  ?.role
                                  ? row?.checkInActionTime[1]?.checkInActionBy
                                      ?.role
                                  : "-"}
                              </span>
                            </Typography>
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.groupVisitors?.length - 1 > 0
                          ? "+ " + (row?.groupVisitors?.length - 1)
                          : "0"}{" "}
                        {row?.groupVisitors?.length - 1 > 0 && (
                          <InfoIcon
                            style={{
                              color: "#2f45c5",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                            onClick={() => {
                              setVisitorLogData(row);
                              setVisitorModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.visitPurpose ? row?.visitPurpose : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.flatByStatus?.length === 1 ? (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography
                              style={{
                                color: "black",
                              }}
                            >
                              {row?.flatByStatus[0]?.status === "allowed" && (
                                <CheckCircleIcon
                                  style={{
                                    fontSize: 15,
                                    color: "#008000",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "pending" && (
                                <AccessTimeFilledIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#ff8800",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "reject" && (
                                <DangerousIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "expired" && (
                                <TimerOff
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 4,
                                  }}
                                />
                              )}

                              {row?.flatByStatus[0]?.flatNumber}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid container style={{ justifyContent: "center" }}>
                            <Typography style={{ marginRight: 4 }}>
                              {row?.flatByStatus[0]?.status === "allowed" && (
                                <CheckCircleIcon
                                  style={{
                                    fontSize: 15,
                                    color: "#008000",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "pending" && (
                                <AccessTimeFilledIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#ff8800",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "reject" && (
                                <DangerousIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.status === "expired" && (
                                <TimerOff
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 4,
                                  }}
                                />
                              )}
                              {row?.flatByStatus[0]?.flatNumber}
                            </Typography>
                            <span>
                              <Tag
                                title={`${
                                  parseInt(row?.flatByStatus?.length) - 1
                                } More ${handleFlatName()} Info`}
                                color="blue"
                                onClick={() => {
                                  handleOpenDialog(row);
                                }}
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  marginLeft: 3,
                                }}
                              >
                                {"+ " +
                                  (parseInt(row?.flatByStatus?.length) - 1)}
                                <InfoIcon
                                  title="More Info"
                                  style={{
                                    color: "#2f45c5",
                                    cursor: "pointer",
                                    marginLeft: 5,
                                    fontSize: 19,
                                  }}
                                />
                              </Tag>
                            </span>
                          </Grid>
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <InfoIcon
                          style={{
                            color: "#2f45c5",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleMoreInfoDialog(row);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {pleasewait && (
          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <Typography style={{ textAlign: "center", color: "#2f3aa3" }}>
              Loading Logs....{" "}
              <LoadingOutlined
                style={{ fontSize: 31, color: "#2f3aa3", marginLeft: 6 }}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      {open && (
        <Dialog
          open={open}
          keepMounted
          onClose={handleCloseDialogBox}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 13,
                fontWeight: "bolder",
                marginBottom: 10,
                display: "flex",
              }}
            >
              Accepted / Rejected by {handleFlatName()} owners
            </Typography>
            {type === "staff" && (
              <>
                <span
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  Action By -{" "}
                  {moreInfoVisitor?.actionBy?.name
                    ? moreInfoVisitor?.actionBy?.name
                    : "-"}
                  {moreInfoVisitor?.actionBy?.role && (
                    <span
                      style={{
                        width: "50px",
                        padding: 5,
                        textTransform: "capitalize",
                        marginLeft: 5,
                      }}
                      class="badge bg-primary"
                    >
                      {moreInfoVisitor?.actionBy?.role}
                    </span>
                  )}
                </span>
                &nbsp; &nbsp;
                <span
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  Action Time -
                  {moreInfoVisitor?.actionTime
                    ? moment(moreInfoVisitor?.actionTime).format(
                        "DD/MM/YYYY - h:mm A"
                      )
                    : "-"}
                </span>
              </>
            )}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container style={{ justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                }}
              >
                {handleFlatName()} Number
              </Typography>
              {type !== "staff" && (
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 15,
                    marginRight: 20,
                    marginBottom: 5,
                    marginLeft: 25,
                  }}
                >
                  Action By - Time
                </Typography>
              )}
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                  marginRight: 20,
                }}
              >
                {handleFlatName()} Status
              </Typography>
            </Grid>
            <Grid>
              {type === "staff" ? (
                <List>
                  {moreInfoVisitor?.flats?.map((flat) => {
                    return (
                      <ListItem>
                        <ApartmentIcon
                          style={{
                            fontSize: 15,
                            color: "#6c757d",
                            marginRight: 4,
                            marginLeft: -17,
                          }}
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography style={{ fontSize: 15 }}>
                            {flat?.number}
                          </Typography>

                          {moreInfoVisitor?.visitorStatus === "allowed" && (
                            <>
                              <Typography style={{ fontSize: 13 }}>
                                <CheckCircleIcon
                                  style={{
                                    fontSize: 15,
                                    color: "#008000",
                                    marginRight: 8,
                                    marginLeft: 50,
                                  }}
                                />
                                ALLOWED
                              </Typography>
                            </>
                          )}
                          {moreInfoVisitor?.visitorStatus === "reject" && (
                            <>
                              <Typography style={{ fontSize: 13 }}>
                                <DangerousIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 8,
                                    marginLeft: 50,
                                  }}
                                />
                                NOT ALLOWED
                              </Typography>
                            </>
                          )}

                          {moreInfoVisitor?.visitorStatus === "pending" && (
                            <>
                              <Typography style={{ fontSize: 13 }}>
                                <HourglassOutlined
                                  style={{
                                    fontSize: 17,
                                    color: "#ff8800",
                                    marginRight: 8,
                                    marginLeft: 50,
                                  }}
                                />
                                PENDING
                              </Typography>
                            </>
                          )}
                          {moreInfoVisitor?.visitorStatus === "expired" && (
                            <>
                              <Typography style={{ fontSize: 13 }}>
                                <HourglassOutlined
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 8,
                                    marginLeft: 50,
                                  }}
                                />
                                EXPIRED
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <List>
                  {moreInfoVisitor?.flatByStatus?.map((flat) => {
                    return (
                      <ListItem>
                        <ApartmentIcon
                          style={{
                            fontSize: 15,
                            color: "#6c757d",
                            marginRight: 4,
                            marginLeft: -17,
                          }}
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            style={{
                              fontSize: 15,
                              marginRight: 20,
                              marginLeft: 5,
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            {flat?.flatNumber}
                          </Typography>
                          {flat?.actionBy?.name ? (
                            <Typography
                              style={{
                                fontSize: 13,
                                textAlign: "center",
                                marginRight: 20,
                                marginLeft: 50,
                                marginBottom: 5,
                              }}
                            >
                              {flat?.actionBy?.name
                                ? flat?.actionBy?.name
                                : "-"}
                              (
                              {flat?.actionBy?.role
                                ? flat?.actionBy?.role
                                : "-"}
                              )<br></br>
                              {flat?.actionTime
                                ? moment(flat?.actionTime).format(
                                    "DD/MM/YYYY - h:mm A"
                                  )
                                : "-"}
                            </Typography>
                          ) : (
                            <Typography
                              style={{ fontSize: 13, textAlign: "center" }}
                            >
                              -
                            </Typography>
                          )}
                          {flat?.status === "allowed" && (
                            <>
                              <Typography
                                style={{
                                  fontSize: 13,
                                  marginRight: -10,
                                  marginLeft: 35,
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              >
                                <CheckCircleIcon
                                  style={{
                                    fontSize: 15,
                                    color: "#008000",
                                    marginRight: 8,
                                  }}
                                />
                                ALLOWED
                              </Typography>
                            </>
                          )}
                          {flat?.status === "reject" && (
                            <>
                              <Typography
                                style={{
                                  fontSize: 13,
                                  marginRight: -10,
                                  marginLeft: 35,
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              >
                                <DangerousIcon
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 8,
                                  }}
                                />
                                NOT ALLOWED
                              </Typography>
                            </>
                          )}

                          {flat?.status === "pending" && (
                            <>
                              <Typography
                                style={{
                                  fontSize: 13,
                                  marginRight: -10,
                                  marginLeft: 35,
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              >
                                <HourglassOutlined
                                  style={{
                                    fontSize: 17,
                                    color: "#ff8800",
                                    marginRight: 8,
                                  }}
                                />
                                PENDING
                              </Typography>
                            </>
                          )}
                          {flat?.status === "expired" && (
                            <>
                              <Typography
                                style={{
                                  fontSize: 13,
                                  marginRight: -10,
                                  marginLeft: 35,
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              >
                                <HourglassOutlined
                                  style={{
                                    fontSize: 17,
                                    color: "#D92402",
                                    marginRight: 8,
                                  }}
                                />
                                EXPIRED
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogBox}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* VISITOR MORE DETAILS BOX */}

      <Dialog
        open={openMore}
        keepMounted
        onClose={handleCloseMoreInfoDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 14,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            More details about the visitor
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontSize: 15,
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: 5,
                textDecoration: "underline",
              }}
            >
              <DirectionsCarIcon />
              Vehicle Info
            </Typography>
            {moreInfoVisitor?.vehicle?.name ||
            moreInfoVisitor?.vehicle?.vehicleType ||
            moreInfoVisitor?.vehicle?.number ? (
              <>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle Name :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.name
                      ? moreInfoVisitor?.vehicle?.name
                      : "-"}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle Type :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.vehicleType
                      ? moreInfoVisitor?.vehicle?.vehicleType
                      : "-"}
                  </span>
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Vehicle No. :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {moreInfoVisitor?.vehicle?.number
                      ? moreInfoVisitor?.vehicle?.number
                      : "-"}
                  </span>
                </Typography>
              </>
            ) : (
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 17,
                  marginTop: 7,
                  fontWeight: "bolder",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                No Vehicle Info
              </Typography>
            )}
          </Grid>
          <Grid>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontSize: 15,
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: 5,
                textDecoration: "underline",
                marginTop: 10,
              }}
            >
              <Person />
              Other Info
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              Temp :{" "}
              <span style={{ fontWeight: "bold" }}>
                {moreInfoVisitor?.temperature
                  ? moreInfoVisitor?.temperature + "° F"
                  : "-"}
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              Mask :{" "}
              <span style={{ fontWeight: "bold" }}>
                {moreInfoVisitor?.isMasked ? "YES" : "NO"}
              </span>
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMoreInfoDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <VisitorDetailsModal
          visitorModal={visitorModal}
          setVisitorModal={setVisitorModal}
          entryExitLogsData={entryExitLogsData}
          visitorLogData={visitorLogData}
        />
      </Grid>
    </>
  );
}

export default VisitorLogsTable;
