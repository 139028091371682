import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { QuestionMark } from "@mui/icons-material";
import { Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import useStyles from "../../Discussion/AllDiscussions/style";
import { ErrorOutline } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../contextAPI/context";
import * as api from "../../../api/index";
import { navigateInitialDiscussions } from "../../../Navigation/Navigations";
import { resolveErrorMessage } from "../../../services/service";

function DeleteDiscussion(props) {
  const { id } = useParams();
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const { authState } = useContext(AuthContext);
  const { user } = authState;

  const handleDeleteDiscussion = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        discussionId: id,
        userId: user?._id,
      };
      const res = await api.deleteDiscussion(sendData);
      if (res?.data?.success) {
        toast.success("Discussion Deleted Successfully");
        setOpen(false);
        navigate(navigateInitialDiscussions({}));
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  return (
    <Modal open={open}>
      <Paper
        style={{ backgroundColor: "#e6e7f0" }}
        className={classes.confirmModal}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          ARE YOU SURE YOU WANT TO DELETE THIS Discussion ?
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
        </Typography>
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 14,
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            marginTop: 5,
          }}
        >
          A confirmation message will appear when this process is complete.You
          can confirm by clicking below.
        </Typography>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setOpen(false);
              setloader(false);
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleDeleteDiscussion();
            }}
            style={{
              backgroundColor: "#1e2082",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CONFIRM
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default DeleteDiscussion;
