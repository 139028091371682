import BarChartIcon from "@mui/icons-material/BarChart";
import HelpIcon from "@mui/icons-material/Help";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  MeetingRoom,
  Category,
  NotificationImportantOutlined,
  QuestionAnswerOutlined,
  SupervisedUserCircle,
  Person,
} from "@material-ui/icons";
import { SettingOutlined, BellOutlined } from "@ant-design/icons";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PollIcon from "@mui/icons-material/Poll";
import {
  AdminPanelSettings,
  AlignHorizontalRightOutlined,
  Approval,
  BuildCircleOutlined,
  DeliveryDiningOutlined,
  DomainAddOutlined,
  Engineering,
  EscalatorWarningOutlined,
  Group,
  HailOutlined,
  PreviewRounded,
  VideoCallOutlined,
} from "@mui/icons-material";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import FaceIcon from "@mui/icons-material/Face";
import ConstructionIcon from "@mui/icons-material/Construction";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import HowToReg from "@mui/icons-material/HowToReg";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExploreIcon from "@mui/icons-material/Explore";

const allMainModules = [
  {
    moduleName: "Complaints",
    key: "complaints",
    icon: (
      <ErrorOutlineIcon
        style={{
          fontSize: 22,
        }}
      />
    ),

    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Helpdesk",
        icon: (
          <NotificationImportantOutlined
            style={{
              // color: "#ffffff",
              fontSize: 19,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/helpdesk",
      },
      {
        moduleName: "Category",
        icon: (
          <Category
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/complaintcategory",
      },
      {
        moduleName: "Area",
        icon: (
          <ExploreIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isPlant: true,
        isHideFromAccess: false,
        route: "/area",
      },
      {
        moduleName: "Approval",
        icon: (
          <Approval
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        isPlant: true,
        route: "/approval",
      },
    ],
  },
  {
    moduleName: "Communication",
    key: "communication",
    icon: (
      <GroupsIcon
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Notice",
        icon: (
          <BellOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/allnotices",
      },
      {
        moduleName: "Discussion",
        icon: (
          <QuestionAnswerOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/alldiscussions",
      },
      {
        moduleName: "Meeting",
        icon: (
          <VideoCallOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/meeting",
      },
      {
        moduleName: "Poll",
        icon: (
          <PollIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/allpoll",
      },
    ],
  },
  {
    moduleName: "Gate Management",
    key: "gatemanagement",
    icon: (
      <MeetingRoom
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Visitor",
        icon: (
          <DeliveryDiningOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/visitor",
      },
      {
        moduleName: "Staff",
        icon: (
          <HailOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/staff",
      },
      {
        moduleName: "Resider",
        icon: (
          <EscalatorWarningOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/resident",
      },
      {
        moduleName: "Settings",
        icon: (
          <RoomPreferencesIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/settings",
      },
    ],
  },
  {
    moduleName: "MIS Dashboard",
    key: "misdashboard",
    icon: (
      <BarChartIcon
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Details View",
        icon: (
          <AlignHorizontalRightOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/detailsview",
      },
      {
        moduleName: "Quick View",
        icon: (
          <PreviewRounded
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/quickview",
      },
    ],
  },
  {
    moduleName: "People",
    key: "people",
    icon: (
      <Group
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Admin",
        icon: (
          <AdminPanelSettingsIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/admins",
      },
      {
        moduleName: "Manager",
        icon: (
          <Person
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        isPlant: true,
        route: "/manager",
      },
      {
        moduleName: "Group",
        icon: (
          <Diversity2Icon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isPlant: true,
        isHideFromAccess: false,
        route: "/group",
      },
      {
        moduleName: "Resident",
        aliasName: "Users",
        icon: (
          <SupervisedUserCircle
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/approved/residents",
      },
      {
        moduleName: "Member",
        icon: (
          <AdminPanelSettings
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: true,
        route: "/membercommittee",
      },
      {
        moduleName: "Vendor",
        icon: (
          <Engineering
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/vendors",
      },
    ],
  },
  {
    moduleName: "Help",
    key: "help",
    icon: (
      <HelpIcon
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Daily Help",
        icon: (
          <FaceIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/dailyhelp",
      },
      {
        moduleName: "Emergency Number",
        icon: (
          <RecentActorsIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/emergencynumber",
      },
      {
        moduleName: "Local Directory",
        icon: (
          <DomainAddOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/localdirectory",
      },
    ],
  },
  {
    moduleName: "Settings",
    key: "settings",
    icon: (
      <SettingOutlined
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Society Type",
        icon: (
          <DomainAddOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/societytype",
      },
      {
        moduleName: "Society Manage",
        icon: (
          <BuildCircleOutlined
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/societymanagement",
      },
      // {
      //   moduleName: "Configuration Setting",
      //   icon: (
      //     <TuneIcon
      //       style={{
      //         // color: "#ffffff",
      //         fontSize: 22,
      //       }}
      //     />
      //   ),
      //   isHide: false,
      //   isHideFromAccess: false,
      //   route: "/configurationsetting",
      // },
    ],
  },
  {
    moduleName: "S2 Admin",
    key: "s2admin",
    icon: (
      <ManageAccounts
        style={{
          fontSize: 22,
        }}
      />
    ),
    isHide: false,
    isHideFromAccess: false,
    subModules: [
      {
        moduleName: "Admin Approval",
        icon: (
          <HowToReg
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/adminapproval",
      },
      {
        moduleName: "Society Request",
        icon: (
          <LocationCityIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/societyrequest/admin",
      },
      {
        moduleName: "Configuration",
        icon: (
          <ConstructionIcon
            style={{
              // color: "#ffffff",
              fontSize: 22,
            }}
          />
        ),
        isHide: false,
        isHideFromAccess: false,
        route: "/configuration",
      },
    ],
  },
];

export default allMainModules;
