import React, { useState, useContext, useEffect } from "react";
import VendorsTable from "./VendorsTable/VendorsTable";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { AuthContext } from "../../contextAPI/context";
import "./Vendors.css";
import * as api from "../../api";
import { useNavigate } from "react-router-dom";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { navigateToVendor } from "../../Navigation/Navigations";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import { Pagination } from "@mui/material";
import Loading from "../../contextAPI/Loading";

function DeletedVendorPage() {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [isReadWrite, setIsReadWrite] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [allDeleteVendor, setAllDeleteVendor] = useState([]);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Vendor"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
        isDeleted: true,
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setAllDeleteVendor(res?.data?.vendor?.vendor);
        setCount(res?.data?.vendor?.totalPage);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetVendor();
  }, [search, page]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Deleted Vendors
                </Typography>

                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(navigateToVendor({}));
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        marginTop: "-12px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      &nbsp;Back
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid style={{ marginTop: 16, marginRight: 20 }}>
                <VendorsTable
                  vendors={allDeleteVendor}
                  type="deleted"
                  isReadWrite={isReadWrite}
                  page={page}
                  setPage={setPage}
                  handleGetVendor={handleGetVendor}
                />
              </Grid>
              {allDeleteVendor?.length > 0 && (
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default DeletedVendorPage;
