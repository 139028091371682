import {
  CssBaseline,
  Typography,
  Grid,
  Button,
  Badge,
  Select,
  MenuItem,
  CardMedia,
  Modal,
  Paper,
  IconButton,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../../contextAPI/context";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import useStyles from "../AllDiscussions/style";
import * as api from "../../../api";
import { Input } from "antd";
import { Close } from "@material-ui/icons";
import { Cancel, Error } from "@material-ui/icons";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import {
  handleUploadInDirectAws,
  handleUploadInLocal,
} from "../../../utils/awsUpload";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import SearchAddress from "../../../GlobalComponents/SearchAddress/SearchAddress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LoadingOutlined } from "@ant-design/icons";

export default function AddDiscussion(props) {
  const classes = useStyles();
  const { open, setOpen, handleGetAllDiscussions, setPage } = props;
  const { authState } = useContext(AuthContext);
  const [pleasewait, setpleasewait] = useState(false);
  const { TextArea } = Input;
  const [discussionTitle, setDiscussionTitle] = useState("");
  const [discussionDesc, setDiscussionDesc] = useState("");
  const { user } = authState;
  const [errorMsg, seterrorMsg] = useState("");
  const [discussionType, setdiscussionType] = useState("societyWise");

  //selection / property Modal
  const [propertyId, setPropertyId] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  const [imageTest, setimageTest] = useState({
    data: "",
    file: "",
  });

  const [attachTest, setattachTest] = useState({
    data: "",
    file: "",
  });

  const handlereset = () => {
    seterrorMsg("");
    setDiscussionTitle("");
    setDiscussionDesc("");
    setPropertyId([]);
    setimageTest({ data: "", file: "" });
    setattachTest({ data: "", file: "" });
    setOpen(false);
  };

  const isInvalidProperty = () => {
    let invalid = false;
    propertyId?.map((p) => {
      if (!p?.societyId) {
        invalid = true;
      } else {
        invalid = false;
      }
    });
    return invalid;
  };

  const handleAddDiscussion = async () => {
    setPage(1);
    seterrorMsg("");
    setpleasewait(true);
    setTimeout(() => {
      setpleasewait(false);
    }, 40000);
    let isApi = true;
    try {
      let temp1 = [];
      let temp2 = [];
      if (discussionType === "propertyWise" && propertyId?.length === 0) {
        isApi = false;
        setpleasewait(false);
        seterrorMsg("Please select atleast one property.");
      }

      if (imageTest?.data) {
        try {
          temp1.push(await handleUploadInDirectAws(imageTest));
        } catch (error) {
          isApi = false;
          seterrorMsg(
            "Something went wrong. Image uploading failed , try again after sometimes."
          );
          setpleasewait(false);
        }
      }
      if (attachTest?.data) {
        try {
          temp2.push(await handleUploadInDirectAws(attachTest));
        } catch (error) {
          isApi = false;
          seterrorMsg(
            "Something went wrong. Image uploading failed , try again after sometimes."
          );
          setpleasewait(false);
        }
      }
      if (isInvalidProperty()) {
        isApi = false;
        setpleasewait(false);
        seterrorMsg(`Please select a valid ${handlePropertyName()}`);
      }
      if (isApi) {
        const sendData = {
          society: fetchSocietyId(),
          discussionTitle: discussionTitle,
          discussionDescription: discussionDesc,
          addedBy: user?._id,
          images: temp1,
          attachments: temp2,
          viewerType: discussionType,
          propertyId: propertyId?.map((x) => {
            return x._id;
          }),
        };
        const res = await api.addDiscussion(sendData);
        if (res?.data?.success) {
          toast.success("Discussion has been published successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          handleGetAllDiscussions();
          setpleasewait(false);
          handlereset();
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setpleasewait(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Modal open={open}>
          <Paper
            style={{
              backgroundColor: "#ffff",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
            }}
            className={classes.ModalView}
          >
            <Grid
              container
              style={{
                textAlign: "center",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <span className="page-header">Create New Discussion</span>

              <IconButton
                onClick={() => {
                  handlereset();
                }}
                className="pointer"
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            </Grid>

            <div
              className="col-sm-12 row justify-content-between scrollable"
              style={{
                maxHeight: "66vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="col-sm-6 row p-3">
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">Title</label>

                  <TextArea
                    rows={2}
                    placeholder="Write discussion title here"
                    style={{ maxWidth: "100%" }}
                    onChange={(event) => setDiscussionTitle(event.target.value)}
                    value={discussionTitle}
                    maxLength={150}
                  />
                </div>
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">Description</label>

                  <TextArea
                    rows={6}
                    placeholder="Write discussion here"
                    style={{ maxWidth: "100%" }}
                    onChange={(event) => setDiscussionDesc(event.target.value)}
                    value={discussionDesc}
                    maxLength={250}
                  />
                </div>
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">
                    Discussion Type
                    {discussionType === "propertyWise" && (
                      <span>
                        &nbsp;(&nbsp;{propertyId?.length} {handlePropertyName()}
                        &nbsp; Selected&nbsp;)&nbsp;
                      </span>
                    )}
                  </label>

                  <Select
                    value={discussionType}
                    onChange={(event) => setdiscussionType(event.target.value)}
                    style={{
                      display: "block",
                      color: "#343a40",
                      fontFamily: "Poppins, sans-serif",
                      backgroundColor: "#fff",
                      borderRadius: 6,
                      fontSize: 16,
                      padding: 4,
                      paddingLeft: 20,
                      border: "0.5px solid #979797",
                    }}
                    onClick={() => {
                      seterrorMsg("");
                    }}
                  >
                    <MenuItem selected value="societyWise">
                      Society Wise
                    </MenuItem>
                    <MenuItem
                      selected
                      value="propertyWise"
                      onClick={() => {
                        seterrorMsg("");
                      }}
                    >
                      {handlePropertyName()} Wise
                    </MenuItem>
                  </Select>
                </div>

                {discussionType === "propertyWise" && (
                  <Grid container style={{ marginTop: 5 }}>
                    <SearchAddress
                      searchKey={searchKey}
                      setsearchKey={setsearchKey}
                      setPropertyId={setPropertyId}
                      propertyId={propertyId}
                      isMultiple={true}
                    />
                  </Grid>
                )}
              </div>

              <div className="col-sm-6 row p-3">
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">
                    Images&nbsp;
                    <span className="text-secondary">(max size 1mb)</span>
                  </label>
                  <div className="col-am-12 d-flex gap-1">
                    <div className="col-sm-4">
                      {imageTest?.data ? (
                        <Badge
                          badgeContent={
                            <Cancel
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: 22,
                              }}
                              onClick={() => {
                                setimageTest({ data: "" });
                              }}
                            />
                          }
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e7f0",
                              maxWidth: "115px",
                              width: "115px",
                              height: "100px",
                              border: "1px dashed #3862DD",
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                objectFit: "scale-down",
                                maxWidth: "115px",
                                width: "115px",
                                height: "100px",
                              }}
                              image={
                                imageTest?.data
                                  ? imageTest?.data
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                              }
                              alt="Image1"
                            />
                          </div>
                        </Badge>
                      ) : (
                        <div
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "20px",
                            border: "1px dashed #3862DD",
                            maxWidth: "115px",
                            height: "100px",
                          }}
                        >
                          <label className="pointer">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                                stroke="#3862DD"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <br />
                            <span
                              style={{
                                color: "#2f45c5",
                                textAlign: "center",
                                fontSize: "8px",
                                width: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              Upload image
                            </span>
                            <input
                              type="file"
                              onChange={(event) => {
                                handleUploadInLocal(
                                  event,
                                  "image",
                                  setimageTest
                                );
                              }}
                              style={{
                                width: 0,
                                outline: 0,
                                backgroundColor: "#4b8edb",
                                height: 0,
                                marginBottom: 0,
                              }}
                              accept="image/png,image/jpeg"
                            ></input>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mb-4">
                  <label className="form-label fs-6">
                    Attachments{" "}
                    <span className="text-secondary">(max size 100kb)</span>
                  </label>

                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-4">
                      {attachTest?.data ? (
                        <Badge
                          badgeContent={
                            <Cancel
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: 22,
                              }}
                              onClick={() => {
                                setattachTest({ data: "" });
                              }}
                            />
                          }
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e7f0",
                              maxWidth: "115px",
                              width: "115px",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px dashed #3862DD",
                            }}
                          >
                            <AttachFileIcon
                              style={{
                                width: "60px",
                                height: "60px",
                                color: "#2f45c5",
                              }}
                            />
                          </div>
                        </Badge>
                      ) : (
                        <div
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "20px",
                            border: "1px dashed #3862DD",
                            maxWidth: "115px",
                            height: "100px",
                          }}
                        >
                          <label className="pointer">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                                stroke="#3862DD"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <br />
                            <span
                              style={{
                                color: "#2f45c5",
                                textAlign: "center",
                                fontSize: "8px",
                                width: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              Upload attachment
                            </span>
                            <input
                              type="file"
                              onChange={(event) => {
                                handleUploadInLocal(
                                  event,
                                  "attach",
                                  setattachTest
                                );
                              }}
                              style={{
                                width: 0,
                                outline: 0,
                                backgroundColor: "#4b8edb",
                                height: 0,
                                marginBottom: 0,
                              }}
                            ></input>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMsg && (
              <Typography
                style={{
                  marginTop: 20,
                  color: "red",
                  textAlign: "center",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                <Error /> {errorMsg}
              </Typography>
            )}
            <div className="col-sm-12 d-flex mt-2 justify-content-center gap-3">
              <Button
                style={{
                  padding: "8px",
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: "40%",
                  color: "#fff",
                }}
                disabled={pleasewait}
                variant="contained"
                size="large"
                onClick={() => {
                  handlereset();
                }}
              >
                Discard
              </Button>

              <Button
                style={{
                  padding: "8px",
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: "40%",
                  backgroundColor: "#3862DD",
                  color: "#fff",
                }}
                disabled={
                  discussionTitle === "" || discussionDesc === "" || pleasewait
                }
                variant="contained"
                size="large"
                onClick={() => {
                  handleAddDiscussion();
                }}
              >
                {pleasewait && <LoadingOutlined />}&nbsp; Post Discussion
              </Button>
            </div>
          </Paper>
        </Modal>
      </main>
    </div>
  );
}
