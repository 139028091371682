import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import MisDashboard from "../../Components/Complaints/MisDashboard";

const MisDashboardRoutes = {
  path: "/misdashboard",
  element: <NavigationDrawer />,
  children: [
    {
      path: "",
      element: <MisDashboard />
  },
    {
        path: "detailsview",
        element: <MisDashboard />
    },
    
]
};

export default MisDashboardRoutes;