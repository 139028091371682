import React, { useState, useEffect } from "react";
import {
  Apartment,
  ErrorOutline,
  Person,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import "../entrypoint.css";
import LoginSvg from "../entrypoint.svg";
import SociohoodLogo from "../../assets/SociohoodLogo.png";
import * as api from "../../api";
import { useNavigate } from "react-router-dom";
import { resolveErrorMessage } from "../../services/service";
import { toast } from "react-toastify";
import SignupSucessModal from "./SignupSucessModal";
import ChooseSocietyDropDown from "../../GlobalComponents/DropDowns/ChooseSocietyDropDown";
import { getAllSocieties } from "../../GlobalFunctions/GlobalFunctions";
import PhoneVerify from "./PhoneVerify";
import EmailVerify from "./EmailVerify";
import ApiCall from "../../api/ApiCall";
import { EntryPoints } from "../../api/Routes";

const Signup = () => {
  const navigate = useNavigate();
  const [firstStep, setfirstStep] = useState(true);
  const [secondStep, setsecondStep] = useState(false);
  const [thirdStep, setthirdStep] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loader, setLoader] = useState("");
  const [isVisible, setisVisible] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [fullname, setfullname] = useState("");
  const [societyUniqueId, setsocietyUniqueId] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [societies, setsocieties] = useState([]);
  const [phone, setphone] = useState("");

  const handlePasswordVisibility = () => {
    setisVisible(!isVisible);
  };

  const startApiCall = () => {
    seterrorMessage("");
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
  };

  const completeFirstStep = () => {
    setfirstStep(false);
    setsecondStep(true);
    setthirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setsecondStep(false);
    setthirdStep(true);
  };

  const handleReset = () => {
    setemail("");
    setsocietyUniqueId("");
    setfullname("");
    setpassword("");
    toast.success("Account Created Successfully");
    setLoader(false);
    setsuccessModal(true);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    startApiCall();
    const sendData = {
      password,
      email: email.trim().toLowerCase(),
      name: fullname,
      isBlacklisted: "false",
      role: "admin",
      societyId: societyUniqueId,
      phone: phone,
    };
    const res = await ApiCall("post", EntryPoints.signUpAdmin, sendData);
    if (res?.success) {
      handleReset();
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetSocieties = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const res = await getAllSocieties();
    setsocieties(res);
    setloader2(false);
  };
  useEffect(() => {
    handleGetSocieties();
  }, []);

  return (
    <div
      class="login-page-container"
      style={{
        opacity: loader ? 0.7 : "",
      }}
    >
      <div class="detalis-main">
        <div
          class="login-main"
          style={{ marginTop: firstStep || secondStep ? "20%" : "" }}
        >
          <img
            class="logo"
            src={SociohoodLogo}
            alt="Sociohood Technologies Pvt. Ltd."
            style={{ marginTop: -10 }}
          />

          <p
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "20px",
              marginTop: "-20px",
              textTransform: "none",
            }}
          >
            Create an Account
          </p>
          {errorMessage && (
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#e34949",
                fontSize: "13px",
                margin: 0,
                textTransform: "none",
              }}
            >
              <ErrorOutline />
              &nbsp;&nbsp;
              {errorMessage}
            </p>
          )}
        </div>
        {/* <form onSubmit={handleSignup}>
          <div class="form-detalis">
            <div class="input-main">
              <Person style={{ color: "#303f9f" }} />

              <div class="be-main"></div>
              <div class="input-box">
                <input
                  type="text"
                  class="input"
                  required
                  placeholder="Full Name"
                  onChange={(e) => {
                    setfullname(e.target.value);
                  }}
                  value={fullname}
                  maxLength={24}
                  style={{ marginLeft: "-15px" }}
                />
              </div>
            </div>
            <div class="input-main">
              <EmailOutlined style={{ color: "#303f9f" }} />

              <div class="be-main" style={{ marginLeft: "22px" }}></div>
              <div class="input-box">
                <input
                  type="email"
                  class="input"
                  required
                  placeholder="Email Address"
                  onChange={(e) => {
                    validateEmail(e);
                  }}
                  value={email}
                  style={{ marginLeft: "9px" }}
                />
              </div>

              {isEmailValid ? (
                <svg
                  class="icon-continue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              ) : (
                <>
                  <svg
                    style={{ color: "red" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      fill="red"
                    ></path>{" "}
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      fill="red"
                    ></path>{" "}
                  </svg>
                </>
              )}
            </div>
            <div class="input-main">
              <PhoneOutlined style={{ color: "#303f9f" }} />

              <div class="be-main"></div>
              <div class="input-box">
                <input
                  type="text"
                  class="input"
                  required
                  placeholder="Phone Number"
                  onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      setphone(e.target.value);
                    }
                  }}
                  value={phone}
                  pattern="^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$"
                  title="please enter a valid phone number"
                  style={{ marginLeft: "-15px" }}
                />
              </div>
            </div>
            <div class="input-main" style={{ marginTop: 15 }}>
              {isVisible ? (
                <VisibilityOutlined
                  onClick={handlePasswordVisibility}
                  style={{ color: "#303f9f", cursor: "pointer" }}
                />
              ) : (
                <VisibilityOffOutlined
                  onClick={handlePasswordVisibility}
                  style={{ color: "#303f9f", cursor: "pointer" }}
                />
              )}
              <div class="be-main"></div>
              <div class="input-box">
                <input
                  type={isVisible ? "text" : "password"}
                  required
                  class="input"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                  title="Password must be minimum eight characters, at least one letter, one number and one special character."
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  value={password}
                />
              </div>
            </div>
            <div class="input-main">
              <Apartment style={{ color: "#303f9f" }} />

              <div class="be-main"></div>
              <div class="input-box">
                <select
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 500,
                  }}
                  disabled={loader2}
                  required
                  onChange={(e) => {
                    setsocietyUniqueId(e.target.value);
                  }}
                >
                  <option value="">
                    {loader2 ? "Loading..." : "Choose Society *"}
                  </option>
                  {societies?.map((sc) => {
                    return <option value={sc?._id}>{sc?.name}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="continue"
            disabled={loader}
            style={{ opacity: loader ? 0.8 : "" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  marginTop: 10,
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {loader ? "Creating Account..." : "SIGN UP"}
              </p>
            </div>
          </button>
        </form> */}
        {firstStep && (
          <PhoneVerify
            phone={phone}
            setphone={setphone}
            seterrorMessage={seterrorMessage}
            completeFirstStep={completeFirstStep}
          />
        )}
        {secondStep && (
          <EmailVerify
            phone={phone}
            email={email}
            setemail={setemail}
            seterrorMessage={seterrorMessage}
            completeSecondStep={completeSecondStep}
          />
        )}
        {thirdStep && (
          <form onSubmit={handleSignup}>
            <div class="form-detalis">
              <div class="input-main">
                <Person style={{ color: "#303f9f" }} />

                <div class="be-main"></div>
                <div class="input-box">
                  <input
                    type="text"
                    class="input"
                    required
                    placeholder="Full Name"
                    onChange={(e) => {
                      setfullname(e.target.value);
                    }}
                    value={fullname}
                    maxLength={24}
                    style={{ marginLeft: "-15px" }}
                  />
                </div>
              </div>
              <div class="input-main" style={{ marginTop: 15 }}>
                {isVisible ? (
                  <VisibilityOutlined
                    onClick={handlePasswordVisibility}
                    style={{ color: "#303f9f", cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={handlePasswordVisibility}
                    style={{ color: "#303f9f", cursor: "pointer" }}
                  />
                )}
                <div class="be-main"></div>
                <div class="input-box">
                  <input
                    type={isVisible ? "text" : "password"}
                    required
                    class="input"
                    pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                    title="Password must be minimum eight characters, at least one letter, one number and one special character."
                    placeholder="Enter Password"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    value={password}
                  />
                </div>
              </div>
              <div class="input-main">
                <Apartment style={{ color: "#303f9f" }} />

                <div class="be-main"></div>
                <div class="input-box">
                  <select
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                    disabled={loader2}
                    required
                    onChange={(e) => {
                      setsocietyUniqueId(e.target.value);
                    }}
                  >
                    <option value="">
                      {loader2 ? "Loading..." : "Choose Society *"}
                    </option>
                    {societies?.map((sc) => {
                      return <option value={sc?._id}>{sc?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="continue"
              disabled={loader}
              style={{ opacity: loader ? 0.8 : "" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Creating Account..." : "SIGN UP"}
                </p>
              </div>
            </button>
          </form>
        )}
        <p class="signup-text" style={{ fontFamily: "Montserrat, sans-serif" }}>
          Already have an account?{" "}
          <button
            style={{
              color: "#303f9f",
              cursor: "pointer",
              border: "none",
              fontWeight: "bold",
              backgroundColor: "#f7f8ff",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign In
          </button>
        </p>
      </div>
      {successModal && (
        <SignupSucessModal
          navigate={navigate}
          successModal={successModal}
          setsuccessModal={setsuccessModal}
        />
      )}
    </div>
  );
};
export default Signup;
