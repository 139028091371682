import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Button,
  Checkbox,
} from "@material-ui/core";
import useStyles from "./style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";
import { Close } from "@material-ui/icons";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import * as api from "../../api/index";
import Loading from "../../contextAPI/Loading";
import { handlePropertyName } from "../../contextAPI/NamingConvention";

function SelectPropertyGlobal(props) {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const {
    setopenSelectPropertyModal,
    propertyId,
    setPropertyId,
    propertyNames,
    setpropertyNames,
  } = props;
  const [totalPages, settotalPages] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);
  const [allProperties, setallProperties] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  const handlePageChange = async (event, value) => {
    setpageNumber(value);
  };

  const handleGetProperties = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber,
        searchKey,
        pageLimit: 100,
      };
      const res = await api.getManagementAllProperties(sendData);
      if (res?.data?.success) {
        settotalPages(res.data?.properties?.totalPages);
        setallProperties(res.data?.properties?.properties);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleGetProperties();
  }, [searchKey, pageNumber]);

  return (
    <>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "450px",
          maxWidth: "45vw",
          filter: loader ? "blur(0.8px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Select {handlePropertyName()}
          </Typography>

          <IconButton
            onClick={() => {
              setopenSelectPropertyModal(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid>
          <>
            <Grid
              container
              style={{ marginBottom: "10px", justifyContent: "flex-end" }}
            >
              <div class="box" style={{ backgroundColor: "#fff" }}>
                <input
                  type="text"
                  placeholder="search by name"
                  autoFocus
                  onChange={(event) => {
                    setpageNumber(1);
                    setsearchKey(event.target.value);
                  }}
                  value={searchKey}
                />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
              </div>
            </Grid>
            <TableContainer
              component={Paper}
              style={{
                borderRadius: 10,
                marginRight: 10,
                height: 450,
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ height: "max-content" }}
              >
                <TableHead>
                  <TableRow container>
                    <TableCell
                      align="center"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Sr No
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Select {handlePropertyName()}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#303f9f",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {handlePropertyName()} Name / Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allProperties?.map((prop, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          {(pageNumber - 1) * 100 + (index + 1)}.
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={propertyId.includes(prop._id)}
                            key={prop._id}
                            onChange={() => {
                              if (propertyId.includes(prop._id)) {
                                var indexNo = propertyId.indexOf(prop._id);
                                var indexNo2 = propertyNames.indexOf(prop.name);
                                propertyNames.splice(indexNo2, 1);
                                propertyId.splice(indexNo, 1);
                                setpropertyNames([...propertyNames]);
                                setPropertyId([...propertyId]);
                              } else {
                                propertyId.push(prop._id);
                                propertyNames.push(prop.name);
                                setpropertyNames([...propertyNames]);
                                setPropertyId([...propertyId]);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "black",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {prop?.name} / {prop.type}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {allProperties?.length === 0 && !loader && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontSize: 17,
                        marginTop: 7,
                        fontWeight: "bolder",
                      }}
                    >
                      No {handlePropertyName()} found
                    </Typography>
                  </Grid>
                )}
              </Table>
            </TableContainer>
          </>
        </Grid>

        {loader && <Loading loader={loader} />}
        {allProperties?.length > 0 && (
          <Grid
            container
            style={{ justifyContent: "center", marginTop: "7px" }}
          >
            <Pagination
              count={totalPages}
              color="primary"
              page={pageNumber}
              onChange={handlePageChange}
            />
          </Grid>
        )}
        <Grid container style={{ justifyContent: "center", marginTop: "4px" }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              setopenSelectPropertyModal(false);
            }}
            style={{ marginTop: 20 }}
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </>
  );
}

export default SelectPropertyGlobal;
