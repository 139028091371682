import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import CategoryIcon from "@mui/icons-material/Category";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../../services/service";
import * as api from "../../../../api";
import { useParams } from "react-router";

function AddSubSubCategory({
  addSubSubCategory,
  subSubCategoryEdit,
  setaddSubSubCategory,
  setsubSubCategoryEdit,
  getComplaintSubSubCategory,
  subSubCategoryData,
}) {
  const classes = useStyles();
  const { id, subcategoryId } = useParams();
  const [subSubCategoryName, setsubSubCategoryName] = useState("");
  const [days, setdays] = useState("");
  const [loader, setloader] = useState(false);
  const [hours, sethours] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  const handleReset = () => {
    setaddSubSubCategory(false);
    setsubSubCategoryEdit(false);
    setsubSubCategoryName("");
    setdays("");
    sethours("");
    seterrorMsg("");
    setloader(false);
  };

  const handleAddSubSubCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let temp =
        24 * parseInt(days === "" ? 0 : days) +
        parseInt(hours === "" ? 0 : hours);

      if (temp <= 0) {
        setloader(false);
        return seterrorMsg("Deadline is required");
      } else if (subSubCategoryName === "") {
        setloader(false);
        return seterrorMsg("Sub-Subcategory name is required");
      } else {
        const sendData = {
          complaintCategoryId: id,
          subCategoryId: subcategoryId,
          name: subSubCategoryName,
          deadline: temp?.toString(),
          society: fetchSocietyId(),
          description: "",
        };

        const res = await api.addSubSubCategory(sendData);
        if (res?.data?.success) {
          handleReset();
          getComplaintSubSubCategory();
          toast.success("Sub-Subcategory Added Successfully");
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };
  const handleEditSubSubCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let temp =
        24 * parseInt(days === "" ? 0 : days) +
        parseInt(hours === "" ? 0 : hours);

      if (temp <= 0) {
        setloader(false);
        return seterrorMsg("Deadline is required");
      } else if (subSubCategoryName === "") {
        setloader(false);
        return seterrorMsg("Sub-Subcategory name is required");
      } else {
        const sendData = {
          subsubCategoryId: subSubCategoryData?._id,
          name: subSubCategoryName,
          description: "",
          deadline: temp?.toString(),
        };

        const res = await api.editSubSubCategory(sendData);
        if (res?.data?.success) {
          handleReset();
          getComplaintSubSubCategory();
          toast.success("Sub-Subcategory Edited Successfully");
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  useEffect(() => {
    if (subSubCategoryEdit) {
      setsubSubCategoryName(subSubCategoryData?.name);
      let tempH = parseInt(subSubCategoryData?.deadline) % 24;
      sethours(tempH.toString());
      let tempD = parseInt(subSubCategoryData?.deadline) - tempH;
      let temp2 = tempD / 24;
      setdays(temp2.toString());
    }
  }, [subSubCategoryEdit]);

  return (
    <Modal open={addSubSubCategory || subSubCategoryEdit}>
      <Paper
        className={classes.ModalView}
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          // width: "29vw",
          height: "auto",
          minWidth: "330px",
          maxWidth: "29vw",
        }}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {subSubCategoryEdit
              ? "Edit Sub-Subcategory"
              : "Add Sub-Subcategory"}
          </Typography>
          <IconButton
            onClick={() => {
              if (!loader) {
                handleReset();
              }
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}{" "}
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",

              marginBottom: 2,
            }}
          >
            Enter a name
          </Typography>
          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 10,
              outline: 0,
              marginTop: 6,
            }}
            onChange={(event) => setsubSubCategoryName(event.target.value)}
            placeholder="Sub-Subcategory Name"
            value={subSubCategoryName}
          />
        </Grid>
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 2,
                marginLeft: 13,
              }}
            >
              Deadline in Days
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 2,
                marginLeft: 13,
              }}
            >
              Deadline in Hours
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdays(event.target.value)}
              placeholder="Enter Days"
              value={days}
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => sethours(event.target.value)}
              placeholder="Enter Hours"
              value={hours}
            />
          </Grid>
        </Grid>
        <Typography
          style={{
            fontWeight: "bold",
            color: "#283f59",
            fontSize: "11px",
            textAlign: "right",
            marginBottom: 8,
            marginRight: 8,
          }}
        >
          Total Deadline Hours : &nbsp;
          {24 * parseInt(days === "" ? 0 : days) +
            parseInt(hours === "" ? 0 : hours)}{" "}
          Hours
        </Typography>

        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "200px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (!loader && !subSubCategoryEdit) {
                    handleAddSubSubCategory();
                  }
                  if (!loader && subSubCategoryEdit) {
                    handleEditSubSubCategory();
                  } else {
                    //NOTHING HAPPENED
                  }
                }}
              >
                {subSubCategoryEdit ? (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <CategoryIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Edit Subcategory" : `Editing...`}
                  </>
                ) : (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <CategoryIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Add Sub-Subcategory" : `Adding...`}
                  </>
                )}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddSubSubCategory;
