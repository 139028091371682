import React from "react";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import Configuration from "../../S2Admin/Configuration/Configuration";

const ConfigurationRoutes = {
  path: "/s2admin",
  element: <NavigationDrawer />,
  children: [
    {
      path: "configuration",
      element: <Configuration />,
    },

 
  ],
};
export default ConfigurationRoutes;
