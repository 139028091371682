import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@material-ui/core";
import { Grid, Paper, Button, Avatar } from "@material-ui/core";
import useStyles from "../styles";
import { TablePagination } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Tag } from "antd";
import moment from "moment";
import { HourglassOutlined, LoadingOutlined } from "@ant-design/icons";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import HailIcon from "@mui/icons-material/Hail";
import { BASE_URL } from "../../../utils/base";
import { toast } from "react-toastify";
import { navigateToLog } from "../../../Navigation/Navigations";

function ResidentTable(props) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const { resident, pleasewait } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Sr. No
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Resident Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Contact No
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                IN / OUT By
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Entry Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Exit Time
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Address
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                IN / OUT
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Temp
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Mask
              </TableCell>
            </TableRow>
          </TableHead>

          {!pleasewait && (
            <TableBody>
              {resident
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  if (row.entryTime) {
                    var entryTime = moment(row.entryTime).format(
                      "DD/MM/YYYY - h:mm A"
                    );
                  }
                  if (row.exitTime) {
                    var exitTime = moment(row?.exitTime).format(
                      "DD/MM/YYYY - h:mm A"
                    );
                  }
                  if (row.createdAt) {
                    var createdAt = moment(row?.createdAt).format(
                      "DD/MM/YYYY - h:mm A"
                    );
                  }

                  return (
                    <TableRow key={row?._id}>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {rowsPerPage * page - 1 + index + 2}.
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          cursor: "pointer",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar
                            onClick={() => {
                              navigate(
                                navigateToLog({
                                  type: "resident",
                                  id: row._id,
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            alt={row.name}
                            src={
                              row.image
                                ? `${BASE_URL}/user/${row.image}`
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            className={classes.avatar}
                          />
                        </Grid>
                        <Typography
                          onClick={() => {
                            navigate(
                              navigateToLog({
                                type: "resident",
                                id: row._id,
                              })
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                          className={classes.textname}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.phone}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.checkInActionBy?.name
                          ? row?.checkInActionBy?.name
                          : "-"}
                        {row?.checkInActionBy?.role && (
                          <span
                            style={{
                              width: "50px",
                              padding: 5,
                              textTransform: "capitalize",
                              marginLeft: 5,
                            }}
                            class="badge bg-primary"
                          >
                            {row?.checkInActionBy?.role}
                          </span>
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {entryTime ? entryTime : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {exitTime ? exitTime : "-"}
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          minWidth: 180,
                        }}
                      >
                        {row?.flat
                          ? row?.property?.name +
                            "-" +
                            row?.floor?.name +
                            "-" +
                            row?.flat?.number
                          : "-"}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.checkInStatus === "in" && (
                          <Tag
                            style={{
                              cursor: "pointer",
                              padding: 6,
                              width: "100px",
                              fontSize: "11px",
                              marginBottom: 15,
                              fontWeight: "bold",
                            }}
                            color="green"
                            icon={
                              <DirectionsWalkIcon
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                              />
                            }
                          >
                            IN
                          </Tag>
                        )}
                        {row?.checkInStatus === "out" && (
                          <Tag
                            style={{
                              cursor: "pointer",
                              padding: 6,
                              width: "100px",
                              fontSize: "11px",
                              marginBottom: 15,
                              fontWeight: "bold",
                            }}
                            color="red"
                            icon={
                              <HailIcon
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                              />
                            }
                          >
                            OUT
                          </Tag>
                        )}
                        {row?.checkInStatus === "waiting" && (
                          <Tag
                            style={{
                              cursor: "pointer",
                              padding: 6,
                              width: "110px",
                              fontSize: "11px",
                              marginBottom: 15,
                              fontWeight: "bold",
                            }}
                            color="#ff8800"
                            icon={
                              <HourglassOutlined
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                              />
                            }
                          >
                            WAITING
                          </Tag>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.temperature ? row?.temperature + "° F" : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row?.isMasked ? (
                          <Tag color="#87d068">Yes</Tag>
                        ) : (
                          <Tag color="#cd201f">No</Tag>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {resident?.length > 25 && !pleasewait && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={resident?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {!resident?.length && !pleasewait && (
        <Grid container style={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 17,
              marginTop: 7,
              fontWeight: "bolder",
            }}
          >
            No Residents
          </Typography>
        </Grid>
      )}

      {pleasewait && (
        <Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <LoadingOutlined
              style={{ fontSize: 31, color: "#2f3aa3", marginTop: 10 }}
            />
          </Grid>
          <Typography style={{ textAlign: "center", color: "#2f3aa3" }}>
            Loading Residents....
          </Typography>
        </Grid>
      )}
    </>
  );
}

export default ResidentTable;
