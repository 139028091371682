import React, { useState } from "react";
import useStyles from "../styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Paper } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteOutlined } from "@material-ui/icons";
import { Modal } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import * as api from "../../../../api";
import { resolveErrorMessage } from "../../../../services/service";

function SubSubCategoryTable({
  isReadWrite,
  getComplaintSubSubCategory,
  allSubSubCategory,
  setsubSubCategoryEdit,
  setsubSubCategoryData,
  page,
}) {
  const classes = useStyles();
  const [loader, setloader] = useState(false);

  const handleDeleteSubSubCategoryModal = (subsubcategory, isDeleted) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${subsubcategory?.name}?`,
      okText: loader ? (
        <LoadingOutlined style={{ color: "#fff" }} />
      ) : (
        "Confirm"
      ),
      cancelText: "Cancel",
      onOk() {
        handleSubSubCategoryDelete(subsubcategory);
      },
    });
  };

  const handleSubSubCategoryDelete = async (subsubcategory) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        subsubCategoryId: subsubcategory?._id,
      };
      const res = await api.deleteSubSubCategory(sendData);
      if (res?.data?.success) {
        toast.success("Sub Sub-Category Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        getComplaintSubSubCategory();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Sr. No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Deadline
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allSubSubCategory?.map((subsubcategory, index) => {
            return (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {subsubcategory?.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {subsubcategory?.deadline} hours
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <TableCell
                    className="d-flex justify-content-center"
                    style={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <span
                      className="badge bg-primary"
                      onClick={() => {
                        setsubSubCategoryData(subsubcategory);
                        setsubSubCategoryEdit(true);
                      }}
                      style={{ width: "50px", padding: 8, cursor: "pointer" }}
                    >
                      <EditIcon style={{ fontSize: "16px" }} />
                    </span>

                    <span
                      className="badge bg-danger ms-2"
                      onClick={() => {
                        handleDeleteSubSubCategoryModal(subsubcategory, true);
                      }}
                      style={{
                        width: "50px",
                        padding: 8,
                        cursor: "pointer",
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: "16px" }} />
                    </span>
                  </TableCell>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SubSubCategoryTable;
