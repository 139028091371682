import { Avatar, Grid, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";

const PerformanceCounts = ({
  vendorPerformanceData,
  complaintCounts,
  mostRaisedCategory,
  mostRaisedProperty,
  categoryName,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container item spacing={2} xs={3} style={{ marginBottom: 10 }}>
        <Grid item xs={12}>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 200,
              marginTop: 33,
              padding: "6px 14px",
            }}
          >
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Highest Rated Vendor
            </Typography>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 10 }}
            >
              <div className="d-flex gap-2">
                <Avatar
                  className={classes.avatar}
                  src={
                    vendorPerformanceData?.highestRatedVendor?.[0]?.image
                      ? vendorPerformanceData?.highestRatedVendor?.[0]?.image
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt={vendorPerformanceData?.highestRatedVendor?.[0]?.name}
                  height="40px"
                  width="40px"
                />
                <div className="d-flex flex-column">
                  <Typography
                    style={{
                      color: "#141414",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    {vendorPerformanceData?.highestRatedVendor?.[0]?.name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {
                      vendorPerformanceData?.highestRatedVendor?.[0]?.category
                        ?.categoryName
                    }
                  </Typography>
                </div>
              </div>
              <div>
                <Typography
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "end",
                  }}
                >
                  {vendorPerformanceData?.highestRatedVendor?.[0]?.averageRating?.toFixed(
                    2
                  )}
                  <br />
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.24325 7.33992L1.86325 8.26492L1.75025 8.28792C1.57919 8.33333 1.42325 8.42333 1.29835 8.54872C1.17344 8.67412 1.08406 8.83041 1.03932 9.00165C0.994575 9.17289 0.996081 9.35293 1.04368 9.5234C1.09128 9.69386 1.18327 9.84864 1.31025 9.97192L5.93225 14.4709L4.84225 20.8259L4.82925 20.9359C4.81878 21.1128 4.85552 21.2894 4.9357 21.4474C5.01589 21.6055 5.13664 21.7394 5.28559 21.8354C5.43454 21.9315 5.60634 21.9862 5.7834 21.994C5.96046 22.0018 6.13642 21.9625 6.29325 21.8799L11.9993 18.8799L17.6923 21.8799L17.7923 21.9259C17.9573 21.9909 18.1367 22.0109 18.312 21.9837C18.4873 21.9565 18.6522 21.8832 18.7898 21.7712C18.9275 21.6592 19.0328 21.5127 19.0951 21.3466C19.1574 21.1804 19.1743 21.0008 19.1443 20.8259L18.0533 14.4709L22.6773 9.97092L22.7553 9.88592C22.8667 9.74869 22.9397 9.58438 22.967 9.40972C22.9942 9.23506 22.9747 9.0563 22.9103 8.89165C22.846 8.72701 22.7392 8.58235 22.6007 8.47244C22.4623 8.36252 22.2972 8.29126 22.1223 8.26592L15.7423 7.33992L12.8903 1.55992C12.8077 1.39246 12.68 1.25144 12.5214 1.15283C12.3629 1.05422 12.1799 1.00195 11.9933 1.00195C11.8066 1.00195 11.6236 1.05422 11.4651 1.15283C11.3065 1.25144 11.1788 1.39246 11.0963 1.55992L8.24325 7.33992Z"
                      fill="#FAD02D"
                    />
                  </svg>
                </Typography>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Total Complaints
              </Typography>
              <Typography
                style={{
                  color: "#0C8CE9",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceData?.complaints}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Within SLA
              </Typography>
              <Typography
                style={{
                  color: "#00C172",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceData?.SLAData?.withinSLA}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Beyond SLA
              </Typography>
              <Typography
                style={{
                  color: "#EE383D",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceData?.SLAData?.beyondSLA}
              </Typography>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 200,
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Total Complaints - {categoryName}
            </Typography>
            <Typography
              style={{
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                textAlign: "center",
                marginTop: 15,
              }}
            >
              {complaintCounts?.totalComplaints}
            </Typography>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 15 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Assigned
              </Typography>
              <Typography
                style={{
                  color: "#0C8CE9",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalAssignedComplaints}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Resolved
              </Typography>
              <Typography
                style={{
                  color: "#00C172",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalResolvedComplaints}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Closed
              </Typography>
              <Typography
                style={{
                  color: "#EE383D",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalClosedComplaints}
              </Typography>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 180,
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Most Raised Category
            </Typography>
            {mostRaisedCategory?.map((data) => {
              return (
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 20 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    {data?.name?.categoryName}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0C8CE9",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {data?.count}
                  </Typography>
                </div>
              );
            })}
          </div>

          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 180,
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Most Raised {handlePropertyName()}
            </Typography>
            {mostRaisedProperty?.map((data) => {
              return (
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 20 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    {data?.property_details?.name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0C8CE9",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {data?.count}
                  </Typography>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceCounts;
