import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Paper, Avatar } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import { TablePagination } from "@mui/material";

function AdminTable(props) {
  const classes = useStyles();
  const {
    admins,
    loader,
    setaddManager,
    setremoveManager,
    setmanagerData,
    isReadWrite,
    plant,
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {admins?.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow container>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Sr No.
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Created Date
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Contact
                </TableCell>
                {isReadWrite && plant && (
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                  >
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {admins
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  var date = moment(row.createdAt).format(
                    "DD/MM/YYYY - h:mm A"
                  );

                  return (
                    <TableRow key={row._id}>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {rowsPerPage * page - 1 + (index + 2)}.
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {date}
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid container style={{ justifyContent: "center" }}>
                          <Avatar alt={row.name} className={classes.avatar} />
                        </Grid>
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {row.phone}
                      </TableCell>
                      {isReadWrite && plant && (
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Poppins, sans-serif",
                            cursor: "pointer",
                          }}
                          className={`${
                            row?.isManager ? "text-danger" : "text-primary"
                          } fw-bold`}
                          onClick={() => {
                            if (row?.isManager) {
                              setmanagerData(row);
                              setremoveManager(true);
                            } else {
                              setmanagerData(row);
                              setaddManager(true);
                            }
                          }}
                        >
                          {row?.isManager ? "Remove Manager" : "Make Manager"}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {admins?.length >= 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={admins?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {admins?.length === 0 && !loader && (
        <Grid container style={{ justifyContent: "center" }}>
          <section
            style={{
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: "2rem",
                textTransform: "uppercase",
                textAlign: "center",
                color: "#4b8edb",
                marginTop: "-40px",
              }}
            >
              NO ADMINS
            </h1>
          </section>
        </Grid>
      )}
    </>
  );
}

export default AdminTable;
