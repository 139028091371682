import { Grid, Paper, Tooltip } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Cancel, CheckCircle } from "@material-ui/icons";

function ManagerPendingTable({
  setaddManager,
  setmanagerData,
  setremoveManager,
  allManager,
  page,
  isReadWrite,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow container>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Sr No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Time
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Email
            </TableCell>
            <TableCell
              className={classes.tableHeaderCell}
              align="center"
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Phone
            </TableCell>
            {isReadWrite && (
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {allManager?.map((manager, index) => {
            return (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {(page - 1) * 50 + (index + 1)}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {manager?.name}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {moment(manager?.createdAt).format("DD/MM/YYYY - h:mm A")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {manager?.email ? manager?.email : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {manager?.phone}
                </TableCell>
                {isReadWrite && (
                  <TableCell
                    align="center"
                    sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                  >
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="Approve Request" placement="top" arrow>
                        <CheckCircle
                          onClick={() => {
                            setmanagerData(manager);
                            setaddManager(true);
                          }}
                          style={{
                            color: "green",
                            fontSize: "20px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        onClick={() => {
                          setmanagerData(manager);
                          setremoveManager(true);
                        }}
                        title="Reject Request"
                        placement="top"
                        arrow
                      >
                        <Cancel
                          style={{
                            color: "red",
                            fontSize: "20px",
                            marginLeft: 4,
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ManagerPendingTable;
