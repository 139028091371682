import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Paper,
  Grid,
  Divider,
  Modal,
  IconButton,
} from "@material-ui/core";
import useStyles from "./style";
import moment from "moment";
import { BASE_URL } from "../../../utils/base";
import * as api from "../../../api/index";
import { AuthContext } from "../../../contextAPI/context";
import { useNavigate } from "react-router-dom";
import { CheckCircle, Close, ErrorOutline } from "@material-ui/icons";
import { VolunteerActivism } from "@mui/icons-material";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { Tag } from "antd";
import { TimerOff } from "@material-ui/icons";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import { navigateToPollDetails } from "../../../Navigation/Navigations";

function Poll(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pollData, handleGetPoll, setIndividualVoters, isReadWrite, page } =
    props;
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const [openVoteModal, setOpenVoteModal] = useState(false);
  const [selectedOption, setselectedOption] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const [countDown, setcountDown] = useState(1000);

  const localPageNumber = parseInt(localStorage.getItem("pollPageNumber"))
    ? parseInt(localStorage.getItem("pollPageNumber"))
    : page;

  const handleTimer = (today, expireDay) => {
    let s = new Date(expireDay.toLocaleString());
    let t = new Date(s) - new Date(today);
    setcountDown(t);
  };

  // Random component
  const Completionist = () => (
    <Tag
      style={{
        cursor: "pointer",
        padding: 6,
        width: "112px",
        fontSize: "11px",
        fontWeight: "bold",
      }}
      color="green"
      icon={
        <CheckCircle
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        />
      }
    >
      &nbsp; COMPLETED
    </Tag>
  );

  useEffect(() => {
    handleTimer(new Date(), pollData?.expiredInDate);
  }, []);

  const handleSelectedVote = async () => {
    // setPage(1);
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      if (selectedOption?.length === 0) {
        seterrorMsg("Please Select an option first");
        setloader(false);
      } else {
        let sendData = {
          pollId: pollData?._id,
          optionId: selectedOption?._id,
          userId: user?._id,
        };
        const res = await api.getVotedBy(sendData);
        if (res?.data?.success) {
          setselectedOption([]);
          setOpenVoteModal(false);
          handleGetPoll();
          setloader(false);
          toast.success(`Successfully Voted to ${selectedOption?.name}`);
        }
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleGetOptionData = async (optionId) => {
    try {
      let sendData = {
        pollId: pollData?._id,
        optionId: optionId,
      };
      const res = await api.getVotersFromOption(sendData);
      if (res?.data?.success) {
        setIndividualVoters(res?.data?.poll);
      }
    } catch (error) {
      console.log(error);
      resolveErrorMessage(error);
    }
  };

  return (
    <div className="col-md-4">
      <div
        className="card"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          borderRadius: "10px",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between gap-3">
              <div className="d-flex align-items-center gap-2">
                <img
                  style={{
                    cursor: "pointer",
                    border: "1px solid #e6e7f0",
                    height: 36,
                    width: 36,
                    borderRadius: 50,
                  }}
                  src={
                    pollData?.createdBy?.image
                      ? `${BASE_URL}/user/${pollData?.createdBy?.image}`
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt="Sociohood"
                />
                <div>
                  <p className="mb-0 text-poppins text-navy text-capitalize fw-bold">
                    {pollData?.createdBy?.name?.length > 18
                      ? pollData?.createdBy?.name.slice(0, 18).concat("...")
                      : pollData?.createdBy?.name}
                  </p>
                  <p className="mb-0 text-poppins fs-sm">
                    {moment(pollData?.createdAt).format("DD/MM/YYYY - h:mm A")}
                  </p>
                </div>
              </div>
              <p className="mb-0 d-flex align-items-center gap-1 text-poppins">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                    stroke="#5E5E5E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {pollData?.viewerType === "societyWise" ? (
                  <>Society Wise</>
                ) : (
                  <>
                    {pollData?.properties?.length}&nbsp;{handlePropertyName()}
                  </>
                )}
              </p>
            </div>

            <div>
              <p className="mb-0 text-navy text-poppins fw-bold">
                {pollData?.title?.length > 28
                  ? pollData?.title.slice(0, 28).concat("...")
                  : pollData?.title}
              </p>
              <div
                className="scrollable"
                style={{ height: "210px", overflow: "auto" }}
              >
                {pollData?.options?.map(function (option, i) {
                  return (
                    <div
                      className="pollmaincard-option pointer"
                      id={`pollmaincard-option-${i}`}
                      onClick={() => {
                        if (isReadWrite) {
                          setOpenVoteModal(true);
                        }
                      }}
                    >
                      <span className="pollmaincard-option-text">
                        {option?.name?.length > 28
                          ? option?.name.slice(0, 28).concat("...")
                          : option?.name}
                      </span>
                      <span
                        className="pollmaincard-percentage-bar"
                        style={{ width: `${option?.percentage}%` }}
                      ></span>
                      <span className="pollmaincard-percentage-value">
                        {option?.percentage}%
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between gap-3">
              <p className="mb-0 fs-sm text-poppins">
                {pollData?.isExpired ? (
                  <Completionist></Completionist>
                ) : (
                  <>
                    Ends at -&nbsp;
                    <TimerOff
                      style={{
                        fontSize: 15,
                        color: "#D92402",
                        marginRight: 4,
                      }}
                    />
                    {moment(pollData?.expiredInDate).format(
                      "DD/MM/YYYY - h:mm A"
                    )}
                  </>
                )}
              </p>

              <span
                onClick={() => {
                  navigate(navigateToPollDetails({ pollId: pollData?._id }));
                }}
                className="btn p-0 rounded-circle"
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="62" height="62" rx="31" fill="#3862DD" />
                  <path
                    d="M15.042 31H45.9587M45.9587 31L32.7087 43.5M45.9587 31L32.7087 18.5"
                    stroke="white"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal open={openVoteModal}>
        <Paper
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                color: "#000",
                textAlign: "center",
                fontWeight: "bold",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Choose an option and Vote
            </Typography>

            {!loader && (
              <IconButton
                onClick={() => {
                  setOpenVoteModal(false);
                  seterrorMsg("");
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              backgroundColor: "#000",
            }}
          />
          <Typography
            style={{
              color: "#2f2f2f",
              textAlign: "start",
              marginLeft: 5,
              fontSize: 17,
              opacity: "50%",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Select an option from below to cast your vote.
          </Typography>
          <Grid
            class="poll"
            style={{
              marginTop: 10,
              top: "0%",
              left: " 0%",
              transform: "translate(0%,0%)",
              background: "#ffff",
              borderRadius: "15px",
              height: "36vh",
              overflowY: "auto",
            }}
          >
            <Grid
              class="question"
              style={{
                padding: "10px",
                color: "#34568B",
                fontSize: "1.2em",
              }}
            >
              {pollData?.title}
            </Grid>
            <Grid class="answers" style={{ padding: "20px" }}>
              {pollData?.options?.map(function (option, i) {
                return (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      class="answer"
                      style={{
                        position: "relative",
                        minWidth: "100%",
                        maxWidth: "100%",
                        height: "40px",
                        lineHeight: " 40px",
                        padding: "0 20px",
                        border: `${
                          selectedOption?._id === option._id
                            ? "2px solid rgba(20,167,62,1)"
                            : "1px solid #d4d4d4"
                        }`,
                        cursor: "pointer",
                        overflow: "hidden",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setselectedOption([]);
                        setselectedOption(option);
                      }}
                    >
                      {option?.name}
                      <span
                        class="percentage_bar"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: `${option?.percentage}%`,
                          height: " 100%",
                          background: "rgba(3, 102, 214, 0.3)",
                          zIndex: -2,
                          transition: "width 200ms ease-in-out",
                        }}
                      ></span>
                      <span
                        class="percentage_value"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: 0,
                          transform: "translateY(-50%)",
                          width: "40px",
                          color: "#111",
                          fontSize: "15px",
                        }}
                      >
                        {option?.percentage}%
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {selectedOption?.name && (
            <Typography
              style={{
                color: "rgba(20,167,62,1)",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 10,
                fontWeight: "bold",
              }}
            >
              <CheckCircle />
              &nbsp;Selected Option : {selectedOption?.name}
            </Typography>
          )}

          {errorMsg && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMsg}
            </Typography>
          )}
          {!pollData?.isExpired && !pollData?.isDeleted && (

          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 35,
                boxShadow: "0 3px 6px #7D858D",
                width: "147px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginRight: 12,
                marginTop: 14,
              }}
              onClick={() => {
                handleSelectedVote();
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-10px",
                    textAlign: "center",
                  }}
                >
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <VolunteerActivism
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}

                  {!loader ? "Submit Vote" : `Submitting...`}
                </p>
              </div>
            </div>
          </Grid>
          )}
        </Paper>
      </Modal>
    </div>
  );
}

export default Poll;
