import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import { AddCircle, Close, ErrorOutline } from "@material-ui/icons";
import { Paper } from "@mui/material";
import React from "react";
import ChooseCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import ChooseSubCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseSubCategoryDropDown";


export default function CategoryModalForMultipleComplaint(props) {
  const {
    addSubMoreComplaintModal,
    seterrorMsg3,
    setCategory,
    setSubCategory,
    subCategory,
    setcomplainDesc,
    setaddSubMoreComplaintModal,
    loader,
    category,
    searchKey,
    setsearchKey,
    complainDesc,
    errorMsg3,
    handleAddMoreComplaint,
    classes,
    TextArea
  } = props;
  return (
    <Modal open={addSubMoreComplaintModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "330px",
          maxWidth: "32vw",
          overflow: "auto",
          left: "55%",
        }}
        className={classes.smallModal}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Category for multiple complaint
          </Typography>

          <IconButton
            onClick={() => {
              seterrorMsg3("");
              setCategory([]);
              setSubCategory([]);
              setcomplainDesc("");
              setaddSubMoreComplaintModal(false);
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        <Grid container style={{ marginBottom: 6, marginTop: 6 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 4,
                marginLeft: 5,
              }}
            >
              Choose Category*
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 4,
                marginLeft: 5,
              }}
            >
              Choose Sub Category*
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 4 }}>
          <Grid container item xs={6} style={{ justifyContent: "center" }}>
            <ChooseCategoryDropDown
              setCategory={setCategory}
              category={category}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
            />
          </Grid>
          <Grid container item xs={6} style={{ justifyContent: "center" }}>
            <ChooseSubCategoryDropDown
              setSubCategory={setSubCategory}
              subCategory={subCategory}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              category={category}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 5,
                marginLeft: 5,
                marginBottom: 4,
              }}
            >
              Complaint Description*
            </Typography>
            <TextArea
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              value={complainDesc}
              onChange={(event) => setcomplainDesc(event.target.value)}
              placeholder="Enter Description Here.."
              maxLength={250}
            />
          </Grid>
        </Grid>
        {errorMsg3 && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg3}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "100px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={() => {
              if (
                category?.length ||
                subCategory?.length ||
                complainDesc === ""
              ) {
                seterrorMsg3("all * fields are required");
              } else {
                handleAddMoreComplaint("");
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                }}
              >
                <AddCircle
                  style={{
                    marginRight: 10,
                    fontSize: "15px",
                  }}
                />
                Add
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}
