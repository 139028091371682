import React, { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import Loading from "../../../contextAPI/Loading";
import * as api from "../../../api";
import { Grid, Typography } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import SearchIcon from "@mui/icons-material/Search";
import SubVendorTable from "./SubVendorTable";
import { Pagination } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import VendorCategory from "../VendorCategory";
import { navigateToVendor } from "../../../Navigation/Navigations";
import { Add, KeyboardBackspaceRounded } from "@material-ui/icons";
import AddSubVendor from "../AddVendor/AddSubVendor";
import EditVendor from "../EditVendor/EditVendor";

function SubVendor() {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addSubVendorModal, setaddSubVendorModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [search, setsearch] = useState("");
  const [allSubVendor, setallSubVendor] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [categoryModal, setcategoryModal] = useState(false);
  const [editVendorModal, seteditVendorModal] = useState(false);

  const handleCloseDialog = () => {
    setcategoryModal(false);
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const checkPermissions = async () => {
      if (usermoduleswithpermissions?.length) {
        const result = await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Vendor"
        );
        setIsReadWrite(result);
      }
    };

    checkPermissions();
  }, [usermoduleswithpermissions]);

  const handleGetSubVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
        approvalStatus: "approved",
        isSubVendor: true,
        mainVendorId: id,
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setallSubVendor(res?.data?.vendor?.vendor);
        setCount(res?.data?.vendor?.totalPage);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetSubVendor();
  }, [page]);

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        handleGetSubVendor();
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      handleGetSubVendor();
    }
  }, [search, 500]);

  return (
    <div
      className={classes.root}
      style={{
        opacity: loader ? 0.4 : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid container>
                <Grid item lg={6} sm={6} md={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Sub-Vendor Management
                    {!loader ? (
                      <RefreshIcon
                        title="refresh request Page"
                        onClick={() => {
                          handleGetSubVendor();
                        }}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{
                          marginLeft: 5,
                          color: "#000",
                          marginTop: -4,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    className="card ms-2 me-2"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "155px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setaddSubVendorModal(true);
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <Add style={{ marginRight: 5, fontSize: "15px" }} />
                        &nbsp;Add Sub Vendor
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "100px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(navigateToVendor({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <KeyboardBackspaceRounded
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        BACK
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 20, justifyContent: "flex-end" }}
              >
                <Grid>
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search..."
                      onChange={(e) => {
                        setsearch(e.target.value);
                        setPage(1);
                      }}
                    />
                    <div className="searchIcon">
                      <SearchIcon onClick={handleGetSubVendor} />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {allSubVendor?.length > 0 && (
                <Grid style={{ marginTop: 16, marginRight: 20 }}>
                  <SubVendorTable
                    allSubVendor={allSubVendor}
                    isReadWrite={isReadWrite}
                    type="all"
                    page={page}
                    setVendor={setVendor}
                    setcategoryModal={setcategoryModal}
                    seteditVendorModal={seteditVendorModal}
                    handleGetSubVendor={handleGetSubVendor}
                  />
                </Grid>
              )}
              {allSubVendor?.length > 0 && (
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              {categoryModal && (
                <VendorCategory
                  categoryModal={categoryModal}
                  handleCloseDialog={handleCloseDialog}
                  vendor={vendor}
                />
              )}
              {!loader && allSubVendor?.length === 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <section
                    style={{
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h1
                      style={{
                        color: "#6c757d",
                        textAlign: "center",
                        fontSize: 22,
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      NO SUB-VENDORS
                    </h1>
                  </section>
                </Grid>
              )}
              <AddSubVendor
                addSubVendorModal={addSubVendorModal}
                setaddSubVendorModal={setaddSubVendorModal}
                handleGetVendor={handleGetSubVendor}
              />
              {editVendorModal && (
                <EditVendor
                  editVendorModal={editVendorModal}
                  seteditVendorModal={seteditVendorModal}
                  vendor={vendor}
                  handleGetVendor={handleGetSubVendor}
                />
              )}
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading />}
    </div>
  );
}

export default SubVendor;
