import React from "react";
import { Col, Row } from "antd";
import Logo from "../../../assets/SociohoodLogo.png";
import moment from "moment";
import styles from "../Invoice/styles/form.module.css";
import { Typography, Grid, Divider } from "@material-ui/core";

const PrintComplaintDetails = React.forwardRef((props, ref) => {
  const { complaint, isPlant } = props;
  var raisedDate = moment(complaint.createdAt).format("DD/MM/YYYY - h:mm:ss A");
  return (
    <>
      <div ref={ref} style={{ padding: 20 }}>
        <Row>
          <Col>
            <img src={Logo} />
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: 32 }}>
          <Col span={10}>
            <h3>Sociohood building</h3>
            <div>M6, Vijay Aman Apartment Main Road, Kankarbagh</div>
            <div>Patna Bihar 800020 India</div>
            <a href="https://www.sociohood.com">www.sociohood.com</a>
          </Col>
          <Col span={10} offset={4}>
            <table className={styles.invoice}>
              <tr>
                <th>Raised Date : </th>
                <td>&nbsp;{raisedDate}</td>
              </tr>

              <tr>
                <th>Complaint No. : </th>
                <td>&nbsp;{complaint?.complaintNumber}</td>
              </tr>
              <tr>
                <th>Complainer : </th>
                <td>
                  &nbsp;{complaint?.complainer?.name}(
                  {complaint?.complainer?.role})
                </td>
              </tr>
              <tr>
                <th>Contact : </th>
                <td>&nbsp;{complaint?.complainer?.phone}</td>
              </tr>
            </table>
          </Col>
        </Row>

        <div className="App">
          <Grid container style={{ justifyContent: "space-between" }}></Grid>
          <div class="wrapper" id="grnericPdf">
            <div class="invoice_wrapper">
              <div class="body">
                <div class="main_table">
                  <div class="table_header">
                    <div class="row">
                      <div
                        class="col col_des"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Address
                      </div>
                      <div
                        class="col col_des"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Category
                      </div>
                      <div
                        class="col col_des"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Sub category
                      </div>
                      {isPlant && (
                        <>
                          <div
                            class="col col_des"
                            style={{ backgroundColor: "#e9ecef" }}
                          >
                            Area
                          </div>
                          <div
                            class="col col_des"
                            style={{ backgroundColor: "#e9ecef" }}
                          >
                            Subarea
                          </div>
                          <div
                            class="col col_des"
                            style={{ backgroundColor: "#e9ecef" }}
                          >
                            Zone
                          </div>
                        </>
                      )}

                      <div
                        class="col col_qty"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Deadline
                      </div>

                      <div
                        class="col col_qty"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Status
                      </div>
                      <div
                        class="col col_qty"
                        style={{ backgroundColor: "#e9ecef" }}
                      >
                        Priority
                      </div>
                    </div>
                  </div>
                  <div class="table_body">
                    <div class="row">
                      <div class="col col_des">
                        <p>
                          {" "}
                          {complaint?.complainer?.flat
                            ? complaint?.complainer?.property?.name +
                              "-" +
                              complaint?.complainer?.floor?.name +
                              "-" +
                              complaint?.complainer?.flat?.number
                            : complaint?.property?.name +
                              "-" +
                              complaint?.floor?.name +
                              "-" +
                              complaint?.flat?.number}
                        </p>
                      </div>
                      <div class="col col_des">
                        <p>{complaint?.category?.categoryName}</p>
                      </div>
                      <div class="col col_price">
                        <p>
                          {" "}
                          {complaint?.subcomplaintCategory?.subcategoryName}
                        </p>
                      </div>
                      {isPlant && (
                        <>
                          <div class="col col_qty">
                            <p>{complaint?.area?.name}</p>
                          </div>
                          <div class="col col_qty">
                            <p>{complaint?.subArea?.name}</p>
                          </div>
                          <div class="col col_qty">
                            <p>{complaint?.zone}</p>
                          </div>
                        </>
                      )}
                      <div class="col col_qty">
                        <p>{complaint?.deadline}&nbsp;hrs.</p>
                      </div>
                      <div class="col col_qty">
                        <p>{complaint?.status}&nbsp;</p>
                      </div>
                      <div class="col col_qty">
                        <p>{complaint?.priority}&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <footer class="text-center mt-4">
                  <Grid container>
                    <p></p>
                    <h3
                      style={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 18,
                      }}
                    >
                      Complaint Title :&nbsp;{complaint?.complaintTitle}
                    </h3>
                  </Grid>
                  <Grid container>
                    <p></p>
                    <h3
                      style={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 18,
                        marginTop: 10,
                      }}
                    >
                      Complaint Description :&nbsp;{complaint?.description}
                    </h3>
                  </Grid>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PrintComplaintDetails;
